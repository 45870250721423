import { Container, FormControl, Grid, TextField } from "@material-ui/core";
import React from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { useSelector } from "react-redux";

export default function RecommendedTestForm(props) {
  const { control, errors, register } = props;
  const { accConfig } = useSelector(({ uiConfigureStore }) => uiConfigureStore);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "prescriptions",
    keyName: "keyId", //, default to "id", you can change the key name
  });

  const onAdd = () => {
    append({
      recommendedTests: "",
    });
  };

  const onRemove = (index) => {
    remove(index);
  };
  const onReset = (index) => {
    remove(index);
    onAdd();
    // console.log('reset');
  };
  // console.log(errors);
  console.log("accConfig", accConfig);
  return (
    <div>
      <div>
        <Container style={{ paddingBottom: 25 }}>
          <Grid container spacing={2}>
            <Grid item xs={11} sm={11}>
              <FormControl fullWidth margin="normal">
                <Controller
                  label={"Recommended Tests"}
                  as={TextField}
                  name={`recommendedTests`}
                  control={control}
                  fullWidth
                  multiline
                />
              </FormControl>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
}
