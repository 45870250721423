import React from "react";
// Vendors Components
import { Tooltip, Button, Paper, Grid } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { Alert } from "reactstrap";
//  Themes Components
// Custom Components and Helpers

const AlertBox = (props) => {
  return (
    // <Paper>
    <Alert color={props.infotype}>
      <div className="text-center">
        <h3 className="jr-font-weight-medium mb-3">{props.mainTitle}</h3>
        <p className="mb-3">{props.message}</p>
        {props.onClick && (
          <Tooltip title={props.label ? props.label : "Update About Info "}>
            <Button
              aria-label="Add"
              onClick={() => props.onClick()}
              variant="contained"
              color="primary"
            >
              {props.label} <AddIcon />
            </Button>
          </Tooltip>
        )}
      </div>
    </Alert>
    // </Paper>
  );
};

export default AlertBox;
