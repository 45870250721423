import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";

import { Alert } from "reactstrap";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import CircularProgress from "@material-ui/core/CircularProgress";
import IntlMessages from "util/IntlMessages";

import { isLoggedIn } from "util/check-auth";
import SignUp from "./SignUp";
import ConfirmSignup from "./ConfirmSignup";

const RegisterUser = (props) => {
  const dispatch = useDispatch();
  const [isSignup, setIsSignUp] = useState(true);
  const { loader, alertMessage, showMessage, authUser, error } = useSelector(
    ({ auth }) => auth
  );

  useEffect(() => {
    if (isLoggedIn()) {
      props.history.push("/");
    }
  }, [authUser, props.history]);
  return (
    <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content">
        <div className="app-logo-content d-flex align-items-center justify-content-center">
          <Link className="logo-lg" to="/" title="Metta">
            <img
              src={require("assets/images/logo-centered.png")}
              alt="Metta"
              title="Metta"
              style={{ width: "100%" }}
            />
          </Link>
        </div>

        {isSignup && <SignUp setIsSignUp={setIsSignUp} />}
        {!isSignup && <ConfirmSignup setIsSignUp={setIsSignUp} />}
      </div>

      {loader && (
        <div className="loader-view">
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default withRouter(RegisterUser);
