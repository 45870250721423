import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from "react-hook-form";

import moment from "moment";
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Container,
  CardHeader,
} from "@material-ui/core";

import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

import { DateTimeInput } from "util/formInput";

import IntlMessages from "util/IntlMessages";

import { getICDcodes } from "redux/actions/Setting";

export default function DiagnosisForm(props) {
  const dispatch = useDispatch();
  const {
    control,
    register,
    setValue,
    getValues,
    errors,
    watch,
    accConfig,
    icds,
    updateMedicalFor,
  } = props;

  const [icdOpts, setIcdOpts] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const values = getValues({ nest: true });

  const watchIcd = watch("diagnosis");

  const onICDChange = (val) => {
    if (val && val.description) {
      setValue(`diagnosis.diagnosis`, val.description);
    }
    return val;
  };

  const onIcdfor = (event) => {
    let val = event.target.value;
    if (val && val.length > 3) {
      if (val.charAt(val.length - 1) === " ") {
        dispatch(getICDcodes("@" + val));
      }
    }
    return val;
  };

  const onIcdforBlur = (event) => {
    let val = event.target.value;
    if (val && val.length > 2) {
      dispatch(getICDcodes("@" + val));
    }
    return val;
  };

  // on Icd Free text
  const onICDFreeType = (val) => {
    // if (val && val.charAt(0) === "@" && val.length > 3) {
    //   if (val.charAt(val.length - 1) === " ") {
    //     dispatch(getICDcodes("@" + val));
    //     setValue(`diagnosis.icd10`, null);
    //     return "";
    //   }
    // }

    setValue(`diagnosis.icd10Text`, val);
  };

  useEffect(() => {
    icds[0] && icds[0].length > 0 ? setIcdOpts(icds[0]) : setIcdOpts([]);
  }, [icds && icds[0]]);

  const filterOptions = createFilterOptions({
    stringify: (option) => option.code + option.description,
  });

  return (
    <div>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <DateTimeInput
                value={values.diagnosis && values.diagnosis.date}
                label="Date"
                name="diagnosis.date"
                format="YYYY-MM-DD"
                inputRef={register}
                fullWidth
                autoOk
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <Controller
                label={<IntlMessages id="medicRecord.form.diagnosedBy" />}
                as={TextField}
                name="diagnosis.diagnosedBy"
                control={control}
                fullWidth
                error={errors.diagnosis && errors.diagnosis.diagnosedBy}
                helperText={
                  errors.diagnosis &&
                  errors.diagnosis.diagnosedBy &&
                  errors.diagnosis.diagnosedBy.message
                }
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth margin="normal">
              <InputLabel htmlFor="unit">
                <IntlMessages id="medicRecord.form.diagnosisType" />
              </InputLabel>
              <Controller
                as={
                  <Select>
                    <MenuItem value="" disabled>
                      {" "}
                      Diagnosis type
                    </MenuItem>
                    {accConfig.diagnosisType &&
                      accConfig.diagnosisType.map((val) => (
                        <MenuItem value={val.key} key={val.key}>
                          {val.value}
                        </MenuItem>
                      ))}
                  </Select>
                }
                name="diagnosis.diagnosisType"
                label={<IntlMessages id="medicRecord.form.diagnosisType" />}
                control={control}
                fullWidth
                // margin="normal"
                error={errors.diagnosis && errors.diagnosis.diagnosisType}
                helperText={
                  errors.diagnosis &&
                  errors.diagnosis.diagnosisType &&
                  errors.diagnosis.diagnosisType.message
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Grid container>
              <Grid item xs={4}>
                <FormControl fullWidth margin="normal">
                  <Controller
                    label={<IntlMessages id="medicRecord.form.icdSearch" />}
                    as={TextField}
                    name="diagnosis.ICDfor"
                    control={control}
                    fullWidth
                    error={errors.diagnosis && errors.diagnosis.ICDfor}
                    helperText={
                      errors.diagnosis &&
                      errors.diagnosis.ICDfor &&
                      errors.diagnosis.ICDfor.message
                    }
                    onBlur={([event]) => {
                      onIcdforBlur(event);
                    }}
                    onChange={([event]) => {
                      onIcdfor(event);
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={8}>
                <FormControl
                  fullWidth
                  margin="normal"
                  error={errors.diagnosis && errors.diagnosis.icd10}
                >
                  <Controller
                    as={
                      <Autocomplete
                        freeSolo
                        options={icdOpts}
                        getOptionLabel={(option) =>
                          option.code ? option.code : ""
                        }
                        filterOptions={filterOptions}
                        renderOption={(option) => (
                          <span>
                            {/* {countryToFlag(option.countryCode)} */}
                            <strong>{option.code}</strong>
                            <br />
                            {option.description}
                          </span>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Choose a ICD"
                            error={errors.diagnosis && errors.diagnosis.icd10}
                          />
                        )}
                      />
                    }
                    onChange={([, data]) => onICDChange(data)}
                    onInputChange={(event, newInputValue) => {
                      onICDFreeType(newInputValue);
                    }}
                    name="diagnosis.icd10"
                    control={control}
                    defaultValue={
                      values.diagnosis && values.diagnosis.icd10
                      // && getCountry(item.countryDialCode)
                    }
                  />
                  <FormHelperText>
                    {errors.diagnosis &&
                      errors.diagnosis.icd10 &&
                      errors.diagnosis.icd10.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <input
          ref={register}
          name="diagnosis.icd10Text"
          type="text"
          hidden
          defaultValue={values.diagnosis && values.diagnosis.icd10}
        />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal">
              <Controller
                label={<IntlMessages id="medicRecord.form.diagnosis" />}
                as={TextField}
                name="diagnosis.diagnosis"
                control={control}
                fullWidth
                error={errors.diagnosis && errors.diagnosis.diagnosis}
                helperText={
                  errors.diagnosis &&
                  errors.diagnosis.diagnosis &&
                  errors.diagnosis.diagnosis.message
                }
                multiline
                rowsMax={4}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
