import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from "react-hook-form";

// import ReactSelect from 'react-select';
import {
  Grid,
  TextField,
  InputAdornment,
  Container,
  InputLabel,
} from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";

import Autocomplete from "@material-ui/lab/Autocomplete";

// Utilities and helpers
import IntlMessages from "util/IntlMessages";
export default function NameForm(props) {
  const { control, errors, register, formRole, setValue, getValues } = props;

  const { contact, updateFormModal, clientTags } = useSelector(
    ({ contactStore }) => contactStore
  );
  const { appUiConfig } = useSelector(
    ({ uiConfigureStore }) => uiConfigureStore
  );
  useEffect(() => {
    if (appUiConfig.contact && appUiConfig.contact.autoGenerateClientId) {
      setValue("isClientId", false);
    }
  }, []);
  const values = getValues({ nest: true });
  return (
    <div>
      {updateFormModal && <input ref={register} name="id" type="text" hidden />}

      <Container style={{ paddingBottom: 25 }}>
        <Grid container spacing={1} direction="row">
          <input
            ref={register}
            name="isClientId"
            type="text"
            hidden
            defaultValue={
              appUiConfig.contact && !appUiConfig.contact.autoGenerateClientId
            }
          />
          {appUiConfig.contact &&
            !appUiConfig.contact.autoGenerateClientId &&
            !updateFormModal && (
              <Grid item xs={12}>
                <Controller
                  as={TextField}
                  label={<IntlMessages id="contact.form.clientId" />}
                  name="clientId"
                  control={control}
                  fullWidth
                  reuqired
                  margin="normal"
                  error={errors.clientId}
                  helperText={errors.clientId && errors.clientId.message}
                />
              </Grid>
            )}
          <Grid item xs={12}>
            <Controller
              as={TextField}
              label={<IntlMessages id="contact.form.name" />}
              name="name"
              control={control}
              fullWidth
              margin="normal"
              error={errors.name}
              helperText={errors.name && errors.name.message}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: "20px" }}>
            <InputLabel htmlFor="group">
              <IntlMessages id="inbox.form.tags" />
            </InputLabel>
            <Controller
              as={
                <Autocomplete
                  multiple
                  freeSolo
                  fullWidth
                  options={clientTags}
                  autoHighlight
                  getOptionLabel={(option) => option}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      {/* <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        /> */}
                      {option}
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // label="Choose a Users"
                      fullWidth
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "disabled", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              }
              onChange={([event, data]) => {
                return data;
              }}
              name={`tags`}
              control={control}
              defaultValue={values.tags}
            />

            {/* <TagInput
              margin="normal"
              label="Add Tags"
              tags={updateFormModal && contact.tags ? contact.tags : []}
              setTags={setTags}
            /> */}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
