const initialState = {
  loading: false,
  messages: [],
  errors: [],
  formerror: null,
  error: "",
  is_saving: false,

  profileTitle: { id: 1, title: "Profile" },
  clientError: null,
  client: {},
  clientLoading: false,

  notes: [],
  note: {},
  noteLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // set Profile
    case "SETPROFILE": {
      return {
        ...state,
        client: action.payload,
      };
    }
    // get client detail by Id
    case "CLIENT_BYID_FULFILLED": {
      const client = action.payload.data;
      return {
        ...state,
        client: client,
        clientLoading: false,
        clientError: null,
      };
    }
    case "CLIENT_BYID_PENDING": {
      return {
        ...state,
        client: {},
        clientLoading: true,
        clientError: null,
      };
    }
    case "CLIENT_BYID_REJECTED": {
      return {
        ...state,
        error: action.payload.response.data,
        client: {},
        messages: [],
        errors: [],
        clientLoading: false,
        clientError: action.payload.response.data.message,
      };
    }

    // set Profile Nav Header
    case "SETPROFILETITLE": {
      return {
        ...state,
        profileTitle: action.payload,
      };
    }

    // Save Inbox Notes
    case "CLIENTNOTE_ADD_FULFILLED": {
      const notes = [action.payload.data, ...state.notes];
      console.log(action.payload.data);
      console.log(notes);
      return {
        ...state,
        notes: [action.payload.data, ...state.notes],
        note: action.payload.data,
        formerror: null,
        is_saving: false,
      };
    }
    case "CLIENTNOTE_ADD_PENDING": {
      return {
        ...state,
        is_saving: true,
      };
    }
    case "CLIENTNOTE_ADD_REJECTED": {
      return {
        ...state,
        formerror: action.payload.response.data,
        is_saving: false,
        messages: [],
        errors: [],
      };
    }

    // get client detail by Id
    case "CLIENT_NOTES_FULFILLED": {
      const notes = action.payload.data;
      return {
        ...state,
        notes: notes.reverse(),
        noteLoading: false,
        error: null,
      };
    }
    case "CLIENT_NOTES_PENDING": {
      return {
        ...state,
        notes: [],
        noteLoading: true,
        error: null,
      };
    }
    case "CLIENT_NOTES_REJECTED": {
      return {
        ...state,
        error: action.payload?.response?.data,
        notes: [],
        messages: [],
        errors: [],
        noteLoading: false,
        // clientError: action.payload.response.data.message,
      };
    }
    case "RESET_PROFILESTORE": {
      return initialState;
    }
    default:
      return state;
  }
};
