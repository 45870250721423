import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { withRouter } from "react-router-dom";

// Vendors Components
import { Fab, Tooltip, Chip, Avatar } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Alert, Spinner } from "reactstrap";
import { DatePicker } from "@material-ui/pickers";

import DoneIcon from "@material-ui/icons/Done";

//  Themes Components
import Auxiliary from "util/Auxiliary";
import Widget from "components/Widget";
import AlertBox from "components/AlertBox/index";

import CardBox from "components/CardBox";
// Custom Components and Helpers and  Language Components
// import { NoLists } from "util/helpers";

//  Actions
import {
  getRecentDailyLogs,
  getDailyLog,
  onDailylogForm,
  setDailyLog,
} from "redux/actions/MedicalActions";
import { setProfileTitle } from "redux/actions/Setting";

// Components
import DailyLogForm from "./DailyLogForm/index";
import Interview from "./Interview";
import MedicalNotes from "./MedicalNotes";
import IntakeOutputRecord from "./IntakeOutputRecord";
import MedicationSchedules from "./MedicationSchedules";
// import MedicalNotes from "../MedicalDetail/MedicalNotes";

// Custom Components and Helpers
import { displayDate } from "util/helpers";

class NursingStation extends Component {
  state = {
    selectedDate: moment().format("YYYY-MM-DD"),
  };

  componentDidMount() {
    // var date = moment().format("YYYY-MM-DD");
    // this.props.getDailyLog(date, this.props.match.params.id);
    this.props.getRecentDailyLogs(this.props.match.params.id);
    this.props.setProfileTitle({ id: 3, title: "Daily Logs" });
  }
  onDailylogForm = () => {
    this.props.onDailylogForm();
  };

  handleDateChange = (date) => {
    var selectedDate = moment(date).format("YYYY-MM-DD");
    this.setState({ selectedDate: selectedDate });
    this.props.getDailyLog(selectedDate, this.props.match.params.id);
  };
  handleClick = (val) => {
    this.props.setDailyLog(val);
  };
  render() {
    const {
      contact,
      dailyLog,
      dailyLogs,
      dailyLog_error,
      dailyLog_loading,
      addDailyLogModal,
      updateDailyLogModal,
    } = this.props;
    return (
      <Auxiliary>
        {console.log("=====contact", contact)}
        {contact && contact.role === "CLIENT" && (
          <div>
            <div className="page-heading d-sm-flex justify-content-sm-between align-items-sm-center">
              <h2 className="title mb-3 mb-sm-0">Daily Log</h2>
              <span className="mb-0">
                <DatePicker
                  margin="normal"
                  // label="Date picker"
                  format="YYYY-MM-DD"
                  value={this.state.selectedDate}
                  onChange={this.handleDateChange}
                  disableFuture
                />
              </span>
            </div>
            {dailyLogs?.length > 0 && (
              <Widget styleName="jr-card-full jr-card-tabs-right jr-card-profile">
                <div className="manage-margin d-flex flex-wrap jr-tabs-content jr-task-list jr-wall-content">
                  {dailyLogs.map((data) => {
                    return (
                      <Chip
                        label={displayDate(data.dateDisplay)}
                        key={data.id}
                        onClick={() => this.handleClick(data)}
                        onDelete={() => this.handleClick(data)}
                        deleteIcon={<DoneIcon />}
                        color={data.id === dailyLog.id ? "primary" : ""}
                        variant={data.id !== dailyLog.id ? "" : "outlined"}
                        style={{ marginTop: "5px" }}
                      />
                    );
                  })}
                </div>
              </Widget>
            )}

            {dailyLog_loading && <Skeleton variant="rect" height={100} />}

            {!dailyLog.id && !dailyLog_loading && (
              <AlertBox
                infotype="danger"
                mainTitle="Daily Log Not Found"
                message={dailyLog_error}
                label={"Click to Add daily log for " + this.state.selectedDate}
                onClick={this.onDailylogForm}
              />
            )}

            {dailyLog.id && (
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                  <MedicationSchedules />
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                  <MedicalNotes />
                  <Interview />
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                  <IntakeOutputRecord />
                </div>
              </div>
            )}

            <div className="bottom-button">
              {!dailyLog.id && (
                <Tooltip
                  title={
                    "Click to Add daily log for " + this.state.selectedDate
                  }
                  aria-label="Add EWS Detail"
                >
                  <Fab
                    color="primary"
                    aria-label="add"
                    onClick={() => this.onDailylogForm()}
                  >
                    <i className="zmdi zmdi-plus zmdi-hc-fw zmdi-hc-lg" />
                  </Fab>
                </Tooltip>
              )}
            </div>
            {(addDailyLogModal || updateDailyLogModal) && (
              <DailyLogForm selectedDate={this.state.selectedDate} />
            )}
          </div>
        )}
        {contact && !contact.role === "CLIENT" && (
          <Alert>Details Avaiable for only Patient</Alert>
        )}
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({
  uiConfigureStore,
  contactStore,
  medRecordStore,
}) => {
  const { width } = uiConfigureStore;
  const { contact } = contactStore;
  const {
    dailyLogs,
    dailyLog,
    dailyLog_loading,
    dailyLog_error,
    addDailyLogModal,
    updateDailyLogModal,
  } = medRecordStore;
  return {
    width,
    dailyLogs,
    dailyLog,
    dailyLog_loading,
    contact,
    dailyLog_error,
    addDailyLogModal,
    updateDailyLogModal,
  };
};

export default connect(mapStateToProps, {
  getRecentDailyLogs,
  setDailyLog,
  getDailyLog,
  onDailylogForm,
  setProfileTitle,
})(withRouter(NursingStation));
