import { api } from "util/api";
export function getAllTasks(filterVal = null, searchVal = null) {
  var query = "";
  if (filterVal === "status") {
    query = "?" + filterVal + "=" + searchVal;
  }
  if (filterVal === "search") {
    query = "?" + filterVal + "=" + searchVal;
  }
  return (dispatch) => {
    return dispatch({
      type: "GET_ALLTASKS",
      payload: api.get("task/all" + query),
    });
  };
}

export function onTaskClose() {
  return (dispatch) => {
    return dispatch({
      type: "INBOXCLOSE",
    });
  };
}

export function onTaskForm(
  value = null,
  formType = "add",
  aboutType = "other"
) {
  return (dispatch) => {
    return dispatch({
      type: "TASKFORM",
      payload: {
        task: value,
        formParam: { formType: formType, aboutType: aboutType },
      },
    });
  };
}

export function onTaskAdd(value) {
  return (dispatch) => {
    return dispatch({
      type: "TASK_ADD",
      payload: api.post("task", value),
    });
  };
}

export function onTaskUpdate(value) {
  return (dispatch) => {
    return dispatch({
      type: "TASK_UPDATE",
      payload: api.post("task", value),
    });
  };
}
export function onTaskDelete(value) {
  return (dispatch) => {
    return dispatch({
      type: "TASK_DELETE",
      payload: api.post("task", value),
    });
  };
}

export function getTaskTags() {
  return (dispatch) => {
    return dispatch({
      type: "TASKTAGS",
      payload: api.get("task/tags/"),
    });
  };
}
