import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";

// material ui
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
} from "@material-ui/core";
import { Alert, Spinner } from "reactstrap";

// custom Components
import DialogTitle from "components/Dialog/DialogTitle";

// Actions
import {
  getActiveUsers,
  onNoteClose,
  onInboxNoteUpdate,
  onInboxNoteAdd,
} from "services/actions";

// Utilities and helpers
import IntlMessages from "util/IntlMessages";
import { CrudNotification } from "util/helpers";
import { formSchema, initInboxVal } from "../formIndex";
// components
import AccessGroupsArray from "./AccessGroupsArray";
import NoteSubjectForm from "./NoteSubjectForm";
import { AccessGroupsInput } from "util/formInput";

const FormTitle = (props) => {
  const { note, updateFormInbox } = props;
  if (updateFormInbox) {
    return <span>Update {note.subject}</span>;
  } else {
    return <span>Add Note</span>;
  }
};

const InboxForm = (props) => {
  const dispatch = useDispatch();
  const { accConfig, districts, appUiConfig, uiUsers } = useSelector(
    ({ uiConfigureStore }) => uiConfigureStore
  );
  const {
    notes,
    notesLoading,
    note,
    addFormInbox,
    updateFormInbox,
    viewInboxNote,
    aboutType,
    formerror,
    is_saving,
  } = useSelector(({ inboxStore }) => inboxStore);

  const [initialVal, setInitialVal] = useState(
    updateFormInbox ? note : initInboxVal
  );

  const {
    handleSubmit,
    register,
    reset,
    control,
    errors,
    setValue,
    getValues,
    watch,
  } = useForm({
    validationSchema: formSchema,
    defaultValues: initialVal,
    mode: "onBlur",
  });

  useEffect(() => {
    uiUsers.length === 0 && dispatch(getActiveUsers());
  }, []);
  const [groups, setGroups] = useState([]);

  // On update submit
  const onSubmit = (values) => {
    if (values.id) {
      // console.log("add", values);
      return dispatch(onInboxNoteUpdate(values)).then((res) => {
        CrudNotification("success", "Note added Successfully !");
      });
    } else {
      // console.log("update", values);
      return dispatch(onInboxNoteAdd(values)).then((res) => {
        CrudNotification("success", "Note updated Successfully !");
      });
    }
  };

  // Process form values before submitting
  const transform = (onSubmitFunc) => (data) => {
    let values = data;
    // if (values.accessGroups) {
    //   values.accessGroups.map((accessGroup, index) => {
    //     if (accessGroup.group === "ALL") {
    //       values.accessGroups.splice(index);
    //     } else {
    //       if (accessGroup.members && accessGroup.members.length !== 0) {
    //         accessGroup.members = accessGroup.members.map((a) =>
    //           a.id ? a.id : a
    //         );
    //         accessGroup.members = [...new Set(accessGroup.members)];
    //         return accessGroup;
    //       }
    //     }
    //   });
    // }
    if (groups?.length > 0) {
      values.accessGroups = groups.map((group) => {
        return group.key;
      });
    } else {
      values.accessGroups = ["DEFAULT"];
    }

    onSubmitFunc(values);
  };
  // on contacts modal close
  const onClose = () => {
    dispatch(onNoteClose());
  };

  const values = getValues({ nest: true });
  return (
    <Fragment>
      <DialogTitle id="contact-form" onClose={onClose}>
        <FormTitle updateFormInbox={updateFormInbox} note={note} />
      </DialogTitle>
      <DialogContent dividers={true}>
        {formerror && <Alert color="danger"> {formerror.message} </Alert>}
        {updateFormInbox && (
          <input
            ref={register}
            name="id"
            type="text"
            hidden
            defaultValue={note.id}
          />
        )}

        <NoteSubjectForm
          {...{
            control,
            register,
            getValues,
            setValue,
            errors,
            updateFormInbox,
            note,
            watch,
          }}
        />

        <AccessGroupsInput
          label="Share with..."
          formVals={initialVal}
          groups={groups}
          setGroups={setGroups}
        />
      </DialogContent>
      <DialogActions>
        <Button
          className="button buttonBlack"
          type="button"
          onClick={() => {
            reset(initialVal);
          }}
        ></Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSubmit(transform(onSubmit))}
          disabled={is_saving}
        >
          {is_saving && <Spinner size="sm" />}
          <IntlMessages id="form.save" />
        </Button>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          onClick={() => onClose()}
        >
          <IntlMessages id="form.cancel" />
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default InboxForm;
