import React from "react";
import TemplateListItem from "./TemplateListItem";
import CustomScrollbars from "util/CustomScrollbars";

const TemplateList = ({ templates, onTemplateSelect, width }) => {
  // console.log(templates);
  return (
    <div className="module-list mail-list">
      <CustomScrollbars
        className="module-list-scroll scrollbar"
        style={{
          height: width >= 1200 ? "calc(100vh - 259px)" : "calc(100vh - 238px)",
        }}
      >
        {templates &&
          templates.map((template, index) => (
            <TemplateListItem
              key={template.id}
              template={template}
              onTemplateSelect={onTemplateSelect}
            />
          ))}
      </CustomScrollbars>
    </div>
  );
};

export default TemplateList;
