import { api } from "util/api";

export function getAllVisits(id) {
  // var query = "";
  // if (filterVal === "status") {
  //   query = "?" + filterVal + "=" + searchVal;
  // }
  // if (filterVal === "search") {
  //   query = "?" + filterVal + "=" + searchVal;
  // }
  return (dispatch) => {
    return dispatch({
      type: "GET_ALLVISIT",
      payload: api.get(`visit/clinical/patient-id/${id}`),
    });
  };
}

export function getAllDepartments(filterVal = null, searchVal = null) {
  // var query = "";
  // if (filterVal === "status") {
  //   query = "?" + filterVal + "=" + searchVal;
  // }
  // if (filterVal === "search") {
  //   query = "?" + filterVal + "=" + searchVal;
  // }
  return (dispatch) => {
    return dispatch({
      type: "GET_ALLDEPARTMENT",
      payload: api.get(`account/lookups?filter=departments`),
    });
  };
}

export function getAllUnits(filterVal = null, searchVal = null) {
  // var query = "";
  // if (filterVal === "status") {
  //   query = "?" + filterVal + "=" + searchVal;
  // }
  // if (filterVal === "search") {
  //   query = "?" + filterVal + "=" + searchVal;
  // }
  return (dispatch) => {
    return dispatch({
      type: "GET_ALLUNIT",
      payload: api.get(`account/lookups?filter=units`),
    });
  };
}

export function onVisitClose() {
  return (dispatch) => {
    return dispatch({
      type: "INBOXCLOSE",
    });
  };
}

export function onVisitForm(
  visitId = null,
  formType = "add",
  aboutType = "other"
) {
  return async (dispatch) => {
    if (visitId) {
      const getData = await api.get(`visit/clinical/visit-id/${visitId}`);
      const getDataJson = getData?.data;
      return dispatch({
        type: "VISITFORM",
        payload: {
          visit: getDataJson,
          formParam: {
            formType: formType,
            aboutType: aboutType,
          },
        },
      });
    } else {
      return dispatch({
        type: "VISITFORM",
        payload: {
          visit: null,
          formParam: {
            formType: formType,
            aboutType: aboutType,
          },
        },
      });
    }
  };
}

export function onVisitAdd(value) {
  return (dispatch) => {
    return dispatch({
      type: "VISIT_ADD",
      payload: api.post("visit/clinical", value),
    });
  };
}

export function onVisitUpdate(value) {
  return (dispatch) => {
    return dispatch({
      type: "VISIT_UPDATE",
      payload: api.post("visit/clinical", value),
    });
  };
}
export function onVisitDelete(value) {
  return (dispatch) => {
    return dispatch({
      type: "VISIT_DELETE",
      payload: api.post("visit/clinical", value),
    });
  };
}

export function getVisitTags() {
  return (dispatch) => {
    return dispatch({
      type: "PATIENTVISITTAGS",
      payload: api.get("visit/tags/"),
    });
  };
}

export function setVisitOpen(value) {
  return (dispatch) => {
    return dispatch({
      type: "VISITOPEN",
      payload: value,
    });
  };
}

export function setVisitEmpty() {
  return (dispatch) => {
    return dispatch({
      type: "VISITEMPTY",
    });
  };
}
