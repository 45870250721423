import {
  CHANGE_DIRECTION,
  CHANGE_NAVIGATION_STYLE,
  FIXED_DRAWER,
  HORIZONTAL_MENU_POSITION,
  INSIDE_THE_HEADER,
  SWITCH_LANGUAGE,
  TOGGLE_COLLAPSED_NAV,
  VERTICAL_NAVIGATION,
  WINDOW_WIDTH,
} from "constants/ActionTypes";
import { getQueryString } from "util/helpers";
const rltLocale = ["ar"];
const initialSettings = {
  navCollapsed: false,
  drawerType: FIXED_DRAWER,
  // drawerType: COLLAPSED_DRAWER,
  width: window.innerWidth,
  isDirectionRTL: false,
  navigationStyle: VERTICAL_NAVIGATION,
  horizontalNavPosition: INSIDE_THE_HEADER,
  locale: {
    languageId: "english",
    locale: "en",
    name: "English",
    icon: "us",
  },

  appUiConfig: {},
  // restrictions
  togRestrictModal: false,
  restrictModalFor: "",
  restrictMessage: "",

  lookup_loading: false,
  accConfig: {
    investigations: [],

  },
  districts: [],

  countries: [],
  isloading: false,
  clientLists: [],
  // uiUsers
  uiUsers: [],
  uiUsersLoading: false,
  // uiTemplates
  uiTemplates: [],
  uiTemplatesLoading: false,
  // clients
  uiClients: [],
  uiStaffs: [],
  uiClientsLoading: false,
  contactsId: [],
};
const compare = (a, b) => {
  // Use toUpperCase() to ignore character casing
  const GroupA = a.group.toUpperCase();
  const GroupB = b.group.toUpperCase();

  let comparison = 0;
  if (GroupA > GroupB) {
    comparison = 1;
  } else if (GroupA < GroupB) {
    comparison = -1;
  }
  return comparison;
};
const uiConfigureStore = (state = initialSettings, action) => {
  switch (action.type) {
    // get Invetstigations
    case "GET_INVESTIGATIONS_FULFILLED": {
      // console.log(action.payload)
      let url = action.payload.config.url;

      let keyword = url.split("lookups/")[1];
      console.log("========++",url)

      let datas = action.payload.data.sort(compare);
      return {
        ...state,
        accConfig: { ...state.accConfig, ["investigations"]: datas },
      };
      // console.log(action.payload)
      // let datas = action.payload.data.sort(compare);
      // return {
      //   ...state,
      //   accConfig: { ...state.accConfig, investigations: datas },
      // };
    }
    case "GET_INVESTIGATIONS_PENDING": {
      return {
        ...state,
      };
    }
    case "GET_INVESTIGATIONS_REJECTED": {
      return {
        ...state,
        error: action.payload.response.data,
        messages: [],
        errors: [],
      };
    }
    // toggle Trial Warning modal
    case "TOGTRIAL_WARNING": {
      return {
        ...state,
        togRestrictModal: action.payload.tog,
        restrictModalFor: action.payload.formfor,
        restrictMessage: action.payload.message,
      };
    }
    case "APILOADING": {
      return {
        ...state,
        isloading: action.payload,
      };
    }

    // get default App Ui configurations
    case "GET_APPUI_CONFIG_FULFILLED": {
      // console.log(action.payload)
      let appUiConfig = action.payload.data;
      return {
        ...state,
        appUiConfig: appUiConfig,
      };
    }
    case "GET_APPUI_CONFIG_PENDING": {
      return {
        ...state,
        appUiConfig: {},
      };
    }
    case "GET_APPUI_CONFIG_REJECTED": {
      return {
        ...state,
        appUiConfig: {},
        error: action.payload.response.data,
        messages: [],
        errors: [],
      };
    }

    // get Districts
    case "GET_DISTRICT_FULFILLED": {
      let datas = action.payload.data;
      return {
        ...state,
        districts: datas,
      };
    }
    case "GET_DISTRICT_PENDING": {
      return {
        ...state,
        districts: [],
      };
    }
    case "GET_DISTRICT_REJECTED": {
      return {
        ...state,
        districts: [],
        error: action.payload.response.data,
        messages: [],
        errors: [],
      };
    }
    // get Countries
    case "GET_COUNTRIES_FULFILLED": {
      let datas = action.payload.data;
      return {
        ...state,
        countries: datas,
      };
    }
    case "GET_COUNTRIES_PENDING": {
      return {
        ...state,
      };
    }
    case "GET_COUNTRIES_REJECTED": {
      return {
        ...state,
        error: action.payload.response.data,
        messages: [],
        errors: [],
      };
    }

    // Account lookout keys
    case "ALLCONFIGDATA_FULFILLED": {
      // get key type from the request url
      // console.log(state.accConfig);
      let keys = {};
      let datas = action.payload.data;
      Object.entries(datas).map(([key, value]) => {
        let object = value.reduce(
          (obj, item) => Object.assign(obj, { [item]: [] }),
          {}
        );
        keys = { ...keys, ...object };
      });
      // data from response
      return {
        ...state,
        accConfig: keys,
        // accConfig: { ...state.accConfig, [type]: datas },
        lookup_loading: false,
      };
    }
    case "ALLCONFIGDATA_PENDING": {
      return {
        ...state,
        lookup_loading: true,
      };
    }
    case "ALLCONFIGDATA_REJECTED": {
      return {
        ...state,
        error: action.payload.response.data,
        lookup_loading: false,
        messages: [],
        errors: [],
      };
    }

    // Account lookout
    case "ACC_LOOKUP_FULFILLED": {
      // get key type from the request url
      var type = getQueryString(action.payload.config.url, "filter");
      // data from response
      var datas = action.payload.data;
      return {
        ...state,
        accConfig: { ...state.accConfig, [type]: datas },
        lookup_loading: false,
      };
    }
    case "ACC_LOOKUP_PENDING": {
      return {
        ...state,
        lookup_loading: true,
      };
    }
    case "ACC_LOOKUP_REJECTED": {
      return {
        ...state,
        error: action.payload.response.data,
        lookup_loading: false,
        messages: [],
        errors: [],
      };
    }

    // get default Account Units
    case "GET_DEFAULT_UNITS_FULFILLED": {
      // console.log(action.payload)
      let accountDefaultUnits = action.payload.data;
      return {
        ...state,
        accountDefaultUnits: accountDefaultUnits,
      };
    }
    case "GET_DEFAULT_UNITS_PENDING": {
      return {
        ...state,
        accountDefaultUnits: {},
      };
    }
    case "GET_DEFAULT_UNITS_REJECTED": {
      return {
        ...state,
        accountDefaultUnits: {},
        error: action.payload.response.data,
        messages: [],
        errors: [],
      };
    }

    // clientLists
    // SettingActions
    // Get Contacts for forms
    case "GET_CONTACTSBYVAL_FULFILLED": {
      let clientLists = action.payload.data;
      return {
        ...state,
        clientLists: clientLists,
        loading: false,
      };
    }
    // Get All Active  Users
    case "GET_ACTIVEUSERS_FULFILLED": {
      let users = action.payload.data;
      users = users.filter((user) => user.status === "ACTIVE");
      return {
        ...state,
        uiUsers: users,
        uiUsersLoading: false,
      };
    }
    case "GET_ACTIVEUSERS_PENDING": {
      return {
        ...state,
        uiUsers: [],
        uiUsersLoading: true,
      };
    }
    case "GET_ALLUSERS_REJECTED": {
      return {
        ...state,
        uiUsers: [],
        uiUsersLoading: false,
        error: action.payload.response.data,
        message: action.payload.message,
      };
    }

    // Get All Active  Users
    case "GET_ACTIVETEMPLATES_FULFILLED": {
      let templates = action.payload.data;
      templates = templates.filter((template) => template.status === "ACTIVE");
      return {
        ...state,
        uiTemplates: templates,
        uiTemplatesLoading: false,
      };
    }
    case "GET_ACTIVETEMPLATES_PENDING": {
      return {
        ...state,
        uiTemplates: [],
        uiTemplatesLoading: true,
      };
    }
    case "GET_ACTIVETEMPLATES_REJECTED": {
      return {
        ...state,
        uiTemplates: [],
        uiTemplatesLoading: false,
        error: action.payload.response.data,
        message: action.payload.message,
      };
    }
    // Get All Active  Staffs
    case "GET_ACTIVESTAFFS_FULFILLED": {
      let staffs = action.payload.data;
      // clients = clients.filter((client) => client.status === "ACTIVE");
      return {
        ...state,
        uiStaffs: staffs,
        uiClientsLoading: false,
      };
    }
    // Get All Active  Clients
    case "GET_ACTIVECONTACTS_FULFILLED": {
      let clients = action.payload.data;
      // clients = clients.filter((client) => client.status === "ACTIVE");
      return {
        ...state,
        uiClients: clients,
        uiClientsLoading: false,
      };
    }
    case "GET_ACTIVECONTACTS_PENDING": {
      return {
        ...state,
        uiClients: [],
        uiClientsLoading: true,
      };
    }
    case "GET_ACTIVECONTACTS_REJECTED": {
      return {
        ...state,
        uiClients: [],
        uiClientsLoading: false,
        error: action.payload.response.data,
        message: action.payload.message,
      };
    }

    case "@@router/LOCATION_CHANGE":
      return {
        ...state,
        navCollapsed: false,
      };
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.isNavCollapsed,
      };
    case WINDOW_WIDTH:
      return {
        ...state,
        width: action.width,
      };
    case SWITCH_LANGUAGE:
      return {
        ...state,
        locale: action.payload,
        isDirectionRTL: rltLocale.includes(action.payload.locale),
      };
    case CHANGE_DIRECTION:
      return {
        ...state,
        isDirectionRTL: !state.isDirectionRTL,
      };

    case CHANGE_NAVIGATION_STYLE:
      return {
        ...state,
        navigationStyle: action.payload,
      };

    case HORIZONTAL_MENU_POSITION:
      return {
        ...state,
        horizontalNavPosition: action.payload,
      };

    default:
      return state;
  }
};

export default uiConfigureStore;
