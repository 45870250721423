import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";

import {
  Drawer,
  IconButton,
  Checkbox,
  Button,
  Tooltip,
  Fab,
  Chip,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

// Custom Component
import AppModuleHeader from "components/AppModuleHeader";

// actions
import {
  onClientList,
  onClientSearch,
  onContactForm,
  onClientAddFav,
  onClientDelete,
  onModalClose,
  getClientTags,
} from "./actions";
import { getGlobalCountries } from "services/actions";

// utilities and helper
import {
  getColor,
  CrudNotification,
  saveStarredItem,
  capitalize,
} from "util/helpers";
import IntlMessages from "util/IntlMessages";
import CustomScrollbars from "util/CustomScrollbars";

// components
import FormModal from "./FormModal";
import ContactList from "./ContactList";
const filterOptions = [
  {
    id: 1,
    name: "Clients",
    icon: "zmdi-menu",
    value: "CLIENT",
  },
  {
    id: 2,
    name: "Opportunities",
    icon: "zmdi-menu",
    value: "OPPORTUNITY",
  },
  ,
  {
    id: 3,
    name: "Staff",
    icon: "zmdi-menu",
    value: "STAFF",
  },
  ,
  {
    id: 4,
    name: "Other",
    icon: "zmdi-menu",

    value: "OTHER",
  },
  {
    id: 5,
    name: "Recents",
    icon: "zmdi-time-restore",
    value: "CLIENT",
  },
  {
    id: 6,
    name: "Starred",
    icon: "zmdi-star",
    value: "CLIENT",
  },
  {
    id: 7,
    name: "Trashed",
    icon: "zmdi-delete",
    value: "CLIENT",
  },
];

const Contact = () => {
  const dispatch = useDispatch();
  const { width } = useSelector(({ uiConfigureStore }) => uiConfigureStore);
  const { loggedUser } = useSelector(({ auth }) => auth);
  const {
    updateFormModal,
    addFormModal,
    contacts,
    contact,
    contactLoading,
    clientTags,
  } = useSelector(({ contactStore }) => contactStore);

  const [noContentFoundMessage, setNoContentFoundMessage] =
    useState("No Contacts found.");
  const [selectedSectionId, setSelectedSectionId] = useState(1);
  const [selectedRole, setSelectedRole] = useState("CLIENT");
  const [drawerState, setDrawerState] = useState(false);
  const [searchUser, setSearchUser] = useState("");
  const [selectedContact, setSelectedContact] = useState(null);
  const [selectedContacts, setSelectedContacts] = useState(0);
  const [addContactState, setAddContactState] = useState(false);
  const [allContact, setAllContact] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [filterVal, setFilterVal] = useState("name");
  const [filterOption, setFilterOption] = useState("CLIENT");

  useEffect(() => {
    document.title = "Clients - Metta";
    getAllcontacts("roles", "client");
    dispatch(getClientTags());
  }, []);

  useEffect(() => {
    // console.log("sdfsdf");
    setAllContact(contacts);
    getWithStarred(contacts.filter((item) => item.status === "ACTIVE"));
  }, [contacts]);

  // get List of contacts
  const getAllcontacts = (filterVal, searchVal = null) => {
    // console.log(filterVal, searchVal);
    dispatch(onClientList(filterVal, searchVal));
  };
  const getWithStarred = (contactslist) => {
    var favItems = JSON.parse(
      localStorage.getItem(loggedUser.accountId + "starredContacts")
    );
    let a = contactslist.map((element) => {
      element.starred = false;
      if (favItems) {
        favItems.forEach((fav) => {
          if (fav.id === element.id) {
            element.starred = true;
          }
        });
      }
      return element;
    });
    setContactList(a);
    // }
  };

  const ContactSideBar = (user) => {
    return (
      <div className="module-side">
        <div className="module-side-header">
          <div className="module-logo">
            <i className="zmdi zmdi-account-box mr-4" />
            <span>
              <IntlMessages id="pages.contacts" />
            </span>
          </div>
        </div>

        <div className="module-side-content">
          <CustomScrollbars
            className="module-side-scroll scrollbar"
            style={{
              height:
                width >= 1200 ? "calc(100vh - 200px)" : "calc(100vh - 80px)",
            }}
          >
            <div className="module-add-task">
              <Button
                className="jr-btn btn-block"
                variant="contained"
                color="primary"
                aria-label="add"
                onClick={() => onAddForm()}
              >
                <i className="zmdi zmdi-account-add zmdi-hc-fw" />
                <span>Add New {capitalize(selectedRole)}</span>
              </Button>
            </div>
            <div className="module-side-nav">
              <ul className="module-nav">
                {filterOptions.map((option) => (
                  <li key={option.id} className="nav-item">
                    <span
                      className={`jr-link ${
                        option.id === selectedSectionId ? "active" : ""
                      }`}
                      onClick={() => onFilterOptionSelect(option)}
                    >
                      <i className={`zmdi ${option.icon}`} />
                      <span>{option.name}</span>
                    </span>
                  </li>
                ))}
              </ul>
              {/* {clientTags.map((tag, index) => (
                <Chip
                  key={index}
                  onClick={() => getAllcontacts("tags", tag)}
                  label={tag}
                  style={{
                    backgroundColor: getColor(tag),
                    color: "white",
                    margin: "10px",
                  }}
                />
              ))} */}
            </div>
          </CustomScrollbars>
        </div>
      </div>
    );
  };

  const addFavourite = (data) => {
    var alertmsg = data.starred
      ? data.name + " removed as starred Contact"
      : data.name + " marked as star Contact";
    saveStarredItem(data, loggedUser.accountId + "starredContacts");
    CrudNotification("success", alertmsg);
    if (filterOption === "Starred") {
      var favItems = JSON.parse(
        localStorage.getItem(loggedUser.accountId + "starredContacts")
      );
      setContactList(favItems ? favItems : []);
    } else {
      getWithStarred(allContact);
    }
  };
  const onContactSelect = (data) => {
    data.selected = !data.selected;
    // console.log(data)
    let selectedContactsCount = 0;
    const contactListMapped = contactList.map((contact) => {
      if (contact.selected) {
        selectedContactsCount++;
      }
      if (contact.id === data.id) {
        if (contact.selected) {
          selectedContactsCount++;
        }
        return data;
      } else {
        return contact;
      }
    });
    setSelectedContacts(selectedContactsCount);
    setContactList(contactListMapped);
    // setAllContact(contacts)
  };

  const onFilterOptionSelect = (option) => {
    setDrawerState(false);
    // console.log(allContact);
    switch (option.name) {
      case "Clients": {
        getAllcontacts("roles", "client");
        setSelectedSectionId(option.id);
        setFilterOption(option.value);
        setSelectedRole(option.value);
        setNoContentFoundMessage("No Clients found.");
        break;
      }
      case "Opportunities": {
        getAllcontacts("roles", "opportunity");
        setSelectedSectionId(option.id);
        setFilterOption(option.name);
        setSelectedRole(option.value);
        setNoContentFoundMessage("No Opportunity found.");

        break;
      }
      case "Staff": {
        getAllcontacts("roles", "staff");
        setSelectedSectionId(option.id);
        setFilterOption(option.name);
        setSelectedRole(option.value);
        setNoContentFoundMessage("No Staff found.");
        break;
      }
      case "Other": {
        getAllcontacts("roles", "other");
        setSelectedSectionId(option.id);
        setFilterOption(option.name);
        setSelectedRole(option.value);
        setNoContentFoundMessage("No Other Data found.");

        break;
      }
      case "Recents": {
        var recentItems = JSON.parse(
          sessionStorage.getItem(loggedUser.accountId + "recentContacts")
        );
        recentItems && getWithStarred(recentItems);
        setSelectedSectionId(option.id);
        setFilterOption(option.name);
        setSelectedRole(option.value);
        setNoContentFoundMessage("No Recent Contacts found.");
        // setContactList();
        break;
      }
      case "Starred": {
        var favItems = JSON.parse(
          localStorage.getItem(loggedUser.accountId + "starredContacts")
        );
        setSelectedSectionId(option.id);
        setFilterOption(option.name);
        setSelectedRole(option.value);
        setNoContentFoundMessage("No Starred Contacts found.");
        setContactList(favItems ? favItems : []);

        break;
      }
      case "Trashed": {
        setSelectedSectionId(option.id);
        setFilterOption(option.name);
        setSelectedRole(option.value);
        setNoContentFoundMessage("No trashed Contacts found.");
        setContactList(allContact.filter((item) => item.status !== "ACTIVE"));

        break;
      }
      default: {
        setSelectedSectionId(option.id);
        setFilterOption(option.name);
        setContactList(getWithStarred(allContact));
      }
    }
  };

  const onDeleteSelectedContact = () => {
    const contacts = allContact.filter((contact) => !contact.selected);
  };

  const getAllContact = () => {
    let contactList = allContact.map((contact) =>
      contact
        ? {
            ...contact,
            selected: true,
          }
        : contact
    );
    setSelectedContacts(contactList.length);
    setAllContact(contactList);
    setContactList(contactList);
  };
  const getUnselectedAllContact = () => {
    let contactList = allContact.map((contact) =>
      contact
        ? {
            ...contact,
            selected: false,
          }
        : contact
    );
    setSelectedContacts(0);
    setAllContact(contactList);
    setContactList(contactList);
  };

  const onAllContactSelect = () => {
    const selectAll = selectedContacts < contactList.length;
    if (selectAll) {
      getAllContact();
    } else {
      getUnselectedAllContact();
    }
  };

  const onToggleDrawer = () => {
    setDrawerState(!drawerState);
  };

  const onContactUserSearch = (val) => {
    setSearchUser(val);
    if (val.length > 2) {
      getAllcontacts(filterVal, val);
    } else if (val.length === 0) {
      // console.log("test");
      getAllcontacts("roles", "client");
    }
  };
  const onClientTypeChange = (evt) => {
    // console.log(evt.target.value);
    setFilterVal(evt.target.value);
  };

  // on contact add form
  const onAddForm = () => {
    dispatch(onContactForm(null, "add", selectedRole));
  };

  return (
    <>
      <div className="app-module animated slideInUpTiny animation-duration-3">
        <div className="d-block d-xl-none">
          <Drawer open={drawerState} onClose={() => onToggleDrawer()}>
            {ContactSideBar(loggedUser)}
          </Drawer>
        </div>
        <div className="app-module-sidenav d-none d-xl-flex">
          {ContactSideBar(loggedUser)}
        </div>

        <div className="module-box">
          <div className="module-box-header">
            <IconButton
              className="drawer-btn d-block d-xl-none"
              aria-label="Menu"
              onClick={() => onToggleDrawer()}
            >
              <i className="zmdi zmdi-menu" />
            </IconButton>
            <AppModuleHeader
              // placeholder="Search contact"
              notification={false}
              apps={false}
              user={loggedUser}
              onChange={onContactUserSearch}
              value={searchUser}
              filterVal={filterVal}
              onClientTypeChange={onClientTypeChange}
            />
          </div>
          <div className="module-box-content">
            <div className="module-box-topbar">
              {/* <Checkbox color="primary"
                    indeterminate={selectedContacts > 0 && selectedContacts < contactList.length}
                    checked={selectedContacts > 0}
                    onChange={()=>onAllContactSelect()}
                    value="SelectMail" /> */}

              {selectedContacts > 0 && (
                <IconButton
                  className="icon-btn"
                  onClick={() => onDeleteSelectedContact()}
                >
                  <i className="zmdi zmdi-delete" />
                </IconButton>
              )}
            </div>
            <CustomScrollbars
              className="module-list-scroll scrollbar"
              style={{
                height:
                  width >= 1200 ? "calc(100vh - 200px)" : "calc(100vh - 200px)",
              }}
            >
              {contactLoading && (
                // <span className="h-10 d-flex align-items-center justify-content-center">
                <Skeleton variant="rect" height={50} />
                // </span>
              )}
              {!contactLoading && contactList && contactList.length === 0 ? (
                <div className="h-100 d-flex align-items-center justify-content-center">
                  {noContentFoundMessage}
                </div>
              ) : (
                <ContactList
                  contactList={contactList}
                  addFavourite={addFavourite}
                  onContactSelect={onContactSelect}
                />
              )}
            </CustomScrollbars>
          </div>
        </div>
      </div>
      <FormModal />
      <div className="bottom-button btn-group-vertical">
        <Tooltip
          title={"Add " + capitalize(selectedRole)}
          aria-label="Add Contacts"
        >
          <Fab color="primary" aria-label="add" onClick={() => onAddForm()}>
            <i className="zmdi zmdi-account-add zmdi-hc-fw zmdi-hc-lg" />
          </Fab>
        </Tooltip>
      </div>
    </>
  );
};

export default withRouter(Contact);
