import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useForm, Controller } from "react-hook-form";
// import ReactSelect from 'react-select';
import {
  Grid,
  TextField,
  FormControl,
  Container,
  Select,
  MenuItem,
  InputLabel,
  Button,
  DialogActions,
  DialogContent,
} from "@material-ui/core";

import { CheckBoxInput } from "util/formInput/CustomInput";
import AccountCircle from "@material-ui/icons/AccountCircle";

import IntlMessages from "util/IntlMessages";

import DialogTitle from "components/Dialog/DialogTitle";
import getFormTitle from "components/formTitle";

import { saveUser, updateUser } from "../actions";
import { getActiveUsers } from "services/actions";
import { CrudNotification } from "util/helpers";
import { formSchema, formUSchema, initValue } from "./formIndex";

import { AccessGroupsInput } from "util/formInput";
export default function UserForm(props) {
  const dispatch = useDispatch();
  const { formerrorr } = useSelector(({ settingStore }) => settingStore);
  const { accConfig, uiUsers } = useSelector(
    ({ uiConfigureStore }) => uiConfigureStore
  );
  const { isProfile, addView, updateView, user, onClose } = props;
  const [initialVal, setInitialVal] = useState(updateView ? user : initValue);
  const {
    handleSubmit,
    register,
    reset,
    control,
    errors,
    setValue,
    getValues,
    watch,
  } = useForm({
    validationSchema: updateView ? formUSchema : formSchema,
    defaultValues: initialVal,
    mode: "onBlur",
  });
  useEffect(() => {
    dispatch(getActiveUsers());
  }, []);
  const [groups, setGroups] = useState([]);

  const values = getValues();

  const onSubmit = (values) => {
    // console.log(values);
    if (values.id) {
      return dispatch(updateUser(values)).then((res) => {
        CrudNotification("success", "User Updated Successfully !");
        onClose();
      });
    } else {
      return dispatch(saveUser(values)).then((res) => {
        CrudNotification("success", "User Added Successfully !");
        onClose();
      });
    }
  };

  // Process form values before submitting
  const transform = (onSubmitFunc) => (data) => {
    let values = data;
    // if (values.accessGroups) {
    //   values.accessGroups.map((accessGroup, index) => {
    //     if (accessGroup.group === "ALL") {
    //       values.accessGroups.splice(index);
    //     } else {
    //       if (accessGroup.members && accessGroup.members.length !== 0) {
    //         accessGroup.members = accessGroup.members.map((a) =>
    //           a.id ? a.id : a
    //         );
    //         accessGroup.members = [...new Set(accessGroup.members)];
    //         return accessGroup;
    //       }
    //     }
    //   });
    // }
    if (groups.length > 0) {
      values.accessGroups = groups.map((group) => {
        return group.key;
        // return { group: group.key };
      });
    } else {
      values.accessGroups = ["DEFAULT"];
    }

    onSubmitFunc(values);
  };
  return (
    <>
      <DialogTitle id="contact-form" onClose={onClose}>
        {
          <strong>
            {addView ? "Add User" : "Update  " + user.name + " details "}
          </strong>
        }
      </DialogTitle>
      <DialogContent dividers={true}>
        {/* <form className="form"> */}
        <Container style={{ paddingBottom: 25 }}>
          {updateView && <input ref={register} name="id" type="text" hidden />}
          <Grid container spacing={1} direction="row">
            {/* <Grid item xs={12}>
              <Controller
                as={TextField}
                label={<IntlMessages id="user.form.name" />}
                name="name"
                control={control}
                fullWidth
                margin="normal"
                error={errors.name}
                helperText={errors.name && errors.name.message}
              />
            </Grid> */}
            <Grid item xs={12}>
              <Controller
                as={TextField}
                label={<IntlMessages id="user.form.userId" />}
                name="userId"
                control={control}
                fullWidth
                margin="normal"
                error={errors.userId}
                disabled={updateView}
                helperText={errors.userId && errors.userId.message}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <Controller
                as={TextField}
                label={<IntlMessages id="user.form.password" />}
                name="password"
                control={control}
                fullWidth
                type="password"
                margin="normal"
                error={errors.password}
                helperText={errors.password && errors.password.message}
                defaultValue=""
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                as={TextField}
                label={<IntlMessages id="user.form.passwordConfirmation" />}
                name="passwordConfirmation"
                control={control}
                fullWidth
                type="password"
                margin="normal"
                error={errors.passwordConfirmation}
                helperText={
                  errors.passwordConfirmation &&
                  errors.passwordConfirmation.message
                }
                defaultValue=""
              />
            </Grid> */}
            {!isProfile && (
              <Grid item xs={12}>
                <FormControl fullWidth margin="normal">
                  <InputLabel htmlFor="roles">
                    <IntlMessages id="user.form.role" />
                  </InputLabel>
                  <Controller
                    as={
                      <Select>
                        <MenuItem value="" disabled>
                          {" "}
                          Select Role
                        </MenuItem>
                        {accConfig.userRole &&
                          accConfig.userRole.map((val) => (
                            // {access_groups.map((val) => (
                            <MenuItem value={val.key} key={val.key}>
                              {val.value}
                            </MenuItem>
                          ))}
                      </Select>
                    }
                    multiple
                    name="roles"
                    label={<IntlMessages id="user.form.role" />}
                    control={control}
                    fullWidth
                    defaultValue={["USER"]}
                    error={errors.accessGroups}
                    helperText={
                      errors.accessGroups && errors.accessGroups.message
                    }
                  />
                </FormControl>
              </Grid>
            )}

            {/* <Grid item xs={4}>
            <FormControl fullWidth margin="normal">
              <IntlMessages id="user.form.status" />
              <CheckBoxInput
                name="status"
                value={values.status}
                control={control}
              />
            </FormControl>
          </Grid> */}
          </Grid>
        </Container>
        {!isProfile && (
          <>
            <AccessGroupsInput
              label={<IntlMessages id="user.form.accessGroups" />}
              formVals={initialVal}
              groups={groups}
              setGroups={setGroups}
            />
          </>
        )}
        {/* </form> */}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSubmit(transform(onSubmit))}
        >
          <IntlMessages id="form.save" />
        </Button>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          onClick={() => onClose()}
        >
          <IntlMessages id="form.cancel" />
        </Button>
      </DialogActions>
    </>
  );
}
