import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    CardHeader,
    Tooltip,
    IconButton,
    TextField,
    Container,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Divider,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
} from "@material-ui/core";
import { Edit as EditIcon, Close as CloseIcon } from "@material-ui/icons";

import {
    Area, AreaChart,
    Brush,
    ReferenceLine,
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip as TooltipChart,
    XAxis,
    YAxis,
    LabelList,
    Label,
} from "recharts";

import Autocomplete, {
    createFilterOptions,
} from "@material-ui/lab/Autocomplete";

import Widget from "components/Widget";

import { onSetTest } from "redux/actions/MedicalActions";

import { getInvestigation, getAccLookupBy } from "redux/actions/Setting";
import AlertBox from "components/AlertBox/index";

import { getValuefromLookup, getUniqueArray, displayDate } from "util/helpers";

const CustomTooltip = (props) => {
    let payload = props.payload.length > 0 ? props.payload[0].payload : {};
    // console.log(payload);
    return (
        <div>
            {payload && <p>{displayDate(payload.dateTimeDisplay, "datetimeR")}</p>}
            {payload && <p>{payload.label + ": " + payload.result + payload.unit}</p>}
        </div>
    );
};

const Investigation = (props) => {
    const dispatch = useDispatch();
    const { medTests, testGraph, medTestError } = useSelector(
        ({ medRecordStore }) => medRecordStore
    );
    const { accConfig } = useSelector(({ uiConfigureStore }) => uiConfigureStore);

    const { testgrpName, filteredTests, allTests, testName, } = testGraph;

    const [data, setData] = useState([]);


    useEffect(() => {
        if (accConfig.investigations.length === 0) {
            dispatch(getInvestigation());
        }
    }, []);

    useEffect(() => {
        let datas = filteredTests.filter((item) => {
            item.displaydate = displayDate(item.dateTimeDisplay, "datetimeR");
            return item.resultValue;
        });
        setData(datas.reverse());
    }, [filteredTests]);

    useEffect(() => {
        let grpName = testgrpName;
        // let grpName = testgrpName;
        var datas = Object.keys(medTests).reduce(function (res, v) {
            // console.log(res, v)
            grpName = grpName === "none" ? v : grpName;
            return res.concat(medTests[v]);
        }, []);

        datas.map((val) => {
            val.label = getValuefromLookup(accConfig.investigations, val.testItem);
            val.graphlabel = val.label + "(" + val.unit + ")";
        });
        // console.log(datas);
        var values = {
            ...testGraph,
            testgrpName: grpName,
            testName: "",
            allTests: datas,
            filteredTests: medTests[grpName] ? medTests[grpName] : [],
        };
        dispatch(onSetTest(values));
    }, [medTests, accConfig.investigations]);


    // on Search Change
    const onTestItemChange = (val) => {
        let data = [];
        if (val) {
            data = allTests.filter((item) => item.testItem === val.testItem);
        }
        let values = {
            ...testGraph,
            testName: val,
            testgrpName: "none",
            filteredTests: data,
        };
        dispatch(onSetTest(values));
        return val;
    };

    return (
        <Widget styleName="jr-card-full jr-card-tabs-right jr-card-profile">
            <CardHeader
                title={<>
                    {/* Investigations */}

                    <Autocomplete
                        options={getUniqueArray(allTests, "testItem")}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                            <TextField {...params} label="Test Items" />
                        )}
                        onChange={(event, newValue) => {
                            onTestItemChange(newValue);
                        }}
                    /></>}
            />
            <div className="jr-tabs-content jr-task-list">
                {testName !== "" && data.length > 0 && (
                    <ResponsiveContainer width="100%" height={250}>
                        <AreaChart
                            data={data}
                            margin={{ top: 10, right: 0, left: -25, bottom: 0 }}
                        >
                            <XAxis dataKey="displaydate" padding={{ left: 50, right: 30 }}>
                                {/* <Label
                                    value={testName ? testName.graphlabel : ""}
                                    offset={0}
                                    position="insideBottom"
                                /> */}
                            </XAxis>

                            <YAxis padding={{ top: 30 }} />
                            <CartesianGrid strokeDasharray="3 3" />
                            <TooltipChart />
                            {/* <TooltipChart content={<CustomTooltip />} /> */}
                            <Legend />

                            <ReferenceLine y={0} stroke="#000" />

                            <defs>
                                <linearGradient id="orders" x1="0" y1="0" x2="1" y2="0">
                                    <stop offset="5%" stopColor="#3f51b5" stopOpacity={1} />
                                    <stop offset="95%" stopColor="#ff4081" stopOpacity={1} />
                                </linearGradient>
                            </defs>
                            <Area type="monotone" dataKey="resultValue" strokeWidth={4} stroke="#C24590" fillOpacity={1}
                                fill="url(#orders)"
                                name={testName ? testName.graphlabel : ""}
                            >
                                <LabelList dataKey="resultValue" position="top" />
                            </Area>
                        </AreaChart>
                    </ResponsiveContainer>
                )}
                {(testName === "" || data.length === 0) && (
                    <AlertBox
                        infotype="danger"
                        message={
                            <span>
                                No Test Records Found <br />
                Please Select other Test Items or Add New Tests.
              </span>
                        }
                    />
                )}
            </div>
        </Widget>
    );
};
export default Investigation;
