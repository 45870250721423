import React, { useEffect } from "react";
import { Image, Text, View } from "@react-pdf/renderer";

// Pdf Header detail
export default function PdfHeader(props) {
  const { styles, inPatient } = props;
  return (
    <View style={styles.header} fixed>
      <View style={styles.tableRow}>
        <View style={{ width: "10%" }}>
          <Image
            style={styles.image}
            src={require("assets/images/tu-logo.png")}
          />
        </View>
        <View style={[styles.headerText, { width: "80%" }]}>
          <Text style={{ fontSize: 13 }}>TRIBHUVAN UNIVERSITY </Text>
          <Text style={{ fontSize: 18 }}>TEACHING HOSPITAL</Text>
          <Text style={{ fontSize: 8 }}>Maharajgunj, Kathmandu</Text>
        </View>
      </View>
      <View style={{ width: "10%" }}>
        <Text>&nbsp;</Text>
      </View>
    </View>
  );
}
