import axios from "axios";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

var domain = process.env.REACT_APP_API_LOCAL || "https://api.metta.one/v1";

// axios.defaults.headers.post['Accept'] = 'application/json';
const api = axios.create({
  baseURL: domain,
  // headers: {
  //   'Content-Type': 'application/json'
  // }
});

// http response for request
// add tokens and keys to request headers
api.interceptors.request.use(
  function (config) {
    config.headers["Accept"] = "application/json";
    // config.headers['API-Secret'] = localStorage.apiSecret ? localStorage.apiSecret : null;
    config.headers["API-Key"] = localStorage.mettaApiKey
      ? localStorage.mettaApiKey
      : null;
    NProgress.start();
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// http response Interceptors
// do something after recieving response from api
api.interceptors.response.use(
  (response) => {
    NProgress.done();
    return response;
  },
  (error) => {
    NProgress.done();
    // console.log(error);
    if (!error.response) {
      var error = {
        reponse: {
          data: {
            message: "Network Error",
          },
          statusText: "Network not found",
        },
        status: 503,
        error: true,
      };
      return Promise.reject(error);
    } else if (error.response) {
      switch (error.response.status) {
        case 401:
          // store.dispatch(loginFailure({
          //   code: 401,
          //   msg: error.response.data
          // }));
          break;
        default:
          break;
      }
      // console.log(JSON.stringify(error));//console : Error: Request failed with status code 402
      return Promise.reject(error);
    }
  }
);
const AWSFileApi = axios.create({
  // baseURL:'',
  // headers: {
  //   'Content-Type' : 'application/json'
  // }
});
export { api, AWSFileApi };
