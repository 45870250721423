import * as yup from "yup";
import moment from "moment";
import { getDefaultUnit } from "util/helpers";
export const initMedRecordVal = {
  bloodType: "",
  patientId: null,
  height: {
    date: null,
    height: null,
    heightText: "00/00",
    unit: "IN",
    feet: 0,
    inch: 0,
  },
  weight: {
    date: null,
    weight: null,
    unit: "KG",
  },
  diagnosis: {
    date: null,
    diagnosis: "",
    diagnosisType: "PROVISIONAL",
    icd10: "",
    diagnosedBy: "",
  },
  bloodSugar: {
    date: null,
    type: "",
    unit: "MG_DL",
    value: "",
    note: "",
  },
  oxygenSaturation: {
    date: null,
    oxygenSaturation: "",
  },
  doNotResuscitate: {
    date: null,
    doNotResuscitate: true,
    orderedBy: "",
    note: "",
  },
  allergyHistory: [
    {
      allergy: "",
      severity: "",
      current: true,
    },
  ],
  personalHistory: [
    {
      name: "",
      current: true,
      note: "",
    },
  ],
  familyHistory: [
    {
      name: "",
      current: false,
      relative: "",
      note: "",
    },
  ],
  surgeryHistory: [
    {
      name: "",
      year: null,
    },
  ],
  drugHistory: [
    {
      name: "",
      dosage: "",
      current: true,
      note: "",
    },
  ],
};

export const medRecordSchema = yup.object().shape({
  // patientId: yup.string().required(),
  // bloodType: yup.string().required().default(null).nullable(),
  height: yup
    .object()
    .default(null)
    .nullable()
    .shape({
      // date: yup.date().required('Date is required'),
      date: yup
        .string()
        .required("Date is required")
        .nullable(),
      feet: yup.number().when("unit", {
        is: "IN",
        then: yup
          .number("Feet must be number")
          .integer("Feer can't include a decimal point")
          .min(0, "Feet can't be Less than 0")
          .max(10, "Height Feet cannot be more than 10 feet")
          .required("Feet is required")
          .typeError("Feet Should be numerical")
          .nullable(),
      }),
      inch: yup.number().when("unit", {
        is: "IN",
        then: yup
          .number("Inch must be number")
          .integer("Inch can't include a decimal point")
          .min(0, "Inches can't be Less than 0")
          .max(12, "Inches cannot be more than 12")
          .required("Inches is required")
          .typeError("Inches Should be numerical")
          .nullable(),
      }),

      height: yup.string().when("unit", {
        is: "CM",
        then: yup
          .number("Height should be Number")
          .min(0, "Height can't be Less than 0")
          .required("Height is required.")
          .typeError("Height Should be numerical")
          .nullable(),
      }),
      // unit: yup.string().required('Unit is required')
    }),
  weight: yup
    .object()
    .default(null)
    .nullable()
    .shape({
      // date: yup.date().required('Date is required'),
      // date: yup.string().required('Date is required'),
      weight: yup
        .number("Weight should be Number")
        .positive("Weight can't be Less than 0")
        .required("Weight is required.")
        .typeError("Weight Should be numerical")
        .nullable(),
      // unit: yup.string().required('Unit is required')
    }),
  diagnosis: yup
    .object()
    .default(null)
    .nullable()
    .shape({
      date: yup.string().required("Date is required"),
      diagnosis: yup.string().required("Diagnosis is required"),
      diagnosisType: yup.string().required("Diagnosis Type is required"),
      diagnosedBy: yup.string().required("Diagnosed By is required"),
    }),

  bloodSugar: yup
    .object()
    .default(null)
    .nullable()
    .shape({
      // date: yup.date(),
      // .required('Date is required'),
      date: yup.string(),
      // .required("Date is required"),
      type: yup.string(),
      // .required("Type is required"),
      // unit: yup.string(),
      // .required("Unit is required"),
      // value: yup.number('Value must be number'),
      // .required('Value is required'),
      value: yup.string(),
      // .required("Value is required"),
      //   note: yup.string().required("Note is required"),
    }),
  oxygenSaturation: yup
    .object()
    .default(null)
    .nullable()
    .shape({
      // date: yup.date(),
      // .required('Date is required'),
      date: yup.string(),
      // .required("Date is required"),
      oxygenSaturation: yup.string(),
      // .required("OxygenSaturation is required"),
    }),
  doNotResuscitate: yup
    .object()
    .default(null)
    .nullable()
    .shape({
      // date: yup.date().required('Date is required'),
      date: yup.string().required("Date is required"),
      doNotResuscitate: yup.bool().required("Do Not Resuscitate is required"),
      orderedBy: yup.string().required("Ordered By is required"),
      note: yup.string().required("Note is required"),
    }),
  allergyHistory: yup.array().of(
    yup.object().shape({
      current: yup.boolean(),
      severity: yup.string().required("Severity is required"),
      allergy: yup.string().required("Allergy  is required"),
    })
  ),
  personalHistory: yup.array().of(
    yup.object().shape({
      current: yup.boolean(),
      name: yup.string().required("Name is required"),
      // note: yup.string().required("Note  is required"),
    })
  ),
  familyHistory: yup.array().of(
    yup.object().shape({
      current: yup.boolean(),
      relative: yup.string().required("Relative is required"),
      name: yup.string().required("Name is required"),
      // note: yup.string().required("Note  is required"),
    })
  ),
  surgeryHistory: yup.array().of(
    yup.object().shape({
      date: yup.string().required("date is required"),
      performedBy: yup.string().required("Performed By is required"),
      name: yup.string().required("Name is required"),
      // note: yup.string().required("Note  is required"),
    })
  ),
  drugHistory: yup.array().of(
    yup.object().shape({
      current: yup.boolean(),
      dosage: yup.string().required("Performed By is required"),
      name: yup.string().required("Name is required"),
      // note: yup.string().required("Note  is required"),
    })
  ),
});

export const medRecordSchemaAdd = yup.object().shape({
  // patientId: yup.string().required(),
  // bloodType: yup.string().required().default(null).nullable(),
  height: yup
    .object()
    .default(null)
    .nullable()
    .shape({
      // date: yup.date().required('Date is required'),
      date: yup
        .string()
        .required("Date is required")
        .nullable(),
      feet: yup.number().when("unit", {
        is: "IN",
        then: yup
          .number("Feet must be number")
          .integer("Feer can't include a decimal point")
          .min(0, "Feet can't be Less than 0")
          .max(10, "Height Feet cannot be more than 10 feet")
          .required("Feet is required")
          .typeError("Feet Should be numerical")
          .nullable(),
      }),
      inch: yup.number().when("unit", {
        is: "IN",
        then: yup
          .number("Inch must be number")
          .integer("Inch can't include a decimal point")
          .min(0, "Inches can't be Less than 0")
          .max(12, "Inches cannot be more than 12")
          .required("Inches is required")
          .typeError("Inches Should be numerical")
          .nullable(),
      }),

      height: yup.string().when("unit", {
        is: "CM",
        then: yup
          .number("Height should be Number")
          .min(0, "Height can't be Less than 0")
          .required("Height is required.")
          .typeError("Height Should be numerical")
          .nullable(),
      }),
      // unit: yup.string().required('Unit is required')
    }),
  weight: yup
    .object()
    .default(null)
    .nullable()
    .shape({
      // date: yup.date().required('Date is required'),
      // date: yup.string().required('Date is required'),
      weight: yup
        .number("Weight should be Number")
        .positive("Weight can't be Less than 0")
        .required("Weight is required.")
        .typeError("Weight Should be numerical")
        .nullable(),
      // unit: yup.string().required('Unit is required')
    }),
  bloodPressure: yup
    .object()
    .default(null)
    .nullable()
    .shape({
      // date: yup.string().required('Date is required'),
      // time: yup.string().required('Time is required'),
      systolicPressure: yup
        .number("Systolic Pressure should be Number")
        .positive("Systolic Pressure can't be Less than 0")
        .required("Systolic Pressure is required.")
        .typeError("Systolic Pressure Should be numerical")
        .nullable(),
      diastolicPressure: yup
        .number("Diastolic Pressure should be Number")
        .positive("Diastolic Pressure can't be Less than 0")
        .required("Diastolic Pressure is required.")
        .typeError("Diastolic Pressure Should be numerical")
        .nullable(),
    }),

  oxygenSaturation: yup
    .object()
    .default(null)
    .nullable()
    .shape({
      // date: yup.string().required('Date is required'),
      // time: yup.string().required('Time is required'),
      oxygenSaturation: yup
        .number("Oxygen Saturation % should be Number")
        .positive("Oxygen Saturation % can't be Less than 0")
        .required("Oxygen Saturation % is required.")
        .typeError("Oxygen Saturation % Should be numerical")
        .nullable(),
    }),
});
