import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import { Button, Zoom } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Alert } from "reactstrap";

import Auxiliary from "util/Auxiliary";
import AlertBox from "components/AlertBox/index";

import {
  AllergyHistory,
  SurgeryHistory,
  FamilyHistory,
  // DrugHistory,
  PersonalHistory,
  Diagnosis,
  // MedicalNotes,
} from "./MedHistory";

// Actions
import {
  onAddMedicationForm,
  getTestDetailByPId,
} from "redux/actions/MedicalActions";
import { setProfileTitle } from "redux/actions/Setting";
import Medications from "../Medications";
import PersonalDetailBox from "./PersonalDetailBox";
import Widget from "components/Widget";

const MedicalRecord = (props) => {
  const dispatch = useDispatch();
  const { medicalrecord } = useSelector(({ medRecordStore }) => medRecordStore);
  const { contact, contactLoading } = useSelector(
    ({ contactStore }) => contactStore
  );
  const onAddMedicalRecord = () => {
    dispatch(onAddMedicationForm("add"));
  };
  useEffect(() => {
    dispatch(setProfileTitle({ id: 2, title: "Medical History" }));
  }, []);
  return (
    <Auxiliary>
  <Widget styleName="jr-card-full jr-card-tabs-right jr-card-profile">
    <div>
          <div className="jr-tabs-content jr-task-list">
        {contact && !contact.id && <Skeleton variant="rect" height={100} />}
        {contact && contact.id && medicalrecord.id && <PersonalDetailBox/>}
      </div>
          </div>
      </Widget>

      {!medicalrecord.id && (
        <AlertBox
          infotype="danger"
          message="No Medical Record Found. Click Add"
          onClick={onAddMedicalRecord}
        />
      )}

     
      {medicalrecord.id && (
        <div className="row">
  

       
          <div className="col-xl-6 col-lg-12 col-md-12 col-12">
            <Diagnosis />
            <AllergyHistory />
            <Medications/>
          </div>
          <div className="col-xl-6 col-lg-12 col-md-12 col-12">
            <PersonalHistory />
            <FamilyHistory />
            <SurgeryHistory />
          </div>
        </div>
      )}
    </Auxiliary>
  );
};

export default withRouter(MedicalRecord);
