import React from "react";
import {
  Document,
  Image,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import moment from "moment";
import HZline from "./hzLine.png";
function Report({ JSONData }) {
  // Create styles
  console.log("JSONData", JSONData);
  const styles = StyleSheet.create({
    ol: {
      margin: "0",
      padding: "0",
    },
    table_td: {
      padding: "0",
    },
    table_th: {
      padding: "0",
    },
    c11: {
      color: "#000000",
      fontWeight: "400",
      textDecoration: "none",
      verticalAlign: "baseline",
      fontSize: "18pt",
      //  fontFamily: '"Helvetica Neue"',
      fontStyle: "normal",
    },
    c14: {
      color: "#263391",
      fontWeight: "400",
      textDecoration: "none",
      verticalAlign: "baseline",
      fontSize: "11pt",
      //   fontFamily: '"Helvetica Neue"',
      fontStyle: "normal",
    },
    c2: {
      color: "#000000",
      fontWeight: "400",
      textDecoration: "none",
      verticalAlign: "baseline",
      fontSize: "12pt",
      //   fontFamily: '"Times New Roman"',
      fontStyle: "normal",
    },
    c4: {
      color: "#000000",
      fontWeight: "400",
      textDecoration: "none",
      verticalAlign: "baseline",
      fontSize: "16pt",
      //   fontFamily: '"Times New Roman"',
      fontStyle: "normal",
    },
    c8: {
      paddingTop: "0pt",
      paddingBottom: "0pt",
      lineHeight: "1.0",
      orphans: "2",
      widows: "2",
      textAlign: "left",
      height: "12pt",
    },
    c10: {
      color: "#000000",
      fontWeight: "400",
      textDecoration: "none",
      verticalAlign: "baseline",
      fontSize: "12pt",
      //   fontFamily: '"Calibri"',
      fontStyle: "normal",
    },
    c1: {
      color: "#000000",
      fontWeight: "700",
      textDecoration: "none",
      verticalAlign: "baseline",
      fontSize: "12pt",
      //   fontFamily: '"Times New Roman"',
      fontStyle: "normal",
    },
    c12: {
      paddingTop: "0pt",
      paddingBottom: "0pt",
      lineHeight: "1.0",
      orphans: "2",
      widows: "2",
      textAlign: "left",
    },
    c18: {
      paddingTop: "0pt",
      paddingBottom: "4pt",
      lineHeight: "1.0",
      orphans: "2",
      widows: "2",
      textAlign: "center",
    },
    c7: {
      paddingTop: "4pt",
      paddingBottom: "14pt",
      lineHeight: "1.0",
      orphans: "2",
      widows: "2",
      textAlign: "center",
    },
    c0: {
      paddingTop: "10pt",
      paddingBottom: "10pt",
      lineHeight: "1.0",
      orphans: "2",
      widows: "2",
      textAlign: "left",
    },
    c6: {
      fontSize: "11pt",
      //   fontFamily: '"Helvetica Neue"',
      color: "#666666",
      fontWeight: "400",
      width: "80pt",
    },
    c006: {
      fontSize: "11pt",
      //   fontFamily: '"Helvetica Neue"',
      color: "#666666",
      fontWeight: "400",
    },
    c06: {
      fontSize: "11pt",
      //   fontFamily: '"Helvetica Neue"',
      color: "#666666",
      fontWeight: "400",
      width: "70pt",
    },
    c9: {
      fontSize: "11pt",
      //   fontFamily: '"Helvetica Neue"',
      color: "#666666",
      fontWeight: "700",
      width: "50pt",
    },
    c09: {
      fontSize: "11pt",
      //   fontFamily: '"Helvetica Neue"',
      color: "#666666",
      fontWeight: "700",
      width: "100pt",
    },
    c15: {
      fontSize: "20pt",
      //   fontFamily: '"Helvetica Neue"',
      color: "#6b63e5",
      fontWeight: "700",
    },
    c21: {
      color: "#dd1982",
      fontWeight: "400",
      fontSize: "11pt",
      //   fontFamily: '"Helvetica Neue"',
    },
    c3: {
      fontSize: "16pt",
      //   fontFamily: '"Helvetica Neue"',
      color: "#263391",
      fontWeight: "400",
    },
    c19: {
      textDecoration: "none",
      verticalAlign: "baseline",
      fontStyle: "normal",
    },
    c16: {
      fontSize: "14pt",
      //   fontFamily: '"Helvetica Neue"',
      fontWeight: "400",
    },
    c016: {
      fontSize: "12pt",
      //   fontFamily: '"Helvetica Neue"',
      fontWeight: "400",
    },
    c5: {
      fontSize: "16pt",
      //   fontFamily: '"Helvetica Neue"',
      fontWeight: "400",
    },
    c013: {
      backgroundColor: "#ffffff",
      // maxWidth: "451pt",
      padding: "0pt 0pt 10pt 0pt",
    },
    c0013: {
      backgroundColor: "#ffffff",
      // maxWidth: "451pt",
      paddingTop: "0pt",
      paddingBottom: "10pt",
    },
    c13: {
      backgroundColor: "#ffffff",
      // maxWidth: "451pt",
      padding: "48.9pt 72pt 37pt 72pt",
    },
    c20: {
      fontWeight: "400",
      //   fontFamily: '"Times New Roman"',
    },
    c17: {
      color: "#6b63e5",
    },
    title: {
      paddingTop: "24pt",
      color: "#000000",
      fontWeight: "700",
      fontSize: "36pt",
      paddingBottom: "6pt",
      //   fontFamily: '"Calibri"',
      lineHeight: "1.0",
      pageBreakAfter: "avoid",
      orphans: "2",
      widows: "2",
      textAlign: "left",
    },
    subtitle: {
      paddingTop: "18pt",
      color: "#666666",
      fontSize: "24pt",
      paddingBottom: "4pt",
      //   fontFamily: '"Georgia"',
      lineHeight: "1.0",
      pageBreakAfter: "avoid",
      fontStyle: "italic",
      orphans: "2",
      widows: "2",
      textAlign: "left",
    },
    li: {
      color: "#000000",
      fontSize: "12pt",
      //   fontFamily: '"Calibri"',
    },
    p: {
      margin: "0",
      color: "#000000",
      fontSize: "12pt",
      //   fontFamily: '"Calibri"',
    },
    h1: {
      paddingTop: "24pt",
      color: "#000000",
      fontWeight: "700",
      fontSize: "24pt",
      paddingBottom: "6pt",
      //   fontFamily: '"Calibri"',
      lineHeight: "1.0",
      pageBreakAfter: "avoid",
      orphans: "2",
      widows: "2",
      textAlign: "left",
    },
    h2: {
      paddingTop: "18pt",
      color: "#000000",
      fontWeight: "700",
      fontSize: "18pt",
      paddingBottom: "4pt",
      //   fontFamily: '"Calibri"',
      lineHeight: "1.0",
      pageBreakAfter: "avoid",
      orphans: "2",
      widows: "2",
      textAlign: "left",
    },
    h3: {
      paddingTop: "14pt",
      color: "#000000",
      fontWeight: "700",
      fontSize: "14pt",
      paddingBottom: "4pt",
      //   fontFamily: '"Calibri"',
      lineHeight: "1.0",
      pageBreakAfter: "avoid",
      orphans: "2",
      widows: "2",
      textAlign: "left",
    },
    h4: {
      paddingTop: "12pt",
      color: "#000000",
      fontWeight: "700",
      fontSize: "12pt",
      paddingBottom: "2pt",
      //   fontFamily: '"Calibri"',
      lineHeight: "1.0",
      pageBreakAfter: "avoid",
      orphans: "2",
      widows: "2",
      textAlign: "left",
    },
    h5: {
      paddingTop: "11pt",
      color: "#000000",
      fontWeight: "700",
      fontSize: "11pt",
      paddingBottom: "2pt",
      //   fontFamily: '"Calibri"',
      lineHeight: "1.0",
      pageBreakAfter: "avoid",
      orphans: "2",
      widows: "2",
      textAlign: "left",
    },
    h6: {
      paddingTop: "10pt",
      color: "#000000",
      fontWeight: "700",
      fontSize: "10pt",
      paddingBottom: "2pt",
      //   fontFamily: '"Calibri"',
      lineHeight: "1.0",
      pageBreakAfter: "avoid",
      orphans: "2",
      widows: "2",
      textAlign: "left",
    },
    inline: {
      display: "flex",
      flexDirection: "row",
    },
    alignItemsCenter: {
      alignItems: "center",
    },
    center: {
      justifyContent: "center",
    },
    ml1: {
      marginLeft: "5pt",
    },
    ml2: {
      marginLeft: "10pt",
    },
    minHeight: {
      // minHeight: "120pt",
    },
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: "72pt",
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
    mt1: { marginTop: "5pt" },
    mt2: {
      marginTop: "22pt",
    },
  });

  {
    /* <PDFViewer width={1000} height={1200}> */
  }
  return (
    <Document size>
      <Page size="A4" style={styles.body}>
        <View fixed style={styles.c0013}>
          <View style={styles.c18}>
            <Text style={styles.c15}>
              {/* Patient Name */}
              {JSONData?.accountData?.accountName}
            </Text>
          </View>
          <View style={styles.c7}>
            <Text style={styles.c016}>
              {JSONData?.accountData?.phone
                ? `${JSONData?.accountData?.phone}, `
                : ""}
              {JSONData?.accountData?.address?.address
                ? `${JSONData?.accountData?.address?.address}, `
                : ""}
              {JSONData?.accountData?.address?.city
                ? JSONData?.accountData?.address?.city
                : ""}
            </Text>
          </View>
          <View style={{ ...styles.inline, ...styles.center }}>
            <Text style={styles.c3}>Patient Visit Summary</Text>
          </View>
          <View style={{ ...styles.inline, ...styles.center }}>
            <Text
              style={{
                ...styles.c21,
              }}
            >
              {moment
                .utc(JSONData?.visitData?.startDateTime)
                .local()
                .format("MMMM Do YYYY, h:mm A")}
            </Text>
          </View>
          <View style={{ marginTop: "4pt" }}>
            <Image src={HZline} />
          </View>
        </View>
        <View style={styles.c013}>
          {/* <View>
            <Text style={styles.company}>Your Company</Text>
          </View> */}
          <View style={styles.c0}>
            <Text style={styles.c5}>Patient Information:</Text>
          </View>
          <View
            style={{
              ...styles.inline,
              ...styles.c0,
              ...styles.alignItemsCenter,
            }}
          >
            <Text style={{ ...styles.c16, ...styles.c17 }}>
              {JSONData?.visitData?.patientName?.toUpperCase()}
            </Text>
            <Text style={{ ...styles.ml1, ...styles.c006 }}>
              (Patient ID: {JSONData?.visitData?.patientId})
            </Text>
          </View>

          <View style={{ ...styles.c0, ...styles.c14 }}>
            {JSONData?.visitData?.visitId && (
              <View>
                <Text>
                  {/* [checkin time] - [checkout time] */}
                  ID# {`${JSONData?.visitData?.visitId}`}
                </Text>
              </View>
            )}
            {JSONData?.visitData?.visitId && (
              <View>
                <Text>
                  {/* [checkin time] - [checkout time] */}
                  Visit With{" "}
                  {`${JSONData?.visitData?.assignedName?.toUpperCase()}`}
                </Text>
              </View>
            )}
          </View>

          <View style={{ ...styles.c0, ...styles.c5 }}>
            <Text>Vitals:</Text>
          </View>
          <View style={{ ...styles.c0, ...styles.inline }}>
            {/* Height (ft/in):5/3  */}
            <Text style={{ ...styles.c6 }}>Height (ft/in): </Text>
            <Text style={{ ...styles.c9 }}>
              {" "}
              {JSONData?.visitData?.vitals?.height?.heightText}
            </Text>
            {/* Weight (kg): 55 */}
            <Text style={{ ...styles.c6 }}>Weight (kg): </Text>
            <Text style={{ ...styles.c9 }}>
              {JSONData?.visitData?.vitals?.weight?.weight}
            </Text>
            {/* BMI:  */}
            <Text style={{ ...styles.c6 }}>BMI: </Text>
            <Text style={{ ...styles.c9 }}>
              {JSONData?.visitData?.vitals?.bmi}
            </Text>
            {/* Temperature:  */}
            <Text style={{ ...styles.c6 }}>Temperature: </Text>
            <Text style={{ ...styles.c9 }}>
              {JSONData?.visitData?.vitals?.Temp?.temp}
            </Text>
            {/* BMI: Temperature: Blood Pressure: Respiration Rate: Pulse:  O2 Saturation:96% */}
          </View>
          <View style={{ ...styles.c0, ...styles.inline }}>
            {/* Height (ft/in):5/3  */}
            <Text style={{ ...styles.c6 }}>Blood Pressure: </Text>
            <Text style={{ ...styles.c9 }}>
              {JSONData?.visitData?.vitals?.bloodPressure?.systolicPressure}
              {JSONData?.visitData?.vitals?.bloodPressure?.diastolicPressure
                ? `/${JSONData?.visitData?.vitals?.bloodPressure?.diastolicPressure}`
                : ""}
            </Text>
            {/* Weight (kg): 55 */}
            <Text style={{ ...styles.c6 }}>Respiration Rate: </Text>
            <Text style={{ ...styles.c9 }}>
              {JSONData?.visitData?.vitals?.respirationRate?.rate
                ? `${JSONData?.visitData?.vitals?.respirationRate?.rate}/min`
                : ""}
            </Text>
            {/* BMI:  */}
            <Text style={{ ...styles.c6 }}>Pulse: </Text>
            <Text style={{ ...styles.c9 }}>
              {JSONData?.visitData?.vitals?.pulse?.count
                ? `${JSONData?.visitData?.vitals?.pulse?.count}/min`
                : ""}
            </Text>
            {/* Temperature:  */}
            <Text style={{ ...styles.c6 }}>O2 Saturation: </Text>
            <Text style={{ ...styles.c9 }}>
              {JSONData?.visitData?.vitals?.oxygenSaturation?.oxygenSaturation
                ? `${JSONData?.visitData?.vitals?.oxygenSaturation?.oxygenSaturation}%`
                : ""}
            </Text>
            {/* BMI: Temperature: Blood Pressure: Respiration Rate: Pulse:  O2 Saturation:96% */}
          </View>
          <View style={{ ...styles.c0, ...styles.c5 }}>
            <Text>Chief Compaints:</Text>
          </View>
          <View style={{ ...styles.c0, ...styles.inline }}>
            <Text style={{ ...styles.c006 }}>
              {JSONData?.visitData?.interview}
            </Text>
          </View>
          {JSONData?.visitData?.observations ? (
            <View>
              <View style={{ ...styles.c0, ...styles.c5 }}>
                <Text>Investigations:</Text>
              </View>
              <View
                style={{ ...styles.c0, ...styles.inline, ...styles.minHeight }}
              >
                <Text
                  style={{ ...styles.c006 }}
                >{`${JSONData?.visitData?.observations}`}</Text>
              </View>
            </View>
          ) : (
            <View />
          )}
          {JSONData?.visitData?.prescriptions ? (
            <View>
              <View style={{ ...styles.c0, ...styles.c5 }}>
                <Text>Pescription:</Text>
              </View>
              <View style={{ ...styles.c0 }}>
                <View style={{ ...styles.c006 }}>
                  {JSONData?.visitData?.prescriptions?.map(
                    (prescription, i) => {
                      return (
                        <View style={{ ...styles.inline }}>
                          <Text>{i + 1}.</Text>
                          <Text style={styles.ml1}>{prescription?.name}</Text>
                          <Text style={styles.ml1}>{prescription?.type}</Text>
                          <Text style={styles.ml1}>{prescription?.dosage}</Text>
                        </View>
                      );
                    }
                  )}
                </View>
              </View>
            </View>
          ) : (
            <View />
          )}

          <View>
            {JSONData?.visitData?.note ? (
              <View>
                <View style={{ ...styles.c0, ...styles.c5 }}>
                  <Text>Note Advices:</Text>
                </View>
                <View style={{ ...styles.c0, ...styles.inline }}>
                  <Text style={{ ...styles.c006 }}>
                    {JSONData?.visitData?.note}
                  </Text>
                </View>
              </View>
            ) : (
              <View />
            )}

            <View style={{ ...styles.c0, ...styles.c5 }}>
              <Text>Followup:</Text>
            </View>
            <View style={{ ...styles.c0, ...styles.inline }}>
              <Text style={{ ...styles.c6 }}>Followup with:</Text>
              <Text style={{ ...styles.c006 }}>
                {JSONData?.visitData?.followupAssignedName?.toUpperCase()}
              </Text>
            </View>

            <View style={{ ...styles.c0, ...styles.inline }}>
              <Text style={{ ...styles.c6 }}>Date/time:</Text>
              <Text style={{ ...styles.c006 }}>
                {moment(JSONData?.visitData?.followupDateTime)?.format(
                  "MMMM Do YYYY, h:mm A"
                )}
              </Text>
            </View>
            {JSONData?.visitData?.reviewedBy ? (
              <View wrap={false}>
                <View style={{ ...styles.c0, ...styles.c5, ...styles.mt1 }}>
                  <Text>Reviewed By:</Text>
                </View>
                <View style={{ ...styles.c0, ...styles.mt2 }}>
                  <Text style={{ ...styles.c6 }}>
                    ..............................
                  </Text>
                  <Text style={{ ...styles.c9 }}>
                    {JSONData?.visitData?.reviewedBy}
                  </Text>
                </View>
              </View>
            ) : (
              <View />
            )}
          </View>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
}

export default Report;
