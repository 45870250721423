import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from "react-hook-form";

import {
  Grid,
  TextField,
  FormControl,
  Container,
  CardHeader,
} from "@material-ui/core";

import { DateTimeInput,  } from "util/formInput";

import IntlMessages from "util/IntlMessages";
import { getDefaultUnit } from "util/helpers";

export default function HtWtForm(props) {
  const {
    control,
    setValue,
    getValues,
    errors,
    register,
    watch,
    updateMedicalFor,
  } = props;
  const { accConfig} = useSelector(({ uiConfigureStore }) => uiConfigureStore);
  const { accountDefaultUnits   } = useSelector(({ configStore }) => configStore);
  const { medicalrecord } = useSelector(({ medRecordStore }) => medRecordStore);
  const watchHeight = watch("height");
  const values = getValues({ nest: true });

  const [feet, setFeet] = useState(0);
  const [inch, setInch] = useState();

  useEffect(() => {
    // console.log(medicalrecord);
    let heightText = medicalrecord.height
      ? medicalrecord.height.heightText
      : null;
    let height = medicalrecord.height ? medicalrecord.height.height : null;

    if (getDefaultUnit(accConfig.heightUnit, "key")) {
      setValue(`height.unit`, getDefaultUnit(accConfig.heightUnit, "key"));
      if (heightText) {
        const heights = heightText
          .split("/")
          .filter((x) => x.trim().length && !isNaN(x))
          .map(Number);
        setFeet(heights[0]);
        setInch(heights[1]);
        setValue(`height.feet`, heights[0]);
        setValue(`height.inch`, heights[1]);
      }
      setValue(`height.height`, height);
    }

    if (accountDefaultUnits.weightUnit === 'lb') {
      let weight = medicalrecord.weight ? medicalrecord.weight.weightInLbs : null;
      setValue(`weight.weight`, weight);
    }



  }, []);

  return (
    <div>
      {updateMedicalFor !== "heightWeight" && (
        <CardHeader title={<h2>Height / Weight</h2>} />
      )}
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth margin="normal">
              <DateTimeInput
                value={values.height && values.height.date}
                label="Date"
                name="height.date"
                format="YYYY-MM-DD"
                inputRef={register}
                fullWidth
                autoOk
              />
            </FormControl>
          </Grid>
          <input
            ref={register}
            name={`height.unit`}
            type="text"
            hidden
            defaultValue={getDefaultUnit(accConfig.heightUnit, "key")}
          />


          <input ref={register} name={`height.heightText`} type="text" hidden />

          {watchHeight && watchHeight.unit === "IN" && (
            <Grid item xs={6} sm={4}>
              <Grid container spacing={1}>
                <Grid item xs={6} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <Controller
                      label={
                        <span>
                          <IntlMessages id="medicRecord.form.height" />
                          {"  "}
                          <IntlMessages id="medicRecord.form.feet" />
                        </span>
                      }
                      as={TextField}
                      name="height.feet"
                      control={control}
                      defaultValue={feet}
                      type="number"
                      fullWidth
                      error={errors.height && errors.height.feet}
                      helperText={
                        errors.height &&
                        errors.height.feet &&
                        errors.height.feet.message
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <Controller
                      label={
                        <span>
                          <IntlMessages id="medicRecord.form.inch" />
                        </span>
                      }
                      as={TextField}
                      name="height.inch"
                      control={control}
                      defaultValue={inch}
                      type="number"
                      fullWidth
                      error={errors.height && errors.height.inch}
                      helperText={
                        errors.height &&
                        errors.height.inch &&
                        errors.height.inch.message
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          )}
          {watchHeight && watchHeight.unit === "CM" && (
            <Grid item xs={6} sm={3}>
              <FormControl fullWidth margin="normal">
                <Controller
                  label={
                    <span>
                      <IntlMessages id="medicRecord.form.height" /> (
                      {getDefaultUnit(accConfig.heightUnit)})
                    </span>
                  }
                  as={TextField}
                  name="height.height"
                  control={control}
                  // type="number"
                  fullWidth
                  error={errors.height && errors.height.height}
                  helperText={
                    errors.height &&
                    errors.height.height &&
                    errors.height.height.message
                  }
                />
              </FormControl>
            </Grid>
          )}
          <Grid item sm={4} xs={6}>
            <FormControl fullWidth margin="normal">
              <Controller
                label={
                  <span>
                    <IntlMessages id="medicRecord.form.weight" /> (
                    {getDefaultUnit(accConfig.weightUnit)})
                  </span>
                }
                as={TextField}
                name="weight.weight"
                control={control}
                type="number"
                fullWidth
                error={errors.weight && errors.weight.weight}
                helperText={
                  errors.weight &&
                  errors.weight.weight &&
                  errors.weight.weight.message
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
