import * as yup from "yup";
import moment from "moment";
export const initVisitVal = {
  description: "",
  startDateTime: null,
  assignedId: "",
  department: "",
  unit: "",
  room: "",
};

export const formSchema = yup.object().shape({
  description: yup.string().required("Description is Required"),
  startDateTime: yup.mixed().required("Start Date is Required"),
  assignedId: yup.mixed().required("Assigned Name is required"),
  department: yup.mixed().required("Department is required"),
  unit: yup.mixed().required("Unit is required"),
  // room: yup.string().required("Room is required"),

  // subject: yup.string().required("Subject is Required"),
  // accessGroups: yup
  //   .array()
  //   // .min(1, 'Atleast 1 Address is Required')
  //   .of(
  //     yup.object().shape({
  //       group: yup.string(),
  //       members: yup.string(),
  //     })
  //   ),
});
