import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import CustomScrollbars from "util/CustomScrollbars";
import { getColor, displayDate, getValuefromLookup } from "util/helpers";
import { onNoteForm, onNoteClose } from "../actions";

const NoteDetail = (props) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(undefined);
  const [open, setOpen] = useState(false);

  const { uiUsers, accConfig } = useSelector(
    ({ uiConfigureStore }) => uiConfigureStore
  );
  const { note, width } = props;
  const { accessGroups, tags, subject } = note;
  const handleClick = (event) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleRequestClose = () => {
    setOpen(false);
  };

  // onCloseNoteView Modal
  const onClose = () => {
    dispatch(onNoteClose());
  };
  // on Update
  const onMenuSelect = (option) => {
    option === "Update" && dispatch(onNoteForm(note, "update"));
  };

  const options = ["Update", "Delete"];
  const getUserName = (memberId) => {
    let member = uiUsers.find((item) => item.id === memberId);
    return member;
  };
  return (
    <div className="module-detail mail-detail">
      <CustomScrollbars
        className="module-list-scroll scrollbar"
        style={{
          height: width >= 1200 ? "calc(100vh - 309px)" : "calc(100vh - 288px)",
        }}
      >
        <div className="mail-header">
          <div style={{ paddingRight: "15px" }}>
            <IconButton className="icon-btn" onClick={() => onClose()}>
              <i className="zmdi zmdi-arrow-back" />
            </IconButton>
          </div>
          <div className="mail-header-content col pl-0">
            <div className="subject">{subject}</div>

            <div className="labels">
              {tags.map((tag, index) => {
                return (
                  // note.labels.includes(tag) && (
                  <div
                    key={index}
                    className={`badge text-white `}
                    style={{ backgroundColor: getColor(tag) }}
                  >
                    {tag}
                  </div>
                  // )
                );
              })}
            </div>
          </div>
        </div>
        <hr />
        <div className="mail-user-info">
          {note.noteBy && (
            <div
              className="avatar rounded-circle size-40 text-white text-center"
              style={{ fontSize: 16, backgroundColor: getColor(note.noteBy) }}
            >
              {note.noteBy.charAt(0).toUpperCase()}
            </div>
          )}

          <div className="sender-name">
            {note.noteBy}
            <div className="send-to text-grey">
              {accessGroups && accessGroups.length > 0 && (
                <>
                  to{" "}
                  {accessGroups.map((access, index) => (
                    <span>
                      {getValuefromLookup(
                        accConfig.userAccessGroup,
                        access.group
                      )}
                      {accessGroups.length !== index + 1 && ", "}
                    </span>
                  ))}
                  {/* {accessGroups[0].group === "ME_AND_THESE" &&
                    accessGroups[0].members &&
                    accessGroups[0].members.length > 0 && (
                      <p className="mb-0">
                        to Me and{" "}
                        {accessGroups[0].members.map((member, index) => {
                          return (
                            <span key={index}>
                              {getUserName(member)}
                              {accessGroups[0].members !== index + 1 && (
                                <span>, </span>
                              )}
                            </span>
                          );
                        })}
                      </p>
                    )} */}
                </>
              )}
            </div>
          </div>

          <IconButton
            aria-label="More"
            aria-owns={open ? "long-SidenavContent.js" : null}
            aria-haspopup
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>

          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => handleRequestClose()}
            MenuListProps={{
              style: {
                width: 200,
              },
            }}
          >
            {options.map((option) => (
              <MenuItem
                key={option}
                onClose={() => handleRequestClose()}
                onClick={() => onMenuSelect(option)}
              >
                {option}
              </MenuItem>
            ))}
          </Menu>
        </div>

        <p className="mt-2">
          <strong>Date: </strong>
          {displayDate(note.createdAt, "datetime")}
        </p>

        <div
          // className="message"
          dangerouslySetInnerHTML={{ __html: note.note }}
        ></div>
        {/* {note.hasAttachments && (
            <div className="attachment-block">
              <h3>Attachments ({note.hasAttachments.length})</h3>
              <div className="row">
                {note.attachments.map((attachment, index) => (
                  <div className="col-3" key={index}>
                    <img
                      className="size-100"
                      src={attachment.preview}
                      alt={attachment.fileName}
                    />
                    <div className="size">{attachment.size}</div>
                  </div>
                ))}
              </div>
            </div>
          )} */}
      </CustomScrollbars>
    </div>
  );
};

export default NoteDetail;
