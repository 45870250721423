import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "@material-ui/core";

import { Input } from "reactstrap";
import { Skeleton } from "@material-ui/lab";

// Actions
import { getActiveContacts } from "services/actions";
import ContactLists from "./ContactLists";
import ContactOptions from "./ContactOptions";

const ContactStep = (props) => {
  const dispatch = useDispatch();
  const {
    width,
    districts,
    appUiConfig,
    uiClients,
    uiClientsLoading,
  } = useSelector(({ uiConfigureStore }) => uiConfigureStore);
  const { step, formVals, setFormVals, activeStep, setActiveStep } = props;
  const [activeButton, setActiveButton] = useState(1);
  const [filtered, setFiltered] = useState([]);

  useEffect(() => {
    activeButton === 1 && dispatch(getActiveContacts());
  }, [activeButton]);

  useEffect(() => {
    var formValues = { ...formVals };
    if (activeButton !== 1) {
      let vals = uiClients.map((a) => a.clientId);
      formValues = { ...formVals, clientIds: vals };
    } else {
      formValues = { ...formVals, clientIds: [] };
    }
    setFiltered(uiClients);
    setFormVals(formValues);
  }, [uiClients, activeButton]);

  // Input Search
  const onSearch = (e) => {
    let searchStr = e.target.value.toLowerCase();
    const filteredData = uiClients.filter((value) => {
      const nameMatches = value.name.toLowerCase().includes(searchStr);
      const phoneMatches = value.phone
        ? value.phone.toLowerCase().includes(searchStr)
        : false;
      const emailMatches = value.email
        ? value.email.toLowerCase().includes(searchStr)
        : false;
      // const oneItemMatches = value.items.some(item => item.toLowerCase().includes(searchStr));
      return nameMatches || emailMatches || phoneMatches;
    });
    setFiltered(filteredData);
  };

  return (
    <>
      <div className="jr-tabs-content">
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <ContactOptions
              formVals={formVals}
              setFormVals={setFormVals}
              setActiveButton={setActiveButton}
              activeButton={activeButton}
            />
          </Grid>

          <Grid item xs={9}>
            <div style={{ textAlign: "center" }}>
              <Input
                onChange={onSearch}
                placeholder="Search..."
                className="my-2"
                disabled={uiClients.length === 0}
              />
              {formVals.clientIds.length > 0 && (
                <div>
                  You have selected {formVals.clientIds.length} contacts.
                </div>
              )}
            </div>
            {uiClientsLoading === 0 && <Skeleton height={401} />}

            {!uiClientsLoading && filtered.length > 0 && (
              <ContactLists
                filtered={filtered}
                formVals={formVals}
                setFormVals={setFormVals}
              />
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ContactStep;
