import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFieldArray, Controller } from "react-hook-form";

import moment from "moment";
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  InputLabel,
  Divider,
  Container,
  Avatar,
  CardHeader,
  IconButton,
  Paper,
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Home as HomeIcon,
  Add as AddIcon,
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
} from "@material-ui/icons";
import IntlMessages from "util/IntlMessages";
import { countries } from "./formIndex";

function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}

export default function AddressFormArray(props) {
  const { accConfig, districts } = useSelector(
    ({ uiConfigureStore }) => uiConfigureStore
  );
  const {
    control,
    errors,
    register,
    getValues,
    setValue,
    watch,
    updateFormModal,
  } = props;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "addresses",
    keyName: "keyId", //, default to "id", you can change the key name
  });
  const watcht = watch("addresses");
  const onAdd = () => {
    append({
      id: 0,
      addressType: "HOME",
      address1: "",
      address2: null,
      city: "",
      state: "",
      zipCode: "",
      countryCode: "US",
      primary: false,
      useCurrentLocation: true,
    });
  };

  const onRemove = (index) => {
    remove(index);
  };

  const onCityChange = (data, index) => {
    // console.log(data, index);
    if (data && data.province) {
      // setValue("name", "Vikash");
      setValue(`addresses[${index}].state`, data.province);
    } else {
      setValue(`addresses[${index}].state`, "");
    }
    return data;
  };
  // get countries json value
  const getCountry = (countryCode) => {
    let country = countries.find((o) => o.countryCode === countryCode);
    return country;
  };
  // get city json value
  const getDistrict = (city) => {
    let district = districts.find((o) => o.name === city);
    return district;
  };
  // label
  const getCountryLabel = (option) => {
    if (option.name) {
      return option.name;
    } else {
      let country = getCountry(option);
      return country.name;
    }
  };
  const isCountry = (index) => {
    const values = getValues({ nest: true });
    if (
      values.addresses &&
      values.addresses[index] &&
      values.addresses[index].countryCode === "NP"
    ) {
      return true;
    } else if (
      values.addresses &&
      values.addresses[index] &&
      values.addresses[index].countryCode &&
      values.addresses[index].countryCode.countryCode === "NP"
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div style={{ paddingBottom: 25 }}>
      <CardHeader
        avatar={
          <Avatar aria-label="">
            <HomeIcon />
          </Avatar>
        }
        action={
          updateFormModal && (
            <IconButton
              aria-label="Add"
              onClick={onAdd}
              variant="contained"
              color="primary"
            >
              <AddIcon />
            </IconButton>
          )
        }
        title={
          <h2>
            <strong>Addresses</strong>
          </h2>
        }
      />

      {/* Addresses */}
      {fields.map((item, index) => {
        return (
          <div key={item.keyId}>
            <Container style={{ paddingBottom: 25 }}>
              {/* <CardHeader
								action={
									<IconButton
										aria-label="Delete"
										onClick={() => onRemove(index)}
										variant="contained"
										color="secondary"
									>
										<CloseIcon />
									</IconButton>
								}
								title={<h3>Address {index + 1}</h3>}
							/> */}
              <input
                ref={register}
                name={`addresses[${index}].primary`}
                type="text"
                hidden
                defaultValue={index === 0}
              />

              <Grid container spacing={2}>
                <Grid item xs={12} spacing={1}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel htmlFor="addressType">
                      <IntlMessages id="contact.form.addressType" />
                    </InputLabel>
                    <Controller
                      as={
                        <Select>
                          <MenuItem value="" disabled>
                            {" "}
                            Select Address Type
                          </MenuItem>
                          {accConfig.addressType &&
                            accConfig.addressType.map((val) => (
                              <MenuItem value={val.key} key={val.key}>
                                {val.value}
                              </MenuItem>
                            ))}
                        </Select>
                      }
                      name={`addresses[${index}].addressType`}
                      label={<IntlMessages id="contact.form.addressType" />}
                      control={control}
                      fullWidth
                      defaultValue={item.addressType}
                      // margin="normal"
                      error={
                        errors.addresses &&
                        errors.addresses[index] &&
                        errors.addresses[index].addressType
                      }
                      helperText={
                        errors.addresses &&
                        errors.addresses[index] &&
                        errors.addresses[index].addressType &&
                        errors.addresses[index].addressType.message
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    label={
                      <span>
                        {isCountry(index) && <span> Block / Area</span>}
                        {!isCountry(index) && (
                          <span>
                            <IntlMessages id="contact.form.address1" />
                          </span>
                        )}
                      </span>
                    }
                    as={TextField}
                    name={`addresses[${index}].address1`}
                    control={control}
                    fullWidth
                    defaultValue={item.address1}
                    error={
                      errors.addresses &&
                      errors.addresses[index] &&
                      errors.addresses[index].address1
                    }
                    helperText={
                      errors.addresses &&
                      errors.addresses[index] &&
                      errors.addresses[index].address1 &&
                      errors.addresses[index].address1.message
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    // label={<IntlMessages id="contact.form.address2" />}
                    as={TextField}
                    label={
                      <span>
                        {isCountry(index) && (
                          <span>Village / Municipality</span>
                        )}
                        {!isCountry(index) && (
                          <span>
                            <IntlMessages id="contact.form.address2" />
                          </span>
                        )}
                      </span>
                    }
                    name={`addresses[${index}].address2`}
                    control={control}
                    fullWidth
                    defaultValue={item.address2}
                    // margin="normal"
                    error={
                      errors.addresses &&
                      errors.addresses[index] &&
                      errors.addresses[index].address2
                    }
                    helperText={
                      errors.addresses &&
                      errors.addresses[index] &&
                      errors.addresses[index].address2 &&
                      errors.addresses[index].address2.message
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs>
                  {isCountry(index) && (
                    <FormControl
                      fullWidth
                      margin="normal"
                      error={
                        errors.addresses &&
                        errors.addresses[index] &&
                        errors.addresses[index].city
                      }
                    >
                      <Controller
                        as={
                          <Autocomplete
                            error={
                              errors.addresses &&
                              errors.addresses[index] &&
                              errors.addresses[index].city
                            }
                            options={districts}
                            getOptionLabel={(option) =>
                              option.name ? option.name : option
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={
                                  errors.addresses &&
                                  errors.addresses[index] &&
                                  errors.addresses[index].city
                                }
                                label={
                                  <IntlMessages id="contact.form.district" />
                                }
                              />
                            )}
                          />
                        }
                        onChange={([, data]) => onCityChange(data, index)}
                        name={`addresses[${index}].city`}
                        control={control}
                        defaultValue={item.city && getDistrict(item.city)}
                        // value={item.city && getDistrict(item.city)}
                      />
                      <FormHelperText>
                        {errors.addresses &&
                          errors.addresses[index] &&
                          errors.addresses[index].countryCode &&
                          errors.addresses[index].countryCode.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                  {!isCountry(index) && (
                    <FormControl fullWidth margin="normal">
                      <Controller
                        // label={<IntlMessages id="contact.form.city" />}
                        label={
                          <span>
                            {isCountry(index) && <span>District</span>}
                            {!isCountry(index) && (
                              <span>
                                <IntlMessages id="contact.form.city" />
                              </span>
                            )}
                          </span>
                        }
                        as={TextField}
                        name={`addresses[${index}].city`}
                        control={control}
                        fullWidth
                        defaultValue={item.city}
                        error={
                          errors.addresses &&
                          errors.addresses[index] &&
                          errors.addresses[index].city
                        }
                        helperText={
                          errors.addresses &&
                          errors.addresses[index] &&
                          errors.addresses[index].city &&
                          errors.addresses[index].city.message
                        }
                      />
                    </FormControl>
                  )}
                </Grid>
                <Grid item xs>
                  <FormControl fullWidth margin="normal">
                    <Controller
                      // label={<IntlMessages id="contact.form.state" />}
                      label={
                        <span>
                          {isCountry(index) && <span>Province</span>}
                          {!isCountry(index) && (
                            <span>
                              <IntlMessages id="contact.form.state" />
                            </span>
                          )}
                        </span>
                      }
                      as={TextField}
                      name={`addresses[${index}].state`}
                      control={control}
                      fullWidth
                      defaultValue={item.state}
                      error={
                        errors.addresses &&
                        errors.addresses[index] &&
                        errors.addresses[index].state
                      }
                      helperText={
                        errors.addresses &&
                        errors.addresses[index] &&
                        errors.addresses[index].state &&
                        errors.addresses[index].state.message
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl fullWidth margin="normal">
                    <Controller
                      // label={<IntlMessages id="contact.form.zipCode" />}
                      label={
                        <span>
                          {isCountry(index) && <span>Ward</span>}
                          {!isCountry(index) && (
                            <span>
                              <IntlMessages id="contact.form.zipCode" />
                            </span>
                          )}
                        </span>
                      }
                      as={TextField}
                      name={`addresses[${index}].zipCode`}
                      control={control}
                      fullWidth
                      defaultValue={item.zipCode}
                      error={
                        errors.addresses &&
                        errors.addresses[index] &&
                        errors.addresses[index].zipCode
                      }
                      helperText={
                        errors.addresses &&
                        errors.addresses[index] &&
                        errors.addresses[index].zipCode &&
                        errors.addresses[index].zipCode.message
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={updateFormModal ? 5 : 6}>
                  <FormControl
                    fullWidth
                    margin="normal"
                    error={
                      errors.addresses &&
                      errors.addresses[index] &&
                      errors.addresses[index].countryCode
                    }
                  >
                    <Controller
                      as={
                        <Autocomplete
                          error={
                            errors.addresses &&
                            errors.addresses[index] &&
                            errors.addresses[index].countryCode
                          }
                          options={countries}
                          getOptionLabel={(option) => getCountryLabel(option)}
                          renderOption={(option) => (
                            <span>
                              {countryToFlag(option.countryCode)}
                              {option.name}
                            </span>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={
                                errors.addresses &&
                                errors.addresses[index] &&
                                errors.addresses[index].countryCode
                              }
                              label={
                                <IntlMessages id="contact.form.countryCode" />
                              }
                            />
                          )}
                        />
                      }
                      onChange={([, data]) => data}
                      name={`addresses[${index}].countryCode`}
                      control={control}
                      defaultValue={
                        item.countryCode && getCountry(item.countryCode)
                      }
                      // value={item.countryCode && getCountry(item.countryCode)}
                    />
                    <FormHelperText>
                      {errors.addresses &&
                        errors.addresses[index] &&
                        errors.addresses[index].countryCode &&
                        errors.addresses[index].countryCode.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                {updateFormModal && (
                  <Grid item xs={1}>
                    <IconButton
                      aria-label="Delete"
                      onClick={() => onRemove(index)}
                      variant="contained"
                      color="secondary"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </Container>
            {index + 1 !== fields.length && <Divider variant="middle" />}
          </div>
        );
      })}
    </div>
  );
}
