import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";

import { Container, CardHeader } from "@material-ui/core";

// Custom Components and Helpers
import { getValuefromLookup, getCountryNamefromCode } from "util/helpers";

//  Actions
import { countries } from "appRoutes/Contact/ContactForm/formIndex";

const ContactInfo = (props) => {
  const { client, clientLoading, clientError } = useSelector(
    ({ profileStore }) => profileStore
  );
  const { accConfig, districts, appUiConfig } = useSelector(
    ({ uiConfigureStore }) => uiConfigureStore
  );
  const { customFields } = client;
  return (
    <div>
      {/* <CardHeader title="Contact Info" /> */}
      {customFields &&
        customFields.map((customField, index) => {
          return (
            <div
              key={index}
              className="media align-items-center flex-nowrap jr-pro-contact-list"
            >
              <div className="mr-3">
                <i className="zmdi zmdi-pin zmdi-hc-fw zmdi-hc-lg text-primary align-self-center" />
              </div>
              <div className="media-body">
                <span className="mb-0 text-grey jr-fs-md">
                  {getValuefromLookup(
                    accConfig.customFieldType,
                    customField.customType
                  )}
                </span>
                <p className="mb-0">{customField.customValue}</p>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default withRouter(ContactInfo);
