import {
  Button,
  CircularProgress,
  Drawer,
  Fab,
  Tooltip,
} from "@material-ui/core";
import { getConfigData } from "appRoutes/actions";
import TaskDetail from "appRoutes/TaskPage/TaskDetail";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomScrollbars from "util/CustomScrollbars";
import IntlMessages from "util/IntlMessages";
import {
  getAllDepartments,
  getAllUnits,
  getAllVisits,
  onVisitClose,
  onVisitForm,
  setVisitEmpty,
} from "./actions";
import ComposeVisit from "./composeVisit";
import VisitDetail from "./VisitForm/VisitDetail";
import VisitReport from "./VisitForm/VisitReport";
import VisitList from "./VisitList";

const Visit = (props) => {
  const dispatch = useDispatch();
  const { width } = useSelector(({ uiConfigureStore }) => uiConfigureStore);
  const { visits, visitsLoading, viewVisit, visit } = useSelector(
    ({ visitStore }) => visitStore
  );

  const [noContentFoundMessage, setNoContentFoundMessage] = useState(
    "No visit found in selected folder"
  );
  const [drawerState, setDrawerState] = useState(false);

  const [allVisit, setAllVisit] = useState(visits);
  const [folderVisits, setFolderVisits] = useState(visits);
  const [filterVal, setFilterVal] = useState("search");

  useEffect(() => {
    document.title = "Inbox - Metta";
    dispatch(getAllVisits(props.match.params.id));
    dispatch(getAllDepartments());
    dispatch(getAllUnits());
    dispatch(getConfigData("visitCategory"));
  }, []);

  useEffect(() => {
    setAllVisit(visits);
    setFolderVisits(visits?.reverse());
  }, [visits]);
  useEffect(() => {
    dispatch(setVisitEmpty());
    // dispatch(onVisitForm(null, "view"));
  }, []);

  // get List of contacts
  const getVisitsList = (filterVal = null, searchVal = null) => {
    dispatch(getAllVisits(filterVal, searchVal));
  };
  const onVisitSelect = (visitId) => {
    dispatch(onVisitForm(visitId, "view"));
  };

  // on compose Patient Visit
  const onAddVisit = () => {
    dispatch(onVisitForm(null, "add"));
  };

  const onToggleDrawer = () => {
    setDrawerState(!drawerState);
  };

  const displayVisit = (folderVisits, noContentFoundMessage) => {
    return (
      <div className="module-box-column">
        <VisitReport />

        {!viewVisit ? (
          folderVisits?.length === 0 ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                height:
                  width >= 1200 ? "calc(100vh - 259px)" : "calc(100vh - 238px)",
              }}
            >
              {noContentFoundMessage}
            </div>
          ) : (
            <VisitList
              visits={folderVisits}
              onVisitSelect={onVisitSelect}
              width={width}
            />
          )
        ) : (
          <VisitDetail visit={visit} width={width} />
          // <TaskDetail task={task} width={width} />
        )}
      </div>
    );
  };

  const VisitSideBar = () => {
    return (
      <div className="module-side" style={{ minWidth: "290px" }}>
        <div className="module-side-header">
          <div className="module-logo">
            <i className="zmdi zmdi-etask mr-3" />
            <span>
              <IntlMessages id="visit.visit" />
            </span>
          </div>
        </div>

        <div className="module-side-content">
          <CustomScrollbars
            className="module-side-scroll scrollbar"
            style={{
              height:
                width >= 1200 ? "calc(100vh - 200px)" : "calc(100vh - 80px)",
            }}
          >
            <div className="module-add-task">
              <Button
                variant="contained"
                color="primary"
                className="btn-block"
                onClick={() => onAddVisit()}
              >
                <i className="zmdi zmdi-edit zmdi-hc-fw" />
                <IntlMessages id="visit.compose" />
              </Button>
            </div>
          </CustomScrollbars>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="animated slideInUpTiny animation-duration-3">
        <div className="d-xl-flex">
          <div className="d-block d-xl-none">
            <Drawer open={drawerState} onClose={() => onToggleDrawer()}>
              {VisitSideBar()}
            </Drawer>
          </div>
          <div className="app-module-sidenav d-none d-xl-flex">
            {VisitSideBar()}
          </div>

          <div className="module-box">
            {/* <div className="module-box-header">
              <IconButton
                className="drawer-btn d-block d-xl-none"
                aria-label="Menu"
                onClick={() => onToggleDrawer()}
              >
                <i className="zmdi zmdi-menu" />
              </IconButton>
              <AppModuleHeader
                placeholder="Search tasks"
                user={loggedUser}
                onChange={updateSearch}
                value={searchTask}
                filterVal={filterVal}
                setFilterVal={setFilterVal}
              />
            </div> */}

            <div className="module-box-content">
              {visitsLoading ? (
                <div
                  className="loader-view"
                  style={{
                    height:
                      width >= 1200
                        ? "calc(100vh - 259px)"
                        : "calc(100vh - 238px)",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                displayVisit(folderVisits, noContentFoundMessage)
              )}

              <ComposeVisit />

              {/* <ComposeTask /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-button btn-group-vertical">
        <Tooltip
          title={"Compose Patient Visit "}
          aria-label="Compose Patient Visit"
        >
          <Fab color="primary" aria-label="add" onClick={() => onAddVisit()}>
            <i className="zmdi zmdi-plus zmdi-hc-fw zmdi-hc-lg" />
          </Fab>
        </Tooltip>
      </div>
    </>
  );
};

export default Visit;
