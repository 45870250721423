// Vendors Components
import { CardHeader, Grid, IconButton, Tooltip } from "@material-ui/core";
import { Edit as EditIcon } from "@material-ui/icons";
import AlertBox from "components/AlertBox/index";
import Widget from "components/Widget/index";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
//  Actions
import { onAddMedicationForm } from "redux/actions/MedicalActions";
//  Themes Components
import Auxiliary from "util/Auxiliary";
// Custom Components and Helpers
import { capitalize, displayDate, getValuefromLookup } from "util/helpers";
import { onAddCheckoutForm } from "./actions";

const Checkout = (props) => {
  const dispatch = useDispatch();
  const { visit } = useSelector(({ visitStore }) => visitStore);
  const { accConfig } = useSelector(({ uiConfigureStore }) => uiConfigureStore);
  // on Add History
  const onAddHistory = () => {
    dispatch(onAddCheckoutForm("update", "checkout"));
    // dispatch(onContactForm(contact, 'update'));
  };
  return (
    <Widget styleName="jr-card-full jr-card-tabs-right jr-card-profile">
      <CardHeader
        action={
          visit.id && (
            <Tooltip title="Update About Info ">
              <IconButton
                aria-label="Add"
                onClick={() => onAddHistory()}
                variant="contained"
                color="primary"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          )
        }
        title={"Checkout"}
      />
      <div className="jr-tabs-content jr-task-list">
        {!visit.followupAssignedId && (
          <AlertBox
            infotype="danger"
            message="Checkout patient"
            onClick={onAddHistory}
          />
        )}

        <div className="row">
          {visit.followupAssignedId && (
            <>
              <Grid container spacing={2}>
                <Grid item>
                  <div>
                    Patient checkout on{" "}
                    {moment
                      .utc(visit.endDateTime)
                      .local()
                      .format("YYYY-MM-DD HH:mm")}
                    {/* {moment(visit.endDateTime)?.format("YYYY-MM-DD h A")} */}
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item>
                  <div>
                    Followup with {visit.followupAssignedName} on{" "}
                    {/* {displayDate(visit.followupDateTime, "datetime")} */}
                    {moment
                      .utc(visit.followupDateTime)
                      .local()
                      .format("YYYY-MM-DD HH:mm")}
                    {/* {moment(visit.followupDateTime)?.format("YYYY-MM-DD h A")} */}
                  </div>
                </Grid>
              </Grid>
              {/* <Grid container spacing={2}>
                <Grid item>
                  <div>
                    <strong>Follow Up AssignedId: </strong>
                    {visit.followupAssignedId}
                  </div>
                </Grid>
              </Grid> */}
              <Grid container spacing={4} style={{ padding: "1rem" }}>
                <Grid item>
                  <div>{visit.note}</div>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item>
                  <div>Reviewed By {visit.reviewedBy}</div>
                </Grid>
              </Grid>
              {/* <Grid container spacing={2}>
                <Grid item>
                  <div>
                    <strong>Follow Up AssignedName: </strong>
                    {visit.followupAssignedName}
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item>
                  <div>
                    <strong>Follow Up DateTime: </strong>
                    {visit.followupDateTime}
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item>
                  <div>
                    <strong>Note: </strong>
                    {visit.note}
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item>
                  <div>
                    <strong>Reviewed By: </strong>
                    {visit.reviewedBy}
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item>
                  <div>
                    <strong>Checkin @: </strong>
                    {visit.endDateTime}
                  </div>
                </Grid>
              </Grid> */}
            </>
          )}
        </div>
      </div>
    </Widget>
  );
};
export default Checkout;
