import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";

import { Container, CardHeader } from "@material-ui/core";

// Custom Components and Helpers
import { getValuefromLookup, getCountryNamefromCode } from "util/helpers";

//  Actions
import { countries } from "appRoutes/Contact/ContactForm/formIndex";

const ContactInfo = (props) => {
  const { client, clientLoading, clientError } = useSelector(
    ({ profileStore }) => profileStore
  );
  const { accConfig, districts, appUiConfig } = useSelector(
    ({ uiConfigureStore }) => uiConfigureStore
  );
  const { phones, emails, addresses } = client;
  return (
    <>
      {/* <CardHeader title="Contact Info" /> */}

      {phones &&
        phones.map((phone, index) => {
          return (
            <div
              key={index}
              className="media align-items-center flex-nowrap jr-pro-contact-list"
            >
              <div className="mr-3">
                <i className="zmdi zmdi-phone zmdi-hc-fw zmdi-hc-lg text-primary align-self-center" />
              </div>
              <div className="media-body">
                <span className="mb-0 text-grey jr-fs-sm" />
                <p className="mb-0">{phone.phoneDisplay} </p>
              </div>
            </div>
          );
        })}
      {emails &&
        emails.map((email, index) => {
          return (
            <div
              key={index}
              className="media align-items-center flex-nowrap jr-pro-contact-list"
            >
              <div className="mr-3">
                <i className="zmdi zmdi-email zmdi-hc-fw zmdi-hc-lg text-primary align-self-center" />
              </div>
              <div className="media-body">
                <span className="mb-0 text-grey jr-fs-md">
                  {/* {getValuefromLookup(accConfig.emailType, email.emailType)} */}
                </span>
                <p className="mb-0">{email.emailDisplay} </p>
              </div>
            </div>
          );
        })}
      {addresses &&
        addresses.map((address, index) => {
          return (
            <div
              key={index}
              className="media align-items-center flex-nowrap jr-pro-contact-list"
            >
              <div className="mr-3">
                <i className="zmdi zmdi-pin zmdi-hc-fw zmdi-hc-lg text-primary align-self-center" />
              </div>
              <div className="media-body">
                <span className="mb-0 text-grey jr-fs-md">
                  Address:{" "}
                  {getValuefromLookup(
                    accConfig.addressType,
                    address.addressType
                  )}
                </span>
                <p className="mb-0">
                  {address.address1 ? address.address1 + ", " : null}
                  {address.address2 ? address.address2 + ", " : null}
                  {address.city}
                  <br />
                  {address.state ? address.state + ", " : null}
                  {address.zipCode ? address.zipCode + ", " : null}&nbsp;
                  {getCountryNamefromCode(countries, address.countryCode)}
                </p>
              </div>
            </div>
          );
        })}
    </>
  );
};

export default withRouter(ContactInfo);
