import * as yup from "yup";
import moment from "moment";
export const initTaskVal = {
  title: "",
  description: "",
  category: "APPOINTMENT",
  endDateTime: null,
  startDateTime: null,
  accessGroups: [
    {
      group: "DEFAULT",
      members: [],
    },
  ],
};

export const formSchema = yup.object().shape({
  title: yup.string().required("Task is Required"),
  description: yup.string().required("Description is Required"),
  startDateTime: yup.mixed().required("Start Date is Required"),
  endDateTime: yup.mixed().required("End Date is Required"),
  assignedId: yup.mixed().required("Assigned Name  is required"),

  // subject: yup.string().required("Subject is Required"),
  // accessGroups: yup
  //   .array()
  //   // .min(1, 'Atleast 1 Address is Required')
  //   .of(
  //     yup.object().shape({
  //       group: yup.string(),
  //       members: yup.string(),
  //     })
  //   ),
});
