// material ui
import { Button, DialogActions, DialogContent } from "@material-ui/core";
import DrugHistoryForm from "appRoutes/Profile/MedicalRecord/MedHistory/MedicalRecordForm/ArrayForm/DrugHistoryForm";
import DialogTitle from "components/Dialog/DialogTitle";
import moment from "moment";
import React, { Fragment, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Alert, Spinner } from "reactstrap";
import { CrudNotification } from "util/helpers";
import IntlMessages from "util/IntlMessages";
import { getAllVisits, onVisitForm } from "../../actions";
import { onPesFormClose, onPesRecordSave } from "../../Prescriptions/actions";
import { onCheckoutFormClose, onCheckoutRecordSave } from "../actions";
import { setVisitOpen, setVisitEmpty } from "../../actions";

import CheckoutForm from "../CheckoutForm";
// import InvestigationForm from "./InvestigationForm";
// formschema and initvalue
import {
  initCheckoutVal,
  initCheckoutSchema,
  initCheckoutSchemaAdd,
} from "./formindex";

const FormComponent = (props) => {
  const dispatch = useDispatch();
  const {
    visit,
    addCheckoutModal,
    updateCheckoutFormView,
    updateCheckoutFor,
    checkoutformerror,
    is_saving,
  } = useSelector(({ visitStore }) => visitStore);

  const { contact } = useSelector(({ contactStore }) => contactStore);
  const {
    handleSubmit,
    register,
    reset,
    control,
    errors,
    setValue,
    getValues,
    watch,
  } = useForm({
    validationSchema: updateCheckoutFormView
      ? initCheckoutSchema
      : initCheckoutSchemaAdd,
    defaultValues: updateCheckoutFormView
      ? {
          ...visit,

          followupAssignedId: {
            clientId: visit?.followupAssignedId,
            name: visit?.followupAssignedName,
          },
        }
      : initCheckoutVal,
    mode: "onBlur",
  });

  useEffect(() => {
    setValue("patientId", contact.clientId);
  }, []);

  const onSubmit = (values) => {
    return dispatch(onCheckoutRecordSave(transform(values))).then(
      async (res) => {
        //   dispatch(clearIcds());
        CrudNotification("success", "Checkout details added Successfully !");
        await dispatch(getAllVisits(visit?.patientId));
        // await dispatch(setVisitEmpty());
        await dispatch(onCheckoutFormClose());

        await dispatch(onVisitForm(visit?.visitId, "view"));

        // await dispatch(setVisitOpen(false));

        // history.push(`/app/viewPatient/${visit?.patientId}/visit`);
      }
    );
  };

  // Process form values before submitting
  const transform = (data) => {
    let values = data;
    values.id = visit.id;
    const followUp = values.followupAssignedId;
    values.followupAssignedId = followUp.clientId;
    values.followupAssignedName = followUp.name;

    values.followupDateTime = moment(values.followupDateTime)
      .utc()
      .toISOString();
    // moment?.utc(values.followupDateTime);

    values.endDateTime = moment(values.endDateTime).utc().toISOString();
    // moment?.utc(values.endDateTime);

    if (values.id === "") {
      delete values.id;
    }
    // console.log(values);
    return values;
  };
  // on contacts modal close
  const onClose = () => {
    dispatch(onCheckoutFormClose());
    // dispatch(clearIcds());
  };
  return (
    <Fragment>
      <DialogTitle id="contact-form" onClose={onClose}>
        {addCheckoutModal ? "Checkout " : "Checkout " + contact.name}
      </DialogTitle>
      <DialogContent dividers={true}>
        {checkoutformerror && (
          <Alert color="danger"> {checkoutformerror.message} </Alert>
        )}

        {!updateCheckoutFor && (
          <CheckoutForm
            {...{
              control,
              register,
              getValues,
              errors,
              setValue,
              updateCheckoutFor,
              watch,
            }}
          />
        )}
        {updateCheckoutFor === "checkout" && (
          <CheckoutForm
            {...{
              control,
              register,
              getValues,
              errors,
              setValue,
              updateCheckoutFor,
              watch,
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSubmit(onSubmit)}
          disabled={is_saving}
        >
          {is_saving && <Spinner size="sm" />}
          <IntlMessages id="form.save" />
        </Button>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          onClick={() => onClose()}
        >
          <IntlMessages id="form.cancel" />
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default FormComponent;
