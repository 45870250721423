import React, { useEffect, useState } from "react";

import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { getValuefromLookup, getInvestigationGroup } from "util/helpers";

const LabTestView = (props) => {
  const {
    invests,
    tests,
    styles,
    getGroupName,
    getInvestigationCount,
    testType,
  } = props;
  if (invests.length > 0 && getInvestigationCount(testType)) {
    return (
      <Text style={styles.tableCellLeft}>
        <Text>{testType} : </Text>
        {invests.map((val, index) => {
          if (getGroupName(val) === testType) {
            return (
              <Text style={{ fontSize: 8 }} key={index}>
                {getValuefromLookup(tests, val.testItem)}:{val.result}
                {val.unit}
                {index + 1 !== invests.length ? ", " : ""}
              </Text>
            );
          }
        })}
      </Text>
    );
  } else {
    return <Text></Text>;
  }
};

// patient  detail
export default function InvestigationDetail(props) {
  const { contact, inPatient, accConfig, styles } = props;
  const allInvests = inPatient.investigation && inPatient.investigation.tests;
  const tests = accConfig.investigations;

  const getGroupName = (val) => {
    return getInvestigationGroup(tests, val.testItem);
  };

  const getInvestigationCount = (inType) => {
    let isAvailable = false;
    allInvests.map((val) => {
      if (getInvestigationGroup(tests, val.testItem) === inType) {
        isAvailable = true;
        return true;
      }
    });
    return isAvailable;
  };

  return (
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <View style={[styles.tableColHeader, { width: "100%" }]}>
          <Text style={styles.tableCell}></Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableColHeader, { width: "100%" }]}>
          <Text style={styles.tableCellHeader}>INVESTIGATION</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableColLeft, { width: "100%" }]}>
          <LabTestView
            tests={tests}
            invests={allInvests}
            styles={styles}
            getGroupName={getGroupName}
            testType="Blood Count"
            getInvestigationCount={getInvestigationCount}
          />

          <LabTestView
            tests={tests}
            invests={allInvests}
            styles={styles}
            getGroupName={getGroupName}
            testType="Blood Chemistry"
            getInvestigationCount={getInvestigationCount}
          />

          <LabTestView
            tests={tests}
            invests={allInvests}
            styles={styles}
            getGroupName={getGroupName}
            testType="Urinalysis"
            getInvestigationCount={getInvestigationCount}
          />
          <LabTestView
            tests={tests}
            invests={allInvests}
            styles={styles}
            getGroupName={getGroupName}
            testType="Stool Examination"
            getInvestigationCount={getInvestigationCount}
          />
          <LabTestView
            tests={tests}
            invests={allInvests}
            styles={styles}
            getGroupName={getGroupName}
            testType="Imaging"
            getInvestigationCount={getInvestigationCount}
          />
          <LabTestView
            tests={tests}
            invests={allInvests}
            styles={styles}
            getGroupName={getGroupName}
            testType="Other"
            getInvestigationCount={getInvestigationCount}
          />
        </View>
      </View>

      <View style={styles.tableRow}>
        <View style={[styles.tableColHeader, { width: "100%" }]}>
          <Text style={styles.tableCellHeader}>OUTCOME DIAGNOSIS</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableColHeader, { width: "33%" }]}>
          <Text style={styles.tableCellHeader}>DATE</Text>
        </View>

        <View style={[styles.tableColHeader, { width: "34%" }]}>
          <Text style={styles.tableCellHeader}>DIAGNOSIS</Text>
        </View>

        <View style={[styles.tableColHeader, { width: "33%" }]}>
          <Text style={styles.tableCellHeader}>OUTCOME CODE</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableColLeft, { width: "100%" }]}>
          {inPatient.diagnoses.map((diagnosis, index) => {
            return (
              <Text style={styles.tableCellLeft} key={index}>
                {diagnosis.dateDisplay} :{" "}
                {getValuefromLookup(
                  accConfig.diagnosisType,
                  diagnosis.diagnosisType
                )}
                ,{diagnosis.icd10}, {diagnosis.diagnosis}
              </Text>
            );
          })}
        </View>
      </View>

      <View style={styles.tableRow}>
        <View style={[styles.tableColHeader, { width: "50%" }]}>
          <Text style={styles.tableCellHeader}>FOR EXPIRED CASE ONLY</Text>
        </View>

        <View style={[styles.tableColHeader, { width: "50%" }]}>
          <Text style={styles.tableCellHeader}>FOR DELIVERY CASE ONLY</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableColHeader, { width: "100%" }]}>
          <Text style={styles.tableCell}> &nbsp;</Text>
        </View>
      </View>

      <View style={styles.tableRow}>
        <View style={[styles.tableColHeader, { width: "100%" }]}>
          <Text style={styles.tableCellHeader}> OPERATION OUTCOME</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableColHeader, { width: "100%" }]}>
          <Text style={styles.tableCellHeader}>ANAESTHESIA CODE</Text>
        </View>
      </View>

      <View style={styles.tableRow}>
        <View style={[styles.tableColHeader, { width: "25%" }]}>
          <Text style={styles.tableCellHeader}>DATE</Text>
        </View>

        <View style={[styles.tableColHeader, { width: "25%" }]}>
          <Text style={styles.tableCellHeader}>OPERATION</Text>
        </View>
        <View style={[styles.tableColHeader, { width: "25%" }]}>
          <Text style={styles.tableCellHeader}>OUTCOME CODES</Text>
        </View>
        <View style={[styles.tableColHeader, { width: "25%" }]}>
          <Text style={styles.tableCellHeader}>ANAESTHESIA CODE</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableColLeft, { width: "100%" }]}>
          <Text style={styles.tableCellLeft}>&nbsp;</Text>
        </View>
      </View>
    </View>
  );
}
