import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import { Skeleton } from "@material-ui/lab";
import NoteItem from "./NoteItem";

import AlertBox from "components/AlertBox/index";
import IntlMessages from "util/IntlMessages";

const NoteList = ({ match }) => {
  const dispatch = useDispatch();
  const { notes, noteLoading } = useSelector(
    ({ profileStore }) => profileStore
  );
  return (
    <div>
      <div className="timeline-section clearfix animated slideInUpTiny animation-duration-3">
        {notes.length > 0 &&
          notes.map((note, index) => <NoteItem key={index} noteItem={note} />)}

        {noteLoading && <Skeleton variant="rect" height={100} />}
      </div>
    </div>
  );
};

export default NoteList;
