import React from "react";
import { connect } from "react-redux";
// Vendors Components
import { CardHeader, Avatar, Tooltip, IconButton } from "@material-ui/core";
import { AccountCircle, Edit as EditIcon } from "@material-ui/icons";
//  Themes Components
import Widget from "components/Widget";
import AlertBox from "components/AlertBox/index";
// Custom Components and Helpers and  Language Components
import IntlMessages from "util/IntlMessages";
import { getValuefromLookup, displayDate } from "util/helpers";

//  Actions
import { onDailylogForm } from "redux/actions/MedicalActions";

// Components

class IntakeOutputRecord extends React.Component {
  componentDidMount() {}

  onUpdate = () => {
    this.props.onDailylogForm("update", "intakeOutputRecords");
  };

  render() {
    const { dailyLog, accConfig } = this.props;
    return (
      <Widget styleName="jr-card-full jr-card-tabs-right jr-card-profile">
        <div className="jr-tabs-content jr-task-list">
          <CardHeader
            action={
              // !updateMedicalFormView &&
              <Tooltip title="Update IntakeOutput Records ">
                <IconButton
                  aria-label="Add"
                  onClick={() => this.onUpdate()}
                  variant="contained"
                  color="primary"
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            }
            subheader={
              <strong>
                <IntlMessages id="dailylog.label.intakeOutputRecords" />
              </strong>
            }
          />
          <div className="jr-wall-content">
            {!dailyLog.intakeOutputRecords && (
              <AlertBox
                infotype="danger"
                message="No in take Output Records Found."
                onClick={this.onUpdate}
              />
            )}
            {dailyLog.intakeOutputRecords && (
              <div>
                {dailyLog.intakeOutputRecords.length > 0 &&
                  dailyLog.intakeOutputRecords.map((note, index) => {
                    return (
                      <div key={index} className="media social-list-line">
                        <Avatar
                          className={`z-index-20 size-40 align-item-self mr-3`}
                        ></Avatar>
                        <div className="media-body">
                          <h4 className="mb-1">
                            <strong>
                              {getValuefromLookup(
                                accConfig.intakeOutputType,
                                note.intakeOutputType
                              )}
                            </strong>
                          </h4>
                          <p>
                            {note.note}
                            <br />
                            <strong>
                              {getValuefromLookup(
                                accConfig.intakeOutputType,
                                note.intakeOutputType
                              )}{" "}
                              method :{" "}
                            </strong>
                            {getValuefromLookup(
                              note.intakeOutputType === "INTAKE"
                                ? accConfig.intakeMethod
                                : accConfig.outputMethod,
                              note.intakeOutputMethod
                            )}{" "}
                            &nbsp;&nbsp;&nbsp;
                            <br />
                            <strong>Fluid Type : </strong>
                            {getValuefromLookup(
                              note.intakeOutputType === "INTAKE"
                                ? accConfig.intakeFluid
                                : accConfig.outputFluid,
                              note.fluidType
                            )}
                            {/* {getValuefromLookup(fluidTypes, note.fluidType)} */}
                            <br />
                            <strong>Amount : </strong>
                            {note.amount}{" "}
                            {getValuefromLookup(
                              accConfig.intakeOutputUnit,
                              note.unit
                            )}
                          </p>
                          <span className="meta-date meta-date-light">
                            {displayDate(note.time, "time", true)}
                          </span>
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      </Widget>
    );
  }
}

export default IntakeOutputRecord = connect(
  (state) => {
    return {
      accConfig: state.uiConfigureStore.accConfig,
      dailyLog: state.medRecordStore.dailyLog,
    };
  },
  { onDailylogForm }
)(IntakeOutputRecord);
