import React from "react";

import { Avatar, Button } from "@material-ui/core";

import moment from "moment";
import { NotificationManager } from "react-notifications";
import allColors from "util/allColors";

const AnswerEmoji = (props) => {
  const { answer } = props;
  // console.log(answer)
  var type = answer.question;
  var imgSrc = require("assets/images/answer/0.png");
  if (type === "PAIN_DISCOMFORT_" || type === "NAUSEA_VOMITING") {
    var image = 1;
    if (answer.answer === 1 || answer.answer === 0) {
      image = 5;
    } else if (answer.answer === 2) {
      image = 4;
    } else if (answer.answer === 4) {
      image = 2;
    } else if (answer.answer === 5) {
      image = 1;
    }
    var imgSrc = require("assets/images/answer/" + image + ".png");
  } else {
    var imgSrc = require("assets/images/answer/" + answer.answer + ".png");
  }
  return (
    <Avatar
      src={imgSrc}
      className={`z-index-20 size-40 align-item-self mr-3`}
    />
  );
};

const getFilePath = (document) => {
  // const coreHost = window && window.location && window.location.hostname;
  const filedomain =
    process.env.NODE_ENV === "development"
      ? "http://localhost:8080"
      : "http://care.metta.one";
  // console.log(document)
  var paths = {
    srcImgPath: document.externalPath
      ? document.path
      : filedomain + document.path,
    srcFilePath: document.externalPath
      ? document.path
      : filedomain + document.path,
  };
  if (document.documentType !== "IMG") {
    paths.srcImgPath = require("assets/images/file_icon.png");
  }
  // console.log(paths)
  return paths;
};

const getFileTypeFromFilename = (item) => {
  var fileType = "";
  if (item) {
    var re = /(?:\.([^.]+))?$/;
    var ext = re.exec(item)[1].toLowerCase();
    if (ext === "jpeg" || ext === "jpg" || ext === "png" || ext === "bmp") {
      fileType = "IMG";
    } else if (ext === "pdf") {
      fileType = "PDF";
    } else if (ext === "xls") {
      fileType = "EXCEL";
    } else if (ext === "doc" || ext === "docx") {
      fileType = "DOC";
    } else if (ext === "txt") {
      fileType = "TXT";
    } else {
      fileType = "OTHER";
    }
  }

  return fileType;
};

const ScaledProgressbar = (props) => {
  const { scalelabel, value, max, ...otherProps } = props;
  const scaledPercentage = (value / max) * 100;
  return (
    <div>{(scaledPercentage / 100) * max + scalelabel}</div>
    // <CircularProgressbar
    // 	percentage={scaledPercentage}
    // 	text={scaledPercentage / 100 * max + scalelabel}
    // 	{...otherProps}
    // />
  );
};

const NoLists = (props) => {
  const { message, buttonLabel, onButtonClick, ...otherProps } = props;
  return (
    <div>
      <span>{message} </span>
      {onButtonClick && (
        <Button type="primary" onClick={() => onButtonClick()}>
          {buttonLabel ? buttonLabel : "Create Now"}
        </Button>
      )}
    </div>
    // <Empty
    //   image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
    //   imageStyle={{
    //     height: 60,
    //   }}
    //   description={
    //     <span>
    //       {message}
    //     </span>
    //   }
    // >
    //   {
    //     onButtonClick &&
    //     <Button type="primary" onClick={() => onButtonClick()}>{buttonLabel ? buttonLabel : 'Create Now'}</Button>
    //   }
    // </Empty>
  );
};

const firstLetter = (str) => {
  // var str     = "Java Script Object Notation";
  if (str !== null && str !== "") {
    let matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    let acronym = matches.join(""); // JSON
    return acronym.toUpperCase();
  } else {
    return "N/A";
  }
};
const CrudNotification = (type, message, title = "") => {
  NotificationManager.listNotify.forEach((n) =>
    NotificationManager.remove({ id: n.id })
  );
  if (type && NotificationManager[type])
    return NotificationManager[type](message, title, 1000);
};

const capitalize = (value) => {
  return value.toLowerCase().replace(/\b[a-z]/g, function (letter) {
    return letter.toUpperCase();
  });
};

const getValuefromLookup = (array, value) => {
  let fullLink = value;
  if (array && array?.length > 0) {
    array.map((item) => {
      // console.log(item)
      if (item.key === value) {
        fullLink = item.value.value ? item.value.value : item.value;
      }
    });
  }
  // console.log(fullLink)
  return fullLink;
};

// Get Investigation Group
const getInvestigationGroup = (array, value) => {
  let investigation = { group: "Other" };
  if (array && array.length > 0) {
    investigation = array.find((item) => {
      return item.key === value;
    });
    return investigation && investigation.group;
  }
};

const getDefaultUnit = (array, type = "value") => {
  let defaultunit = "";
  if (array && array.length > 0) {
    console.log("aaaray", array);
    array.map((item) => {
      // console.log(item)
      if (item.default === true) {
        if (type === "value") {
          defaultunit = item.value;
        } else {
          defaultunit = item.key;
        }
      }
    });
  }
  return defaultunit;
};

const getCountryNamefromCode = (array, code, codeType = "countryCode") => {
  let name = "";
  if (array && array.length > 0) {
    array.map((item) => {
      if (codeType === "countryCode") {
        if (item.countryCode === code) {
          // console.log(item)
          name = item.name;
        }
      } else {
        if (item.dialCode === code) {
          name = item.name;
        }
      }
    });
  }
  return name;
};

const toRegularString = (string) => {
  var value = string;
  // insert a space before all caps
  value = value.replace(/([A-Z])/g, " $1");
  // uppercase the first character
  value = value.replace(/^./, function (str) {
    return str.toUpperCase();
  });
  return value;
};

const objectKeysToRegular = (origObj) => {
  return Object.keys(origObj).reduce(function (newObj, key) {
    if (key === "dateTime") {
      let val = origObj[key];
      let newVal = typeof val === "object" ? objectKeysToRegular(val) : val;
      newObj[toRegularString(key)] = newVal;
      newObj[key] = origObj[key];
    } else if (key !== "createdAt") {
      let val = origObj[key];
      let newVal = typeof val === "object" ? objectKeysToRegular(val) : val;
      newObj[toRegularString(key)] = newVal;
    } else {
      newObj[key] = origObj[key];
    }

    return newObj;
  }, {});
};

const nullfunction = (val = false) => {
  return val;
};

const saveStarredItem = (data, itemType) => {
  // Parse the serialized data back into an aray of objects
  var recentItems = JSON.parse(localStorage.getItem(itemType));
  // check if list already has the value or empty
  if (recentItems) {
    recentItems = recentItems.filter((item) => item.id !== data.id);
  } else {
    recentItems = [];
  }
  if (!data.starred) {
    // Push the new data (whether it be an object or anything else) onto the array
    // push the recent viewed items
    data.starred = true;
    recentItems.push(data);
  }
  // Re-serialize the array back into a string and store it in localStorage
  localStorage.setItem(itemType, JSON.stringify(recentItems));
};

const saveRecentItemLocalStorage = (data, itemType) => {
  // var value = {
  //   clientId: data.clientId,
  //   id: data.id
  // }
  // Parse the serialized data back into an aray of objects
  var recentItems = JSON.parse(sessionStorage.getItem(itemType));
  // check if list already has the value or empty
  if (recentItems) {
    recentItems = recentItems.filter((item) => item.id !== data.id);
  } else {
    recentItems = [];
  }
  // check if list is greater than 5
  if (recentItems.length > 9) {
    recentItems.shift();
  }
  // Push the new data (whether it be an object or anything else) onto the array
  // push the recent viewed items
  recentItems.push(data);
  // Re-serialize the array back into a string and store it in localStorage
  sessionStorage.setItem(itemType, JSON.stringify(recentItems));
};

// check if image exists
var checks = {};
const imageCheck = (src) => {
  if (src in checks) {
    return nullfunction(checks[src]);
  }
  var img = new Image();

  img.onload = function () {
    checks[src] = true;
    nullfunction(true);
  };

  img.onerror = function () {
    checks[src] = false;
    nullfunction(false);
  };
  img.src = src;
};

const getQueryString = function (url, field) {
  // var href = url ? url : window.location.href;
  var reg = new RegExp("[?&]" + field + "=([^&#]*)", "i");
  var string = reg.exec(url);
  return string ? string[1] : null;
};

const getUniqueArray = function (arr, comp) {
  const unique = arr
    .map((e) => e[comp])
    // store the keys of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)
    // eliminate the dead keys & store unique objects
    .filter((e) => arr[e])
    .map((e) => arr[e]);

  return unique;
};

const datetoArray = (dateStr) => {
  let dtime = dateStr + moment().format(" HH:mm");
  // let dateVal = moment(dtime).utc().format("YYYY,MM,DD");
  let dateVal = moment(dtime).format("YYYY,MM,DD");
  var res = dateVal
    .split(/[ ,:]+/)
    .filter((x) => x.trim().length && !isNaN(x))
    .map(Number);
  return res;
};
const timeToArray = (time) => {
  // let timeVal = moment(time, "HH:mm").utc().format("HH:mm");
  let timeVal = moment(time, "HH:mm").format("HH:mm");
  return timeVal
    .split(/[ ,:]+/)
    .filter((x) => x.trim().length && !isNaN(x))
    .map(Number);
};

const displayDate = (d, dateType = "date", isArray = false) => {
  let date = d;
  let displayDate = date;

  if (isArray && (dateType === "date" || dateType === "datetime")) {
    date = d.slice(0);
    date[1] = date[1] - 1;
  }
  if (isArray && dateType === "time") {
    date = moment.utc(date, "HH:mm").local();
  }

  if (dateType === "datetime") {
    displayDate = moment.utc(date).local().format("YYYY-MM-DD HH:mm");
  } else if (dateType === "time") {
    displayDate = moment.utc(date).local().format("HH:mm");
  } else if (dateType === "datef") {
    displayDate = moment.utc(date).local().format("MMMM Do YYYY");
  } else if (dateType === "datetimef") {
    displayDate = moment.utc(date).local().format("MMMM Do, YYYY, h:mm a");
  } else {
    displayDate = moment.utc(date).format("YYYY-MM-DD");
  }
  return displayDate;
};

const saveDateFmt = () => {};

// convert date from Api to Datepicker
const setDateForPicker = (d) => {
  let date = d;
  if (Array.isArray(d)) {
    date = d.slice(0);
    date[1] = date[1] - 1;
    // let res = moment(date);
  }
  let res = moment.utc(date).local();
  return res;
};

const getColor = (string) => {
  let colour = allColors.find(
    (color) =>
      color.color.charAt(0).toUpperCase() === string.charAt(0).toUpperCase()
  );
  return colour ? colour.hex : "#777696";
};

export {
  getQueryString,
  getFilePath,
  getFileTypeFromFilename,
  getCountryNamefromCode,
  getValuefromLookup,
  getInvestigationGroup,
  getDefaultUnit,
  toRegularString,
  objectKeysToRegular,
  nullfunction,
  capitalize,
  CrudNotification,
  firstLetter,
  ScaledProgressbar,
  NoLists,
  AnswerEmoji,
  saveRecentItemLocalStorage,
  saveStarredItem,
  imageCheck,
  getUniqueArray,
  datetoArray,
  timeToArray,
  displayDate,
  setDateForPicker,
  getColor,
};
