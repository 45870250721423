import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IconButton, Tooltip } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
} from "reactstrap";
import { Link, NavLink } from "react-router-dom";

//  actions
import { onContactForm } from "services/actions";

const NavBar = (props) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { profileTitle, client, clientLoading, clientError } = useSelector(
    ({ profileStore }) => profileStore
  );
  //   const { appUiConfig } = useSelector(
  //     ({ uiConfigureStore }) => uiConfigureStore
  //   );
  const toggle = () => setIsOpen(!isOpen);

  // on Update Contact
  const onEditContact = () => {
    dispatch(onContactForm(client, "update"));
  };

  return (
    <>
      <Navbar expand="md" style={{ width: "100%" }}>
        <NavbarBrand>
          <NavbarToggler onClick={toggle} style={{ color: "white" }}>
            {/* {profileTitle.title} */}
          </NavbarToggler>
        </NavbarBrand>
        <NavbarToggler onClick={toggle} style={{ color: "white" }}>
          <MenuIcon />
        </NavbarToggler>
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            {/* <NavItem>
              <NavLink
                activeStyle={{ color: "#9ad9ff" }}
                to={"/app/contact/" + client.clientId}
                activeClassName="is-active"
                style={{ fontSize: 18 }}
                onClick={() => toggle()}
              >
                Profile
              </NavLink>
            </NavItem> */}
          </Nav>
          {/* <ul className="jr-navbar-nav">
            <li>
              <Tooltip
                title="Update Contact Detail"
                aria-label="Update Contact Detail"
              >
                <span
                  className="jr-link jr-profile-setting"
                  onClick={() => onEditContact()}
                >
                  <i className="zmdi zmdi-settings mr-2" />
                  <span className="d-inline-flex align-middle ml-1 jr-ml-sm-0">
                    Update
                  </span>
                </span>
              </Tooltip>
            </li>
          </ul> */}
          {/* <NavbarText>Simple Text</NavbarText>
          <NavbarText>Simple Text</NavbarText> */}
        </Collapse>
      </Navbar>
    </>
  );
};

export default NavBar;
