import * as yup from "yup";
import moment from "moment";
export const initContactVal = {
  name: "",
  isClientId: false,
  //   role: "",
  about: "",
  tags: [],
  personalInfo: {
    dateOfBirth: null,
    anniversaryDate: null,
    gender: "FEMALE",
    ethnicity: "",
    maritalStatus: "SINGLE",
  },
  addresses: [
    {
      addressType: "HOME",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zipCode: "",
      countryCode: "US",
      primary: true,
      useCurrentLocation: true,
    },
  ],
  phones: [
    {
      phoneType: "MOBILE",
      countryDialCode: "+977",
      phone: "",
      primary: true,
    },
  ],
  emails: [
    {
      emailType: "PERSONAL",
      email: "",
      primary: true,
    },
  ],
  workInfo: {
    employer: "",
    title: "",
    note: "",
  },
  customFields: [
    {
      customType: "MESSENGER",
      customValue: "",
    },
  ],
  accessGroups: [{ group: "DEFAULT" }],
  // relations: [
  //   {
  //     relation: "PHYSICIAN",
  //     relationName: "",
  //     contactInfo: "",
  //     note: "",
  //   },
  // ],
  // accessGroups: ["ADMINISTRATORS"],
};

export const formSchema = yup.object().shape({
  name: yup.string().required("Name is Required"),
  role: yup.string().required(),
  clientId: yup.string().when("isClientId", {
    is: true,
    then: yup.string().required("Client Id is required."),
  }),
  emails: yup.array().of(
    yup.object().shape({
      primary: yup.boolean(),
      // emailType: yup.string().required('Email Type is required'),
      // email: yup.string().email('Invalid email').required('Please enter email')
    })
  ),
  phones: yup
    .array()
    // .min(1, 'Atleast 1 Address is Required')
    .of(
      yup.object().shape({
        primary: yup.boolean(),
        phoneType: yup.string().required("Phone Type is required"),
        countryDialCode: yup.mixed().required("Country Code  is required"),
        phone: yup.string().required("Phone is required"),
      })
    ),
  addresses: yup
    .array()
    // .min(1, 'Atleast 1 Address is Required')
    .of(
      yup.object().shape({
        primary: yup.boolean(),
        addressType: yup.string(),
        address1: yup.string(),
        // address2: yup.string().required('Address2 is required'),
        city: yup.mixed(),
        // state: yup.string().required('State is required'),
        // zipCode: yup.string().required('ZipcountryCode is required'),
        countryCode: yup.mixed(),
      })
    ),
  // relations: yup
  //   .array()
  //   // .min(1, 'Atleast 1 Address is Required')
  //   .of(
  //     yup.object().shape({
  //       relation: yup.string(),
  //       relationName: yup.string(),
  //       contactInfo: yup.string(),
  //     })
  //   ),
  customFields: yup
    .array()
    // .min(1, 'Atleast 1 Address is Required')
    .of(
      yup.object().shape({
        customType: yup.string(),
        customValue: yup.string(),
      })
    ),
  // personalInfo: yup.object().default(null).nullable().shape({
  // dateOfBirth: yup.date().required('Dob is required'),
  // dateOfBirth: yup.string().required("Dob is required"),
  // anniversaryDate: yup.date().required('Anniversary  is required'),
  // gender: yup.string().required("Gender is required"),
  // ethnicity: yup.string().required('Ethnicity is required'),
  // maritalStatus: yup.string().required('Value is required')
  // }),
  // workInfo: yup
  //   .object()
  //   .default(null)
  //   .nullable()
  //   .shape({
  //     employer: yup.string().required("Employer is required"),
  //     title: yup.string().required("Title is required"),
  //     note: yup.string().required("Note  is required"),
  //   }),
});

export const formSchemaUp = yup.object().shape({
  name: yup.string().required("Name is Required"),
  role: yup.string().required(),
  clientId: yup.string().when("isClientId", {
    is: true,
    then: yup.string().required("Client Id is required."),
  }),
  emails: yup.array().of(
    yup.object().shape({
      primary: yup.boolean(),
      // emailType: yup.string().required('Email Type is required'),
      // email: yup.string().email('Invalid email').required('Please enter email')
    })
  ),
  phones: yup
    .array()
    // .min(1, 'Atleast 1 Address is Required')
    .of(
      yup.object().shape({
        primary: yup.boolean(),
        phoneType: yup.string().required("Phone Type is required"),
        countryDialCode: yup.mixed().required("Country Code  is required"),
        phone: yup.string().required("Phone is required"),
      })
    ),
  addresses: yup
    .array()
    // .min(1, 'Atleast 1 Address is Required')
    .of(
      yup.object().shape({
        primary: yup.boolean(),
        addressType: yup.string().required("Address Type is required"),
        address1: yup.string().required("Address 1 is required"),
        // address2: yup.string().required('Address2 is required'),
        city: yup.string().required("City is required"),
        // state: yup.string().required('State is required'),
        // zipCode: yup.string().required('ZipcountryCode is required'),
        countryCode: yup.mixed().required("Country  is required"),
      })
    ),
  customFields: yup
    .array()
    // .min(1, 'Atleast 1 Address is Required')
    .of(
      yup.object().shape({
        customType: yup.string().required("Type is required"),
        customValue: yup.string().required("Value is required"),
      })
    ),
  // personalInfo: yup
  //   .object()
  //   .default(null)
  //   .nullable()
  //   .shape({
  //     // dateOfBirth: yup.date().required('Dob is required'),
  //     dateOfBirth: yup.string().required("Dob is required"),
  //     gender: yup.string().required("Gender is required"),
  //     // ethnicity: yup.string().required('Ethnicity is required'),
  //     // maritalStatus: yup.string().required('Value is required')
  //   }),
  // workInfo: yup
  //   .object()
  //   .default(null)
  //   .nullable()
  //   .shape({
  //     employer: yup.string().required("Employer is required"),
  //     title: yup.string().required("Title is required"),
  //     note: yup.string().required("Note  is required"),
  //   }),
});

export const countries = [
  { countryCode: "AD", name: "Andorra", countryDialCode: "+376" },
  { countryCode: "AE", name: "United Arab Emirates", countryDialCode: "+971" },
  { countryCode: "AF", name: "Afghanistan", countryDialCode: "+93" },
  { countryCode: "AG", name: "Antigua and Barbuda", countryDialCode: "+1-268" },
  { countryCode: "AI", name: "Anguilla", countryDialCode: "+1-264" },
  { countryCode: "AL", name: "Albania", countryDialCode: "+355" },
  { countryCode: "AM", name: "Armenia", countryDialCode: "+374" },
  { countryCode: "AO", name: "Angola", countryDialCode: "+244" },
  { countryCode: "AQ", name: "Antarctica", countryDialCode: "+672" },
  { countryCode: "AR", name: "Argentina", countryDialCode: "+54" },
  { countryCode: "AS", name: "American Samoa", countryDialCode: "+1-684" },
  { countryCode: "AT", name: "Austria", countryDialCode: "+43" },
  {
    countryCode: "AU",
    name: "Australia",
    countryDialCode: "+61",
    suggested: true,
  },
  { countryCode: "AW", name: "Aruba", countryDialCode: "+297" },
  { countryCode: "AX", name: "Alland Islands", countryDialCode: "+358" },
  { countryCode: "AZ", name: "Azerbaijan", countryDialCode: "+994" },
  {
    countryCode: "BA",
    name: "Bosnia and Herzegovina",
    countryDialCode: "+387",
  },
  { countryCode: "BB", name: "Barbados", countryDialCode: "+1-246" },
  { countryCode: "BD", name: "Bangladesh", countryDialCode: "+880" },
  { countryCode: "BE", name: "Belgium", countryDialCode: "+32" },
  { countryCode: "BF", name: "Burkina Faso", countryDialCode: "+226" },
  { countryCode: "BG", name: "Bulgaria", countryDialCode: "+359" },
  { countryCode: "BH", name: "Bahrain", countryDialCode: "+973" },
  { countryCode: "BI", name: "Burundi", countryDialCode: "+257" },
  { countryCode: "BJ", name: "Benin", countryDialCode: "+229" },
  { countryCode: "BL", name: "Saint Barthelemy", countryDialCode: "+590" },
  { countryCode: "BM", name: "Bermuda", countryDialCode: "+1-441" },
  { countryCode: "BN", name: "Brunei Darussalam", countryDialCode: "+673" },
  { countryCode: "BO", name: "Bolivia", countryDialCode: "+591" },
  { countryCode: "BR", name: "Brazil", countryDialCode: "+55" },
  { countryCode: "BS", name: "Bahamas", countryDialCode: "+1-242" },
  { countryCode: "BT", name: "Bhutan", countryDialCode: "+975" },
  { countryCode: "BV", name: "Bouvet Island", countryDialCode: "+47" },
  { countryCode: "BW", name: "Botswana", countryDialCode: "+267" },
  { countryCode: "BY", name: "Belarus", countryDialCode: "+375" },
  { countryCode: "BZ", name: "Belize", countryDialCode: "+501" },
  { countryCode: "CA", name: "Canada", countryDialCode: "+1", suggested: true },
  {
    countryCode: "CC",
    name: "Cocos (Keeling) Islands",
    countryDialCode: "+61",
  },
  {
    countryCode: "CD",
    name: "Congo, Republic of the",
    countryDialCode: "+242",
  },
  {
    countryCode: "CF",
    name: "Central African Republic",
    countryDialCode: "+236",
  },
  {
    countryCode: "CG",
    name: "Congo, Democratic Republic of the",
    countryDialCode: "+243",
  },
  { countryCode: "CH", name: "Switzerland", countryDialCode: "+41" },
  { countryCode: "CI", name: "Cote d'Ivoire", countryDialCode: "+225" },
  { countryCode: "CK", name: "Cook Islands", countryDialCode: "+682" },
  { countryCode: "CL", name: "Chile", countryDialCode: "+56" },
  { countryCode: "CM", name: "Cameroon", countryDialCode: "+237" },
  { countryCode: "CN", name: "China", countryDialCode: "+86" },
  { countryCode: "CO", name: "Colombia", countryDialCode: "+57" },
  { countryCode: "CR", name: "Costa Rica", countryDialCode: "+506" },
  { countryCode: "CU", name: "Cuba", countryDialCode: "+53" },
  { countryCode: "CV", name: "Cape Verde", countryDialCode: "+238" },
  { countryCode: "CW", name: "Curacao", countryDialCode: "+599" },
  { countryCode: "CX", name: "Christmas Island", countryDialCode: "+61" },
  { countryCode: "CY", name: "Cyprus", countryDialCode: "+357" },
  { countryCode: "CZ", name: "Czech Republic", countryDialCode: "+420" },
  {
    countryCode: "DE",
    name: "Germany",
    countryDialCode: "+49",
    suggested: true,
  },
  { countryCode: "DJ", name: "Djibouti", countryDialCode: "+253" },
  { countryCode: "DK", name: "Denmark", countryDialCode: "+45" },
  { countryCode: "DM", name: "Dominica", countryDialCode: "+1-767" },
  { countryCode: "DO", name: "Dominican Republic", countryDialCode: "+1-809" },
  { countryCode: "DZ", name: "Algeria", countryDialCode: "+213" },
  { countryCode: "EC", name: "Ecuador", countryDialCode: "+593" },
  { countryCode: "EE", name: "Estonia", countryDialCode: "+372" },
  { countryCode: "EG", name: "Egypt", countryDialCode: "+20" },
  { countryCode: "EH", name: "Western Sahara", countryDialCode: "+212" },
  { countryCode: "ER", name: "Eritrea", countryDialCode: "+291" },
  { countryCode: "ES", name: "Spain", countryDialCode: "+34" },
  { countryCode: "ET", name: "Ethiopia", countryDialCode: "+251" },
  { countryCode: "FI", name: "Finland", countryDialCode: "+358" },
  { countryCode: "FJ", name: "Fiji", countryDialCode: "+679" },
  {
    countryCode: "FK",
    name: "Falkland Islands (Malvinas)",
    countryDialCode: "+500",
  },
  {
    countryCode: "FM",
    name: "Micronesia, Federated States of",
    countryDialCode: "+691",
  },
  { countryCode: "FO", name: "Faroe Islands", countryDialCode: "+298" },
  {
    countryCode: "FR",
    name: "France",
    countryDialCode: "+33",
    suggested: true,
  },
  { countryCode: "GA", name: "Gabon", countryDialCode: "+241" },
  { countryCode: "GB", name: "United Kingdom", countryDialCode: "+44" },
  { countryCode: "GD", name: "Grenada", countryDialCode: "+1-473" },
  { countryCode: "GE", name: "Georgia", countryDialCode: "+995" },
  { countryCode: "GF", name: "French Guiana", countryDialCode: "+594" },
  { countryCode: "GG", name: "Guernsey", countryDialCode: "+44" },
  { countryCode: "GH", name: "Ghana", countryDialCode: "+233" },
  { countryCode: "GI", name: "Gibraltar", countryDialCode: "+350" },
  { countryCode: "GL", name: "Greenland", countryDialCode: "+299" },
  { countryCode: "GM", name: "Gambia", countryDialCode: "+220" },
  { countryCode: "GN", name: "Guinea", countryDialCode: "+224" },
  { countryCode: "GP", name: "Guadeloupe", countryDialCode: "+590" },
  { countryCode: "GQ", name: "Equatorial Guinea", countryDialCode: "+240" },
  { countryCode: "GR", name: "Greece", countryDialCode: "+30" },
  {
    countryCode: "GS",
    name: "South Georgia and the South Sandwich Islands",
    countryDialCode: "+500",
  },
  { countryCode: "GT", name: "Guatemala", countryDialCode: "+502" },
  { countryCode: "GU", name: "Guam", countryDialCode: "+1-671" },
  { countryCode: "GW", name: "Guinea-Bissau", countryDialCode: "+245" },
  { countryCode: "GY", name: "Guyana", countryDialCode: "+592" },
  { countryCode: "HK", name: "Hong Kong", countryDialCode: "+852" },
  {
    countryCode: "HM",
    name: "Heard Island and McDonald Islands",
    countryDialCode: "+672",
  },
  { countryCode: "HN", name: "Honduras", countryDialCode: "+504" },
  { countryCode: "HR", name: "Croatia", countryDialCode: "+385" },
  { countryCode: "HT", name: "Haiti", countryDialCode: "+509" },
  { countryCode: "HU", name: "Hungary", countryDialCode: "+36" },
  { countryCode: "ID", name: "Indonesia", countryDialCode: "+62" },
  { countryCode: "IE", name: "Ireland", countryDialCode: "+353" },
  { countryCode: "IL", name: "Israel", countryDialCode: "+972" },
  { countryCode: "IM", name: "Isle of Man", countryDialCode: "+44" },
  { countryCode: "IN", name: "India", countryDialCode: "+91" },
  {
    countryCode: "IO",
    name: "British Indian Ocean Territory",
    countryDialCode: "+246",
  },
  { countryCode: "IQ", name: "Iraq", countryDialCode: "+964" },
  {
    countryCode: "IR",
    name: "Iran, Islamic Republic of",
    countryDialCode: "+98",
  },
  { countryCode: "IS", name: "Iceland", countryDialCode: "+354" },
  { countryCode: "IT", name: "Italy", countryDialCode: "+39" },
  { countryCode: "JE", name: "Jersey", countryDialCode: "+44" },
  { countryCode: "JM", name: "Jamaica", countryDialCode: "+1-876" },
  { countryCode: "JO", name: "Jordan", countryDialCode: "+962" },
  { countryCode: "JP", name: "Japan", countryDialCode: "+81", suggested: true },
  { countryCode: "KE", name: "Kenya", countryDialCode: "+254" },
  { countryCode: "KG", name: "Kyrgyzstan", countryDialCode: "+996" },
  { countryCode: "KH", name: "Cambodia", countryDialCode: "+855" },
  { countryCode: "KI", name: "Kiribati", countryDialCode: "+686" },
  { countryCode: "KM", name: "Comoros", countryDialCode: "+269" },
  {
    countryCode: "KN",
    name: "Saint Kitts and Nevis",
    countryDialCode: "+1-869",
  },
  {
    countryCode: "KP",
    name: "Korea, Democratic People's Republic of",
    countryDialCode: "+850",
  },
  { countryCode: "KR", name: "Korea, Republic of", countryDialCode: "+82" },
  { countryCode: "KW", name: "Kuwait", countryDialCode: "+965" },
  { countryCode: "KY", name: "Cayman Islands", countryDialCode: "+1-345" },
  { countryCode: "KZ", name: "Kazakhstan", countryDialCode: "+7" },
  {
    countryCode: "LA",
    name: "Lao People's Democratic Republic",
    countryDialCode: "+856",
  },
  { countryCode: "LB", name: "Lebanon", countryDialCode: "+961" },
  { countryCode: "LC", name: "Saint Lucia", countryDialCode: "+1-758" },
  { countryCode: "LI", name: "Liechtenstein", countryDialCode: "+423" },
  { countryCode: "LK", name: "Sri Lanka", countryDialCode: "+94" },
  { countryCode: "LR", name: "Liberia", countryDialCode: "+231" },
  { countryCode: "LS", name: "Lesotho", countryDialCode: "+266" },
  { countryCode: "LT", name: "Lithuania", countryDialCode: "+370" },
  { countryCode: "LU", name: "Luxembourg", countryDialCode: "+352" },
  { countryCode: "LV", name: "Latvia", countryDialCode: "+371" },
  { countryCode: "LY", name: "Libya", countryDialCode: "+218" },
  { countryCode: "MA", name: "Morocco", countryDialCode: "+212" },
  { countryCode: "MC", name: "Monaco", countryDialCode: "+377" },
  { countryCode: "MD", name: "Moldova, Republic of", countryDialCode: "+373" },
  { countryCode: "ME", name: "Montenegro", countryDialCode: "+382" },
  {
    countryCode: "MF",
    name: "Saint Martin (French part)",
    countryDialCode: "+590",
  },
  { countryCode: "MG", name: "Madagascar", countryDialCode: "+261" },
  { countryCode: "MH", name: "Marshall Islands", countryDialCode: "+692" },
  {
    countryCode: "MK",
    name: "Macedonia, the Former Yugoslav Republic of",
    countryDialCode: "+389",
  },
  { countryCode: "ML", name: "Mali", countryDialCode: "+223" },
  { countryCode: "MM", name: "Myanmar", countryDialCode: "+95" },
  { countryCode: "MN", name: "Mongolia", countryDialCode: "+976" },
  { countryCode: "MO", name: "Macao", countryDialCode: "+853" },
  {
    countryCode: "MP",
    name: "Northern Mariana Islands",
    countryDialCode: "+1-670",
  },
  { countryCode: "MQ", name: "Martinique", countryDialCode: "+596" },
  { countryCode: "MR", name: "Mauritania", countryDialCode: "+222" },
  { countryCode: "MS", name: "Montserrat", countryDialCode: "+1-664" },
  { countryCode: "MT", name: "Malta", countryDialCode: "+356" },
  { countryCode: "MU", name: "Mauritius", countryDialCode: "+230" },
  { countryCode: "MV", name: "Maldives", countryDialCode: "+960" },
  { countryCode: "MW", name: "Malawi", countryDialCode: "+265" },
  { countryCode: "MX", name: "Mexico", countryDialCode: "+52" },
  { countryCode: "MY", name: "Malaysia", countryDialCode: "+60" },
  { countryCode: "MZ", name: "Mozambique", countryDialCode: "+258" },
  { countryCode: "NA", name: "Namibia", countryDialCode: "+264" },
  { countryCode: "NC", name: "New Caledonia", countryDialCode: "+687" },
  { countryCode: "NE", name: "Niger", countryDialCode: "+227" },
  { countryCode: "NF", name: "Norfolk Island", countryDialCode: "+672" },
  { countryCode: "NG", name: "Nigeria", countryDialCode: "+234" },
  { countryCode: "NI", name: "Nicaragua", countryDialCode: "+505" },
  { countryCode: "NL", name: "Netherlands", countryDialCode: "+31" },
  { countryCode: "NO", name: "Norway", countryDialCode: "+47" },
  { countryCode: "NP", name: "Nepal", countryDialCode: "+977" },
  { countryCode: "NR", name: "Nauru", countryDialCode: "+674" },
  { countryCode: "NU", name: "Niue", countryDialCode: "+683" },
  { countryCode: "NZ", name: "New Zealand", countryDialCode: "+64" },
  { countryCode: "OM", name: "Oman", countryDialCode: "+968" },
  { countryCode: "PA", name: "Panama", countryDialCode: "+507" },
  { countryCode: "PE", name: "Peru", countryDialCode: "+51" },
  { countryCode: "PF", name: "French Polynesia", countryDialCode: "+689" },
  { countryCode: "PG", name: "Papua New Guinea", countryDialCode: "+675" },
  { countryCode: "PH", name: "Philippines", countryDialCode: "+63" },
  { countryCode: "PK", name: "Pakistan", countryDialCode: "+92" },
  { countryCode: "PL", name: "Poland", countryDialCode: "+48" },
  {
    countryCode: "PM",
    name: "Saint Pierre and Miquelon",
    countryDialCode: "+508",
  },
  { countryCode: "PN", name: "Pitcairn", countryDialCode: "+870" },
  { countryCode: "PR", name: "Puerto Rico", countryDialCode: "+1" },
  { countryCode: "PS", name: "Palestine, State of", countryDialCode: "+970" },
  { countryCode: "PT", name: "Portugal", countryDialCode: "+351" },
  { countryCode: "PW", name: "Palau", countryDialCode: "+680" },
  { countryCode: "PY", name: "Paraguay", countryDialCode: "+595" },
  { countryCode: "QA", name: "Qatar", countryDialCode: "+974" },
  { countryCode: "RE", name: "Reunion", countryDialCode: "+262" },
  { countryCode: "RO", name: "Romania", countryDialCode: "+40" },
  { countryCode: "RS", name: "Serbia", countryDialCode: "+381" },
  { countryCode: "RU", name: "Russian Federation", countryDialCode: "+7" },
  { countryCode: "RW", name: "Rwanda", countryDialCode: "+250" },
  { countryCode: "SA", name: "Saudi Arabia", countryDialCode: "+966" },
  { countryCode: "SB", name: "Solomon Islands", countryDialCode: "+677" },
  { countryCode: "SC", name: "Seychelles", countryDialCode: "+248" },
  { countryCode: "SD", name: "Sudan", countryDialCode: "+249" },
  { countryCode: "SE", name: "Sweden", countryDialCode: "+46" },
  { countryCode: "SG", name: "Singapore", countryDialCode: "+65" },
  { countryCode: "SH", name: "Saint Helena", countryDialCode: "+290" },
  { countryCode: "SI", name: "Slovenia", countryDialCode: "+386" },
  { countryCode: "SJ", name: "Svalbard and Jan Mayen", countryDialCode: "+47" },
  { countryCode: "SK", name: "Slovakia", countryDialCode: "+421" },
  { countryCode: "SL", name: "Sierra Leone", countryDialCode: "+232" },
  { countryCode: "SM", name: "San Marino", countryDialCode: "+378" },
  { countryCode: "SN", name: "Senegal", countryDialCode: "+221" },
  { countryCode: "SO", name: "Somalia", countryDialCode: "+252" },
  { countryCode: "SR", name: "Suriname", countryDialCode: "+597" },
  { countryCode: "SS", name: "South Sudan", countryDialCode: "+211" },
  { countryCode: "ST", name: "Sao Tome and Principe", countryDialCode: "+239" },
  { countryCode: "SV", name: "El Salvador", countryDialCode: "+503" },
  {
    countryCode: "SX",
    name: "Sint Maarten (Dutch part)",
    countryDialCode: "+1-721",
  },
  { countryCode: "SY", name: "Syrian Arab Republic", countryDialCode: "+963" },
  { countryCode: "SZ", name: "Swaziland", countryDialCode: "+268" },
  {
    countryCode: "TC",
    name: "Turks and Caicos Islands",
    countryDialCode: "+1-649",
  },
  { countryCode: "TD", name: "Chad", countryDialCode: "+235" },
  {
    countryCode: "TF",
    name: "French Southern Territories",
    countryDialCode: "+262",
  },
  { countryCode: "TG", name: "Togo", countryDialCode: "+228" },
  { countryCode: "TH", name: "Thailand", countryDialCode: "+66" },
  { countryCode: "TJ", name: "Tajikistan", countryDialCode: "+992" },
  { countryCode: "TK", name: "Tokelau", countryDialCode: "+690" },
  { countryCode: "TL", name: "Timor-Leste", countryDialCode: "+670" },
  { countryCode: "TM", name: "Turkmenistan", countryDialCode: "+993" },
  { countryCode: "TN", name: "Tunisia", countryDialCode: "+216" },
  { countryCode: "TO", name: "Tonga", countryDialCode: "+676" },
  { countryCode: "TR", name: "Turkey", countryDialCode: "+90" },
  { countryCode: "TT", name: "Trinidad and Tobago", countryDialCode: "+1-868" },
  { countryCode: "TV", name: "Tuvalu", countryDialCode: "+688" },
  {
    countryCode: "TW",
    name: "Taiwan, Province of China",
    countryDialCode: "+886",
  },
  {
    countryCode: "TZ",
    name: "United Republic of Tanzania",
    countryDialCode: "+255",
  },
  { countryCode: "UA", name: "Ukraine", countryDialCode: "+380" },
  { countryCode: "UG", name: "Uganda", countryDialCode: "+256" },
  {
    countryCode: "US",
    name: "United States",
    countryDialCode: "+1",
    suggested: true,
  },
  { countryCode: "UY", name: "Uruguay", countryDialCode: "+598" },
  { countryCode: "UZ", name: "Uzbekistan", countryDialCode: "+998" },
  {
    countryCode: "VA",
    name: "Holy See (Vatican City State)",
    countryDialCode: "+379",
  },
  {
    countryCode: "VC",
    name: "Saint Vincent and the Grenadines",
    countryDialCode: "+1-784",
  },
  { countryCode: "VE", name: "Venezuela", countryDialCode: "+58" },
  {
    countryCode: "VG",
    name: "British Virgin Islands",
    countryDialCode: "+1-284",
  },
  { countryCode: "VI", name: "US Virgin Islands", countryDialCode: "+1-340" },
  { countryCode: "VN", name: "Vietnam", countryDialCode: "+84" },
  { countryCode: "VU", name: "Vanuatu", countryDialCode: "+678" },
  { countryCode: "WF", name: "Wallis and Futuna", countryDialCode: "+681" },
  { countryCode: "WS", name: "Samoa", countryDialCode: "+685" },
  { countryCode: "XK", name: "Kosovo", countryDialCode: "+383" },
  { countryCode: "YE", name: "Yemen", countryDialCode: "+967" },
  { countryCode: "YT", name: "Mayotte", countryDialCode: "+262" },
  { countryCode: "ZA", name: "South Africa", countryDialCode: "+27" },
  { countryCode: "ZM", name: "Zambia", countryDialCode: "+260" },
  { countryCode: "ZW", name: "Zimbabwe", countryDialCode: "+263" },
];
