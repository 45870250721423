import React, { useEffect, useState } from "react";

import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { getValuefromLookup, getCountryNamefromCode } from "util/helpers";
import { countries } from "appRoutes/Contact/ContactForm/formIndex";
// import { countries } from "app/routes/Contact/components/ContactForm/formIndex";

const getGuardianName = (relations) => {
  let guardian = "N/A";
  if (relations.length !== 0) {
    relations.map((val) => {
      if (val.relation === "GUARDIAN") {
        guardian = val.relationName;
        // return;
      }
    });
  }
  return guardian;
};

// patient  detail
export default function PatientDetail(props) {
  const { contact, inPatient, accConfig, styles } = props;
  const [address, setAddress] = useState({});

  // Get Primary Address
  const getAddress = (addresses) => {
    let address = addresses[0];
    addresses.map((val) => {
      if (val.primary) {
        address = val;
      }
    });
    setAddress(address);
  };

  useEffect(() => {
    getAddress(contact.addresses);
  }, []);

  return (
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <View style={[styles.tableColHeader, { width: "20%" }]}>
          <Text style={styles.tableCellHeader}>INPATIENT NO.</Text>
        </View>
        <View style={[styles.tableColHeader, { width: "10%" }]}>
          <Text style={styles.tableCellHeader}>SEX</Text>
        </View>
        <View style={[styles.tableColHeader, { width: "30%" }]}>
          <Text style={styles.tableCellHeader}>PATIENT NAME</Text>
        </View>
        <View style={[styles.tableColHeader, { width: "25%" }]}>
          <Text style={styles.tableCellHeader}>GAURDIAN'S NAME</Text>
        </View>
        <View style={[styles.tableColHeader, { width: "15%" }]}>
          <Text style={styles.tableCellHeader}>RELATION</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableCol, { width: "20%" }]}>
          <Text style={styles.tableCell}>{contact.clientId}</Text>
        </View>

        <View style={[styles.tableCol, { width: "10%" }]}>
          <Text style={styles.tableCell}>
            {contact.personalInfo &&
              getValuefromLookup(accConfig.gender, contact.personalInfo.gender)}
          </Text>
        </View>

        <View style={[styles.tableCol, { width: "30%" }]}>
          <Text style={styles.tableCell}>{contact.name}</Text>
        </View>

        <View style={[styles.tableCol, { width: "25%" }]}>
          <Text style={styles.tableCell}>
            {getGuardianName(contact.relations)}
          </Text>
        </View>

        <View style={[styles.tableCol, { width: "15%" }]}>
          <Text style={styles.tableCell}>
            {getGuardianName(contact.relations)}
          </Text>
        </View>
      </View>

      <View style={styles.tableEmptyRow}>
        <View style={styles.tableEmptyCol}></View>
      </View>

      <View style={styles.tableRow}>
        <View style={[styles.tableColHeader, { width: "20%" }]}>
          <Text style={styles.tableCellHeader}>DATE OF BIRTH</Text>
        </View>
        <View style={[styles.tableColHeader, { width: "10%" }]}>
          <Text style={styles.tableCellHeader}>AGE</Text>
        </View>
        <View style={[styles.tableColHeader, { width: "30%" }]}>
          <Text style={styles.tableCellHeader}>MARITAL STATUS</Text>
        </View>
        <View style={[styles.tableColHeader, { width: "25%" }]}>
          <Text style={styles.tableCellHeader}>RELIGION</Text>
        </View>
        <View style={[styles.tableColHeader, { width: "15%" }]}>
          <Text style={styles.tableCellHeader}>OCCUPATION</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableCol, { width: "20%" }]}>
          <Text style={styles.tableCell}>
            {" "}
            {contact.personalInfo && contact.personalInfo.dateOfBirthDisplay}
          </Text>
        </View>
        <View style={[styles.tableCol, { width: "10%" }]}>
          <Text style={styles.tableCell}>
            {contact.personalInfo && contact.personalInfo.age}
          </Text>
        </View>
        <View style={[styles.tableCol, { width: "30%" }]}>
          <Text style={styles.tableCell}>
            {contact.personalInfo &&
              getValuefromLookup(
                accConfig.maritalStatus,
                contact.personalInfo.maritalStatus
              )}
          </Text>
        </View>
        <View style={[styles.tableCol, { width: "25%" }]}>
          <Text style={styles.tableCell}>
            {contact.personalInfo &&
              getValuefromLookup(
                accConfig.religion,
                contact.personalInfo.religion
              )}
          </Text>
        </View>
        <View style={[styles.tableCol, { width: "15%" }]}>
          <Text style={styles.tableCell}>
            {contact.personalInfo && contact.personalInfo.occupation}
          </Text>
        </View>
      </View>

      <View style={styles.tableEmptyRow}>
        <View style={styles.tableEmptyCol}></View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableCol, { width: "30%" }]}>
          <Text style={styles.tableCell}> How did you come into contact? </Text>
        </View>
        <View style={[styles.tableColLeft, { width: "70%" }]}>
          <Text style={styles.tableCell}> Reffered </Text>
        </View>
      </View>

      <View style={styles.tableEmptyRow}>
        <View style={styles.tableEmptyCol}></View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableCol, { width: "20%" }]}>
          <Text style={styles.tableCellHeader}> TEL NO. </Text>
        </View>
        <View style={[styles.tableCol, { width: "10%" }]}>
          <Text style={styles.tableCellHeader}> WARD NO. </Text>
        </View>
        <View style={[styles.tableCol, { width: "30%" }]}>
          <Text style={styles.tableCellHeader}> TOWN/VILLAGE </Text>
        </View>
        <View style={[styles.tableCol, { width: "25%" }]}>
          <Text style={styles.tableCellHeader}> DISTRICT </Text>
        </View>
        <View style={[styles.tableCol, { width: "15%" }]}>
          <Text style={styles.tableCellHeader}> COUNTRY </Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableCol, { width: "20%" }]}>
          <Text style={styles.tableCell}>{contact.phone}</Text>
        </View>
        <View style={[styles.tableCol, { width: "10%" }]}>
          <Text style={styles.tableCell}>{address.zipCode}</Text>
        </View>
        <View style={[styles.tableCol, { width: "30%" }]}>
          <Text style={styles.tableCell}>{address.address1}</Text>
        </View>
        <View style={[styles.tableCol, { width: "25%" }]}>
          <Text style={styles.tableCell}>{address.address2}</Text>
        </View>
        <View style={[styles.tableCol, { width: "15%" }]}>
          <Text style={styles.tableCell}>
            {getCountryNamefromCode(countries, address.countryCode)}
          </Text>
        </View>
      </View>

      <View style={styles.tableEmptyRow}>
        <View style={styles.tableEmptyCol}></View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableCol, { width: "20%" }]}>
          <Text style={styles.tableCellHeader}> DEPARTMENT </Text>
        </View>
        <View style={[styles.tableCol, { width: "10%" }]}>
          <Text style={styles.tableCellHeader}> DEPT/UNIT </Text>
        </View>
        <View style={[styles.tableCol, { width: "20%" }]}>
          <Text style={styles.tableCellHeader}> CODE </Text>
        </View>
        <View style={[styles.tableCol, { width: "10%" }]}>
          <Text style={styles.tableCellHeader}> WARD </Text>
        </View>
        <View style={[styles.tableCol, { width: "25%" }]}>
          <Text style={styles.tableCellHeader}> CODE </Text>
        </View>
        <View style={[styles.tableCol, { width: "15%" }]}>
          <Text style={styles.tableCellHeader}> BED NO. </Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableCol, { width: "20%" }]}>
          <Text style={styles.tableCell}>
            {inPatient.admission &&
              getValuefromLookup(
                accConfig.department,
                inPatient.admission.department
              )}
          </Text>
        </View>
        <View style={[styles.tableCol, { width: "10%" }]}>
          <Text style={styles.tableCell}>
            {inPatient.admission &&
              getValuefromLookup(accConfig.unit, inPatient.admission.unit)}
          </Text>
        </View>
        <View style={[styles.tableCol, { width: "20%" }]}>
          <Text style={styles.tableCell}>-</Text>
        </View>
        <View style={[styles.tableCol, { width: "10%" }]}>
          <Text style={styles.tableCell}>
            {inPatient.admission &&
              getValuefromLookup(accConfig.ward, inPatient.admission.ward)}
          </Text>
        </View>
        <View style={[styles.tableCol, { width: "25%" }]}>
          <Text style={styles.tableCell}>-</Text>
        </View>
        <View style={[styles.tableCol, { width: "15%" }]}>
          <Text style={styles.tableCell}>{inPatient.admission.bedNumber}</Text>
        </View>
      </View>

      <View style={styles.tableEmptyRow}>
        <View style={styles.tableEmptyCol}></View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableCol, { width: "30%" }]}>
          <Text style={styles.tableCellHeader}>
            {" "}
            DATE OF ADMISSION (YYYY-MM-DD){" "}
          </Text>
        </View>
        <View style={[styles.tableCol, { width: "45%" }]}>
          <Text style={styles.tableCellHeader}>
            {" "}
            DATE OF DISCHARGE (YYYY-MM-DD){" "}
          </Text>
        </View>
        <View style={[styles.tableCol, { width: "25%" }]}>
          <Text style={styles.tableCellHeader}> DURATION OF STAY </Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableCol, { width: "30%" }]}>
          <Text style={styles.tableCell}>
            {inPatient.admission ? inPatient.admission.dateDisplay : ""}
          </Text>
        </View>
        <View style={[styles.tableCol, { width: "45%" }]}>
          <Text style={styles.tableCell}>
            {inPatient.discharge ? inPatient.discharge.dateDisplay : ""}
          </Text>
        </View>
        <View style={[styles.tableCol, { width: "25%" }]}>
          <Text style={styles.tableCell}>{"-"}</Text>
        </View>
      </View>
    </View>
  );
}
