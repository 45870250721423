import React, { useEffect, useState } from "react";
import { useFieldArray, Controller } from "react-hook-form";

import {
  Grid,
  TextField,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment,
  Divider,
  Container,
  Avatar,
  CardHeader,
  IconButton,
  Tooltip,
  Button,
} from "@material-ui/core";

import { Refresh, Add as AddIcon, Clear, Close } from "@material-ui/icons";

import IntlMessages from "util/IntlMessages";
import { DateTimeInput } from "util/formInput";

export default function SurgeryHistoryForm(props) {
  const { control, errors, accConfig, register, getValues } = props;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "surgeryHistory",
    keyName: "keyId", //, default to "id", you can change the key name
  });

  const values = getValues({ nest: true });
  const onAdd = () => {
    append({
      name: "",
      date: null,
      note: "",
      performedBy: "",
    });
  };

  const onRemove = (index) => {
    remove(index);
  };
  const onReset = (index) => {
    remove(index);
    onAdd();
    // console.log('reset');
  };
  // console.log(errors);
  return (
    <div>
      {fields.map((item, index) => {
        return (
          <div key={item.keyId}>
            <Container style={{ paddingBottom: 25 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth margin="normal">
                    <Controller
                      label={<IntlMessages id="medicRecord.form.name" />}
                      as={TextField}
                      name={`surgeryHistory[${index}].name`}
                      control={control}
                      fullWidth
                      defaultValue={item.name}
                      error={
                        errors.surgeryHistory &&
                        errors.surgeryHistory[index] &&
                        errors.surgeryHistory[index].name
                      }
                      helperText={
                        errors.surgeryHistory &&
                        errors.surgeryHistory[index] &&
                        errors.surgeryHistory[index].name &&
                        errors.surgeryHistory[index].name.message
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth margin="normal">
                    <Controller
                      label={<IntlMessages id="medicRecord.form.note" />}
                      as={TextField}
                      name={`surgeryHistory[${index}].note`}
                      control={control}
                      fullWidth
                      multiline
                      defaultValue={item.note}
                      error={
                        errors.surgeryHistory &&
                        errors.surgeryHistory[index] &&
                        errors.surgeryHistory[index].note
                      }
                      helperText={
                        errors.surgeryHistory &&
                        errors.surgeryHistory[index] &&
                        errors.surgeryHistory[index].note &&
                        errors.surgeryHistory[index].note.message
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <Controller
                      label={<IntlMessages id="medicRecord.form.performedBy" />}
                      as={TextField}
                      name={`surgeryHistory[${index}].performedBy`}
                      control={control}
                      fullWidth
                      defaultValue={item.performedBy}
                      error={
                        errors.surgeryHistory &&
                        errors.surgeryHistory[index] &&
                        errors.surgeryHistory[index].performedBy
                      }
                      helperText={
                        errors.surgeryHistory &&
                        errors.surgeryHistory[index] &&
                        errors.surgeryHistory[index].performedBy &&
                        errors.surgeryHistory[index].performedBy.message
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={5} sm={5}>
                  <FormControl fullWidth margin="normal">
                    <DateTimeInput
                      value={
                        values.surgeryHistory &&
                        values.surgeryHistory[index] &&
                        values.surgeryHistory[index].date
                      }
                      label="Date"
                      name={`surgeryHistory[${index}].date`}
                      format="YYYY-MM-DD"
                      inputRef={register}
                      fullWidth
                      autoOk
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={1} sm={1}>
                  <Button
                    color="secondary"
                    aria-label="Remove"
                    onClick={() => onRemove(index)}
                    size="small"
                  >
                    <Tooltip
                      title={"Remove Surgery  " + (index + 1)}
                      size="large"
                    >
                      <Clear />
                    </Tooltip>
                  </Button>
                </Grid>
              </Grid>
            </Container>

            {index + 1 !== fields.length && <Divider />}
          </div>
        );
      })}
      <Tooltip title="Click to Add Surgery detail">
        <Button
          aria-label="Add"
          onClick={() => onAdd()}
          variant="contained"
          color="primary"
        >
          Add More <AddIcon />
        </Button>
      </Tooltip>
    </div>
  );
}
