import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

// Vendors Components
import {
  CardHeader,
  Tooltip,
  IconButton,
  Avatar,
  Button,
} from "@material-ui/core";

import { Skeleton } from "@material-ui/lab";
import { Alert } from "reactstrap";

import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Add as AddIcon,
} from "@material-ui/icons";

//  Themes Components
import Widget from "components/Widget/index";
import AlertBox from "components/AlertBox/index";
// Custom Components and Helpers

import { capitalize, displayDate } from "util/helpers";
//  Actions
import { onAddMedicationForm } from "redux/actions/MedicalActions";

const MedicalNotes = (props) => {
  const dispatch = useDispatch();
  const { contact } = useSelector(({ contactStore }) => contactStore);
  const { medicalrecord } = useSelector(({ medRecordStore }) => medRecordStore);

  const [cpage, setCpage] = useState(1);
  const [total, setTotal] = useState(1);

  const [currentNotes, setcurrentNotes] = useState([]);
  // on Add History
  const onAddHistory = (type = "add") => {
    dispatch(onAddMedicationForm(type, "notes"));
    // dispatch(onContactForm(contact, 'update'));
  };

  useEffect(() => {
    onLoadMore(1);
  }, [medicalrecord.notes]);

  const onLoadMore = (val) => {
    if (medicalrecord.notes) {
      const indexOfLastTodo = val * 3;
      const cNotes = medicalrecord.notes.slice(0, indexOfLastTodo);
      setCpage(val);
      setcurrentNotes(cNotes);
    }
  };

  return (
    <Widget styleName="jr-card-full jr-card-tabs-right jr-card-profile">
      <CardHeader
        action={
          <Tooltip title="Add a note for patient">
            <IconButton
              aria-label="Add"
              onClick={() => onAddHistory()}
              variant="contained"
              color="primary"
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        }
        title={"Notes"}
      />
      <div className="jr-tabs-content jr-task-list">
        {!medicalrecord.notes && (
          <AlertBox
            infotype="danger"
            message="No Notes Found."
            onClick={onAddHistory}
          />
        )}
        {medicalrecord.notes && (
          <div className="timeline-section">
            {currentNotes.map((value, index) => {
              return (
                <div className="timeline-panel " key={index}>
                  <div className="timeline-panel-header">
                    <div className="timeline-heading">
                      <div
                        span={18}
                        dangerouslySetInnerHTML={{
                          __html: value.note,
                        }}
                      />
                      <small>
                        by {value.createdBy} at{" "}
                        {displayDate(value.createdAt, "datetime", true)}
                      </small>
                    </div>
                  </div>
                  <div className="timeline-body"></div>
                </div>
              );
            })}
            {medicalrecord.notes.length !== currentNotes.length && (
              <Button
                onClick={() => onLoadMore(cpage + 1)}
                variant="contained"
                color="primary"
              >
                Load More <KeyboardArrowDownIcon />
              </Button>
            )}
          </div>
        )}
      </div>
    </Widget>
  );
};
export default MedicalNotes;
