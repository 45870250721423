const initialState = {
  isLoading: false,
  medicRecordError: null,
  is_saving: false,
  // medicalrecords of client
  //
  //
  medicalrecord: {},
  // medical record form
  savedTest: {},
  graphFor: null,
  updateMedicalFor: null,
  addMedicationModal: false,
  updateMedicalFormView: false,
  medformerror: null,
  medTests: { Others: [] },
  medTest: {},
  medTest_loading: false,
  medTestError: null,

  addMedModal: false,
  updateMedModal: false,
  formMedFor: null,
  // updateMedicalFor: 'diagnosis',
  // addMedicationModal:true,

  // inPatient Details
  //
  //
  inPatient: {},
  inPatientList: [],
  inpatientlist_error: null,
  inpatient_error: null,
  inpatientlist_loading: false,
  inpatient_loading: false,
  // inPatient Form
  updateInPatientFor: null,
  updateInPatientModal: false,
  addInPatientModal: false,
  inPatientFormError: null,

  // Daily Logs Details
  //
  //
  dailyLogs: [],
  dailyLog: {},
  dailyLog_error: null,
  dailyLog_loading: false,
  // inPatient Form
  updateDailyLogFor: null,
  updateDailyLogModal: false,
  addDailyLogModal: false,
  dailyLogFormError: null,

  // patient ews vital details
  patientEwsList: [],
  patientEws: {},
  vitalForm: false,
  ewslistloading: false,
  ewsloading: false,
  addEwsModal: false,
  updateEwsModal: false,
  ewsFormFor: "vital",
  ewsFormError: null,

  // TestGraph
  //
  //
  testGraph: {
    testName: "",
    testgrpName: "none",
    filteredTests: [],
    allTests: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    // // Patients Medications details
    //
    //
    //
    // get MedicRecord detail by Id
    case "GET_MEDREC_BYPATID_FULFILLED": {
      const records = action.payload.data;
      return {
        ...state,
        medicalrecord: records,
        isLoading: false,
        addFormModal: false,
        viewModal: false,
        medicRecordError: null,
      };
    }
    case "GET_MEDREC_BYPATID_PENDING": {
      return {
        ...state,
        medicalrecord: {},
        isLoading: true,
        medicRecordError: null,
      };
    }
    case "GET_MEDREC_BYPATID_REJECTED": {
      return {
        ...state,
        medicalrecord: {},
        isLoading: false,
        medicRecordError: action?.payload?.response?.data.message,
      };
    }

    // Patient medical record Save
    case "MEDICRECORD_SAVE_FULFILLED": {
      return {
        ...state,
        medicalrecord: action.payload.data,
        addMedicationModal: false,
        updateMedicalFormView: false,
        updateMedicalFor: null,
        medformerror: null,
        is_saving: false,
      };
    }
    case "MEDICRECORD_SAVE_PENDING": {
      return {
        ...state,
        is_saving: true,
        medformerror: false,
      };
    }
    case "MEDICRECORD_SAVE_REJECTED": {
      // console.log(action.payload);
      return {
        ...state,
        medformerror: action?.payload?.response?.data,
        is_saving: false,
        messages: [],
        errors: [],
      };
    }

    // // Set Investigation Tests
    case "SET_TEST": {
      return {
        ...state,
        testGraph: action.payload,
      };
    }

    // // toggle medication modal
    case "TOGGLE_GRAPH": {
      return {
        ...state,
        graphFor: action.payload.graphFor,
      };
    }

    // toggle medication modal
    case "MEDICATIONFORM": {
      return {
        ...state,
        addMedicationModal: action.payload.formtype === "add" ? true : false,
        updateMedicalFormView:
          action.payload.formtype === "update" ? true : false,
        updateMedicalFor: action.payload.formFor,
      };
    }

    // toggle medication modal to close
    case "MEDICFORMCLOSE": {
      return {
        ...state,
        // Medical Record
        //
        addMedicationModal: false,
        updateMedicalFormView: false,
        updateMedicalFor: null,
        medformerror: null,
        //
        //  Inpatient Form
        updateInPatientModal: false,
        addInPatientModal: false,
        updateInPatientFor: null,
        inPatientFormError: null,
        //
        // DailyLogs Form
        updateDailyLogFor: null,
        updateDailyLogModal: false,
        addDailyLogModal: false,
        dailyLogFormError: null,
        //
        // ews Form
        addEwsModal: false,
        updateEwsModal: false,
        ewsFormFor: "vital",
        ewsFormError: null,

        addMedModal: false,
        updateMedModal: false,
        formMedFor: null,
      };
    }

    // toggle medication modal
    case "TESTFORM": {
      return {
        ...state,
        addMedModal: action.payload.formtype === "add" ? true : false,
        updateMedModal: action.payload.formtype === "update" ? true : false,
        formMedFor: action.payload.formFor,
        // medTest
      };
    }
    // get Medic Test Record detail by PId
    case "GET_TESTREC_BYPATID_FULFILLED": {
      const records = action.payload.data;
      return {
        ...state,
        medTests: records,
        medTest_loading: false,
        addFormModal: false,
        viewModal: false,
        medTestError: null,
      };
    }
    case "GET_TESTREC_BYPATID_PENDING": {
      return {
        ...state,
        medTests: {},
        medTest_loading: true,
        medTestError: null,
      };
    }
    case "GET_TESTREC_BYPATID_REJECTED": {
      return {
        ...state,
        medTests: {},
        medTest_loading: false,
        medTestError: action?.payload?.response?.data.message,
      };
    }

    // Patient medical record Save
    case "TESTRECORD_SAVE_FULFILLED": {
      let record = action.payload.data;
      return {
        ...state,
        // medTests: { ...state.medTests, Others: [...state.Others, record] },
        savedTest: record,
        addMedModal: false,
        updateMedModal: false,
        // formMedFor: null,
        medformerror: null,
        is_saving: false,
      };
    }
    case "TESTRECORD_SAVE_PENDING": {
      return {
        ...state,
        savedTest: {},
        is_saving: true,
        medformerror: false,
      };
    }
    case "TESTRECORD_SAVE_REJECTED": {
      // console.log(action.payload);
      return {
        ...state,
        savedTest: {},
        medformerror: action?.payload?.response?.data,
        is_saving: false,
        messages: [],
        errors: [],
      };
    }

    //
    //
    //  Inpatient Store
    //
    //
    //
    //
    // toggle inpatient Form  modal
    case "INPATIENTFORM": {
      // console.log(action.payload);
      return {
        ...state,
        addInPatientModal: action.payload.formtype === "add" ? true : false,
        updateInPatientModal:
          action.payload.formtype === "update" ? true : false,
        updateInPatientFor: action.payload.formFor,
      };
    }

    // get Inpatient list by Patient Id
    case "GET_INPATIENTLIST_BYPATID_FULFILLED": {
      const records = action.payload.data;
      return {
        ...state,
        inPatientList: records,
        inpatientlist_loading: false,
        inpatientlist_error: false,
      };
    }
    case "GET_INPATIENTLIST_BYPATID_PENDING": {
      return {
        ...state,
        inPatientList: [],
        inpatientlist_loading: true,
        inpatientlist_error: null,
      };
    }
    case "GET_INPATIENTLIST_BYPATID_REJECTED": {
      return {
        ...state,
        inPatientList: [],
        inpatientlist_loading: false,
        inpatientlist_error: action?.payload?.response?.data.message,
      };
    }

    // get Inpatient detail by Id
    case "GET_INPATIENTDETAIL_BYID_FULFILLED": {
      const record = action.payload.data;
      return {
        ...state,
        inPatient: record,
        inpatient_loading: false,
        inpatient_error: false,
      };
    }
    case "GET_INPATIENTDETAIL_BYID_PENDING": {
      return {
        ...state,
        inPatient: [],
        inpatient_loading: true,
        inpatient_error: null,
      };
    }
    case "GET_INPATIENTDETAIL_BYID_REJECTED": {
      return {
        ...state,
        inPatient: [],
        inpatient_loading: false,
        inpatient_error: action?.payload?.response?.data.message,
      };
    }

    // InPatient detail Save
    case "INPATIENT_SAVE_FULFILLED": {
      // console.log(action.payload)
      return {
        ...state,
        inPatient: action.payload.data,
        inPatientList: [...state.inPatientList, action.payload.data],
        updateInPatientFor: false,
        updateInPatientModal: false,
        addInPatientModal: false,
        updateMedicalFor: null,
        inPatientFormError: null,
        is_saving: false,
      };
    }
    case "INPATIENT_SAVE_PENDING": {
      return {
        ...state,
        is_saving: true,
      };
    }
    case "INPATIENT_SAVE_REJECTED": {
      return {
        ...state,
        inPatientFormError: action?.payload?.response?.data,
        messages: [],
        errors: [],
        is_saving: false,
      };
    }

    //
    //
    //  Daily Logs Store
    //
    //
    //
    //
    // toggle daily log Form  modal
    case "DAILYLOGFORM": {
      // console.log(action.payload);
      return {
        ...state,
        addDailyLogModal: action.payload.formtype === "add" ? true : false,
        updateDailyLogModal:
          action.payload.formtype === "update" ? true : false,
        updateDailyLogFor: action.payload.formFor,
      };
    }

    // get dailylog detail by patient Id
    case "DAILYLOG_GET_FULFILLED": {
      const record = action.payload.data;
      return {
        ...state,
        dailyLog: record,
        dailyLog_loading: false,
        dailyLog_error: false,
      };
    }
    case "DAILYLOG_GET_PENDING": {
      return {
        ...state,
        inPatient: [],
        dailyLog_loading: true,
        dailyLog_error: null,
      };
    }
    case "DAILYLOG_GET_REJECTED": {
      return {
        ...state,
        dailyLog: {},
        dailyLog_loading: false,
        dailyLog_error: action?.payload?.response?.data.message,
      };
    }
    // get top 10 dailylogs detail by patient Id
    case "GET_DAILYLOGS_FULFILLED": {
      const record = action.payload.data;
      return {
        ...state,
        dailyLogs: record,
        dailyLog: record.length > 0 ? record[0] : {},
        dailyLog_loading: false,
        dailyLog_error: false,
      };
    }
    case "GET_DAILYLOGS_PENDING": {
      return {
        ...state,
        // inPatient: [],
        dailyLog_loading: true,
        dailyLog_error: null,
      };
    }
    case "GET_DAILYLOGS_REJECTED": {
      return {
        ...state,
        dailyLogs: [],
        dailyLog_loading: false,
        dailyLog_error: action?.payload?.response?.data.message,
      };
    }
    // set  daily log
    case "SET_DAILYLOG": {
      return {
        ...state,
        dailyLog: action.payload,
      };
    }
    // InPatient detail Save
    case "DAILYLOG_SAVE_FULFILLED": {
      // console.log(action.payload)
      return {
        ...state,
        dailyLog: action.payload.data,
        updateDailyLogFor: null,
        updateDailyLogModal: false,
        addDailyLogModal: false,
        dailyLogFormError: null,
        is_saving: false,
      };
    }
    case "DAILYLOG_SAVE_PENDING": {
      return {
        ...state,
        is_saving: true,
      };
    }
    case "DAILYLOG_SAVE_REJECTED": {
      return {
        ...state,
        dailyLogFormError: action?.payload?.response?.data,
        messages: [],
        errors: [],
        is_saving: false,
      };
    }

    //
    //
    //  Ews Store
    //
    //
    //
    //
    // toggle Ews Form  modal
    case "EWSFORM": {
      // console.log(action.payload);
      return {
        ...state,
        addEwsModal: action.payload.formtype === "add" ? true : false,
        updateEwsModal: action.payload.formtype === "update" ? true : false,
        ewsFormFor: action.payload.formFor,
      };
    }

    // get dailylog detail by patient Id
    case "EWSBY_PID_FULFILLED": {
      const record = action.payload.data;
      return {
        ...state,
        patientEwsList: record.reverse(),
        patientEws: record[record.length - 1],
        ewslistloading: false,
        ews_error: null,
      };
    }
    case "EWSBY_PID_PENDING": {
      return {
        ...state,
        patientEwsList: [],
        patientEws: {},
        ewslistloading: true,
        ews_error: null,
      };
    }
    case "EWSBY_PID_REJECTED": {
      return {
        ...state,
        patientEwsList: [],
        patientEws: {},
        ewslistloading: false,
        ews_error: action?.payload?.response?.data.message,
      };
    }
    // get ews detail by  ewsId
    case "EWSDETAILBY_ID_FULFILLED": {
      const record = action.payload.data;
      return {
        ...state,
        patientEws: record,
        ewsloading: false,
        ews_error: null,
      };
    }
    case "EWSDETAILBY_ID_PENDING": {
      return {
        ...state,
        patientEws: {},
        ewsloading: true,
        ews_error: null,
      };
    }
    case "EWSDETAILBY_ID_REJECTED": {
      return {
        ...state,
        patientEws: {},
        ewsloading: false,
        ews_error: action?.payload?.response?.data.message,
      };
    }
    // InPatient detail Save
    case "EWS_SAVE_FULFILLED": {
      // console.log(action.payload)
      return {
        ...state,
        patientEwsList: [...state.patientEwsList, action.payload.data],
        patientEws: action.payload.data,
        ewsFormFor: null,
        updateEwsModal: false,
        addEwsModal: false,
        ewsFormError: null,
        is_saving: false,
      };
    }
    case "EWS_SAVE_PENDING": {
      return {
        ...state,
        is_saving: true,
      };
    }
    case "EWS_SAVE_REJECTED": {
      return {
        ...state,
        ewsFormError: action?.payload?.response?.data,
        messages: [],
        errors: [],
        is_saving: false,
      };
    }
    // InPatient detail Save
    case "EWS_UPDATE_FULFILLED": {
      const patientEws = action.payload.data;
      return {
        ...state,
        patientEwsList: state.patientEwsList.map((item) =>
          item.id === patientEws.id ? patientEws : item
        ),
        patientEws: patientEws,
        ewsFormFor: null,
        updateEwsModal: false,
        addEwsModal: false,
        ewsFormError: null,
        is_saving: false,
      };
    }
    case "EWS_UPDATE_PENDING": {
      return {
        ...state,
        is_saving: true,
      };
    }
    case "EWS_UPDATE_REJECTED": {
      return {
        ...state,
        ewsFormError: action?.payload?.response?.data,
        messages: [],
        errors: [],
        is_saving: false,
      };
    }
    // override ews FReq
    case "EWS_OVERRIDE_FULFILLED": {
      // console.log(action.payload.data)
      return {
        ...state,
        patientEws: action.payload.data,
        ewsFormFor: null,
        updateEwsModal: false,
        addEwsModal: false,
        ewsFormError: null,
        is_saving: false,
      };
    }
    case "EWS_OVERRIDE_PENDING": {
      return {
        ...state,
        is_saving: true,
      };
    }
    case "EWS_OVERRIDE_REJECTED": {
      return {
        ...state,
        ewsFormError: action?.payload?.response?.data,
        is_saving: false,
        messages: [],
        errors: [],
      };
    }

    case "RESET_MEDICSTORE": {
      return initialState;
    }
    default:
      return state;
  }
};
