import React from "react";
import { connect } from "react-redux";

import { Tooltip, Fab } from "@material-ui/core";

import { Skeleton } from "@material-ui/lab";
import AlertBox from "components/AlertBox/index";
import Widget from "components/Widget/index";

import ContainerHeader from "components/ContainerHeader/index";
import IntlMessages from "util/IntlMessages";

import UserTable from "./UserTable";
import UserModal from "./UserModal";

import RestrictionDialog from "components/RestrictionDialog";

import { checkRestriction } from "util/check-auth";
import { getAllUsers } from "../actions";
import { onTogRestrictWarning } from "services/actions";

class UserPage extends React.Component {
  state = {
    addView: false,
    updateView: false,
    user: null,
  };
  componentDidMount = () => {
    this.props.getAllUsers();
    document.title = "Users Setting ";
  };

  onUpdate = (value) => {
    this.setState({
      addView: false,
      updateView: true,
      user: value,
    });
  };
  onAddUser = () => {
    let message = null;
    // message = checkRestriction(this.props.users.length, "USERS");
    // message = checkRestriction(6, 'USERS')
    // if (!message) {
    this.setState({
      addView: true,
      updateView: false,
      user: null,
    });
    // } else {
    //   this.props.onTogRestrictWarning(true, "USERS", message);
    // }
  };
  onClose = () => {
    this.setState({
      addView: false,
      updateView: false,
      user: null,
    });
  };
  render() {
    const { errors, users, loading, message } = this.props;
    const { addView, user, updateView } = this.state;

    return (
      <>
        <ContainerHeader
          match={this.props.match}
          title={<IntlMessages id="pages.users" />}
        />
        <Widget>
          <div className="row">
            {users.length !== 0 && <UserTable onUpdate={this.onUpdate} />}
          </div>
          {!loading && users.length === 0 && (
            <AlertBox
              infotype="danger"
              message="No Users Found. Click Add"
              onClick={this.onAddUser}
            />
          )}

          {loading && <Skeleton variant="rect" height={100} />}
        </Widget>
        {<RestrictionDialog />}
        {
          <UserModal
            addView={addView}
            updateView={updateView}
            user={user}
            onClose={this.onClose}
            isProfile={false}
          />
        }
        <div className="bottom-button">
          <Tooltip title="Add User" aria-label="Add User">
            <Fab
              color="primary"
              aria-label="add"
              onClick={() => this.onAddUser()}
            >
              <i className="zmdi zmdi-plus zmdi-hc-fw zmdi-hc-lg" />
            </Fab>
          </Tooltip>
        </div>
      </>
    );
  }
}

export default connect(
  (state) => {
    return {
      loading: state.settingStore.usersLoading,
      errors: state.settingStore.errors,
      error: state.settingStore.error,
      users: state.settingStore.users,
      user: state.settingStore.user,
    };
  },
  { getAllUsers, onTogRestrictWarning }
)(UserPage);
