import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

// Vendors Components
import {
  CardHeader,
  Tooltip,
  IconButton,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
} from "@material-ui/core";

import {
  AccountCircle,
  CheckCircle as CheckCircleIcon,
  Edit as EditIcon,
} from "@material-ui/icons";

//  Themes Components

import Auxiliary from "util/Auxiliary";
import Widget from "components/Widget/index";
import AlertBox from "components/AlertBox/index";
// Custom Components and Helpers
// import { capitalize } from "util/helpers";

// Components
import NoDetail from "../NoDetail";
// import ChiefComplaints from "./ChiefComplaints";
// import Illnesses from "./Illnesses";

import { getValuefromLookup, displayDate } from "util/helpers";
//  Actions
import { onInPatientForm } from "redux/actions/MedicalActions";
import { getInvestigation } from "redux/actions/Setting";

const Investigation = (props) => {
  const dispatch = useDispatch();
  //   const { contact } = useSelector(({ contactStore }) => contactStore);

  const { accConfig } = useSelector(({ uiConfigureStore }) => uiConfigureStore);
  const { inPatient, inpatient_loading } = useSelector(
    ({ medRecordStore }) => medRecordStore
  );

  useEffect(() => {
    if (accConfig.investigations.length === 0) {
      dispatch(getInvestigation());
    }
  }, []);

  const { inverted = "timeline-inverted" } = props;

  const onAddInpatientRecord = (formtype = "labworks") => {
    dispatch(onInPatientForm("update", formtype));
  };
  return (
    <div className={"timeline-item " + inverted}>
      {!inPatient.investigation && (
        <NoDetail
          title={"Test Records"}
          loading={inpatient_loading}
          onAddInpatientRecord={onAddInpatientRecord}
          // inverted={inverted}
        />
      )}
      {inPatient.investigation && inPatient.investigation.tests && (
        <div>
          {/* <div className="timeline-time">
            {inPatient.admission.dateDisplay}
            <br /> {inPatient.admission.timeDisplay}
          </div> */}
          <div className={`timeline-badge bg-green`}>
            <AccountCircle />
          </div>

          <div className={"timeline-panel mytimeline"}>
            <CardHeader
              action={
                inPatient.id && (
                  <Tooltip title="Update Investigation Info ">
                    <IconButton
                      aria-label="Add"
                      onClick={() => onAddInpatientRecord("labworks")}
                      variant="contained"
                      color="primary"
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                )
              }
              title={"Investigation Records"}
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs>
                  <List>
                    {inPatient.investigation &&
                      inPatient.investigation.tests.map((value, index) => {
                        return (
                          <div key={index}>
                            <ListItem alignItems="flex-start">
                              <ListItemAvatar>
                                <CheckCircleIcon
                                  color="secondary"
                                  fontSize="large"
                                />
                              </ListItemAvatar>
                              <ListItemText
                                primary={displayDate(value.dateTimeDisplay)}
                                secondary={
                                  <div>
                                    <strong>
                                      {getValuefromLookup(
                                        accConfig.investigations,
                                        value.testItem
                                      )}{" "}
                                      :
                                    </strong>{" "}
                                    {value.result} {value.unit}
                                    <p>{value.note}</p>
                                  </div>
                                }
                              />
                            </ListItem>
                            {index + 1 !==
                              inPatient.investigation.tests.length && (
                              <Divider variant="inset" component="li" />
                            )}
                          </div>
                        );
                      })}
                    {/* <Divider component="li" />
                    <ListItem button>
                      <ListItemText primary={<h2> Imaging Works Info</h2>} />
                      <Tooltip title="Update LabWorks Info ">
                        <IconButton
                          aria-label="Add"
                          onClick={() => onAddInpatientRecord("imageworks")}
                          variant="contained"
                          color="primary"
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </ListItem>
                    {inPatient.investigation.imagingWorks &&
                      inPatient.investigation.imagingWorks.map(
                        (value, index) => {
                          return (
                            <div key={index}>
                              <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                  <CheckCircleIcon
                                    color="secondary"
                                    fontSize="large"
                                  />
                                </ListItemAvatar>
                                <ListItemText
                                  primary={value.dateDisplay}
                                  secondary={
                                    <div>
                                      <strong>{value.imagingWorkItem}</strong>
                                      <p>{value.result}</p>
                                      <p>{value.note}</p>
                                    </div>
                                  }
                                />
                              </ListItem>
                              {index + 1 !==
                                inPatient.investigation.imagingWorks.length && (
                                <Divider variant="inset" component="li" />
                              )}
                            </div>
                          );
                        }
                      )} */}
                  </List>
                </Grid>
              </Grid>
            </CardContent>
          </div>
        </div>
      )}
    </div>
  );
};
export default Investigation;
