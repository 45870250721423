import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import {
  CardHeader,
  Button,
  ButtonGroup,
  IconButton,
  Tooltip,
  TableContainer,
} from "@material-ui/core";

import Widget from "components/Widget";
import IntlMessages from "util/IntlMessages";

import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Brush,
  Bar,
  ReferenceLine,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as TooltipChart,
  Legend,
  LabelList,
} from "recharts";

import { Edit as EditIcon, Close as CloseIcon } from "@material-ui/icons";

import { onAddMedicationForm, onToggleGraphView } from "redux/actions/MedicalActions";

import AlertBox from "components/AlertBox";

import { getValuefromLookup, getDefaultUnit, displayDate } from "util/helpers";
const initData = [
  {
    dateDisplay: moment().format("MMM Do YY"),
    "Weight in KG": 0,
    "Height in Cm": 0,
  },
];

const HeightWeight = (props) => {
  const dispatch = useDispatch();
  const { contact } = useSelector(({ contactStore }) => contactStore);
  const { accConfig, accountDefaultUnits } = useSelector(
    ({ configStore }) => configStore
  );
  const {
    medicalrecord,
    updateMedicalFormView,
    updateMedicalFor,
  } = useSelector(({ medRecordStore }) => medRecordStore);
  //   console.log(updateMedicalFormView);

  const [data, setData] = useState(initData);
  const [wlabel, setWLabel] = useState("Weight in Kg");
  const [hlabel, setHLabel] = useState("Height in Inches");
  const [bmiLabel, setBmiLabel] = useState("BMI Range");

  useEffect(() => {
    onBmiData();
  }, [medicalrecord]);

  useEffect(() => {
    onBmiData();
    accountDefaultUnits.weightUnit === "kg"
      ? setWLabel("Weight in KG")
      : setWLabel("Weight in lb");
  }, [accountDefaultUnits]);

  const onAddHistory = () => {
    dispatch(onAddMedicationForm("update", "heightWeight"));
  };

  const onBmiData = () => {
    let allDatalist = [];
    allDatalist = medicalrecord.weightHistory.map((val) => {
      medicalrecord.heightHistory.map((height) => {
        if (height.dateTimeDisplay === val.dateTimeDisplay) {
          val.dateDisplay = displayDate(val.dateTimeDisplay, "dateR");
          val.heightInInches = height.heightInInches;
          val.heightText = height.heightText;
          val.weightVal =
            accountDefaultUnits.weightUnit === "kg"
              ? val.weight
              : val.weightInLbs;
        }
      });
      medicalrecord.bodyMassIndexHistory.map((bmi) => {
        if (bmi.dateTimeDisplay === val.dateTimeDisplay) {
          val.bmi = bmi.bmi;
          val.bmiRange = bmi.bmi + " " + bmi.range;
        }
      });
      return val;
    });
    console.log(allDatalist);
    setData(allDatalist.reverse());
  };

  return (
    <Widget styleName="jr-card-full jr-card-tabs-right jr-card-profile">
      <CardHeader
        action={
          <>
            <Tooltip title="Update Height/Weight">
              <IconButton
                aria-label="Add"
                onClick={() => onAddHistory()}
                variant="contained"
                color="primary"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </>
        }
        title="Height/Weight"
      />
      <div className="jr-tabs-content jr-task-list">
        {(medicalrecord.weightHistory &&
          medicalrecord.weightHistory.length > 0) ||
          (!medicalrecord.weightHistory && (
            <AlertBox
              infotype="danger"
              message="No Weight History Found."
              onClick={onAddHistory}
            />
          ))}

        {data.length > 0 && (
          <TableContainer>
            <ResponsiveContainer width="100%" height={400}>
              <ComposedChart
                width={"100%"}
                data={data}
                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
              >
                <defs>
                  <linearGradient
                    id="salesStatistic"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop offset="5%" stopColor="#4258BC" stopOpacity={1} />
                    <stop offset="95%" stopColor="#FFF" stopOpacity={0.8} />
                  </linearGradient>
                </defs>
                <CartesianGrid stroke="#f5f5f5" />

                <XAxis
                  dataKey="dateDisplay"
                  padding={{ left: 30, right: 30 }}
                />
                <YAxis padding={{ top: 30 }} />
                <TooltipChart />
                <Legend />
                <ReferenceLine y={0} stroke="#000" />
                <Brush
                  dataKey="dateDisplay"
                  height={30}
                  stroke="#8884d8"
                  // startIndex={brushIndex.start}
                  // endIndex={brushIndex.end}
                  startIndex={data.length < 6 ? 0 : data.length - 5}
                  endIndex={data.length - 1}
                />

                <Area
                  type="monotone"
                  dataKey="weightVal"
                  // fill="#8884d8"
                  fill="url(#salesStatistic)"
                  stroke="#8884d8"
                  strokeWidth={2}
                  name={wlabel}
                >
                  <LabelList dataKey="weightVal" position="top" />
                </Area>

                <Line
                  type="monotone"
                  dataKey="heightInInches"
                  stroke="#ff7300"
                  name={hlabel}
                  strokeWidth={2}
                >
                  <LabelList dataKey="heightText" position="top" />
                </Line>

                <Line
                  type="monotone"
                  dataKey="bmi"
                  stroke="#4caf50"
                  name={bmiLabel}
                  strokeWidth={2}
                >
                  <LabelList dataKey="bmiRange" position="top" />
                </Line>
              </ComposedChart>
            </ResponsiveContainer>
          </TableContainer>
        )}
      </div>
    </Widget>
  );
};

export default HeightWeight;
