import React, { useEffect } from "react";

import moment from "moment";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
// Pdf Header detail
export default function ReportSign(props) {
  const { contact, inPatient, accConfig, styles } = props;
  return (
    <View style={styles.signature}>
      <View style={styles.tableRow}>
        <View style={{ width: "70%" }}>
          <Text style={styles.signatureCell}>Written By :</Text>
          <Text style={styles.signatureCell}>
            {inPatient.discharge && inPatient.discharge.reportPreparedBy}
          </Text>
        </View>
        <View style={{ width: "30%" }}>
          <View>
            <View>
              <Text style={styles.signatureCell}>
                ______________________________
              </Text>
            </View>
            <View>
              <Text style={styles.signatureCell}>
                Date: {moment().format("MMMM Do YYYY, h:mm a")}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}
