const initialState = {
  loading: false,
  messages: [],
  errors: [],
  formerror: null,
  error: "",

  // Notes
  errorMsg: "",
  taskError: null,
  tasks: [],
  tasksLoading: false,
  // NoteForm
  task: {},
  aboutType: "other",
  addFormTask: false,
  updateFormTask: false,
  viewTask: false,
  is_saving: false,
  taskTags: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    // get client detail by Id
    case "GET_ALLTASKS_FULFILLED": {
      const tasks = action.payload.data;
      return {
        ...state,
        tasks: tasks,
        tasksLoading: false,
        taskError: null,
      };
    }
    case "GET_ALLTASKS_PENDING": {
      return {
        ...state,
        tasks: [],
        tasksLoading: true,
        taskError: null,
      };
    }
    case "GET_ALLTASKS_REJECTED": {
      return {
        ...state,
        error: action.payload.response.data,
        tasks: [],
        messages: [],
        errors: [],
        tasksLoading: false,
        taskError: action.payload.response.data.message,
      };
    }
    case "TASKFORM": {
      let formType = action.payload.formParam.formType;
      let aboutType = action.payload.formParam.aboutType;
      // console.log(action.payload);
      return {
        ...state,
        task:
          formType === "update" || formType === "view"
            ? action.payload.task
            : null,
        addFormTask: formType === "add" ? true : false,
        updateFormTask: formType === "update" ? true : false,
        viewTask: formType === "view" ? true : false,
        aboutType: aboutType,
      };
    }
    case "INBOXCLOSE": {
      // console.log(action.payload)
      return {
        ...state,
        task: null,
        addFormTask: false,
        updateFormTask: false,
        viewTask: false,
        aboutType: "other",
      };
    }

    // Save Task Notes
    case "TASK_ADD_FULFILLED": {
      const tasks = [action.payload.data, ...state.tasks];
      // console.log(action.payload.data);
      // console.log(tasks);
      return {
        ...state,
        tasks: [action.payload.data, ...state.tasks],
        task: action.payload.data,
        viewTask: true,
        updateFormTask: false,
        addFormTask: false,
        formerror: null,
        is_saving: false,
      };
    }
    case "TASK_ADD_PENDING": {
      return {
        ...state,
        is_saving: true,
      };
    }
    case "TASK_ADD_REJECTED": {
      return {
        ...state,
        formerror: action.payload.response.data,
        is_saving: false,
        messages: [],
        errors: [],
      };
    }

    // Update Contacts
    case "TASK_UPDATE_FULFILLED": {
      const task = action.payload.data;
      const tasks = state.tasks.map((item) =>
        item.id === task.id ? task : item
      );
      // console.log(tasks);
      return {
        ...state,
        tasks: state.tasks.map((item) => (item.id === task.id ? task : item)),
        task: task,
        viewTask: true,
        updateFormTask: false,
        addFormTask: false,
        formerror: null,
        is_saving: false,
      };
    }
    case "TASK_UPDATE_PENDING": {
      return {
        ...state,
        is_saving: true,
      };
    }
    case "TASK_UPDATE_REJECTED": {
      return {
        ...state,
        formerror: action.payload.response.data,
        messages: [],
        errors: [],
        is_saving: false,
      };
    }

    // Delete Contacts
    case "TASK_DELETE_FULFILLED": {
      let task = action.payload.data;
      // task.status = "DELETED";
      // console.log(task);
      return {
        ...state,
        tasks: state.tasks.map((item) => (item.id === task.id ? task : item)),
        task: {},
        viewTask: false,
        updateFormTask: false,
        addFormTask: false,
        formerror: null,
        is_saving: false,
      };
    }

    case "TASK_ADD_INBOXSTORE": {
      return initialState;
    }

    // Note Tags
    case "TASKTAGS_FULFILLED": {
      // console.log(action.payload)
      return {
        ...state,
        taskTags: action.payload.data,
      };
    }
    case "TASKTAGS_PENDING": {
      return {
        ...state,
      };
    }
    case "TASKTAGS_REJECTED": {
      // console.log(action)
      return {
        ...state,
        taskTags: [],
        error: action.payload.response,
        messages: [],
        errors: [],
      };
    }
    default:
      return state;
  }
};
