import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from "react-hook-form";

import moment from "moment";
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  InputAdornment,
  Container,
  Avatar,
  CardHeader,
  Paper,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
  Grow,
} from "@material-ui/core";

import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

import {
  Face as FaceIcon,
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";

import { DateTimeInput } from "util/formInput";

import IntlMessages from "util/IntlMessages";

import { getICDcodes } from "redux/actions/Setting";

export default function InvestigationForm(props) {
  const dispatch = useDispatch();
  const {
    control,
    register,
    setValue,
    getValues,
    errors,
    accConfig,
    watch,
  } = props;

  const values = getValues({ nest: true });

  const watcht = watch("unit");
  const filterOptions = createFilterOptions({
    stringify: (option) => option.group + option.value,
  });

  // get investigation json value
  const getInvestValue = (val) => {
    let value =
      accConfig.investigations.length !== 0
        ? accConfig.investigations.find((o) => o.key === val)
        : "";
    return value;
  };
  const onTestItemChange = (data, index) => {
    if (data && data.unit) {
      setValue(`unit`, data.unit);
    } else {
      setValue(`unit`, "");
    }
    return data;
  };
  return (
    <Container>
      <input
        ref={register}
        name={`unit`}
        type="text"
        hidden
        defaultValue={values.unit}
      />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <DateTimeInput
              value={values.date}
              label="Date"
              name="date"
              format="YYYY-MM-DD"
              inputRef={register}
              fullWidth
              autoOk
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <DateTimeInput
              value={values.time}
              label="Time"
              name="time"
              inputRef={register}
              fullWidth
              type="time"
              ampm={false}
              autoOk
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal" error={errors.testItem}>
            <Controller
              as={
                <Autocomplete
                  options={accConfig.investigations}
                  getOptionLabel={(option) =>
                    option.display ? option.display : option.value
                  }
                  renderOption={(option) => (
                    <span>
                      {option.display ? option.display : option.value}
                    </span>
                  )}
                  filterOptions={filterOptions}
                  groupBy={(option) => option.group}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={errors.testItem}
                      label={
                        <IntlMessages id="inpatient.form.investigation.testItem" />
                      }
                    />
                  )}
                />
              }
              onChange={([, data]) => onTestItemChange(data)}
              name={`testItem`}
              control={control}
              defaultValue={values.testItem && getInvestValue(values.testItem)}
            />
            <FormHelperText>
              {errors.testItem && errors.testItem.message}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Controller
              label={
                <span>
                  <IntlMessages id="inpatient.form.investigation.result" />{" "}
                  {watcht && <strong>({watcht})</strong>}
                </span>
              }
              as={TextField}
              name="result"
              control={control}
              fullWidth
              error={errors.result}
              helperText={errors.result && errors.result.message}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Container>
  );
}
