import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from "react-hook-form";

import moment from "moment";
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Container,
  CardHeader,
} from "@material-ui/core";

import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

import { DateTimeInput } from "util/formInput";

import IntlMessages from "util/IntlMessages";

import { getICDcodes } from "redux/actions/Setting";

export default function MedicalNoteForm(props) {
  const dispatch = useDispatch();
  const { control, register, getValues, errors } = props;

  const values = getValues({ nest: true });

  return (
    <div>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal">
              <Controller
                label={<IntlMessages id="dailylog.form.dailynote" />}
                as={TextField}
                name="note.note"
                control={control}
                fullWidth
                error={errors.note && errors.note.note}
                helperText={
                  errors.mote && errors.note.note && errors.note.note.message
                }
                multiline
                rowsMax={4}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
