import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import CustomScrollbars from "util/CustomScrollbars";
import { getColor, displayDate } from "util/helpers";
import { onTemplateForm, onTemplateClose } from "../actions";

const TemplateDetail = (props) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [showDetail, setShowDetail] = useState(true);

  const { template, width } = props;

  const handleClick = (event) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleRequestClose = () => {
    setOpen(false);
  };

  // onCloseNoteView Modal
  const onClose = () => {
    dispatch(onTemplateClose());
  };
  // on Update
  const onMenuSelect = (option) => {
    option === "Update" && dispatch(onTemplateForm(template, "update"));
  };

  const options = ["Update", "Delete"];

  return (
    <div className="module-detail mail-detail">
      <CustomScrollbars
        className="module-list-scroll scrollbar"
        style={{
          height: width >= 1200 ? "calc(100vh - 309px)" : "calc(100vh - 288px)",
        }}
      >
        <div className="mail-header">
          <div style={{ paddingRight: "15px" }}>
            <IconButton className="icon-btn" onClick={() => onClose()}>
              <i className="zmdi zmdi-arrow-back" />
            </IconButton>
          </div>
          <div className="mail-header-content col pl-0">
            <div className="subject">{template.name}</div>

            <div className="labels">{template.subject}</div>
          </div>
        </div>
        <hr />
        <div className="mail-user-info">
          {template.createdBy && (
            <div
              className="avatar rounded-circle size-40 text-white text-center"
              style={{
                fontSize: 16,
                backgroundColor: getColor(template.createdBy),
              }}
            >
              {template.createdBy.charAt(0).toUpperCase()}
            </div>
          )}

          <div className="sender-name">
            {template.createdBy}
            <div className="send-to text-grey">
              <div
                className={`badge text-white `}
                style={{
                  backgroundColor:
                    template.status === "ACTIVE" ? "green" : "red",
                }}
              >
                {template.status}
              </div>
            </div>
          </div>

          <IconButton
            aria-label="More"
            aria-owns={open ? "long-SidenavContent.js" : null}
            aria-haspopup
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>

          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => handleRequestClose()}
            MenuListProps={{
              style: {
                width: 200,
              },
            }}
          >
            {options.map((option) => (
              <MenuItem
                key={option}
                onClose={() => handleRequestClose()}
                onClick={() => onMenuSelect(option)}
              >
                {option}
              </MenuItem>
            ))}
          </Menu>
        </div>

        <div className="mt-2">
          <strong>Date: </strong>
          {displayDate(template.createdAt, "datetime")}
        </div>
        <div>
          {template.personalization && template.personalization.salutation}
          {template.personalization &&
            template.personalization.useFirstNameOnly && (
              <span> FirstName</span>
            )}
          {template.personalization && template.personalization.endsWith}
        </div>
        <p
          className="message"
          dangerouslySetInnerHTML={{ __html: template.body }}
        ></p>
        {/* {template.hasAttachments && (
            <div className="attachment-block">
              <h3>Attachments ({template.hasAttachments.length})</h3>
              <div className="row">
                {template.attachments.map((attachment, index) => (
                  <div className="col-3" key={index}>
                    <img
                      className="size-100"
                      src={attachment.preview}
                      alt={attachment.fileName}
                    />
                    <div className="size">{attachment.size}</div>
                  </div>
                ))}
              </div>
            </div>
          )} */}
      </CustomScrollbars>
    </div>
  );
};

export default TemplateDetail;
