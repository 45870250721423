import React, { useEffect, useState } from "react";
import { useFieldArray, Controller } from "react-hook-form";

import {
  Grid,
  TextField,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment,
  Divider,
  Container,
  Avatar,
  CardHeader,
  IconButton,
  Tooltip,
  Button,
} from "@material-ui/core";

import { DateTimeInput } from "util/formInput";
import { Refresh, Add as AddIcon, Clear, Close } from "@material-ui/icons";

import IntlMessages from "util/IntlMessages";

export default function OperationForm(props) {
  const { control, errors, accConfig, register, getValues, setValue } = props;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "surgeries",
    keyName: "keyId", //, default to "id", you can change the key name
  });

  const values = getValues({ nest: true });
  const onAdd = () => {
    append({
      date: null,
      code: "",
      name: "",
      performedBy: "",
    });
  };

  const onRemove = (index) => {
    remove(index);
  };

  useEffect(() => {
    console.log(fields);
    if (fields.length === 0) {
      onAdd();
    }
  }, []);

  const onReset = (index) => {
    remove(index);
    onAdd();
    // console.log('reset');
  };
  // console.log(errors);
  return (
    <div>
      {fields.map((item, index) => {
        return (
          <div key={item.keyId}>
            <Container style={{ paddingBottom: 25 }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl fullWidth margin="normal">
                    <DateTimeInput
                      value={
                        values.surgeries &&
                        values.surgeries[index] &&
                        values.surgeries[index].date
                      }
                      label="Date"
                      name={`surgeries[${index}].date`}
                      format="YYYY-MM-DD"
                      inputRef={register}
                      fullWidth
                      autoOk
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  {/* <FormControl fullWidth margin="normal">
                    <InputLabel htmlFor="unit">
                      <IntlMessages id="inpatient.form.surgeries.code" />
                    </InputLabel>
                    <Controller
                      as={
                        <Select>
                          <MenuItem value="" disabled>
                            {" "}
                            <IntlMessages id="inpatient.form.surgeries.code" />
                          </MenuItem>
                          {accConfig.diagnosisType && accConfig.diagnosisType.map((val) => (
                            <MenuItem value={val.key} key={val.key}>
                              {val.value}
                            </MenuItem>
                          ))}
                        </Select>
                      }
                      name={`surgeries[${index}].code`}
                      label={
                        <IntlMessages id="inpatient.form.surgeries.code" />
                      }
                      control={control}
                      fullWidth
                      defaultValue={item.code}
                      error={
                        errors.surgeries &&
                        errors.surgeries[index] &&
                        errors.surgeries[index].code
                      }
                      helperText={
                        errors.surgeries &&
                        errors.surgeries[index] &&
                        errors.surgeries[index].code &&
                        errors.surgeries[index].code.message
                      }
                    />
                  </FormControl> */}
                  <FormControl fullWidth margin="normal">
                    <Controller
                      label={
                        <IntlMessages id="inpatient.form.surgeries.code" />
                      }
                      as={TextField}
                      name={`surgeries[${index}].code`}
                      control={control}
                      fullWidth
                      defaultValue={item.code}
                      error={
                        errors.surgeries &&
                        errors.surgeries[index] &&
                        errors.surgeries[index].code
                      }
                      helperText={
                        errors.surgeries &&
                        errors.surgeries[index] &&
                        errors.surgeries[index].code &&
                        errors.surgeries[index].code.message
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl fullWidth margin="normal">
                    <Controller
                      label={
                        <IntlMessages id="inpatient.form.surgeries.name" />
                      }
                      as={TextField}
                      name={`surgeries[${index}].name`}
                      control={control}
                      fullWidth
                      defaultValue={item.name}
                      error={
                        errors.surgeries &&
                        errors.surgeries[index] &&
                        errors.surgeries[index].name
                      }
                      helperText={
                        errors.surgeries &&
                        errors.surgeries[index] &&
                        errors.surgeries[index].name &&
                        errors.surgeries[index].name.message
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth margin="normal">
                    <Controller
                      label={
                        <IntlMessages id="inpatient.form.surgeries.performedBy" />
                      }
                      as={TextField}
                      name={`surgeries[${index}].performedBy`}
                      control={control}
                      fullWidth
                      defaultValue={item.performedBy}
                      error={
                        errors.surgeries &&
                        errors.surgeries[index] &&
                        errors.surgeries[index].performedBy
                      }
                      helperText={
                        errors.surgeries &&
                        errors.surgeries[index] &&
                        errors.surgeries[index].performedBy &&
                        errors.surgeries[index].performedBy.message
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={11}>
                  <FormControl fullWidth margin="normal">
                    <Controller
                      label={
                        <IntlMessages id="inpatient.form.surgeries.note" />
                      }
                      as={TextField}
                      name={`surgeries[${index}].note`}
                      control={control}
                      fullWidth
                      defaultValue={item.note}
                      error={
                        errors.surgeries &&
                        errors.surgeries[index] &&
                        errors.surgeries[index].note
                      }
                      helperText={
                        errors.surgeries &&
                        errors.surgeries[index] &&
                        errors.surgeries[index].note &&
                        errors.surgeries[index].note.message
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={1} sm={1}>
                  <Button
                    color="secondary"
                    aria-label="Remove"
                    onClick={() => onRemove(index)}
                    size="small"
                  >
                    <Tooltip
                      title={"Remove Operation Notes  " + (index + 1)}
                      size="large"
                    >
                      <Clear />
                    </Tooltip>
                  </Button>
                </Grid>
              </Grid>
            </Container>

            {index + 1 !== fields.length && <Divider />}
          </div>
        );
      })}
      <Tooltip title="Click to Add Operation detail">
        <Button
          aria-label="Add"
          onClick={() => onAdd()}
          variant="contained"
          color="primary"
        >
          Add More <AddIcon />
        </Button>
      </Tooltip>
    </div>
  );
}
