import React from "react";
import Checkout from "../../Checkout";
import CheckoutModal from "../../Checkout/CheckoutModal";
import ChiefComplaint from "../../ChiefComplaint";
import ComplainModal from "../../ChiefComplaint/ComplainModal";
import Observations from "../../Observations";
import ObservationModal from "../../Observations/ObservationModal";
import Prescriptions from "../../Prescriptions";
import RecommendedTest from "../../RecommendedTest";
import RecommendedTestModal from "../../RecommendedTest/RecommendedTestModal";
import VisitModal from "../VisitModal";
import VisitDetailCard from "./VisitDetailCard";
const VisitDetail = (props) => {
  const { width } = props;
  // useEffect(() => {
  //   dispatch(getAllClinical());

  // }, []);
  return (
    <>
      <VisitModal />
      <CheckoutModal />
      <RecommendedTestModal />
      <ObservationModal />
      <ComplainModal />

      <div>
        <VisitDetailCard {...props} />

        <div style={{ marginTop: "4rem" }}>
          <ChiefComplaint />

          <RecommendedTest />
          <Observations />

          <Prescriptions />
          <Checkout />
        </div>
      </div>
    </>
  );
};
export default VisitDetail;
