import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Controller } from "react-hook-form";

import moment from "moment";
import {
  Button,
  ButtonGroup,
  Grid,
  TextField,
  FormControl,
  Container,
} from "@material-ui/core";
// import { DateTimeInput } from "util/formInput";
import { getDefaultUnit } from "util/helpers";


import IntlMessages from "util/IntlMessages";
import { DateTimeInput,  } from "util/formInput";

export default function ClinicalRecordForm(props) {
  const { width } = useSelector(({ settings }) => settings);

  const {
    control,
    setValue,
    getValues,
    errors,
    accConfig,
    register,
    watch,
  } = props;

  const watchHeight = watch("clinicalRecord.clinicalExam.height");
  const [alerttype, setAlerttype] = useState(false);
  const [feet, setFeet] = useState(0);
  const [inch, setInch] = useState();

  const values = getValues({ nest: true });

  const onAlertType = (val) => {
    setValue("clinicalRecord.clinicalExam.consciousnessLevel", val);
    setAlerttype(val);
    // console.log(values);
  };

  useEffect(() => {
    if (
      values.clinicalRecord &&
      values.clinicalRecord.clinicalExam &&
      values.clinicalRecord.clinicalExam.consciousnessLevel
    ) {
      onAlertType(values.clinicalRecord.clinicalExam.consciousnessLevel);
    } else {
      onAlertType("ALERT");
    }
    if (getDefaultUnit(accConfig.heightUnit, "key")) {
      setValue(
        `clinicalRecord.clinicalExam.height.unit`,
        getDefaultUnit(accConfig.heightUnit, "key")
      );
      if (watchHeight && watchHeight.heightText) {
        const heights = watchHeight.heightText
          .split("/")
          .filter((x) => x.trim().length && !isNaN(x))
          .map(Number);

        setFeet(heights[0]);
        setInch(heights[1]);
      }
    }
  }, []);
  // console.log(values);
  return (
    <>
      <Grid container spacing={2}>
        <input
          ref={register}
          name="clinicalRecord.clinicalExam.consciousnessLevel"
          type="text"
          hidden
          defaultValue={alerttype}
        />

        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <div
              class="btn-group mr-2 flex-wrap"
              role="group"
              aria-label="First group"
            >
              {accConfig.consciousnessLevel &&
                accConfig.consciousnessLevel.map((val) => (
                  <Button
                    key={val.key}
                    onClick={() => onAlertType(val.key)}
                    variant={alerttype === val.key ? "contained" : "outlined"}
                    color="primary"
                    size="large"
                    className={width < 576 ? "btngrp-btn" : ""}
                  >
                    {val.value}
                  </Button>
                ))}
            </div>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <Controller
              label={
                <span>
                  <IntlMessages id="inpatient.form.clinicalRecord.note" />
                </span>
              }
              as={TextField}
              name="clinicalRecord.clinicalExam.note"
              control={control}
              multiline
              fullWidth
              error={
                errors.clinicalRecord &&
                errors.clinicalRecord.clinicalExam &&
                errors.clinicalRecord.clinicalExam.note
              }
              helperText={
                errors.clinicalRecord &&
                errors.clinicalRecord.clinicalExam &&
                errors.clinicalRecord.clinicalExam.note &&
                errors.clinicalRecord.clinicalExam.note.message
              }
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <DateTimeInput
              setAgeValue={setValue}
              value={
                values.clinicalRecord &&
                values.clinicalRecord.clinicalExam &&
                values.clinicalRecord.clinicalExam.height &&
                values.clinicalRecord.clinicalExam.height.date
              }
              label="Date"
              name="clinicalRecord.clinicalExam.height.date"
              format="YYYY-MM-DD"
              inputRef={register}
              fullWidth
              autoOk
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <DateTimeInput
              setAgeValue={setValue}
              value={
                values.clinicalRecord &&
                values.clinicalRecord.clinicalExam &&
                values.clinicalRecord.clinicalExam.height &&
                values.clinicalRecord.clinicalExam.height.time
              }
              label="Time"
              name="clinicalRecord.clinicalExam.height.time"
              inputRef={register}
              fullWidth
              type="time"
              ampm={false}
              autoOk
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <input
          ref={register}
          name="clinicalRecord.clinicalExam.height.unit"
          type="text"
          hidden
          defaultValue={getDefaultUnit(accConfig.heightUnit, "key")}
        />

        <input
          ref={register}
          name="clinicalRecord.clinicalExam.height.heightText"
          type="text"
          hidden
        />

        {watchHeight && watchHeight.unit === "IN" && (
          <Grid item xs={6}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <FormControl fullWidth margin="normal">
                  <Controller
                    label={
                      <span>
                        <IntlMessages id="inpatient.form.clinicalRecord.feet" />
                      </span>
                    }
                    as={TextField}
                    name="clinicalRecord.clinicalExam.height.feet"
                    control={control}
                    defaultValue={feet}
                    type="number"
                    fullWidth
                    error={
                      errors.clinicalRecord &&
                      errors.clinicalRecord.clinicalExam &&
                      errors.clinicalRecord.clinicalExam.height &&
                      errors.clinicalRecord.clinicalExam.height.feet
                    }
                    helperText={
                      errors.clinicalRecord &&
                      errors.clinicalRecord.clinicalExam &&
                      errors.clinicalRecord.clinicalExam.height &&
                      errors.clinicalRecord.clinicalExam.height.feet &&
                      errors.clinicalRecord.clinicalExam.height.feet.message
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth margin="normal">
                  <Controller
                    label={
                      <span>
                        <IntlMessages id="inpatient.form.clinicalRecord.inch" />
                      </span>
                    }
                    as={TextField}
                    name="clinicalRecord.clinicalExam.height.inch"
                    control={control}
                    defaultValue={inch}
                    type="number"
                    fullWidth
                    error={
                      errors.clinicalRecord &&
                      errors.clinicalRecord.clinicalExam &&
                      errors.clinicalRecord.clinicalExam.height &&
                      errors.clinicalRecord.clinicalExam.height.inch
                    }
                    helperText={
                      errors.clinicalRecord &&
                      errors.clinicalRecord.clinicalExam &&
                      errors.clinicalRecord.clinicalExam.height &&
                      errors.clinicalRecord.clinicalExam.height.inch &&
                      errors.clinicalRecord.clinicalExam.height.inch.message
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        )}
        {watchHeight && watchHeight.unit === "CM" && (
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <Controller
                label={
                  <span>
                    <IntlMessages id="inpatient.form.clinicalRecord.height" />
                    &nbsp;({getDefaultUnit(accConfig.heightUnit)})
                  </span>
                }
                as={TextField}
                name="clinicalRecord.clinicalExam.height.height"
                control={control}
                fullWidth
                error={
                  errors.clinicalRecord &&
                  errors.clinicalRecord.clinicalExam &&
                  errors.clinicalRecord.clinicalExam.height &&
                  errors.clinicalRecord.clinicalExam.height.height
                }
                helperText={
                  errors.clinicalRecord &&
                  errors.clinicalRecord.clinicalExam &&
                  errors.clinicalRecord.clinicalExam.height &&
                  errors.clinicalRecord.clinicalExam.height.height &&
                  errors.clinicalRecord.clinicalExam.height.height.message
                }
              />
            </FormControl>
          </Grid>
        )}

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Controller
              label={
                <span>
                  <IntlMessages id="inpatient.form.clinicalRecord.weight" />
                  &nbsp;({getDefaultUnit(accConfig.weightUnit)})
                </span>
              }
              as={TextField}
              name="clinicalRecord.clinicalExam.weight.weight"
              control={control}
              fullWidth
              type="number"
              error={
                errors.clinicalRecord &&
                errors.clinicalRecord.clinicalExam &&
                errors.clinicalRecord.clinicalExam.weight &&
                errors.clinicalRecord.clinicalExam.weight.weight
              }
              helperText={
                errors.clinicalRecord &&
                errors.clinicalRecord.clinicalExam &&
                errors.clinicalRecord.clinicalExam.weight &&
                errors.clinicalRecord.clinicalExam.weight.weight &&
                errors.clinicalRecord.clinicalExam.weight.weight.message
              }
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Controller
              label={
                <span>
                  <IntlMessages id="inpatient.form.clinicalRecord.pulse" />
                  (/min)
                </span>
              }
              as={TextField}
              name="clinicalRecord.clinicalExam.pulse.pulse"
              control={control}
              fullWidth
              error={
                errors.clinicalRecord &&
                errors.clinicalRecord.clinicalExam &&
                errors.clinicalRecord.clinicalExam.pulse &&
                errors.clinicalRecord.clinicalExam.pulse.pulse
              }
              helperText={
                errors.clinicalRecord &&
                errors.clinicalRecord.clinicalExam &&
                errors.clinicalRecord.clinicalExam.pulse &&
                errors.clinicalRecord.clinicalExam.pulse.pulse &&
                errors.clinicalRecord.clinicalExam.pulse.pulse.message
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Controller
              label={
                <span>
                  <IntlMessages id="inpatient.form.clinicalRecord.respiratoryRate" />
                  (/min)
                </span>
              }
              as={TextField}
              name="clinicalRecord.clinicalExam.respiratoryRate.respiratoryRate"
              control={control}
              fullWidth
              error={
                errors.clinicalRecord &&
                errors.clinicalRecord.clinicalExam &&
                errors.clinicalRecord.clinicalExam.respiratoryRate &&
                errors.clinicalRecord.clinicalExam.respiratoryRate
                  .respiratoryRate
              }
              helperText={
                errors.clinicalRecord &&
                errors.clinicalRecord.clinicalExam &&
                errors.clinicalRecord.clinicalExam.respiratoryRate &&
                errors.clinicalRecord.clinicalExam.respiratoryRate
                  .respiratoryRate &&
                errors.clinicalRecord.clinicalExam.respiratoryRate
                  .respiratoryRate.message
              }
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Controller
              label={
                <span>
                  <IntlMessages id="inpatient.form.clinicalRecord.oxygenSaturation" />
                </span>
              }
              as={TextField}
              name="clinicalRecord.clinicalExam.oxygenSaturation.oxygenSaturation"
              control={control}
              fullWidth
              error={
                errors.clinicalRecord &&
                errors.clinicalRecord.clinicalExam &&
                errors.clinicalRecord.clinicalExam.oxygenSaturation &&
                errors.clinicalRecord.clinicalExam.oxygenSaturation
                  .oxygenSaturation
              }
              helperText={
                errors.clinicalRecord &&
                errors.clinicalRecord.clinicalExam &&
                errors.clinicalRecord.clinicalExam.oxygenSaturation &&
                errors.clinicalRecord.clinicalExam.oxygenSaturation
                  .oxygenSaturation &&
                errors.clinicalRecord.clinicalExam.oxygenSaturation
                  .oxygenSaturation.message
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Controller
              label={
                <span>
                  <IntlMessages id="inpatient.form.clinicalRecord.temperature" />
                  &nbsp;({getDefaultUnit(accConfig.temperatureUnit)})
                </span>
              }
              as={TextField}
              name="clinicalRecord.clinicalExam.temperature.temperature"
              control={control}
              fullWidth
              error={
                errors.clinicalRecord &&
                errors.clinicalRecord.clinicalExam &&
                errors.clinicalRecord.clinicalExam.temperature &&
                errors.clinicalRecord.clinicalExam.temperature.temperature
              }
              helperText={
                errors.clinicalRecord &&
                errors.clinicalRecord.clinicalExam &&
                errors.clinicalRecord.clinicalExam.temperature &&
                errors.clinicalRecord.clinicalExam.temperature.temperature &&
                errors.clinicalRecord.clinicalExam.temperature.temperature
                  .message
              }
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Controller
              label={
                <span>
                  <IntlMessages id="inpatient.form.clinicalRecord.systolicPressure" />
                </span>
              }
              as={TextField}
              name="clinicalRecord.clinicalExam.bloodPressure.systolicPressure"
              control={control}
              fullWidth
              error={
                errors.clinicalRecord &&
                errors.clinicalRecord.clinicalExam &&
                errors.clinicalRecord.clinicalExam.bloodPressure &&
                errors.clinicalRecord.clinicalExam.bloodPressure
                  .systolicPressure
              }
              helperText={
                errors.clinicalRecord &&
                errors.clinicalRecord.clinicalExam &&
                errors.clinicalRecord.clinicalExam.bloodPressure &&
                errors.clinicalRecord.clinicalExam.bloodPressure
                  .systolicPressure &&
                errors.clinicalRecord.clinicalExam.bloodPressure
                  .systolicPressure.message
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Controller
              label={
                <span>
                  <IntlMessages id="inpatient.form.clinicalRecord.diastolicPressure" />
                </span>
              }
              as={TextField}
              name="clinicalRecord.clinicalExam.bloodPressure.diastolicPressure"
              control={control}
              fullWidth
              error={
                errors.clinicalRecord &&
                errors.clinicalRecord.clinicalExam &&
                errors.clinicalRecord.clinicalExam.bloodPressure &&
                errors.clinicalRecord.clinicalExam.bloodPressure
                  .diastolicPressure
              }
              helperText={
                errors.clinicalRecord &&
                errors.clinicalRecord.clinicalExam &&
                errors.clinicalRecord.clinicalExam.bloodPressure &&
                errors.clinicalRecord.clinicalExam.bloodPressure
                  .diastolicPressure &&
                errors.clinicalRecord.clinicalExam.bloodPressure
                  .diastolicPressure.message
              }
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}
