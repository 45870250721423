import React from "react";
import TaskListItem from "./TaskListItem";
import CustomScrollbars from "util/CustomScrollbars";

const TaskList = ({ tasks, onTaskSelect, width }) => {
  // console.log(tasks);
  return (
    <div className="module-list mail-list">
      <CustomScrollbars
        className="module-list-scroll scrollbar"
        style={{
          height: width >= 1200 ? "calc(100vh - 259px)" : "calc(100vh - 238px)",
        }}
      >
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div id="tracking">
              <div className="tracking-list">
                {tasks &&
                  tasks.map((task, index) => (
                    <TaskListItem
                      key={task.id}
                      task={task}
                      onTaskSelect={onTaskSelect}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>

        {/* 
        <div className="timeline-section">
         
        </div> */}
      </CustomScrollbars>
    </div>
  );
};

export default TaskList;
