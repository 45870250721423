import { api } from "util/api";
export function getAllTemplates() {
  return (dispatch) => {
    return dispatch({
      type: "GET_ALLTEMPLATES",
      payload: api.get("message/template/all"),
    });
  };
}

export function onTemplateClose() {
  return (dispatch) => {
    return dispatch({
      type: "TEMPLATECLOSE",
    });
  };
}

export function onTemplateForm(
  value = null,
  formType = "add",
  aboutType = "other"
) {
  return (dispatch) => {
    return dispatch({
      type: "TEMPLATEFORM",
      payload: {
        template: value,
        formParam: { formType: formType, aboutType: aboutType },
      },
    });
  };
}

export function onTemplateAdd(value) {
  return (dispatch) => {
    return dispatch({
      type: "TEMPLATE_ADD",
      payload: api.post("message/template", value),
    });
  };
}

export function onTemplateUpdate(value) {
  return (dispatch) => {
    return dispatch({
      type: "TEMPLATE_UPDATE",
      payload: api.post("message/template", value),
    });
  };
}

export function onTemplateDelete(value) {
  return (dispatch) => {
    return dispatch({
      type: "TEMPLATE_DELETE",
      payload: api.post("message/template", value),
    });
  };
}
