// import { getQueryString, getDefaultUnit } from "util/helpers";

import { getQueryString, getDefaultUnit  } from "util/helpers";

const rltLocale = ["ar"];
const initialSettings = {
  lookup_loading: false,
  accConfig: {
    investigations: [],
  },
  accountDefaultUnits: {
    heightUnit: "ft/in",
    INTAKE_OUTPUT_SCALE: "cc",
    BLOOD_SUGAR_SCALE: "mg/dL",
    TEMPERATURE_SCALE: "℃",
    weightUnit: "kg",
  },

  districts: [],
  countries: [],
  isloading: false,
  clientLists: [],
  icds: [],
  // User Settings
  users: [],
  user: {},
  usersLoading: false,
  errors: {},
  error: {},
  formerror: null,
  appUiConfig: {},

  profileTitle: { id: 1, title: "Medical History" },
  togRestrictModal: false,
  restrictModalFor: "",
  restrictMessage: "",
};

const compare = (a, b) => {
  // Use toUpperCase() to ignore character casing
  const GroupA = a.group.toUpperCase();
  const GroupB = b.group.toUpperCase();

  let comparison = 0;
  if (GroupA > GroupB) {
    comparison = 1;
  } else if (GroupA < GroupB) {
    comparison = -1;
  }
  return comparison;
};
const configStore = (state = initialSettings, action) => {
  switch (action.type) {
    // toggle Trial Warning modal
    case "TOGTRIAL_WARNING": {
      return {
        ...state,
        togRestrictModal: action.payload.tog,
        restrictModalFor: action.payload.formfor,
        restrictMessage: action.payload.message,
      };
    }

    case "SETPROFILETITLE": {
      return {
        ...state,
        profileTitle: action.payload,
      };
    }

    // get default App Ui configurations
    case "GET_APPUI_CONFIG_FULFILLED": {
      // console.log(action.payload)
      let appUiConfig = action.payload.data;
      return {
        ...state,
        appUiConfig: appUiConfig,
      };
    }
    case "GET_APPUI_CONFIG_PENDING": {
      return {
        ...state,
        appUiConfig: {},
      };
    }
    case "GET_APPUI_CONFIG_REJECTED": {
      return {
        ...state,
        appUiConfig: {},
        error: action.payload.response.data,
        messages: [],
        errors: [],
      };
    }

    // Account lookout keys
    case "ALLCONFIGDATA_FULFILLED": {
      // get key type from the request url
      // console.log(state.accConfig);
      let keys = {};
      let datas = action.payload.data;
      Object.entries(datas).map(([key, value]) => {
        let object = value.reduce(
          (obj, item) => Object.assign(obj, { [item]: [] }),
          {}
        );
        keys = { ...keys, ...object };
      });
      // data from response
      return {
        ...state,
        accConfig: keys,
        // accConfig: { ...state.accConfig, [type]: datas },
        lookup_loading: false,
      };
    }
    case "ALLCONFIGDATA_PENDING": {
      return {
        ...state,
        lookup_loading: true,
      };
    }
    case "ALLCONFIGDATA_REJECTED": {
      return {
        ...state,
        error: action.payload.response.data,
        lookup_loading: false,
        messages: [],
        errors: [],
      };
    }

    // Account lookout
    case "ACC_LOOKUP_FULFILLED": {
      var unit = null;
      // get key type from the request url
      var type = getQueryString(action.payload.config.url, "filter");
      // data from response
      var datas =
        action.payload.data.length > 0 ? action.payload.data[0].value : [];
      if (type === "weightUnit" || type === "heightUnit") {
        //TESTTTTT
        // unit = getDefaultUnit(datas);
        unit = (datas);
      }

      // console.log(unit)

      return {
        ...state,
        accConfig: { ...state.accConfig, [type]: datas },
        lookup_loading: false,
        accountDefaultUnits: unit
          ? {
              ...state.accountDefaultUnits,
              [type]: unit,
            }
          : state.accountDefaultUnits,
      };
    }
    case "ACC_LOOKUP_PENDING": {
      return {
        ...state,
        lookup_loading: true,
      };
    }
    case "ACC_LOOKUP_REJECTED": {
      return {
        ...state,
        error: action.payload.response.data,
        lookup_loading: false,
        messages: [],
        errors: [],
      };
    }
    
    //  get app config lookup datas
    case "GET_LOOKUP_FULFILLED": {
      // console.log(action.payload)
      let url = action.payload.config.url;
      let keyword = url.split("lookups/")[1];
      let datas = action.payload.data;
      // .sort(compare);
      return {
        ...state,
        accConfig: { ...state.accConfig, [keyword]: datas },
      };
    }
    case "GET_LOOKUP_PENDING": {
      return {
        ...state,
        // districts: [],
      };
    }
    case "GET_LOOKUP_REJECTED": {
      return {
        ...state,
        error: action.payload.response.data,
        messages: [],
        errors: [],
      };
    }

    // get Diagnosis ICD Codes
    case "GET_ICD_FULFILLED": {
      // console.log(action.payload)
      let datas = action.payload.payloaddata.data;
      let index = action.payload.index;
      // console.log(action)
      return {
        ...state,
        icds: {
          ...state.icds,
          [index]: datas,
        },
        // icds[index]: datas,
      };
    }
    case "GET_ICD_PENDING": {
      return {
        ...state,
      };
    }
    case "GET_ICD_REJECTED": {
      return {
        ...state,
        error: action.payload.response.data,
        messages: [],
        errors: [],
      };
    }
    case "CLEARICDS": {
      return {
        ...state,
        icds: [],
      };
    }
    // get Districts
    case "GET_DISTRICT_FULFILLED": {
      // console.log(action.payload)
      let datas = action.payload.data;
      return {
        ...state,
        districts: datas,
      };
    }
    case "GET_DISTRICT_PENDING": {
      return {
        ...state,
        districts: [],
      };
    }
    case "GET_DISTRICT_REJECTED": {
      return {
        ...state,
        districts: [],
        error: action.payload.response.data,
        messages: [],
        errors: [],
      };
    }
    // get Countries
    case "GET_COUNTRIES_FULFILLED": {
      // console.log(action.payload)
      let datas = action.payload.data;
      return {
        ...state,
        countries: datas,
      };
    }
    case "GET_COUNTRIES_PENDING": {
      return {
        ...state,
      };
    }
    case "GET_COUNTRIES_REJECTED": {
      return {
        ...state,
        error: action.payload.response.data,
        messages: [],
        errors: [],
      };
    }

    // get default Account Units
    case "GET_DEFAULT_UNITS_FULFILLED": {
      // console.log(action.payload)
      let accountDefaultUnits = action.payload.data;
      return {
        ...state,
        accountDefaultUnits: accountDefaultUnits,
      };
    }
    case "GET_DEFAULT_UNITS_PENDING": {
      return {
        ...state,
        accountDefaultUnits: {},
      };
    }
    case "GET_DEFAULT_UNITS_REJECTED": {
      return {
        ...state,
        accountDefaultUnits: {},
        error: action.payload.response.data,
        messages: [],
        errors: [],
      };
    }

    // clientLists
    // SettingActions
    // Get Contacts for forms
    case "GET_CONTACTSBYVAL_FULFILLED": {
      let clientLists = action.payload.data;
      return {
        ...state,
        clientLists: clientLists,
        loading: false,
      };
    }

    // Users Setting Started
    // SettingActions
    // Get All Users
    case "GET_ALLUSERS_FULFILLED": {
      let users = action.payload.data;
      return {
        ...state,
        users: users,
        usersLoading: false,
      };
    }
    case "GET_ALLUSERS_PENDING": {
      return {
        ...state,
        users: [],
        usersLoading: true,
      };
    }
    case "GET_ALLUSERS_REJECTED": {
      return {
        ...state,
        users: [],
        usersLoading: false,
        error: action.payload.response.data,
        message: action.payload.message,
      };
    }

    // Save Password / Create New Users
    // AuthActions
    case "SAVEUSER_FULFILLED": {
      // console.log(action.payload)
      return {
        ...state,
        // messages: 'Sign Up Is Done.',
        formerror: null,
      };
    }
    case "SAVEUSER_PENDING": {
      return {
        ...state,
      };
    }
    case "SAVEUSER_REJECTED": {
      return {
        ...state,
        formerror: action.payload.response.data,
        messages: [],
        errors: [],
      };
    }

    // Save Default Ubits
    // SettingActions
    case "SAVE_DEFAULTUNITS_FULFILLED": {
      // console.log(action.payload)
      // let accountDefaultUnits = action.payload.data.accountDefaultUnits;
      return {
        ...state,
        // accountDefaultUnits: accountDefaultUnits,
        formerror: null,
      };
    }
    case "SAVE_DEFAULTUNITS_PENDING": {
      return {
        ...state,
      };
    }
    case "SAVE_DEFAULTUNITS_REJECTED": {
      return {
        ...state,
        formerror: action.payload.response.data,
        messages: [],
        errors: [],
      };
    }
    default:
      return state;
  }
};

export default configStore;
