import React, { useCallback, useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Grid,
  Button,
  Divider,
  InputLabel,
  DialogActions,
  Chip,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Alert, Spinner } from "reactstrap";
import { TagInput, EditorInput } from "util/formInput";
import IntlMessages from "util/IntlMessages";
// Actions
import { onClientNoteAdd, getNoteTags } from "services/actions";
import { CrudNotification, getColor } from "util/helpers";

const ComposeNote = (props) => {
  const dispatch = useDispatch();
  const {
    client,
    clientLoading,
    clientError,
    is_saving,
    formerror,
  } = useSelector(({ profileStore }) => profileStore);
  const { noteTags } = useSelector(({ inboxStore }) => inboxStore);
  const [content, setContent] = useState("");
  const [tags, setTags] = useState([]);
  const handleAddPost = () => {
    let values = {
      note: content,
      aboutType: "CONTACT",
      aboutId: client.clientId,
      subject: client.name,
      tags: tags,
    };
    return dispatch(onClientNoteAdd(values)).then((res) => {
      props.onClose();
      CrudNotification("success", "Note updated Successfully !");
    });
  };

  useEffect(() => {
    dispatch(getNoteTags());
  }, []);
  const onValueChange = (event, value) => {
    setTags(value);
    return value;
  };
  return (
    <>
      <div className="media mb-2">
        {formerror && <Alert color="danger"> {formerror.message} </Alert>}
        <div className="media-body">
          <EditorInput
            miniTool={true}
            content={content}
            setContent={setContent}
            // errorsmsg={errors.about}
          />
        </div>
      </div>

      <div className="jr-clearfix">
        <Grid item xs={12} style={{ marginTop: "20px" }}>
          <Autocomplete
            multiple
            id="tags-filled"
            options={noteTags}
            defaultValue={[]}
            freeSolo
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  style={{
                    backgroundColor: getColor(option),
                    color: "white",
                    margin: "10px",
                  }}
                  {...getTagProps({ index })}
                />
              ))
            }
            onChange={onValueChange}
            renderInput={(params) => (
              <TextField {...params} placeholder="Tags" fullWidth />
            )}
          />
        </Grid>
      </div>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => handleAddPost()}
          disabled={is_saving || !content}
        >
          {is_saving && <Spinner size="sm" />}
          <IntlMessages id="form.save" />
        </Button>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          onClick={() => props.onClose()}
        >
          <IntlMessages id="form.cancel" />
        </Button>
      </DialogActions>
    </>
  );
};

export default ComposeNote;
