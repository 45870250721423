import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  CircularProgress,
  MenuItem,
  Menu,
  Drawer,
  IconButton,
  Checkbox,
  Button,
  Tooltip,
  Fab,
} from "@material-ui/core";
// import templates from '../data/templates';
// actions
import { getAllTemplates, onTemplateForm, onTemplateClose } from "./actions";

import { filters, labels, options, folders } from "./data";
import IntlMessages from "util/IntlMessages";
import CustomScrollbars from "util/CustomScrollbars";
import { CrudNotification, capitalize } from "util/helpers";

import TemplateList from "./TemplateList";
import ComposeTemplate from "./Compose";
import AppModuleHeader from "./AppModuleHeader";
import TemplateDetail from "./TemplateDetail";

const Template = (props) => {
  const dispatch = useDispatch();
  const { width } = useSelector(({ uiConfigureStore }) => uiConfigureStore);
  const { loggedUser } = useSelector(({ auth }) => auth);
  const { templates, templatesLoading, template, viewTemplate } = useSelector(
    ({ templateStore }) => templateStore
  );

  const [searchTemplate, setSearchTemplate] = useState("");
  const [noContentFoundMessage, setNoContentFoundMessage] = useState(
    "No template found in selected folder"
  );
  const [drawerState, setDrawerState] = useState(false);

  const [allTemplate, setAllTemplate] = useState(templates);
  const [selectedTemplates, setSelectedTemplates] = useState(0);
  const [selectedFolder, setSelectedFolder] = useState(0);
  const [folderTemplates, setFolderTemplates] = useState(templates);

  useEffect(() => {
    document.title = "Template - Metta";
    dispatch(getAllTemplates());
  }, []);

  useEffect(() => {
    // console.log(templates);
    setAllTemplate(templates);
    setFolderTemplates(templates.filter((item) => item.status === "ACTIVE"));
  }, [templates]);

  // on Template View
  const onTemplateSelect = (template) => {
    dispatch(onTemplateForm(template, "view"));
  };

  // on compose Template
  const onAddTemplate = () => {
    dispatch(onTemplateForm(null, "add"));
  };

  // onDelete Template
  const onDeleteTemplate = () => {
    // console.log("deleteTemplate");
  };

  const onNavTemplates = (folder) => {
    setSelectedFolder(folder.id);
    setNoContentFoundMessage("No template found in selected folder");
    if (folder.handle === "inbox") {
      setFolderTemplates(templates.filter((item) => item.status === "ACTIVE"));
    } else {
      setFolderTemplates(templates.filter((item) => item.status !== "ACTIVE"));
    }
    dispatch(onTemplateClose());
  };

  const searchTemplateF = (searchText) => {
    if (searchText === "") {
      setFolderTemplates(allTemplate.filter((template) => !template.deleted));
    } else {
      const searchTemplates = allTemplate.filter(
        (template) =>
          !template.deleted &&
          template.subject.toLowerCase().indexOf(searchText.toLowerCase()) > -1
      );
      setFolderTemplates(searchTemplates);
    }
  };

  const updateSearch = (evt) => {
    setSearchTemplate(evt.target.value);
    searchTemplateF(evt.target.value);
  };

  const onToggleDrawer = () => {
    setDrawerState(!drawerState);
  };
  const displayTemplate = (folderTemplates, noContentFoundMessage) => {
    return (
      <div className="module-box-column">
        {!viewTemplate ? (
          folderTemplates.length === 0 ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                height:
                  width >= 1200 ? "calc(100vh - 259px)" : "calc(100vh - 238px)",
              }}
            >
              {noContentFoundMessage}
            </div>
          ) : (
            <TemplateList
              templates={folderTemplates}
              onTemplateSelect={onTemplateSelect}
              width={width}
            />
          )
        ) : (
          <TemplateDetail template={template} width={width} />
        )}
      </div>
    );
  };

  // get Actions when template is selected
  const getTemplateActions = () => {
    return (
      <div>
        <IconButton onClick={() => onDeleteTemplate()} className="icon-btn">
          <i className="zmdi zmdi-delete" />
        </IconButton>
      </div>
    );
  };

  const TemplateSideBar = () => {
    return (
      <div className="module-side">
        <div className="module-side-header">
          <div className="module-logo">
            <i className="zmdi zmdi-enote mr-3" />
            <span>
              <IntlMessages id="template.template" />
            </span>
          </div>
        </div>

        <div className="module-side-content">
          <CustomScrollbars
            className="module-side-scroll scrollbar"
            style={{
              height:
                width >= 1200 ? "calc(100vh - 200px)" : "calc(100vh - 80px)",
            }}
          >
            <div className="module-add-task">
              <Button
                variant="contained"
                color="primary"
                className="btn-block"
                onClick={() => onAddTemplate()}
              >
                <i className="zmdi zmdi-edit zmdi-hc-fw" />
                <IntlMessages id="template.compose" />{" "}
              </Button>
            </div>

            <ul className="module-nav">
              {folders.map((folder, index) => (
                <li key={index} onClick={() => onNavTemplates(folder)}>
                  <span
                    className={`jr-link ${
                      selectedFolder === folder.id ? "active" : ""
                    }`}
                  >
                    <i className={`zmdi zmdi-${folder.icon}`} />
                    <span>{folder.title}</span>
                  </span>
                </li>
              ))}

              {/* <li className="module-nav-label">
                <IntlMessages id="inbox.tags" />
              </li>
              {labels.map((label, index) => (
                <li
                  key={index}
                  onClick={() => {
                    const filterTemplates = allTemplate.filter((template) =>
                      template.labels.includes(label.id)
                    );
                    setNoContentFoundMessage(
                      "No template found in selected label"
                    );
                    setFolderTemplates(filterTemplates);
                  }}
                >
                  <span className="jr-link">
                    <i className={`zmdi zmdi-circle text-${label.color}`} />
                    <span>{label.title}</span>
                  </span>
                </li>
              ))} */}
            </ul>
          </CustomScrollbars>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="animated slideInUpTiny animation-duration-3">
        <div className="app-module">
          <div className="d-block d-xl-none">
            <Drawer open={drawerState} onClose={() => onToggleDrawer()}>
              {TemplateSideBar()}
            </Drawer>
          </div>
          <div className="app-module-sidenav d-none d-xl-flex">
            {TemplateSideBar()}
          </div>

          <div className="module-box">
            <div className="module-box-header">
              <IconButton
                className="drawer-btn d-block d-xl-none"
                aria-label="Menu"
                onClick={() => onToggleDrawer()}
              >
                <i className="zmdi zmdi-menu" />
              </IconButton>
              <AppModuleHeader
                placeholder="Search templates"
                user={loggedUser}
                onChange={updateSearch}
                value={searchTemplate}
              />
            </div>

            <div className="module-box-content">
              {templatesLoading ? (
                <div
                  className="loader-view"
                  style={{
                    height:
                      width >= 1200
                        ? "calc(100vh - 259px)"
                        : "calc(100vh - 238px)",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                displayTemplate(folderTemplates, noContentFoundMessage)
              )}

              <ComposeTemplate />
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-button btn-group-vertical">
        <Tooltip title={"Compose Template "} aria-label="Compose Template">
          <Fab color="primary" aria-label="add" onClick={() => onAddTemplate()}>
            <i className="zmdi zmdi-plus zmdi-hc-fw zmdi-hc-lg" />
          </Fab>
        </Tooltip>
      </div>
    </>
  );
};

export default Template;
