import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IconButton, Tooltip } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  //   NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
} from "reactstrap";
import { Link, NavLink } from "react-router-dom";
//  Actions
// import { onContactForm } from "actions/ContactActions";
// import { onAddMedicationForm } from "redux/actions/MedicalActions";
import { onContactForm } from "services/actions";
import { onAddMedicationForm } from "redux/actions/MedicalActions";
import FormModal from "appRoutes/Contact/FormModal";

const NavBar = (props) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { contact } = useSelector(({ contactStore }) => contactStore);
  const { medRecordStore } = useSelector((state) => ({
    medRecordStore: state.medRecordStore,
  }));
  const medicalrecord = medRecordStore.medicalrecord;

  // const { medicalrecord } = useSelector(({ medRecordStore }) => medRecordStore)||{};
  const { appUiConfig, profileTitle } = useSelector(
    ({ uiConfigureStore }) => uiConfigureStore
  );
  const profileData = appUiConfig?.profile || {
    enableAbout: false,
    enableMedicalRecord: false,
    enableInvestigations: false,
    enableClinicalVisit: false,
    enableEWS: false,
    enableDailyLog: false,
    enableInsights: false,
  };
  const {
    enableAbout,
    enableMedicalRecord,
    enableInvestigations,
    enableClinicalVisit,
    enableEWS,
    enableDailyLog,
    enableInsights,
  } = profileData;

  const toggle = () => setIsOpen(!isOpen);

  // on Update Contact
  const onEditContact = () => {
    dispatch(onContactForm(contact, "update"));
  };

  // on Update DNR
  const onUpdateDnr = () => {
    dispatch(onAddMedicationForm("update", "dnr"));
    dispatch(onContactForm(contact, "update"));
  };

  return (
    <>
      <Navbar expand="md" style={{ width: "100%", zIndex: "999" }}>
        <NavbarBrand>
          <NavbarToggler onClick={toggle} style={{ color: "white" }}>
            {profileTitle?.title}
          </NavbarToggler>
        </NavbarBrand>
        <NavbarToggler onClick={toggle} style={{ color: "white" }}>
          <MenuIcon />
        </NavbarToggler>
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto horizontalNavbar" navbar>
            {enableAbout && (
              <NavItem>
                <NavLink
                  // activeStyle={{ color: "#9ad9ff" }}
                  to={"/app/viewPatient/" + contact?.clientId + "/about"}
                  activeClassName="is-active"
                  style={{ fontSize: 15 }}
                  // onClick={() => toggle()}
                >
                  About
                </NavLink>
              </NavItem>
            )}
            {enableMedicalRecord && (
              <NavItem>
                <NavLink
                  // activeStyle={{ color: "#9ad9ff" }}
                  to={
                    "/app/viewPatient/" + contact?.clientId + "/medicalRecord"
                  }
                  activeClassName="is-active"
                  style={{ fontSize: 15 }}
                  // onClick={() => toggle()}
                >
                  Medical History
                </NavLink>
              </NavItem>
            )}
            {enableClinicalVisit && (
              <NavItem>
                <NavLink
                  // activeStyle={{ color: "#9ad9ff" }}
                  to={"/app/viewPatient/" + contact?.clientId + "/visit"}
                  activeClassName="is-active"
                  style={{ fontSize: 15 }}
                  // onClick={() => toggle()}
                >
                  Clinical Visit
                </NavLink>
              </NavItem>
            )}
            {/* <NavItem>
              <NavLink
                // activeStyle={{ color: "#9ad9ff" }}
                to={"/app/viewPatient/" + contact?.clientId + "/Medications"}
                activeClassName="is-active"
                style={{ fontSize: 15 }}
                // onClick={() => toggle()}
              >
                Medications
              </NavLink>
            </NavItem> */}
            {enableInvestigations && (
              <NavItem>
                <NavLink
                  // activeStyle={{ color: "#9ad9ff" }}
                  to={
                    "/app/viewPatient/" + contact?.clientId + "/investigations"
                  }
                  activeClassName="is-active"
                  style={{ fontSize: 15 }}
                  // onClick={() => toggle()}
                >
                  Investigations
                </NavLink>
              </NavItem>
            )}
            {/* {appUiConfig.profile && appUiConfig.profile.enableDailyLog && ( */}
            {enableDailyLog && (
              <NavItem>
                <NavLink
                  // activeStyle={{ color: "#9ad9ff" }}
                  to={"/app/viewPatient/" + contact?.clientId + "/dailyLogs"}
                  activeClassName="is-active"
                  style={{ fontSize: 15 }}
                  // onClick={() => toggle()}
                >
                  Daily Logs
                </NavLink>
              </NavItem>
            )}
            {/* )} */}
            {enableEWS && (
              <NavItem>
                <NavLink
                  // activeStyle={{ color: "#9ad9ff" }}
                  to={"/app/viewPatient/" + contact?.clientId + "/ews"}
                  activeClassName="is-active"
                  style={{ fontSize: 15 }}
                  // onClick={() => toggle()}
                >
                  EWS
                </NavLink>
              </NavItem>
            )}
            {/* <NavItem>
              <NavLink
                // activeStyle={{ color: "#9ad9ff" }}
                to={"/app/viewPatient/" + contact?.clientId + "/medicalNotes"}
                activeClassName="is-active"
                style={{ fontSize: 15 }}
                // onClick={() => toggle()}
              >
                Progress Notes
              </NavLink>
            </NavItem> */}
            {enableInsights && (
              <NavItem>
                <NavLink
                  // activeStyle={{ color: "#9ad9ff" }}
                  to={"/app/viewPatient/" + contact?.clientId + "/insights"}
                  activeClassName="is-active"
                  style={{ fontSize: 15 }}
                  // onClick={() => toggle()}
                >
                  Insights
                </NavLink>
              </NavItem>
            )}

            {/* <NavItem>
              {appUiConfig.profile && appUiConfig.profile.enableInpatientCare && (
                <NavLink
                  // activeStyle={{ color: "#9ad9ff" }}
                  to={"/app/viewPatient/" + contact?.clientId + "/inpatient"}
                  activeClassName="is-active"
                  style={{ fontSize: 18 }}
                  // onClick={() => toggle()}
                >
                  Inpatient Care
                </NavLink>
              )}
            </NavItem> */}
          </Nav>
          <ul className="jr-navbar-nav navBarProfile">
            <li>
              <Tooltip
                title="Update Contact Detail"
                aria-label="Update Contact Detail"
              >
                <span
                  className="jr-link jr-profile-setting"
                  onClick={() => onEditContact()}
                >
                  <i className="zmdi zmdi-settings mr-2" />
                  <span className="d-inline-flex align-middle ml-1 jr-ml-sm-0">
                    Update
                  </span>
                </span>
              </Tooltip>
            </li>
            <li>
              {medicalrecord?.id && (
                <Tooltip title="Update DNR" aria-label="Update Contact DNR">
                  <span
                    className="jr-link jr-profile-setting"
                    onClick={() => onUpdateDnr()}
                  >
                    <i className="zmdi zmdi-settings mr-2" />
                    <span className="d-inline-flex align-middle ml-1 jr-ml-sm-0">
                      Update DNR
                    </span>
                  </span>
                </Tooltip>
              )}
            </li>
          </ul>
          {/* <NavbarText>Simple Text</NavbarText>
          <NavbarText>Simple Text</NavbarText> */}
          <FormModal />
        </Collapse>
      </Navbar>
    </>
  );
};

export default NavBar;
