import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  Brush,
  ReferenceLine,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  LabelList,
} from "recharts";
import moment from "moment";
import Chip from "@material-ui/core/Chip";

import DoneIcon from "@material-ui/icons/Done";

import { toRegularString, objectKeysToRegular, displayDate } from "util/helpers";

const chartTypes = [
  { selected: true, id: "newsScore", color: "#368bf4" },
  { id: "bpDiastolic", color: "#40feff" },
  { id: "bpSystolic", color: "#ffc000" },
  { id: "heartRate", color: "#f436ee" },
  { id: "oxygenSaturation", color: "#f4e536" },
  { id: "respiratoryRate", color: "#42f436" },
  { id: "temperature", color: "#f44336" },
];

const EwsBPChart = (props) => {
  const dispatch = useDispatch();
  const { contact } = useSelector(({ contactStore }) => contactStore);
  const { patientEwsList } = useSelector(
    ({ medRecordStore }) => medRecordStore
  );

  const [chartLists, setChartList] = useState(chartTypes);
  const [data, setData] = useState([
    {
      name: moment().format("MMM Do YY"),
      bpDiastolic: 3000,
      bpSystolic: 4000,
    },
  ]);

  const loadEwsMap = (ewslist) => {
    let ewslistsData = ewslist.map((val) => {
      let newObj = objectKeysToRegular(JSON.parse(JSON.stringify(val)));
      newObj.name = displayDate(val.dateTimeDisplay, "datetime");
      return newObj;
    });
    setData(ewslistsData);
  };

  useEffect(() => {
    loadEwsMap(patientEwsList);
  }, [patientEwsList]);

  // on Type Change
  // const handleChange = (event) => {
  //   setNames(event.target.value);
  // };

  // on Handle Click Change
  const handleClick = (val) => {
    let lists = chartLists.map((value) => {
      if (value.id === val.id) {
        value.selected = value.selected ? false : true;
      }
      return value;
    });
    setChartList(lists);
  };

  return (
    <div className="jr-card p-0 ">
      <div className="jr-card-header mb-0 p-4 bg-grey lighten-4">
        {/* <FormControl>
          <Select
            multiple
            value={name}
            onChange={handleChange}
            input={<Input id="select-multiple-chip" />}
            renderValue={(selected) => (
              <div>
                {selected.map((value) => (
                  <Chip key={value.id} label={toRegularString(value.id)} />
                ))}
              </div>
            )}
          >
            {chartTypes.map((name) => (
              <MenuItem key={name.id} value={name}>
                {toRegularString(name.id)}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
        <div className="manage-margin d-flex flex-wrap jr-tabs-content jr-task-list jr-wall-content">
          {chartLists.map((data) => {
            return (
              <Chip
                label={toRegularString(data.id)}
                key={data.id}
                onClick={() => handleClick(data)}
                onDelete={() => handleClick(data)}
                deleteIcon={<DoneIcon />}
                color={"primary"}
                variant={data.selected ? "default" : "outlined"}
                style={{ marginTop: "5px" }}
              />
            );
          })}
        </div>
      </div>
      <div className="card-body ">
        <ResponsiveContainer width="100%" height={400}>
          <LineChart
            data={data}
            margin={{ top: 10, right: 0, left: -25, bottom: 0 }}
          >
            <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
            <YAxis padding={{ top: 30 }} />
            <CartesianGrid stroke="#f5f5f5" />

            <Tooltip />
            <Legend />
            <ReferenceLine y={0} stroke="#000" />
            <Brush
              dataKey="name"
              height={30}
              stroke="#8884d8"
              startIndex={data.length < 6 ? 0 : data.length - 5}
              endIndex={data.length - 1}
            />
            {chartLists.map((value, index) => {
              if (value.selected) {
                return (
                  <Line
                    key={value.id}
                    type="monotone"
                    dataKey={toRegularString(value.id)}
                    stroke={value.color}
                    // activeDot={{ r: 8 }}
                    strokeWidth={3}
                  >
                    <LabelList
                      dataKey={toRegularString(value.id)}
                      position="top"
                      // style={{ pointerEvents: "none" }}
                    />
                  </Line>
                );
              }
            })}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
export default EwsBPChart;
