import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

// material ui
import { withMobileDialog, Dialog } from "@material-ui/core";
import { Alert, Spinner } from "reactstrap";
// custom Components
import TransitionSlide from "components/Dialog/TransitionSlide";

import { getAllTasks, onTaskForm, onTaskClose } from "../actions";
import TaskForm from "./TaskForm";

const ComposeTask = (props) => {
  const dispatch = useDispatch();
  const {
    tasks,
    tasksLoading,
    task,
    addFormTask,
    updateFormTask,
    viewTask,
    aboutType,
  } = useSelector(({ taskStore }) => taskStore);

  // onCloseTaskView Modal
  const onClose = () => {
    dispatch(onTaskClose());
  };
  return (
    <Dialog
      fullScreen={props.fullScreen}
      fullWidth={true}
      maxWidth="md"
      scroll="paper"
      TransitionComponent={TransitionSlide}
      open={addFormTask || updateFormTask}
      onClose={onClose}
    >
      {(addFormTask || updateFormTask) && <TaskForm />}
    </Dialog>
  );
};
ComposeTask.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};
export default withMobileDialog()(ComposeTask);
