export const colors = [
  {
    id: 0,
    color: "purple lighten-3",
  },
  {
    id: 1,
    color: "amber darken-2",
  },
  {
    id: 2,
    color: "green darken-1",
  },
  {
    id: 3,
    color: "light-blue darken-2",
  },
];

export const filters = [
  {
    id: 0,
    handle: "starred",
    title: "Starred",
    icon: "star",
  },
  {
    id: 1,
    handle: "important",
    title: "Important",
    icon: "label",
  },
];

export const folders = [
  {
    id: 1,
    handle: "inbox",
    title: "Active",
    icon: "inbox",
  },
  {
    id: 2,
    handle: "trash",
    title: "Trash",
    icon: "delete",
  },
];

export const labels = [
  {
    id: 0,
    handle: "note",
    title: "PayPal",
    color: "purple lighten-3",
  },
  {
    id: 1,
    handle: "paypal",
    title: "Upwork",
    color: "amber darken-2",
  },
  {
    id: 2,
    handle: "invoice",
    title: "In-house",
    color: "green darken-1",
  },
  {
    id: 3,
    handle: "amazon",
    title: "Clients",
    color: "light-blue darken-2",
  },
];

export const options = [
  {
    title: "All",
  },
  {
    title: "None",
  },
  {
    title: "Read",
  },
  {
    title: "Unread",
  },
  {
    title: "Starred",
  },
  {
    title: "Unstarred",
  },
  {
    title: "Important",
  },
  {
    title: "Unimportant",
  },
];
