import {
  Button,
  ButtonGroup,
  Container,
  FormControl,
  FormLabel,
  Grid,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { DateTimeInput } from "util/formInput";
import { getDefaultUnit } from "util/helpers";
import IntlMessages from "util/IntlMessages";

const boolVal = [
  {
    label: "True",
    val: true,
  },
  {
    label: "False",
    val: false,
  },
];

export default function VitalForm(props) {
  const { control, setValue, getValues, errors, accConfig, register } = props;
  const { width } = useSelector(({ uiConfigureStore }) => uiConfigureStore);

  const [alerttype, setAlerttype] = useState("ALERT");
  const [hyperRespFail, setHyperRespFail] = useState(false);
  const [supOxygen, setSupOxygen] = useState(false);

  const values = getValues({ nest: true });

  const onAlertType = (val) => {
    setValue("consciousnessLevel", val);
    setAlerttype(val);
  };

  const onHyperResp = (val) => {
    setValue("hypercapnicRespiratoryFailure", val);
    setHyperRespFail(val);
  };

  const onSupOxygen = (val) => {
    setValue("supplementalOxygen", val);
    setSupOxygen(val);
  };

  useEffect(() => {
    values.consciousnessLevel
      ? onAlertType(values.consciousnessLevel)
      : onAlertType("ALERT");
    values.hypercapnicRespiratoryFailure
      ? setHyperRespFail(values.hypercapnicRespiratoryFailure)
      : setHyperRespFail(false);
    values.supplementalOxygen
      ? setSupOxygen(values.supplementalOxygen)
      : setSupOxygen(false);
  }, []);
  return (
    <div style={{ paddingBottom: 25 }}>
      <input
        ref={register}
        name="consciousnessLevel"
        type="text"
        hidden
        defaultValue={alerttype}
      />
      <input
        ref={register}
        name="hypercapnicRespiratoryFailure"
        type="text"
        hidden
        defaultValue={hyperRespFail}
      />
      <input
        ref={register}
        name="supplementalOxygen"
        type="text"
        hidden
        defaultValue={supOxygen}
      />
      <Container style={{ paddingBottom: 25 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal">
              <FormLabel htmlFor="consciousnessLevel">
                <IntlMessages id="vital.form.consciousnessLevel" />
              </FormLabel>
              {/* <ButtonGroup className="btngrp" aria-label="button group"> */}
              <div
                class="btn-group mr-2 flex-wrap"
                role="group"
                aria-label="First group"
              >
                {accConfig.consciousnessLevel &&
                  accConfig.consciousnessLevel.map((val) => (
                    <Button
                      key={val.key}
                      onClick={() => onAlertType(val.key)}
                      variant={alerttype === val.key ? "contained" : "outlined"}
                      color="primary"
                      size="large"
                      // fullWidth
                      className={width < 576 ? "btngrp-btn" : ""}
                    >
                      {val.value}
                    </Button>
                  ))}
              </div>
              {/* </ButtonGroup> */}
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <FormLabel htmlFor="hypercapnicRespiratoryFailure">
                <IntlMessages id="vital.form.hypercapnicRespiratoryFailure" />
              </FormLabel>
              <ButtonGroup
                color="primary"
                aria-label="outlined primary button group"
              >
                {boolVal.map((val) => (
                  <Button
                    key={val.label}
                    onClick={() => onHyperResp(val.val)}
                    variant={
                      hyperRespFail === val.val ? "contained" : "outlined"
                    }
                    color="primary"
                    size="large"
                  >
                    {val.label}
                  </Button>
                ))}
              </ButtonGroup>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <FormLabel htmlFor="supplementalOxygen">
                <IntlMessages id="vital.form.supplementalOxygen" />
              </FormLabel>
              <ButtonGroup
                color="primary"
                aria-label="outlined primary button group"
              >
                {boolVal.map((val) => (
                  <Button
                    key={val.label}
                    onClick={() => onSupOxygen(val.val)}
                    variant={supOxygen === val.val ? "contained" : "outlined"}
                    color="primary"
                    size="large"
                  >
                    {val.label}
                  </Button>
                ))}
              </ButtonGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <DateTimeInput
                value={values.date}
                label="Date"
                name="date"
                type="date"
                format="YYYY-MM-DD"
                inputRef={register}
                fullWidth
                autoOk
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <DateTimeInput
                value={values.time}
                label="Time"
                name="time"
                type="time"
                format="HH:mm"
                inputRef={register}
                fullWidth
                ampm={false}
                autoOk
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal">
              <Controller
                label={<IntlMessages id="vital.form.respiratoryRate" />}
                as={TextField}
                name="respiratoryRate"
                control={control}
                fullWidth
                error={errors.respiratoryRate}
                helperText={
                  errors.respiratoryRate && errors.respiratoryRate.message
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal">
              <Controller
                label={<IntlMessages id="vital.form.oxygenSaturation" />}
                as={TextField}
                name="oxygenSaturation"
                control={control}
                fullWidth
                error={errors.oxygenSaturation}
                helperText={
                  errors.oxygenSaturation && errors.oxygenSaturation.message
                }
              />
            </FormControl>
          </Grid>
          {/* <Grid item xs={12}>
            <FormControl fullWidth margin="normal">
              <Controller
                label={<IntlMessages id="vital.form.supplementalOxygen" />}
                as={TextField}
                name="supplementalOxygen"
                control={control}
                fullWidth
                error={errors.supplementalOxygen}
                helperText={
                  errors.supplementalOxygen && errors.supplementalOxygen.message
                }
              />
            </FormControl>
          </Grid> */}
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal">
              <Controller
                label={
                  <span>
                    <IntlMessages id="vital.form.temperature" /> (
                    {getDefaultUnit(accConfig.temperatureUnit)})
                  </span>
                }
                as={TextField}
                name="temperature"
                control={control}
                fullWidth
                error={errors.temperature}
                helperText={errors.temperature && errors.temperature.message}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <Controller
                label={<IntlMessages id="vital.form.bpSystolic" />}
                as={TextField}
                name="bpSystolic"
                control={control}
                fullWidth
                error={errors.bpSystolic}
                helperText={errors.bpSystolic && errors.bpSystolic.message}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <Controller
                label={<IntlMessages id="vital.form.bpDiastolic" />}
                as={TextField}
                name="bpDiastolic"
                control={control}
                fullWidth
                error={errors.bpDiastolic}
                helperText={errors.bpDiastolic && errors.bpDiastolic.message}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal">
              <Controller
                label={<IntlMessages id="vital.form.heartRate" />}
                as={TextField}
                name="heartRate"
                control={control}
                fullWidth
                error={errors.heartRate}
                helperText={errors.heartRate && errors.heartRate.message}
              />
            </FormControl>
          </Grid>
          {/* <Grid item xs={12}>
            <FormControl fullWidth margin="normal">
              <Controller
                label={
                  <IntlMessages id="vital.form.hypercapnicRespiratoryFailure" />
                }
                as={TextField}
                name="hypercapnicRespiratoryFailure"
                control={control}
                fullWidth
                error={errors.hypercapnicRespiratoryFailure}
                helperText={
                  errors.hypercapnicRespiratoryFailure &&
                  errors.hypercapnicRespiratoryFailure.message
                }
              />
            </FormControl>
          </Grid> */}
        </Grid>
      </Container>
    </div>
  );
}
