import { api } from "util/api";
export function getClientById(id) {
  return (dispatch) => {
    return dispatch({
      type: "CLIENT_BYID",
      payload: api.get("client/client-id/" + id),
    });
  };
}
export function setProfile(val) {
  return (dispatch) => {
    return dispatch({
      type: "SETPROFILE",
      payload: val,
    });
  };
}
// Set Profile Page title
export function setProfileTitle(val = { id: 1, title: "Medical History" }) {
  return (dispatch) => {
    return dispatch({
      type: "SETPROFILETITLE",
      payload: val,
    });
  };
}

export function onClientNoteAdd(value) {
  return (dispatch) => {
    return dispatch({
      type: "CLIENTNOTE_ADD",
      payload: api.post("note", value),
    });
  };
}
export function getClientNotes(clientId) {
  return (dispatch) => {
    return dispatch({
      type: "CLIENT_NOTES",
      payload: api.get("/note/all?contactId=" + clientId),
    });
  };
}
