import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

// Vendors Components
import {
  CardHeader,
  Tooltip,
  IconButton,
  Avatar,
  CardContent,
} from "@material-ui/core";

import { Skeleton } from "@material-ui/lab";
import { Alert } from "reactstrap";

import { AccountCircle, Edit as EditIcon } from "@material-ui/icons";

//  Themes Components

import Auxiliary from "util/Auxiliary";
import Widget from "components/Widget/index";
import AlertBox from "components/AlertBox/index";
// Custom Components and Helpers
// import { capitalize } from "util/helpers";

// Components
import NoDetail from "../NoDetail";

import { getValuefromLookup, displayDate } from "util/helpers";
//  Actions
import {
  onInPatientForm,
  //   getInPatientDetailById,
} from "redux/actions/MedicalActions";

const Admission = (props) => {
  const dispatch = useDispatch();
  //   const { contact } = useSelector(({ contactStore }) => contactStore);

  const { accConfig } = useSelector(({ uiConfigureStore }) => uiConfigureStore);
  const { inPatient, inpatient_loading } = useSelector(
    ({ medRecordStore }) => medRecordStore
  );

  const { inverted = "timeline-inverted" } = props;

  const onAddInpatientRecord = () => {
    dispatch(onInPatientForm("update", "admission"));
  };
  return (
    <div className={"timeline-item " + inverted}>
      {!inPatient.admission && (
        <NoDetail
          title={"Admisison"}
          loading={inpatient_loading}
          onAddInpatientRecord={onAddInpatientRecord}
          // formFor="admission"
        />
      )}
      {inPatient.admission && (
        <div>
          {/* <div className="timeline-time">
            {inPatient.admission.dateDisplay}
            <br /> {inPatient.admission.timeDisplay}
          </div> */}
          <div className={`timeline-badge bg-green`}>
            <AccountCircle />
          </div>

          <div className={"timeline-panel mytimeline "}>
            <CardHeader
              action={
                inPatient.id && (
                  <Tooltip title="Update Admission Info ">
                    <IconButton
                      aria-label="Add"
                      onClick={() => onAddInpatientRecord()}
                      variant="contained"
                      color="primary"
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                )
              }
              title={"Admission Detail"}
              subheader={
                <small>
                  {displayDate(inPatient.admission.dateTimeDisplay, "datetime")}
                </small>
              }
            />
            <CardContent>
              <h3>
                {getValuefromLookup(
                  accConfig.department,
                  inPatient.admission.department
                )}
              </h3>
              <p>
                <strong> Unit : </strong>
                {getValuefromLookup(accConfig.unit, inPatient.admission.unit)}
                <strong> Ward : </strong>
                {getValuefromLookup(accConfig.ward, inPatient.admission.ward)}
                <strong> Bed No : </strong>
                {inPatient.admission.bedNumber}
              </p>
            </CardContent>
          </div>
        </div>
      )}
    </div>
  );
};
export default Admission;
