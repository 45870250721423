import React, { useEffect, useState } from "react";
import { useFieldArray, Controller } from "react-hook-form";

import {
  Grid,
  TextField,
  FormControl,
  Container,
  Avatar,
  Tooltip,
  Button,
  IconButton,
} from "@material-ui/core";

import { Add as AddIcon, Close as CloseIcon } from "@material-ui/icons";

import { DateTimeInput } from "util/formInput";

import IntlMessages from "util/IntlMessages";

export default function ChiefComplaintForm(props) {
  const { control, setValue, getValues, errors, accConfig, register } = props;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "clinicalRecord.chiefComplaints",
    keyName: "keyId", //, default to "id", you can change the key name
  });

  const onAdd = () => {
    append({});
  };

  const onRemove = (index) => {
    remove(index);
  };
  useEffect(() => {
    if (fields.length === 0) {
      onAdd();
    }
  }, []);
  return (
    <Container>
      {fields.map((item, index) => {
        return (
          <Grid container spacing={2} key={item.keyId}>
            <Grid item xs={11}>
              <FormControl fullWidth margin="normal">
                <Controller
                  label={
                    <IntlMessages id="inpatient.form.clinicalRecord.chiefComplaints" />
                  }
                  as={TextField}
                  name={`clinicalRecord.chiefComplaints[${index}]`}
                  defaultValue={item.value ? item.value : ""}
                  control={control}
                  fullWidth
                  error={
                    errors.clinicalRecord &&
                    errors.clinicalRecord.chiefComplaints &&
                    errors.clinicalRecord.chiefComplaints[index]
                  }
                  helperText={
                    errors.clinicalRecord &&
                    errors.clinicalRecord.chiefComplaints &&
                    errors.clinicalRecord.chiefComplaints[index] &&
                    errors.clinicalRecord.chiefComplaints[index].message
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={1}>
              <Tooltip title="Remove Complain detail">
                <IconButton
                  aria-label="Remove"
                  onClick={() => onRemove(index)}
                  variant="contained"
                  color="secondary"
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        );
      })}

      <Tooltip title="Click to Add Complain detail">
        <Button
          aria-label="Add"
          onClick={() => onAdd()}
          variant="contained"
          color="primary"
        >
          Add More <AddIcon />
        </Button>
      </Tooltip>
    </Container>
  );
}
