import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFieldArray, Controller } from "react-hook-form";

import {
  FormControl,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  InputAdornment,
  Divider,
  Container,
  Avatar,
  CardHeader,
  IconButton,
  Paper,
  Checkbox,
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Add as AddIcon,
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from "@material-ui/icons";

import IntlMessages from "util/IntlMessages";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function AccessGroupsArray(props) {
  const { accConfig, uiUsers } = useSelector(
    ({ uiConfigureStore }) => uiConfigureStore
  );
  const { control, errors, register, getValues, watch, setValue } = props;
  const values = getValues({ nest: true });
  // const watcht = watch("accessGroups");
  const { fields, append, remove } = useFieldArray({
    control,
    name: "accessGroups",
    keyName: "keyId", //, default to "id", you can change the key name
  });
  const watcht = watch("accessGroups");
  useEffect(() => {
    if (!watcht) {
      onAdd();
    }
  }, []);
  const showMember = (index) => {
    if (watcht && watcht[index] && watcht[index].group === "ME_AND_THESE") {
      return true;
    } else {
      return false;
    }
  };
  const onRemove = (index) => {
    remove(index);
  };
  const onAdd = () => {
    append({
      id: 0,
      group: "ALL",
      members: [],
    });
  };
  const getUserNames = (ids) => {
    let names = [];
    if (ids && ids.length > 0) {
      names = ids.map((id) => {
        let user = uiUsers.find((uiUser) => {
          if (uiUser.id === id) {
            return uiUser;
          }
        });
        return user;
      });
      return [];
    }
    return names;
  };
  const getUserLabel = (option) => {
    if (option && option.name) {
      return option.name;
    } else {
      let name = uiUsers.find((o) => o.id === option);
      return name ? name.name : "";
    }
  };
  return (
    <div style={{ paddingBottom: 25 }}>
      {/* <CardHeader
        avatar={
          <Avatar aria-label="Access Group">
            <MoreVertIcon />
          </Avatar>
        }
        title={
          <h2>
            <strong>Access Groups</strong>
          </h2>
        }
      /> */}
      {fields.map((item, index) => {
        return (
          <div key={item.keyId}>
            <Container style={{ paddingBottom: 25 }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={showMember(index) ? 4 : 12}
                  sm={showMember(index) ? 4 : 12}
                  spacing={1}
                >
                  <FormControl fullWidth margin="normal">
                    <InputLabel htmlFor="group">
                      <IntlMessages id="inbox.form.group" />
                    </InputLabel>
                    <Controller
                      as={
                        <Select>
                          <MenuItem value="" disabled>
                            Select Type
                          </MenuItem>
                          {accConfig.userAccessGroup &&
                            accConfig.userAccessGroup.map((val) => (
                              <MenuItem value={val.key} key={val.key}>
                                {val.value}
                              </MenuItem>
                            ))}
                        </Select>
                      }
                      name={`accessGroups[${index}].group`}
                      label={<IntlMessages id="inbox.form.group" />}
                      control={control}
                      fullWidth
                      defaultValue={item.group}
                      margin="normal"
                      error={
                        errors.accessGroups &&
                        errors.accessGroups[index] &&
                        errors.accessGroups[index].group
                      }
                      helperText={
                        errors.accessGroups &&
                        errors.accessGroups[index] &&
                        errors.accessGroups[index].group &&
                        errors.accessGroups[index].group.message
                      }
                    />
                  </FormControl>
                </Grid>
                {showMember(index) && (
                  <Grid item xs={8} sm={8}>
                    <FormControl fullWidth margin="normal">
                      <Controller
                        as={
                          <Autocomplete
                            multiple
                            fullWidth
                            options={uiUsers}
                            autoHighlight
                            getOptionLabel={(option) => getUserLabel(option)}
                            renderOption={(option, { selected }) => (
                              <React.Fragment>
                                {/* <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          /> */}
                                {option.name}
                              </React.Fragment>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Choose a Users"
                                fullWidth
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "disabled", // disable autocomplete and autofill
                                }}
                              />
                            )}
                          />
                        }
                        onChange={([event, data]) => {
                          return data;
                        }}
                        name={`accessGroups[${index}].members`}
                        control={control}
                        defaultValue={getUserNames(item.members)}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Grid>
              {/* <Grid item xs={1}>
                <IconButton
                  aria-label="Delete"
                  onClick={() => onRemove(index)}
                  variant="contained"
                  color="secondary"
                >
                  <CloseIcon />
                </IconButton>
              </Grid> */}
            </Container>
          </div>
        );
      })}
    </div>
  );
}
