import * as yup from "yup";
import moment from "moment";
import { getDefaultUnit } from "util/helpers";
export const initMedRecordVal = {};

export const medRecordSchema = yup.object().shape({
  // patientId: yup.string().required(),
  // bloodType: yup.string().required().default(null).nullable(),
});

export const medRecordSchemaAdd = yup.object().shape({
  // patientId: yup.string().required(),
  // bloodType: yup.string().required().default(null).nullable(),
});
