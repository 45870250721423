import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import { labels } from "appRoutes/Inbox/data";
import { TagInput } from "util/formInput";
import { CrudNotification, displayDate, getColor } from "util/helpers";

import { onNoteForm, onInboxNoteDelete } from "../../actions";
const NoteListItem = ({ note, onNoteSelect }) => {
  // console.log(note);
  const dispatch = useDispatch();
  const { loggedUser } = useSelector(({ auth }) => auth);

  const [noteContent, setNoteContent] = useState("");
  const [noteBy, setNoteBy] = useState(
    note.noteBy ? note.noteBy : loggedUser.name
  );
  useEffect(() => {
    let str = note.note;
    str = str.replace(/<\/?[^>]+(>|$)/g, "");
    // replace(/(<([^>]+)>)/gi, "");
    if (str.length > 300) {
      str = str.slice(0, 300) + "<strong> more >></strong>";
    }
    setNoteContent(str);
  }, []);
  // on Update
  const onUpdateNote = (note) => {
    dispatch(onNoteForm(note, "update"));
  };

  // on onDeleteNote
  const onDeleteNote = (note) => {
    note.status = note.status === "ACTIVE" ? "DELETED" : "ACTIVE";
    return dispatch(onInboxNoteDelete(note)).then((res) => {
      CrudNotification(
        "success",
        `Note ${note.status === "ACTIVE" ? "Active" : "Deleted"} Successfully !`
      );
    });
  };

  return (
    <div className="module-list-item mail-cell my-2">
      <div className="mail-user-info">
        {noteBy && (
          <div
            className="avatar rounded-circle size-40 text-white text-center"
            style={{ fontSize: 16, backgroundColor: getColor(noteBy) }}
          >
            {noteBy.charAt(0).toUpperCase()}
          </div>
        )}
      </div>

      <div
        className="module-list-info"
        onClick={() => {
          onNoteSelect(note);
        }}
      >
        <div className="module-list-content">
          <div className="mail-user-info">
            <span className="sender-name text-dark">{noteBy}</span>

            <span className="toolbar-separator" />

            <span
              className="d-inline-block "
              // style={{ maxWidth: "calc(100% - 220px)" }}
            >
              {note.subject}
            </span>

            {note.hasAttachments && <i className="zmdi zmdi-attachment" />}
          </div>
          <small className="d-inline-block">
            {displayDate(note.createdAt, "datetime")}
          </small>
          {/* <div>{noteContent}</div> */}

          <div dangerouslySetInnerHTML={{ __html: noteContent }}></div>
          {/* <div dangerouslySetInnerHTML={{ __html: note.note }}></div> */}
          {note.tags.length > 0 &&
            note.tags.map((tag, index) => {
              return (
                <div
                  key={index}
                  className={`badge text-white `}
                  style={{ backgroundColor: getColor(tag) }}
                >
                  {tag}
                </div>
              );
            })}
        </div>
      </div>
      <IconButton
        type="button"
        className="icon-btn size-50 p-0"
        onClick={() => {
          onUpdateNote(note);
        }}
      >
        <i className="zmdi zmdi-edit" />
      </IconButton>
      <IconButton
        type="button"
        className="icon-btn size-50 p-0"
        color="secondary"
        onClick={() => {
          onDeleteNote(note);
        }}
      >
        <i className="zmdi zmdi-delete" />
      </IconButton>
    </div>
  );
};

export default NoteListItem;
