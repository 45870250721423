import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useForm } from "react-hook-form";

// material ui
import { Button, DialogActions, DialogContent } from "@material-ui/core";

import { Alert, Spinner } from "reactstrap";
import DialogTitle from "components/Dialog/DialogTitle";
import TransitionSlide from "components/Dialog/TransitionSlide";
import getFormTitle from "components/formTitle";
// form components
import MedicRecordForm from "./MedicRecordForm";
import AllergyHistoryForm from "./ArrayForm/AllergyHistoryForm";
import SurgeryHistoryForm from "./ArrayForm/SurgeryHistoryForm";
import FamilyHistoryForm from "./ArrayForm/FamilyHistoryForm";
import DrugHistoryForm from "./ArrayForm/DrugHistoryForm";
import PersonalHistoryForm from "./ArrayForm/PersonalHistoryForm";
import DnrForm from "./DnrForm";
import BloodGroupForm from "./BloodGroupForm";
import BloodPressureForm from "./BloodPressureForm";
import HtWtForm from "./HtWtForm";
import DiagnosisForm from "./DiagnosisForm";
import MedicalNoteForm from "./MedicalNoteForm";

// import BloodSugarForm from "./BloodSugarForm";
import OxygenSatForm from "./OxygenSatForm";

// Actions
import { onMedRecordSave, onMedicFormClose } from "redux/actions/MedicalActions";

import { clearIcds } from "redux/actions/Setting";
import IntlMessages from "util/IntlMessages";
import {
  CrudNotification,
  datetoArray,
  timeToArray,
  getDefaultUnit,
} from "util/helpers";
// import InvestigationForm from "./InvestigationForm";

// formschema and initvalue
import {
  medRecordSchema,
  medRecordSchemaAdd,
  initMedRecordVal,
} from "./formindex";

const FormComponent = (props) => {
  const dispatch = useDispatch();
  const {
    medicalrecord,
    addMedicationModal,
    updateMedicalFormView,
    updateMedicalFor,
    medformerror,
    is_saving,
  } = useSelector(({ medRecordStore }) => medRecordStore);

  
  const { accConfig } = useSelector(
    ({ uiConfigureStore }) => uiConfigureStore
  );
  const {  icds, accountDefaultUnits } = useSelector(
    ({ configStore }) => configStore
  );

  const { contact } = useSelector(({ contactStore }) => contactStore);
  const {
    handleSubmit,
    register,
    reset,
    control,
    errors,
    setValue,
    getValues,
    watch,
  } = useForm({
    validationSchema: updateMedicalFormView
      ? medRecordSchema
      : medRecordSchemaAdd,
    defaultValues: updateMedicalFormView ? medicalrecord : initMedRecordVal,
    mode: "onBlur",
  });

  useEffect(() => {
    setValue("patientId", contact.clientId);
  }, []);

  const onSubmit = (values) => {
    console.log(values);
    return dispatch(onMedRecordSave(values)).then((res) => {
      dispatch(clearIcds());
      CrudNotification("success", "Medication details added Successfully !");
    });
  };

  // Process form values before submitting
  const transform = (onSubmitFunc) => (data) => {
    let values = data;

    values.patientId = contact.clientId;
    if (values.weight && accountDefaultUnits.weightUnit === "lb") {
      values.weight.weight = (values.weight.weight / 2.20462262185).toFixed(2);
    }

    if (values.height && values.height.unit === "IN") {
      values.height.heightText = values.height.feet + "/" + values.height.inch;
    }

    if (values.height && values.height.date) {
      values.height.date = values.height.date
        ? datetoArray(values.height.date)
        : null;
      values.weight.date = values.height.date;
    }
    if (values.diagnosis) {
      if (values.diagnosis.date) {
        values.diagnosis.date = values.diagnosis.date
          ? datetoArray(values.diagnosis.date)
          : null;
      }
      if (values.diagnosis.icd10 && values.diagnosis.icd10.code) {
        values.diagnosis.icd10 = values.diagnosis.icd10.code;
      } else {
        values.diagnosis.icd10 = values.diagnosis.icd10Text;
      }
      delete values.diagnosis.ICDfor;
      delete values.diagnosis.icd10Text;
    }

    if (values.bloodSugar && values.bloodSugar.date) {
      values.bloodSugar.date = values.bloodSugar.date
        ? datetoArray(values.bloodSugar.date)
        : null;
    }
    if (values.oxygenSaturation && values.oxygenSaturation.date) {
      values.oxygenSaturation.date = values.oxygenSaturation.date
        ? datetoArray(values.oxygenSaturation.date)
        : null;
    }

    if (values.doNotResuscitate && values.doNotResuscitate.date) {
      values.doNotResuscitate.date = values.doNotResuscitate.date
        ? datetoArray(values.doNotResuscitate.date)
        : null;
    }
    if (values.bloodSugar && !values.bloodSugar.value) {
      delete values.bloodSugar;
    }
    if (values.oxygenSaturation && !values.oxygenSaturation.oxygenSaturation) {
      delete values.oxygenSaturation;
    }
    if (values.diagnosis && !values.diagnosis.diagnosis) {
      delete values.diagnosis;
    }

    if (values.surgeryHistory && values.surgeryHistory.length !== 0) {
      values.surgeryHistory.map((val) => {
        val.date = val.date ? datetoArray(val.date) : null;
      });
    }
    if (values.bloodPressure) {
      values.bloodPressure.date = values.bloodPressure.date
        ? datetoArray(values.bloodPressure.date)
        : null;

      values.bloodPressure.time = values.bloodPressure.time
        ? timeToArray(values.bloodPressure.time)
        : null;
    }
    // if (!values.allergyHistory.allergies[0].allergy) {
    // 	delete values.allergyHistory;
    // }
    // if (!values.medicalHistory.medicalHistoryRecords[0].medicalHistoryType) {
    // 	delete values.medicalHistory;
    // }

    // if (!values.medicationHistory.medications[0].name) {
    // 	delete values.medicationHistory;
    // }

    if (values.id === "") {
      delete values.id;
    }
    // console.log(values);
    onSubmitFunc(values);
  };
  // on contacts modal close
  const onClose = () => {
    dispatch(onMedicFormClose());
    dispatch(clearIcds());
  };

  return (
    <Fragment>
      <DialogTitle id="contact-form" onClose={onClose}>
        {getFormTitle(addMedicationModal, updateMedicalFor, contact.name)}
      </DialogTitle>
      <DialogContent dividers={true}>
        {medformerror && <Alert color="danger"> {medformerror.message} </Alert>}
        <input
          ref={register}
          name="patientId"
          type="text"
          hidden
          defaultValue={contact.clientId}
        />
        {updateMedicalFor && (
          <input
            ref={register}
            name="id"
            type="text"
            hidden
            defaultValue={medicalrecord.id}
          />
        )}
        {!updateMedicalFor && (
          <MedicRecordForm
            {...{
              control,
              register,
              getValues,
              errors,
              setValue,
              updateMedicalFor,
              watch,
            }}
          />
        )}
        {updateMedicalFor === "medRecord" && (
          <MedicRecordForm
            {...{
              control,
              register,
              getValues,
              errors,
              setValue,
              updateMedicalFor,
              watch,
            }}
          />
        )}

        {updateMedicalFor === "bloodgroup" && (
          <BloodGroupForm
            {...{
              control,
              errors,
              accConfig,
              register,
              getValues,
              setValue,
              updateMedicalFor,
            }}
          />
        )}
        {updateMedicalFor === "bloodpressure" && (
          <BloodPressureForm
            {...{
              control,
              errors,
              accConfig,
              register,
              getValues,
              setValue,
              updateMedicalFor,
            }}
          />
        )}

        {updateMedicalFor === "heightWeight" && (
          <HtWtForm
            {...{
              control,
              errors,
              register,
              getValues,
              setValue,
              watch,
              updateMedicalFor,
            }}
          />
        )}

        {updateMedicalFor === "oxygenSat" && (
          <OxygenSatForm
            {...{
              control,
              errors,
              accConfig,
              register,
              getValues,
              setValue,
              updateMedicalFor,
            }}
          />
        )}
        {/* Diagnosis form */}
        {updateMedicalFor === "diagnosis" && (
          <DiagnosisForm
            {...{
              control,
              errors,
              register,
              watch,
              getValues,
              setValue,
              accConfig,
              icds,
              updateMedicalFor,
            }}
          />
        )}
        {/* Investigation form */}
        {/* {updateMedicalFor === "labworks" && (
          <InvestigationForm
            {...{
              control,
              errors,
              register,
              watch,
              getValues,
              setValue,
              accConfig,
              icds,
            }}
          />
        )} */}
        {updateMedicalFor === "allergy" && (
          <AllergyHistoryForm
            {...{
              control,
              register,
              getValues,
              errors,
              setValue,
              accConfig,
            }}
          />
        )}
        {updateMedicalFor === "surgery" && (
          <SurgeryHistoryForm
            {...{
              control,
              register,
              getValues,
              errors,
              setValue,
              accConfig,
            }}
          />
        )}
        {updateMedicalFor === "familyhistory" && (
          <FamilyHistoryForm
            {...{
              control,
              register,
              getValues,
              errors,
              setValue,
              accConfig,
            }}
          />
        )}
        {updateMedicalFor === "drughistory" && (
          <DrugHistoryForm
            {...{
              control,
              register,
              getValues,
              errors,
              setValue,
              accConfig,
            }}
          />
        )}
        {updateMedicalFor === "personalhistory" && (
          <PersonalHistoryForm
            {...{
              control,
              register,
              getValues,
              errors,
              setValue,
              accConfig,
            }}
          />
        )}

        {updateMedicalFor === "dnr" && (
          <DnrForm
            {...{
              control,
              register,
              getValues,
              errors,
              setValue,
              accConfig,
            }}
          />
        )}
        {updateMedicalFor === "notes" && (
          <MedicalNoteForm
            {...{
              control,
              register,
              getValues,
              errors,
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSubmit(transform(onSubmit))}
          disabled={is_saving}
        >
          {is_saving && <Spinner size="sm" />}
          <IntlMessages id="form.save" />
        </Button>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          onClick={() => onClose()}
        >
          <IntlMessages id="form.cancel" />
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default FormComponent;
