import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { useForm } from "react-hook-form";

// material ui
import {
  withMobileDialog,
  Dialog,
  Button,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { Alert, Spinner } from "reactstrap";
import DialogTitle from "components/Dialog/DialogTitle";
import TransitionSlide from "components/Dialog/TransitionSlide";
import getFormTitle from "components/formTitle";
// form components
import AdmissionForm from "./AdmissionForm";
import ChiefComplaintForm from "./ChiefComplaintForm";
import FindingForm from "./FindingForm";
import IllnessForm from "./IllnessForm";
import ObservationForm from "./ObservationForm";
import ClinicalRecordForm from "./ClinicalRecordForm";

import InvestLabWorkForm from "./InvestLabWorkForm";
import InvestImagWorkForm from "./InvestImagWorkForm";
import DiagnosisForm from "./DiagnosisForm";
import OperationForm from "./OperationForm";
import DischargeForm from "./DischargeForm";

// Actions
import { onInpatientSave, onMedicFormClose } from "redux/actions/MedicalActions";

import { clearIcds } from "redux/actions/Setting";
import IntlMessages from "util/IntlMessages";
import { CrudNotification, datetoArray, timeToArray, getDefaultUnit } from "util/helpers";

// formschema and initvalue
import { inpatientSchema, initInPatientVal } from "./formIndex";
// import InPatientDetail from "../InpatientDetail";

const InpatientForm = (props) => {
  const dispatch = useDispatch();
  const {
    inPatient,
    addInPatientModal,
    updateInPatientModal,
    updateInPatientFor,
    is_saving,
    inPatientFormError,
  } = useSelector(({ medRecordStore }) => medRecordStore);
  const { accConfig, accountDefaultUnits, icds } = useSelector(
    ({ configStore }) => configStore
  );

  const { contact } = useSelector(({ contactStore }) => contactStore);
  const {
    handleSubmit,
    register,
    reset,
    control,
    errors,
    setValue,
    getValues,
    watch,
  } = useForm({
    validationSchema: inpatientSchema,
    defaultValues: updateInPatientModal ? inPatient : initInPatientVal,
    mode: "onBlur",
  });

  const onSubmit = (values) => {
    // console.log(values);
    return dispatch(onInpatientSave(values)).then((res) => {
      dispatch(clearIcds());
      CrudNotification("success", "Inpatient Data added Successfully !");
    });
  };

  // Process form values before submitting
  const transform = (onSubmitFunc) => (data) => {
    // console.log(data);
    let values = data;
    values.patientId = contact.clientId;
    if (values.admission) {
      if (values.admission.date) {
        values.admission.date = values.admission.date
          ? datetoArray(values.admission.date)
          : null;
      }
      if (values.admission.time) {
        values.admission.time = values.admission.time
          ? timeToArray(values.admission.time)
          : null;
      }
    }
    if (values.clinicalRecord) {
      if (values.clinicalRecord.clinicalExam) {
        if (values.clinicalRecord.clinicalExam.height) {
          if (values.clinicalRecord.clinicalExam.height.unit === "IN") {
            values.clinicalRecord.clinicalExam.height.heightText =
              values.clinicalRecord.clinicalExam.height.feet +
              "/" +
              values.clinicalRecord.clinicalExam.height.inch;
          }


          if (values.clinicalRecord.clinicalExam.weight && accountDefaultUnits.weightUnit === "lb") {
            values.clinicalRecord.clinicalExam.weight.weight = (values.clinicalRecord.clinicalExam.weight.weight / 2.205).toFixed(2);
          }

          if (values.clinicalRecord.clinicalExam.height.date) {
            values.clinicalRecord.clinicalExam.height.date = values
              .clinicalRecord.clinicalExam.height.date
              ? datetoArray(values.clinicalRecord.clinicalExam.height.date)
              : null;
            values.clinicalRecord.date =
              values.clinicalRecord.clinicalExam.height.date;
            // weight Date
            values.clinicalRecord.clinicalExam.weight.date =
              values.clinicalRecord.clinicalExam.height.date;
            // pulse date
            values.clinicalRecord.clinicalExam.pulse.date =
              values.clinicalRecord.clinicalExam.height.date;
            // respiratory date
            values.clinicalRecord.clinicalExam.respiratoryRate.date =
              values.clinicalRecord.clinicalExam.height.date;
            // blood pressure date
            values.clinicalRecord.clinicalExam.bloodPressure.date =
              values.clinicalRecord.clinicalExam.height.date;
            // Oxygen saturation date
            values.clinicalRecord.clinicalExam.oxygenSaturation.date =
              values.clinicalRecord.clinicalExam.height.date;
            // Temp date
            values.clinicalRecord.clinicalExam.temperature.date =
              values.clinicalRecord.clinicalExam.height.date;
          }
          if (values.clinicalRecord.clinicalExam.height.time) {
            values.clinicalRecord.clinicalExam.height.time = values
              .clinicalRecord.clinicalExam.height.time
              ? timeToArray(values.clinicalRecord.clinicalExam.height.time)
              : null;
            values.clinicalRecord.clinicalExam.weight.time =
              values.clinicalRecord.clinicalExam.height.time;
          }
        }
        if (!values.clinicalRecord.clinicalExam.observations) {
          if (
            inPatient &&
            inPatient.clinicalRecord &&
            inPatient.clinicalRecord.clinicalExam &&
            inPatient.clinicalRecord.clinicalExam.observations
          ) {
            values.clinicalRecord.clinicalExam.observations =
              inPatient.clinicalRecord.clinicalExam.observations;
          } else {
            values.clinicalRecord.clinicalExam.observations = [];
          }
        } else if (
          inPatient &&
          inPatient.clinicalRecord &&
          inPatient.clinicalRecord.clinicalExam
        ) {
          values.clinicalRecord.clinicalExam = {
            ...inPatient.clinicalRecord.clinicalExam,
            observations: values.clinicalRecord.clinicalExam.observations,
          };
        }
      }
      // console.log(values.clinicalRecord.chiefComplaints);
      if (
        updateInPatientFor === "chiefComplaints" &&
        values.clinicalRecord.chiefComplaints
      ) {
        // console.log(inPatient);
        values.clinicalRecord = {
          ...inPatient.clinicalRecord,
          chiefComplaints: values.clinicalRecord.chiefComplaints,
        };
      }
      if (
        updateInPatientFor === "illnesses" &&
        values.clinicalRecord.illnesses
      ) {
        // console.log(inPatient);
        values.clinicalRecord = {
          ...inPatient.clinicalRecord,
          illnesses: values.clinicalRecord.illnesses,
        };
      }
      if (updateInPatientFor === "findings" && values.clinicalRecord.findings) {
        // console.log(inPatient);
        values.clinicalRecord = {
          ...inPatient.clinicalRecord,
          findings: values.clinicalRecord.findings,
        };
      }
    }
    if (values.diagnoses) {
      if (values.diagnoses.length !== 0) {
        values.diagnoses.map((diagnosis) => {
          diagnosis.date = datetoArray(diagnosis.date);

          if (diagnosis.icd10 && diagnosis.icd10.code) {
            diagnosis.icd10 = diagnosis.icd10.code;
          } else {
            diagnosis.icd10 = diagnosis.icd10Text;
          }
          delete diagnosis.ICDfor;
          delete diagnosis.icd10Text;
        });
      }
    }

    if (values.investigation) {
      if (values.investigation.tests) {
        if (values.investigation.tests.length !== 0) {
          values.investigation.tests.map((labwork) => {
            labwork.date = datetoArray(labwork.date);
            if (labwork.testItem && labwork.testItem.key) {
              labwork.testItem = labwork.testItem.key;
            } else if (labwork.testItem && !labwork.testItem.key) {
              labwork.testItem = labwork.testItem;
            } else {
              labwork.testItem = "";
            }
          });
        }
      }
      if (
        values.investigation.imagingWorks &&
        values.investigation.imagingWorks.length !== 0
      ) {
        values.investigation.imagingWorks.map((imgwork) => {
          imgwork.date = datetoArray(imgwork.date);
        });
      }
    }

    if (values.surgeries) {
      if (values.surgeries.length !== 0) {
        values.surgeries.map((operation) => {
          operation.date = datetoArray(operation.date);
        });
      }
    }

    onSubmitFunc(values);
  };
  // on contacts modal close
  const onClose = () => {
    dispatch(onMedicFormClose());
    dispatch(clearIcds());
  };

  const values = getValues({ nest: true });
  // console.log(values);
  return (
    <Dialog
      fullScreen={props.fullScreen}
      fullWidth={true}
      maxWidth="md"
      scroll="paper"
      TransitionComponent={TransitionSlide}
      open={addInPatientModal || updateInPatientModal}
      onClose={onClose}
    >
      <DialogTitle id="contact-form" onClose={onClose}>
        {getFormTitle(addInPatientModal, updateInPatientFor, contact.name)}
      </DialogTitle>
      <DialogContent dividers={true}>
        {inPatientFormError && (
          <Alert color="danger"> {inPatientFormError.message} </Alert>
        )}

        <input
          ref={register}
          name="patientId"
          type="text"
          hidden
          defaultValue={contact.clientId}
        />
        {updateInPatientModal && (
          <input
            ref={register}
            name="id"
            type="text"
            hidden
            defaultValue={inPatient.id}
          />
        )}
        {updateInPatientModal && (
          <input
            ref={register}
            name="inpatientId"
            type="text"
            hidden
            defaultValue={inPatient.inpatientId}
          />
        )}
        {updateInPatientFor === "admission" && (
          <AdmissionForm
            {...{
              control,
              register,
              getValues,
              errors,
              setValue,
              accConfig,
            }}
          />
        )}
        {updateInPatientFor === "chiefComplaints" && (
          <ChiefComplaintForm
            {...{
              control,
              register,
              getValues,
              errors,
              accConfig,
            }}
          />
        )}
        {updateInPatientFor === "findings" && (
          <FindingForm
            {...{
              control,
              register,
              getValues,
              errors,
              accConfig,
            }}
          />
        )}
        {updateInPatientFor === "illnesses" && (
          <IllnessForm
            {...{
              control,
              register,
              getValues,
              errors,
              accConfig,
            }}
          />
        )}
        {updateInPatientFor === "observation" && (
          <ObservationForm
            {...{
              control,
              register,
              getValues,
              errors,
              accConfig,
            }}
          />
        )}
        {updateInPatientFor === "clinicalrecord" && (
          <ClinicalRecordForm
            {...{
              control,
              register,
              setValue,
              getValues,
              errors,
              accConfig,
              accountDefaultUnits,
              watch,
            }}
          />
        )}

        {(updateInPatientFor === "investigation" ||
          updateInPatientFor === "labworks") && (
            <InvestLabWorkForm
              {...{
                control,
                register,
                getValues,
                errors,
                accConfig,
                setValue,
                watch,
                updateInPatientFor,
              }}
            />
          )}
        {(updateInPatientFor === "investigation" ||
          updateInPatientFor === "imageworks") && (
            <InvestImagWorkForm
              {...{
                control,
                register,
                getValues,
                errors,
                accConfig,
                setValue,
                updateInPatientFor,
              }}
            />
          )}

        {updateInPatientFor === "diagnosis" && (
          <DiagnosisForm
            {...{
              control,
              register,
              getValues,
              errors,
              watch,
              accConfig,
              icds,
              setValue,
            }}
          />
        )}

        {updateInPatientFor === "operation" && (
          <OperationForm
            {...{
              control,
              register,
              getValues,
              errors,
              accConfig,
              setValue,
            }}
          />
        )}

        {updateInPatientFor === "discharge" && (
          <DischargeForm
            {...{
              control,
              register,
              getValues,
              errors,
              accConfig,
              setValue,
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSubmit(transform(onSubmit))}
          disabled={is_saving}
        >
          {is_saving && <Spinner size="sm" />}
          <IntlMessages id="form.save" />
        </Button>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          onClick={() => onClose()}
        >
          <IntlMessages id="form.cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

InpatientForm.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};
export default withMobileDialog()(InpatientForm);
