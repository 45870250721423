import * as yup from "yup";
import moment from "moment";
export const initFormVal = {
  dateTime: null,
  respiratoryRate: null,
  oxygenSaturation: null,
  supplementalOxygen: false,
  temperature: null,
  bpSystolic: null,
  bpDiastolic: null,
  heartRate: null,
  consciousnessLevel: "ALERT",
  hypercapnicRespiratoryFailure: false,
};

export const formSchema = yup.object().shape({
  // patientId: yup.string().required("PatientId is required"),
  // date: yup.string().required("Date is required"),
  date: yup
    .string()
    .required("Date is required")
    .nullable(),
  time: yup
    .string()
    .required("Time is required")
    .nullable(),
  respiratoryRate: yup
    .number()
    .required("Respiratory Rate is required")
    .typeError("Respiratory Rate Should be numerical")
    .nullable(),
  oxygenSaturation: yup
    .number()
    .required("Oxygen Saturation is required")
    .typeError("Oxygen Saturation Should be numerical")
    .nullable(),
  supplementalOxygen: yup
    .boolean()
    .required("Supplemental Oxygen is required")
    .nullable(),
  temperature: yup
    .number()
    .required("Temperature is required")
    .typeError("Temperature Should be numerical")
    .nullable(),
  heartRate: yup
    .number()
    .required("Heart Rate is required")
    .typeError("Heart Rate Should be numerical")
    .nullable(),
  bpSystolic: yup
    .number()
    .required("BP Systolic is required")
    .typeError("BP Systolic Should be numerical")
    .nullable(),
  bpDiastolic: yup
    .number()
    .required("BP Diastolic is required")
    .typeError("BP Diastolic Should be numerical")
    .nullable(),
  consciousnessLevel: yup
    .string()
    .required("Consciousness Level is required")
    .nullable(),
  hypercapnicRespiratoryFailure: yup
    .boolean()
    .required("Hypercapnic Respiratory Failure is required")
    .nullable(),
});
