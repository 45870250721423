import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Tooltip from "@material-ui/core/Tooltip";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { COLLAPSED_DRAWER, FIXED_DRAWER } from "constants/ActionTypes";
import SearchBox from "components/SearchBox";
import MailNotification from "components/MailNotification";
import AppNotification from "components/AppNotification";
import CardHeader from "components/dashboard/Common/CardHeader/index";
import { switchLanguage, toggleCollapsedNav } from "services/actions";
import IntlMessages from "util/IntlMessages";
import LanguageSwitcher from "components/LanguageSwitcher/index";

import { logOut } from "services/actions";
const Index = (props) => {
  const dispatch = useDispatch();
  const { drawerType, locale } = useSelector(
    ({ uiConfigureStore }) => uiConfigureStore
  );
  const [langSwitcher, setLangSwitcher] = useState(false);
  const [mailNotification, setMailNotification] = useState(false);
  const [appNotification, setAppNotification] = useState(false);
  const [searchBox, setSearchBox] = useState(false);
  const [apps, setApps] = useState(false);
  const [searchText, setSearchText] = useState("");

  const onAppNotificationSelect = () => {
    setAppNotification(!appNotification);
  };

  const onMailNotificationSelect = () => {
    setMailNotification(!mailNotification);
  };
  const onLangSwitcherSelect = () => {
    setLangSwitcher(!langSwitcher);
  };

  const onSearchBoxSelect = () => {
    setSearchBox(!searchBox);
  };
  const onAppsSelect = () => {
    setApps(!apps);
  };

  const handleRequestClose = () => {
    setSearchBox(false);
    setLangSwitcher(false);
    setMailNotification(false);
    setSearchBox(false);
    setApps(false);
  };

  const onToggleCollapsedNav = (e) => {
    const val = !props.navCollapsed;
    dispatch(toggleCollapsedNav(val));
  };

  const Apps = () => {
    return (
      <ul className="jr-list jr-list-half">
        <li className="jr-list-item">
          <Link className="jr-list-link" to="/app/contacts">
            <i className="zmdi zmdi-account-box zmdi-hc-fw" />
            <span className="jr-list-text">
              <IntlMessages id="pages.contactsPage" />
            </span>
          </Link>
        </li>

        <li className="jr-list-item">
          <Link className="jr-list-link" to="/app/inbox">
            <i className="zmdi zmdi-archive zmdi-hc-fw" />
            <span className="jr-list-text">
              <IntlMessages id="pages.inboxPage" />
            </span>
          </Link>
        </li>

        <li className="jr-list-item">
          <Link className="jr-list-link" to="/app/template">
            <i className="zmdi zmdi-file-text zmdi-hc-fw" />
            <span className="jr-list-text">
              <IntlMessages id="pages.templatePage" />
            </span>
          </Link>
        </li>
        <li className="jr-list-item">
          <Link className="jr-list-link" to="/app/message">
            <i className="zmdi zmdi-email zmdi-hc-fw" />
            <span className="jr-list-text">
              <IntlMessages id="pages.messagePage" />
            </span>
          </Link>
        </li>
        {/*
				<li className="jr-list-item">
					<Link className="jr-list-link" to="/app/mail">
						<i className="zmdi zmdi-email zmdi-hc-fw" />
						<span className="jr-list-text">
							<IntlMessages id="sidebar.appModule.mail" />
						</span>
					</Link>
				</li>

				<li className="jr-list-item">
					<Link className="jr-list-link" to="/app/chat">
						<i className="zmdi zmdi-comment zmdi-hc-fw" />
						<span className="jr-list-text">
							<IntlMessages id="sidebar.appModule.chat" />
						</span>
					</Link>
				</li>

				<li className="jr-list-item">
					<Link className="jr-list-link" to="/">
						<i className="zmdi zmdi-plus-circle-o zmdi-hc-fw" />
						<span className="jr-list-text">Add New</span>
					</Link>
				</li> */}
      </ul>
    );
  };

  const updateSearchText = (evt) => {
    setSearchText(evt.target.value);
  };

  const onSwitchLanguage = (lang) => {
    dispatch(switchLanguage(lang));
  };

  const handlelogout = () => {
    dispatch(logOut());
    // this.setState({ open: false });
    props.history.push("/signin");
  };

  const drawerStyle = drawerType.includes(FIXED_DRAWER)
    ? "d-block d-xl-none"
    : drawerType.includes(COLLAPSED_DRAWER)
    ? "d-block"
    : "d-none";

  return (
    <AppBar className="app-main-header">
      <Toolbar className="app-toolbar" disableGutters={false}>
        <IconButton
          className={`jr-menu-icon mr-3 ${drawerStyle}`}
          aria-label="Menu"
          onClick={onToggleCollapsedNav}
        >
          <span className="menu-icon" />
        </IconButton>

        <Link className="app-logo mr-2 d-none d-sm-block" to="/">
          <img
            src={require("assets/images/logo-centered.png")}
            alt="Metta"
            title="Metta"
          />
        </Link>

        {/* <SearchBox
					styleName="d-none d-lg-block"
					placeholder=""
					onChange={updateSearchText}
					value={searchText}
				/> */}

        <ul className="header-notifications list-inline ml-auto">
          <li className="list-inline-item">
            <Dropdown
              className="quick-menu app-notification"
              isOpen={apps}
              toggle={onAppsSelect}
            >
              <DropdownToggle
                className="d-inline-block"
                tag="span"
                data-toggle="dropdown"
              >
                <span className="app-notification-menu">
                  <i className="zmdi zmdi-apps zmdi-hc-fw zmdi-hc-lg" />
                  <span>Apps</span>
                </span>
              </DropdownToggle>

              <DropdownMenu>{Apps()}</DropdownMenu>
            </Dropdown>
          </li>
          <li className="d-inline-block d-lg-none list-inline-item">
            <Dropdown
              className="quick-menu nav-searchbox"
              isOpen={searchBox}
              toggle={onSearchBoxSelect}
            >
              <DropdownToggle
                className="d-inline-block"
                tag="span"
                data-toggle="dropdown"
              >
                <IconButton className="icon-btn">
                  <i className="zmdi zmdi-search zmdi-hc-fw" />
                </IconButton>
              </DropdownToggle>

              <DropdownMenu right className="p-0">
                <SearchBox
                  styleName="search-dropdown"
                  placeholder=""
                  onChange={updateSearchText}
                  value={searchText}
                />
              </DropdownMenu>
            </Dropdown>
          </li>
          <li className="list-inline-item">
            <Tooltip title="Logout">
              <IconButton className="icon-btn" onClick={() => handlelogout()}>
                <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />
              </IconButton>
            </Tooltip>
            {/*   <Dropdown
              className="quick-menu"
              isOpen={langSwitcher}
              toggle={onLangSwitcherSelect}
            >
              <DropdownToggle
                className="d-inline-block"
                tag="span"
                data-toggle="dropdown"
              >
                <IconButton className="icon-btn">
                  <i className={`flag flag-24 flag-${locale.icon}`} />
                </IconButton>
              </DropdownToggle>

              <DropdownMenu right className="w-50">
                <LanguageSwitcher
                  switchLanguage={onSwitchLanguage}
                  handleRequestClose={handleRequestClose}
                />
              </DropdownMenu>
            </Dropdown> */}
          </li>

          {/* Notification */}
          {/* <li className="list-inline-item app-tour">
						<Dropdown className="quick-menu" isOpen={appNotification} toggle={onAppNotificationSelect}>
							<DropdownToggle className="d-inline-block" tag="span" data-toggle="dropdown">
								<IconButton className="icon-btn">
									<i className="zmdi zmdi-notifications-none icon-alert animated infinite wobble" />
								</IconButton>
							</DropdownToggle>

							<DropdownMenu right>
								<CardHeader
									styleName="align-items-center"
									heading={<IntlMessages id="appNotification.title" />}
								/>
								<AppNotification />
							</DropdownMenu>
						</Dropdown>
					</li> */}

          {/* Messages */}
          {/* <li className="list-inline-item mail-tour">
						<Dropdown className="quick-menu" isOpen={mailNotification} toggle={onMailNotificationSelect}>
							<DropdownToggle className="d-inline-block" tag="span" data-toggle="dropdown">
								<IconButton className="icon-btn">
									<i className="zmdi zmdi-comment-alt-text zmdi-hc-fw" />
								</IconButton>
							</DropdownToggle>

							<DropdownMenu right>
								<CardHeader
									styleName="align-items-center"
									heading={<IntlMessages id="mailNotification.title" />}
								/>
								<MailNotification />
							</DropdownMenu>
						</Dropdown>
					</li>
			 */}
        </ul>

        <div className="ellipse-shape" />
      </Toolbar>
    </AppBar>
  );
};

export default withRouter(Index);
