import React from "react";

import { useDispatch, useSelector } from "react-redux";

// Vendors Components
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Tooltip,
  IconButton,
  Paper,
} from "@material-ui/core";

import {
  Edit as EditIcon,
  CheckCircle as CheckCircleIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
} from "@material-ui/icons";

// Themes Components
import Auxiliary from "util/Auxiliary";
import Widget from "components/Widget/index";
import AlertBox from "components/AlertBox/index";

// Custom Components and Helpers
import { capitalize, getValuefromLookup } from "util/helpers";

// Actions
import { onInPatientForm } from "redux/actions/MedicalActions";

const Illnesses = (props) => {
  const dispatch = useDispatch();
  const { inPatient } = useSelector(({ medRecordStore }) => medRecordStore);
  const { accConfig } = useSelector(({ uiConfigureStore }) => uiConfigureStore);

  // on Add History
  const onUpdate = () => {
    dispatch(onInPatientForm("update", "illnesses"));
  };
  return (
    <Paper className="jr-tabs-content jr-task-list">
      <List component="nav" aria-label="main mailbox folders">
        <ListItem button>
          <ListItemText primary={<strong>Past & Present Illnesses</strong>} />
          <Tooltip title="Update Illness Info ">
            <IconButton
              aria-label="Add"
              onClick={() => onUpdate()}
              variant="contained"
              color="primary"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </ListItem>
        {!inPatient.clinicalRecord.illnesses && (
          <AlertBox
            infotype="danger"
            message="No Illnesses Info Found."
            onClick={onUpdate}
          />
        )}
        {inPatient.clinicalRecord.illnesses &&
          inPatient.clinicalRecord.illnesses.map((value, index) => {
            return (
              <ListItem button key={index}>
                <ListItemAvatar>
                  <div>
                    {value.current && (
                      <CheckCircleIcon color="secondary" fontSize="large" />
                    )}
                    {!value.current && (
                      <RadioButtonUncheckedIcon
                        color="primary"
                        fontSize="large"
                      />
                    )}
                  </div>
                </ListItemAvatar>
                <ListItemText primary={getValuefromLookup(accConfig.medicalHistory, value.name)} secondary={value.note} />
              </ListItem>
            );
          })}
      </List>
    </Paper>
  );
};

export default Illnesses;
