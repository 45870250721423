import {
  Container,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { getActiveContacts, getActiveStaffs } from "appRoutes/actions";
import moment from "moment";
import React, { useEffect } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { DateTimeInput } from "util/formInput";
import IntlMessages from "util/IntlMessages";

export default function CheckoutForm(props) {
  const { control, errors, register, getValues } = props;
  const dispatch = useDispatch();
  const { accConfig, uiClients, uiStaffs } = useSelector(
    ({ uiConfigureStore }) => uiConfigureStore
  );
  const values = getValues({ nest: true });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "prescriptions",
    keyName: "keyId", //, default to "id", you can change the key name
  });

  const onAdd = () => {
    append({
      type: "TABLET",
      name: "",
      dosage: "",
      note: "",
      current: true,
    });
  };

  useEffect(() => {
    uiStaffs.length === 0 && dispatch(getActiveStaffs());
    uiClients.length === 0 && dispatch(getActiveContacts());
  }, []);
  const getVisitWithLabel = (opts, option) => {
    if (option && option.name) {
      return option.name;
    } else {
      let name = opts.find((o) => o.clientId === option);
      return name ? name.name : "";
    }
  };
  return (
    <div>
      <div>
        <Container style={{ paddingBottom: 25 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <DateTimeInput
                  value={
                    values.endDateTime ? values.endDateTime : moment().format()
                  }
                  label={"Checkout @"}
                  name="endDateTime"
                  type="datetime"
                  inputRef={register}
                  fullWidth
                  autoOk
                  disableFuture={false}
                  error={errors.endDateTime}
                  helperText={errors.endDateTime && errors.endDateTime.message}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Controller
                  as={TextField}
                  label={"Followup Notes"}
                  name="note"
                  control={control}
                  fullWidth
                  multiline
                  rows="2"
                  rowsMax="4"
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <DateTimeInput
                  // value={values.startDateTime}
                  value={
                    values.followupDateTime
                      ? values.followupDateTime
                      : moment().format()
                  }
                  label={"Followup Date"}
                  name="followupDateTime"
                  type="datetime"
                  inputRef={register}
                  fullWidth
                  autoOk
                  disableFuture={false}
                  // error={errors.startDateTime}
                  // helperText={
                  //   errors.startDateTime && errors.startDateTime.message
                  // }
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {uiStaffs?.length > 0 && (
                <FormControl
                  fullWidth
                  margin="normal"
                  error={errors.followupAssignedId}
                >
                  <Controller
                    as={
                      <Autocomplete
                        error={errors.followupAssignedId}
                        options={uiStaffs}
                        getOptionLabel={(option) =>
                          getVisitWithLabel(uiStaffs, option)
                        }
                        renderOption={(option) => <span>{option.name}</span>}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={
                              errors.followupAssignedId &&
                              errors.followupAssignedId.message
                            }
                            label={"Followup With"}
                          />
                        )}
                      />
                    }
                    onChange={([, data]) => data}
                    name={`followupAssignedId`}
                    control={control}
                    defaultValue={uiStaffs?.find((uiStaff) => {
                      return uiStaff.clientId === values.followupAssignedId;
                    })}
                  />
                  <FormHelperText>
                    {errors.followupAssignedId &&
                      errors.followupAssignedId.message}
                  </FormHelperText>
                </FormControl>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Controller
                  as={TextField}
                  label={"Reviewed By"}
                  name="reviewedBy"
                  control={control}
                  fullWidth
                  multiline
                  rows="2"
                  rowsMax="4"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={11} sm={11}>
              {/* <FormControl fullWidth margin="normal" error={errors.assignedId}>
              <Controller
                as={
                  <Autocomplete
                    error={errors.assignedId}
                    options={uiClients}
                    getOptionLabel={(option) =>
                      getVisitWithLabel(uiClients, option)
                    }
                    renderOption={(option) => <span>{option.name}</span>}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={errors.assignedId && errors.assignedId.message}
                        label={<IntlMessages id="visit.form.visitWith" />}
                      />
                    )}
                  />
                }
                onChange={([, data]) => data}
                name={`assignedId`}
                control={control}
                defaultValue={
                  values.assignedId &&
                  uiClients.find(
                    (uiUser) => uiUser.clientId === values.assignedId
                  )
                }
              />
              <FormHelperText>
                {errors.assignedId && errors.assignedId.message}
              </FormHelperText>
            </FormControl> */}
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
}
