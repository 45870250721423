import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Chip,
  Button,
  ListSubheader,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Checkbox,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import CustomScrollbars from "util/CustomScrollbars";
// Actions
import { getActiveContacts, getClientTags } from "services/actions";

const ByTags = (props) => {
  const dispatch = useDispatch();

  const { clientTags } = useSelector(({ contactStore }) => contactStore);

  const {
    expandTag,
    openTag,
    formVals,
    setFormVals,
    activeButton,
    setActiveButton,
  } = props;

  const [tags, setTags] = useState([]);

  useEffect(() => {
    dispatch(getClientTags());
  }, []);

  useEffect(() => {
    setTags([]);
  }, [openTag]);

  const handleChange = () => {
    if (tags.length > 0) {
      dispatch(getActiveContacts("tags", tags.toString()));
    }
    // else {
    //   dispatch(getActiveContacts("roles", "client"));
    // }
  };

  useEffect(() => {
    handleChange();
  }, [tags]);

  const onTagClick = (key) => {
    var tagVal = [...tags];
    if (tags.includes(key)) {
      tagVal = tagVal.filter((item) => item !== key);
    } else {
      tagVal.push(key);
    }
    setTags(tagVal);
  };

  return (
    <>
      <ListItem button onClick={expandTag}>
        <ListItemIcon>
          <LocalOfferIcon />
        </ListItemIcon>
        <ListItemText primary="By Tags" />
        {openTag ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openTag} timeout="auto" unmountOnExit>
        <List component="div" className="pl-4">
          {clientTags.map((item) => (
            <ListItem button onClick={() => onTagClick(item)}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  onChange={() => onTagClick(item)}
                  checked={tags.includes(item)}
                  inputProps={{ "aria-labelledby": item }}
                />
              </ListItemIcon>
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default ByTags;
