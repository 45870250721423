import React, { Fragment, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useFieldArray, Controller } from "react-hook-form";

import {
  Grid,
  TextField,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  InputAdornment,
  Divider,
  Container,
  Avatar,
  CardHeader,
  IconButton,
  Tooltip,
  Button,
  Grow,
} from "@material-ui/core";

import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

import { DateTimeInput } from "util/formInput";
import { Refresh, Add as AddIcon, Clear, Close } from "@material-ui/icons";

import IntlMessages from "util/IntlMessages";

import { getICDcodes } from "redux/actions/Setting";

const DiagFormComponent = (props) => {
  const dispatch = useDispatch();
  const {
    setValue,
    values,
    item,
    index,
    register,
    control,
    errors,
    onRemove,
    watch,
  } = props;

  const { accConfig, icds } = useSelector(({ configStore }) => configStore);
  const [icdOpts, setIcdOpts] = useState([]);

  // Set Description when IcdCode is selected
  const onICDChange = (val) => {
    // console.log(val);
    if (val && val.description) {
      setValue(`diagnoses[${index}].diagnosis`, val.description);
    }
    return val;
  };

  // IcdCode Search
  const onIcdfor = (event) => {
    let val = event.target.value;
    if (val && val.length > 3) {
      if (val.charAt(val.length - 1) === " ") {
        dispatch(getICDcodes("@" + val, index));
      }
    }
    return val;
  };

  // Change when Icdcodes are loaded from server
  useEffect(() => {
    icds[index] && icds[index].length > 0
      ? setIcdOpts(icds[index])
      : setIcdOpts([]);
    // setIcdOpts(icds[index]);
  }, [icds && icds[index]]);

  // Initial Values
  useEffect(() => {
    if (values.diagnoses && values.diagnoses.length > 0) {
      if (values.diagnoses[index] && values.diagnoses[index].icd10) {
        let icds = [
          {
            code: values.diagnoses[index].icd10,
            description: values.diagnoses[index].diagnosis,
          },
        ];
        setIcdOpts(icds);
        setValue(`diagnoses[${index}].icd10`, icds[0]);
      } else {
        setIcdOpts([]);
      }
    }
  }, []);

  // on Icd Free text
  const onICDFreeType = (val) => {
    setValue(`diagnoses[${index}].icd10Text`, val);
  };
  // get investigation json value
  const getInitICDValue = (val) => {
    let value = icdOpts.length !== 0 ? icdOpts.find((o) => o.code === val) : "";
    // console.log(value);
    return value;
  };

  // filter Icd options
  const filterOptions = createFilterOptions({
    // matchFrom: 'start',
    stringify: (option) => option.code + option.description,
  });
  // console.log(icdOpts);
  return (
    <Container style={{ paddingBottom: 25 }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <DateTimeInput
              value={
                values.diagnoses &&
                values.diagnoses[index] &&
                values.diagnoses[index].date
              }
              label="Date"
              name={`diagnoses[${index}].date`}
              format="YYYY-MM-DD"
              inputRef={register}
              fullWidth
              autoOk
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Controller
              label={<IntlMessages id="inpatient.form.diagnoses.diagnosedBy" />}
              as={TextField}
              name={`diagnoses[${index}].diagnosedBy`}
              control={control}
              fullWidth
              defaultValue={item.diagnosedBy}
              error={
                errors.diagnoses &&
                errors.diagnoses[index] &&
                errors.diagnoses[index].diagnosedBy
              }
              helperText={
                errors.diagnoses &&
                errors.diagnoses[index] &&
                errors.diagnoses[index].diagnosedBy &&
                errors.diagnoses[index].diagnosedBy.message
              }
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="unit">
              <IntlMessages id="inpatient.form.diagnoses.diagnosisType" />
            </InputLabel>
            <Controller
              as={
                <Select>
                  <MenuItem value="" disabled>
                    {" "}
                    <IntlMessages id="inpatient.form.diagnoses.diagnosisType" />
                  </MenuItem>
                  {accConfig.diagnosisType &&
                    accConfig.diagnosisType.map((val) => (
                      <MenuItem value={val.key} key={val.key}>
                        {val.value}
                      </MenuItem>
                    ))}
                </Select>
              }
              name={`diagnoses[${index}].diagnosisType`}
              label={
                <IntlMessages id="inpatient.form.diagnoses.diagnosisType" />
              }
              control={control}
              fullWidth
              defaultValue={item.diagnosisType}
              error={
                errors.diagnoses &&
                errors.diagnoses[index] &&
                errors.diagnoses[index].diagnosisType
              }
              helperText={
                errors.diagnoses &&
                errors.diagnoses[index] &&
                errors.diagnoses[index].diagnosisType &&
                errors.diagnoses[index].diagnosisType.message
              }
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={8}>
          <Grid container>
            <Grid item xs={4}>
              <FormControl fullWidth margin="normal">
                <Controller
                  label={<IntlMessages id="medicRecord.form.icdSearch" />}
                  as={TextField}
                  name={`diagnoses[${index}].ICDfor`}
                  control={control}
                  fullWidth
                  // onChange={onIcdfor}
                  onChange={([event, newInputValue]) => {
                    onIcdfor(event);
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={8}>
              <FormControl
                fullWidth
                margin="normal"
                error={errors.diagnosis && errors.diagnosis.icd10}
              >
                <Controller
                  as={
                    <Autocomplete
                      freeSolo
                      options={icds && icdOpts}
                      getOptionLabel={(option) => option.code}
                      filterOptions={filterOptions}
                      renderOption={(option) => {
                        return (
                          <Fragment style={{ backgroundColor: "#e4e4e4" }}>
                            <span>
                              <strong>{option.code}</strong>
                              <br />
                              {option.description}
                            </span>
                            <Divider />
                          </Fragment>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Choose a ICD"
                          error={errors.diagnosis && errors.diagnosis.icd10}
                        />
                      )}
                    />
                  }
                  onChange={([, data]) => onICDChange(data)}
                  onInputChange={(event, newInputValue) => {
                    onICDFreeType(newInputValue);
                  }}
                  name={`diagnoses[${index}].icd10`}
                  control={control}
                  defaultValue={item.icd10 && getInitICDValue(item.icd10)}
                />
                <FormHelperText>
                  {errors.diagnosis &&
                    errors.diagnosis.icd10 &&
                    errors.diagnosis.icd10.message}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <input
        ref={register}
        name={`diagnoses[${index}].icd10Text`}
        type="text"
        hidden
        defaultValue={values.diagnosis && values.diagnosis.icd10}
      />
      <Grid container spacing={2}>
        <Grid item xs={11} sm={11}>
          <FormControl fullWidth margin="normal">
            <Controller
              label={<IntlMessages id="inpatient.form.diagnoses.diagnosis" />}
              as={TextField}
              name={`diagnoses[${index}].diagnosis`}
              control={control}
              fullWidth
              multiline
              defaultValue={item.diagnosis}
              error={
                errors.diagnoses &&
                errors.diagnoses[index] &&
                errors.diagnoses[index].diagnosis
              }
              helperText={
                errors.diagnoses &&
                errors.diagnoses[index] &&
                errors.diagnoses[index].diagnosis &&
                errors.diagnoses[index].diagnosis.message
              }
            />
          </FormControl>
        </Grid>

        <Grid item xs={1} sm={1}>
          <Container>
            <Button
              color="secondary"
              aria-label="Remove"
              onClick={() => onRemove(index)}
              size="small"
            >
              <Tooltip
                title={"Remove Diagnosis Notes  " + (index + 1)}
                size="large"
              >
                <Clear />
              </Tooltip>
            </Button>
          </Container>
        </Grid>
      </Grid>
    </Container>
  );
};

export default function DiagnosisForm(props) {
  const dispatch = useDispatch();
  const { control, register, getValues, setValue, watch, errors } = props;

  const watchIcd = watch("diagnosis");
  const { fields, append, remove } = useFieldArray({
    control,
    name: "diagnoses",
    keyName: "keyId", //, default to "id", you can change the key name
  });

  const values = getValues({ nest: true });
  const onAdd = () => {
    append({
      date: null,
      diagnosis: "",
      icd10: "",
      diagnosisType: "",
      diagnosedBy: "",
    });
  };

  const onRemove = (index) => {
    remove(index);
  };

  useEffect(() => {
    if (fields.length === 0) {
      onAdd();
    }
  }, []);

  const onReset = (index) => {
    remove(index);
    onAdd();
    // console.log('reset');
  };
  // console.log(errors);
  return (
    <div>
      {fields.map((item, index) => {
        return (
          <div key={item.keyId}>
            <DiagFormComponent
              item={item}
              values={values}
              index={index}
              onRemove={onRemove}
              {...props}
            />
            {index + 1 !== fields.length && <Divider />}
          </div>
        );
      })}

      <Tooltip title="Click to Add Diagnosis detail">
        <Button
          aria-label="Add"
          onClick={() => onAdd()}
          variant="contained"
          color="primary"
        >
          Add More <AddIcon />
        </Button>
      </Tooltip>
    </div>
  );
}
