import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
//  Reducers
import uiConfigureStore from "appRoutes/reducers";
import SettingStore from "appRoutes/Settings/reducers";
import Auth from "appRoutes/Account/reducers";
import Contact from "appRoutes/Contact/reducers";
import Profile from "appRoutes/Profile/reducers";
import Inbox from "appRoutes/Inbox/reducers";
import Template from "appRoutes/Template/reducers";
import Message from "appRoutes/MessagePage/reducers";
import Task from "appRoutes/TaskPage/reducers";
import Visit from "appRoutes/Profile/Visit/reducers";
import MedRecordStore from "appRoutes/reducers/MedRecordStore";
import configStore from "appRoutes/reducers/ConfigStore";
export default (history) =>
  combineReducers({
    router: connectRouter(history),
    uiConfigureStore: uiConfigureStore,
    settingStore: SettingStore,
    auth: Auth,
    contactStore: Contact,
    profileStore: Profile,
    inboxStore: Inbox,
    templateStore: Template,
    messageStore: Message,
    taskStore: Task,
    visitStore: Visit,
    medRecordStore: MedRecordStore,
    configStore: configStore,
  });
