import { api } from "util/api";
export function onSendMessage(value) {
  return (dispatch) => {
    return dispatch({
      type: "MESSAGE_BULK",
      payload: api.post("message/bulk", value),
    });
  };
}

export function onGetMessageLogs() {
  return (dispatch) => {
    return dispatch({
      type: "GET_MESSAGE_LOGS",
      payload: api.get("message/log/all"),
    });
  };
}
export function onGetMessageLogById(id) {
  return (dispatch) => {
    return dispatch({
      type: "GET_MESSAGE_LOGSBYID",
      payload: api.get("/message/log/id/" + id),
    });
  };
}
