const initialState = {
  loading: false,
  messages: [],
  errors: [],
  formerror: null,
  error: "",
  is_saving: false,
  // Notes

  msgLogs: [],
  msgLogs_loading: false,
  msgLog: {},
  logError: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Save Inbox Notes
    case "MESSAGE_BULK_FULFILLED": {
      console.log(action.payload);
      return {
        ...state,
        formerror: null,
        is_saving: false,
      };
    }
    case "MESSAGE_BULK_PENDING": {
      return {
        ...state,
        is_saving: true,
      };
    }
    case "MESSAGE_BULK_REJECTED": {
      return {
        ...state,
        formerror: action.payload.response.data,
        is_saving: false,
        messages: [],
        errors: [],
      };
    }

    // get All Message Logs
    case "GET_MESSAGE_LOGS_FULFILLED": {
      const msgLogs = action.payload.data;
      return {
        ...state,
        msgLogs: msgLogs,
        msgLogs_loading: false,
        logError: null,
      };
    }
    case "GET_MESSAGE_LOGS_PENDING": {
      return {
        ...state,
        msgLogs: [],
        msgLogs_loading: true,
        logError: null,
      };
    }
    case "GET_MESSAGE_LOGS_REJECTED": {
      return {
        ...state,
        msgLogs: [],
        msgLogs_loading: false,
        logError: action.payload.response.data.message,
      };
    }

    // get All Message Logs
    case "GET_MESSAGE_LOGSBYID_FULFILLED": {
      const msgLog = action.payload.data;
      return {
        ...state,
        msgLog: msgLog,
        msgLogs_loading: false,
        logError: null,
      };
    }
    case "GET_MESSAGE_LOGSBYID_PENDING": {
      return {
        ...state,
        msgLog: {},
        msgLogs_loading: true,
        logError: null,
      };
    }
    case "GET_MESSAGE_LOGSBYID_REJECTED": {
      return {
        ...state,
        msgLog: {},
        msgLogs_loading: false,
        logError: action.payload.response.data.message,
      };
    }
    default:
      return state;
  }
};
