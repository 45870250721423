import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  CircularProgress,
  MenuItem,
  Menu,
  Drawer,
  IconButton,
  Checkbox,
  Button,
  Tooltip,
  Fab,
  Chip,
} from "@material-ui/core";
// import notes from '../data/notes';
// actions
import { getAllNotes, onNoteForm, onNoteClose, getNoteTags } from "./actions";

import { filters, labels, options, folders } from "./data";
import IntlMessages from "util/IntlMessages";
import CustomScrollbars from "util/CustomScrollbars";
import { getColor, CrudNotification, capitalize } from "util/helpers";

import NoteList from "./NoteList";
import ComposeNote from "./Compose";
import AppModuleHeader from "./AppModuleHeader";
import NoteDetail from "./NoteDetail";

const Inbox = (props) => {
  const dispatch = useDispatch();
  const { width } = useSelector(({ uiConfigureStore }) => uiConfigureStore);
  const { loggedUser } = useSelector(({ auth }) => auth);
  const { notes, notesLoading, note, viewInboxNote, noteTags } = useSelector(
    ({ inboxStore }) => inboxStore
  );

  const [searchNote, setSearchNote] = useState("");
  const [noContentFoundMessage, setNoContentFoundMessage] = useState(
    "No note found in selected folder"
  );
  const [drawerState, setDrawerState] = useState(false);

  const [allNote, setAllNote] = useState(notes);
  const [selectedNotes, setSelectedNotes] = useState(0);
  const [selectedFolder, setSelectedFolder] = useState(0);
  const [folderNotes, setFolderNotes] = useState(notes);
  const [filterVal, setFilterVal] = useState("subject");

  useEffect(() => {
    document.title = "Inbox - Metta";
    dispatch(getAllNotes());
    dispatch(getNoteTags());
  }, []);

  useEffect(() => {
    setAllNote(notes);
    setFolderNotes(notes.filter((item) => item.status === "ACTIVE"));
  }, [notes]);

  // get List of contacts
  const getNotesList = (filterVal = null, searchVal = null) => {
    dispatch(getAllNotes(filterVal, searchVal));
  };
  // on Note View
  const onNoteSelect = (note) => {
    dispatch(onNoteForm(note, "view"));
  };

  // on compose Note
  const onAddNote = () => {
    dispatch(onNoteForm(null, "add"));
  };

  const onNavNotes = (folder) => {
    dispatch(onNoteClose());
    setSelectedFolder(folder.id);
    setNoContentFoundMessage("No note found in selected folder");
    if (folder.handle === "inbox") {
      setFolderNotes(notes.filter((item) => item.status === "ACTIVE"));
    } else {
      setFolderNotes(notes.filter((item) => item.status !== "ACTIVE"));
    }
  };
  const searchNoteF = (searchText) => {
    if (searchText === "") {
      setFolderNotes(allNote.filter((note) => !note.deleted));
    } else {
      const searchNotes = allNote.filter(
        (note) =>
          !note.deleted &&
          note.subject.toLowerCase().indexOf(searchText.toLowerCase()) > -1
      );
      setFolderNotes(searchNotes);
    }
  };

  const updateSearch = (val) => {
    if (filterVal !== "tags") {
      setSearchNote(val);
      searchNoteF(val);
    } else {
      getNotesList(filterVal, val);
    }
  };

  const onToggleDrawer = () => {
    setDrawerState(!drawerState);
  };
  const displayNote = (folderNotes, noContentFoundMessage) => {
    return (
      <div className="module-box-column">
        {!viewInboxNote ? (
          folderNotes.length === 0 ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                height:
                  width >= 1200 ? "calc(100vh - 259px)" : "calc(100vh - 238px)",
              }}
            >
              {noContentFoundMessage}
            </div>
          ) : (
            <NoteList
              notes={folderNotes}
              onNoteSelect={onNoteSelect}
              width={width}
            />
          )
        ) : (
          <NoteDetail note={note} width={width} />
        )}
      </div>
    );
  };

  const NoteSideBar = () => {
    return (
      <div className="module-side">
        <div className="module-side-header">
          <div className="module-logo">
            <i className="zmdi zmdi-enote mr-3" />
            <span>
              <IntlMessages id="inbox.inbox" />
            </span>
          </div>
        </div>

        <div className="module-side-content">
          <CustomScrollbars
            className="module-side-scroll scrollbar"
            style={{
              height:
                width >= 1200 ? "calc(100vh - 200px)" : "calc(100vh - 80px)",
            }}
          >
            <div className="module-add-task">
              <Button
                variant="contained"
                color="primary"
                className="btn-block"
                onClick={() => onAddNote()}
              >
                <i className="zmdi zmdi-edit zmdi-hc-fw" />
                <IntlMessages id="inbox.compose" />{" "}
              </Button>
            </div>

            <ul className="module-nav">
              {folders.map((folder, index) => (
                <li key={index} onClick={() => onNavNotes(folder)}>
                  <span
                    className={`jr-link ${
                      selectedFolder === folder.id ? "active" : ""
                    }`}
                  >
                    <i className={`zmdi zmdi-${folder.icon}`} />
                    <span>{folder.title}</span>
                  </span>
                </li>
              ))}
            </ul>
          </CustomScrollbars>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="animated slideInUpTiny animation-duration-3">
        <div className="app-module">
          <div className="d-block d-xl-none">
            <Drawer open={drawerState} onClose={() => onToggleDrawer()}>
              {NoteSideBar()}
            </Drawer>
          </div>
          <div className="app-module-sidenav d-none d-xl-flex">
            {NoteSideBar()}
          </div>

          <div className="module-box">
            <div className="module-box-header">
              <IconButton
                className="drawer-btn d-block d-xl-none"
                aria-label="Menu"
                onClick={() => onToggleDrawer()}
              >
                <i className="zmdi zmdi-menu" />
              </IconButton>
              <AppModuleHeader
                placeholder="Search notes"
                user={loggedUser}
                onChange={updateSearch}
                value={searchNote}
                filterVal={filterVal}
                setFilterVal={setFilterVal}
              />
            </div>

            <div className="module-box-content">
              {notesLoading ? (
                <div
                  className="loader-view"
                  style={{
                    height:
                      width >= 1200
                        ? "calc(100vh - 259px)"
                        : "calc(100vh - 238px)",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                displayNote(folderNotes, noContentFoundMessage)
              )}

              <ComposeNote />
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-button btn-group-vertical">
        <Tooltip title={"Compose Note "} aria-label="Compose Note">
          <Fab color="primary" aria-label="add" onClick={() => onAddNote()}>
            <i className="zmdi zmdi-plus zmdi-hc-fw zmdi-hc-lg" />
          </Fab>
        </Tooltip>
      </div>
    </>
  );
};

export default Inbox;
