import React from "react";

import { useDispatch, useSelector } from "react-redux";

// Vendors Components
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Tooltip,
  IconButton,
  Paper,
} from "@material-ui/core";

import {
  Edit as EditIcon,
  CheckCircle as CheckCircleIcon,
} from "@material-ui/icons";

// Themes Components
import Auxiliary from "util/Auxiliary";
import Widget from "components/Widget/index";
import AlertBox from "components/AlertBox/index";

// Custom Components and Helpers
import { capitalize, getValuefromLookup } from "util/helpers";

// Actions
import { onInPatientForm } from "redux/actions/MedicalActions";

const Observations = (props) => {
  const dispatch = useDispatch();
  const { inPatient } = useSelector(({ medRecordStore }) => medRecordStore);
  const { accConfig } = useSelector(({ uiConfigureStore }) => uiConfigureStore);

  // on Add History
  const onUpdate = () => {
    dispatch(onInPatientForm("update", "observation"));
  };
  return (
    <Paper className="jr-tabs-content jr-task-list">
      <List component="nav" aria-label="Observations">
        <ListItem button>
          <ListItemText
            primary={<strong>Clinical Exam / Observations</strong>}
          />
          <Tooltip title="Update About Info ">
            <IconButton
              aria-label="Add"
              onClick={() => onUpdate()}
              variant="contained"
              color="primary"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </ListItem>
        {inPatient.clinicalRecord.clinicalExam.observations.length === 0 && (
          <AlertBox
            infotype="danger"
            message="No Observations detail Found."
            onClick={onUpdate}
          />
        )}
        {inPatient.clinicalRecord.clinicalExam.observations &&
          inPatient.clinicalRecord.clinicalExam.observations.map(
            (value, index) => {
              return (
                <ListItem alignItems="flex-start" key={index}>
                  <ListItemAvatar>
                    <CheckCircleIcon color="secondary" fontSize="large" />
                  </ListItemAvatar>
                  <ListItemText
                    primary={getValuefromLookup(
                      accConfig.observationType,
                      value.observationType
                    )}
                    secondary={value.observation}
                  />
                </ListItem>
              );
            }
          )}
      </List>
    </Paper>
  );
};

export default Observations;
