import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  DialogActions,
  Button,
  Radio,
  RadioGroup,
  FormHelperText,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@material-ui/core";

import { Skeleton } from "@material-ui/lab";
import IntlMessages from "util/IntlMessages";

import CustomScrollbars from "util/CustomScrollbars";
// Actions
import { getActiveTemplates } from "services/actions";

const TemplateForm = (props) => {
  const dispatch = useDispatch();
  const {
    width,
    accConfig,
    districts,
    appUiConfig,
    uiTemplates,
    uiTemplatesLoading,
  } = useSelector(({ uiConfigureStore }) => uiConfigureStore);
  const { step, formVals, setFormVals, activeStep, setActiveStep } = props;
  const [value, setValue] = React.useState(null);

  useEffect(() => {
    uiTemplates.length === 0 && dispatch(getActiveTemplates());
  }, []);

  useEffect(() => {
    let formValues = { ...formVals, templateId: value };
    setFormVals(formValues);
  }, [value]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>
      <div className="jr-tabs-content">
        {uiTemplatesLoading && (
          <Skeleton
            variant="rect"
            height={100}
            // style={{ backgroundColor: "white" }}
          />
        )}
        <CustomScrollbars
          style={{
            height: width >= 1200 ? "calc(70vh - 200px)" : "calc(70vh - 80px)",
          }}
        >
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={value}
              onChange={handleChange}
            >
              {uiTemplates.length > 0 &&
                uiTemplates.map((template) => {
                  return (
                    <FormControlLabel
                      key={template.id}
                      value={template.templateId}
                      control={<Radio />}
                      label={template.name}
                    />
                  );
                })}
            </RadioGroup>
          </FormControl>
        </CustomScrollbars>
      </div>
    </>
  );
};

export default TemplateForm;
