import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from "react-hook-form";

import moment from "moment";
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  InputAdornment,
  Container,
  Avatar,
  CardHeader,
  Paper,
} from "@material-ui/core";

import { Face as FaceIcon } from "@material-ui/icons";

import { DateTimeInput } from "util/formInput";

import IntlMessages from "util/IntlMessages";

export default function WorkInfoFormArray(props) {
  const { accConfig } = useSelector(({ uiConfigureStore }) => uiConfigureStore);
  const { control, setValue, getValues, errors, register } = props;
  return (
    <div style={{ paddingBottom: 25 }}>
      <CardHeader
        avatar={
          <Avatar aria-label="Work Info ">
            <FaceIcon />
          </Avatar>
        }
        title={
          <h2>
            <strong>Work Info</strong>
          </h2>
        }
      />
      <Container style={{ paddingBottom: 25 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal">
              <Controller
                label={<IntlMessages id="contact.form.employer" />}
                as={TextField}
                name="workInfo.employer"
                control={control}
                fullWidth
                error={errors.workInfo && errors.workInfo.employer}
                helperText={
                  errors.workInfo &&
                  errors.workInfo.employer &&
                  errors.workInfo.employer.message
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal">
              <Controller
                label={<IntlMessages id="contact.form.title" />}
                as={TextField}
                name="workInfo.title"
                control={control}
                fullWidth
                error={errors.workInfo && errors.workInfo.title}
                helperText={
                  errors.workInfo &&
                  errors.workInfo.title &&
                  errors.workInfo.title.message
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal">
              <Controller
                multiline
                label={<IntlMessages id="contact.form.note" />}
                as={TextField}
                name="workInfo.note"
                control={control}
                fullWidth
                error={errors.workInfo && errors.workInfo.note}
                helperText={
                  errors.workInfo &&
                  errors.workInfo.note &&
                  errors.workInfo.note.message
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
