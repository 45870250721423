import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DialogActions,
  Button,
  StepLabel,
  Step,
  Stepper,
} from "@material-ui/core";

import { steps } from "../data";

// Actions
import { onSendMessage } from "services/actions";

import { CrudNotification } from "util/helpers";
import { ColorlibConnector } from "./styles";
import ColorlibStepIcon from "./ColorlibStepIcon";
import TemplateForm from "./StepForms/TemplateForm";
import DeliveryChannelForm from "./StepForms/DeliveryChannelForm";
import Confirm from "./StepForms/Confirm";
import ContactStep from "./ContactStep";
import Finished from "./Finished";

function getStepContent(
  step,
  formVals,
  setFormVals,
  activeStep,
  setActiveStep
) {
  switch (step) {
    case 0:
      return (
        <TemplateForm
          formVals={formVals}
          setFormVals={setFormVals}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      );
    case 1:
      return (
        <ContactStep
          formVals={formVals}
          setFormVals={setFormVals}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      );
    case 2:
      return (
        <DeliveryChannelForm
          formVals={formVals}
          setFormVals={setFormVals}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      );
    case 3:
      return (
        <Confirm
          formVals={formVals}
          setFormVals={setFormVals}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      );
    default:
      return (
        <Finished
          formVals={formVals}
          setFormVals={setFormVals}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      );
  }
}

export const channelsArray = [
  { label: "Both Text and Email", key: "EMAILNTEXT" },
  { label: "Mail", key: "EMAIL" },
  { label: "Text", key: "TEXT" },
];
const intFormVals = {
  templateId: null,
  clientIds: [],
  deliveryChannel: null,
};
export default function StepperView() {
  const dispatch = useDispatch();
  const { width, districts, appUiConfig, uiUsers } = useSelector(
    ({ uiConfigureStore }) => uiConfigureStore
  );
  const [activeStep, setActiveStep] = useState(0);
  const [disableNext, setDisableNext] = useState(true);
  const [formVals, setFormVals] = useState(intFormVals);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (activeStep === 3) {
      return dispatch(onSendMessage(formVals)).then((res) => {
        // setActiveStep(0);
        CrudNotification(
          "success",
          "Bulk Message has been sent Successfully !"
        );
      });
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    if (activeStep === 0 && formVals.templateId === null) {
      setDisableNext(true);
    } else if (activeStep === 1 && formVals.clientIds.length === 0) {
      setDisableNext(true);
    } else if (activeStep === 2 && formVals.deliveryChannel === null) {
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }
  }, [activeStep, formVals]);
  return (
    <div>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
        // orientation="vertical"
      >
        {steps.map((stepVal) => (
          <Step key={stepVal.key}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>
              {stepVal.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <div
        style={{
          height: width >= 1200 ? "calc(70vh - 200px)" : "calc(70vh - 80px)",
        }}
      >
        {/* {activeStep === steps.length ? (
          <div>
            All steps completed - you are finished
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : ( */}
        <div className="mx-5">
          {getStepContent(
            activeStep,
            formVals,
            setFormVals,
            activeStep,
            setActiveStep
          )}
        </div>
        {/* )} */}
      </div>
      <DialogActions>
        {activeStep < 4 && (
          <>
            <Button disabled={activeStep === 0} onClick={handleBack}>
              Back
            </Button>
            <Button
              disabled={disableNext}
              variant="contained"
              color="primary"
              onClick={handleNext}
            >
              Next
            </Button>
          </>
        )}
        {activeStep > 3 && (
          <>
            <Button
              disabled={disableNext}
              variant="contained"
              color="primary"
              onClick={handleReset}
            >
              Reset
            </Button>
          </>
        )}
      </DialogActions>
    </div>
  );
}
