import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { useForm } from "react-hook-form";

// material ui
import {
  ButtonGroup,
  Grid,
  withMobileDialog,
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  FormControl,
} from "@material-ui/core";
import { Alert, Spinner } from "reactstrap";
import DialogTitle from "components/Dialog/DialogTitle";
import TransitionSlide from "components/Dialog/TransitionSlide";
import getFormTitle from "components/formTitle";
// form components
import VitalForm from "./VitalForm";
// Actions
import {
  onPatientEwsSave,
  onMedicFormClose,
  onPatientEwsUpdate,
} from "redux/actions/MedicalActions";

import IntlMessages from "util/IntlMessages";

import { CrudNotification, datetoArray, timeToArray } from "util/helpers";

// formschema and initvalue
import { initFormVal, formSchema } from "./formIndex";

const EwsForm = (props) => {
  const dispatch = useDispatch();
  const {
    patientEws,
    addEwsModal,
    updateEwsModal,
    ewsFormFor,
    is_saving,
    ewsFormError,
  } = useSelector(({ medRecordStore }) => medRecordStore);
  const { accConfig, accountDefaultUnits } = useSelector(
    ({ uiConfigureStore }) => uiConfigureStore
  );
  const { contact } = useSelector(({ contactStore }) => contactStore);
  const {
    handleSubmit,
    register,
    reset,
    control,
    errors,
    setValue,
    getValues,
    watch,
  } = useForm({
    validationSchema: formSchema,
    defaultValues: updateEwsModal ? patientEws : initFormVal,
    mode: "onBlur",
  });
  const onSubmit = (values) => {
    // console.log(values);
    if (values.id) {
      return dispatch(onPatientEwsUpdate(values)).then((res) => {
        CrudNotification("success", "EWS  Data added Successfully !");
      });
    } else {
      return dispatch(onPatientEwsSave(values)).then((res) => {
        CrudNotification("success", "EWS  Data added Successfully !");
      });
    }
  };

  // Process form values before submitting
  const transform = (onSubmitFunc) => (data) => {
    // console.log(data);
    let values = data;
    values.patientId = contact.clientId;
    if (values.date) {
      values.date = values.date ? datetoArray(values.date) : null;
    }
    if (values.time) {
      values.time = values.time ? timeToArray(values.time) : null;
    }
    // console.log(values);
    onSubmitFunc(values);
  };
  // on contacts modal close
  const onClose = () => {
    dispatch(onMedicFormClose());
  };

  // const onChangeFormType = (formFor) => {
  //   dispatch(onEwsForm(AddEwsModal ? "add" : "update", formFor));
  // };
  const values = getValues({ nest: true });
  // console.log(errors);
  return (
    <Dialog
      fullScreen={props.fullScreen}
      fullWidth={true}
      maxWidth="md"
      scroll="paper"
      TransitionComponent={TransitionSlide}
      open={addEwsModal || updateEwsModal}
      onClose={onClose}
    >
      <DialogTitle id="contact-form" onClose={onClose}>
        {getFormTitle(addEwsModal, ewsFormFor, contact.name)}
      </DialogTitle>
      <DialogContent dividers={true}>
        {ewsFormError && <Alert color="danger"> {ewsFormError.message} </Alert>}

        <input
          ref={register}
          name="patientId"
          type="text"
          hidden
          defaultValue={contact.clientId}
        />
        {updateEwsModal && (
          <input
            ref={register}
            name="id"
            type="text"
            hidden
            defaultValue={patientEws.id}
          />
        )}
        {updateEwsModal && (
          <input
            ref={register}
            name="accountId"
            type="text"
            hidden
            defaultValue={patientEws.accountId}
          />
        )}
        <VitalForm
          {...{
            control,
            register,
            getValues,
            setValue,
            errors,
            accConfig,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSubmit(transform(onSubmit))}
          disabled={is_saving}
        >
          {is_saving && <Spinner size="sm" />}
          <IntlMessages id="form.save" />
        </Button>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          onClick={() => onClose()}
        >
          <IntlMessages id="form.cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EwsForm.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};
export default withMobileDialog()(EwsForm);
