import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

// material ui
import { withMobileDialog, Dialog } from "@material-ui/core";
import { Alert, Spinner } from "reactstrap";
// custom Components
import TransitionSlide from "components/Dialog/TransitionSlide";

// Actions
import { onModalClose } from "../actions";
// components
import ContactForm from "../ContactForm";

const FormModal = (props) => {
  const dispatch = useDispatch();
  const {
    formerror,
    contact,
    addFormModal,
    updateFormModal,
    formRole,
    is_saving,
  } = useSelector(({ contactStore }) => contactStore);

  // on contacts modal close
  const onClose = () => {
    dispatch(onModalClose());
  };

  return (
    <>

    <Dialog
      fullScreen={props.fullScreen}
      fullWidth={true}
      maxWidth="md"
      scroll="paper"
      TransitionComponent={TransitionSlide}
      open={addFormModal || updateFormModal}
      onClose={onClose}
    >
      {(addFormModal || updateFormModal) && <ContactForm />}
    </Dialog>
    </>
  );
};

FormModal.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};
export default withMobileDialog()(FormModal);
