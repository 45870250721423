import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";

import { Button, Tooltip, Card, CardContent } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
// Custom Components and Helpers

import ContainerHeader from "components/ContainerHeader/index";

import UserModal from "../../Settings/Users/UserModal";
import IntlMessages from "util/IntlMessages";
import { getValuefromLookup } from "util/helpers";

// Components
const UserProfile = (props) => {
  const dispatch = useDispatch();
  const { loggedUser, profilePic } = useSelector(({ auth }) => auth);
  const { accConfig } = useSelector(({ uiConfigureStore }) => uiConfigureStore);
  const user = loggedUser;
  const [updateView, setUpdateView] = useState();

  useEffect(() => {}, []);

  const goBack = () => {
    props.history.goBack();
  };

  const onUpdate = (value) => {
    setUpdateView(true);
  };

  const onClose = () => {
    setUpdateView(false);
  };
  return (
    <>
      <div className="page-heading d-sm-flex justify-content-sm-between align-items-sm-center">
        <h2 className="title mb-3 mb-sm-0">My Account</h2>
        <span className="mb-0">
          <img
            className="ml-2 rounded-circle size-40 pointer"
            id="Popover1"
            alt={user.name}
            src={profilePic}
          />
          {/* <Button onClick={() => onUpdate()}>Update Password</Button> */}
        </span>
      </div>
      <Card>
        <CardContent>
          <p>Name : {user.name}</p>
          <p>User Id : {user.userId}</p>
          <p>
            Role :{" "}
            {user.roles.map((val) => {
              return <span>{getValuefromLookup(accConfig.userRole, val)}</span>;
            })}
          </p>
        </CardContent>
      </Card>
      {updateView && (
        <UserModal
          updateView={updateView}
          user={user}
          onClose={onClose}
          isProfile={true}
        />
      )}
    </>
  );
};

export default withRouter(UserProfile);
