import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from "react-hook-form";

import {
  Grid,
  TextField,
  FormControl,
  FormHelperText,
  FormLabel,
  InputAdornment,
  Container,
  InputLabel,
  Button,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { DateTimeInput } from "util/formInput";
import { AccountCircle } from "@material-ui/icons";

import { TagInput, EditorInput } from "util/formInput";
// Utilities and helpers
import IntlMessages from "util/IntlMessages";

export default function TaskSubjectForm(props) {
  const {
    control,
    errors,
    register,
    formRole,
    setValue,
    getValues,
    updateFormTask,
    task,
  } = props;
  const { accConfig, width, uiClients, uiStaffs } = useSelector(
    ({ uiConfigureStore }) => uiConfigureStore
  );
  const { taskTags } = useSelector(({ taskStore }) => taskStore);

  const values = getValues({ nest: true });
  const [taskCat, setTaskCat] = useState(values.category);

  const onTaskCategory = (val) => {
    setValue("category", val);
    setTaskCat(val);
  };
  const getUserLabel = (opts, option) => {
    if (option && option.name) {
      return option.name;
    } else {
      let name = opts.find((o) => o.clientId === option);
      return name ? name.name : "";
    }
  };
  const getDefaultVal = (options, id) => {
    if (id) {
      let val = options.find((o) => o.clientId === id);
      return val;
    }
  };
  return (
    <div>
      <Container style={{ paddingBottom: 25 }}>
        <Grid container spacing={1} direction="row">
          <Grid item xs={12}>
            <Controller
              as={TextField}
              label={<IntlMessages id="task.form.title" />}
              name="title"
              control={control}
              fullWidth
              rowsMax="4"
              error={errors.title}
              helperText={errors.title && errors.title.message}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              as={TextField}
              label={<IntlMessages id="task.form.description" />}
              name="description"
              control={control}
              fullWidth
              multiline
              rows="2"
              rowsMax="4"
              error={errors.description}
              helperText={errors.description && errors.description.message}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <input
              ref={register}
              name={`category`}
              type="text"
              hidden
              defaultValue={values.category}
            />
            <FormControl fullWidth margin="normal">
              <FormLabel htmlFor="category" style={{ paddingBottom: "20px" }}>
                <IntlMessages id="task.form.category" />
              </FormLabel>
              <div
                className="btn-group mr-2 flex-wrap"
                role="group"
                aria-label="First group"
              >
                {accConfig.taskCategory &&
                  accConfig.taskCategory.map((val) => (
                    <Button
                      key={val.key}
                      onClick={() => onTaskCategory(val.key)}
                      variant={taskCat === val.key ? "contained" : "outlined"}
                      color="primary"
                      size="large"
                      className={width < 576 ? "btngrp-btn" : ""}
                    >
                      {val.key}
                    </Button>
                  ))}
              </div>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <DateTimeInput
                value={values.startDateTime && values.startDateTime}
                label={<IntlMessages id="task.form.startDateTime" />}
                name="startDateTime"
                type="datetime"
                inputRef={register}
                fullWidth
                autoOk
                disableFuture={false}
                error={errors.startDateTime}
                helperText={
                  errors.startDateTime && errors.startDateTime.message
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <DateTimeInput
                value={values.endDateTime && values.endDateTime}
                label={<IntlMessages id="task.form.endDateTime" />}
                name="endDateTime"
                type="datetime"
                inputRef={register}
                fullWidth
                autoOk
                disableFuture={false}
                error={errors.endDateTime}
                helperText={errors.endDateTime && errors.endDateTime.message}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal" error={errors.aboutId}>
              <Controller
                as={
                  <Autocomplete
                    error={errors.aboutId}
                    options={uiClients}
                    getOptionLabel={(option) => getUserLabel(uiClients, option)}
                    renderOption={(option) => <span>{option.name}</span>}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={errors.aboutId && errors.aboutId.message}
                        label={<IntlMessages id="task.form.aboutName" />}
                      />
                    )}
                  />
                }
                onChange={([, data]) => data}
                name={`aboutId`}
                control={control}
                defaultValue={
                  values.aboutId &&
                  uiClients.find((uiUser) => uiUser.clientId === values.aboutId)
                }
              />
              <FormHelperText>
                {errors.aboutId && errors.aboutId.message}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal" error={errors.assignedId}>
              <Controller
                as={
                  <Autocomplete
                    error={errors.assignedId}
                    options={uiStaffs}
                    getOptionLabel={(option) => getUserLabel(uiStaffs, option)}
                    renderOption={(option) => <span>{option.name}</span>}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={errors.assignedId && errors.assignedId.message}
                        label={<IntlMessages id="task.form.assignedName" />}
                      />
                    )}
                  />
                }
                onChange={([, data]) => data}
                name={`assignedId`}
                control={control}
                defaultValue={
                  values.assignedId &&
                  getDefaultVal(uiStaffs, values.assignedId)
                }
              />
              <FormHelperText>
                {errors.assignedId && errors.assignedId.message}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
