import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from "react-hook-form";

import moment from "moment";
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  InputAdornment,
  Container,
  Avatar,
  CardHeader,
  Paper,
} from "@material-ui/core";

import { Face as FaceIcon } from "@material-ui/icons";

import { DateTimeInput } from "util/formInput";

import IntlMessages from "util/IntlMessages";

export default function PersonalDetailForm(props) {
  const { accConfig } = useSelector(({ uiConfigureStore }) => uiConfigureStore);
  const { control, setValue, getValues, errors, register } = props;

  const [showDoa, setShowDoa] = useState(false);

  const values = getValues({ nest: true });

  const onMaritialSelect = ([event]) => {
    let val = event.target.value;
    setShowDoa(val === "MARRIED" ? true : false);
    return event.target.value;
  };

  useEffect(() => {
    if (
      values.personalInfo &&
      values.personalInfo.maritalStatus === "MARRIED"
    ) {
      setShowDoa(true);
    }
  }, []);
  return (
    <div style={{ paddingBottom: 25 }}>
      <CardHeader
        avatar={
          <Avatar aria-label="Personal Info ">
            <FaceIcon />
          </Avatar>
        }
        title={
          <h2>
            <strong>Personal Info</strong>
          </h2>
        }
      />
      <Container style={{ paddingBottom: 25 }}>
        {/* Perssonal details */}
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormControl
              fullWidth
              margin="normal"
              error={errors.personalInfo && errors.personalInfo.gender}
            >
              <InputLabel htmlFor="gender">
                <IntlMessages id="contact.form.gender" />
              </InputLabel>
              <Controller
                as={
                  <Select>
                    <MenuItem value="" disabled>
                      {" "}
                      Select Gender
                    </MenuItem>
                    {accConfig.gender &&
                      accConfig.gender.map((val) => (
                        <MenuItem value={val.key} key={val.key}>
                          {val.value}
                        </MenuItem>
                      ))}
                  </Select>
                }
                name="personalInfo.gender"
                label={<IntlMessages id="contact.form.gender" />}
                control={control}
                fullWidth
              />
              <FormHelperText>
                {errors.personalInfo &&
                  errors.personalInfo.gender &&
                  errors.personalInfo.gender.message}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <FormControl
              fullWidth
              margin="normal"
              error={errors.personalInfo && errors.personalInfo.religion}
            >
              <InputLabel htmlFor="religion">
                <IntlMessages id="contact.form.religion" />
              </InputLabel>
              <Controller
                as={
                  <Select>
                    <MenuItem value="" disabled>
                      {" "}
                      Select Religion
                    </MenuItem>
                    {accConfig.religion &&
                      accConfig.religion.map((val) => (
                        <MenuItem value={val.key} key={val.key}>
                          {val.value}
                        </MenuItem>
                      ))}
                  </Select>
                }
                name="personalInfo.religion"
                label={<IntlMessages id="contact.form.religion" />}
                control={control}
                fullWidth
              />
              <FormHelperText>
                {errors.personalInfo &&
                  errors.personalInfo.religion &&
                  errors.personalInfo.religion.message}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl
              fullWidth
              margin="normal"
              error={errors.personalInfo && errors.personalInfo.maritalStatus}
            >
              <InputLabel htmlFor="maritalStatus">
                <IntlMessages id="contact.form.maritalStatus" />
              </InputLabel>
              <Controller
                as={
                  <Select>
                    <MenuItem value="" disabled>
                      {" "}
                      Select Marital Status
                    </MenuItem>
                    {accConfig.maritalStatus &&
                      accConfig.maritalStatus.map((val) => (
                        <MenuItem value={val.key} key={val.key}>
                          {val.value}
                        </MenuItem>
                      ))}
                  </Select>
                }
                onChange={onMaritialSelect}
                name="personalInfo.maritalStatus"
                label={<IntlMessages id="contact.form.maritalStatus" />}
                control={control}
                fullWidth
              />

              <FormHelperText>
                {errors.personalInfo &&
                  errors.personalInfo.maritalStatus &&
                  errors.personalInfo.maritalStatus.message}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        {/* Dob nad Age */}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <DateTimeInput
                setAgeValue={setValue}
                value={values.personalInfo && values.personalInfo.dateOfBirth}
                label="Date of Birth"
                name="personalInfo.dateOfBirth"
                format="YYYY-MM-DD"
                inputRef={register}
                fullWidth
                autoOk
              />
            </FormControl>
          </Grid>
          <input
            ref={register}
            name="personalInfo.anniversaryDate"
            type="text"
            hidden
          />
          {showDoa && (
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <DateTimeInput
                  value={
                    values.personalInfo && values.personalInfo.anniversaryDate
                  }
                  label="Anniversary Date"
                  name="personalInfo.anniversaryDate"
                  format="DD/MM/YYYY"
                  inputRef={register}
                  fullWidth
                  autoOk
                />
              </FormControl>
            </Grid>
          )}
        </Grid>
        {/* Maritial Status And DoA */}
        <Grid container spacing={2}></Grid>
      </Container>
    </div>
  );
}
