import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";

import {
  Button,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  FormControl,
  TextField,
  Radio,
  RadioGroup,
  Divider,
  Checkbox,
} from "@material-ui/core";

import { Alert } from "reactstrap";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import CircularProgress from "@material-ui/core/CircularProgress";
import IntlMessages from "util/IntlMessages";
import { signupUser, logUserId } from "../actions";

import { CrudNotification } from "util/helpers";

import SocialButton from "../SocialButton";
import { saveAuthInfo } from "util/check-auth";
const defaultVal = {
  phone: "",
  terms_conditions: false,
  privacy_policy: false,
  accountType: "TRIAL",
  usefor: "personal",
  // passwordConfirmation: ""
};
const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
const formSchema = yup.object().shape({
  phone: yup.string().matches(phoneRegExp, "Phone number is not valid"),
  terms_conditions: yup
    .boolean()
    .required("The terms and conditions must be accepted.")
    .oneOf([true], "The terms and conditions must be accepted."),
  privacy_policy: yup
    .boolean()
    .required("The Metta Privacy Policy must be accepted.")
    .oneOf([true], "The Metta Privacy Policy must be accepted."),
});

const SignUp = (props) => {
  const dispatch = useDispatch();
  const [showOrgText, setShowOrgText] = useState(false);
  const [formVals, setformVals] = useState({});
  const { authUser, error } = useSelector(({ auth }) => auth);
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const { handleSubmit, register, reset, control, errors, formState } = useForm(
    {
      validationSchema: formSchema,
      defaultValues: defaultVal,
      mode: "onChange",
    }
  );

  const onSubmit = (values) => {
    setformVals(values);
  };

  const onUseForChange = ([event]) => {
    let val = event.target.value;
    setShowOrgText(val === "org" ? true : false);
    return event.target.value;
  };

  const handleSocialLogin = (user) => {
    formVals.name = user._profile.name;
    formVals.email = user._profile.email;
    formVals.name = user._profile.name;
    formVals.profileImageUrl = user._profile.profilePicURL;
    formVals.key = user._profile.id;
    dispatch(signupUser(formVals)).then((response) => {
      CrudNotification("success", "You are logged in Google !");
      saveAuthInfo(user).then(
        dispatch(logUserId(formVals.email)).then((response) => {
          CrudNotification("success", "You are logged in !");
          props.history.push("/");
        })
      );
    });
  };

  const handleSocialLoginFailure = (err) => {
    CrudNotification("danger", "Google SignUp Fail !");
  };
  return (
    <div className="app-login-content">
      <div className="app-login-header">
        <h1>
          <IntlMessages id="appModule.createAccount" />
        </h1>
      </div>
      {error && <Alert color="danger">{error.message}</Alert>}

      <div className="app-login-form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <fieldset>
            <Controller
              // className="mt-1 my-sm-3"
              as={TextField}
              label={<IntlMessages id="appModule.phone" />}
              name="phone"
              control={control}
              fullWidth
              margin="normal"
              error={errors.phone}
              helperText={errors.phone && errors.phone.message}
              required
            />
            {/*
            <FormLabel htmlFor="consciousnessLevel" className="mt-1 my-sm-3">
              <IntlMessages id="appModule.usefor" />
            </FormLabel>

            <Controller
              onChange={onUseForChange}
              as={
                <RadioGroup aria-label="usefor">
                  <FormControlLabel
                    value="personal"
                    control={<Radio />}
                    label={<IntlMessages id="appModule.personal" />}
                  />
                  <FormControlLabel
                    value="org"
                    control={<Radio />}
                    label={<IntlMessages id="appModule.org" />}
                  />
                </RadioGroup>
              }
              name="usefor"
              control={control}
            />

            {showOrgText && (
              <Controller
                className="mt-1 my-sm-3"
                as={TextField}
                label={<IntlMessages id="appModule.organization" />}
                name="organization"
                control={control}
                fullWidth
                margin="normal"
                error={errors.organization}
                helperText={errors.organization && errors.organization.message}
              />
            )} */}
            <Controller
              className="mt-1 my-sm-3"
              as={TextField}
              label={<IntlMessages id="appModule.organization" />}
              name="organization"
              control={control}
              fullWidth
              margin="normal"
              error={errors.organization}
              required
              helperText={errors.organization && errors.organization.message}
            />
            <hr></hr>
            <FormControl fullWidth error={errors.terms_conditions}>
              <Controller
                label={
                  <a target="_blank" href="https://metta.one/terms-of-use.html">
                    <IntlMessages id="appModule.terms_conditions" />
                  </a>
                }
                name="terms_conditions"
                control={control}
                fullWidth
                error={errors.terms_conditions}
                helperText={
                  errors.terms_conditions && errors.terms_conditions.message
                }
                as={<FormControlLabel control={<Checkbox color="primary" />} />}
              />
              {errors.terms_conditions && (
                <FormHelperText error={true}>
                  {errors.terms_conditions && errors.terms_conditions.message}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth error={errors.privacy_policy}>
              <Controller
                label={
                  <a
                    target="_blank"
                    href="https://metta.one/privacy-policy.html"
                  >
                    <IntlMessages id="appModule.privacy_policy" />
                  </a>
                }
                name="privacy_policy"
                control={control}
                fullWidth
                error={errors.privacy_policy}
                helperText={
                  errors.privacy_policy && errors.privacy_policy.message
                }
                as={<FormControlLabel control={<Checkbox color="primary" />} />}
              />
              {errors.privacy_policy && (
                <FormHelperText error={true}>
                  {errors.privacy_policy && errors.privacy_policy.message}
                </FormHelperText>
              )}
            </FormControl>

            <div className="mb-3 d-flex align-items-center justify-content-between mt-1 my-sm-3">
              <SocialButton
                provider="google"
                appId={clientId}
                // vgSmfVLx4LGPxStnxOLXAlZT
                onLoginSuccess={handleSocialLogin}
                onLoginFailure={handleSocialLoginFailure}
                type="submit"
                disabled={!formState.isValid}
              >
                <i className="zmdi zmdi-google"></i> &nbsp;&nbsp;Signup
              </SocialButton>

              {/* <Button variant="contained" color="primary" type="submit">
                <IntlMessages id="appModule.regsiter" />
              </Button> */}
              <Link to="/signin">
                <IntlMessages id="signUp.alreadyMember" />
              </Link>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  );
};

export default withRouter(SignUp);
