import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from "react-hook-form";

// import ReactSelect from 'react-select';
import {
  Grid,
  CardHeader,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  InputLabel,
  Container,
} from "@material-ui/core";

import IntlMessages from "util/IntlMessages";

export default function BloodGroupForm(props) {
  const {
    control,
    errors,
    register,
    setValue,
    getValues,
    updateMedicalFor,
  } = props;
  const { accConfig } = useSelector(({ uiConfigureStore }) => uiConfigureStore);
  const { contact } = useSelector(({ contactStore }) => contactStore);

  useEffect(() => {
    setValue("patientId", contact.clientId);
  }, []);

  return (
    <div>
      {updateMedicalFor !== "bloodgroup" && (
        <CardHeader title={<h2>Blood Group</h2>} />
      )}
      <Container style={{ paddingBottom: 25 }}>
        <Grid container spacing={1}>
          <Grid item xs spacing={1}>
            <FormControl fullWidth margin="normal" error={errors.bloodType}>
              <InputLabel htmlFor="bloodType">
                <IntlMessages id="medicRecord.form.blood_type" />
              </InputLabel>
              <Controller
                as={
                  <Select>
                    <MenuItem value="" disabled>
                      {" "}
                      Select Blood Group
                    </MenuItem>
                    {accConfig.bloodType &&
                      accConfig.bloodType.map((val) => (
                        <MenuItem value={val.key} key={val.key}>
                          {val.value}
                        </MenuItem>
                      ))}
                  </Select>
                }
                name="bloodType"
                label={<IntlMessages id="medicRecord.form.blood_type" />}
                control={control}
                fullWidth
                // margin="normal"
                error={errors.bloodType}
              />
              <FormHelperText>
                {errors.bloodType && errors.bloodType.message}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
