import React, { useEffect } from "react";
import { StyleSheet, Font } from "@react-pdf/renderer";

export const registerFont = () => {
  // Font.register({
  //   family: "Roboto Bold",
  //   src: require("assets/fonts/Roboto-Medium.ttf"),
  // });
};

// Font.register({
//   family: "Roboto Bold",
//   src: require("assets/fonts/Roboto-Medium.ttf"),
// });
// Font.register({
//   family: "Nepali",
//   src: require("assets/fonts/preeti.ttf"),
// });
const styles = StyleSheet.create({
  body: {
    // paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    paddingBottom: 20,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },

  //  cell header
  tableColHeader: {
    width: "20%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    // borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    alignItems: "center",
  },
  tableCellHeader: {
    fontFamily: "Roboto Bold",
    margin: "auto",
    margin: 5,
    fontSize: 8,
  },

  //  Table normal cell
  tableCol: {
    // width: "20%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    alignItems: "center",
  },
  tableCell: {
    margin: "auto",
    margin: 5,
    fontSize: 8,
  },

  //  Col Left
  tableColLeft: {
    // width: "20%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    // alignItems: "left",
  },
  tableCellLeft: {
    margin: "auto",
    margin: 5,
    fontSize: 8,
    alignItems: "left",
  },
  // empty table row
  tableEmptyRow: {
    height: 3,
    margin: "auto",
    flexDirection: "row",
  },
  tableEmptyCol: {
    width: "100%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    // borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,

    alignItems: "center",
  },
  header: {
    height: 90,
    display: "table",
    width: "auto",
    paddingBottom: 20,
    paddingTop: 20,
  },
  headerText: {
    alignItems: "center",
    fontFamily: "Roboto Bold",
  },
  summary: {
    padding: 15,
    alignItems: "center",
  },
  summaryText: {
    alignItems: "center",
  },

  signature: {
    display: "table",
    width: "auto",
    paddingBottom: 20,
    paddingTop: 30,
  },
  signatureCol: {
    borderWidth: 1,
    // borderLeftWidth: 0,
    // borderTopWidth: 0,
    // alignItems: "left",
  },
  signatureCell: {
    fontFamily: "Roboto Bold",
    margin: "auto",
    margin: 5,
    fontSize: 8,
    alignItems: "left",
  },
  // nepaliText: {
  //   fontFamily: "Nepali",
  //   margin: "auto",
  //   margin: 5,
  //   fontSize: 8,
  //   alignItems: "left",
  // },
  pageFooter: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 8,
    bottom: 10,
    left: 0,
    right: 10,
    textAlign: "right",
    color: "grey",
  },

  image: {
    // marginVertical: 15,
    // marginHorizontal: 100,
  },
});

export default styles;
