import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

// Vendors Components
import {
  CardHeader,
  Tooltip,
  IconButton,
  Avatar,
  Button,
} from "@material-ui/core";

import { Skeleton } from "@material-ui/lab";
import { Alert } from "reactstrap";

import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Add as AddIcon,
} from "@material-ui/icons";

//  Themes Components

import Auxiliary from "util/Auxiliary";
import Widget from "components/Widget/index";
import AlertBox from "components/AlertBox/index";
// Custom Components and Helpers
import { capitalize, displayDate } from "util/helpers";

//  Actions
import { onAddMedicationForm } from "redux/actions/MedicalActions";

const Diagnosis = (props) => {
  const dispatch = useDispatch();
  const { contact } = useSelector(({ contactStore }) => contactStore);
  const { medicalrecord } = useSelector(({ medRecordStore }) => medRecordStore);

  const [cp, setCp] = useState(1);
  const [total, setTotal] = useState(1);

  const [currentDiagnosis, setCurrentDiagnosis] = useState([]);
  // on Add History
  const onAddHistory = (type = "add") => {
    dispatch(onAddMedicationForm(type, "diagnosis"));
    // dispatch(onContactForm(contact, 'update'));
  };

  useEffect(() => {
    onLoadMore(1);
  }, [medicalrecord.diagnosisHistory]);

  const onLoadMore = (val) => {
    const indexOfLastTodo = val * 3;
    const currentDiags = medicalrecord.diagnosisHistory.slice(
      0,
      indexOfLastTodo
    );
    setCp(val);
    setCurrentDiagnosis(currentDiags);
  };

  return (
    <Widget styleName="jr-card-full jr-card-tabs-right jr-card-profile">
      <CardHeader
        action={
          <Tooltip title="Add Diagnosis ">
            <IconButton
              aria-label="Add"
              onClick={() => onAddHistory()}
              variant="contained"
              color="primary"
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        }
        title={"Diagnosis"}
      />
      <div className="jr-tabs-content jr-task-list">
        {!medicalrecord.diagnosisHistory && (
          <AlertBox
            infotype="danger"
            message="No Diagnosis Found."
            onClick={onAddHistory}
          />
        )}
        {medicalrecord.diagnosisHistory && (
          <div className="timeline-section">
            {currentDiagnosis.map((value, index) => {
              return (
                <div className={`timeline-item`} key={index}>
                  <div className="timeline-badge timeline-img ">
                    <Avatar>{contact.name.charAt(0).toUpperCase()}</Avatar>
                  </div>

                  <div className="timeline-panel ">
                    <div className="timeline-panel-header">
                      <div className="timeline-heading">
                        <div
                          span={18}
                          dangerouslySetInnerHTML={{
                            __html: value.diagnosisDisplay,
                          }}
                        />
                        <small>
                          {value.diagnosedByAndDate +
                            " at " +
                            displayDate(value.dateTimeDisplay)}
                        </small>
                      </div>
                    </div>
                    <div className="timeline-body"></div>
                  </div>
                </div>
              );
            })}
            {medicalrecord.diagnosisHistory.length !==
              currentDiagnosis.length && (
              <Button
                onClick={() => onLoadMore(cp + 1)}
                variant="contained"
                color="primary"
              >
                Load More <KeyboardArrowDownIcon />
              </Button>
            )}
          </div>
        )}
      </div>
    </Widget>
  );
};
export default Diagnosis;
