import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from 'react-redux';

// Vendors Components
import { CardHeader, IconButton, CardContent } from '@material-ui/core';


import {
    Area, AreaChart, Bar, BarChart, ResponsiveContainer,
    Legend, CartesianGrid, ComposedChart,
    Tooltip as TooltipChart, XAxis, LabelList, YAxis, ReferenceLine, Brush, Line
} from 'recharts';

import ReportBox from 'components/ReportBox';

import Widget from "components/Widget";
import ChartCard from 'components/dashboard/Common/ChartCard';

import IntlMessages from "util/IntlMessages";
import { getValuefromLookup, getDefaultUnit, displayDate } from "util/helpers";


const BloodPressure = (props) => {
    const dispatch = useDispatch();
    const { contact } = useSelector(({ contactStore }) => contactStore);
    const { medicalrecord } = useSelector(({ medRecordStore }) => medRecordStore);
    const [data, setData] = useState([]);

    useEffect(() => {
        onBPData();
    }, [medicalrecord]);

    const onBPData = () => {
        let allDatalist = [];
        allDatalist = medicalrecord.bloodPressureHistory.map((val) => {
            val.dateDisplay = displayDate(val.dateTimeDisplay, "datetimeR");
            return val;
        });

        setData(allDatalist.reverse());
    };
    console.log(data)

    return (
        <div>
            <Widget styleName="jr-card-full jr-card-tabs-right jr-card-profile">
                <CardHeader
                    title="Blood Pressure"
                />
                <CardContent>
                    <ResponsiveContainer className="card-img-bottom overflow-hidden" width="100%" height={300}>
                        <ComposedChart data={data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                            <XAxis
                                dataKey="dateDisplay"
                                padding={{ left: 30, right: 30 }}
                            />
                            <CartesianGrid stroke="#f5f5f5" />

                            <XAxis
                                dataKey="dateDisplay"
                                padding={{ left: 30, right: 30 }}
                            />
                            <YAxis padding={{ top: 30 }} />
                            <TooltipChart />
                            <Legend />
                            <ReferenceLine y={0} stroke="#000" />

                            {/* <Tooltip /> */}
                            <Line
                                type="monotone"
                                dataKey="diastolicPressure"
                                stroke="#40feff"
                                name={'Diastolic Pressure in mmHg'}
                                strokeWidth={3}
                            >
                                <LabelList dataKey="diastolicPressure" position="top" />
                            </Line>
                            <Line
                                type="monotone"
                                dataKey="systolicPressure"
                                stroke="#ffc000"
                                name={'Systolic Pressure in mmHg'}
                                strokeWidth={3}
                            >
                                <LabelList dataKey="systolicPressure" position="top" />
                            </Line>
                        </ComposedChart>
                    </ResponsiveContainer>
                </CardContent>
            </Widget>
        </div>
    );
};
export default BloodPressure;
