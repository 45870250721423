// material ui
import { Dialog, withMobileDialog } from "@material-ui/core";
import TransitionSlide from "components/Dialog/TransitionSlide";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { onRecommendedTestFormClose } from "../actions";
import FormComponent from "./FormComponent";

const RecommendedTestModal = (props) => {
  const dispatch = useDispatch();
  const { addRecommendedTestModal, updateRecommendedTestFormView } =
    useSelector(({ visitStore }) => visitStore);

  // on contacts modal close
  const onClose = () => {
    dispatch(onRecommendedTestFormClose());
    // dispatch(clearIcds());
  };
  return (
    <Dialog
      fullScreen={props.fullScreen}
      fullWidth={true}
      maxWidth="md"
      scroll="paper"
      TransitionComponent={TransitionSlide}
      open={addRecommendedTestModal || updateRecommendedTestFormView}
      onClose={onClose}
    >
      {(addRecommendedTestModal || updateRecommendedTestFormView) && (
        <FormComponent />
      )}
    </Dialog>
  );
};

RecommendedTestModal.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};
export default withMobileDialog()(RecommendedTestModal);
