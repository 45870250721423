import React, { useEffect, useState } from "react";
import { useFieldArray, Controller } from "react-hook-form";

import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  TextField,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  InputLabel,
  InputAdornment,
  Divider,
  Container,
  Avatar,
  CardHeader,
  IconButton,
  Tooltip,
  Button,
} from "@material-ui/core";

import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

import { DateTimeInput } from "util/formInput";
import { Refresh, Add as AddIcon, Clear, Close } from "@material-ui/icons";

import IntlMessages from "util/IntlMessages";
import { getInvestigation } from "redux/actions/Setting";

export default function InvestLabWorkForm(props) {
  const dispatch = useDispatch();
  const {
    control,
    errors,
    accConfig,
    register,
    getValues,
    setValue,
    watch,
    updateInPatientFor,
  } = props;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "investigation.tests",
    keyName: "keyId", //, default to "id", you can change the key name
  });

  const watcht = watch("investigation.tests");
  const values = getValues({ nest: true });
  const onAdd = () => {
    append({
      date: null,
      testItem: "",
      result: 0,
      note: "",
    });
  };

  useEffect(() => {
    // console.log(fields);
    if (fields.length === 0) {
      onAdd();
    }
  }, []);

  // get investigation json value
  const getInvestValue = (val) => {
    let value =
      accConfig.investigations.length !== 0
        ? accConfig.investigations.find((o) => o.key === val)
        : "";
    return value;
  };
  const onTestItemChange = (data, index) => {
    // console.log(data, index);
    if (data && data.unit) {
      // setValue("name", "Vikash");
      setValue(`investigation.tests[${index}].unit`, data.unit);
    } else {
      setValue(`investigation.tests[${index}].unit`, "");
    }
    return data;
  };

  const onRemove = (index) => {
    remove(index);
  };

  const onReset = (index) => {
    remove(index);
    onAdd();
    // console.log('reset');
  };
  // console.log(accConfig.investigations);

  // filter Icd options
  const filterOptions = createFilterOptions({
    // matchFrom: 'start',
    stringify: (option) => option.group + option.value,
  });
  return (
    <div>
      {updateInPatientFor === "investigation" && (
        <CardHeader title={<h3>Lab Works</h3>} />
      )}

      {fields.map((item, index) => {
        return (
          <div key={item.keyId}>
            <input
              ref={register}
              name={`investigation.tests[${index}].unit`}
              type="text"
              hidden
              defaultValue={item.unit}
            />
            <Container style={{ paddingBottom: 25 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth margin="normal">
                    <DateTimeInput
                      setAgeValue={setValue}
                      value={
                        values.investigation &&
                        values.investigation.tests &&
                        values.investigation.tests[index] &&
                        values.investigation.tests[index].date
                      }
                      label="Date"
                      name={`investigation.tests[${index}].date`}
                      format="YYYY-MM-DD"
                      inputRef={register}
                      fullWidth
                      autoOk
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <FormControl
                    fullWidth
                    margin="normal"
                    error={
                      errors.investigation &&
                      errors.investigation.tests &&
                      errors.investigation.tests[index] &&
                      errors.investigation.tests[index].testItem
                    }
                  >
                    <Controller
                      as={
                        <Autocomplete
                          options={accConfig.investigations}
                          getOptionLabel={(option) =>
                            option.display ? option.display : option.value
                          }
                          renderOption={(option) => (
                            <span>
                              {option.display ? option.display : option.value}
                            </span>
                          )}
                          filterOptions={filterOptions}
                          groupBy={(option) => option.group}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={
                                errors.investigation &&
                                errors.investigation.tests &&
                                errors.investigation.tests[index] &&
                                errors.investigation.tests[index].testItem
                              }
                              label={
                                <IntlMessages id="inpatient.form.investigation.testItem" />
                              }
                            />
                          )}
                        />
                      }
                      onChange={([, data]) => onTestItemChange(data, index)}
                      name={`investigation.tests[${index}].testItem`}
                      control={control}
                      defaultValue={
                        item.testItem && getInvestValue(item.testItem)
                      }
                    />
                    <FormHelperText>
                      {errors.investigation &&
                        errors.investigation.tests &&
                        errors.investigation.tests[index] &&
                        errors.investigation.tests[index].testItem &&
                        errors.investigation.tests[index].testItem.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <FormControl fullWidth margin="normal">
                    <Controller
                      label={
                        <span>
                          <IntlMessages id="inpatient.form.investigation.result" />{" "}
                          {watcht && watcht[index] && watcht[index].unit && (
                            <strong>({watcht[index].unit})</strong>
                          )}
                        </span>
                      }
                      as={TextField}
                      name={`investigation.tests[${index}].result`}
                      control={control}
                      fullWidth
                      defaultValue={item.result}
                      error={
                        errors.investigation &&
                        errors.investigation.tests &&
                        errors.investigation.tests[index] &&
                        errors.investigation.tests[index].result
                      }
                      helperText={
                        errors.investigation &&
                        errors.investigation.tests &&
                        errors.investigation.tests[index] &&
                        errors.investigation.tests[index].result &&
                        errors.investigation.tests[index].result.message
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={11} sm={11}>
                  <FormControl fullWidth margin="normal">
                    <Controller
                      label={
                        <IntlMessages id="inpatient.form.investigation.note" />
                      }
                      as={TextField}
                      name={`investigation.tests[${index}].note`}
                      control={control}
                      fullWidth
                      multiline
                      defaultValue={item.note}
                      error={
                        errors.investigation &&
                        errors.investigation.tests &&
                        errors.investigation.tests[index] &&
                        errors.investigation.tests[index].note
                      }
                      helperText={
                        errors.investigation &&
                        errors.investigation.tests &&
                        errors.investigation.tests[index] &&
                        errors.investigation.tests[index].note &&
                        errors.investigation.tests[index].note.message
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={1} sm={1}>
                  <Container>
                    <Button
                      color="secondary"
                      aria-label="Remove"
                      onClick={() => onRemove(index)}
                      size="small"
                    >
                      <Tooltip
                        title={"Remove tests Notes  " + (index + 1)}
                        size="large"
                      >
                        <Clear />
                      </Tooltip>
                    </Button>
                  </Container>
                </Grid>
              </Grid>
            </Container>

            {index + 1 !== fields.length && <Divider />}
          </div>
        );
      })}
      <Tooltip title="Click to Add tests detail">
        <Button
          aria-label="Add"
          onClick={() => onAdd()}
          variant="contained"
          color="primary"
        >
          Add More <AddIcon />
        </Button>
      </Tooltip>
    </div>
  );
}
