import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

// material ui
import { withMobileDialog, Dialog } from "@material-ui/core";

import TransitionSlide from "components/Dialog/TransitionSlide";

// Actions
import { onMedicFormClose } from "redux/actions/MedicalActions";
import { clearIcds } from "redux/actions/Setting";

import FormComponent from "./FormComponent";

const FormModal = (props) => {
  const dispatch = useDispatch();
  const { addMedicationModal, updateMedicalFormView, formMedFor } = useSelector(
    ({ medRecordStore }) => medRecordStore
  );

  // on contacts modal close
  const onClose = () => {
    dispatch(onMedicFormClose());
    dispatch(clearIcds());
  };
  return (
    <Dialog
      fullScreen={props.fullScreen}
      fullWidth={true}
      maxWidth="md"
      scroll="paper"
      TransitionComponent={TransitionSlide}
      open={addMedicationModal || updateMedicalFormView}
      onClose={onClose}
    >
      {(addMedicationModal || updateMedicalFormView) && <FormComponent />}
    </Dialog>
  );
};

FormModal.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};
export default withMobileDialog()(FormModal);
