import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";

// material ui
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
} from "@material-ui/core";
import { Alert, Spinner } from "reactstrap";

// custom Components
import DialogTitle from "components/Dialog/DialogTitle";

// Actions
import {
  getActiveUsers,
  onTemplateClose,
  onTemplateUpdate,
  onTemplateAdd,
} from "services/actions";

// Utilities and helpers
import IntlMessages from "util/IntlMessages";
import {
  CrudNotification,
  datetoArray,
  timeToArray,
  getValuefromLookup,
} from "util/helpers";
import { formSchema, initInboxVal } from "../formIndex";
// components
import TemplateSubjectForm from "./TemplateSubjectForm";
import BodyForm from "./BodyForm";
import PersonalizationForm from "./PersonalizationForm";
import { ON_MAIL_CHECKED } from "constants/ActionTypes";

const FormTitle = (props) => {
  const { template, updateFormTemplate } = props;
  if (updateFormTemplate) {
    return <span>Update {template.subject}</span>;
  } else {
    return <span>Add Template</span>;
  }
};

const TemplateForm = (props) => {
  const dispatch = useDispatch();
  const { accConfig, districts, appUiConfig, uiUsers } = useSelector(
    ({ uiConfigureStore }) => uiConfigureStore
  );
  const {
    templates,
    templatesLoading,
    template,
    addFormTemplate,
    updateFormTemplate,
    viewTemplate,
    aboutType,
    formerror,
    is_saving,
  } = useSelector(({ templateStore }) => templateStore);

  const [initialVal, setInitialVal] = useState(
    updateFormTemplate ? template : initInboxVal
  );
  const [isChecked, setIsChecked] = useState(initialVal.status === "ACTIVE");

  const {
    handleSubmit,
    register,
    reset,
    control,
    errors,
    setValue,
    getValues,
    watch,
  } = useForm({
    validationSchema: formSchema,
    defaultValues: initialVal,
    mode: "onBlur",
  });

  useEffect(() => {
    uiUsers.length === 0 && dispatch(getActiveUsers());
    // setActive(initialVal.status === "ACTIVE");
  }, []);

  // On update submit
  const onSubmit = (values) => {
    if (values.id) {
      // console.log("add", values);
      return dispatch(onTemplateUpdate(values)).then((res) => {
        CrudNotification("success", "Note added Successfully !");
      });
    } else {
      // console.log("update", values);
      return dispatch(onTemplateAdd(values)).then((res) => {
        CrudNotification("success", "Note updated Successfully !");
      });
    }
  };

  // Process form values before submitting
  const transform = (onSubmitFunc) => (data) => {
    let values = data;
    values.personalization.salutation = values.personalization.salutation
      .replace(",", "")
      .trim();
    onSubmitFunc(values);
  };
  // on contacts modal close
  const onClose = () => {
    dispatch(onTemplateClose());
  };

  const values = getValues({ nest: true });
  const onChecked = (event) => {
    // console.log(event[1]);
    setIsChecked(event[1]);
    return event[1] ? "ACTIVE" : "INACTIVE";
  };

  console.log(errors);
  return (
    <Fragment>
      <DialogTitle id="contact-form" onClose={onClose}>
        <FormTitle
          updateFormTemplate={updateFormTemplate}
          template={template}
        />
      </DialogTitle>
      <DialogContent dividers={true}>
        {formerror && <Alert color="danger"> {formerror.message} </Alert>}
        {updateFormTemplate && (
          <input
            ref={register}
            name="id"
            type="text"
            hidden
            defaultValue={template.id}
          />
        )}
        <input ref={register} name="status" type="text" hidden />

        <TemplateSubjectForm
          {...{
            control,
            register,
            getValues,
            setValue,
            errors,
            updateFormTemplate,
            template,
            watch,
          }}
        />
        <BodyForm
          {...{
            control,
            register,
            getValues,
            setValue,
            errors,
            updateFormTemplate,
            template,
            watch,
          }}
        />
        <PersonalizationForm
          {...{
            control,
            register,
            getValues,
            setValue,
            errors,
            updateFormTemplate,
            template,
            watch,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          className="button buttonBlack"
          type="button"
          onClick={() => {
            reset(initialVal);
          }}
        ></Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSubmit(transform(onSubmit))}
          disabled={is_saving}
        >
          {is_saving && <Spinner size="sm" />}
          <IntlMessages id="form.save" />
        </Button>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          onClick={() => onClose()}
        >
          <IntlMessages id="form.cancel" />
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default TemplateForm;
