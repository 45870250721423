import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  CircularProgress,
  MenuItem,
  Menu,
  Drawer,
  IconButton,
  Checkbox,
  Button,
  Tooltip,
  Fab,
  Chip,
  TextField,
} from "@material-ui/core";

import { Calendar } from "@material-ui/pickers";

// import tasks from '../data/tasks';
// actions
import { getAllTasks, onTaskForm, onTaskClose, getTaskTags } from "./actions";

import { folders } from "./data";
import IntlMessages from "util/IntlMessages";
import CustomScrollbars from "util/CustomScrollbars";
import { getColor, CrudNotification, capitalize } from "util/helpers";

import TaskList from "./TaskList";
import ComposeTask from "./Compose";
import AppModuleHeader from "./AppModuleHeader";
import TaskDetail from "./TaskDetail";
import { getConfigData } from "appRoutes/actions";
import { find } from "@amcharts/amcharts4/.internal/core/utils/Iterator";

const TaskPage = (props) => {
  const dispatch = useDispatch();
  const { width } = useSelector(({ uiConfigureStore }) => uiConfigureStore);
  const { loggedUser } = useSelector(({ auth }) => auth);
  const { tasks, tasksLoading, task, viewTask, taskTags } = useSelector(
    ({ taskStore }) => taskStore
  );

  const [searchTask, setSearchTask] = useState("");
  const [noContentFoundMessage, setNoContentFoundMessage] = useState(
    "No task found in selected folder"
  );
  const [drawerState, setDrawerState] = useState(false);

  const [allTask, setAllTask] = useState(tasks);
  const [filterDate, setFilterDate] = useState(moment());
  const [selectedFolder, setSelectedFolder] = useState(2);
  const [folderTasks, setFolderTasks] = useState(tasks);
  const [filterVal, setFilterVal] = useState("status");
  const [searchVal, setSearchVal] = useState("ACTIVE");

  useEffect(() => {
    document.title = "Inbox - Metta";
    dispatch(getConfigData("taskCategory"));
    // dispatch(getTaskTags());
  }, []);
  useEffect(() => {
    if (filterVal && searchVal === "all") {
      dispatch(getAllTasks());
    } else {
      dispatch(getAllTasks(filterVal, searchVal));
    }
  }, [filterVal, searchVal]);
  useEffect(() => {
    if (tasks) {
      setAllTask(tasks);

      setFolderTasks(tasks);
    }
  }, [tasks]);
  // get List of contacts
  const getTasksList = (filterVal = null, searchVal = null) => {
    dispatch(getAllTasks(filterVal, searchVal));
  };
  // on Task View
  const onTaskSelect = (task) => {
    dispatch(onTaskForm(task, "view"));
  };

  // on compose Task
  const onAddTask = () => {
    dispatch(onTaskForm(null, "add"));
  };

  const onNavTasks = (folder) => {
    if (folder !== "all") {
      dispatch(onTaskClose());
      setSelectedFolder(folder.id);
      setNoContentFoundMessage("No Task found in selected folder");

      setSearchVal(folder.handle);
    } else {
      setSelectedFolder(6);

      setSearchVal("all");
    }
  };
  const searchTaskF = (searchText) => {
    if (searchText === "") {
      setFolderTasks(allTask.filter((task) => !task.deleted));
    } else {
      const searchTasks = allTask.filter(
        (task) =>
          !task.deleted &&
          task.title.toLowerCase().indexOf(searchText.toLowerCase()) > -1
      );
      setFolderTasks(searchTasks);
    }
  };

  const updateSearch = (val) => {
    // if (filterVal === "search") {
    //   setSearchTask(val);
    //   searchTaskF(val);
    // } else if (filterVal === "status") {
    // getTasksList(filterVal, val);
    // }
  };

  const onToggleDrawer = () => {
    setDrawerState(!drawerState);
  };

  const handleChangeDate = (date) => {
    setFilterDate(date);
    const searchTasks = allTask.filter((task) => {
      let sDate = moment(task.startDateTime).format("DD-MM-YYYY");
      let eDate = moment(task.endDateTime).format("DD-MM-YYYY");
      let dDate = moment(date).format("DD-MM-YYYY");
      if (dDate === sDate) {
        return task;
      } else if (dDate === eDate) {
        return task;
      }
    });
    setFolderTasks(searchTasks);
  };
  const displayTask = (folderTasks, noContentFoundMessage) => {
    return (
      <div className="module-box-column">
        {!viewTask ? (
          folderTasks.length === 0 ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                height:
                  width >= 1200 ? "calc(100vh - 259px)" : "calc(100vh - 238px)",
              }}
            >
              {noContentFoundMessage}
            </div>
          ) : (
            <TaskList
              tasks={folderTasks}
              onTaskSelect={onTaskSelect}
              width={width}
            />
          )
        ) : (
          <TaskDetail task={task} width={width} />
        )}
      </div>
    );
  };

  const TaskSideBar = () => {
    return (
      <div className="module-side" style={{ minWidth: "290px" }}>
        <div className="module-side-header">
          <div className="module-logo">
            <i className="zmdi zmdi-etask mr-3" />
            <span>
              <IntlMessages id="task.task" />
            </span>
          </div>
        </div>

        <div className="module-side-content">
          <CustomScrollbars
            className="module-side-scroll scrollbar"
            style={{
              height:
                width >= 1200 ? "calc(100vh - 200px)" : "calc(100vh - 80px)",
            }}
          >
            <div className="module-add-task">
              <Button
                variant="contained"
                color="primary"
                className="btn-block"
                onClick={() => onAddTask()}
              >
                <i className="zmdi zmdi-edit zmdi-hc-fw" />
                <IntlMessages id="task.compose" />{" "}
              </Button>
            </div>
            <div>
              <Calendar
                date={filterDate}
                onChange={(date) => handleChangeDate(date)}
              />
            </div>
            <hr />

            <ul className="module-nav">
              {folders.map((folder, index) => {
                if (index === 0)
                  return (
                    <>
                      <li key={index} onClick={() => onNavTasks(folder)}>
                        <span
                          className={`jr-link ${
                            selectedFolder === folder.id || !selectedFolder
                              ? "active"
                              : ""
                          }`}
                        >
                          <i className={`zmdi zmdi-${folder.icon}`} />
                          <span>{folder.title}</span>
                        </span>
                      </li>
                    </>
                  );

                if (index === folders?.length - 1)
                  return (
                    <>
                      <li key={index} onClick={() => onNavTasks("all")}>
                        <span
                          className={`jr-link ${
                            selectedFolder === folder.id || !selectedFolder
                              ? "active"
                              : ""
                          }`}
                        >
                          <i className={`zmdi zmdi-${folder.icon}`} />
                          <span>{folder.title}</span>
                        </span>
                      </li>
                    </>
                  );
                return (
                  <>
                    <li key={index} onClick={() => onNavTasks(folder)}>
                      <span
                        className={`jr-link ${
                          selectedFolder === folder.id ? "active" : ""
                        }`}
                      >
                        <i className={`zmdi zmdi-${folder.icon}`} />
                        <span>{folder.title}</span>
                      </span>
                    </li>
                  </>
                );
              })}
            </ul>
          </CustomScrollbars>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="animated slideInUpTiny animation-duration-3">
        <div className="app-module">
          <div className="d-block d-xl-none">
            <Drawer open={drawerState} onClose={() => onToggleDrawer()}>
              {TaskSideBar()}
            </Drawer>
          </div>
          <div className="app-module-sidenav d-none d-xl-flex">
            {TaskSideBar()}
          </div>

          <div className="module-box">
            <div className="module-box-header">
              <IconButton
                className="drawer-btn d-block d-xl-none"
                aria-label="Menu"
                onClick={() => onToggleDrawer()}
              >
                <i className="zmdi zmdi-menu" />
              </IconButton>
              <AppModuleHeader
                placeholder="Search tasks"
                user={loggedUser}
                onChange={updateSearch}
                value={searchTask}
                filterVal={filterVal}
                setFilterVal={setFilterVal}
              />
            </div>

            <div className="module-box-content">
              {tasksLoading ? (
                <div
                  className="loader-view"
                  style={{
                    height:
                      width >= 1200
                        ? "calc(100vh - 259px)"
                        : "calc(100vh - 238px)",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                displayTask(folderTasks, noContentFoundMessage)
              )}

              <ComposeTask />
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-button btn-group-vertical">
        <Tooltip title={"Compose Task "} aria-label="Compose Task">
          <Fab color="primary" aria-label="add" onClick={() => onAddTask()}>
            <i className="zmdi zmdi-plus zmdi-hc-fw zmdi-hc-lg" />
          </Fab>
        </Tooltip>
      </div>
    </>
  );
};

export default TaskPage;
