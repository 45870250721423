import * as yup from "yup";
import moment from "moment";
export const initFormVal = {
  interview: {
    time: null,
    questionAnswers: [
      {
        question: "",
        answer: 0,
      },
    ],
  },
  intakeOutputRecords: [
    {
      time: null,
      intakeOutputType: "",
      intakeOutputMethod: "",
      fluidType: "",
      amount: "",
      note: "",
    },
  ],
  medicationSchedules: [
    {
      time: null,
      medication: "",
      note: "",
    },
  ],
};

export const formSchema = yup.object().shape({
  // patientId: yup.string().required("PatientId is required"),
  // date: yup.string().required("Date is required"),
  interview: yup
    .object()
    .default(null)
    .nullable()
    .shape({
      time: yup.string().required("Time is required"),
      questionAnswers: yup.array().of(
        yup.object().shape({
          question: yup.string().required("Question is required"),
          answer: yup.string().required("Answer is required"),
        })
      ),
    }),
  intakeOutputRecords: yup.array().of(
    yup.object().shape({
      time: yup.string().required("Time is required"),
      intakeOutputType: yup.string().required("Intake Output Type is required"),
      intakeOutputMethod: yup
        .string()
        .required("Intake Output Method is required"),
      fluidType: yup.string().required("Fluid Type is required"),
      amount: yup.string().required("Amount is required"),
      // note: yup.string().required("Note is required"),
    })
  ),
  medicationSchedules: yup.array().of(
    yup.object().shape({
      time: yup.string().required("Time is required"),
      medication: yup.string().required("Medication is required"),
      // note: yup.string().required("Note is required"),
    })
  ),
});
