import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";

import moment from "moment";
import {
  Grid,
  TextField,
  Checkbox,
  FormControl,
  Container,
} from "@material-ui/core";

import {
  Face as FaceIcon,
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";


import IntlMessages from "util/IntlMessages";
import { DateTimeInput } from "util/formInput";

export default function DnrForm(props) {
  const { control, setValue, getValues, errors, accConfig, register } = props;

  const values = getValues({ nest: true });
  const checkboxVal =
    values.doNotResuscitate && values.doNotResuscitate.doNotResuscitate
      ? true
      : false;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <FormControl fullWidth margin="normal">
          <DateTimeInput
            setAgeValue={setValue}
            value={values.doNotResuscitate && values.doNotResuscitate.date}
            label="Date"
            name="doNotResuscitate.date"
            format="YYYY-MM-DD"
            inputRef={register}
            fullWidth
            autoOk
          />
        </FormControl>
      </Grid>
      <Grid item xs={6} sm={6}>
        <FormControl fullWidth margin="normal">
          {/* <InputLabel htmlFor="current"> */}
          <IntlMessages id="medicRecord.form.doNotResuscitate" />
          {/* </InputLabel> */}
          <Controller
            as={Checkbox}
            name={`doNotResuscitate.doNotResuscitate`}
            type="checkbox"
            defaultValue={checkboxVal}
            control={control}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth margin="normal">
          <Controller
            label={<IntlMessages id="medicRecord.form.orderedBy" />}
            as={TextField}
            name="doNotResuscitate.orderedBy"
            control={control}
            fullWidth
            error={errors.doNotResuscitate && errors.doNotResuscitate.orderedBy}
            helperText={
              errors.doNotResuscitate &&
              errors.doNotResuscitate.orderedBy &&
              errors.doNotResuscitate.orderedBy.message
            }
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth margin="normal">
          <Controller
            label={<IntlMessages id="medicRecord.form.note" />}
            as={TextField}
            name="doNotResuscitate.note"
            control={control}
            fullWidth
            multiline
            error={errors.doNotResuscitate && errors.doNotResuscitate.note}
            helperText={
              errors.doNotResuscitate &&
              errors.doNotResuscitate.note &&
              errors.doNotResuscitate.note.message
            }
            multiline
            rowsMax={4}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
}
