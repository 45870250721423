import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import { Button, Zoom, IconButton } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Alert } from "reactstrap";

import Auxiliary from "util/Auxiliary";
import AlertBox from "components/AlertBox/index";

import IntlMessages from "util/IntlMessages";
// Actions
import {
  onAddMedicationForm,
  getTestDetailByPId,
} from "redux/actions/MedicalActions";
import { setProfileTitle } from "redux/actions/Setting";
import SearchBar from "./SearchBar";

import HeightWeight from "./Charts/HeightWeight";
import OSaturation from "./Charts/OSaturation";
import BloodPressure from "./Charts/BloodPressure";
import Investigation from "./Charts/Investigation";

// Options
const options = [
  {
    label: "Height/Weight",
    value: "height_weight",
    component: <HeightWeight />,
  },
  { label: "Blood Pressure", value: "bp", component: <BloodPressure /> },
  { label: "O2 Saturation", value: "os", component: <OSaturation /> },
  {
    label: "Investigation",
    value: "investigation",
    component: <Investigation />,
  },
];
const Insights = (props) => {
  const dispatch = useDispatch();
  const { medicalrecord, graphFor } = useSelector(
    ({ medRecordStore }) => medRecordStore
  );
  const { accConfig, accountDefaultUnits } = useSelector(
    ({ configStore }) => configStore
  );

  const [selectedLists, setSelectedLists] = useState([]);
  const [rdata, setRData] = useState([]);

  const onAddMedicalRecord = () => {
    dispatch(onAddMedicationForm("add"));
  };
  useEffect(() => {
    if (props.location.state && props.location.state.item) {
      let urlItem = options.filter((val) => {
        if (val.value === props.location.state.item) {
          return val;
        }
      });
      // console.log(urlItem)
      setSelectedLists(urlItem);
    }
    dispatch(setProfileTitle({ id: 5, title: "Insights" }));
  }, []);

  useEffect(() => {
    setRData(selectedLists.reverse());
  }, [selectedLists]);

  return (
    <Auxiliary>
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-sm-12 col-12 ">
          <SearchBar
            options={options}
            setSelectedLists={setSelectedLists}
            selectedLists={selectedLists}
          />
        </div>
      </div>

      {!rdata.length > 0 && (
        <AlertBox
          infotype="info"
          message="No Insight Found. Search "
          // onClick={onAddMedicalRecord}
        />
      )}
      {medicalrecord.id && !accConfig.weightUnit && (
        <Skeleton variant="rect" height={100} />
      )}

      {medicalrecord.id && accConfig.weightUnit && (
        <>
          {rdata.map((item) => {
            return <div key={item.value}>{item.component}</div>;
          })}
        </>
      )}
    </Auxiliary>
  );
};

export default withRouter(Insights);
