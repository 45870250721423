import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
} from "constants/ActionTypes";

const INIT_STATE = {
  loader: false,
  alertMessage: "",
  showMessage: false,
  initURL: "",
  authUser: localStorage.getItem("user_id"),
  loggedUser: localStorage.mettaLoggedUser
    ? JSON.parse(localStorage.mettaLoggedUser)
    : null,
  sessionKey: {
    apiKey: "",
  },
  error: null,
  profilePic: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload,
      };
    }

    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
      };
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: "",
        showMessage: false,
        loader: false,
      };
    }

    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true,
      };
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false,
      };
    }

    // Login User
    case "SETLOGGEDUSER": {
      // console.log(action.payload)
      var profilepic = localStorage.profilepic;

      var authUser = localStorage.mettaLoggedUser
        ? JSON.parse(localStorage.mettaLoggedUser)
        : null;
      var apiKey = localStorage.mettaApiKey ? localStorage.mettaApiKey : null;

      return {
        ...state,
        profilePic: profilepic,
        loggedUser: authUser,
        sessionKey: {
          apiKey: apiKey,
        },
        requesting: false,
        successful: false,
        isloginPage: true,
        messages: [],
        // errors: []
      };
    }
    //  New Logic
    // Login User
    case "LOGUSERID_FULFILLED": {
      var loggedUser = action.payload.data;
      localStorage.setItem(
        "mettaLoggedUser",
        JSON.stringify(action.payload.data)
      );

      var profilepic = localStorage.profilepic;
      return {
        ...state,
        profilePic: profilepic,
        loggedUser: action.payload.data,
        requesting: false,
        successful: false,
        isloginPage: true,
        messages: [],
        // errors: []
        error: null,
        loader: false,
      };
    }
    case "LOGUSERID_PENDING": {
      return {
        ...state,
        error: null,
        loader: true,
      };
    }
    case "LOGUSERID_REJECTED": {
      return {
        ...state,
        error: action.payload.response.data,
        messages: [],
        errors: [],
        loader: false,
      };
    }

    case "LOGFIRSTUSERID_FULFILLED": {
      var loggedUser = action.payload.data;
      if (action.payload.status === 206) {
        const error = new Error("message");
        error.status = 403;
        throw error;
      }
      localStorage.setItem(
        "mettaLoggedUser",
        JSON.stringify(action.payload.data)
      );

      var profilepic = localStorage.profilepic;
      return {
        ...state,
        profilePic: profilepic,
        loggedUser: action.payload.data,
        requesting: false,
        successful: false,
        isloginPage: true,
        messages: [],
        // errors: []
        error: null,
        loader: false,
      };
    }
    case "LOGFIRSTUSERID_PENDING": {
      return {
        ...state,
        error: null,
        loader: true,
      };
    }
    case "LOGFIRSTUSERID_REJECTED": {
      return {
        ...state,
        error: action.payload.response.data,
        messages: [],
        errors: [],
        loader: false,
      };
    }

    // Login User
    case "LOGIN_FULFILLED": {
      // console.log(action.payload);
      var loggedUser = {
        userId: action.payload.data.userId,
        accountId: action.payload.data.accountId,
        name: action.payload.data.name,
        accessGroups: action.payload.data.accessGroups,
        roles: action.payload.data.roles,
      };
      localStorage.setItem(
        "mettaApiKey",
        action.payload.data.sessionKey.apiKey
      );
      localStorage.setItem("mettaLoggedUser", JSON.stringify(loggedUser));
      return {
        ...state,
        loggedUser: loggedUser,
        sessionKey: action.payload.data.sessionKey,
        requesting: false,
        successful: false,
        isloginPage: true,
        messages: [],
        // errors: []
        error: null,
        loader: false,
      };
    }
    case "LOGIN_PENDING": {
      return {
        ...state,
        error: null,
        loader: true,
      };
    }
    case "LOGIN_REJECTED": {
      return {
        ...state,
        error: action.payload.response.data,
        messages: [],
        errors: [],
        loader: false,
      };
    }

    // Sign Up
    case "SIGNUP_FULFILLED": {
      return {
        ...state,
        // messages: 'Sign Up Is Done.',
      };
    }
    case "SIGNUP_PENDING": {
      return {
        ...state,
      };
    }
    case "SIGNUP_REJECTED": {
      return {
        ...state,
        error: action.payload.response.data,
        messages: [],
        errors: [],
      };
    }

    // Sign Up Email
    case "SIGNUP_EMAIL_FULFILLED": {
      let newuser = JSON.parse(sessionStorage.getItem("newuser"));
      newuser.codeconfirm = action.payload.data.code;
      // console.log(newuser);
      sessionStorage.setItem("newuser", JSON.stringify(newuser));
      return {
        ...state,
        // messages: 'Sign Up Is Done.',
      };
    }
    case "SIGNUP_EMAIL_PENDING": {
      return {
        ...state,
      };
    }
    case "SIGNUP_EMAIL_REJECTED": {
      sessionStorage.removeItem("newuser");
      return {
        ...state,
        error: action.payload.response.data,
        messages: [],
        errors: [],
      };
    }

    // Save Password
    case "SAVEPASSWORD_FULFILLED": {
      return {
        ...state,
        // messages: 'Sign Up Is Done.',
      };
    }
    case "SAVEPASSWORD_PENDING": {
      return {
        ...state,
      };
    }
    case "SAVEPASSWORD_REJECTED": {
      return {
        ...state,
        error: action.payload.response.data,
        messages: [],
        errors: [],
      };
    }

    //  Logout
    case "LOGOUT": {
      // console.log(action.payload)
      localStorage.removeItem("mettaApiKey");
      localStorage.removeItem("mettaLoggedUser");
      localStorage.removeItem("provider");
      localStorage.removeItem("profilepic");
      localStorage.removeItem("provideridToken");
      localStorage.removeItem("providerToken");
      return {
        ...state,
        requesting: false,
        successful: false,
        messages: [
          {
            body: `${action.message}`,
          },
        ],
        errors: [],
      };
    }

    default:
      return state;
  }
};
