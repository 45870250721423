import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { List, ListItem } from "@material-ui/core";

import IntlMessages from "../../util/IntlMessages";

import { isAccessable } from "util/check-auth";

import NavMenuItem from "./NavMenuItem";
import NavCollapse from "./NavCollapse";

const NavSection = (props) => {
  const { loggedUser } = useSelector(({ auth }) => auth);
  const { accessFor, name, icon, children = [] } = props;
  const isExpandable = children && children.length > 0;

  const MenuCollapse = (
    <List component="div" className="nav-header">
      {/* Display an icon if any */}
      {!!icon && <i className={"zmdi zmdi-hc-fw  zmdi-" + icon} />}
      <IntlMessages id={name} />
    </List>
  );

  const MenuItemChildren = isExpandable ? (
    <List component="div" disablePadding>
      {children.map((item, index) => {
        switch (item.type) {
          case "section":
            return <NavSection {...item} key={index} />;
          case "collapse":
            return <NavCollapse {...item} key={index} />;
          case "item":
            return <NavMenuItem {...item} key={index} />;
        }
      })}
    </List>
  ) : null;
  if (accessFor && isAccessable(accessFor, loggedUser.roles)) {
    return (
      <div className="nav-section">
        {MenuCollapse}
        {MenuItemChildren}
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default NavSection;
