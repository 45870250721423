import {
  Container,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import React from "react";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { DateTimeInput } from "util/formInput";
// Utilities and helpers
import IntlMessages from "util/IntlMessages";

export default function VisitSubjectForm(props) {
  const { control, errors, register, setValue, getValues } = props;
  const { uiStaffs } = useSelector(({ uiConfigureStore }) => uiConfigureStore);
  const { departments, units } = useSelector(({ visitStore }) => visitStore);
  const values = getValues({ nest: true });
  const getVisitWithLabel = (opts, option) => {
    if (option && option.name) {
      return option.name;
    } else {
      let name = opts.find((o) => o.clientId === option);
      return name ? name.name : "";
    }
  };

  const getTryLabel = (opts, option) => {
    if (option && option.key) {
      return option.key;
    } else {
      let name = opts.find((o) => o.key === option);
      return name ? name.key : "";
    }
  };

  return (
    <div>
      <Container style={{ paddingBottom: 25 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <DateTimeInput
                value={values.startDateTime || moment().format()}
                label={<IntlMessages id="visit.form.checkinDate" />}
                name="startDateTime"
                type="datetime"
                inputRef={register}
                fullWidth
                autoOk
                disableFuture={false}
                error={errors.startDateTime}
                helperText={
                  errors.startDateTime && errors.startDateTime.message
                }
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {uiStaffs?.length > 0 && (
              <FormControl fullWidth margin="normal" error={errors.assignedId}>
                <Controller
                  as={
                    <Autocomplete
                      error={errors.assignedId}
                      options={uiStaffs}
                      getOptionLabel={(option) =>
                        getVisitWithLabel(uiStaffs, option)
                      }
                      renderOption={(option) => <span>{option.name}</span>}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={errors.assignedId && errors.assignedId.message}
                          label={<IntlMessages id="visit.form.visitWith" />}
                        />
                      )}
                    />
                  }
                  onChange={([, data]) => data}
                  name={`assignedId`}
                  control={control}
                  defaultValue={
                    values.assignedId &&
                    uiStaffs.find(
                      (uiUser) => uiUser.clientId === values.assignedId
                    )
                  }
                />
                <FormHelperText>
                  {errors.assignedId && errors.assignedId.message}
                </FormHelperText>
              </FormControl>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={1} direction="row">
          <Grid item xs={12}>
            <Controller
              as={TextField}
              label={<IntlMessages id="visit.form.for" />}
              name="description"
              control={control}
              fullWidth
              multiline
              rows="2"
              rowsMax="4"
              error={errors.description}
              helperText={errors.description && errors.description.message}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <FormControl fullWidth margin="normal" error={errors.department}>
              <Controller
                as={
                  <Autocomplete
                    error={errors.department}
                    options={departments}
                    getOptionLabel={(option) =>
                      getTryLabel(departments, option)
                    }
                    renderOption={(option) => <span>{option.value}</span>}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={errors.department && errors.department.message}
                        label={<IntlMessages id="visit.form.department" />}
                      />
                    )}
                  />
                }
                onChange={([, data]) => data}
                name={`department`}
                control={control}
                defaultValue={
                  departments &&
                  departments.find(
                    (department) => department.key === values.department
                  )
                }
              />
              <FormHelperText>
                {errors.department && errors.department.message}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth margin="normal" error={errors.unit}>
              {units && (
                <Controller
                  as={
                    <Autocomplete
                      error={errors.unit}
                      options={units}
                      getOptionLabel={(option) => getTryLabel(units, option)}
                      renderOption={(option) => <span>{option.value}</span>}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={errors.unit && errors.unit.message}
                          label={<IntlMessages id="visit.form.unit" />}
                        />
                      )}
                      defaultValue={units[0]}
                    />
                  }
                  onChange={([, data]) => data}
                  name={`unit`}
                  control={control}
                  defaultValue={units[0]}
                />
              )}
              <FormHelperText>
                {errors.unit && errors.unit.message}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <FormControl fullWidth margin="normal">
              <Controller
                as={TextField}
                label={<IntlMessages id="visit.form.room" />}
                name="room"
                control={control}
                fullWidth
                multiline
                error={errors.room}
                helperText={errors.room && errors.room.message}
              />
              <FormHelperText>
                {errors.room && errors.room.message}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
