const initialState = {
  loading: false,
  messages: [],
  errors: [],
  formerror: null,
  error: "",
  contactError: null,
  contacts: [],
  contact: {},
  contactdetailLoading: false,
  favcontacts: [],
  updateFormModal: false,
  updatePage: 1,
  addFormModal: false,
  viewModal: false,
  formRole: "PATIENT",

  clientTags: [],
  is_saving: false,
  docForm: false,
  documents: [],
  document: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "CONTACTFORM": {
      let formType = action.payload.formParam.formType;
      let formRole = action.payload.formParam.role;
      // console.log(action.payload);
      return {
        ...state,
        contact: formType === "update" ? action.payload.contact : null,
        addFormModal: formType === "add" ? true : false,
        updateFormModal: formType === "update" ? true : false,
        viewModal: action.payload.viewModal === "view" ? true : false,
        updatePage: action.payload.updatePage,
        formRole:
          formType === "update" ? action.payload.contact.role : formRole,
      };
    }

    case "CONTACTMODAL": {
      // console.log(action.payload)
      return {
        ...state,
        updateMedicalFor: null,
        updateMedicalFormView: false,
        updateFormModal: false,
        addFormModal: false,
        viewModal: false,
      };
    }
    // Client Tags
    case "CLIENTTAGS_FULFILLED": {
      // console.log(action.payload)
      return {
        ...state,
        clientTags: action.payload.data,
      };
    }
    case "CLIENTTAGS_PENDING": {
      return {
        ...state,
      };
    }
    case "CLIENTTAGS_REJECTED": {
      // console.log(action)
      return {
        ...state,
        clientTags: [],
        error: action?.payload?.response,
        messages: [],
        errors: [],
      };
    }
    // Client Search
    case "CLIENTSEARCH_FULFILLED": {
      return {
        ...state,
        contacts: action.payload.data,
      };
    }
    case "CLIENTSEARCH_PENDING": {
      return {
        ...state,
      };
    }
    case "CLIENTSEARCH_REJECTED": {
      // console.log(action)
      return {
        ...state,
        error: action?.payload?.response,
        messages: [],
        errors: [],
      };
    }

    // Save Contacts
    case "CLIENTADD_FULFILLED": {
      // console.log(action.payload)
      return {
        ...state,
        contacts: [action.payload.data, ...state.contacts],
        contact: action.payload.data,
        updateFormModal: false,
        addFormModal: false,
        viewModal: false,
        formerror: null,
        is_saving: false,
      };
    }
    case "CLIENTADD_PENDING": {
      return {
        ...state,
        is_saving: true,
      };
    }
    case "CLIENTADD_REJECTED": {
      return {
        ...state,
        formerror: action?.payload?.response?.data,
        is_saving: false,
        messages: [],
        errors: [],
      };
    }

    // get Contact detail by Id
    case "CLIENTBYID_FULFILLED": {
      const contact = action.payload.data;
      return {
        ...state,
        contact: contact,
        contactdetailLoading: false,
        addFormModal: false,
        viewModal: false,
        contactError: null,
      };
    }
    case "CLIENTBYID_PENDING": {
      return {
        ...state,
        contact: {},
        contactdetailLoading: true,
        contactError: null,
      };
    }
    case "CLIENTBYID_REJECTED": {
      return {
        ...state,
        error: action?.payload?.response?.data,
        contact: {},
        messages: [],
        errors: [],
        contactdetailLoading: false,
        contactError: action?.payload?.response?.data.message,
      };
    }

    // Update Contacts
    case "CLIENTUPDATE_FULFILLED": {
      const contact = action.payload.data;
      // console.log(state)
      // console.log(contact);

      return {
        ...state,
        contacts: state.contacts.map((item) =>
          item.id === contact.id ? contact : item
        ),
        contact: contact,
        updateFormModal: false,
        addFormModal: false,
        viewModal: false,
        formerror: null,
        is_saving: false,
      };
    }
    case "CLIENTUPDATE_PENDING": {
      return {
        ...state,
        is_saving: true,
      };
    }
    case "CLIENTUPDATE_REJECTED": {
      return {
        ...state,
        formerror: action?.payload?.response?.data,
        messages: [],
        errors: [],
        is_saving: false,
      };
    }

    // on Client Delete
    case "CLIENT_DELETE_FULFILLED": {
      let contact = action.payload.data;
      // contact.status = "DELETED";
      // console.log(contact);
      return {
        ...state,
        contacts: state.contacts.map((item) =>
          item.id === contact.id ? contact : item
        ),
      };
    }

    // on multiple client Delete by ids
    // case 'CLIENT_DELETE_FULFILLED': {
    case "CLIENTS_DELETEBUIDS": {
      // const _ids = action.payload;
      // const _id = action.payload.data.data;
      return {
        ...state,
        // contacts: state.contacts.filter(item => item.id !== _id),
        contact: {},
      };
    }

    // Add Clients to Favourite
    case "ADD_FAVCLIENT": {
      return {
        ...state,
        // favcontacts: [...state.favcontacts, action.payload.data.data],
        favcontacts: [...state.favcontacts, action.payload],
        contact: null,
        message: "Contact added to Favourite",
      };
    }
    // Remove Clients to Favourite
    case "REM_FAVCLIENT": {
      const _id = action.payload.id;
      // const _id = action.payload.data.data;
      return {
        ...state,
        // favcontacts: [...state.favcontacts, action.payload.data.data],
        // favcontacts: [...state.favcontacts, action.payload],
        favcontacts: state.favcontacts.filter((item) => item.id !== _id),
        contact: null,
        message: "Contact Removed from Favourite",
      };
    }

    // get documents list by Patient Id
    case "GET_DOCSBYPATID_FULFILLED": {
      const documents = action.payload.data;
      return {
        ...state,
        documents: documents,
        documentsLoading: false,
      };
    }
    case "GET_DOCSBYPATID_PENDING": {
      return {
        ...state,
        documents: [],
        documentsLoading: true,
      };
    }
    case "GET_DOCSBYPATID_REJECTED": {
      return {
        ...state,
        error: action?.payload?.response?.data,
        documents: [],
        message: "",
        errors: [],
        documentsLoading: false,
      };
    }

    // get document by  Id
    case "GET_DOCSBYID_FULFILLED": {
      const document = action.payload.data;
      // console.log(document)
      return {
        ...state,
        // documents: state.documents.map(item => item.id === document.id ? document : item),
        document: document,
        documentsLoading: false,
      };
    }
    case "GET_DOCSBYID_PENDING": {
      return {
        ...state,
        document: null,
        documentsLoading: true,
      };
    }
    case "GET_DOCSBYID_REJECTED": {
      return {
        ...state,
        error: action?.payload?.response?.data,
        document: null,
        message: "",
        errors: [],
        documentsLoading: false,
      };
    }

    // OnAdd DocumentModal
    // ContactActions
    case "DOCFORM": {
      return {
        ...state,
        docForm: action.payload.view,
        formerror: null,
      };
    }

    // save patient Documents
    // ContactActions
    case "SAVE_DOCUMENT_FULFILLED": {
      return {
        ...state,
        documents: [...state.documents, action.payload.data],
        documentsLoading: false,
        formerror: null,
        docForm: false,
      };
    }
    case "SAVE_DOCUMENT_PENDING": {
      return {
        ...state,
        documentsLoading: true,
      };
    }
    case "SAVE_DOCUMENT_REJECTED": {
      return {
        ...state,
        formerror: action?.payload?.response?.data,
        documentsLoading: false,
        messages: [],
        errors: [],
      };
    }

    // Update patient Documents
    // ContactActions
    case "UPDATE_DOCUMENT_FULFILLED": {
      var document = action.payload.data;
      return {
        ...state,
        documents: state.documents.map((item) =>
          item.id === document.id ? document : item
        ),
        documentsLoading: false,
        formerror: null,
        docForm: false,
      };
    }
    case "UPDATE_DOCUMENT_PENDING": {
      return {
        ...state,
        documentsLoading: true,
      };
    }
    case "UPDATE_DOCUMENT_REJECTED": {
      return {
        ...state,
        formerror: action?.payload?.response?.data,
        documentsLoading: false,
        messages: [],
        errors: [],
      };
    }

    case "RESET_CONTACTSTORE": {
      return initialState;
    }
    default:
      return state;
  }
};
