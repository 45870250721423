import React, { useEffect, useState } from "react";
import { useFieldArray, Controller } from "react-hook-form";

import {
  Grid,
  TextField,
  Slider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment,
  Divider,
  Container,
  Avatar,
  CardHeader,
  IconButton,
  Tooltip,
  Button,
} from "@material-ui/core";

import { DateTimeInput } from "util/formInput";
import { Refresh, Add as AddIcon, Clear, Close } from "@material-ui/icons";

import IntlMessages from "util/IntlMessages";

export default function MedicationScheduleForm(props) {
  const { control, errors, accConfig, register, getValues, watch } = props;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "medicationSchedules",
    keyName: "keyId", //, default to "id", you can change the key name
  });
  //   const wrecord = watch("medicationSchedules");
  const values = getValues({ nest: true });
  const onAdd = () => {
    append({
      time: null,
      medication: "Cetamol",
      note: "1",
    });
  };

  const onRemove = (index) => {
    remove(index);
  };

  useEffect(() => {
    if (fields.length === 0) {
      onAdd();
    }
  }, []);
  const onReset = (index) => {
    remove(index);
    onAdd();
    // console.log('reset');
  };
  return (
    <div>
      {fields.map((item, index) => {
        return (
          <div key={item.keyId}>
            <Container style={{ paddingBottom: 25 }}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <FormControl fullWidth margin="normal">
                    <DateTimeInput
                      value={item.time}
                      label="Time"
                      name={`medicationSchedules[${index}].time`}
                      inputRef={register}
                      fullWidth
                      type="time"
                      ampm={false}
                      autoOk
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={8} sm={8}>
                  <FormControl fullWidth margin="normal">
                    <Controller
                      label={<IntlMessages id="dailylog.form.medication" />}
                      as={TextField}
                      name={`medicationSchedules[${index}].medication`}
                      control={control}
                      multiline
                      fullWidth
                      error={
                        errors.medicationSchedules &&
                        errors.medicationSchedules[index] &&
                        errors.medicationSchedules[index].medication
                      }
                      helperText={
                        errors.medicationSchedules &&
                        errors.medicationSchedules[index] &&
                        errors.medicationSchedules[index].medication &&
                        errors.medicationSchedules[index].medication.message
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={11} sm={11}>
                  <FormControl fullWidth margin="normal">
                    <Controller
                      label={<IntlMessages id="dailylog.form.note" />}
                      as={TextField}
                      name={`medicationSchedules[${index}].note`}
                      control={control}
                      multiline
                      fullWidth
                      error={
                        errors.medicationSchedules &&
                        errors.medicationSchedules[index] &&
                        errors.medicationSchedules[index].note
                      }
                      helperText={
                        errors.medicationSchedules &&
                        errors.medicationSchedules[index] &&
                        errors.medicationSchedules[index].note &&
                        errors.medicationSchedules[index].note.message
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={1} sm={1}>
                  <Container>
                    <Button
                      color="secondary"
                      aria-label="Remove"
                      onClick={() => onRemove(index)}
                      size="small"
                    >
                      <Tooltip
                        title={"Remove Observation Notes  " + (index + 1)}
                        size="large"
                      >
                        <Clear />
                      </Tooltip>
                    </Button>
                  </Container>
                </Grid>
              </Grid>
            </Container>

            {index + 1 !== fields.length && <Divider />}
          </div>
        );
      })}

      <Tooltip title="Click to Add Observation Detail">
        <Button
          aria-label="Add"
          onClick={() => onAdd()}
          variant="contained"
          color="primary"
        >
          Add More <AddIcon />
        </Button>
      </Tooltip>
    </div>
  );
}
