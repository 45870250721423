import React from "react";
import NoteListItem from "./NoteListItem";
import CustomScrollbars from "util/CustomScrollbars";

const NoteList = ({ notes, onNoteSelect, width }) => {
  // console.log(notes);
  return (
    <div className="module-list mail-list">
      <CustomScrollbars
        className="module-list-scroll scrollbar"
        style={{
          height: width >= 1200 ? "calc(100vh - 259px)" : "calc(100vh - 238px)",
        }}
      >
        {notes &&
          notes.map((note, index) => (
            <NoteListItem
              key={note.id}
              note={note}
              onNoteSelect={onNoteSelect}
            />
          ))}
      </CustomScrollbars>
    </div>
  );
};

export default NoteList;
