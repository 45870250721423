import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from "react-hook-form";

import {
  Grid,
  TextField,
  InputAdornment,
  Container,
  InputLabel,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { AccountCircle } from "@material-ui/icons";

import { TagInput, EditorInput } from "util/formInput";
// Utilities and helpers
import IntlMessages from "util/IntlMessages";

export default function NoteSubjectForm(props) {
  const {
    control,
    errors,
    register,
    formRole,
    setValue,
    getValues,
    updateFormInbox,
    note,
  } = props;
  const { noteTags } = useSelector(({ inboxStore }) => inboxStore);

  const values = getValues({ nest: true });

  const [content, setContent] = useState(values.note);

  useEffect(() => {
    content && setValue("note", content, { shouldValidate: true });
  }, [content]);

  return (
    <div>
      <Container style={{ paddingBottom: 25 }}>
        <Grid container spacing={1} direction="row">
          <Grid item xs={12}>
            <Controller
              as={TextField}
              label={<IntlMessages id="inbox.form.subject" />}
              name="subject"
              control={control}
              fullWidth
              error={errors.subject}
              helperText={errors.subject && errors.subject.message}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: "20px" }}>
            <InputLabel htmlFor="group" error={errors.note}>
              <IntlMessages id="inbox.form.note" />
            </InputLabel>
            <input
              ref={register}
              name="note"
              type="text"
              hidden
              defaultValue={values.note}
            />
            <EditorInput
              content={content}
              setContent={setContent}
              errorsmsg={errors.note}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: "20px" }}>
            <InputLabel htmlFor="group">
              <IntlMessages id="inbox.form.tags" />
            </InputLabel>
            <Controller
              as={
                <Autocomplete
                  multiple
                  freeSolo
                  fullWidth
                  options={noteTags}
                  autoHighlight
                  getOptionLabel={(option) => option}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      {/* <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        /> */}
                      {option}
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // label="Choose a Users"
                      fullWidth
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "disabled", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              }
              onChange={([event, data]) => {
                return data;
              }}
              name={`tags`}
              control={control}
              defaultValue={values.tags}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
