// Vendors Components
import { CardHeader, IconButton, Tooltip } from "@material-ui/core";
import { Edit as EditIcon } from "@material-ui/icons";
import AlertBox from "components/AlertBox/index";
import Widget from "components/Widget/index";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { onAddObservationForm } from "./actions";

function Observation() {
  const dispatch = useDispatch();
  const { visit } = useSelector(({ visitStore }) => visitStore);
  const onAddHistory = () => {
    dispatch(onAddObservationForm("update", "observation"));
    // dispatch(onContactForm(contact, 'update'));
  };
  return (
    <>
      <Widget styleName="jr-card-full jr-card-tabs-right jr-card-profile">
        <CardHeader
          action={
            visit.id && (
              <Tooltip title="Update About Info ">
                <IconButton
                  aria-label="Add"
                  onClick={() => onAddHistory()}
                  variant="contained"
                  color="primary"
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )
          }
          title={"Observations"}
        />
        <div className="jr-tabs-content jr-task-list">
          {!visit.observations && (
            <AlertBox
              infotype="danger"
              message="Add observation"
              onClick={onAddHistory}
            />
          )}

          <div className="row">{visit.observations}</div>
        </div>
      </Widget>
    </>
  );
}
export default Observation;
