import React from "react";

import { Link, withRouter } from "react-router-dom";
import IntlMessages from "util/IntlMessages";

const Footer = () => {
  const d = new Date();
  return (
    <footer className="app-footer">
      <span className="d-inline-block">AmatyaNet &copy; {d.getFullYear()}</span>
      <Link className="app-logo mr-2 d-none d-sm-block" to="/">
        <img
          src={require("assets/images/logo-centered.png")}
          alt="Metta"
          title="Metta"
        />
      </Link>
    </footer>
  );
};

export default withRouter(Footer);
