import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { Chip, Tooltip } from "@material-ui/core";
import {
  CrudNotification,
  displayDate,
  getColor,
  getValuefromLookup,
} from "util/helpers";

import { onTaskForm, onTaskDelete } from "../../actions";
const TaskListItem = ({ task, onTaskSelect }) => {
  // console.log(task);
  const dispatch = useDispatch();
  const { accConfig } = useSelector(({ uiConfigureStore }) => uiConfigureStore);

  const { loggedUser } = useSelector(({ auth }) => auth);

  const [taskContent, setTaskContent] = useState("");
  // on Update
  const onUpdateTask = (task) => {
    dispatch(onTaskForm(task, "update"));
  };
  useEffect(() => {
    let str = task.description ? task.description : "";
    str = str.replace(/<\/?[^>]+(>|$)/g, "");
    // replace(/(<([^>]+)>)/gi, "");
    if (str.length > 300) {
      str = str.slice(0, 300) + "<strong> more >></strong>";
    }
    setTaskContent(str);
  }, []);

  // on onDeleteTask
  const onDeleteTask = (task) => {
    task.status = task.status === "ACTIVE" ? "DELETED" : "ACTIVE";
    return dispatch(onTaskDelete(task)).then((res) => {
      CrudNotification(
        "success",
        `Task ${task.status === "ACTIVE" ? "Active" : "Deleted"} Successfully !`
      );
    });
  };

  let startD = displayDate(task.startDateTime, "datetimef").split(",");
  let startE = displayDate(task.endDateTime, "datetimef").split(",");

  const setIcons = () => {
    let icon = "check";
    icon =
      task.status === "COMPLETED"
        ? "check"
        : task.status === "ACTIVE"
        ? "dot-circle"
        : task.status === "CANCELED"
        ? "close"
        : task.status === "DELETED"
        ? "delete"
        : "circle";
    return icon;
  };

  return (
    <div className="tracking-item">
      <div className={`tracking-icon status-${task.status.toLowerCase()}`}>
        <i className={`zmdi zmdi-${setIcons()}`} />
      </div>
      <div className="tracking-date">
        <Tooltip title={"Start Date"} aria-label="Start Date">
          <span>
            {startD[0] + startD[1]}
            <br />
            {startD[2]}
          </span>
        </Tooltip>
      </div>
      <div className="tracking-content" onClick={() => onTaskSelect(task)}>
        <div>{task.title}</div>
        <div dangerouslySetInnerHTML={{ __html: taskContent }}></div>
        <span>
          {task.aboutName && `${task.aboutName} / with `}
          {task.assignedName && `${task.assignedName} `}

          <Chip
            variant="outlined"
            color="primary"
            size="small"
            label={getValuefromLookup(accConfig.taskCategory, task.category)}
          />
        </span>
      </div>
      <div></div>
    </div>
  );
};

export default TaskListItem;
