import { Grid, TextField } from "@material-ui/core";
import React, { useState } from "react";
const VisitHeader = ({ visits, setAllVisits }) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={8} sm={8}>
          <TextField
            id="searchVisit"
            label="Search Visit"
            onChange={(e) => {
              const listOfVisits = visits?.filter((ele) => {
                return (
                  ele?.assignedName
                    ?.toLowerCase()
                    .includes(e.target.value?.toLowerCase()) ||
                  ele?.description
                    ?.toLowerCase()
                    .includes(e.target.value?.toLowerCase())
                );
              });
              setAllVisits(listOfVisits);
            }}
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
};

export default VisitHeader;
