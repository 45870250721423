import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IntlMessages from "util/IntlMessages";

import { logOut } from "services/actions";
import { getColor } from "util/helpers";

class UserInfo extends React.Component {
  state = {
    anchorEl: null,
    open: false,
  };

  handleClick = (event) => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };
  handlelogout = () => {
    this.props.logOut();
    this.setState({ open: false });
    this.props.history.push("/signin");
  };

  //  go to user Profile
  goToUserProfilePage = () => {
    this.setState({ open: false });
    this.props.history.push("/app/account");
  };
  //  goto account setting
  goToUserAccountPage = () => {
    this.setState({ open: false });
    this.props.history.push("/app/account/default_setting");
  };

  render() {
    const { loggedUser, profilePic } = this.props;
    // console.log(loggedUser);
    return (
      <div className="user-profile d-flex flex-row align-items-center">
        {loggedUser.name && profilePic && (
          <Avatar
            className="user-avatar "
            alt={loggedUser.name}
            src={profilePic}
          />
        )}
        {loggedUser.name && !profilePic && (
          <Avatar
            className="user-avatar "
            style={{ fontSize: 16, backgroundColor: getColor(loggedUser.name) }}
          >
            {loggedUser.name.charAt(0).toUpperCase()}
          </Avatar>
        )}

        <div className="user-detail">
          <h4 className="user-name" onClick={this.handleClick}>
            {" "}
            {loggedUser.name}
            <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
          </h4>
        </div>
        <Menu
          className="user-info"
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={this.handleRequestClose}
          PaperProps={{
            style: {
              minWidth: 120,
              paddingTop: 0,
              paddingBottom: 0,
            },
          }}
        >
          <MenuItem onClick={this.goToUserProfilePage}>
            <i className="zmdi zmdi-account zmdi-hc-fw mr-2" />
            <IntlMessages id="popup.profile" />
          </MenuItem>
          {/* <MenuItem onClick={this.goToUserAccountPage}>
            <i className="zmdi zmdi-settings zmdi-hc-fw mr-2" />
            <IntlMessages id="popup.setting" />
          </MenuItem> */}
          <MenuItem onClick={this.handlelogout}>
            <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />
            <IntlMessages id="popup.logout" />
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = ({ uiConfigureStore, auth }) => {
  const { locale } = uiConfigureStore;
  const { loggedUser, profilePic } = auth;
  return { locale, loggedUser, profilePic };
};
export default connect(mapStateToProps, { logOut })(withRouter(UserInfo));
