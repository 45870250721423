import { IconButton, Menu, MenuItem } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Widget from "components/Widget/index";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CrudNotification, displayDate, getColor } from "util/helpers";
import {
  onVisitClose,
  onVisitDelete,
  onVisitForm,
  setVisitOpen,
} from "../../actions";
const VisitDetailCard = (props) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(undefined);
  // const [open, setOpen] = useState(false);

  const { uiUsers, accConfig } = useSelector(
    ({ uiConfigureStore }) => uiConfigureStore
  );
  const { visit_open } = useSelector(({ visitStore }) => visitStore);
  const { visit, width } = props;
  const { category, title, visitId } = visit;
  const setOpen = (val) => {
    dispatch(setVisitOpen(val));
  };
  const handleClick = (event) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleRequestClose = () => {
    setOpen(false);
  };

  // onCloseVisitView Modal
  const onClose = () => {
    dispatch(onVisitClose());
  };
  // on Update
  const onMenuSelect = (option) => {
    option === "Update" && dispatch(onVisitForm(visit?.visitId, "update"));
    if (option === "Delete") {
      visit.status = visit.status === "ACTIVE" ? "DELETED" : "ACTIVE";
      return dispatch(onVisitDelete(visit)).then((res) => {
        CrudNotification(
          "success",
          `Visit ${
            visit.status === "ACTIVE" ? "Active" : "Deleted"
          } Successfully !`
        );
      });
    }
  };

  const options = ["Update", "Delete"];

  return (
    <Widget styleName="jr-card-full jr-card-tabs-right jr-card-profile">
      <div className="module-detail ">
        <div className="mail-header">
          <div style={{ paddingRight: "15px" }}>
            <IconButton className="icon-btn" onClick={() => onClose()}>
              <i className="zmdi zmdi-arrow-back" />
            </IconButton>
          </div>
          <div className="mail-header-content col pl-0">
            <span
              style={{
                fontSize: "1.5rem",
                fontWeight: "Bold",
                marginTop: "1rem",
              }}
            >
              Visit #{visitId}
            </span>
            {/* <div className="subject">
              Visit ID
              {visitId}
            </div> */}
            <div className="subject">{title}</div>
            <div className="labels">
              <div
                className={`badge text-white `}
                style={{
                  backgroundColor: category ? getColor(category) : "#fff",
                }}
              >
                {category}
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div style={{ padding: "16px" }}>
          <div className="mail-user-info">
            <div className=" ml-4">
              <div>
                <strong>Checkin @ </strong>
                {displayDate(visit.startDateTime, "datetime")}
              </div>
              <div
                className="send-from text-grey"
                style={{ fontWeight: "bold" }}
              >
                {visit.patientName && `${visit.patientName}/ with `}
                {visit.assignedName && `${visit.assignedName} `}
              </div>
              <div className="mt-2 ">
                {/* <strong>Description: </strong> */}
                <div
                  className="message"
                  dangerouslySetInnerHTML={{ __html: visit.description }}
                ></div>
              </div>
            </div>

            {/* {visit.createdBy && (
              <div
                className="avatar rounded-circle size-40 text-white text-center"
                style={{
                  fontSize: 16,
                  backgroundColor: getColor(visit.createdBy),
                }}
              >
                {visit.createdBy.charAt(0).toUpperCase()}
              </div>
            )} */}

            {/* <div className="sender-name">
              <>
                Visit ID:
                {visitId}
                <br />
              </>
              {visit.createdBy}
             
            </div> */}

            <IconButton
              aria-label="More"
              aria-owns={visit_open ? "long-SidenavContent.js" : null}
              aria-haspopup
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>

            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              open={visit_open}
              onClose={() => handleRequestClose()}
              MenuListProps={{
                style: {
                  width: 200,
                },
              }}
            >
              {options.map((option) => (
                <MenuItem
                  key={option}
                  onClose={() => handleRequestClose()}
                  onClick={() => onMenuSelect(option)}
                >
                  {option}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </div>
      </div>
    </Widget>
  );
};

export default VisitDetailCard;
