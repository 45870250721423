// get medicalRecord list by Patient Id

import { api } from "util/api";

// medRecordStore
export function getMedicalDetailByPId(Pid) {
  return (dispatch) => {
    return dispatch({
      type: "GET_MEDREC_BYPATID",
      payload: api.get("ehr/medical-record/patient-id/" + Pid),
    });
  };
}

// toggle patient medical details modal
// MedRecordStore
export function onAddMedicationForm(formtype = "add", formFor = null) {
  return (dispatch) => {
    return dispatch({
      type: "MEDICATIONFORM",
      payload: { formtype: formtype, formFor: formFor },
    });
  };
}

// toggle patient medical details modal
// MedRecordStore
export function onMedicFormClose() {
  return (dispatch) => {
    return dispatch({
      type: "MEDICFORMCLOSE",
    });
  };
}

// On medical record Save
export function onMedRecordSave(value) {
  return (dispatch) => {
    return dispatch({
      type: "MEDICRECORD_SAVE",
      payload: api.post("ehr/medical-record", value),
    });
  };
}
// get investigation list by Patient Id
// medRecordStore
export function getTestDetailByPId(Pid, filter = null) {
  console.log("============", Pid);
  let filterurl = filter ? "/items?filter=" + filter : "/items";
  return (dispatch) => {
    return dispatch({
      type: "GET_TESTREC_BYPATID",
      payload: api.get("ehr/investigation/patient-id/" + Pid + filterurl),
    });
  };
}

// toggle patient Test details modal
// MedRecordStore
export function onTestFormModal(formtype = "add", formFor = null) {
  return (dispatch) => {
    return dispatch({
      type: "TESTFORM",
      payload: { formtype: formtype, formFor: formFor },
    });
  };
}

// On Test Investigation  record Save
export function onTestRecordSave(value) {
  console.log("onTestRecordSave", value);
  return (dispatch) => {
    return dispatch({
      type: "TESTRECORD_SAVE",
      payload: api.post("ehr/investigation", value),
    });
  };
}

// set Investigation Test
// MedRecordStore
export function onSetTest(val = null) {
  return (dispatch) => {
    return dispatch({
      type: "SET_TEST",
      payload: val,
    });
  };
}
// toggle medical Record Graph
// MedRecordStore
export function onToggleGraphView(graphFor = null) {
  return (dispatch) => {
    return dispatch({
      type: "TOGGLE_GRAPH",
      payload: { graphFor: graphFor },
    });
  };
}
//
//
//  Inpatient Store
//
//
//
//
// toggle patient Inpatient details modal
// MedRecordStore
export function onInPatientForm(formtype = "add", formFor = "admission") {
  return (dispatch) => {
    return dispatch({
      type: "INPATIENTFORM",
      payload: { formtype: formtype, formFor: formFor },
    });
  };
}
// On Inpatient detail Save
// MedRecordStore
export function onInpatientSave(value) {
  return (dispatch) => {
    return dispatch({
      type: "INPATIENT_SAVE",
      payload: api.post("ehr/inpatient-visit", value),
    });
  };
}

// get Inpatient list by Patient Id
// MedRecordStore
export function getInPatientListByPId(Pid) {
  return (dispatch) => {
    return dispatch({
      type: "GET_INPATIENTLIST_BYPATID",
      payload: api.get("ehr/inpatient-visit/patient-id/" + Pid),
    });
  };
}

// get Inpatient Detail by  Id
// MedRecordStore
export function getInPatientDetailById(id) {
  return (dispatch) => {
    return dispatch({
      type: "GET_INPATIENTDETAIL_BYID",
      payload: api.get("ehr/inpatient-visit/inpatient-id/" + id),
    });
  };
}

//
//
//  Daily Log
//
//
//
//
// toggle patient daily Log form modal
// MedRecordStore
export function onDailylogForm(formtype = "add", formFor = "dailylog") {
  return (dispatch) => {
    return dispatch({
      type: "DAILYLOGFORM",
      payload: { formtype: formtype, formFor: formFor },
    });
  };
}
// get dailylog Nursing Station  Detail
// medRecordStore
export function getDailyLog(date, id) {
  return (dispatch) => {
    return dispatch({
      type: "DAILYLOG_GET",
      payload: api.get("ehr/daily-log/patient-id/" + id + "/date/" + date),
    });
  };
}
// get recent 10  dailylogs Nursing Station  Detail
// medRecordStore
export function getRecentDailyLogs(id) {
  return (dispatch) => {
    return dispatch({
      type: "GET_DAILYLOGS",
      payload: api.get("ehr/daily-log/patient-id/" + id),
    });
  };
}
// set daily log from list
export function setDailyLog(val) {
  return (dispatch) => {
    return dispatch({
      type: "SET_DAILYLOG",
      payload: val,
    });
  };
}

// OnAdd Nursing Station Detail
// medRecordStore
export function onSaveDailyLog(value) {
  return (dispatch) => {
    return dispatch({
      type: "DAILYLOG_SAVE",
      payload: api.post("ehr/daily-log", value),
    });
  };
}

//
//
//  Patient EWS
//
//
//
//
// toggle patient EWS form modal
// MedRecordStore
export function onEwsForm(formtype = "add", formFor = "vitals") {
  return (dispatch) => {
    return dispatch({
      type: "EWSFORM",
      payload: { formtype: formtype, formFor: formFor },
    });
  };
}
// medRecordStore
export function onPatientEwsSave(value) {
  return (dispatch) => {
    return dispatch({
      type: "EWS_SAVE",
      payload: api.post("ehr/ews", value),
    });
  };
}
// override ews FReq
// medRecordStore
export function onEWSfreqOverride(value) {
  return (dispatch) => {
    return dispatch({
      type: "EWS_OVERRIDE",
      payload: api.post("ehr/ews", value),
    });
  };
}

// save patient vital details
// medRecordStore
export function onPatientEwsUpdate(value) {
  return (dispatch) => {
    return dispatch({
      type: "EWS_UPDATE",
      payload: api.post("ehr/ews", value),
    });
  };
}

// get patients' ews vital detail
// medRecordStore
export function getEwsByPatientId(id) {
  return (dispatch) => {
    return dispatch({
      type: "EWSBY_PID",
      payload: api.get("ehr/ews/patient-id/" + id),
    });
  };
}
// get ews by id
// contact store
export function getEwsDetailById(id) {
  return (dispatch) => {
    return dispatch({
      type: "EWSDETAILBY_ID",
      payload: api.get("ehr/ews/id/" + id),
    });
  };
}
