import {
  CHANGE_DIRECTION,
  CHANGE_NAVIGATION_STYLE,
  DARK_THEME,
  DRAWER_TYPE,
  HORIZONTAL_MENU_POSITION,
  SWITCH_LANGUAGE,
  THEME_COLOR,
  TOGGLE_COLLAPSED_NAV,
  WINDOW_WIDTH,
} from "constants/ActionTypes";

import { api } from "util/api";

export function toggleCollapsedNav(isNavCollapsed) {
  return { type: TOGGLE_COLLAPSED_NAV, isNavCollapsed };
}

export function setDrawerType(drawerType) {
  return { type: DRAWER_TYPE, drawerType };
}

export function updateWindowWidth(width) {
  return { type: WINDOW_WIDTH, width };
}

export function setThemeColor(color) {
  return { type: THEME_COLOR, color };
}

export function setDarkTheme() {
  return { type: DARK_THEME };
}

export function changeDirection() {
  return { type: CHANGE_DIRECTION };
}

export function changeNavigationStyle(layoutType) {
  return {
    type: CHANGE_NAVIGATION_STYLE,
    payload: layoutType,
  };
}

export function setHorizontalMenuPosition(navigationPosition) {
  return {
    type: HORIZONTAL_MENU_POSITION,
    payload: navigationPosition,
  };
}

export function switchLanguage(locale) {
  return {
    type: SWITCH_LANGUAGE,
    payload: locale,
  };
}

// get app config datas
export function isApiLoading(val) {
  return (dispatch) => {
    return dispatch({
      type: "APILOADING",
      payload: val,
    });
  };
}
// get default App Ui Configuration
export function getAppUiConfig() {
  return (dispatch) => {
    return dispatch({
      type: "GET_APPUI_CONFIG",
      payload: api.get("/account/config"),
    });
  };
}
// get default Account Units
export function getDefaultAccUnits() {
  return (dispatch) => {
    return dispatch({
      type: "GET_DEFAULT_UNITS",
      payload: api.get("/account/units"),
    });
  };
}

// get app config datas
export function getDistricts() {
  return (dispatch) => {
    return dispatch({
      type: "GET_DISTRICT",
      payload: api.get("account/lookups/districts"),
    });
  };
}

// Get Contacts for forms
// Setting Store
export function getContactsByVal(searchVal) {
  return (dispatch) => {
    return dispatch({
      type: "GET_CONTACTSBYVAL",
      payload: api.get("client?query=" + searchVal),
    });
  };
}

export function getAllConfigData1() {
  return (dispatch) => {
    return dispatch({
      type: "ALLCONFIGDATA",
      payload: api.get("account/lookups/keys"),
    }).then((response) => {
      // console.log(response.value.data);
      let datas = response.value.data;
      Object.entries(datas).map(([key, value]) => {
        value.map((val) => {
          dispatch(getConfigData(val));
        });
      });
    });
  };
}

// export function getAllConfigData1() {
//   return (dispatch) => {
//     return dispatch({
//       type: "ALLCONFIGDATA",
//       payload: api.get("account/lookups?filter=tables"),
//     }).then((response) => {
//       // console.log(response.value.data);
//       let datas = response.value.data;
//       Object.entries(datas).map(([key, value]) => {
//         value.map((val) => {
//           dispatch(getConfigData(val));
//         });
//       });
//     });
//   };
// }

// get app config datas
export function getGlobalCountries() {
  return (dispatch) => {
    return dispatch({
      type: "GET_COUNTRIES",
      payload: api.get("/global/countries"),
    });
  };
}
// get app config datas
export function getConfigData(val = "clienttype") {
  // console.log(val);
  return (dispatch) => {
    return dispatch({
      type: "ACC_LOOKUP",
      payload: api.get("account/lookups?filter=" + val),
    });
  };
}

// get app config datas
export function getAllConfigData() {
  return (dispatch) => {
    api.get("account/lookups?filter=tables").then((response) => {
      let datas = response.data;
      Object.entries(datas).map(([key, value]) => {
        value.map((val) => {
          dispatch(getConfigData(val));
        });
      });
    });
  };
}

// Trial modal warning
export function onTogRestrictWarning(tog = true, formfor = "", message = null) {
  return (dispatch) => {
    return dispatch({
      type: "TOGTRIAL_WARNING",
      payload: { tog: tog, formfor: formfor, message: message },
    });
  };
}

// Get All Users for dropdowns
export function getActiveUsers() {
  return (dispatch) => {
    return dispatch({
      type: "GET_ACTIVEUSERS",
      payload: api.get("user/all"),
    });
  };
}

// Get All Templates for dropdowns
export function getActiveTemplates() {
  return (dispatch) => {
    return dispatch({
      type: "GET_ACTIVETEMPLATES",
      payload: api.get("message/template/all"),
    });
  };
}

// Get All Contacts for dropdowns
export function getActiveContacts(filterVal = "roles", searchVal = "client") {
  let query = "?" + filterVal + "=" + searchVal;
  return (dispatch) => {
    return dispatch({
      type: "GET_ACTIVECONTACTS",
      payload: api.get("client/all" + query),
    });
  };
}

// Get All Staffs for dropdowns
export function getActiveStaffs(filterVal = "roles", searchVal = "staff") {
  let query = "?" + filterVal + "=" + searchVal;
  return (dispatch) => {
    return dispatch({
      type: "GET_ACTIVESTAFFS",
      payload: api.get("client/all" + query),
    });
  };
}
