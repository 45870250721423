import React, { useEffect, useState } from "react";
import { Document, Page, Text, View, Font } from "@react-pdf/renderer";

import ReportSign from "./PdfComponents/ReportSign";
import PdfHeader from "./PdfComponents/PdfHeader";
import PatientDetail from "./PdfComponents/PatientDetail";
import InvestigationDetail from "./PdfComponents/InvestigationDetail";
import ClinicalReport from "./PdfComponents/ClinicalReport";
import PageFooter from "./PdfComponents/PageFooter";

import styles from "./pdfStyle";

// Discharfe Doc Pdf
export default function DischargeDoc(props) {
  const { contact, inPatient } = props;
  return (
    <Document>
      <Page style={styles.body} wrap>
        <Text ></Text>
        <PdfHeader {...props} styles={styles} />
     
        <View style={styles.summary}>
          <Text style={styles.summaryText}> Inpatient Discharge Summary</Text>
        </View>
        <PatientDetail {...props} styles={styles} />

        <InvestigationDetail {...props} styles={styles} />
        <ClinicalReport {...props} styles={styles} />
        <ReportSign {...props} styles={styles} />
        <PageFooter {...props} styles={styles} />
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
}
