import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { List, ListItem } from "@material-ui/core";
import { NavLink } from "react-router-dom";

import IntlMessages from "../../util/IntlMessages";

import { isAccessable } from "util/check-auth";

const NavMenuItem = (props) => {
  const { loggedUser } = useSelector(({ auth }) => auth);
  const { name, icon, link, accessFor } = props;
  if (isAccessable(accessFor, loggedUser.roles)) {
    return (
      <List component="div" className="nav-menu-item">
        <NavLink className="prepend-icon nav-menu-link" to={link}>
          {/* Display an icon if any */}
          {!!icon && <i className={"zmdi zmdi-hc-fw  zmdi-" + icon} />}
          <span className="nav-text">
            <IntlMessages id={name} />
          </span>
        </NavLink>
      </List>
    );
  } else {
    return <span></span>;
  }
};

export default NavMenuItem;
