import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

// material ui
import { withMobileDialog, Dialog } from "@material-ui/core";

import TransitionSlide from "components/Dialog/TransitionSlide";

// Actions
import { onMedicFormClose } from "redux/actions/MedicalActions";

import FormComponent from "./FormComponent";

const FormModal = (props) => {
  const dispatch = useDispatch();
  const { addMedModal, updateMedModal, formMedFor } = useSelector(
    ({ medRecordStore }) => medRecordStore
  );

  const [alertMsg, setAlertMsg] = useState(null)

  // on contacts modal close
  const onClose = () => {
    dispatch(onMedicFormClose());
  };


  useEffect(() => {
    if (alertMsg !== null) {
      let timerFunc = setTimeout(() => {
        setAlertMsg(null);
      }, 3000);
      return () => clearTimeout(timerFunc);
    }
  }, [alertMsg]);

  return (
    <>

    <Dialog
      fullScreen={props.fullScreen}
      fullWidth={true}
      maxWidth="md"
      scroll="paper"
      TransitionComponent={TransitionSlide}
      open={addMedModal || updateMedModal}
      onClose={onClose}
    >
      {(addMedModal || updateMedModal) && formMedFor === "labworks" && (
        <FormComponent {...{ alertMsg, setAlertMsg }} />
      )}
    </Dialog>
    </>
  );
};

FormModal.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};
export default withMobileDialog()(FormModal);
