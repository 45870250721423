// get visitRecord list by Patient Id

import { api } from "util/api";

// MedRecordStore
export function onAddRecommendedTestForm(formtype = "add", formFor = null) {
  return (dispatch) => {
    return dispatch({
      type: "RECOMMENDEDTESTFORM",
      payload: { formtype: formtype, formFor: formFor },
    });
  };
}

// toggle patient visit details modal
// MedRecordStore
export function onRecommendedTestFormClose() {
  return (dispatch) => {
    return dispatch({
      type: "RECOMMENDEDTESTFORMCLOSE",
    });
  };
}

// On visit record Save
export function onRecommendedTestRecordSave(value) {
  return (dispatch) => {
    return dispatch({
      type: "RECOMMENDEDTESTRECORD_SAVE",
      payload: api.post("visit/clinical", value),
    });
  };
}
