import React from "react";
import { connect } from "react-redux";
// Vendors Components
import { CardHeader, Tooltip, IconButton } from "@material-ui/core";
import { AccountCircle, Edit as EditIcon } from "@material-ui/icons";

//  Themes Components
import Widget from "components/Widget";
import AlertBox from "components/AlertBox/index";
// Custom Components and Helpers
import { getValuefromLookup, AnswerEmoji, displayDate } from "util/helpers";

//  Language Components
import IntlMessages from "util/IntlMessages";

//  Actions
import { onDailylogForm } from "redux/actions/MedicalActions";

class Interview extends React.Component {
  onUpdate = () => {
    this.props.onDailylogForm("update", "interview");
  };

  render() {
    const { dailyLog, accConfig } = this.props;
    return (
      <Widget styleName="jr-card-full jr-card-tabs-right jr-card-profile">
        <div className="jr-tabs-content jr-task-list">
          <CardHeader
            action={
              // !updateMedicalFormView &&
              <Tooltip title="Update Interview ">
                <IconButton
                  aria-label="Add"
                  onClick={() => this.onUpdate()}
                  variant="contained"
                  color="primary"
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            }
            subheader={
              <strong>
                <IntlMessages id="dailylog.label.interview" />
                {dailyLog.interview && (
                  <span>
                    -{displayDate(dailyLog.interview.time, "time", true)}
                  </span>
                )}
              </strong>
            }
          />
          <div className="jr-wall-content">
            {!dailyLog.interview && (
              <AlertBox
                infotype="danger"
                message="No Interview Info Found."
                onClick={this.onUpdate}
              />
            )}
            {dailyLog.interview && (
              <div>
                {dailyLog.interview.questionAnswers &&
                  dailyLog.interview.questionAnswers.map((note, index) => {
                    var isSad = note.answer < 6 ? true : false;
                    return (
                      <div key={index} className="media social-list-line">
                        <AnswerEmoji answer={note} />
                        <div className="media-body">
                          <h4 className="mb-1">
                            <strong>
                              {getValuefromLookup(
                                accConfig.interviewQuestion,
                                note.question
                              )}{" "}
                              :
                            </strong>{" "}
                            {note.answer}
                          </h4>
                          {/* <span className="meta-date meta-date-light">{note.started}</span> */}
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      </Widget>
    );
  }
}

export default Interview = connect(
  (state) => {
    return {
      accConfig: state.uiConfigureStore.accConfig,
      dailyLog: state.medRecordStore.dailyLog,
    };
  },
  { onDailylogForm }
)(Interview);
