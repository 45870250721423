import React from "react";

import { useDispatch, useSelector } from "react-redux";

// Vendors Components
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Tooltip,
  IconButton,
  Paper,
} from "@material-ui/core";

import {
  Edit as EditIcon,
  CheckCircle as CheckCircleIcon,
} from "@material-ui/icons";

// Themes Components
import Auxiliary from "util/Auxiliary";
import Widget from "components/Widget/index";
import AlertBox from "components/AlertBox/index";

// Custom Components and Helpers
import { capitalize, getValuefromLookup } from "util/helpers";

// Actions
import { onInPatientForm } from "redux/actions/MedicalActions";

const ChiefComplaints = (props) => {
  const dispatch = useDispatch();
  const { inPatient, medicalrecord } = useSelector(
    ({ medRecordStore }) => medRecordStore
  );
  const { accConfig } = useSelector(({ uiConfigureStore }) => uiConfigureStore);

  // on Add History
  const onUpdate = () => {
    dispatch(onInPatientForm("update", "chiefComplaints"));
  };
  return (
    <Paper className="jr-tabs-content jr-task-list">
      <List component="nav" aria-label="main mailbox folders">
        <ListItem button>
          <ListItemText primary={<strong>Chief Complaints</strong>} />
          <Tooltip title="Add Chief Complaint">
            <IconButton
              aria-label="Add"
              onClick={() => onUpdate()}
              variant="contained"
              color="primary"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </ListItem>
        {!inPatient.clinicalRecord.chiefComplaints && (
          <AlertBox
            infotype="danger"
            message="No Complaintes Found."
            onClick={onUpdate}
          />
        )}
        {inPatient.clinicalRecord.chiefComplaints &&
          inPatient.clinicalRecord.chiefComplaints.map((value, index) => {
            return (
              <div key={index}>
                <ListItem button>
                  <ListItemAvatar>
                    <CheckCircleIcon color="secondary" fontSize="large" />
                  </ListItemAvatar>
                  <ListItemText primary={value} />
                </ListItem>
              </div>
            );
          })}
      </List>
    </Paper>
  );
};

export default ChiefComplaints;
