import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";

import moment from "moment";
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Container,
} from "@material-ui/core";

// import { DateTimeInput } from "util/formInput";

import IntlMessages from "util/IntlMessages";
import { DateTimeInput } from "util/formInput";

export default function AdmissionForm(props) {
  const { control, setValue, getValues, errors, accConfig, register } = props;

  const values = getValues({ nest: true });
  // console.log(values);

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <DateTimeInput
              setAgeValue={setValue}
              value={values.admission && values.admission.date}
              label="Date"
              name="admission.date"
              format="YYYY-MM-DD"
              inputRef={register}
              fullWidth
              autoOk
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <DateTimeInput
              setAgeValue={setValue}
              value={values.admission && values.admission.time}
              label="Time"
              name="admission.time"
              inputRef={register}
              fullWidth
              type="time"
              ampm={false}
              autoOk
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item spacing={2} xs={6}>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="unit">
              <IntlMessages id="inpatient.form.department" />
            </InputLabel>
            <Controller
              as={
                <Select>
                  <MenuItem value="" disabled>
                    {" "}
                    Department
                  </MenuItem>
                  {accConfig.department.map((val) => (
                    <MenuItem value={val.key} key={val.key}>
                      {val.value}
                    </MenuItem>
                  ))}
                </Select>
              }
              name="admission.department"
              label={<IntlMessages id="inpatient.form.department" />}
              control={control}
              fullWidth
              // margin="normal"
              error={errors.admission && errors.admission.department}
              helperText={
                errors.admission &&
                errors.admission.department &&
                errors.admission.department.message
              }
            />
          </FormControl>
        </Grid>
        <Grid item spacing={2} xs={6}>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="unit">
              <IntlMessages id="inpatient.form.unit" />
            </InputLabel>
            <Controller
              as={
                <Select>
                  <MenuItem value="" disabled>
                    {" "}
                    Unit
                  </MenuItem>
                  {accConfig.unit.map((val) => (
                    <MenuItem value={val.key} key={val.key}>
                      {val.value}
                    </MenuItem>
                  ))}
                </Select>
              }
              name="admission.unit"
              label={<IntlMessages id="inpatient.form.unit" />}
              control={control}
              fullWidth
              // margin="normal"
              error={errors.admission && errors.admission.unit}
              helperText={
                errors.admission &&
                errors.admission.unit &&
                errors.admission.unit.message
              }
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item spacing={2} xs={6}>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="unit">
              <IntlMessages id="inpatient.form.ward" />
            </InputLabel>
            <Controller
              as={
                <Select>
                  <MenuItem value="" disabled>
                    {" "}
                    Ward
                  </MenuItem>
                  {accConfig.ward.map((val) => (
                    <MenuItem value={val.key} key={val.key}>
                      {val.value}
                    </MenuItem>
                  ))}
                </Select>
              }
              name="admission.ward"
              label={<IntlMessages id="inpatient.form.ward" />}
              control={control}
              fullWidth
              // margin="normal"
              error={errors.admission && errors.admission.ward}
              helperText={
                errors.admission &&
                errors.admission.ward &&
                errors.admission.ward.message
              }
            />
          </FormControl>
        </Grid>
        <Grid item spacing={2} xs={6}>
          <FormControl fullWidth margin="normal">
            <Controller
              label={<IntlMessages id="inpatient.form.bedNumber" />}
              as={TextField}
              name="admission.bedNumber"
              control={control}
              fullWidth
              error={errors.admission && errors.admission.bedNumber}
              helperText={
                errors.admission &&
                errors.admission.bedNumber &&
                errors.admission.bedNumber.message
              }
            />
          </FormControl>
        </Grid>
      </Grid>
    </Container>
  );
}
