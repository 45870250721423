import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SendIcon from "@material-ui/icons/Send";
import ByCategories from "./ByCategories";

import CustomScrollbars from "util/CustomScrollbars";
import ByTags from "./ByTags";

export default function ContactOptions(props) {
  const { step, formVals, setFormVals, activeButton, setActiveButton } = props;

  const { width, uiClients, uiClientsLoading } = useSelector(
    ({ uiConfigureStore }) => uiConfigureStore
  );

  const [openCat, setOpenCat] = React.useState(false);
  const [openTag, setOpenTag] = React.useState(false);

  const expandCat = () => {
    setActiveButton(!openCat ? 2 : 1);
    setOpenCat(!openCat);
  };

  const expandTag = () => {
    setActiveButton(!openTag ? 3 : 1);
    setOpenTag(!openTag);
  };

  return (
    <CustomScrollbars
      style={{
        height: width >= 1200 ? "calc(60vh - 200px)" : "calc(60vh - 80px)",
      }}
    >
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Let me choose ...
          </ListSubheader>
        }
      >
        <ListItem button onClick={() => setActiveButton(1)}>
          <ListItemIcon>
            <SendIcon />
          </ListItemIcon>
          <ListItemText primary="All Contacts" />
        </ListItem>
        <ByCategories
          expandCat={expandCat}
          openCat={openCat}
          formVals={formVals}
          setFormVals={setFormVals}
          setActiveButton={setActiveButton}
          activeButton={activeButton}
        />
        <ByTags
          expandTag={expandTag}
          openTag={openTag}
          formVals={formVals}
          setFormVals={setFormVals}
          setActiveButton={setActiveButton}
          activeButton={activeButton}
        />
      </List>
    </CustomScrollbars>
  );
}
