const initialState = {
  loading: false,
  messages: [],
  errors: [],
  formerror: null,
  error: "",

  // Notes
  errorMsg: "",
  noteError: null,
  notes: [],
  notesLoading: false,
  // NoteForm
  note: {},
  aboutType: "other",
  addFormInbox: false,
  updateFormInbox: false,
  viewInboxNote: false,

  noteTags: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    // get client detail by Id
    case "GET_ALLNOTES_FULFILLED": {
      const notes = action.payload.data;
      return {
        ...state,
        notes: notes,
        notesLoading: false,
        noteError: null,
      };
    }
    case "GET_ALLNOTES_PENDING": {
      return {
        ...state,
        notes: [],
        notesLoading: true,
        noteError: null,
      };
    }
    case "GET_ALLNOTES_REJECTED": {
      return {
        ...state,
        error: action.payload.response.data,
        notes: [],
        messages: [],
        errors: [],
        notesLoading: false,
        noteError: action.payload.response.data.message,
      };
    }
    case "INBOXNOTEFORM": {
      let formType = action.payload.formParam.formType;
      let aboutType = action.payload.formParam.aboutType;
      // console.log(action.payload);
      return {
        ...state,
        note:
          formType === "update" || formType === "view"
            ? action.payload.note
            : null,
        addFormInbox: formType === "add" ? true : false,
        updateFormInbox: formType === "update" ? true : false,
        viewInboxNote: formType === "view" ? true : false,
        aboutType: aboutType,
      };
    }
    case "INBOXCLOSE": {
      // console.log(action.payload)
      return {
        ...state,
        note: null,
        addFormInbox: false,
        updateFormInbox: false,
        viewInboxNote: false,
        aboutType: "other",
      };
    }

    // Save Inbox Notes
    case "INBOXNOTE_ADD_FULFILLED": {
      const notes = [action.payload.data, ...state.notes];
      console.log(action.payload.data);
      console.log(notes);
      return {
        ...state,
        notes: [action.payload.data, ...state.notes],
        note: action.payload.data,
        viewInboxNote: true,
        updateFormInbox: false,
        addFormInbox: false,
        formerror: null,
        is_saving: false,
      };
    }
    case "INBOXNOTE_ADD_PENDING": {
      return {
        ...state,
        is_saving: true,
      };
    }
    case "INBOXNOTE_ADD_REJECTED": {
      return {
        ...state,
        formerror: action.payload.response.data,
        is_saving: false,
        messages: [],
        errors: [],
      };
    }

    // Update Contacts
    case "INBOXNOTE_UPDATE_FULFILLED": {
      const note = action.payload.data;
      const notes = state.notes.map((item) =>
        item.id === note.id ? note : item
      );
      // console.log(notes);
      return {
        ...state,
        notes: state.notes.map((item) => (item.id === note.id ? note : item)),
        note: note,
        viewInboxNote: true,
        updateFormInbox: false,
        addFormInbox: false,
        formerror: null,
        is_saving: false,
      };
    }
    case "INBOXNOTE_UPDATE_PENDING": {
      return {
        ...state,
        is_saving: true,
      };
    }
    case "INBOXNOTE_UPDATE_REJECTED": {
      return {
        ...state,
        formerror: action.payload.response.data,
        messages: [],
        errors: [],
        is_saving: false,
      };
    }

    // Delete Contacts
    case "INBOXNOTE_DELETE_FULFILLED": {
      let note = action.payload.data;
      // note.status = "DELETED";
      // console.log(note);
      return {
        ...state,
        notes: state.notes.map((item) => (item.id === note.id ? note : item)),
        note: {},
        viewInboxNote: false,
        updateFormInbox: false,
        addFormInbox: false,
        formerror: null,
        is_saving: false,
      };
    }

    case "INBOXNOTE_ADD_INBOXSTORE": {
      return initialState;
    }

    // Note Tags
    case "NOTETAGS_FULFILLED": {
      // console.log(action.payload)
      return {
        ...state,
        noteTags: action.payload.data,
      };
    }
    case "NOTETAGS_PENDING": {
      return {
        ...state,
      };
    }
    case "NOTETAGS_REJECTED": {
      // console.log(action)
      return {
        ...state,
        noteTags: [],
        error: action.payload.response,
        messages: [],
        errors: [],
      };
    }
    default:
      return state;
  }
};
