import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import { Alert } from "reactstrap";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  hideMessage,
  logUserId,
  loginUser,
  signupUser,
  logFirstUserId,
} from "../actions";
import { CrudNotification } from "util/helpers";
import { isLoggedIn, saveAuthInfo } from "util/check-auth";
import SocialButton from "../SocialButton";

const SignIn = (props) => {
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const dispatch = useDispatch();
  const { loader, alertMessage, showMessage, authUser, error } = useSelector(
    ({ auth }) => auth
  );

  useEffect(() => {
    if (isLoggedIn()) {
      props.history.push("/");
    }
  }, [authUser, props.history]);

  const handleSocialLogin = async (user) => {
    // console.log(user);
    CrudNotification("success", "You are logged in Google !");

    await saveAuthInfo(user);
    try {
      const logUserIdData = await dispatch(logFirstUserId(user._profile.email));
      if (logUserIdData) {
        CrudNotification("success", "You are logged in !");
        props.history.push("/");
      } else {
      }
    } catch (error) {
      const UserData = {};
      if (error && error.status && error.status === 403) {
        UserData.name = user._profile.name;
        UserData.email = user._profile.email;
        UserData.name = user._profile.name;
        UserData.profileImageUrl = user._profile.profilePicURL;
        UserData.key = user._profile.id;
        dispatch(signupUser(UserData)).then((response) => {
          dispatch(logUserId(UserData.email)).then((response) => {
            CrudNotification("success", "You are logged in !");
            props.history.push("/");
          });
        });
      } else {
        CrudNotification("danger", "SignIn Fail !");
      }
    }
  };

  const handleSocialLoginFailure = (err) => {
    CrudNotification("danger", "SignIn Fail !");
  };

  return (
    <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content">
        <div className="app-logo-content d-flex align-items-center justify-content-center">
          <Link className="logo-lg" to="/" title="Metta">
            <img
              src={require("assets/images/logo-centered.png")}
              alt="Metta"
              title="Metta"
              style={{ width: "100%" }}
            />
          </Link>
        </div>

        <div className="app-login-content">
          <div className="app-login-header mb-4">
            <h1>{<IntlMessages id="appModule.loginForm" />}</h1>
            <h4>You need to login with your Google Account</h4>
          </div>
          {error && <Alert color="danger">{error}</Alert>}

          <div className="app-login-form">
            {/* <form onSubmit={handleSubmit(onSubmit)}>
              <fieldset>
                <Controller
                  className="mt-1 my-sm-3"
                  as={TextField}
                  label={<IntlMessages id="appModule.userName" />}
                  name="userId"
                  control={control}
                  fullWidth
                  margin="normal"
                  error={errors.userId}
                  helperText={errors.userId && errors.userId.message}
                />
                <Controller
                  type="password"
                  className="mt-1 my-sm-3"
                  as={TextField}
                  label={<IntlMessages id="appModule.password" />}
                  name="password"
                  control={control}
                  fullWidth
                  margin="normal"
                  error={errors.password}
                  helperText={errors.password && errors.password.message}
                />
                <div className="mb-3 d-flex align-items-center justify-content-between">
                  <Button variant="contained" color="primary" type="submit">
                    <IntlMessages id="appModule.signIn" />
                  </Button>
                  <Link to="/signup">
                    <IntlMessages id="signIn.signUp" />
                  </Link>
                </div>
              </fieldset>
            </form> */}

            <div className="mb-3 d-flex align-items-center justify-content-between">
              <SocialButton
                provider="google"
                appId={clientId}
                // vgSmfVLx4LGPxStnxOLXAlZT
                onLoginSuccess={handleSocialLogin}
                onLoginFailure={handleSocialLoginFailure}
              >
                <i className="zmdi zmdi-google"></i>&nbsp;&nbsp;
                <IntlMessages id="appModule.signIn" />
              </SocialButton>
              {/* <SocialButton
                provider="facebook"
                appId="160325149295926"
                // vgSmfVLx4LGPxStnxOLXAlZT
                onLoginSuccess={handleSocialLogin}
                onLoginFailure={handleSocialLoginFailure}
              >
                <i className="zmdi zmdi-facebook"></i>&nbsp;&nbsp;
                <IntlMessages id="appModule.signIn" />
              </SocialButton> */}
              <Link to="/signup">
                <IntlMessages id="signIn.signUp" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      {loader && (
        <div className="loader-view">
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default withRouter(SignIn);
