import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { onGetMessageLogById, onGetMessageLogs } from "services/actions";
import { CardHeader, Grid } from "@material-ui/core";
import CustomScrollbars from "util/CustomScrollbars";
import MessageLogDetail from "./MessageLogDetail";

const MessageLogs = (props) => {
  const dispatch = useDispatch();
  const { width } = useSelector(({ uiConfigureStore }) => uiConfigureStore);
  const { msgLogs, msgLogs_loading, logError, msgLog, msgLog_loading } =
    useSelector(({ messageStore }) => messageStore);

  useEffect(() => {
    dispatch(onGetMessageLogs());
  }, []);

  const onMessageLog = (id) => {
    dispatch(onGetMessageLogById(id));
  };
  return (
    <>
      <div className="row">
        <Grid container>
          <Grid item xs={4}>
            <CardHeader title={"Message Logs"} />
            <CustomScrollbars
              style={{
                height:
                  width >= 1200 ? "calc(60vh - 200px)" : "calc(60vh - 80px)",
              }}
            >
              <List component="nav" aria-label="logs">
                {msgLogs.length > 0 &&
                  msgLogs.map((item, index) => (
                    <ListItem
                      button
                      key={index}
                      onClick={() => onMessageLog(item.id)}
                      selected={msgLog.id === item.id}
                    >
                      <ListItemText primary={item.subject} />
                    </ListItem>
                  ))}
              </List>
            </CustomScrollbars>
          </Grid>

          <Grid item xs={8}>
            <MessageLogDetail />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default MessageLogs;
