import React, { useEffect, useState } from "react";
import { useFieldArray, Controller } from "react-hook-form";

import {
  Grid,
  TextField,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment,
  Divider,
  Container,
  Avatar,
  CardHeader,
  IconButton,
  Tooltip,
  Button,
} from "@material-ui/core";

import { Refresh, Add as AddIcon, Clear, Close } from "@material-ui/icons";

import IntlMessages from "util/IntlMessages";

export default function PersonalHistoryForm(props) {
  const { control, errors, accConfig, register } = props;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "personalHistory",
    keyName: "keyId", //, default to "id", you can change the key name
  });

  const onAdd = () => {
    append({
      name: "",
      note: "",
      current: true,
    });
  };

  const onRemove = (index) => {
    remove(index);
  };
  const onReset = (index) => {
    remove(index);
    onAdd();
    // console.log('reset');
  };
  // console.log(errors);
  return (
    <div>
      {fields.map((item, index) => {
        return (
          <div key={item.keyId}>
            <Container style={{ paddingBottom: 25 }}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel htmlFor="unit">
                      <IntlMessages id="medicRecord.form.name" />
                    </InputLabel>
                    <Controller
                      as={
                        <Select>
                          <MenuItem value="" disabled>
                            {" "}
                            Medical History
                          </MenuItem>
                          {accConfig.medicalHistory &&
                            accConfig.medicalHistory.map((val) => (
                              <MenuItem value={val.key} key={val.key}>
                                {val.value}
                              </MenuItem>
                            ))}
                        </Select>
                      }
                      name={`personalHistory[${index}].name`}
                      label={<IntlMessages id="medicRecord.form.name" />}
                      control={control}
                      fullWidth
                      defaultValue={item.name}
                      error={
                        errors.personalHistory &&
                        errors.personalHistory[index] &&
                        errors.personalHistory[index].name
                      }
                      helperText={
                        errors.personalHistory &&
                        errors.personalHistory[index] &&
                        errors.personalHistory[index].name &&
                        errors.personalHistory[index].name.message
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <FormControl fullWidth margin="normal">
                    {/* <InputLabel htmlFor="current"> */}
                    <IntlMessages id="medicRecord.form.current" />
                    {/* </InputLabel> */}
                    <Controller
                      as={Checkbox}
                      name={`personalHistory[${index}].current`}
                      defaultValue={item.current}
                      type="checkbox"
                      control={control}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={11} sm={11}>
                  <FormControl fullWidth margin="normal">
                    <Controller
                      label={<IntlMessages id="medicRecord.form.note" />}
                      as={TextField}
                      name={`personalHistory[${index}].note`}
                      control={control}
                      fullWidth
                      multiline
                      defaultValue={item.note}
                      error={
                        errors.personalHistory &&
                        errors.personalHistory[index] &&
                        errors.personalHistory[index].note
                      }
                      helperText={
                        errors.personalHistory &&
                        errors.personalHistory[index] &&
                        errors.personalHistory[index].note &&
                        errors.personalHistory[index].note.message
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={1} sm={1}>
                  <Button
                    color="secondary"
                    aria-label="Remove"
                    onClick={() => onRemove(index)}
                    size="small"
                  >
                    <Tooltip
                      title={"Remove Personal History  " + (index + 1)}
                      size="large"
                    >
                      <Clear />
                    </Tooltip>
                  </Button>
                </Grid>
              </Grid>
            </Container>

            {index + 1 !== fields.length && <Divider />}
          </div>
        );
      })}
      <Tooltip title="Click to Add Personal History detail">
        <Button
          aria-label="Add"
          onClick={() => onAdd()}
          variant="contained"
          color="primary"
        >
          Add More <AddIcon />
        </Button>
      </Tooltip>
    </div>
  );
}
