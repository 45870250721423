import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

// Vendors Components
import {
  CardHeader,
  Tooltip,
  IconButton,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
} from "@material-ui/core";

import {
  AccountCircle,
  CheckCircle as CheckCircleIcon,
  Edit as EditIcon,
} from "@material-ui/icons";

//  Themes Components

import Auxiliary from "util/Auxiliary";
import Widget from "components/Widget/index";
import AlertBox from "components/AlertBox/index";
// Custom Components and Helpers
import { getValuefromLookup, displayDate } from "util/helpers";
// Components
import NoDetail from "../NoDetail";
// import ChiefComplaints from "./ChiefComplaints";
// import Illnesses from "./Illnesses";

//  Actions
import { onInPatientForm } from "redux/actions/MedicalActions";

const Operation = (props) => {
  const dispatch = useDispatch();
  //   const { contact } = useSelector(({ contactStore }) => contactStore);

  const { accConfig } = useSelector(({ uiConfigureStore }) => uiConfigureStore);
  const { inPatient, inpatient_loading } = useSelector(
    ({ medRecordStore }) => medRecordStore
  );

  const { inverted = "timeline-inverted" } = props;

  const onAddInpatientRecord = () => {
    dispatch(onInPatientForm("update", "operation"));
  };
  return (
    <div className={"timeline-item " + inverted}>
      {!inPatient.surgeries && (
        <NoDetail
          title={"Operations Records"}
          loading={inpatient_loading}
          onAddInpatientRecord={onAddInpatientRecord}
          // inverted={inverted}
        />
      )}
      {inPatient.surgeries && (
        <div>
          <div className={`timeline-badge bg-green`}>
            <AccountCircle />
          </div>

          <div className={"timeline-panel mytimeline"}>
            <CardHeader
              action={
                inPatient.id && (
                  <Tooltip title="Update Operations Info ">
                    <IconButton
                      aria-label="Add"
                      onClick={() => onAddInpatientRecord()}
                      variant="contained"
                      color="primary"
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                )
              }
              title={"Operations Records"}
              //   subheader={
              //     <small>
              //       {inPatient.clinicalRecord.dateDisplay}{" "}
              //       {inPatient.clinicalRecord.timeDisplay}
              //     </small>
              //   }
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs>
                  <List>
                    {inPatient.surgeries &&
                      inPatient.surgeries.map((value, index) => {
                        return (
                          <div key={index}>
                            <ListItem alignItems="flex-start">
                              <ListItemAvatar>
                                <CheckCircleIcon
                                  color="secondary"
                                  fontSize="large"
                                />
                              </ListItemAvatar>
                              <ListItemText
                                primary={
                                  <span>
                                    PerformBy : {value.performedBy} at{" "}
                                    <small>
                                      {displayDate(value.dateTimeDisplay)}
                                    </small>
                                  </span>
                                }
                                // primary={displayDate(value.dateTimeDisplay)}
                                secondary={
                                  <div>
                                    <strong>
                                      {value.code} - {value.name}
                                    </strong>
                                    <p>{value.note}</p>
                                  </div>
                                }
                              />
                            </ListItem>
                            {index + 1 !== inPatient.surgeries.length && (
                              <Divider variant="inset" component="li" />
                            )}
                          </div>
                        );
                      })}
                  </List>
                </Grid>
              </Grid>
            </CardContent>
          </div>
        </div>
      )}
    </div>
  );
};
export default Operation;
