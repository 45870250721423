import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import { Button, Tooltip, Fab } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { AccountCircle } from "@material-ui/icons";

// Custom Components and Helpers
import Widget from "components/Widget/index";
import Auxiliary from "util/Auxiliary";
import AlertBox from "components/AlertBox/index";

import { getValuefromLookup, displayDate } from "util/helpers";

// import RecentItems from './Component/RecentItems';
import {
  getInPatientListByPId,
  getInPatientDetailById,
  onInPatientForm,
} from "redux/actions/MedicalActions";
import { setProfileTitle } from "redux/actions/Setting";

import MedicRecordModal from "./InPatientForm/index";

export default function InPatient(props) {
  const dispatch = useDispatch();
  const { accConfig } = useSelector(({ uiConfigureStore }) => uiConfigureStore);
  const { contact } = useSelector(({ contactStore }) => contactStore);
  const {
    inPatientList,
    addInPatientModal,
    updateInPatientModal,
    inpatientlist_loading,
  } = useSelector(({ medRecordStore }) => medRecordStore);

  useEffect(() => {
    dispatch(setProfileTitle({ id: 1, title: "Inpatient Care" }));
    dispatch(getInPatientListByPId(contact.clientId));
  }, []);

  const onAddInpatientRecord = () => {
    dispatch(onInPatientForm("add"));
    // console.log('onAddmedicalrecord');
  };
  //    on Click
  const onViewDetailClick = (item) => {
    dispatch(getInPatientDetailById(item.inpatientId)).then((res) => {
      props.history.push(
        `/app/viewPatient/${props.match.params.id}/inpatientDetail/` +
          item.inpatientId
      );
    });
  };
  return (
    <Auxiliary>
      <div className="timeline-section timeline-center clearfix animated slideInUpTiny animation-duration-3">
        <div className={"timeline-item timeline-inverted"}>
          <div className="timeline-time">{moment().format("YYYY-MM-DD")}</div>
          <div className={`timeline-badge bg-green`}>
            <AccountCircle />
          </div>
          <div className="timeline-panel" style={{ padding: 0 }}>
            {!inpatientlist_loading && (
              <AlertBox
                infotype="info"
                message="Click to add new Inpatient Record"
                onClick={onAddInpatientRecord}
              />
            )}
            {inpatientlist_loading && <Skeleton variant="rect" height={100} />}
          </div>
        </div>

        {inPatientList.length !== 0 &&
          inPatientList.map((value, index) => {
            if (value.admission) {
              return (
                <div
                  className={
                    index % 2 === 0
                      ? "timeline-item"
                      : " timeline-item timeline-inverted"
                  }
                  key={index}
                >
                  <div className="timeline-time">
                    {displayDate(value.admission.dateTimeDisplay, "datetime")}
                  </div>
                  <div className={`timeline-badge bg-green`}>
                    <AccountCircle />
                  </div>
                  <Tooltip
                    title="Click to View Inpatient Visit Detail"
                    aria-label="Click to View Inpatient Visit Detail"
                  >
                    <div
                      className="timeline-panel onclick"
                      onClick={() => onViewDetailClick(value)}
                    >
                      <h3 className={`timeline-tile text-green`}>
                        {getValuefromLookup(
                          accConfig.department,
                          value.admission.department
                        )}
                      </h3>
                      <p>
                        <strong> Unit : </strong>
                        {getValuefromLookup(
                          accConfig.unit,
                          value.admission.unit
                        )}
                        <strong> Ward : </strong>
                        {getValuefromLookup(
                          accConfig.ward,
                          value.admission.ward
                        )}
                        <strong> Bed No : </strong>
                        {value.admission.bedNumber}
                      </p>
                    </div>
                  </Tooltip>
                </div>
              );
            }
          })}
      </div>

      {(addInPatientModal || updateInPatientModal) && <MedicRecordModal />}
      <div className="bottom-button">
        <Tooltip title="Add Patient Visit" aria-label="Add Patient Visit">
          <Fab
            color="primary"
            aria-label="add"
            onClick={() => onAddInpatientRecord()}
          >
            <i className="zmdi zmdi-plus zmdi-hc-fw zmdi-hc-lg" />
          </Fab>
        </Tooltip>
      </div>
    </Auxiliary>
  );
}
