import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Chip,
  Button,
  ListSubheader,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Checkbox,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import RefreshIcon from "@material-ui/icons/Refresh";

import InboxIcon from "@material-ui/icons/MoveToInbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";
import IntlMessages from "util/IntlMessages";

import CustomScrollbars from "util/CustomScrollbars";
// Actions
import { getActiveContacts } from "services/actions";

const categories = [
  {
    id: 1,
    label: "Clients",
    key: "client",
  },
  {
    id: 2,
    label: "Opportunity",
    key: "opportunity",
  },
  {
    id: 3,
    label: "Staffs",
    key: "staff",
  },
  {
    id: 4,
    label: "Other",
    key: "other",
  },
];

const ByCategories = (props) => {
  const dispatch = useDispatch();
  const {
    width,
    districts,
    appUiConfig,
    uiClients,
    uiClientsLoading,
  } = useSelector(({ uiConfigureStore }) => uiConfigureStore);
  const {
    expandCat,
    openCat,
    formVals,
    setFormVals,
    activeButton,
    setActiveButton,
  } = props;

  const [tags, setTags] = useState([]);

  useEffect(() => {
    setTags([]);
  }, [openCat]);

  const handleChange = () => {
    if (tags.length > 0) {
      dispatch(getActiveContacts("roles", tags.toString()));
    }
    // else {
    //   dispatch(getActiveContacts("roles", "client"));
    // }
  };

  useEffect(() => {
    handleChange();
  }, [tags]);

  const onCatClick = (key) => {
    var tagVal = [...tags];
    if (tags.includes(key)) {
      tagVal = tagVal.filter((item) => item !== key);
    } else {
      tagVal.push(key);
    }
    setTags(tagVal);
  };

  return (
    <>
      <ListItem button onClick={expandCat}>
        <ListItemIcon>
          <InboxIcon />
        </ListItemIcon>
        <ListItemText primary="By Category" />
        {openCat ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openCat} timeout="auto" unmountOnExit>
        <List component="div" className="pl-4">
          {categories.map((item) => (
            <ListItem button onClick={() => onCatClick(item.key)}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  onChange={() => onCatClick(item.key)}
                  checked={tags.includes(item.key)}
                  inputProps={{ "aria-labelledby": item.key }}
                />
              </ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItem>
          ))}
        </List>
      </Collapse>
      {/* <div className="stepper-tags">
        <div>I would like to send message to </div>
        {categories.map((item) => (
          <Chip
            label={item.label}
            onClick={() => handleClick(item.key)}
            variant={tags.includes(item.key) ? "default" : "outlined"}
            color="primary"
            style={{ margin: "10px" }}
          />
        ))}
      </div> */}
      {/* <div className="stepper-tags">
        {formVals.clientIds.length > 0 && (
          <div>You have selected {formVals.clientIds.length} contacts.</div>
        )}

        <Button
          variant="contained"
          color="primary"
          disabled={tags.length === 0 || uiClientsLoading}
          onClick={() => handleChange()}
        >
          <RefreshIcon /> Refresh
        </Button>
      </div> */}
    </>
  );
};

export default ByCategories;
