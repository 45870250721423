import React from "react";
import { connect } from "react-redux";

// Vendors Components
import { CardHeader, Avatar, Tooltip, IconButton } from "@material-ui/core";

import { AccountCircle, Edit as EditIcon } from "@material-ui/icons";

//  Themes Components
import Widget from "components/Widget";
import AlertBox from "components/AlertBox/index";
// Custom Components and Helpers
import { getValuefromLookup, displayDate } from "util/helpers";

//  Language Components
import IntlMessages from "util/IntlMessages";

//  Actions
import { onDailylogForm } from "redux/actions/MedicalActions";

class MedicationSchedules extends React.Component {
  onUpdate = () => {
    this.props.onDailylogForm("update", "medicationSchedules");
  };

  render() {
    const { dailyLog, accConfig } = this.props;
    return (
      <Widget styleName="jr-card-full jr-card-tabs-right jr-card-profile">
        <div className="jr-tabs-content jr-task-list">
          <CardHeader
            action={
              // !updateMedicalFormView &&
              <Tooltip title="Update Medication Schedules ">
                <IconButton
                  aria-label="Add"
                  onClick={() => this.onUpdate()}
                  variant="contained"
                  color="primary"
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            }
            subheader={
              <strong>
                <IntlMessages id="dailylog.label.medicationSchedules" />
              </strong>
            }
          />
          <div className="jr-wall-content">
            {!dailyLog.medicationSchedules && (
              <AlertBox
                infotype="danger"
                message="No Medication Info Found."
                onClick={this.onUpdate}
              />
            )}
            {dailyLog.medicationSchedules &&
              dailyLog.medicationSchedules.length > 0 && (
                <div>
                  {dailyLog.medicationSchedules.map((note, index) => {
                    return (
                      <div key={index} className="media social-list-line">
                        <Avatar
                          className={`z-index-20 size-40 align-item-self mr-3`}
                        ></Avatar>
                        <div className="media-body">
                          <h5 className="mb-1">
                            {/* {getValuefromLookup(
                              accConfig.medication_route,
                              note.route
                            )} */}
                          </h5>
                          <p>
                            Medication : {note.medication} {note.dose} <br />
                            {note.note}
                          </p>
                          <span className="meta-date meta-date-light">
                            {displayDate(note.time, "time", true)}
                            {/* {note.timeDisplay} */}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
          </div>
        </div>
      </Widget>
    );
  }
}

export default MedicationSchedules = connect(
  (state) => {
    return {
      loading: state.uiConfigureStore.isloading,
      accConfig: state.uiConfigureStore.accConfig,
      dailyLog: state.medRecordStore.dailyLog,
    };
  },
  { onDailylogForm }
)(MedicationSchedules);
