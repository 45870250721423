import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { withRouter } from "react-router-dom";

import { Button, Card, Tooltip, Fab } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import Auxiliary from "util/Auxiliary";

import AlertBox from "components/AlertBox/index";
import EwsForm from "./EwsForm/index";
import EwsBPChart from "./vitalDetail/EwsBPChart";
import EwsVitalDetail from "./vitalDetail/EwsVitalDetail";

import {
  onEwsForm,
  getEwsByPatientId,
  getEwsDetailById,
} from "redux/actions/MedicalActions";
import { setProfileTitle } from "redux/actions/Setting";

const EwsPage = (props) => {
  const dispatch = useDispatch();
  const { contact } = useSelector(({ contactStore }) => contactStore);
  const {
    ewslistloading,
    patientEws,
    patientEwsList,
    addEwsModal,
    updateEwsModal,
  } = useSelector(({ medRecordStore }) => medRecordStore);

  useEffect(() => {
    dispatch(getEwsByPatientId(props.match.params.id));
    dispatch(setProfileTitle({ id: 4, title: "Early Warning System" }));
  }, []);

  // on Type Change
  const onAddVitalForm = () => {
    dispatch(onEwsForm());
  };
  return (
    <Auxiliary>
      {contact && contact.role === "CLIENT" && (
        <div>
          {!ewslistloading && patientEwsList.length === 0 && (
            <AlertBox
              infotype="info"
              label="Click to Add"
              message="EWS Details not Avaiable"
              onClick={onAddVitalForm}
            />
          )}
          {patientEws && patientEws.id && <EwsVitalDetail />}
          {ewslistloading && <Skeleton variant="rect" height={100} />}

          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
              {!ewslistloading && patientEwsList.length !== 0 && <EwsBPChart />}
            </div>
          </div>
          <div className="bottom-button">
            <Tooltip title="Add EWS Detail" aria-label="Add EWS Detail">
              <Fab
                color="primary"
                aria-label="add"
                onClick={() => onAddVitalForm()}
              >
                <i className="zmdi zmdi-plus zmdi-hc-fw zmdi-hc-lg" />
              </Fab>
            </Tooltip>
          </div>
          {(addEwsModal || updateEwsModal) && <EwsForm />}
        </div>
      )}
      {contact && !contact.role === "CLIENT" && (
        <AlertBox
          infotype="info"
          message="Details Avaiable for only Patient"
          // onClick={onAddVitalForm}
        />
      )}
    </Auxiliary>
  );
};
export default EwsPage;
