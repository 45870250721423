import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFieldArray, Controller } from "react-hook-form";

import {
  FormControl,
  Grid,
  TextField,
  FormHelperText,
  Container,
  Avatar,
  CardHeader,
  Chip,
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { MoreVert as MoreVertIcon } from "@material-ui/icons";
export default function AccessGroupsArray(props) {
  const { accConfig, uiUsers } = useSelector(
    ({ uiConfigureStore }) => uiConfigureStore
  );
  const { formVals, groups, setGroups, label } = props;

  useEffect(() => {
    if (
      formVals &&
      formVals.accessGroups &&
      formVals.accessGroups.length > 0 &&
      accConfig.userAccessGroup
    ) {
      let filteredArray = accConfig.userAccessGroup.filter((acc) => {
        return (
          formVals.accessGroups.filter((item) => {
            return item.group === acc.key;
          }).length > 0
        );
      });
      setGroups(filteredArray);
    }
  }, []);

  const onValueChange = (event, value) => {
    setGroups(value);
    return value;
  };
  return (
    <div style={{ paddingBottom: 25 }}>
      <CardHeader
        avatar={
          <Avatar aria-label="Custom Fields">
            <MoreVertIcon />
          </Avatar>
        }
        title={
          <h2>
            <strong>{label}</strong>
          </h2>
        }
      />

      <Container style={{ paddingBottom: 25 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {/* <FormControl
              fullWidth
              margin="normal"
              error={errors.accessGroupsVal}
            > */}
            {/* options={accConfig.userAccessGroup} */}
            <Autocomplete
              multiple
              fullWidth
              id="tags-filled"
              options={accConfig.userAccessGroup}
              value={groups}
              getOptionLabel={(option) => option.value}
              freeSolo
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    color="primary"
                    label={option.value}
                    {...getTagProps({ index })}
                  />
                ))
              }
              onChange={onValueChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Share With ..."
                  placeholder="Groups"
                  fullWidth
                />
              )}
            />

            {/* <FormHelperText>
                {errors.accessGroupsVal && errors.accessGroupsVal.message}
              </FormHelperText>
            </FormControl> */}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
