import React from "react";
import CustomScrollbars from "util/CustomScrollbars";
import Navigation from "components/Navigation";

const SideBarContent = () => {
  const navigationMenus = [
    {
      name: "sidebar.main",
      type: "section",
      accessFor: ["USER", "ADMIN"],
      children: [
        {
          name: "pages.contactsPage",
          type: "item",
          link: "/app/contacts",
          icon: "zmdi zmdi-account-box zmdi-hc-fw",
          accessFor: ["USER", "ADMIN"],
        },
        {
          name: "pages.taskPage",
          type: "item",
          link: "/app/tasks",
          icon: "zmdi zmdi-email zmdi-hc-fw",
          accessFor: ["USER", "ADMIN"],
        },
        {
          name: "pages.inboxPage",
          type: "item",
          link: "/app/inbox",
          icon: "zmdi zmdi-archive zmdi-hc-fw",
          accessFor: ["USER", "ADMIN"],
        },
        {
          name: "pages.messagePage",
          type: "item",
          link: "/app/message",
          icon: "zmdi zmdi-email zmdi-hc-fw",
          accessFor: ["USER", "ADMIN"],
        },
        // {
        //   name: 'pages.visit',
        //   type: 'item',
        //   link: '/app/visit',
        //   icon: 'zmdi zmdi-email zmdi-hc-fw',
        //   accessFor: ['USER', 'ADMIN'],
        // },

        // {
        //   name: "pages.chatPage",
        //   type: "item",
        //   link: "/app/contacts",
        //   icon: "zmdi zmdi-account-box zmdi-hc-fw",
        //   accessFor: ["USER", "ADMIN"],
        // },
      ],
    },
    {
      name: "sidebar.settings",
      type: "section",
      accessFor: ["ADMIN"],
      children: [
        {
          name: "pages.users",
          type: "item",
          link: "/app/settings/users",
          icon: "zmdi zmdi-account-box zmdi-hc-fw",
          accessFor: ["ADMIN"],
        },
        {
          name: "pages.templatePage",
          type: "item",
          link: "/app/template",
          icon: "zmdi zmdi-file-text zmdi-hc-fw",
          accessFor: ["USER", "ADMIN"],
        },
      ],
    },
  ];
  return (
    <CustomScrollbars className=" scrollbar">
      <Navigation menuItems={navigationMenus} />
    </CustomScrollbars>
  );
};

export default SideBarContent;
