import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Skeleton } from "@material-ui/lab";
// custom components
import Widget from "components/Widget";
import ContactInfo from "./ContactInfo";
import CustomFieldInfo from "./CustomFieldInfo";

const ContactDetail = () => {
  const { client, clientLoading, clientError } = useSelector(
    ({ profileStore }) => profileStore
  );
  return (
    <Widget title="Contact" styleName="jr-card-profile-sm">
      {client && !client.id && <Skeleton variant="rect" height={400} />}
      {client && client.id && (
        <>
          <ContactInfo />
          <CustomFieldInfo />
        </>
      )}
    </Widget>
  );
};

export default ContactDetail;
