import * as yup from "yup";

export const initInboxVal = {
  note: "",
  subject: "",
  tags: [],
  accessGroups: [
    {
      group: "DEFAULT",
      members: [],
    },
  ],
};

export const formSchema = yup.object().shape({
  note: yup.string().required("Note is Required"),
  subject: yup.string().required("Subject is Required"),
  // accessGroups: yup
  //   .array()
  //   // .min(1, 'Atleast 1 Address is Required')
  //   .of(
  //     yup.object().shape({
  //       group: yup.string(),
  //       members: yup.string(),
  //     })
  //   ),
});
