import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import { CardHeader, Tooltip, IconButton, Button } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import {
  Edit as EditIcon,
  Close as CloseIcon,
  Add as AddIcon,
} from "@material-ui/icons";

// Custom Components and Helpers
import Widget from "components/Widget/index";
import Auxiliary from "util/Auxiliary";
import AlertBox from "components/AlertBox/index";
import ComposeNote from "./ComposeNote";
import NoteList from "./NoteList";
import { getNoteTags } from "services/actions";

export default function ClientNotes(props) {
  const dispatch = useDispatch();
  const {
    notes,
    noteLoading,
    client,
    clientLoading,
    clientError,
  } = useSelector(({ profileStore }) => profileStore);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    dispatch(getNoteTags());
  }, []);
  return (
    <>
      <Widget styleName="jr-card-full jr-card-tabs-right jr-card-profile">
        <CardHeader title={"Notes"} />
        <div className="jr-tabs-content jr-task-list">
          {client.id && (
            <>
              {showForm && <ComposeNote onClose={() => setShowForm(false)} />}
              {!showForm && (
                <span>
                  Add a note for {client.name} ?{" "}
                  <IconButton color="primary" onClick={() => setShowForm(true)}>
                    <AddIcon />
                  </IconButton>
                </span>
              )}
            </>
          )}
        </div>
      </Widget>
      <NoteList />
    </>
  );
}
