import React, { useEffect, useState } from "react";
import { useFieldArray, Controller } from "react-hook-form";

import {
  Grid,
  TextField,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment,
  Divider,
  Container,
  Avatar,
  CardHeader,
  IconButton,
  Tooltip,
  Button,
} from "@material-ui/core";

import { Refresh, Add as AddIcon, Clear, Close } from "@material-ui/icons";

import IntlMessages from "util/IntlMessages";
import { useSelector } from "react-redux";

export default function DrugHistoryForm(props) {
  const { control, errors, register } = props;
  const { accConfig } = useSelector(({ uiConfigureStore }) => uiConfigureStore);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "drugHistory",
    keyName: "keyId", //, default to "id", you can change the key name
  });

  const onAdd = () => {
    append({
      type: "TABLET",
      name: "",
      dosage: "",
      note: "",
      current: true,
    });
  };

  const onRemove = (index) => {
    remove(index);
  };
  const onReset = (index) => {
    remove(index);
    onAdd();
    // console.log('reset');
  };
  // console.log(errors);
  console.log("accConfig", accConfig);
  return (
    <div>
      {fields.map((item, index) => {
        return (
          <div key={item.keyId}>
            <Container style={{ paddingBottom: 25 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel htmlFor="unit">
                      <IntlMessages id="medicRecord.form.drugtype" />
                    </InputLabel>
                    <Controller
                      as={
                        <Select>
                          <MenuItem value="" disabled>
                            Medical History
                          </MenuItem>
                          {accConfig.drugType &&
                            accConfig.drugType.map((val) => (
                              <MenuItem value={val.key} key={val.key}>
                                {val.value}
                              </MenuItem>
                            ))}
                        </Select>
                      }
                      name={`drugHistory[${index}].type`}
                      label={<IntlMessages id="medicRecord.form.drugtype" />}
                      control={control}
                      fullWidth
                      defaultValue={item.type}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <FormControl fullWidth margin="normal">
                    <Controller
                      label={<IntlMessages id="medicRecord.form.name" />}
                      as={TextField}
                      name={`drugHistory[${index}].name`}
                      control={control}
                      fullWidth
                      defaultValue={item.name}
                      error={
                        errors.drugHistory &&
                        errors.drugHistory[index] &&
                        errors.drugHistory[index].name
                      }
                      helperText={
                        errors.drugHistory &&
                        errors.drugHistory[index] &&
                        errors.drugHistory[index].name &&
                        errors.drugHistory[index].name.message
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={8} sm={8}>
                  <FormControl fullWidth margin="normal">
                    <Controller
                      label={<IntlMessages id="medicRecord.form.dosage" />}
                      as={TextField}
                      name={`drugHistory[${index}].dosage`}
                      control={control}
                      fullWidth
                      defaultValue={item.dosage}
                      error={
                        errors.drugHistory &&
                        errors.drugHistory[index] &&
                        errors.drugHistory[index].dosage
                      }
                      helperText={
                        errors.drugHistory &&
                        errors.drugHistory[index] &&
                        errors.drugHistory[index].dosage &&
                        errors.drugHistory[index].dosage.message
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel htmlFor="current">
                      <IntlMessages id="medicRecord.form.current" />
                    </InputLabel>
                    <Controller
                      as={Checkbox}
                      name={`drugHistory[${index}].current`}
                      defaultValue={item.current}
                      type="checkbox"
                      control={control}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={11} sm={11}>
                  <FormControl fullWidth margin="normal">
                    <Controller
                      label={<IntlMessages id="medicRecord.form.note" />}
                      as={TextField}
                      name={`drugHistory[${index}].note`}
                      control={control}
                      fullWidth
                      multiline
                      defaultValue={item.note}
                      error={
                        errors.drugHistory &&
                        errors.drugHistory[index] &&
                        errors.drugHistory[index].note
                      }
                      helperText={
                        errors.drugHistory &&
                        errors.drugHistory[index] &&
                        errors.drugHistory[index].note &&
                        errors.drugHistory[index].note.message
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={1} sm={1}>
                  <Button
                    color="secondary"
                    aria-label="Remove"
                    onClick={() => onRemove(index)}
                    size="small"
                  >
                    <Tooltip
                      title={"Remove Medication  " + (index + 1)}
                      size="large"
                    >
                      <Clear />
                    </Tooltip>
                  </Button>
                </Grid>
              </Grid>
            </Container>

            {index + 1 !== fields.length && <Divider />}
          </div>
        );
      })}
      <Tooltip title="Click to Add Complain detail">
        <Button
          aria-label="Add"
          onClick={() => onAdd()}
          variant="contained"
          color="primary"
        >
          Add More <AddIcon />
        </Button>
      </Tooltip>
    </div>
  );
}
