const inpatientFormItems = [
  {
    formFor: "admission",
    label: "Admission",
  },
  {
    formFor: "chiefComplaints",
    label: "Chief Complaints",
  },
  {
    formFor: "findings",
    label: "Findings",
  },
  {
    formFor: "illnesses",
    label: "Illnesses",
  },
  {
    formFor: "observation",
    label: "Obeservations",
  },
  {
    formFor: "clinicalrecord",
    label: "Clinical Record",
  },
  {
    formFor: "investigation",
    label: "Investigations",
  },
  {
    formFor: "imageworks",
    label: "Imaging",
  },
  {
    formFor: "labworks",
    label: "Lab Works",
  },
  {
    formFor: "diagnosis",
    label: "Diagnosis",
  },
  {
    formFor: "operation",
    label: "Operation",
  },
  {
    formFor: "discharge",
    label: "Discharge",
  },
  {
    formFor: "allergy",
    label: "Allergy",
  },
  {
    formFor: "surgery",
    label: "Surgery",
  },
  {
    formFor: "familyhistory",
    label: "Family History",
  },
  {
    formFor: "drughistory",
    label: "Medications",
  },
  {
    formFor: "personalhistory",
    label: "Personal History",
  },
  {
    formFor: "dnr",
    label: "DNR",
  },
  {
    formFor: "dailylog",
    label: "Daily Log",
  },
  {
    formFor: "interview",
    label: "Interview",
  },
  {
    formFor: "intakeOutputRecords",
    label: "Intake OutputRecords",
  },
  {
    formFor: "medicationSchedules",
    label: "Medication Schedules",
  },
  {
    formFor: "vitals",
    label: "Vitals",
  },
  {
    formFor: "bloodgroup",
    label: "Blood Group Type",
  },
  {
    formFor: "heightWeight",
    label: "Height/Weight",
  },
  {
    formFor: "oxygenSat",
    label: "O2 Saturation",
  },
  {
    formFor: "bloodpressure",
    label: "Blood Pressure",
  },
  {
    formFor: "dailynotes",
    label: "Daily Notes",
  },
  {
    formFor: "notes",
    label: " Notes",
  },
];

const getFormTitle = (formAdd, formFor, contactName) => {
  let formLabel = "Details";
  let finalLabel = "";
  inpatientFormItems.map((item) => {
    if (item.formFor === formFor) {
      formLabel = item.label;
    }
  });

  if (formAdd) {
    finalLabel = "Add " + contactName + "'s " + formLabel;
  } else {
    finalLabel = "Update " + contactName + "'s " + formLabel;
  }
  return finalLabel;
};

export default getFormTitle;
