const initialState = {
  loading: false,
  messages: [],
  errors: [],
  formerror: null,
  error: "",

  // Notes
  errorMsg: "",
  templateError: null,
  templates: [],
  onTemplateAdd: false,
  // NoteForm
  template: {},
  aboutType: "other",
  addFormTemplate: false,
  updateFormTemplate: false,
  viewTemplate: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // get client detail by Id
    case "GET_ALLTEMPLATES_FULFILLED": {
      const templates = action.payload.data;
      return {
        ...state,
        templates: templates,
        templatesLoading: false,
        templateError: null,
      };
    }
    case "GET_ALLTEMPLATES_PENDING": {
      return {
        ...state,
        templates: [],
        templatesLoading: true,
        templateError: null,
      };
    }
    case "GET_ALLTEMPLATES_REJECTED": {
      return {
        ...state,
        error: action.payload.response.data,
        templates: [],
        messages: [],
        errors: [],
        templatesLoading: false,
        templateError: action.payload.response.data.message,
      };
    }
    case "TEMPLATEFORM": {
      let formType = action.payload.formParam.formType;
      let aboutType = action.payload.formParam.aboutType;
      // console.log(action.payload);
      return {
        ...state,
        template:
          formType === "update" || formType === "view"
            ? action.payload.template
            : null,
        addFormTemplate: formType === "add" ? true : false,
        updateFormTemplate: formType === "update" ? true : false,
        viewTemplate: formType === "view" ? true : false,
        aboutType: aboutType,
      };
    }
    case "TEMPLATECLOSE": {
      // console.log(action.payload)
      return {
        ...state,
        template: null,
        addFormTemplate: false,
        updateFormTemplate: false,
        viewTemplate: false,
        aboutType: "other",
      };
    }

    // Save Inbox Notes
    case "TEMPLATE_ADD_FULFILLED": {
      const templates = [action.payload.data, ...state.templates];
      // console.log(action.payload.data);
      // console.log(templates);
      return {
        ...state,
        templates: [action.payload.data, ...state.templates],
        template: action.payload.data,
        viewTemplate: true,
        updateFormTemplate: false,
        addFormTemplate: false,
        formerror: null,
        is_saving: false,
      };
    }
    case "TEMPLATE_ADD_PENDING": {
      return {
        ...state,
        is_saving: true,
      };
    }
    case "TEMPLATE_ADD_REJECTED": {
      return {
        ...state,
        formerror: action.payload.response.data,
        is_saving: false,
        messages: [],
        errors: [],
      };
    }

    // Update Contacts
    case "TEMPLATE_UPDATE_FULFILLED": {
      const template = action.payload.data;

      return {
        ...state,
        templates: state.templates.map((item) =>
          item.id === template.id ? template : item
        ),
        template: template,
        viewTemplate: true,
        updateFormTemplate: false,
        addFormTemplate: false,
        formerror: null,
        is_saving: false,
      };
    }
    case "TEMPLATE_UPDATE_PENDING": {
      return {
        ...state,
        is_saving: true,
      };
    }
    case "TEMPLATE_UPDATE_REJECTED": {
      return {
        ...state,
        formerror: action.payload.response.data,
        messages: [],
        errors: [],
        is_saving: false,
      };
    }

    // DELETE Contacts
    case "TEMPLATE_DELETE_FULFILLED": {
      let template = action.payload.data;
      // template.status = "DELETE";
      return {
        ...state,
        templates: state.templates.map((item) =>
          item.id === template.id ? template : item
        ),
        template: {},
        viewTemplate: false,
        updateFormTemplate: false,
        addFormTemplate: false,
        formerror: null,
        is_saving: false,
      };
    }

    case "TEMPLATE_RESTORE": {
      return initialState;
    }
    default:
      return state;
  }
};
