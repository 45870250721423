import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from "react-hook-form";

import {
  Grid,
  TextField,
  InputAdornment,
  Container,
  InputLabel,
} from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";

import { TagInput, EditorInput } from "util/formInput";
import CKEditor from "react-ckeditor-component";
// Utilities and helpers
import IntlMessages from "util/IntlMessages";

export default function TemplateSubjectForm(props) {
  const {
    control,
    errors,
    register,
    formRole,
    setValue,
    getValues,
    updateFormTemplate,
    template,
  } = props;

  const values = getValues({ nest: true });

  const [content, setContent] = useState(values.template);

  useEffect(() => {
    setValue("template", content, { shouldValidate: true });
  }, [content]);

  return (
    <div>
      <Container style={{ paddingBottom: 25 }}>
        <Grid container spacing={1} direction="row">
          <Grid item xs={12}>
            <Controller
              as={TextField}
              label={<IntlMessages id="template.form.name" />}
              name="name"
              control={control}
              fullWidth
              error={errors.name}
              helperText={errors.name && errors.name.message}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              as={TextField}
              label={<IntlMessages id="template.form.subject" />}
              name="subject"
              control={control}
              fullWidth
              error={errors.subject}
              helperText={errors.subject && errors.subject.message}
              margin="normal"
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
