import React from "react";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField, Chip } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";

import Widget from "components/Widget";
import { toRegularString, objectKeysToRegular, displayDate } from "util/helpers";
import { FormatUnderlined } from "@material-ui/icons";

export default function SearchBar(props) {
  //   on Type Change
  const handleChange = (val) => {
    var selected = [...props.selectedLists];
    let found = selected.find((item) => item.value === val.value);
    if (found) {
      selected = selected.filter((item) => item.value !== val.value);
    } else {
      selected.push(val);
    }
    props.setSelectedLists(selected);
  };
  //   const handleChange1 = (val) => {
  //     props.setSelectedLists(val);
  //   };
  const isSelected = (val) => {
    let found = props.selectedLists.find((item) => item.value === val.value);
    return found ? true : false;
  };
  return (
    <Widget styleName="jr-card-full jr-card-tabs-right jr-card-profile">
      <div className="manage-margin d-flex flex-wrap jr-tabs-content jr-task-list jr-wall-content">
        {props.options.map((data) => {
          return (
            <Chip
              label={toRegularString(data.label)}
              key={data.id}
              onClick={() => handleChange(data)}
              onDelete={() => handleChange(data)}
              deleteIcon={<DoneIcon />}
              color={"primary"}
              variant={isSelected(data) ? "default" : "outlined"}
              style={{ marginTop: "5px" }}
            />
          );
        })}
      </div>

      {/* <Autocomplete
        multiple
        options={props.options}
        getOptionLabel={(option) => option.label}
        value={props.selectedLists}
        // onInputChange={handleChange1}
        onChange={(event, newValue) => handleChange1(newValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Search"
            fullWidth
          />
        )}
      /> */}
    </Widget>
  );
}
