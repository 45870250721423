import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components and Helpers
import Auxiliary from "util/Auxiliary";
// import RecentItems from './Component/RecentItems';

import { displayDate } from "util/helpers";
// Actions
import { getInPatientDetailById } from "redux/actions/MedicalActions";
import { setProfileTitle } from "redux/actions/Setting";
// Components
import MedicRecordModal from "./InPatientForm/index";
import Admission from "./InpatientDetail/Admission/index";
import ClinicalRecords from "./InpatientDetail/ClinicalRecords/index";
import Investigation from "./InpatientDetail/Investigation/index";
import Diagnosis from "./InpatientDetail/Diagnosis/index";
import Operation from "./InpatientDetail/Operation/index";
import Discharge from "./InpatientDetail/Discharge/index";

// Inpatient detail
export default function InpatientDetail(props) {
  const dispatch = useDispatch();
  // const { contact } = useSelector(({ contactStore }) => contactStore);
  const { inPatient, addInPatientModal, updateInPatientModal } = useSelector(
    ({ medRecordStore }) => medRecordStore
  );

  useEffect(() => {
    let inpatientId = props.match.params.inpatientId;
    if (inpatientId !== inPatient.inpatientId) {
      dispatch(getInPatientDetailById(inpatientId));
    }
    dispatch(setProfileTitle({ id: 2, title: "Inpatient Care" }));
  }, []);

  return (
    <Auxiliary>
      {/* <div className="timeline-section  timeline-center  clearfix animated slideInUpTiny animation-duration-3"> */}
      <div className="timeline-section clearfix animated slideInUpTiny animation-duration-3">
        <Admission />
        <ClinicalRecords inverted={false} />
        <Investigation />
        <Diagnosis />
        <Operation />
        <Discharge />
      </div>

      {(addInPatientModal || updateInPatientModal) && <MedicRecordModal />}
    </Auxiliary>
  );
}
