import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Divider,
} from "@material-ui/core";

import CustomScrollbars from "util/CustomScrollbars";
// Actions

const ContactLists = (props) => {
  const { width } = useSelector(({ uiConfigureStore }) => uiConfigureStore);
  const {
    step,
    formVals,
    setFormVals,
    activeStep,
    setActiveStep,
    filtered,
  } = props;

  const [values, setValues] = useState(formVals.clientIds);

  useEffect(() => {
    let formValues = { ...formVals, clientIds: values };
    setFormVals(formValues);
  }, [values]);

  useEffect(() => {
    // let formValues = { ...formVals, clientIds: values };
    setValues(formVals.clientIds);
  }, [formVals.clientIds]);

  const handleChange = (val) => {
    var vals = [];
    if (values.includes(val)) {
      vals = values.filter((item) => item !== val);
    } else {
      vals = [...values, val];
    }
    setValues(vals);
  };

  // console.log(formVals);
  // console.log(values);

  return (
    <>
      <CustomScrollbars
        style={{
          height: width >= 1200 ? "calc(60vh - 200px)" : "calc(60vh - 80px)",
        }}
      >
        <FormControl component="fieldset">
          <FormGroup>
            {filtered.map((client) => {
              return (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.includes(client.clientId)}
                        onChange={() => handleChange(client.clientId)}
                        value={client.clientId}
                      />
                    }
                    label={
                      <div className="py-2">
                        <strong>{client.name}</strong>
                        <br />
                        {client.phone}
                        {client.phone && client.email && <span>, </span>}
                        <span className="phone d-inline-block ">
                          {client.email}
                        </span>
                      </div>
                    }
                  />
                  <Divider />
                </>
              );
            })}
          </FormGroup>
        </FormControl>
      </CustomScrollbars>
      {/* </div> */}
    </>
  );
};

export default ContactLists;
