import React from "react";

import { useDispatch, useSelector } from "react-redux";

// Vendors Components
import {
  Paper,
  CardContent,
  Tooltip,
  IconButton,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
} from "@material-ui/core";

import {
  Edit as EditIcon,
  CheckCircle as CheckCircleIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
} from "@material-ui/icons";

// Themes Components
import Auxiliary from "util/Auxiliary";
import Widget from "components/Widget/index";
import AlertBox from "components/AlertBox/index";

// Custom Components and Helpers
import { getDefaultUnit, getValuefromLookup, displayDate } from "util/helpers";
// Actions
import { onInPatientForm } from "redux/actions/MedicalActions";

const ClinicalExam = (props) => {
  const dispatch = useDispatch();

  const { accConfig, accountDefaultUnits } = useSelector(
    ({ configStore }) => configStore
  );
  const { inPatient } = useSelector(({ medRecordStore }) => medRecordStore);

  // on Add History
  const onUpdate = (formType = "clinicalrecord") => {
    dispatch(onInPatientForm("update", formType));
  };
  return (
    <Paper className="jr-tabs-content jr-task-list">
      {!inPatient.clinicalRecord.clinicalExam && (
        <AlertBox
          infotype="danger"
          message="No Clinical Info was Found."
          onClick={onUpdate}
        />
      )}
      <List component="nav" aria-label="Observations">
        <ListItem button>
          <ListItemText
            primary={<strong>Clinical Exam / Vitals</strong>}
            secondary={
              <small>
                {displayDate(
                  inPatient.clinicalRecord.clinicalExam.height.dateTimeDisplay,
                  "datetime"
                )}
              </small>
            }
          />
          <Tooltip title="Update About Info ">
            <IconButton
              aria-label="Add"
              onClick={() => onUpdate()}
              variant="contained"
              color="primary"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </ListItem>
      </List>
      {/* <h3> */}
      {/* </h3> */}

      {inPatient.clinicalRecord.clinicalExam && (
        <CardContent>
          <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
            <div className="mr-3">
              <i className={`zmdi zmdi-alert-octagon zmdi-hc-2x text-black`} />
            </div>
            <div className="media-body">
              <h2 className="mb-1 text-black">
                {getValuefromLookup(
                  accConfig.consciousnessLevel,
                  inPatient.clinicalRecord.clinicalExam.consciousnessLevel
                )}
              </h2>
              <p>{inPatient.clinicalRecord.clinicalExam.note}</p>
            </div>
          </div>

          <Grid container spacing={4}>
            {/* Height */}
            {inPatient.clinicalRecord.clinicalExam.height && (
              <Grid item xs>
                <Auxiliary>
                  <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
                    <div className="mr-3">
                      <i
                        className={`zmdi zmdi-format-valign-top jr-fs-xlxl text-orange`}
                      />
                    </div>
                    <div className="media-body">
                      <h6 className="mb-1 text-grey">Height </h6>
                      {
                        inPatient.clinicalRecord.clinicalExam.height.heightText
                      }{" "}
                      {accountDefaultUnits.heightUnit}
                    </div>
                  </div>
                </Auxiliary>
              </Grid>
            )}

            {/* Weight */}
            {inPatient.clinicalRecord.clinicalExam.weight && (
              <Grid item xs>
                <Auxiliary>
                  <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
                    <div className="mr-3">
                      <i
                        className={`zmdi zmdi-space-bar jr-fs-xlxl text-orange`}
                      />
                    </div>
                    <div className="media-body">
                      <h6 className="mb-1 text-grey">Weight </h6>
                      {accountDefaultUnits.weightUnit === 'kg' ? inPatient.clinicalRecord.clinicalExam.weight.weight : inPatient.clinicalRecord.clinicalExam.weight.weightInLbs}{" "}

                      {" "}
                      {accountDefaultUnits.weightUnit}
                    </div>
                  </div>
                </Auxiliary>
              </Grid>
            )}
            {inPatient.clinicalRecord.clinicalExam.temperature && (
              <Grid item xs>
                <Auxiliary>
                  <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
                    <div className="mr-3">
                      <i
                        className={`zmdi zmdi-space-bar jr-fs-xlxl text-orange`}
                      />
                    </div>
                    <div className="media-body">
                      <h6 className="mb-1 text-grey">Pulse </h6>
                      {
                        inPatient.clinicalRecord.clinicalExam.temperature
                          .temperature
                      }{" "}
                    </div>
                  </div>
                </Auxiliary>
              </Grid>
            )}
          </Grid>
          <Grid container spacing={4}>
            {inPatient.clinicalRecord.clinicalExam.oxygenSaturation && (
              <Grid item xs>
                <Auxiliary>
                  <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
                    <div className="mr-3">
                      <i
                        className={`zmdi zmdi-circle-o jr-fs-xlxl text-orange`}
                      />
                    </div>
                    <div className="media-body">
                      <h6 className="mb-1 text-grey">Oxygen Saturation </h6>
                      {
                        inPatient.clinicalRecord.clinicalExam.oxygenSaturation
                          .oxygenSaturation
                      }{" "}
                    </div>
                  </div>
                </Auxiliary>
              </Grid>
            )}
            {inPatient.clinicalRecord.clinicalExam.pulse && (
              <Grid item xs>
                <Auxiliary>
                  <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
                    <div className="mr-3">
                      <i
                        className={`zmdi zmdi-space-bar jr-fs-xlxl text-orange`}
                      />
                    </div>
                    <div className="media-body">
                      <h6 className="mb-1 text-grey">Pulse </h6>
                      {inPatient.clinicalRecord.clinicalExam.pulse.pulse}
                    </div>
                  </div>
                </Auxiliary>
              </Grid>
            )}

            {inPatient.clinicalRecord.clinicalExam.respiratoryRate && (
              <Grid item xs>
                <Auxiliary>
                  <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
                    <div className="mr-3">
                      <i
                        className={`zmdi zmdi-space-bar jr-fs-xlxl text-orange`}
                      />
                    </div>
                    <div className="media-body">
                      <h6 className="mb-1 text-grey">Respiratory Rate </h6>
                      {
                        inPatient.clinicalRecord.clinicalExam.respiratoryRate
                          .respiratoryRate
                      }{" "}
                    </div>
                  </div>
                </Auxiliary>
              </Grid>
            )}
          </Grid>
          <Grid container spacing={4}>
            {inPatient.clinicalRecord.clinicalExam.bloodPressure && (
              <Grid item xs>
                <Auxiliary>
                  <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
                    <div className="mr-3">
                      <i
                        className={`zmdi zmdi-space-bar jr-fs-xlxl text-orange`}
                      />
                    </div>
                    <div className="media-body">
                      <h6 className="mb-1 text-grey">Blood Pressure </h6>
                      {
                        inPatient.clinicalRecord.clinicalExam.bloodPressure
                          .systolicPressure
                      }
                      {" / "}
                      {
                        inPatient.clinicalRecord.clinicalExam.bloodPressure
                          .diastolicPressure
                      }
                    </div>
                  </div>
                </Auxiliary>
              </Grid>
            )}
          </Grid>
        </CardContent>
      )}
    </Paper>
  );
};
export default ClinicalExam;
