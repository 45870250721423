// get visitRecord list by Patient Id

import { api } from "util/api";

// MedRecordStore
export function onAddObservationForm(formtype = "add", formFor = null) {
  return (dispatch) => {
    return dispatch({
      type: "OBSERVATION_FORM",
      payload: { formtype: formtype, formFor: formFor },
    });
  };
}

// toggle patient visit details modal
// MedRecordStore
export function onObservationFormClose() {
  return (dispatch) => {
    return dispatch({
      type: "OBSERVATION_FORMCLOSE",
    });
  };
}

// On visit record Save
export function onObservationRecordSave(value) {
  return (dispatch) => {
    return dispatch({
      type: "OBSERVATION_RECORD_SAVE",
      payload: api.post("visit/clinical", value),
    });
  };
}
