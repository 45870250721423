import React, { useEffect, useState } from "react";
import { useFieldArray, Controller } from "react-hook-form";

import {
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Container,
  Tooltip,
  IconButton,
  Button,
} from "@material-ui/core";

import { Add as AddIcon, Close as CloseIcon } from "@material-ui/icons";

import { DateTimeInput } from "util/formInput";

import IntlMessages from "util/IntlMessages";

export default function FindingForm(props) {
  const { control, setValue, getValues, errors, accConfig, register } = props;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "clinicalRecord.findings",
    keyName: "keyId", //, default to "id", you can change the key name
  });

  const onAdd = () => {
    append({});
  };

  const onRemove = (index) => {
    remove(index);
  };
  useEffect(() => {
    if (fields.length === 0) {
      onAdd();
    }
  }, []);
  return (
    <Container>
      {fields.map((item, index) => {
        return (
          <Grid container spacing={2} key={item.keyId}>
            <Grid item xs={11}>
              <FormControl fullWidth margin="normal">
                <Controller
                  label={
                    <IntlMessages id="inpatient.form.clinicalRecord.findings" />
                  }
                  as={TextField}
                  name={`clinicalRecord.findings[${index}]`}
                  control={control}
                  multiline
                  fullWidth
                  error={
                    errors.clinicalRecord &&
                    errors.clinicalRecord.findings &&
                    errors.clinicalRecord.findings[index]
                  }
                  helperText={
                    errors.clinicalRecord &&
                    errors.clinicalRecord.findings &&
                    errors.clinicalRecord.findings[index] &&
                    errors.clinicalRecord.findings[index].message
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                aria-label="Delete"
                onClick={() => onRemove(index)}
                variant="contained"
                color="secondary"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        );
      })}

      <Tooltip title="Click to Add Finding detail">
        <Button
          aria-label="Add"
          onClick={() => onAdd()}
          variant="contained"
          color="primary"
        >
          Add More <AddIcon />
        </Button>
      </Tooltip>
    </Container>
  );
}
