import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from 'react-redux';

// Vendors Components
import { CardHeader, IconButton, CardContent } from '@material-ui/core';


import {
    Area, AreaChart, Bar, BarChart, ResponsiveContainer,
    Legend,
    Tooltip as TooltipChart, XAxis, LabelList, YAxis, ReferenceLine, Brush
} from 'recharts';

import ReportBox from 'components/ReportBox';

import Widget from "components/Widget";
import ChartCard from 'components/dashboard/Common/ChartCard';

import IntlMessages from "util/IntlMessages";
import { getValuefromLookup, getDefaultUnit, displayDate } from "util/helpers";


const OSaturation = (props) => {
    const dispatch = useDispatch();
    const { contact } = useSelector(({ contactStore }) => contactStore);
    const { medicalrecord } = useSelector(({ medRecordStore }) => medRecordStore);
    const [data, setData] = useState([]);

    useEffect(() => {
        onO2Data();
    }, [medicalrecord]);

    const onO2Data = () => {
        let allDatalist = [];
        allDatalist = medicalrecord.oxygenSaturationHistory.map((val) => {
            val.dateDisplay = displayDate(val.dateTimeDisplay, "datetimeR");
            return val;
        });

        setData(allDatalist.reverse());
    };

    return (
        <div>
            <Widget styleName="jr-card-full jr-card-tabs-right jr-card-profile">
                <CardHeader
                    title="Oxygen Saturation in %"
                />
                <CardContent>
                    <ResponsiveContainer className="card-img-bottom overflow-hidden" width="100%" height={300}>
                        <AreaChart data={data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                            <XAxis
                                dataKey="dateDisplay"
                                padding={{ left: 60, right: 30 }}
                            />
                            <YAxis padding={{ top: 30 }} />
                            <TooltipChart />
                            <Legend />
                            <ReferenceLine y={0} stroke="#000" />

                            {/* <Tooltip /> */}
                            <defs>
                                <linearGradient id="orders" x1="0" y1="0" x2="1" y2="0">
                                    <stop offset="5%" stopColor="#3f51b5" stopOpacity={1} />
                                    <stop offset="95%" stopColor="#ff4081" stopOpacity={1} />
                                </linearGradient>
                            </defs>
                            <Area type="monotone" dataKey="oxygenSaturation" strokeWidth={0} stroke="#C24590" fillOpacity={1}
                                fill="url(#orders)"
                                name={'SaO2 in %'}
                            >
                                <LabelList dataKey="oxygenSaturation" position="top" />
                            </Area>
                        </AreaChart>
                    </ResponsiveContainer>
                </CardContent>
            </Widget>
        </div>
    );
};
export default OSaturation;
