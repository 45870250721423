import React, { useEffect, useState } from "react";

import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";

// patient  detail
export default function ClinicalReport(props) {
  const { contact, inPatient, styles } = props;
  const chiefComplaints =
    inPatient.clinicalRecord && inPatient.clinicalRecord.chiefComplaints;
  const clinicalExam =
    inPatient.clinicalRecord && inPatient.clinicalRecord.clinicalExam;
  const findings =
    inPatient.clinicalRecord && inPatient.clinicalRecord.findings;
  const prescriptions =
    inPatient.discharge && inPatient.discharge.prescriptions;
  const pastillness =
    inPatient.clinicalRecord && inPatient.clinicalRecord.illnesses;
  // console.log(findings);
  return (
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <View style={[styles.tableColHeader, { width: "100%" }]}>
          <Text style={styles.tableCellHeader}>CLINICAL NOTES :</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableColLeft, { width: "20%" }]}>
          <Text style={styles.tableCellHeader}>Chief Complaints :</Text>
        </View>
        <View style={[styles.tableColLeft, { width: "80%" }]}>
          {chiefComplaints &&
            chiefComplaints.length !== 0 &&
            chiefComplaints.map((val, index) => {
              return (
                <Text style={styles.tableCellLeft} key={index}>
                  {val}
                </Text>
              );
            })}
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableColLeft, { width: "20%" }]}>
          <Text style={styles.tableCellHeader}>History Present Illness :</Text>
        </View>
        <View style={[styles.tableColLeft, { width: "80%", fontSize: 8 }]}>
          {pastillness &&
            pastillness.length !== 0 &&
            pastillness.map((val, index) => {
              if (!val.current) {
                return (
                  <Text style={styles.tableCellLeft} key={index}>
                    {val.name} - {val.note}
                  </Text>
                );
              }
            })}
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableColLeft, { width: "20%" }]}>
          <Text style={styles.tableCellHeader}>History Past Illness :</Text>
        </View>
        <View style={[styles.tableColLeft, { width: "80%" }]}>
          {pastillness &&
            pastillness.length !== 0 &&
            pastillness.map((val, index) => {
              if (val.current) {
                return (
                  <Text style={styles.tableCellLeft} key={index}>
                    {val.name} - {val.note}
                  </Text>
                );
              }
            })}
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableColLeft, { width: "20%" }]}>
          <Text style={styles.tableCellHeader}>Clinical Examination :</Text>
        </View>
        <View style={[styles.tableColLeft, { width: "80%" }]}>
          <Text style={styles.tableCellLeft}>
            {"VITALS : P" + clinicalExam.pulse.pulse + "/MIN,"}
            {" BP" +
              clinicalExam.bloodPressure.systolicPressure +
              "/" +
              clinicalExam.bloodPressure.diastolicPressure +
              "MMHG"}
          </Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableColHeader, { width: "100%" }]}>
          <Text style={styles.tableCell}>&nbsp;</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableColHeader, { width: "100%" }]}>
          <Text style={styles.tableCellHeader}>FINDINGS :</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableColLeft, { width: "100%" }]}>
          {findings &&
            findings.length !== 0 &&
            findings.map((val, index) => {
              return (
                <Text style={styles.tableCellLeft} key={index}>
                  {val}
                </Text>
              );
            })}
          {!findings && <Text style={styles.tableCellLeft}>&nbsp;</Text>}
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableColHeader, { width: "100%" }]}>
          <Text style={styles.tableCell}>&nbsp;</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableColHeader, { width: "100%" }]}>
          <Text style={styles.tableCell}>&nbsp;</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableColHeader, { width: "100%" }]}>
          <Text style={styles.tableCellHeader}>
            ADVICE ON DISCHARGE / PRESCRIPTION / OTHERS :
          </Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableColLeft, { width: "100%" }]}>
          <Text style={styles.tableCellLeft}>Prescriptions :</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableColLeft, { width: "100%" }]}>
          {prescriptions &&
            prescriptions.length !== 0 &&
            prescriptions.map((val, index) => {
              return (
                <Text style={styles.tableCellLeft} key={index}>
                  {val.name + " " + val.dosage + " " + val.note}
                </Text>
              );
            })}
          {!prescriptions && <Text style={styles.tableCellLeft}>&nbsp;</Text>}
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableColHeader, { width: "100%" }]}>
          <Text style={styles.tableCellHeader}>FOLLOW UP :</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableColLeft, { width: "100%" }]}>
          <Text style={styles.tableCellLeft}>
            {inPatient.discharge && inPatient.discharge.adviceNote}
          </Text>
          <Text style={styles.tableCellLeft}>
            {inPatient.discharge && inPatient.discharge.followupNote}
          </Text>
        </View>
      </View>
    </View>
  );
}
