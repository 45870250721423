import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// components
import { Avatar, Chip } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import NavBar from "./NavBar";

import { getColor, CrudNotification } from "util/helpers";
import ClientTags from "./ClientTags";
import { onClientUpdate, getClientTags } from "services/actions";

const ProfileHeader = () => {
  const dispatch = useDispatch();
  const { client, clientLoading, clientError } = useSelector(
    ({ profileStore }) => profileStore
  );
  useEffect(() => {
    dispatch(getClientTags());
  }, []);

  const onUpdateTags = (tags) => {
    if (tags.length > 0) {
      let values = {
        id: client.id,
        tags: tags,
      };
      return dispatch(onClientUpdate(values)).then((res) => {
        dispatch(getClientTags());
        CrudNotification("success", "Contacts Tags added Successfully !");
      });
    }
  };

  return (
    <div className="jr-profile-banner">
      <div className="jr-profile-container">
        <div className="jr-profile-banner-top">
          <div className="jr-profile-banner-top-left">
            <div className="jr-profile-banner-avatar">
              {clientLoading || !client.id ? (
                <>
                  <Avatar className="size-90">
                    <div class="spinner-border" role="status"></div>
                  </Avatar>
                  {/* <Skeleton variant="circle" width={40} height={40} /> */}
                </>
              ) : (
                <Avatar
                  className="size-90"
                  alt={client.name}
                  style={{
                    fontSize: 36,
                    backgroundColor: getColor(client.name),
                  }}
                >
                  {client.name.charAt(0).toUpperCase()}
                </Avatar>
              )}
            </div>
            <div className="jr-profile-banner-avatar-info">
              {!client.id && (
                <Skeleton
                  animation="wave"
                  height={100}
                  width={210}
                  style={{ backgroundColor: "grey" }}
                />
              )}
              {!clientLoading && client && client.name && (
                <>
                  <h2 className="mb-2 jr-mb-sm-3 jr-fs-xxl jr-font-weight-light">
                    {client.name}
                  </h2>
                  <p className="mb-0 jr-fs-sm">{client.phone}</p>
                  <p className="mb-0 jr-fs-sm">{client.email}</p>
                </>
              )}
              <div
                className="manage-margin d-flex flex-wrap"
                style={{ width: "100%" }}
              >
                {!clientLoading && client && client.name && (
                  <ClientTags
                    margin="normal"
                    label="Add Tags"
                    tags={client.tags ? client.tags : []}
                    setTags={onUpdateTags}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="jr-profile-banner-top-right">
            <ul className="jr-follower-list">
              {/* <li>
                <span className="jr-follower-title jr-fs-lg jr-font-weight-medium">
                  2k+
                </span>
                <span className="jr-fs-sm">Followers</span>
              </li>
               */}
            </ul>
          </div>
        </div>
        <div
          className="jr-profile-banner-bottom"
          style={{ marginBottom: "25px" }}
        >
          <NavBar />
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;
