import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";

import {
  Avatar,
  List,
  ListItem,
  Divider,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
} from "@material-ui/core";

import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Badge } from "reactstrap";

import { onContactForm, getClientById, onClientDelete } from "services/actions";

import {
  CrudNotification,
  getColor,
  saveRecentItemLocalStorage,
} from "util/helpers";

const ContactCell = (props) => {
  const dispatch = useDispatch();
  const { width, accConfig } = useSelector(
    ({ uiConfigureStore }) => uiConfigureStore
  );
  const { loggedUser } = useSelector(({ auth }) => auth);
  const { contactLoading } = useSelector(({ contactStore }) => contactStore);
  const { contact, addFavourite, onContactSelect, contactId, setContactId } =
    props;

  const { name, email, phone, starred, clientId, tags } = contact;

  const options = ["Edit", "Delete"];

  const onDeleteContact = (contact) => {
    contact.status = contact.status === "ACTIVE" ? "DELETED" : "ACTIVE";
    return dispatch(onClientDelete(contact)).then((res) => {
      CrudNotification(
        "success",
        `Contacts ${
          contact.status === "ACTIVE" ? "Active" : "Deleted"
        } Successfully !`
      );
    });
  };
  // on UpdateContact
  const onEditContact = (contact) => {
    dispatch(onContactForm(contact, "update"));
  };
  // On Contact View
  const onContactViewPage = (item) => {
    setContactId(item.id);
    saveRecentItemLocalStorage(item, loggedUser.accountId + "recentContacts");
    // dispatch(getClientById(item.clientId)).then((res) => {
    props.history.push(
      "/app/viewPatient/" +
        // "KE8I45HP"
        item.clientId
    );
  };
  return (
    <>
      <ListItem alignItems="flex-start" button disabled={contactLoading}>
        <ListItemIcon className="d-none d-sm-flex">
          <IconButton
            color="primary"
            className="size-30"
            onClick={() => {
              addFavourite(contact);
            }}
          >
            {starred ? (
              <i className="zmdi zmdi-star" />
            ) : (
              <i className="zmdi zmdi-star-outline" />
            )}
          </IconButton>
        </ListItemIcon>
        <ListItemAvatar>
          <div className="progress-wrapper">
            <Avatar style={{ fontSize: 16, backgroundColor: getColor(name) }}>
              {name.charAt(0).toUpperCase()}
            </Avatar>
            {contactId === contact.id && (
              <CircularProgress size={40} className="progress-avatar" />
            )}
          </div>
        </ListItemAvatar>
        <ListItemText
          onClick={() => onContactViewPage(contact)}
          primary={
            <>
              <span className="text-truncate contact-name jr-link">
                {name} &nbsp; &nbsp;
              </span>
              <span className="text-truncate job-title">
                {/* <Badge color="secondary">
                  {getValuefromLookup(accConfig.clientRole, contact.role)}
                  &nbsp;/&nbsp;{clientId}
                </Badge> */}
              </span>
            </>
          }
          secondary={
            <>
              {phone}
              {phone && email && <span>, </span>}
              <span className="phone d-inline-block ">{email}</span>
              {(phone || email) && <br />}
              {tags &&
                tags.length > 0 &&
                tags.map((tag, index) => {
                  return (
                    <div
                      key={index}
                      className={`badge text-white `}
                      style={{ backgroundColor: getColor(tag) }}
                    >
                      {tag}
                    </div>
                  );
                })}
            </>
          }
        />
        <ListItemSecondaryAction
          // className="col-auto px-1 actions d-none d-sm-flex"
          className="col-auto px-1 actions "
        >
          <IconButton
            color="primary"
            className="size-30  d-none  dd-none-sm"
            onClick={() => {
              addFavourite(contact);
            }}
          >
            {starred ? (
              <i className="zmdi zmdi-star" />
            ) : (
              <i className="zmdi zmdi-star-outline" />
            )}
          </IconButton>
          <IconButton
            className="size-30 d-none d-sm-flex"
            color="primary"
            onClick={() => onEditContact(contact)}
          >
            <i className="zmdi zmdi-edit" />
          </IconButton>
          <IconButton
            className="size-30  d-none d-sm-flex"
            color="secondary"
            onClick={() => onDeleteContact(contact)}
          >
            <i className="zmdi zmdi-delete" />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider component="li" />
    </>
  );
};

export default withRouter(ContactCell);
