import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@material-ui/core";

import { onTogRestrictWarning } from "services/actions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RestrictionDialog() {
  const dispatch = useDispatch();

  const { restrictModalFor, togRestrictModal, restrictMessage } = useSelector(
    ({ uiConfigureStore }) => uiConfigureStore
  );

  const handleClose = () => {
    // setOpen(false);
    dispatch(onTogRestrictWarning(false));
  };

  return (
    <Dialog
      open={togRestrictModal}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      maxWidth="md"
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {"Limited Use Reached "}
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="alert-dialog-slide-description">
          {restrictMessage}
          <br />
          Please upgrade your Subscription.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
}
