import React from "react";

import moment from "moment";
// Vendors Components
import { Tooltip, Button } from "@material-ui/core";
import { AccountCircle, Add as AddIcon } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";

//  Themes Components
// Custom Components and Helpers

const NoDetail = (props) => {
  const { loading, title, onAddInpatientRecord } = props;
  return (
    <div>
      <div className={`timeline-badge bg-green`}>
        <AccountCircle />
      </div>
      <div className="timeline-panel bg-light">
        <h3 className={`timeline-tile text-green`}>
          {moment().format("YYYY-MM-DD")}
        </h3>
        {!loading && (
          <div className="text-center">
            <h3 className="jr-font-weight-medium mb-3">{title}</h3>
            <p className="mb-3">No {title} Available.</p>
            <Tooltip title={"Add " + title} aria-label={"Add " + title}>
              <Button
                aria-label="Add"
                onClick={() => onAddInpatientRecord()}
                variant="contained"
                color="primary"
              >
                Add <AddIcon />
              </Button>
            </Tooltip>
          </div>
        )}
        {loading && <Skeleton variant="rect" height={100} />}
      </div>
    </div>
  );
};

export default NoDetail;
