import { api } from "util/api";

// Get All Users
// Setting Store
export function getAllUsers() {
  return (dispatch) => {
    return dispatch({
      type: "GET_ALLUSERS",
      payload: api.get("/user/all"),
    });
  };
}

// save Users Password
// Settingstore
export function saveUser(value) {
  return (dispatch) => {
    return dispatch({
      type: "SAVEUSER",
      payload: api.post("user", value),
    });
  };
}
// Update Users Password
// Settingstore
export function updateUser(value) {
  return (dispatch) => {
    return dispatch({
      type: "UPDATEUSER",
      payload: api.post("user", value),
    });
  };
}
