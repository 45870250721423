import React, { useState } from "react";
import List from "@material-ui/core/List";

import ContactCell from "./ContactCell/index";
const ContactList = ({ contactList, addFavourite, onContactSelect }) => {
  const [contactId, setContactId] = useState(null);
  // console.log(contactList);
  return (
    <List>
      {contactList.map((contact, index) => (
        <ContactCell
          {...{
            key: contact.id,
            contact,
            addFavourite,
            onContactSelect,
            contactId,
            setContactId,
          }}
        />
      ))}

      {/*  </div> */}
    </List>
  );
};

export default ContactList;
