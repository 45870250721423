const initialSettings = {
  // User Settings
  users: [],
  user: {},
  usersLoading: false,
  errors: {},
  error: {},
  formerror: null,
};

const settingStore = (state = initialSettings, action) => {
  switch (action.type) {
    // Users Setting Started
    // SettingActions
    // Get All Users
    case "GET_ALLUSERS_FULFILLED": {
      let users = action.payload.data;
      return {
        ...state,
        users: users,
        usersLoading: false,
      };
    }
    case "GET_ALLUSERS_PENDING": {
      return {
        ...state,
        users: [],
        usersLoading: true,
      };
    }
    case "GET_ALLUSERS_REJECTED": {
      return {
        ...state,
        users: [],
        usersLoading: false,
        error: action.payload.response.data,
        message: action.payload.message,
      };
    }

    // Save Password / Create New Users
    // AuthActions
    case "SAVEUSER_FULFILLED": {
      return {
        ...state,
        users: [action.payload.data, ...state.users],
        formerror: null,
      };
    }
    case "SAVEUSER_PENDING": {
      return {
        ...state,
      };
    }
    case "SAVEUSER_REJECTED": {
      return {
        ...state,
        formerror: action.payload.response.data,
        messages: [],
        errors: [],
      };
    }

    //  Update User
    case "UPDATEUSER_FULFILLED": {
      const user = action.payload.data;
      return {
        ...state,
        users: state.users.map((item) => (item.id === user.id ? user : item)),
        formerror: null,
      };
    }
    case "UPDATEUSER_PENDING": {
      return {
        ...state,
      };
    }
    case "UPDATEUSER_REJECTED": {
      return {
        ...state,
        formerror: action.payload.response.data,
        messages: [],
        errors: [],
      };
    }

    default:
      return state;
  }
};

export default settingStore;
