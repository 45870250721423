import React, { useEffect, useState } from "react";
import { useFieldArray, Controller } from "react-hook-form";

import {
  Grid,
  TextField,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment,
  Divider,
  Container,
  Avatar,
  CardHeader,
  IconButton,
  Tooltip,
  Button,
} from "@material-ui/core";

import { DateTimeInput } from "util/formInput";
import { Refresh, Add as AddIcon, Clear, Close } from "@material-ui/icons";

import IntlMessages from "util/IntlMessages";

export default function InvestImagWorkForm(props) {
  const {
    control,
    errors,
    accConfig,
    register,
    getValues,
    setValue,
    updateInPatientFor,
  } = props;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "investigation.imagingWorks",
    keyName: "keyId", //, default to "id", you can change the key name
  });

  const values = getValues({ nest: true });
  const onAdd = () => {
    append({
      date: null,
      imagingWorkItem: "",
      result: 0,
      note: "",
    });
  };

  const onRemove = (index) => {
    remove(index);
  };

  useEffect(() => {
    console.log(fields);
    console.log('sdfsdf')
    if (fields.length === 0) {
      onAdd();
    }
  }, []);

  const onReset = (index) => {
    remove(index);
    onAdd();
    // console.log('reset');
  };
  // console.log(errors);
  return (
    <div>
      {updateInPatientFor === "investigation" && (
        <CardHeader title={<h3>Imaging Works</h3>} />
      )}

      {fields.map((item, index) => {
        return (
          <div key={item.keyId}>
            <Container style={{ paddingBottom: 25 }}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <FormControl fullWidth margin="normal">
                    <DateTimeInput
                      setAgeValue={setValue}
                      value={
                        values.investigation &&
                        values.investigation.imagingWorks &&
                        values.investigation.imagingWorks[index] &&
                        values.investigation.imagingWorks[index].date
                      }
                      label="Date"
                      name={`investigation.imagingWorks[${index}].date`}
                      format="YYYY-MM-DD"
                      inputRef={register}
                      fullWidth
                      autoOk
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel htmlFor="unit">
                      <IntlMessages id="inpatient.form.investigation.imagingWorkItem" />
                    </InputLabel>
                    <Controller
                      as={
                        <Select>
                          <MenuItem value="" disabled>
                            {" "}
                            Imaging Work Item
                          </MenuItem>
                          {accConfig.imagingWork &&
                            accConfig.imagingWork.map((val) => (
                              <MenuItem value={val.key} key={val.key}>
                                {val.value}
                              </MenuItem>
                            ))}
                        </Select>
                      }
                      name={`investigation.imagingWorks[${index}].imagingWorkItem`}
                      label={
                        <IntlMessages id="inpatient.form.investigation.imagingWorkItem" />
                      }
                      control={control}
                      fullWidth
                      defaultValue={item.imagingWorkItem}
                      error={
                        errors.investigation &&
                        errors.investigation.imagingWorks &&
                        errors.investigation.imagingWorks[index] &&
                        errors.investigation.imagingWorks[index].imagingWorkItem
                      }
                      helperText={
                        errors.investigation &&
                        errors.investigation.imagingWorks &&
                        errors.investigation.imagingWorks[index] &&
                        errors.investigation.imagingWorks[index]
                          .imagingWorkItem &&
                        errors.investigation.imagingWorks[index].imagingWorkItem
                          .message
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth margin="normal">
                    <Controller
                      label={
                        <IntlMessages id="inpatient.form.investigation.result" />
                      }
                      as={TextField}
                      name={`investigation.imagingWorks[${index}].result`}
                      control={control}
                      fullWidth
                      defaultValue={item.result}
                      error={
                        errors.investigation &&
                        errors.investigation.imagingWorks &&
                        errors.investigation.imagingWorks[index] &&
                        errors.investigation.imagingWorks[index].result
                      }
                      helperText={
                        errors.investigation &&
                        errors.investigation.imagingWorks &&
                        errors.investigation.imagingWorks[index] &&
                        errors.investigation.imagingWorks[index].result &&
                        errors.investigation.imagingWorks[index].result.message
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={11} sm={11}>
                  <FormControl fullWidth margin="normal">
                    <Controller
                      label={
                        <IntlMessages id="inpatient.form.investigation.note" />
                      }
                      as={TextField}
                      name={`investigation.imagingWorks[${index}].note`}
                      control={control}
                      fullWidth
                      multiline
                      defaultValue={item.note}
                      error={
                        errors.investigation &&
                        errors.investigation.imagingWorks &&
                        errors.investigation.imagingWorks[index] &&
                        errors.investigation.imagingWorks[index].note
                      }
                      helperText={
                        errors.investigation &&
                        errors.investigation.imagingWorks &&
                        errors.investigation.imagingWorks[index] &&
                        errors.investigation.imagingWorks[index].note &&
                        errors.investigation.imagingWorks[index].note.message
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={1} sm={1}>
                  <Container>
                    <Button
                      color="secondary"
                      aria-label="Remove"
                      onClick={() => onRemove(index)}
                      size="small"
                    >
                      <Tooltip
                        title={"Remove Imaging Works  " + (index + 1)}
                        size="large"
                      >
                        <Clear />
                      </Tooltip>
                    </Button>
                  </Container>
                </Grid>
              </Grid>
            </Container>

            {index + 1 !== fields.length && <Divider />}
          </div>
        );
      })}
      <Tooltip title="Click to Add Imaging Works detail">
        <Button
          aria-label="Add"
          onClick={() => onAdd()}
          variant="contained"
          color="primary"
        >
          Add More <AddIcon />
        </Button>
      </Tooltip>
    </div>
  );
}
