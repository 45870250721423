import React, { useEffect, useState } from "react";
import { useFieldArray, Controller } from "react-hook-form";

import {
  Grid,
  TextField,
  Slider,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  InputLabel,
  InputAdornment,
  Divider,
  Container,
  Avatar,
  CardHeader,
  IconButton,
  Tooltip,
  Button,
} from "@material-ui/core";

import { DateTimeInput } from "util/formInput";
import { Refresh, Add as AddIcon, Clear, Close } from "@material-ui/icons";

import IntlMessages from "util/IntlMessages";

const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
];

export default function InterviewForm(props) {
  const { control, errors, accConfig, register, getValues } = props;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "interview.questionAnswers",
    keyName: "keyId", //, default to "id", you can change the key name
  });

  const values = getValues({ nest: true });
  const onAdd = () => {
    append({
      question: "",
      answer: 0,
    });
  };

  const onRemove = (index) => {
    remove(index);
  };

  useEffect(() => {
    if (fields.length === 0) {
      onAdd();
    }
  }, []);
  const onReset = (index) => {
    remove(index);
    onAdd();
    // console.log('reset');
  };

  // console.log(errors);
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <DateTimeInput
              value={values.interview && values.interview.time}
              label="Time"
              name="interview.time"
              inputRef={register}
              fullWidth
              type="time"
              ampm={false}
              autoOk
            />
          </FormControl>
        </Grid>
      </Grid>
      {fields.map((item, index) => {
        return (
          <div key={item.keyId}>
            <div style={{ paddingBottom: 25 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <FormControl
                    fullWidth
                    margin="normal"
                    error={
                      errors.interview &&
                      errors.interview.questionAnswers &&
                      errors.interview.questionAnswers[index] &&
                      errors.interview.questionAnswers[index].question
                    }
                  >
                    <InputLabel htmlFor="question">
                      <IntlMessages id="dailylog.form.question" />
                    </InputLabel>
                    <Controller
                      as={
                        <Select>
                          <MenuItem value="" disabled>
                            {<IntlMessages id="dailylog.form.question" />}
                          </MenuItem>
                          {accConfig.interviewQuestion &&
                            accConfig.interviewQuestion.map((val) => (
                              <MenuItem value={val.key} key={val.key}>
                                {val.value}
                              </MenuItem>
                            ))}
                        </Select>
                      }
                      name={`interview.questionAnswers[${index}].question`}
                      label={<IntlMessages id="dailylog.form.question" />}
                      control={control}
                      fullWidth
                      defaultValue={item.question}
                    />
                    <FormHelperText>
                      {errors.interview &&
                        errors.interview.questionAnswers &&
                        errors.interview.questionAnswers[index] &&
                        errors.interview.questionAnswers[index].question &&
                        errors.interview.questionAnswers[index].question
                          .message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={10} sm={8}>
                  <FormControl fullWidth margin="normal">
                    <Controller
                      name={`interview.questionAnswers[${index}].answer`}
                      control={control}
                      onChange={([, value]) => value}
                      as={
                        <Slider
                          valueLabelDisplay="auto"
                          max={5}
                          step={1}
                          marks={marks}
                        />
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={1} sm={1}>
                  <Container>
                    <Button
                      color="secondary"
                      aria-label="Remove"
                      onClick={() => onRemove(index)}
                      size="small"
                    >
                      <Tooltip
                        title={"Remove Observation Notes  " + (index + 1)}
                        size="large"
                      >
                        <Clear />
                      </Tooltip>
                    </Button>
                  </Container>
                </Grid>
              </Grid>
            </div>

            {index + 1 !== fields.length && <Divider />}
          </div>
        );
      })}

      <Tooltip title="Click to Add Observation Detail">
        <Button
          aria-label="Add"
          onClick={() => onAdd()}
          variant="contained"
          color="primary"
        >
          Add More <AddIcon />
        </Button>
      </Tooltip>
    </div>
  );
}
