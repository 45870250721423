import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  DialogActions,
  Button,
  FormControl,
  FormLabel,
  CircularProgress,
} from "@material-ui/core";

import { Alert, Spinner } from "reactstrap";
import IntlMessages from "util/IntlMessages";
import { channelsArray } from "../index";

const Confirm = (props) => {
  const dispatch = useDispatch();
  const { accConfig, districts, appUiConfig, uiTemplates } = useSelector(
    ({ uiConfigureStore }) => uiConfigureStore
  );
  const { formerror, error, is_saving } = useSelector(
    ({ messageStore }) => messageStore
  );
  const { step, formVals, setFormVals, activeStep, setActiveStep } = props;

  const handleNext = () => {
    // console.log(formVals);
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const getTemplateName = () => {
    let template = uiTemplates.find(
      (item) => item.templateId === formVals.templateId
    );
    return template.name;
  };
  const getDeliveryChannel = () => {
    let channel = channelsArray.find(
      (item) => item.key === formVals.deliveryChannel
    );
    return channel.label;
  };
  return (
    <>
      <div className="jr-tabs-content">
        <div className="stepper-tags">
          <h3>
            You Are messaging <strong> {formVals.clientIds.length}</strong>{" "}
            clients using <strong> {getTemplateName()}</strong> by{" "}
            <strong>{getDeliveryChannel()}</strong>.
          </h3>
          {is_saving && <CircularProgress />}
          {formerror && <Alert color="danger"> {formerror.message} </Alert>}
        </div>
      </div>
    </>
  );
};

export default Confirm;
