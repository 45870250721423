import React, { useEffect, useState } from "react";
import { useFieldArray, Controller } from "react-hook-form";

import {
  Grid,
  TextField,
  Checkbox,
  FormControl,
  Divider,
  Container,
  CardHeader,
  Tooltip,
  Button,
} from "@material-ui/core";

import { DateTimeInput } from "util/formInput";
import { Refresh, Add as AddIcon, Clear, Close } from "@material-ui/icons";

import IntlMessages from "util/IntlMessages";

export default function DischargeForm(props) {
  const { control, errors, accConfig, register, getValues, setValue } = props;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "discharge.prescriptions",
    keyName: "keyId", //, default to "id", you can change the key name
  });

  const values = getValues({ nest: true });
  const onAdd = () => {
    append({
      name: "",
      dosage: "",
      current: true,
      note: "",
    });
  };

  const onRemove = (index) => {
    remove(index);
  };

  useEffect(() => {
    console.log(fields);
    if (fields.length === 0) {
      onAdd();
    }
  }, []);

  const onReset = (index) => {
    remove(index);
    onAdd();
    // console.log('reset');
  };
  // console.log(errors);
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <Controller
              label={<IntlMessages id="inpatient.form.discharge.adviceNote" />}
              as={TextField}
              name={`discharge.adviceNote`}
              control={control}
              fullWidth
              multiline
              //   defaultValue={item.dosage}
              error={errors.discharge && errors.discharge.adviceNote}
              helperText={
                errors.discharge &&
                errors.discharge.adviceNote &&
                errors.discharge.adviceNote.message
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <Controller
              label={
                <IntlMessages id="inpatient.form.discharge.followupNote" />
              }
              as={TextField}
              name={`discharge.followupNote`}
              control={control}
              fullWidth
              multiline
              //   defaultValue={item.dosage}
              error={errors.discharge && errors.discharge.followupNote}
              helperText={
                errors.discharge &&
                errors.discharge.followupNote &&
                errors.discharge.followupNote.message
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <Controller
              label={
                <IntlMessages id="inpatient.form.discharge.reportPreparedBy" />
              }
              as={TextField}
              name={`discharge.reportPreparedBy`}
              control={control}
              fullWidth
              multiline
              //   defaultValue={item.dosage}
              error={errors.discharge && errors.discharge.reportPreparedBy}
              helperText={
                errors.discharge &&
                errors.discharge.reportPreparedBy &&
                errors.discharge.reportPreparedBy.message
              }
            />
          </FormControl>
        </Grid>
      </Grid>
      <Divider />
      <CardHeader title={<h3>Prescriptions</h3>} />
      {fields.map((item, index) => {
        return (
          <div key={item.keyId}>
            <Container style={{ paddingBottom: 25 }}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <FormControl fullWidth margin="normal">
                    <Controller
                      label={
                        <IntlMessages id="inpatient.form.discharge.name" />
                      }
                      as={TextField}
                      name={`discharge.prescriptions[${index}].name`}
                      control={control}
                      fullWidth
                      defaultValue={item.name}
                      error={
                        errors.discharge &&
                        errors.discharge.prescriptions &&
                        errors.discharge.prescriptions[index] &&
                        errors.discharge.prescriptions[index].name
                      }
                      helperText={
                        errors.discharge &&
                        errors.discharge.prescriptions &&
                        errors.discharge.prescriptions[index] &&
                        errors.discharge.prescriptions[index].name &&
                        errors.discharge.prescriptions[index].name.message
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth margin="normal">
                    {/* <InputLabel htmlFor="current"> */}
                    <IntlMessages id="inpatient.form.discharge.current" />
                    {/* </InputLabel> */}
                    <Controller
                      as={Checkbox}
                      name={`discharge.prescriptions[${index}].current`}
                      defaultValue={item.current}
                      type="checkbox"
                      control={control}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth margin="normal">
                    <Controller
                      label={
                        <IntlMessages id="inpatient.form.discharge.dosage" />
                      }
                      as={TextField}
                      name={`discharge.prescriptions[${index}].dosage`}
                      control={control}
                      fullWidth
                      defaultValue={item.dosage}
                      error={
                        errors.discharge &&
                        errors.discharge.prescriptions &&
                        errors.discharge.prescriptions[index] &&
                        errors.discharge.prescriptions[index].dosage
                      }
                      helperText={
                        errors.discharge &&
                        errors.discharge.prescriptions &&
                        errors.discharge.prescriptions[index] &&
                        errors.discharge.prescriptions[index].dosage &&
                        errors.discharge.prescriptions[index].dosage.message
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={11}>
                  <FormControl fullWidth margin="normal">
                    <Controller
                      label={
                        <IntlMessages id="inpatient.form.discharge.note" />
                      }
                      as={TextField}
                      name={`discharge.prescriptions[${index}].note`}
                      control={control}
                      fullWidth
                      defaultValue={item.note}
                      error={
                        errors.discharge &&
                        errors.discharge.prescriptions &&
                        errors.discharge.prescriptions[index] &&
                        errors.discharge.prescriptions[index].note
                      }
                      helperText={
                        errors.discharge &&
                        errors.discharge.prescriptions &&
                        errors.discharge.prescriptions[index] &&
                        errors.discharge.prescriptions[index].note &&
                        errors.discharge.prescriptions[index].note.message
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={1} sm={1}>
                  <Container>
                    <Button
                      color="secondary"
                      aria-label="Remove"
                      onClick={() => onRemove(index)}
                      size="small"
                    >
                      <Tooltip
                        title={"Remove Operation Notes  " + (index + 1)}
                        size="large"
                      >
                        <Clear />
                      </Tooltip>
                    </Button>
                  </Container>
                </Grid>
              </Grid>
            </Container>

            {index + 1 !== fields.length && <Divider />}
          </div>
        );
      })}

      <Tooltip title="Click to Add Operation detail">
        <Button
          aria-label="Add"
          onClick={() => onAdd()}
          variant="contained"
          color="primary"
        >
          Add More <AddIcon />
        </Button>
      </Tooltip>
    </div>
  );
}
