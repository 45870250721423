import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from "react-hook-form";

import moment from "moment";
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  FormLabel,
  InputLabel,
  FormHelperText,
  InputAdornment,
  Container,
  Avatar,
  CardHeader,
  Paper,
  Button,
  ButtonGroup,
  IconButton,
  Checkbox,
} from "@material-ui/core";

import { Face as FaceIcon, Close as CloseIcon } from "@material-ui/icons";
import IntlMessages from "util/IntlMessages";

export default function PersonalizationForm(props) {
  const { accConfig, width } = useSelector(
    ({ uiConfigureStore }) => uiConfigureStore
  );
  const { control, setValue, getValues, errors, register } = props;

  const [salutation, setSalutation] = useState("");
  const [endsWith, setEndsWith] = useState("");
  const [showInput, setShowInput] = useState(false);

  const values = getValues({ nest: true });
  const [isChecked, setIsChecked] = useState(values.status === "ACTIVE");

  const checkboxVal =
    values.personalization && values.personalization.useFirstNameOnly
      ? true
      : false;

  useEffect(() => {
    if (values.personalization && values.personalization.salutation) {
      let str = values.personalization.salutation.replace(",", "");
      str = str.trim();
      setSalutation(str);
      let hello =
        accConfig.salutation &&
        accConfig.salutation.some((item) => item.value === str);
      setShowInput(!hello);
    }
    if (values.personalization && values.personalization.endsWith) {
      setEndsWith(values.personalization.endsWith);
    }
  }, []);

  const onSalutation = (val) => {
    if (val.key !== "OTHER") {
      setShowInput(false);
      setSalutation(val.value);
      setValue("personalization.salutation", val.value);
    } else {
      setShowInput(true);
    }
  };
  const onEndsWith = (val) => {
    setValue("personalization.endsWith", val);
    setEndsWith(val);
  };

  const onChecked = (event) => {
    // console.log(event[1]);
    setIsChecked(event[1]);
    return event[1] ? "ACTIVE" : "INACTIVE";
  };

  console.log(values);

  return (
    <div style={{ paddingBottom: 25 }}>
      {!showInput && (
        <input
          ref={register}
          name="personalization.salutation"
          type="text"
          hidden
          defaultValue={values.salutation && values.personalization.salutation}
        />
      )}
      <input
        ref={register}
        name="personalization.endsWith"
        type="text"
        hidden
        defaultValue={values.personalization && values.personalization.endsWith}
      />
      <Container style={{ paddingBottom: 25 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {!showInput && (
              <FormControl fullWidth margin="normal">
                <FormLabel
                  htmlFor="salutation"
                  style={{ paddingBottom: "20px" }}
                >
                  <IntlMessages id="template.form.salutation" />
                </FormLabel>
                {/* <ButtonGroup className="btngrp" aria-label="button group"> */}
                <div
                  class="btn-group mr-2 flex-wrap"
                  role="group"
                  aria-label="First group"
                >
                  {accConfig.salutation &&
                    accConfig.salutation.map((val) => (
                      <Button
                        key={val.key}
                        onClick={() => onSalutation(val)}
                        variant={
                          salutation === val.value ? "contained" : "outlined"
                        }
                        color="primary"
                        size="large"
                        className={
                          width < 576 ? "btngrp-btn normal" : " normal"
                        }
                      >
                        {val.value}
                      </Button>
                    ))}
                </div>
                {/* </ButtonGroup> */}
              </FormControl>
            )}
            {showInput && (
              <Controller
                as={TextField}
                label={<IntlMessages id="template.form.salutation" />}
                name="personalization.salutation"
                control={control}
                fullWidth
                margin="normal"
                error={
                  errors.personalization && errors.personalization.salutation
                }
                defaultValue={
                  values.personalization && values.personalization.endsWith
                }
                helperText={
                  errors.personalization &&
                  errors.personalization.salutation &&
                  errors.personalization.salutation.message
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle Input visibility"
                        onClick={() => setShowInput(false)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal">
              <FormLabel htmlFor="endsWith" style={{ paddingBottom: "20px" }}>
                <IntlMessages id="template.form.endsWith" />
              </FormLabel>
              {/* <ButtonGroup className="btngrp" aria-label="button group"> */}
              <div
                class="btn-group mr-2 flex-wrap"
                role="group"
                aria-label="First group"
              >
                {accConfig.salutationEndsWith &&
                  accConfig.salutationEndsWith.map((val) => (
                    <Button
                      key={val.key}
                      onClick={() => onEndsWith(val.value)}
                      variant={
                        endsWith === val.value ? "contained" : "outlined"
                      }
                      color="primary"
                      size="large"
                      className={width < 576 ? "btngrp-btn" : ""}
                    >
                      {val.value}
                    </Button>
                  ))}
              </div>
              {/* </ButtonGroup> */}
            </FormControl>
          </Grid>{" "}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={3} sm={3}>
            <FormControl fullWidth margin="normal">
              <FormLabel
                htmlFor="useFirstNameOnly"
                style={{ paddingBottom: "20px" }}
              >
                <IntlMessages id="template.form.useFirstNameOnly" />
              </FormLabel>

              <Controller
                as={Checkbox}
                name={`personalization.useFirstNameOnly`}
                type="checkbox"
                defaultValue={checkboxVal}
                control={control}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={3} sm={3}>
            <FormControl fullWidth margin="normal">
              <FormLabel htmlFor="status" style={{ paddingBottom: "20px" }}>
                <IntlMessages id="template.form.status" />
              </FormLabel>

              <Controller
                as={<Checkbox />}
                name={"status"}
                type="checkbox"
                control={control}
                onChange={onChecked}
                checked={isChecked}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
