import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";

// material ui
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
} from "@material-ui/core";
import { Alert, Spinner } from "reactstrap";

// custom Components
import DialogTitle from "components/Dialog/DialogTitle";

// Actions

import {
  onClientAdd,
  onClientUpdate,
  onModalClose,
  getClientTags,
} from "../actions";
import { getDistricts, getActiveUsers, setProfile } from "services/actions";

// Utilities and helpers
import IntlMessages from "util/IntlMessages";
import {
  CrudNotification,
  datetoArray,
  timeToArray,
  getValuefromLookup,
} from "util/helpers";

import { formSchema, initContactVal } from "./formIndex";
// components
import NameForm from "./NameForm";
import PhonesFormArray from "./PhonesFormArray";
import AddressFormArray from "./AddressFormArray";
import EmailsFormArray from "./EmailsFormArray";
import CustomFormArray from "./CustomFormArray";
import PersonalDetailForm from "./PersonalDetailForm";
import WorkInfoFormArray from "./WorkInfoFormArray";
import AccessGroupsArray from "./AccessGroupsArray";

const FormTitle = (props) => {
  const { formRole, contact, updateFormModal, clientRole } = props;
  if (updateFormModal) {
    return <span>Update {contact.name}</span>;
  } else {
    return <span>Add {getValuefromLookup(clientRole, formRole)}</span>;
  }
};

const ContactForm = (props) => {
  const dispatch = useDispatch();
  const { accConfig, districts, appUiConfig, uiUsers } = useSelector(
    ({ uiConfigureStore }) => uiConfigureStore
  );
  const {
    formerror,
    contact,
    addFormModal,
    updateFormModal,
    formRole,
    is_saving,
  } = useSelector(({ contactStore }) => contactStore);

  const [initialVal, setInitialVal] = useState(
    updateFormModal ? contact : initContactVal
  );
  const [groups, setGroups] = useState([]);
  const {
    handleSubmit,
    register,
    reset,
    control,
    errors,
    setValue,
    getValues,
    watch,
  } = useForm({
    validationSchema: formSchema,
    defaultValues: initialVal,
    mode: "onBlur",
  });

  useEffect(() => {
    districts.length === 0 && dispatch(getDistricts());
    uiUsers.length === 0 && dispatch(getActiveUsers());

    dispatch(getClientTags());
  }, []);

  // On update submit
  const onSubmit = (values) => {
    const newAccessGroups = values?.accessGroups?.map((ele) => ele.group) || [];
    const newAccessValues = values;
    newAccessValues.accessGroups = newAccessGroups;

    if (values.id) {
      return dispatch(onClientUpdate(values)).then((res) => {
        CrudNotification("success", "Contacts Updated Successfully !");
      });
    } else {
      return dispatch(onClientAdd(values)).then((res) => {
        // props.getAllcontacts("roles", values.role);
        CrudNotification("success", "Contacts added Successfully !");
      });
    }
  };

  // Process form values before submitting
  const transform = (onSubmitFunc) => (data) => {
    let values = data;

    console.log("values", values);
    if (values.phones) {
      values.phones.map((phone) => {
        if (phone.countryDialCode && phone.countryDialCode.countryDialCode) {
          phone.countryDialCode = phone.countryDialCode.countryDialCode;
        } else if (
          phone.countryDialCode &&
          !phone.countryDialCode.countryDialCode
        ) {
          phone.countryDialCode = phone.countryDialCode;
        } else {
          phone.countryDialCode = "977";
        }
      });
    }
    if (values.addresses) {
      values.addresses.map((address, index) => {
        if (address.address1 === "") {
          values.addresses.splice(index);
        } else {
          if (address.countryCode && address.countryCode.countryCode) {
            address.countryCode = address.countryCode.countryCode;
          } else if (address.countryCode && !address.countryCode.countryCode) {
            address.countryCode = address.countryCode;
          } else {
            address.countryCode = "NP";
          }
          if (address.city && address.city.name) {
            address.city = address.city.name;
          } else if (address.city && !address.city.name) {
            address.city = address.city;
          } else {
            address.city = "";
          }
        }
      });
    }
    if (values.accessGroups) {
      values.accessGroups.map((accessGroup, index) => {
        if (accessGroup.group === "") {
          values.accessGroup.splice(index);
        }
      });
    }

    if (values.customFields) {
      values.customFields.map((custom, index) => {
        if (custom.customValue === "") {
          values.customFields.splice(index);
        }
      });
    }
    if (values.emails) {
      values.emails.map((email, index) => {
        if (email.email === "") {
          values.emails.splice(index);
        }
      });
    }

    if (values.personalInfo) {
      if (!values.personalInfo.dateOfBirth) {
        delete values.personalInfo.dateOfBirth;
      } else {
        values.personalInfo.dateOfBirth = values.personalInfo.dateOfBirth
          ? datetoArray(values.personalInfo.dateOfBirth)
          : null;
      }
      if (!values.personalInfo.anniversaryDate) {
        delete values.personalInfo.anniversaryDate;
      } else {
        values.personalInfo.anniversaryDate = values.personalInfo
          .anniversaryDate
          ? datetoArray(values.personalInfo.anniversaryDate)
          : null;
      }
      if (!values.personalInfo.gender) {
        delete values.personalInfo.gender;
      }
      if (!values.personalInfo.maritalStatus) {
        delete values.personalInfo.maritalStatus;
      }
    }

    // if (values.accessGroups) {
    //   values.accessGroups.map((accessGroup, index) => {
    //     if (accessGroup.group === "ALL") {
    //       values.accessGroups.splice(index);
    //     } else {
    //       if (accessGroup.members && accessGroup.members.length !== 0) {
    //         accessGroup.members = accessGroup.members.map((a) =>
    //           a.id ? a.id : a
    //         );
    //         accessGroup.members = [...new Set(accessGroup.members)];
    //         return accessGroup;
    //       }
    //     }
    //   });
    // }
    if (groups.length > 0) {
      values.accessGroups = groups.map((group) => {
        return { group: group.key };
      });
    } else {
      values.accessGroups = [{ group: "DEFAULT" }];
    }

    onSubmitFunc(values);
  };
  // on contacts modal close
  const onClose = () => {
    dispatch(onModalClose());
  };

  const val = getValues({ nest: true });
  // console.log(val);
  return (
    <Fragment>
      <DialogTitle id="contact-form" onClose={onClose}>
        <FormTitle
          updateFormModal={updateFormModal}
          contact={contact}
          formRole={formRole}
          clientRole={accConfig.clientRole}
        />
      </DialogTitle>
      <DialogContent dividers={true}>
        {formerror && <Alert color="danger"> {formerror.message} </Alert>}
        {updateFormModal && (
          <input
            ref={register}
            name="clientId"
            type="text"
            hidden
            defaultValue={contact.clientId}
          />
        )}
        {updateFormModal && (
          <input ref={register} name="id" type="text" hidden />
        )}
        <input
          ref={register}
          name="role"
          type="text"
          hidden
          defaultValue={formRole}
        />
        <NameForm
          {...{
            control,
            register,
            errors,
            formRole,
            setValue,
            getValues,
          }}
        />

        <Divider />
        <PhonesFormArray
          {...{
            control,
            register,
            getValues,
            errors,
            updateFormModal,
          }}
        />
        <Divider />
        {((appUiConfig?.contact && appUiConfig?.contact?.enableAddressOnAdd) ||
          updateFormModal) && (
          <>
            <AddressFormArray
              {...{
                watch,
                control,
                register,
                getValues,
                setValue,
                errors,
                updateFormModal,
              }}
            />
            <Divider />
          </>
        )}
        {((appUiConfig.contact && appUiConfig.contact.enableEmailOnAdd) ||
          updateFormModal) && (
          <>
            <EmailsFormArray
              {...{
                control,
                register,
                getValues,
                errors,
                updateFormModal,
              }}
            />
            <Divider />
          </>
        )}
        {((appUiConfig.contact &&
          appUiConfig.contact.enableCustomFieldsOnAdd) ||
          updateFormModal) && (
          <>
            <CustomFormArray
              {...{
                control,
                register,
                getValues,
                errors,
                updateFormModal,
              }}
            />
            <Divider />{" "}
          </>
        )}
        {((appUiConfig.contact &&
          appUiConfig.contact.enablePersonalInfoOnAdd) ||
          updateFormModal) && (
          <>
            <PersonalDetailForm
              {...{
                control,
                errors,
                register,
                getValues,
                setValue,
              }}
            />
            <Divider />{" "}
          </>
        )}
        {((appUiConfig.contact && appUiConfig.contact.enableWorkInfoOnAdd) ||
          updateFormModal) && (
          <>
            <WorkInfoFormArray
              {...{
                control,
                register,
                getValues,
                errors,
                updateFormModal,
              }}
            />
            <Divider />{" "}
          </>
        )}
        <AccessGroupsArray
          label="Share with..."
          formVals={initialVal}
          groups={groups}
          setGroups={setGroups}
        />
      </DialogContent>
      <DialogActions>
        <Button
          className="button buttonBlack"
          type="button"
          onClick={() => {
            reset(initContactVal);
          }}
        ></Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSubmit(transform(onSubmit))}
          disabled={is_saving}
        >
          {is_saving && <Spinner size="sm" />}
          <IntlMessages id="form.save" />
        </Button>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          onClick={() => onClose()}
        >
          <IntlMessages id="form.cancel" />
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default ContactForm;
