import React, { useEffect } from "react";

import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
// Pdf Header detail
export default function PageFooter(props) {
  const { styles } = props;
  return (
    <View style={styles.pageFooter} fixed>
      <View>
        {/* <Text style={styles.nepaliText}> */}
        <Text style={styles.signatureCell}>
          Please Keep this card Safe And Bring this Card in Next Visit.
        </Text>
      </View>
    </View>
  );
}
