// material ui
import { Button, DialogActions, DialogContent } from "@material-ui/core";
import PrescriptionForm from "appRoutes/Profile/MedicalRecord/MedHistory/MedicalRecordForm/ArrayForm/PrescriptionForm";
import MedicRecordForm from "appRoutes/Profile/MedicalRecord/MedHistory/MedicalRecordForm/MedicRecordForm";
import DialogTitle from "components/Dialog/DialogTitle";
import getFormTitle from "components/formTitle";
import React, { Fragment, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Spinner } from "reactstrap";
// Actions
import { onMedicFormClose } from "redux/actions/MedicalActions";
import { clearIcds } from "redux/actions/Setting";
import { CrudNotification } from "util/helpers";
import IntlMessages from "util/IntlMessages";
import { getAllVisits, onVisitClose, onVisitForm } from "../../actions";
import { onPesFormClose, onPesRecordSave } from "../../Prescriptions/actions";
// import InvestigationForm from "./InvestigationForm";
// formschema and initvalue
import {
  initMedRecordVal,
  medRecordSchema,
  medRecordSchemaAdd,
} from "./formindex";

const FormComponent = (props) => {
  const dispatch = useDispatch();
  const {
    visit,
    addPesModal,
    updatePesFormView,
    updatePesFor,
    pesformerror,
    is_saving,
  } = useSelector(({ visitStore }) => visitStore);

  const { icds, accountDefaultUnits } = useSelector(
    ({ configStore }) => configStore
  );

  const { contact } = useSelector(({ contactStore }) => contactStore);
  const {
    handleSubmit,
    register,
    reset,
    control,
    errors,
    setValue,
    getValues,
    watch,
  } = useForm({
    validationSchema: updatePesFormView ? medRecordSchema : medRecordSchemaAdd,
    defaultValues: updatePesFormView ? visit : initMedRecordVal,
    mode: "onBlur",
  });

  useEffect(() => {
    setValue("id", contact.id);
  }, []);

  const onSubmit = (values) => {
    return dispatch(onPesRecordSave(values)).then(async (res) => {
      CrudNotification("success", "Medication details added Successfully !");
      await dispatch(getAllVisits(visit?.patientId));
      await dispatch(onPesFormClose());
      await dispatch(onVisitForm(visit?.visitId, "view"));

      // await dispatch(onVisitClose());
    });
  };

  // Process form values before submitting
  const transform = (onSubmitFunc) => (data) => {
    let values = data;

    values.id = visit.id;
    // if (values.weight && accountDefaultUnits.weightUnit === "lb") {
    //   values.weight.weight = (values.weight.weight / 2.20462262185).toFixed(2);
    // }

    // if (values.height && values.height.unit === "IN") {
    //   values.height.heightText = values.height.feet + "/" + values.height.inch;
    // }

    // // if (!values.allergyHistory.allergies[0].allergy) {
    // // 	delete values.allergyHistory;
    // // }
    // // if (!values.medicalHistory.medicalHistoryRecords[0].medicalHistoryType) {
    // // 	delete values.medicalHistory;
    // // }

    // // if (!values.medicationHistory.medications[0].name) {
    // // 	delete values.medicationHistory;
    // // }

    if (values.id === "") {
      delete values.id;
    }
    // console.log(values);
    onSubmitFunc(values);
  };
  // on contacts modal close
  const onClose = () => {
    dispatch(onPesFormClose());
    // dispatch(clearIcds());
  };
  console.log("updatePesFor", updatePesFor);
  return (
    <Fragment>
      <DialogTitle id="contact-form" onClose={onClose}>
        {addPesModal
          ? "Add "
          : "Update " + contact.name + "'s " + "Prescriptions"}
        {/* {getFormTitle(addPesModal, updatePesFor, contact.name)} */}
      </DialogTitle>
      <DialogContent dividers={true}>
        {pesformerror && <Alert color="danger"> {pesformerror.message} </Alert>}

        {!updatePesFor && (
          <PrescriptionForm
            {...{
              control,
              register,
              getValues,
              errors,
              setValue,
              updatePesFor,
              watch,
            }}
          />
        )}
        {updatePesFor === "prescriptions" && (
          <PrescriptionForm
            {...{
              control,
              register,
              getValues,
              errors,
              setValue,
              updatePesFor,
              watch,
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSubmit(transform(onSubmit))}
          disabled={is_saving}
        >
          {is_saving && <Spinner size="sm" />}
          <IntlMessages id="form.save" />
        </Button>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          onClick={() => onClose()}
        >
          <IntlMessages id="form.cancel" />
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default FormComponent;
