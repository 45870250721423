// Vendors Components
import { CardHeader, IconButton, Tooltip } from "@material-ui/core";
import { Edit as EditIcon } from "@material-ui/icons";
import AlertBox from "components/AlertBox/index";
import Widget from "components/Widget/index";
import React from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { onAddRecommendedTestForm } from "./actions";

const RecommendedTest = (props) => {
  const dispatch = useDispatch();
  const { visit } = useSelector(({ visitStore }) => visitStore);
  const {
    handleSubmit,
    register,
    reset,
    control,
    errors,
    setValue,
    getValues,
    watch,
  } = useForm({
    // validationSchema: updatePesFormView ? medRecordSchema : medRecordSchemaAdd,
    // defaultValues: updatePesFormView ? visit : initMedRecordVal,
    mode: "onBlur",
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "prescriptions",
    keyName: "keyId", //, default to "id", you can change the key name
  });
  const { accConfig } = useSelector(({ uiConfigureStore }) => uiConfigureStore);
  // on Add History
  const onAddHistory = () => {
    dispatch(onAddRecommendedTestForm("update", "recommendedTest"));
    // dispatch(onContactForm(contact, 'update'));
  };
  return (
    <Widget styleName="jr-card-full jr-card-tabs-right jr-card-profile">
      <CardHeader
        action={
          visit.id && (
            <Tooltip title="Update About Info ">
              <IconButton
                aria-label="Add"
                onClick={() => onAddHistory()}
                variant="contained"
                color="primary"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          )
        }
        title={"Recommended Tests"}
      />
      <div className="jr-tabs-content jr-task-list">
        {!visit.recommendedTests && (
          <AlertBox
            infotype="danger"
            message="Add recommended tests"
            onClick={onAddHistory}
          />
        )}

        <div className="row">
          {visit.recommendedTests}
          {/* {visit.drugHistory &&
            visit.drugHistory.map((value, index) => {
              return (
                // <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12" key={index}>
                <div className="col-12" key={index}>
                  <Auxiliary>
                    <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
                      <div className="mr-3">
                        {value.current && (
                          <i
                            className={`zmdi zmdi-check-circle jr-fs-xlxl text-red`}
                          />
                        )}
                        {!value.current && (
                          <i
                            className={`zmdi zmdi-circle-o jr-fs-xlxl text-green`}
                          />
                        )}
                      </div>
                      <div className="media-body">
                        <h6 className="mb-1 text-grey">
                          <strong>
                            {getValuefromLookup(accConfig.drugType, value.type)}
                          </strong>{" "}
                          {capitalize(value.name)}
                        </h6>
                        <p>{value.dosage}</p>
                        {value.note}
                      </div>
                    </div>
                  </Auxiliary>
                </div>
              );
            })} */}
        </div>
      </div>
    </Widget>
  );
};
export default RecommendedTest;
