export const folders = [
  {
    id: 2,
    handle: "ACTIVE",
    title: "Active",
    icon: "dot-circle",
    color: "#4cbb87",
  },
  {
    id: 1,
    handle: "COMPLETED",
    title: "Completed",
    icon: "check",
    color: "#166bec",
  },
  {
    id: 3,
    handle: "CANCELED",
    title: "Canceled",
    icon: "close",
    color: "#bb664c",
  },
  {
    id: 5,
    handle: "OTHER",
    title: "Other",
    icon: "circle",
    color: "#bb664c",
  },

  {
    id: 4,
    handle: "DELETED",
    title: "Trash",
    icon: "delete",
    color: "#fd1313",
  },
  {
    id: 6,
    handle: "ALL",
    title: "All",
    icon: "view-list",
    color: "#fd1313",
  },
];
