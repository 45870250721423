import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

// material ui
import { withMobileDialog, Dialog } from "@material-ui/core";

import TransitionSlide from "components/Dialog/TransitionSlide";

// Actions
import { onMedicFormClose } from "redux/actions/MedicalActions";
import { clearIcds } from "redux/actions/Setting";

import FormComponent from "./FormComponent";
import { onPesFormClose } from "../../Prescriptions/actions";

const CheckoutModal = (props) => {
  const dispatch = useDispatch();
  const { addCheckoutModal, updateCheckoutFormView } = useSelector(
    ({ visitStore }) => visitStore
  );

  // on contacts modal close
  const onClose = () => {
    dispatch(onPesFormClose());
    // dispatch(clearIcds());
  };
  return (
    <Dialog
      fullScreen={props.fullScreen}
      fullWidth={true}
      maxWidth="md"
      scroll="paper"
      TransitionComponent={TransitionSlide}
      open={addCheckoutModal || updateCheckoutFormView}
      onClose={onClose}
    >
      {(addCheckoutModal || updateCheckoutFormView) && <FormComponent />}
    </Dialog>
  );
};

CheckoutModal.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};
export default withMobileDialog()(CheckoutModal);
