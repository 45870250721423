import * as yup from "yup";

export const initValue = {
  userId: "",
  name: "",
  password: "",
  accessGroups: [
    {
      group: "DEFAULT",
      members: [],
    },
  ],
};

export const formSchema = yup.object().shape({
  userId: yup.string().email("Invalid email").required("Please enter email"),
  // name: yup.string().min(2, "Must be longer than 2 characters").required(),
});

export const formUSchema = yup.object().shape({
  accountId: yup.string(),
  userId: yup.string().email("Invalid email").required("Please enter email"),
});
