import React from "react";
import { connect } from "react-redux";
import {
  CardHeader,
  Tooltip,
  Button,
  ButtonGroup,
  IconButton,
} from "@material-ui/core";

import { Edit as EditIcon, Redo as RedoIcon } from "@material-ui/icons";

import { onEwsForm, onEWSfreqOverride } from "redux/actions/MedicalActions";
import OverrideFrequencyForm from "./OverrideFrequencyForm";
import { CrudNotification } from "util/helpers";

// const ButtonGroup = AntButton.Group;
class EwsVitalDetail extends React.Component {
  state = {
    bgStyle: "bg-success",
    overrideView: false,
  };
  componentDidMount() {
    var bgStyle = "bg-success";
    if (this.props.patientEws.result.scoreRank.rank === "High") {
      bgStyle = "bg-danger";
    }
    this.setState({
      bgStyle: bgStyle,
    });
  }
  onUpdateEws = () => {
    this.props.onEwsForm("update");
  };
  onOverRideFrequency = () => {
    this.setState({
      overrideView: !this.state.overrideView,
    });
  };
  submit = (values) => {
    return this.props.onEWSfreqOverride(values).then((res) => {
      this.onOverRideFrequency();
      CrudNotification("success", "EWS Frequency overridden Successfully !");
    });
  };
  render() {
    const { patientEws } = this.props;
    return (
      <div className="jr-card p-0">
        {!this.state.overrideView && (
          <div className={"card shadow text-center " + this.state.bgStyle}>
            <CardHeader
              action={
                <ButtonGroup
                  color="primary"
                  aria-label="outlined primary button group"
                >
                  <Tooltip title="Override EWS Frequency ">
                    <IconButton
                      onClick={() => this.onOverRideFrequency()}
                      variant="contained"
                      color="primary"
                      size="small"
                    >
                      <RedoIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Edit Last EWSDetail">
                    <IconButton
                      onClick={() => this.onUpdateEws()}
                      variant="contained"
                      color="primary"
                      size="small"
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </ButtonGroup>
              }
            />
            <div className="row col-with-divider py-4 px-2 text-white br-break ">
              <div className="col-4">
                <div className="stack-order">
                  <h1 className="chart-f30">{patientEws.newsScore} point</h1>
                  <span className="h3 "> National Early Warning System</span>
                  <span className="h5 ">
                    <i className="zmdi zmdi-long-arrow-return zmdi-hc-fw zmdi-hc-rotate-90" />
                    {/* 20% */}
                  </span>
                </div>
              </div>
              <div className="col-4">
                <div className="stack-order">
                  <h1 className="chart-f30">
                    {patientEws.result.scoreRank.rank} Risk
                  </h1>
                  <span className="h5 br-break">
                    {patientEws.result.scoreRank.message}
                  </span>
                </div>
              </div>

              <div className="col-4">
                <div className="stack-order">
                  <h1 className="chart-f30">
                    {patientEws.result.monitoringFrequency}
                  </h1>
                  <span className="h3 "></span>
                </div>
              </div>
            </div>
          </div>
        )}
        {this.state.overrideView && (
          <OverrideFrequencyForm
            onCancel={this.onOverRideFrequency}
            onSubmit={this.submit}
          />
        )}
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      loading: state.medRecordStore.ewslistloading,
      patientEws: state.medRecordStore.patientEws,
      vitalForm: state.medRecordStore.vitalForm,
      screenWidth: state.uiConfigureStore.width,
    };
  },
  {
    onEwsForm,
    onEWSfreqOverride,
  }
)(EwsVitalDetail);
