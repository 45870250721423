import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFieldArray, Controller } from "react-hook-form";

import {
  FormControl,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  InputAdornment,
  Divider,
  Container,
  Avatar,
  CardHeader,
  IconButton,
  Paper,
} from "@material-ui/core";

import {
  Add as AddIcon,
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
} from "@material-ui/icons";

import IntlMessages from "util/IntlMessages";

export default function CustomFormArray(props) {
  const { accConfig } = useSelector(({ uiConfigureStore }) => uiConfigureStore);
  const { control, errors, register, updateFormModal } = props;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "customFields",
    keyName: "keyId", //, default to "id", you can change the key name
  });

  const onAdd = () => {
    append({
      id: 0,
      customType: "MESSENGER",
      customValue: "",
    });
  };

  const onRemove = (index) => {
    remove(index);
  };

  return (
    <div style={{ paddingBottom: 25 }}>
      <CardHeader
        avatar={
          <Avatar aria-label="Custom Fields">
            <MoreVertIcon />
          </Avatar>
        }
        action={
          updateFormModal && (
            <IconButton
              aria-label="Add"
              onClick={onAdd}
              variant="contained"
              color="primary"
            >
              <AddIcon />
            </IconButton>
          )
        }
        title={
          <h2>
            <strong>Custom Fields</strong>
          </h2>
        }
      />

      {fields.map((item, index) => {
        return (
          <div key={item.keyId}>
            <Container style={{ paddingBottom: 25 }}>
              <Grid container spacing={2}>
                <Grid item xs={4} sm={4} spacing={1}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel htmlFor="customType">
                      <IntlMessages id="contact.form.customType" />
                    </InputLabel>
                    <Controller
                      as={
                        <Select>
                          <MenuItem value="" disabled>
                            {" "}
                            Select Type
                          </MenuItem>
                          {accConfig.customFieldType &&
                            accConfig.customFieldType.map((val) => (
                              <MenuItem value={val.key} key={val.key}>
                                {val.value}
                              </MenuItem>
                            ))}
                        </Select>
                      }
                      name={`customFields[${index}].customType`}
                      label={<IntlMessages id="contact.form.customType" />}
                      control={control}
                      fullWidth
                      defaultValue={item.customType}
                      margin="normal"
                      error={
                        errors.customFields &&
                        errors.customFields[index] &&
                        errors.customFields[index].customType
                      }
                      helperText={
                        errors.customFields &&
                        errors.customFields[index] &&
                        errors.customFields[index].customType &&
                        errors.customFields[index].customType.message
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid
                  item
                  xs={updateFormModal ? 7 : 8}
                  sm={updateFormModal ? 7 : 8}
                >
                  <FormControl fullWidth margin="normal">
                    <Controller
                      label={<IntlMessages id="contact.form.customValue" />}
                      as={TextField}
                      name={`customFields[${index}].customValue`}
                      control={control}
                      fullWidth
                      defaultValue={item.customValue}
                      error={
                        errors.customFields &&
                        errors.customFields[index] &&
                        errors.customFields[index].customValue
                      }
                      helperText={
                        errors.customFields &&
                        errors.customFields[index] &&
                        errors.customFields[index].customValue &&
                        errors.customFields[index].customValue.message
                      }
                    />
                  </FormControl>
                </Grid>
                {updateFormModal && (
                  <Grid item xs={1}>
                    <IconButton
                      aria-label="Delete"
                      onClick={() => onRemove(index)}
                      variant="contained"
                      color="secondary"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </Container>

            {index + 1 !== fields.length && <Divider variant="middle" />}
          </div>
        );
      })}
    </div>
  );
}
