import * as yup from "yup";
import moment from "moment";
export const initInPatientVal = {
  patientId: null,
  admission: {
    date: null,
    time: null,
    department: "",
    unit: "",
    ward: "",
    bedNumber: "",
  },
};

export const inpatientSchema = yup.object().shape({
  admission: yup
    .object()
    .default(null)
    .nullable()
    .shape({
      // date: yup.date().required('Date is required'),
      date: yup.string().required("Date is required"),
      time: yup.string().required("Time is required"),
      // department: yup.string().required('Department is required'),
      unit: yup.string().required("Unit is required"),
      ward: yup.string().required("Ward is required"),
      bedNumber: yup.string().required("Bed Number is required"),
    }),
  clinicalRecord: yup
    .object()
    .default(null)
    .nullable()
    .shape({
      illnesses: yup.array().of(
        yup.object().shape({
          name: yup.string().required("Illness Name is required"),
          // note: yup.string().required("Illness Note is required"),
        })
      ),
      chiefComplaints: yup.array().of(
        yup
          .string()
          .required("Chief Complaints is required")
          .nullable()
      ),
      findings: yup.array().of(
        yup
          .string()
          .required("Findings is required")
          .nullable()
      ),

      clinicalExam: yup
        .object()
        .default(null)
        .nullable()
        .shape({
          height: yup
            .object()
            .default(null)
            .nullable()
            .shape({
              date: yup
                .string()
                .required("Date is required")
                .nullable(),
              feet: yup.number().when("unit", {
                is: "IN",
                then: yup
                  .number("Feet must be number")
                  .integer("Feer can't include a decimal point")
                  .min(0, "Feet can't be Less than 0")
                  .max(10, "Height Feet cannot be more than 10 feet")
                  .required("Feet is required")
                  .typeError("Feet Should be numerical")
                  .nullable(),
              }),
              inch: yup.number().when("unit", {
                is: "IN",
                then: yup
                  .number("Inch must be number")
                  .integer("Inch can't include a decimal point")
                  .min(0, "Inches can't be Less than 0")
                  .max(12, "Inches cannot be more than 12")
                  .required("Inches is required")
                  .typeError("Inches Should be numerical")
                  .nullable(),
              }),

              height: yup.string().when("unit", {
                is: "CM",
                then: yup
                  .number("Height should be Number")
                  .min(0, "Height can't be Less than 0")
                  .required("Height is required.")
                  .typeError("Height Should be numerical")
                  .nullable(),
              }),
              // unit: yup.string().required('Unit is required')
            }),
          weight: yup
            .object()
            .default(null)
            .nullable()
            .shape({
              weight: yup
                .number("Weight should be Number")
                .positive("Weight can't be Less than 0")
                .required("Weight is required.")
                .typeError("Weight Should be numerical")
                .nullable(),
            }),
          bloodPressure: yup
            .object()
            .default(null)
            .nullable()
            .shape({
              systolicPressure: yup
                .string()
                .required("Systolic Pressure is required")
                .nullable(),
              diastolicPressure: yup
                .string()
                .required("Diastolic Pressure is required")
                .nullable(),
            }),
          pulse: yup
            .object()
            .default(null)
            .nullable()
            .shape({
              pulse: yup
                .string()
                .required("Pulse is required")
                .nullable(),
            }),
          respiratoryRate: yup
            .object()
            .default(null)
            .nullable()
            .shape({
              respiratoryRate: yup
                .string()
                .required("Respiratory Rate is required")
                .nullable(),
            }),
          oxygenSaturation: yup
            .object()
            .default(null)
            .nullable()
            .shape({
              oxygenSaturation: yup
                .string()
                .required("Oxygen Saturation is required")
                .nullable(),
            }),
          temperature: yup
            .object()
            .default(null)
            .nullable()
            .shape({
              temperature: yup
                .string()
                .required("Temperature is required")
                .nullable(),
            }),
          observations: yup
            .array()
            // .min(1, 'Atleast 1 Address is Required')
            .of(
              yup.object().shape({
                observationType: yup
                  .string()
                  .required("Observation Type is required"),
                observation: yup.string().required("Observation is required"),
              })
            ),
          // consciousnessLevel: yup
          //   .string()
          //   .required("ConsciousnessLevel is required")
          //   .nullable(),
          // note: yup
          //   .string()
          //   .required("Note is required")
          //   .nullable(),
        }),
    }),
  discharge: yup
    .object()
    .default(null)
    .nullable()
    .shape({
      adviceNote: yup.string().required("Advice Note is required"),
      followupNote: yup.string().required("Followup Note is required"),
      reportPreparedBy: yup.string().required("Report Prepared By is required"),
      prescriptions: yup
        .array()
        // .min(1, 'Atleast 1 Address is Required')
        .of(
          yup.object().shape({
            name: yup.string().required("Name is required"),
            dosage: yup.string().required("Dosage is required"),
            note: yup.string().required("Note is required"),
          })
        ),
    }),
  surgeries: yup
    .array()
    // .min(1, 'Atleast 1 Address is Required')
    .of(
      yup.object().shape({
        date: yup.string().required("Date is required"),
        name: yup.string().required("Name is required"),
        code: yup.string().required("Surgery Code is required"),
        note: yup.string().required("Surgery Note is required"),
        performedBy: yup.string().required("Performed By is required"),
      })
    ),
  diagnoses: yup
    .array()
    // .min(1, 'Atleast 1 Address is Required')
    .of(
      yup.object().shape({
        date: yup.string().required("Date is required"),
        diagnosis: yup.string().required("Diagnosis is required"),
        // icd10: yup.string().required("ICD10 is required"),
        diagnosisType: yup.string().required("Diagnosis Type is required"),
        // diagnosedBy: yup.string().required("Diagnosed By is required"),
      })
    ),
  investigation: yup
    .object()
    .default(null)
    .nullable()
    .shape({
      labWorks: yup
        .array()
        // .min(1, 'Atleast 1 Address is Required')
        .of(
          yup.object().shape({
            date: yup.string().required("Date is required"),
            labWorkItem: yup
              .string()
              .required("Lab works item is required")
              .nullable(),
            result: yup.string().required("Lab works result is required"),
            note: yup.string().required("Lab works Note is required"),
          })
        ),
      imagingWorks: yup
        .array()
        // .min(1, 'Atleast 1 Address is Required')
        .of(
          yup.object().shape({
            date: yup.string().required("Date is required"),
            imagingWorkItem: yup
              .string()
              .required("Imaging works item is required"),
            result: yup.string().required("Imaging works result is required"),
            note: yup.string().required("Imaging works Note is required"),
          })
        ),
    }),
});
