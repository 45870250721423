import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  IconButton,
  MenuItem,
  FormControl,
  FormHelperText,
  TextField,
  Chip,
  InputAdornment,
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";

import { Dropdown, DropdownMenu, DropdownToggle, Popover } from "reactstrap";

import SearchBox from "components/SearchBox";
import {
  Search as SeacrhIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  AddCircle as AddCircleIcon,
} from "@material-ui/icons";

import { getColor } from "util/helpers";
const searchTypes = [
  {
    label: "Name",
    value: "name",
  },
  {
    label: "Phone",
    value: "phone",
  },
  {
    label: "Tags",
    value: "tags",
  },
];

const AppModuleHeader = (props) => {
  const { clientTags } = useSelector(({ contactStore }) => contactStore);
  const { profilePic } = useSelector(({ auth }) => auth);
  const {
    placeholder,
    onChange,
    onClientTypeChange,
    filterVal,
    value,
    user,
    notification,
    apps,
  } = props;
  const [searchBox, setSearchBox] = useState(false);
  const [popoverOpen, setPopOverOpen] = useState(false);
  const [tagDatas, setTagDatas] = useState([]);
  const [searchVal, setSearchVal] = useState(value);

  useEffect(() => {
    onChange(tagDatas.toString());
  }, [tagDatas]);

  useEffect(() => {
    // console.log("sdf");
    // if (searchVal.length > 0) {
    onChange(searchVal);
    // }
  }, [searchVal]);

  const onSearchBoxSelect = () => {
    setSearchBox(!searchBox);
  };

  const toggle = () => {
    setPopOverOpen(!popoverOpen);
  };

  const onTagChange = (event, value) => {
    setTagDatas(value);
    return value;
  };

  return (
    <div className="module-box-header-inner">
      <div
        className="search-bar right-side-icon bg-transparent d-none d-sm-block"
        style={{ width: "80%" }}
      >
        <Grid container spacing={2}>
          <Grid item xs={3} sm={3}>
            <TextField
              select
              fullWidth
              label=" "
              value={filterVal}
              onChange={onClientTypeChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SeacrhIcon />
                  </InputAdornment>
                ),
              }}
            >
              {searchTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6} sm={6}>
            {filterVal !== "tags" && (
              <TextField
                id="search"
                label="Search"
                value={value}
                onChange={(e) => setSearchVal(e.target.value)}
                fullWidth
              />
            )}
            {filterVal === "tags" && (
              <Autocomplete
                margin="normal"
                multiple
                options={clientTags}
                value={tagDatas}
                freeSolo
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      size="small"
                      variant="outlined"
                      label={option}
                      style={{
                        backgroundColor: getColor(option),
                        color: "white",
                      }}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                onChange={onTagChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Tags"
                    fullWidth
                    margin="normal"
                  />
                )}
              />
            )}
          </Grid>
        </Grid>

        {/* <div className="form-group">
          <input className="form-control border-0" type="search" placeholder={placeholder}
                 onChange={onChange}
                 value={value}/>
          <button className="search-icon"><i className="zmdi zmdi-search zmdi-hc-lg"/></button>
        </div> */}
      </div>

      <Popover
        className="p-3"
        placement="bottom"
        isOpen={popoverOpen}
        target="Popover1"
        toggle={toggle}
      >
        <h3>{user.name}</h3>
        <h4>{user.email}</h4>
      </Popover>

      <div className="d-inline-block d-sm-none">
        <Dropdown
          className="quick-menu nav-searchbox"
          isOpen={searchBox}
          toggle={onSearchBoxSelect}
        >
          <DropdownToggle
            className="d-inline-block"
            tag="span"
            data-toggle="dropdown"
          >
            <IconButton className="icon-btn">
              <i className="zmdi zmdi-search zmdi-hc-fw text-grey" />
            </IconButton>
          </DropdownToggle>

          <DropdownMenu className="p-0">
            <SearchBox
              styleName="search-dropdown"
              placeholder="Search"
              onChange={onChange}
              value={value}
            />
          </DropdownMenu>
        </Dropdown>
      </div>

      <div className="module-box-header-right">
        {apps && (
          <IconButton className="size-40" aria-label="Menu">
            <i className="zmdi zmdi-apps" />
          </IconButton>
        )}
        {notification && (
          <IconButton className="size-40" aria-label="Menu">
            <i className="zmdi zmdi-notifications-none" />
          </IconButton>
        )}

        <img
          className="ml-2 rounded-circle size-40 pointer"
          id="Popover1"
          alt={user.name}
          onMouseEnter={toggle}
          onMouseLeave={toggle}
          onClick={toggle}
          src={profilePic}
        />
      </div>
    </div>
  );
};

export default AppModuleHeader;

AppModuleHeader.defaultProps = {
  styleName: "",
  value: "",
  notification: true,
  apps: true,
};
