import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import moment from "moment";
import { useForm, Controller } from "react-hook-form";

// material ui
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
} from "@material-ui/core";
import { Alert, Spinner } from "reactstrap";

// custom Components
import DialogTitle from "components/Dialog/DialogTitle";

// Actions
import {
  getActiveStaffs,
  getActiveContacts,
  onTaskClose,
  onTaskUpdate,
  onTaskAdd,
} from "services/actions";

// Utilities and helpers
import IntlMessages from "util/IntlMessages";
import { CrudNotification } from "util/helpers";
import { formSchema, initTaskVal } from "../formIndex";
// components
import AccessGroupsArray from "./AccessGroupsArray";
import TaskSubjectForm from "./TaskSubjectForm";
import { AccessGroupsInput } from "util/formInput";

const FormTitle = (props) => {
  const { task, updateFormTask } = props;
  if (updateFormTask) {
    return <span>Update Task</span>;
  } else {
    return <span>Add Task</span>;
  }
};

const TaskForm = (props) => {
  const dispatch = useDispatch();
  const { accConfig, districts, appUiConfig, uiClients, uiStaffs } =
    useSelector(({ uiConfigureStore }) => uiConfigureStore);
  const {
    tasks,
    tasksLoading,
    task,
    addFormTask,
    updateFormTask,
    viewTask,
    aboutType,
    formerror,
    is_saving,
  } = useSelector(({ taskStore }) => taskStore);

  const [initialVal, setInitialVal] = useState(
    updateFormTask ? task : initTaskVal
  );

  const {
    handleSubmit,
    register,
    reset,
    control,
    errors,
    setValue,
    getValues,
    watch,
  } = useForm({
    validationSchema: formSchema,
    defaultValues: initialVal,
    mode: "onBlur",
  });

  useEffect(() => {
    uiStaffs.length === 0 && dispatch(getActiveStaffs());
    uiClients.length === 0 && dispatch(getActiveContacts());
  }, []);
  const [groups, setGroups] = useState([]);

  // On update submit
  const onSubmit = (values) => {

    if (values.id) {
      // console.log("add", values);
      return dispatch(onTaskUpdate(values)).then((res) => {
        CrudNotification("success", "Task added Successfully !");
      });
    } else {
      // console.log("update", values);
      return dispatch(onTaskAdd(values)).then((res) => {
        CrudNotification("success", "Task updated Successfully !");
      });
    }
  };

  // Process form values before submitting
  const transform = (onSubmitFunc) => (data) => {
    let values = { ...data };
    if (data.aboutId && data.aboutId.clientId) {
      values.aboutId = data.aboutId.clientId;
      values.aboutName = data.aboutId.name;
    } else {
      values.aboutName = values.aboutName ? values.aboutName : null;
      values.aboutId = values.aboutId ? values.aboutId : null;
    }
    if (data.assignedId && data.assignedId.clientId) {
      values.assignedId = data.assignedId.clientId;
      values.assignedName = data.assignedId.name;
    } else {
      values.assignedName = values.assignedName ? values.assignedName : null;
      values.assignedId = values.assignedId ? values.assignedId : null;
    }

    if (data.startDateTime) {
      values.startDateTime = moment(data.startDateTime).utc().toISOString();
    }
    if (data.startDateTime) {
      values.endDateTime = moment(data.endDateTime).utc().toISOString();
    }
    // if (values.accessGroups) {
    //   values.accessGroups.map((accessGroup, index) => {
    //     if (accessGroup.group === "ALL") {
    //       values.accessGroups.splice(index);
    //     } else {
    //       if (accessGroup.members && accessGroup.members.length !== 0) {
    //         accessGroup.members = accessGroup.members.map((a) =>
    //           a.id ? a.id : a
    //         );
    //         accessGroup.members = [...new Set(accessGroup.members)];
    //         return accessGroup;
    //       }
    //     }
    //   });
    // }
    if (groups.length > 0) {
      // values.accessGroups = groups.map((group) => {
      //   return { group: group.key };
      // });
      values.accessGroups = groups.map((group) => {
        return group.key
      });
    } else {
      // values.accessGroups = [{ group: "DEFAULT" }];
      values.accessGroups = [ "DEFAULT"];
    }
    // console.log(values);
    onSubmitFunc(values);
  };
  // on contacts modal close
  const onClose = () => {
    dispatch(onTaskClose());
  };

  const values = getValues({ nest: true });
  return (
    <Fragment>
      <DialogTitle id="contact-form" onClose={onClose}>
        <FormTitle updateFormTask={updateFormTask} task={task} />
      </DialogTitle>
      <DialogContent dividers={true}>
        {formerror && <Alert color="danger"> {formerror.message} </Alert>}
        {updateFormTask && (
          <input
            ref={register}
            name="id"
            type="text"
            hidden
            defaultValue={task.id}
          />
        )}
        {/* <input
          ref={register}
          name="accountId"
          type="text"
          hidden
          defaultValue={contact.clientId}
        /> */}

        <TaskSubjectForm
          {...{
            control,
            register,
            getValues,
            setValue,
            errors,
            updateFormTask,
            task,
            watch,
          }}
        />

        <AccessGroupsInput
          label="Share with..."
          formVals={initialVal}
          groups={groups}
          setGroups={setGroups}
        />
      </DialogContent>
      <DialogActions>
        <Button
          className="button buttonBlack"
          type="button"
          onClick={() => {
            reset(initialVal);
          }}
        ></Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSubmit(transform(onSubmit))}
          disabled={is_saving}
        >
          {is_saving && <Spinner size="sm" />}
          <IntlMessages id="form.save" />
        </Button>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          onClick={() => onClose()}
        >
          <IntlMessages id="form.cancel" />
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default TaskForm;
