import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// Vendors Components
import {
  CardHeader,
  Tooltip,
  IconButton,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Button,
} from "@material-ui/core";

import {
  AccountCircle,
  CheckCircle as CheckCircleIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
  Edit as EditIcon,
  Assignment as AssignmentIcon,
} from "@material-ui/icons";
//  Themes Components

import Auxiliary from "util/Auxiliary";
import Widget from "components/Widget/index";
import AlertBox from "components/AlertBox/index";

// Custom Components and Helpers
// import { capitalize } from "util/helpers";

// Components
import NoDetail from "../NoDetail";
// import ChiefComplaints from "./ChiefComplaints";
// import Illnesses from "./Illnesses";

import { getValuefromLookup } from "util/helpers";
//  Actions
import { onInPatientForm } from "redux/actions/MedicalActions";

const Discharge = (props) => {
  const dispatch = useDispatch();
  //   const { contact } = useSelector(({ contactStore }) => contactStore);

  const { accConfig } = useSelector(({ uiConfigureStore }) => uiConfigureStore);
  const { inPatient, inpatient_loading } = useSelector(
    ({ medRecordStore }) => medRecordStore
  );

  const { inverted = "timeline-inverted" } = props;

  const onAddInpatientRecord = () => {
    dispatch(onInPatientForm("update", "discharge"));
  };
  const onDischargeReport = () => {
    props.history.push(
      `/app/viewPatient/${props.match.params.id}/dischargeReport/` +
        inPatient.inpatientId
    );
  };
  return (
    <div className={"timeline-item " + inverted}>
      {!inPatient.discharge && (
        <NoDetail
          title={"Discharge Records"}
          loading={inpatient_loading}
          onAddInpatientRecord={onAddInpatientRecord}
          // inverted={inverted}
        />
      )}
      {inPatient.discharge && (
        <div>
          <div className={`timeline-badge bg-green`}>
            <AccountCircle />
          </div>

          <div className={"timeline-panel mytimeline"}>
            <CardHeader
              action={
                inPatient.id && (
                  <Tooltip title="Update Discharge Info ">
                    <IconButton
                      aria-label="Add"
                      onClick={() => onAddInpatientRecord()}
                      variant="contained"
                      color="primary"
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                )
              }
              title={"Discharge"}
              //   subheader={
              //     <small>
              //       {inPatient.clinicalRecord.dateDisplay}{" "}
              //       {inPatient.clinicalRecord.timeDisplay}
              //     </small>
              //   }
            />
            <CardContent>
              <Grid container spacing={1}>
                {/* {inPatient.clinicalRecord.clinicalExam.observations && ( */}
                <Grid item xs>
                  <List>
                    <ListItem>
                      <ListItemText
                        primary={inPatient.discharge.reportPreparedBy}
                        secondary={
                          <div>
                            {inPatient.discharge.adviceNote}
                            <p>{inPatient.discharge.followupNote}</p>
                          </div>
                        }
                      />
                    </ListItem>
                    <ListItem button>
                      <ListItemText primary={<h2>Prescriptions</h2>} />
                    </ListItem>
                    {inPatient.discharge.prescriptions &&
                      inPatient.discharge.prescriptions.map((value, index) => {
                        return (
                          <ListItem alignItems="flex-start" key={index}>
                            <ListItemAvatar>
                              {value.current && (
                                <CheckCircleIcon
                                  color="secondary"
                                  fontSize="large"
                                />
                              )}
                              {!value.current && (
                                <RadioButtonUncheckedIcon
                                  color="primary"
                                  fontSize="large"
                                />
                              )}
                            </ListItemAvatar>
                            <ListItemText
                              primary={value.name}
                              secondary={
                                <div>
                                  {value.dosage}
                                  <p>{value.note}</p>
                                </div>
                              }
                            />
                          </ListItem>
                        );
                      })}

                    <Tooltip title="Click to View Discharge report">
                      <Button
                        onClick={() => onDischargeReport()}
                        color="primary"
                      >
                        <AssignmentIcon /> &nbsp; Discharge Report
                      </Button>
                    </Tooltip>
                  </List>
                </Grid>
              </Grid>
            </CardContent>
          </div>
        </div>
      )}
    </div>
  );
};
export default withRouter(Discharge);
