import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";

import { PDFViewer, PDFDownloadLink, Font } from "@react-pdf/renderer";

import { Button, Tooltip, Card, CardContent } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
// Custom Components and Helpers
import Auxiliary from "util/Auxiliary";
import DischargeDoc from "./DischargeDoc";

// Actions
import { getInPatientDetailById } from "redux/actions/MedicalActions";
import { getInvestigation } from "redux/actions/Setting";

import styles from "./pdfStyle";

Font.register({
  family: "Roboto Bold",
  src: require("assets/fonts/Roboto-Medium.ttf"),
});

// Components
// Discharge Report Page
const DischargeReport = (props) => {
  const dispatch = useDispatch();
  const { contact } = useSelector(({ contactStore }) => contactStore);
  const { inPatient, addInPatientModal, updateInPatientModal } = useSelector(
    ({ medRecordStore }) => medRecordStore
  );
  const { accConfig } = useSelector(({ uiConfigureStore }) => uiConfigureStore);

  useEffect(() => {
    let inpatientId = props.match.params.inpatientId;
    if (inpatientId !== inPatient.inpatientId) {
      dispatch(getInPatientDetailById(inpatientId));
    }
    if (accConfig.investigations.length === 0) {
      dispatch(getInvestigation());
    }
  }, []);

  const goBack = () => {
    props.history.goBack();
  };
  return (
    <Auxiliary>
      <div className="page-heading d-sm-flex justify-content-sm-between align-items-sm-center">
        <h2 className="title mb-3 mb-sm-0">Discharge Documents</h2>
        <span className="mb-0">
          {inPatient.id && (
            <NavLink
              to={
                "/app/viewPatient/" +
                contact.clientId +
                "/inpatientDetail/" +
                inPatient.inpatientId
              }
              activeClassName="is-active"
              style={{ fontSize: 18 }}
            >
              Go Back
            </NavLink>
          )}
          {/* {inPatient.id && (
            <PDFDownloadLink
              // style={{ textDecoration: "none" }}
              // className={styles.PDFDownloadLink}
              document={
                <DischargeDoc
                  contact={contact}
                  inPatient={inPatient}
                  accConfig={accConfig}
                />
              }
              fileName="usuarios.pdf"
            >
              {({ blob, url, loading, error }) =>
                loading ? "Loading document..." : "Download now!"
              }
            </PDFDownloadLink>
          )} */}
        </span>
      </div>
      {inPatient.id && accConfig.investigations.length > 0 && (
        <Card>
          <CardContent style={{ height: "1200px" }}>
            <PDFViewer width={"100%"} height={"100%"}>
              <DischargeDoc
                contact={contact}
                inPatient={inPatient}
                accConfig={accConfig}
              />
            </PDFViewer>
          </CardContent>
        </Card>
      )}
      {!inPatient.id && accConfig.investigations.length !== 0 && (
        <Skeleton variant="rect" height={200} />
      )}
    </Auxiliary>
  );
};

export default withRouter(DischargeReport);
