import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  IconButton,
  ButtonGroup,
  Button,
  Menu,
  MenuItem,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import CustomScrollbars from "util/CustomScrollbars";
import {
  getColor,
  displayDate,
  getValuefromLookup,
  CrudNotification,
} from "util/helpers";
import {
  onTaskForm,
  onTaskClose,
  onTaskDelete,
  onTaskUpdate,
} from "../actions";
import { folders } from "../data";

const TaskDetail = (props) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(undefined);
  const [open, setOpen] = useState(false);

  const { uiUsers, accConfig } = useSelector(
    ({ uiConfigureStore }) => uiConfigureStore
  );
  const { is_saving } = useSelector(({ taskStore }) => taskStore);
  const { task, width } = props;
  const { accessGroups, category, title } = task;

  const handleClick = (event) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleRequestClose = () => {
    setOpen(false);
  };

  // onCloseTaskView Modal
  const onClose = () => {
    dispatch(onTaskClose());
  };
  // on Update
  const onMenuSelect = (option) => {
    option === "Update" && dispatch(onTaskForm(task, "update"));
    if (option === "Delete") {
      task.status = task.status === "ACTIVE" ? "DELETED" : "ACTIVE";
      return dispatch(onTaskDelete(task)).then((res) => {
        CrudNotification(
          "success",
          `Task ${
            task.status === "ACTIVE" ? "Active" : "Deleted"
          } Successfully !`
        );
      });
    }
  };

  const options = ["Update", "Delete"];
  const getUserName = (memberId) => {
    let member = uiUsers.find((item) => item.id === memberId);
    return member;
  };
  const onStatusChange = (stat) => {
    task.status = stat;
    return dispatch(onTaskUpdate(task)).then((res) => {
      CrudNotification("success", `Task Updated Successfully !`);
    });
  };
  return (
    <div className="module-detail mail-detail">
      <CustomScrollbars
        className="module-list-scroll scrollbar"
        style={{
          height: width >= 1200 ? "calc(100vh - 309px)" : "calc(100vh - 288px)",
        }}
      >
        <div className="mail-header">
          <div style={{ paddingRight: "15px" }}>
            <IconButton className="icon-btn" onClick={() => onClose()}>
              <i className="zmdi zmdi-arrow-back" />
            </IconButton>
          </div>
          <div className="mail-header-content col pl-0">
            <div className="subject">{title}</div>
            <div className="labels">
              <div
                className={`badge text-white `}
                style={{ backgroundColor: getColor(category) }}
              >
                {category}
              </div>
            </div>
          </div>
          <div>
            <ButtonGroup size="small">
              {folders.map((item) => {
                return (
                  <Button
                    onClick={() => onStatusChange(item.handle)}
                    variant={task.status === item.handle && "contained"}
                    color="primary"
                    disabled={is_saving}
                  >
                    <i className={`zmdi zmdi-${item.icon} `} />
                    {item.title}
                  </Button>
                );
              })}
            </ButtonGroup>
          </div>
        </div>
        <hr />
        <div className="mail-user-info">
          {task.createdBy && (
            <div
              className="avatar rounded-circle size-40 text-white text-center"
              style={{
                fontSize: 16,
                backgroundColor: getColor(task.createdBy),
              }}
            >
              {task.createdBy.charAt(0).toUpperCase()}
            </div>
          )}

          <div className="sender-name">
            {task.createdBy}
            <div className="send-to text-grey">
              {task.aboutName && `${task.aboutName} / with `}
              {task.assignedName && `${task.assignedName} `}
            </div>
            {accessGroups && accessGroups.length > 0 && (
              <span>
                <i className="zmdi zmdi-eye zmdi-hc-fw zmdi-hc-lg" />{" "}
                {accessGroups.map((access, index) => (
                  <span>
                    {getValuefromLookup(
                      accConfig.userAccessGroup,
                      access.group
                    )}
                    {accessGroups.length !== index + 1 && ", "}
                  </span>
                ))}
                {/* {accessGroups[0].group === "ME_AND_THESE" &&
                    accessGroups[0].members &&
                    accessGroups[0].members.length > 0 && (
                      <p className="mb-0">
                        to Me and{" "}
                        {accessGroups[0].members.map((member, index) => {
                          return (
                            <span key={index}>
                              {getUserName(member)}
                              {accessGroups[0].members !== index + 1 && (
                                <span>, </span>
                              )}
                            </span>
                          );
                        })}
                      </p>
                    )} */}
              </span>
            )}
          </div>

          <IconButton
            aria-label="More"
            aria-owns={open ? "long-SidenavContent.js" : null}
            aria-haspopup
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>

          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => handleRequestClose()}
            MenuListProps={{
              style: {
                width: 200,
              },
            }}
          >
            {options.map((option) => (
              <MenuItem
                key={option}
                onClose={() => handleRequestClose()}
                onClick={() => onMenuSelect(option)}
              >
                {option}
              </MenuItem>
            ))}
          </Menu>
        </div>

        <p className="mt-2">
          <strong>Date: </strong>
          {displayDate(task.startDateTime, "datetime") +
            " - " +
            displayDate(task.endDateTime, "datetime")}
        </p>

        <div
          className="message"
          dangerouslySetInnerHTML={{ __html: task.description }}
        ></div>
        {/* {task.hasAttachments && (
            <div className="attachment-block">
              <h3>Attachments ({task.hasAttachments.length})</h3>
              <div className="row">
                {task.attachments.map((attachment, index) => (
                  <div className="col-3" key={index}>
                    <img
                      className="size-100"
                      src={attachment.preview}
                      alt={attachment.fileName}
                    />
                    <div className="size">{attachment.size}</div>
                  </div>
                ))}
              </div>
            </div>
          )} */}
      </CustomScrollbars>
    </div>
  );
};

export default TaskDetail;
