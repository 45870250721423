import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { withRouter } from "react-router";

// Vendors Components
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Button,
  ButtonGroup,
} from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { isRole } from "util/check-auth";
const UserTable = (props) => {
  const dispatch = useDispatch();
  const { users } = useSelector(({ settingStore }) => settingStore);
  const { loggedUser } = useSelector(({ auth }) => auth);
  const isAdmin = isRole(loggedUser.roles);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>S no</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>User Id</TableCell>
            <TableCell>Status</TableCell>
            {isAdmin && <TableCell align="right">Actions</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user, index) => (
            <TableRow key={user.id}>
              <TableCell component="th" scope="row">
                {index + 1}
              </TableCell>
              <TableCell component="th" scope="row">
                {user.name}
              </TableCell>
              <TableCell component="th" scope="row">
                {user.userId}
              </TableCell>
              <TableCell>
                {user.status === "ACTIVE" && <CheckBoxIcon />}
                {user.status !== "ACTIVE" && <CheckBoxOutlineBlankIcon />}
              </TableCell>
              {isAdmin && (
                <TableCell align="right">
                  <ButtonGroup>
                    <Button
                      onClick={() => props.onUpdate(user)}
                      variant="contained"
                      color="primary"
                    >
                      <EditIcon />
                      Update
                    </Button>
                    {/* <Button
                onClick={() => props.onDelete(user)}
                variant="contained"
                color="secondary"
              >
                <DeleteIcon />
                Delete
              </Button> */}
                  </ButtonGroup>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserTable;
