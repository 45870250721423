export const isLoggedIn = () => {
  if (localStorage["mettaApiKey"] && localStorage["mettaLoggedUser"]) {
    if (localStorage["mettaApiKey"] === "undefined") {
      localStorage.clear();
      return false;
    } else {
      return true;
    }
    // let token = localStorage['token'].substr(4);
    // if (jwtDecode(token).exp < Date.now() / 1000) {
    // 	localStorage.clear();
    // 	return false;
    // }
  }
  localStorage.clear();
  return false;
};
export const isAccessable = (accessFor, roles) => {
  let intersectionArray = [];
  if (roles && roles.length > 0) {
    intersectionArray = accessFor.filter((element) => roles.includes(element));
  } else {
    return true;
  }
  return intersectionArray.length > 0;
};

export const isRole = (roles, roleType = "ADMIN") => {
  let roleFound = false;
  if (roles && roles.length > 0) {
    roleFound = roles.includes(roleType);

    // console.log(roleFound);
  } else {
    return true;
  }
  return roleFound;
};

const trialRestriction = (qty, formType) => {
  switch (formType) {
    case "USERS":
      return qty >= 1 ? "Trial subscription is limited to 1 user. " : null;
    case "CARE_TEAM":
      return qty >= 1 ? "Trial subscription is limited to 1 member. " : null;
    default:
      return qty >= 1 ? "Trial subscription is limited to 1 member. " : null;
  }
};

const personalRestriction = (qty, formType) => {
  switch (formType) {
    case "USERS":
      return qty >= 1 ? "Trial subscription is limited to 1 user. " : null;
    case "CARE_TEAM":
      return qty >= 3 ? "Trial subscription is limited to 3 members. " : null;
    default:
      return qty >= 3 ? "Trial subscription is limited to 3 members. " : null;
  }
};
const familyRestriction = (qty, formType) => {
  switch (formType) {
    case "USERS":
      return qty >= 5 ? "Trial subscription is limited to 5 users. " : null;
    case "CARE_TEAM":
      return qty >= 5 ? "Trial subscription is limited to 10 members. " : null;
    default:
      return qty >= 10 ? "Trial subscription is limited to 10 members. " : null;
  }
};
const businessRestriction = (qty, formType) => {
  switch (formType) {
    case "USERS":
      return qty >= 10 ? "Trial subscription is limited to 10 users. " : null;
    case "CARE_TEAM":
      return qty >= 500 ? "Trial subscription is limited to 1 members. " : null;
    default:
      return qty >= 500
        ? "Trial subscription is limited to 500 members. "
        : null;
  }
};

export const checkRestriction = (qty = 0, formtype = "clients") => {
  let loggedUser = JSON.parse(localStorage.mettaLoggedUser);
  let accountType = loggedUser.accountType;
  // console.log(qty, formtype)
  switch (accountType) {
    case "PERSONAL":
      return personalRestriction(qty, formtype);
    case "FAMILY":
      return familyRestriction(qty, formtype);
    case "BUSINESS":
      return businessRestriction(qty, formtype);
    default:
      return trialRestriction(qty, formtype);
  }
};

export const saveAuthInfo = async function (authVal) {
  // do something
  let profile = authVal._profile;
  let tokens = authVal._token;
  let provider = authVal._provider;

  localStorage.setItem("profilepic", profile.profilePicURL);
  localStorage.setItem("mettaApiKey", profile.id);
  localStorage.setItem("provider", provider);
  localStorage.setItem("providerToken", tokens.accessToken);
  localStorage.setItem("provideridToken", tokens.idToken);
};
