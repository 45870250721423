import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFieldArray, Controller } from "react-hook-form";

import {
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  InputAdornment,
  Divider,
  Container,
  Avatar,
  CardHeader,
  IconButton,
  Paper,
  Popper,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Phone as PhoneIcon,
  Add as AddIcon,
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
} from "@material-ui/icons";

// Utilities and Helpers
import IntlMessages from "util/IntlMessages";
import { countries } from "./formIndex";
import { getCountryNamefromCode } from "util/helpers";

function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}

export default function PhonesFormArray(props) {
  const { accConfig } = useSelector(({ uiConfigureStore }) => uiConfigureStore);

  const { control, errors, register, getValues, updateFormModal } = props;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "phones",
    keyName: "keyId", //, default to "id", you can change the key name
  });

  const onAdd = () => {
    append({
      id: 0,
      phoneType: "MOBILE",
      countryDialCode: "+1",
      phone: "",
      primary: false,
    });
  };

  const onRemove = (index) => {
    remove(index);
  };
  const values = getValues({ nest: true });
  // console.log(values);
  // get countries json value
  const getCountry = (countryDialCode) => {
    let country = countries.find((o) => o.countryDialCode === countryDialCode);
    // console.log(country);
    return country;
  };
  const getCountryLabel = (option) => {
    if (option.name) {
      console.log(option);
      return countryToFlag(option.countryCode);
    } else {
      let country = getCountry(option);
      // let country = countries.find((o) => o.countryDialCode === option);
      return country ? countryToFlag(country.countryCode) : null;
    }
  };

  return (
    <div style={{ paddingBottom: 25 }}>
      <CardHeader
        avatar={
          <Avatar aria-label="">
            <PhoneIcon />
          </Avatar>
        }
        action={
          updateFormModal && (
            <IconButton
              aria-label="Add"
              onClick={onAdd}
              variant="contained"
              color="primary"
            >
              <AddIcon />
            </IconButton>
          )
        }
        title={
          <h2>
            <strong>Phones</strong>
          </h2>
        }
      />

      {fields.map((item, index) => {
        return (
          <div key={item.keyId}>
            <Container style={{ paddingBottom: 25 }}>
              <input
                ref={register}
                name={`phones[${index}].primary`}
                type="text"
                hidden
                defaultValue={index === 0}
              />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3} spacing={1}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel htmlFor="phoneType">
                      <IntlMessages id="contact.form.phoneType" />
                    </InputLabel>
                    <Controller
                      as={
                        <Select>
                          <MenuItem value="" disabled>
                            Select Phone Type
                          </MenuItem>
                          {accConfig.phoneType &&
                            accConfig.phoneType.map((val) => (
                              <MenuItem value={val.key} key={val.key}>
                                {val.value}
                              </MenuItem>
                            ))}
                        </Select>
                      }
                      name={`phones[${index}].phoneType`}
                      label={<IntlMessages id="contact.form.phoneType" />}
                      control={control}
                      fullWidth
                      defaultValue={item.phoneType}
                      error={
                        errors.phones &&
                        errors.phones[index] &&
                        errors.phones[index].phoneType
                      }
                      helperText={
                        errors.phones &&
                        errors.phones[index] &&
                        errors.phones[index].phoneType &&
                        errors.phones[index].phoneType.message
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={3} sm={2}>
                  <FormControl
                    fullWidth
                    margin="normal"
                    error={
                      errors.phones &&
                      errors.phones[index] &&
                      errors.phones[index].countryDialCode
                    }
                  >
                    <Controller
                      as={
                        <Autocomplete
                          options={countries}
                          getOptionLabel={(option) => getCountryLabel(option)}
                          renderOption={(option) => (
                            <span>
                              {countryToFlag(option.countryCode)}
                              {option.name + " " + option.countryDialCode}
                            </span>
                          )}
                          PopperComponent={PopperMy}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Country"
                              error={
                                errors.phones &&
                                errors.phones[index] &&
                                errors.phones[index].countryDialCode
                              }
                            />
                          )}
                        />
                      }
                      // onChange={([ , data ])=> onCountryChange(data)}
                      onChange={([, data]) => data}
                      name={`phones[${index}].countryDialCode`}
                      control={control}
                      defaultValue={
                        item.countryDialCode && getCountry(item.countryDialCode)
                      }
                    />
                    <FormHelperText>
                      {errors.phones &&
                        errors.phones[index] &&
                        errors.phones[index].countryDialCode &&
                        errors.phones[index].countryDialCode.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={updateFormModal ? 8 : 9}
                  sm={updateFormModal ? 6 : 7}
                >
                  <FormControl fullWidth margin="normal">
                    <Controller
                      label={<IntlMessages id="contact.form.phone" />}
                      as={TextField}
                      name={`phones[${index}].phone`}
                      control={control}
                      fullWidth
                      defaultValue={item.phone}
                      error={
                        errors.phones &&
                        errors.phones[index] &&
                        errors.phones[index].phone
                      }
                      helperText={
                        errors.phones &&
                        errors.phones[index] &&
                        errors.phones[index].phone &&
                        errors.phones[index].phone.message
                      }
                    />
                  </FormControl>
                </Grid>
                {updateFormModal && (
                  <Grid item xs={1}>
                    <IconButton
                      aria-label="Delete"
                      onClick={() => onRemove(index)}
                      variant="contained"
                      color="secondary"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </Container>

            {index + 1 !== fields.length && <Divider variant="middle" />}
          </div>
        );
      })}
    </div>
  );
}

const PopperMy = function (props) {
  return (
    <Popper {...props} style={{ maxWidth: "250px" }} placement="bottom-start" />
  );
};
