import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  DialogActions,
  Button,
  Radio,
  RadioGroup,
  FormHelperText,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@material-ui/core";

import IntlMessages from "util/IntlMessages";

// Actions
import { getActiveTemplates } from "services/actions";

import { channelsArray } from "../index";
const DeliveryChannelForm = (props) => {
  const dispatch = useDispatch();
  const { accConfig, districts, appUiConfig, uiTemplates } = useSelector(
    ({ uiConfigureStore }) => uiConfigureStore
  );
  const { step, formVals, setFormVals, activeStep, setActiveStep } = props;
  const [value, setValue] = React.useState("EMAILNTEXT");

  useEffect(() => {
    let formValues = { ...formVals, deliveryChannel: value };
    setFormVals(formValues);
  }, [value]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <>
      <div className="jr-tabs-content">
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={value}
            onChange={handleChange}
          >
            {channelsArray.map((channel) => {
              return (
                <FormControlLabel
                  key={channel.key}
                  value={channel.key}
                  control={<Radio />}
                  label={channel.label}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      </div>
    </>
  );
};

export default DeliveryChannelForm;
