import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { Skeleton } from "@material-ui/lab";
import Widget from "components/Widget/index";
import { getValuefromLookup } from "util/helpers";
import AccessGroupComponent from "./AccessGroupComponent";
import PersonalDetailBox from "../MedicalRecord/PersonalDetailBox";

const About = () => {
  const [value, setValue] = useState(0);
  const { client, clientLoading, clientError } = useSelector(
    ({ profileStore }) => profileStore
  );
  const { contact } = useSelector(({ contactStore }) => contactStore);
  const { medicalrecord } = useSelector(({ medRecordStore }) => medRecordStore);
  const { accConfig, uiUsers } = useSelector(
    ({ uiConfigureStore }) => uiConfigureStore
  );
  const { workInfo, personalInfo, accessGroups } = client;
  const handleChange = (event, value) => {
    setValue(value);
  };

  return (
    <Widget styleName="jr-card-full jr-card-tabs-right jr-card-profile">
      <div className="card-header">About {client.name}</div>
      {/* <div className="jr-tabs-classic"> */}
      {/* <Tabs className="jr-tabs-up" value={value} onChange={handleChange}>
          <Tab className="jr-tabs-label" label="Overview" />
          <Tab className="jr-tabs-label" label="Work" />
          <Tab className="jr-tabs-label" label="Education" />
        </Tabs> */}
      <div className="jr-tabs-content jr-task-list">
        <div className="row">
          {client && !client.id && (
            <Skeleton
              variant="rect"
              height={100}
              // style={{ backgroundColor: "white" }}
            />
          )}
          {workInfo && workInfo.employer && (
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
                <div className="mr-3">
                  <i className={`zmdi zmdi-city-alt jr-fs-xlxl text-orange`} />
                </div>
                <div className="media-body">
                  <h6 className="mb-1 text-grey">{workInfo.title}</h6>

                  <p className="mb-0">{workInfo.employer}</p>
                </div>
              </div>
            </div>
          )}

          {personalInfo && personalInfo.dateOfBirth && (
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
                <div className="mr-3">
                  <i className={`zmdi zmdi-cake jr-fs-xlxl text-orange`} />
                </div>
                <div className="media-body">
                  <h6 className="mb-1 text-grey">Birth Day</h6>

                  <p className="mb-0">{personalInfo.dateOfBirthDisplay}</p>
                </div>
              </div>
            </div>
          )}

          {personalInfo && personalInfo.gender && (
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
                <div className="mr-3">
                  <i
                    className={`zmdi zmdi-male-female jr-fs-xlxl text-orange`}
                  />
                </div>
                <div className="media-body">
                  <h6 className="mb-1 text-grey">Gender</h6>
                  <p className="mb-0">
                    {getValuefromLookup(accConfig.gender, personalInfo.gender)}
                  </p>
                </div>
              </div>
            </div>
          )}

          {personalInfo && personalInfo.maritalStatus && (
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
                <div className="mr-3">
                  <i
                    className={`zmdi zmdi-accounts-alt jr-fs-xlxl text-orange`}
                  />
                </div>
                <div className="media-body">
                  <h6 className="mb-1 text-grey">Marital Status</h6>
                  <p className="mb-0">
                    {getValuefromLookup(
                      accConfig.maritalStatus,
                      personalInfo.maritalStatus
                    )}
                  </p>
                </div>
              </div>
            </div>
          )}
          {personalInfo && personalInfo.anniversaryDate && (
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
                <div className="mr-3">
                  <i className={`zmdi zmdi-calendar jr-fs-xlxl text-orange`} />
                </div>
                <div className="media-body">
                  <h6 className="mb-1 text-grey">Anniversary date</h6>
                  <p className="mb-0">{personalInfo.anniversaryDateDisplay}</p>
                </div>
              </div>
            </div>
          )}
          {personalInfo && personalInfo.religion && (
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
                <div className="mr-3">
                  <i className={`zmdi zmdi-face jr-fs-xlxl text-orange`} />
                </div>
                <div className="media-body">
                  <h6 className="mb-1 text-grey">Religion</h6>
                  <p className="mb-0">
                    {getValuefromLookup(
                      accConfig.religion,
                      personalInfo.religion
                    )}
                  </p>
                </div>
              </div>
            </div>
          )}
          {/* {accessGroups && accessGroups.length > 0 && (
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
                <div className="mr-3">
                  <i className={`zmdi zmdi-face jr-fs-xlxl text-orange`} />
                </div>
                <div className="media-body">
                  <h6 className="mb-1 text-grey">Share with ...</h6>
                  <p className="mb-0">
                    {accessGroups.map((access, index) => (
                      <span>
                        {getValuefromLookup(
                          accConfig.userAccessGroup,
                          access.group
                        )}
                        {accessGroups.length !== index + 1 && ", "}
                      </span>
                    ))}
                  </p>
                </div>
              </div>
            </div>
          )} */}
          {/* <AccessGroupComponent /> */}
        </div>
      </div>
      {/* </div> */}
    </Widget>
  );
};

export default About;
