import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";

// material ui
import {
  Button,
  DialogActions,
  DialogContent,
  Checkbox,
  FormControl,
  FormControlLabel,
} from "@material-ui/core";

import { Alert, Spinner } from "reactstrap";
import DialogTitle from "components/Dialog/DialogTitle";
import getFormTitle from "components/formTitle";

// Actions
import {
  getTestDetailByPId,
  onTestRecordSave,
  onMedicFormClose,
  onTestFormModal,
} from "redux/actions/MedicalActions";

import IntlMessages from "util/IntlMessages";
import {
  CrudNotification,
  datetoArray,
  timeToArray,
  getValuefromLookup,
} from "util/helpers";
import InvestigationForm from "./InvestigationForm";

// formschema and initvalue
const initVal = {
  date: null,
  time: null,
  testItem: "",
  testGroup: "",
  result: "",
  unit: "",
  more: true,
};
const valSchema = yup.object().shape({
  // testItem: yup.mixed().required("Test Item  is required"),
  result: yup.string().required("Test result  is required"),
  testItem: yup.mixed().required("Test Item  is required"),
});

const FormComponent = (props) => {
  const dispatch = useDispatch();
  const {
    addMedModal,
    updateMedModal,
    formMedFor,
    medicalrecord,
    medformerror,
    is_saving,
  } = useSelector(({ medRecordStore }) => medRecordStore);
  const { accConfig, icds } = useSelector(
    ({ uiConfigureStore }) => uiConfigureStore
  );

  const { contact } = useSelector(({ contactStore }) => contactStore);
  const {
    handleSubmit,
    register,
    reset,
    control,
    errors,
    setValue,
    getValues,
    watch,
  } = useForm({
    validationSchema: valSchema,
    defaultValues: updateMedModal ? medicalrecord : initVal,
    mode: "onBlur",
  });
  const { alertMsg, setAlertMsg } = props;

  useEffect(() => {
    setValue("patientId", contact.clientId);
  }, []);

  const onSubmit = (values) => {
    values.label = getValuefromLookup(
      accConfig.investigations,
      values.testItem
    );
    console.log("accConfig.investigations", accConfig.investigations);
    console.log("values.testItem", values.testItem);
    const investigationVal = accConfig.investigations?.find(
      (ele) => ele.key === values.testItem
    );
    values.testGroup = investigationVal?.groupKey;

    console.log("values,,,", values);

    return dispatch(onTestRecordSave(values)).then((res) => {
      setAlertMsg(values.label + "is added added Successfully !");
      dispatch(getTestDetailByPId(medicalrecord.patientId));
      // if (values.more) {
      dispatch(onTestFormModal("update", formMedFor));
      // }
      CrudNotification(
        "success",
        values.label + "Investigation details added Successfully !"
      );
    });
  };

  // Process form values before submitting
  const transform = (onSubmitFunc) => (data) => {
    let values = data;

    values.patientId = contact.clientId;

    values.date = datetoArray(values.date);
    values.time = values.time ? timeToArray(values.time) : null;
    console.log("values,,", values);

    if (values.testItem && values.testItem.key) {
      values.testItem = values.testItem.key;
    } else if (values.testItem && !values.testItem.key) {
      values.testItem = values.testItem;
    } else {
      values.testItem = "";
    }
    // console.log(values);
    onSubmitFunc(values);
  };
  // on contacts modal close
  const onClose = () => {
    dispatch(onMedicFormClose());
  };

  return (
    <Fragment>
      <DialogTitle id="contact-form" onClose={onClose}>
        {getFormTitle(addMedModal, formMedFor, contact.name)}
      </DialogTitle>
      <DialogContent dividers={true}>
        {alertMsg && <Alert color="success"> {alertMsg} </Alert>}

        {medformerror && <Alert color="danger"> {medformerror.message} </Alert>}
        <input
          ref={register}
          name="patientId"
          type="text"
          hidden
          defaultValue={contact.clientId}
        />
        <InvestigationForm
          {...{
            control,
            errors,
            register,
            watch,
            getValues,
            setValue,
            accConfig,
            icds,
          }}
        />
      </DialogContent>
      <DialogActions>
        {/*
        <IntlMessages id="appModule.enterMore" />
         <Controller
          as={Checkbox}
          name={`more`}
          type="checkbox"
          control={control}
        /> */}
        {/* </FormControl> */}
        <Button
          // type="button"
          variant="contained"
          color="secondary"
          onClick={() => onClose()}
        >
          Back to Medical History
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSubmit(transform(onSubmit))}
          disabled={is_saving}
        >
          {is_saving && <Spinner size="sm" />}
          <IntlMessages id="form.save" />
        </Button>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          onClick={() => onClose()}
        >
          <IntlMessages id="form.cancel" />
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default FormComponent;
