export const steps = [
  {
    key: 1,
    label: "Choose Template",
    icon: "template",
  },
  {
    key: 2,
    label: "Select Contacts",
    icon: "template",
  },
  {
    key: 3,
    label: "Select Deliver Channel",
    icon: "template",
  },
  {
    key: 4,
    label: "Confirm",
    icon: "template",
  },
];
