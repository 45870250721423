import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";

import {
  Button,
  FormControlLabel,
  FormLabel,
  TextField,
  Radio,
  RadioGroup,
  ButtonBase,
} from "@material-ui/core";

import { Alert } from "reactstrap";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import CircularProgress from "@material-ui/core/CircularProgress";
import IntlMessages from "util/IntlMessages";
import { signupUser, signupUserEmail } from "../actions";

import { CrudNotification } from "util/helpers";
import { isLoggedIn } from "util/check-auth";

const defaultVal = {
  name: "",
  email: "",
  organization: "",
  phone: "",
  accountType: "TRIAL",
  password: "",
  usefor: "personal",
  confirmcode: "",
  code: "",
};
const formSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required("Email is Required")
    .min(2, "Must be longer than 4 characters"),
  codeconfirm: yup.string().required("Code is required"),
  code: yup
    .string()
    .test("code-match", "Code doesn't must match", function (value) {
      return this.parent.codeconfirm === value;
    }),
});

const ConfirmSignup = (props) => {
  const dispatch = useDispatch();
  const [userVal, setUserVal] = useState(defaultVal);

  const { loader, alertMessage, showMessage, authUser, error } = useSelector(
    ({ auth }) => auth
  );

  const {
    handleSubmit,
    register,
    reset,
    control,
    errors,
    setValue,
    getValues,
  } = useForm({
    validationSchema: formSchema,
    defaultValues: defaultVal,
    mode: "onBlur",
  });

  useEffect(() => {
    let newuser = JSON.parse(sessionStorage.getItem("newuser"));
    if (newuser && newuser.codeconfirm) {
      setUserVal(newuser);
      setValue("name", newuser.name);
      setValue("email", newuser.email);
      setValue("organization", newuser.organization);
      setValue("phone", newuser.phone);
      setValue("accountType", newuser.accountType);
      setValue("password", newuser.password);
      setValue("codeconfirm", newuser.codeconfirm);
    } else {
      props.setIsSignUp(true);
    }
  }, []);

  const onSubmit = (values) => {
    dispatch(signupUser(values)).then((response) => {
      props.history.push("/signin");
      CrudNotification("success", "Registered Successfully !");
    });
  };

  const lostCode = () => {
    dispatch(signupUserEmail(userVal)).then((response) => {
      props.setIsSignUp(false);
      CrudNotification("success", "Please Enter the code !");
    });
  };

  return (
    <div className="app-login-content">
      <div className="app-login-header">
        <h1>
          <IntlMessages id="appModule.verifyCode" />
        </h1>
      </div>
      {error && <Alert color="danger">{error.message}</Alert>}

      <div className="app-login-form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <input ref={register} name="name" type="text" hidden />
          <input ref={register} name="organization" type="text" hidden />
          <input ref={register} name="phone" type="text" hidden />
          <input ref={register} name="accountType" type="text" hidden />
          <input ref={register} name="password" type="text" hidden />
          <input ref={register} name="codeconfirm" type="text" hidden />
          <fieldset>
            <Controller
              as={TextField}
              label={<IntlMessages id="appModule.email" />}
              name="email"
              control={control}
              fullWidth
              margin="normal"
              defaultValue={userVal.email}
              disabled
            />

            <Controller
              as={TextField}
              label={<IntlMessages id="appModule.code" />}
              name="code"
              control={control}
              fullWidth
              margin="normal"
              error={errors.code}
              helperText={errors.code && errors.code.message}
            />

            <div className="mb-3 d-flex align-items-center justify-content-between mt-1 my-sm-3">
              <Button variant="contained" color="primary" type="submit">
                <IntlMessages id="appModule.confirm" />
              </Button>
              <Link to="/signin">
                <IntlMessages id="signUp.alreadyMember" />
              </Link>
            </div>
            <div className="app-social-block my-1 my-sm-3">
              <button
                className="btn btn-link"
                size="small"
                color="primary"
                onClick={() => lostCode()}
              >
                <IntlMessages id="appModule.resendCode" />
              </button>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  );
};

export default withRouter(ConfirmSignup);
