// material ui
import { Button, DialogActions, DialogContent } from "@material-ui/core";
import DialogTitle from "components/Dialog/DialogTitle";
import React, { Fragment, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Spinner } from "reactstrap";
import { CrudNotification } from "util/helpers";
import IntlMessages from "util/IntlMessages";
import { getAllVisits, onVisitForm } from "../../actions";
import { onObservationFormClose, onObservationRecordSave } from "../actions";
import ObservationForm from "../ObservationForm";
// import InvestigationForm from "./InvestigationForm";
// formschema and initvalue
import {
  initObservationVal,
  observationSchema,
  observationSchemaAdd,
} from "./formindex";

const FormComponent = (props) => {
  const dispatch = useDispatch();
  const {
    visit,
    addObservationModal,
    updateObservationFormView,
    updateObservationFor,
    observationformerror,
    is_saving,
  } = useSelector(({ visitStore }) => visitStore);

  const { icds, accountDefaultUnits } = useSelector(
    ({ configStore }) => configStore
  );

  const { contact } = useSelector(({ contactStore }) => contactStore);
  const {
    handleSubmit,
    register,
    reset,
    control,
    errors,
    setValue,
    getValues,
    watch,
  } = useForm({
    validationSchema: updateObservationFormView
      ? observationSchema
      : observationSchemaAdd,
    defaultValues: updateObservationFormView ? visit : initObservationVal,
    mode: "onBlur",
  });

  useEffect(() => {
    setValue("patientId", contact.clientId);
  }, []);

  const onSubmit = (values) => {
    return dispatch(onObservationRecordSave(values)).then(async (res) => {
      //   dispatch(clearIcds());
      CrudNotification("success", "Observation Test added Successfully !");
      await dispatch(getAllVisits(visit?.patientId));
      await dispatch(onObservationFormClose());
      await dispatch(onVisitForm(visit?.visitId, "view"));

      // await dispatch(onVisitClose());
    });
  };

  // Process form values before submitting
  const transform = (onSubmitFunc) => (data) => {
    let values = data;
    values.id = visit.id;

    // values.patientId = contact.clientId;
    if (values.weight && accountDefaultUnits.weightUnit === "lb") {
      values.weight.weight = (values.weight.weight / 2.20462262185).toFixed(2);
    }

    if (values.height && values.height.unit === "IN") {
      values.height.heightText = values.height.feet + "/" + values.height.inch;
    }

    if (values.id === "") {
      delete values.id;
    }
    onSubmitFunc(values);
  };
  // on contacts modal close
  const onClose = () => {
    dispatch(onObservationFormClose());
    // dispatch(clearIcds());
  };
  return (
    <Fragment>
      <DialogTitle id="contact-form" onClose={onClose}>
        {addObservationModal
          ? "Add "
          : "Update " + contact.name + "'s " + "Observation"}
        {/* {getFormTitle(addObservationModal, updateObservationFor, contact.name)} */}
      </DialogTitle>
      <DialogContent dividers={true}>
        {observationformerror && (
          <Alert color="danger"> {observationformerror.message} </Alert>
        )}

        {!updateObservationFor && (
          <ObservationForm
            {...{
              control,
              register,
              getValues,
              errors,
              setValue,
              updateObservationFor,
              watch,
            }}
          />
        )}
        {updateObservationFor === "observation" && (
          <ObservationForm
            {...{
              control,
              register,
              getValues,
              errors,
              setValue,
              updateObservationFor,
              watch,
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSubmit(transform(onSubmit))}
          disabled={is_saving}
        >
          {is_saving && <Spinner size="sm" />}
          <IntlMessages id="form.save" />
        </Button>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          onClick={() => onClose()}
        >
          <IntlMessages id="form.cancel" />
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default FormComponent;
