import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemAvatar,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CardHeader,
} from "@material-ui/core";

import CustomScrollbars from "util/CustomScrollbars";

const MessageLogDetail = (props) => {
  const dispatch = useDispatch();
  const { width } = useSelector(({ uiConfigureStore }) => uiConfigureStore);
  const { msgLogs, msgLogs_loading, logError, msgLog, msgLog_loading } =
    useSelector(({ messageStore }) => messageStore);
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <>
      {msgLog.id && <CardHeader title={msgLog.subject} />}
      <CustomScrollbars
        style={{
          height: width >= 1200 ? "calc(60vh - 200px)" : "calc(60vh - 80px)",
        }}
      >
        {!msgLog.id && (
          <div className="h-100 d-flex align-items-center justify-content-center">
            Click on a log to view Message Log detail
          </div>
        )}
        {msgLog.id && (
          <div>
            <Accordion
              square
              expanded={expanded === "email"}
              onChange={handleChange("email")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <span>Emails {"(" + msgLog.emails.count + ")"}</span>
              </AccordionSummary>
              <AccordionDetails>
                <List style={{ width: "100%", maxWidth: 360 }}>
                  {msgLog.emails.addresses &&
                    msgLog.emails.addresses.map((email, index) => {
                      return (
                        <ListItem key={index}>
                          <ListItemAvatar>
                            <Avatar>{/* <ImageIcon /> */}</Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={email.name}
                            secondary={email.address}
                          />
                        </ListItem>
                      );
                    })}
                </List>
              </AccordionDetails>
            </Accordion>

            <Accordion
              square
              expanded={expanded === "texts"}
              onChange={handleChange("texts")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <span>
                  Texts {msgLog.texts && "(" + msgLog.texts.count + ")"}
                </span>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {msgLog.texts.addresses &&
                    msgLog.texts.addresses.map((text, index) => (
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>{/* <ImageIcon /> */}</Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={text.name}
                          secondary={text.address}
                        />
                      </ListItem>
                    ))}
                </List>
              </AccordionDetails>
            </Accordion>
          </div>
        )}
      </CustomScrollbars>
    </>
  );
};

export default MessageLogDetail;
