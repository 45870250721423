import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton } from "@material-ui/lab";
import { CardHeader, Tooltip, IconButton } from "@material-ui/core";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Close as CloseIcon,
} from "@material-ui/icons";
import Widget from "components/Widget";
import AlertBox from "components/AlertBox/index";
import IntroForm from "./IntroForm";

const Intro = () => {
  const { client, clientLoading, clientError } = useSelector(
    ({ profileStore }) => profileStore
  );
  const { about } = client;
  const [showForm, setShowForm] = useState(false);

  return (
    <>
      <Widget styleName="jr-card-full jr-card-tabs-right jr-card-profile">
        <CardHeader
          action={
            client.id &&
            about && (
              <Tooltip title="Add Test ">
                <IconButton
                  aria-label="Add"
                  onClick={() => setShowForm(!showForm)}
                  variant="contained"
                  color="primary"
                >
                  {showForm ? <CloseIcon /> : <EditIcon />}
                </IconButton>
              </Tooltip>
            )
          }
          title={"Intro"}
        />
        <div className="jr-tabs-content jr-task-list">
          {!client.id && <Skeleton variant="rect" height={100} />}
          {client.id && !showForm && (
            <>
              {!about && (
                <span>
                  Would you like to add an introduction for {client.name} ?{" "}
                  <IconButton color="primary" onClick={() => setShowForm(true)}>
                    <AddIcon />
                  </IconButton>
                </span>
              )}

              {about && <div dangerouslySetInnerHTML={{ __html: about }}></div>}
            </>
          )}
          {client.id && showForm && (
            <IntroForm onClose={() => setShowForm(false)} />
          )}
        </div>
      </Widget>
    </>
  );
};

export default Intro;
