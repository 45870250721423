const initialState = {
  loading: false,
  messages: [],
  errors: [],
  formerror: null,
  error: "",

  // Notes
  errorMsg: "",
  visitError: null,
  visits: [],
  visitsLoading: false,
  // NoteForm
  visit: {},
  visit_open: false,
  aboutType: "other",
  addFormVisit: false,
  updateVisit: false,
  viewVisit: false,
  is_saving: false,
  visitTags: [],
  //DEPARTMENT
  departments: [],
  departmentsLoading: false,
  departmentError: null,
  //UNIT
  units: [],
  //PES
  addPesModal: false,
  updatePesFormView: false,
  updatePesFor: null,
  pesformerror: null,
  //CHECKOUT
  addCheckoutModal: false,
  updateCheckoutFormView: false,
  updateCheckoutFor: null,
  checkoutformerror: null,
  //RECOMMENDEDTEST
  addRecommendedTestModal: false,
  updateRecommendedTestFormView: false,
  updateRecommendedTestFor: null,
  recommendedTestformerror: null,
  //OBSERVATION
  addObservationModal: false,
  updateObservationFormView: false,
  updateObservationFor: null,
  observationformerror: null,
  //COMPLAIN
  addComplainModal: false,
  updateComplainFormView: false,
  updateComplainFor: null,
  complainformerror: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    //get Departments
    case "GET_ALLDEPARTMENT_FULFILLED": {
      const departments = action.payload.data;
      return {
        ...state,
        departments: departments,
        departmentsLoading: false,
        departmentError: null,
      };
    }
    case "GET_ALLDEPARTMENT_PENDING": {
      return {
        ...state,
        departments: [],
        departmentsLoading: true,
        departmentError: null,
      };
    }
    case "GET_ALLDEPARTMENT_REJECTED": {
      return {
        ...state,
        error: action.payload.response.data,
        departments: [],
        messages: [],
        errors: [],
        departmentsLoading: false,
        departmentError: action.payload.response.data.message,
      };
    }
    //get Units
    case "GET_ALLUNIT_FULFILLED": {
      const units = action.payload.data;
      return {
        ...state,
        units: units,
        unitsLoading: false,
        unitError: null,
      };
    }
    case "GET_ALLUNIT_PENDING": {
      return {
        ...state,
        units: [],
        unitsLoading: true,
        unitError: null,
      };
    }
    case "GET_ALLUNIT_REJECTED": {
      return {
        ...state,
        error: action.payload.response.data,
        units: [],
        messages: [],
        errors: [],
        unitsLoading: false,
        unitError: action.payload.response.data.message,
      };
    }
    // get client detail by Id
    case "GET_ALLVISIT_FULFILLED": {
      const visits = action.payload.data;
      return {
        ...state,
        visits: visits,
        visitsLoading: false,
        visitError: null,
      };
    }
    case "GET_ALLVISIT_PENDING": {
      return {
        ...state,
        visits: [],
        visitsLoading: true,
        visitError: null,
      };
    }
    case "GET_ALLVISIT_REJECTED": {
      return {
        ...state,
        error: action.payload.response.data,
        visits: [],
        messages: [],
        errors: [],
        visitsLoading: false,
        visitError: action.payload.response.data.message,
      };
    }
    case "VISITFORM": {
      let formType = action.payload.formParam.formType;
      let aboutType = action.payload.formParam.aboutType;

      return {
        ...state,
        visit:
          formType === "update" || formType === "view"
            ? action.payload.visit
            : null,
        addFormVisit: formType === "add" ? true : false,
        updateVisit: formType === "update" ? true : false,
        viewVisit: formType === "view" ? true : false,
        aboutType: aboutType,
      };
    }
    case "INBOXCLOSE": {
      // console.log(action.payload)
      return {
        ...state,
        visit: null,
        addFormVisit: false,
        updateVisit: false,
        viewVisit: false,
        aboutType: "other",
      };
    }

    // Save visit Notes
    case "VISIT_ADD_FULFILLED": {
      const visits = [action.payload.data, ...state.visits];
      // console.log(action.payload.data);
      // console.log(visits);
      return {
        ...state,
        visits: [action.payload.data, ...state.visits],
        visit: action.payload.data,
        viewVisit: true,
        updateVisit: false,
        addFormVisit: false,
        formerror: null,
        is_saving: false,
      };
    }
    case "VISIT_ADD_PENDING": {
      return {
        ...state,
        is_saving: true,
      };
    }
    case "VISIT_ADD_REJECTED": {
      return {
        ...state,
        formerror: action.payload.response.data,
        is_saving: false,
        messages: [],
        errors: [],
      };
    }

    // Update Contacts
    case "VISIT_UPDATE_FULFILLED": {
      const visit = action.payload.data;
      const visits = state.visits.map((item) =>
        item.id === visit.id ? visit : item
      );
      // console.log(visits);
      return {
        ...state,
        visits: state.visits.map((item) =>
          item.id === visit.id ? visit : item
        ),
        visit: visit,
        viewVisit: true,
        updateVisit: false,
        addFormVisit: false,
        formerror: null,
        is_saving: false,
      };
    }
    case "VISIT_UPDATE_PENDING": {
      return {
        ...state,
        is_saving: true,
      };
    }
    case "VISIT_UPDATE_REJECTED": {
      return {
        ...state,
        formerror: action.payload.response.data,
        messages: [],
        errors: [],
        is_saving: false,
      };
    }

    // Delete Contacts
    case "VISIT_DELETE_FULFILLED": {
      let visit = action.payload.data;
      // visit.status = "DELETED";
      // console.log(visit);
      return {
        ...state,
        visits: state.visits.map((item) =>
          item.id === visit.id ? visit : item
        ),
        visit: {},
        viewVisit: false,
        updateVisit: false,
        addFormVisit: false,
        formerror: null,
        is_saving: false,
      };
    }

    case "VISIT_ADD_INBOXSTORE": {
      return initialState;
    }

    // Note Tags
    case "VISITTAGS_FULFILLED": {
      // console.log(action.payload)
      return {
        ...state,
        visitTags: action.payload.data,
      };
    }
    case "VISITTAGS_PENDING": {
      return {
        ...state,
      };
    }
    case "VISITTAGS_REJECTED": {
      // console.log(action)
      return {
        ...state,
        visitTags: [],
        error: action.payload.response,
        messages: [],
        errors: [],
      };
    }
    // toggle medication modal
    case "PESCRIPTIONFORM": {
      return {
        ...state,
        addPesModal: action.payload.formtype === "add" ? true : false,
        updatePesFormView: action.payload.formtype === "update" ? true : false,
        updatePesFor: action.payload.formFor,
      };
    }
    // toggle medication modal to close
    case "PESFORMCLOSE": {
      return {
        ...state,
        // Pes Record
        addPesModal: false,
        updatePesFormView: false,
        updatePesFor: null,
        pesformerror: null,
      };
    }

    // CHECKOUT
    // toggle medication modal
    case "CHECKOUTFORM": {
      return {
        ...state,
        addCheckoutModal: action.payload.formtype === "add" ? true : false,
        updateCheckoutFormView:
          action.payload.formtype === "update" ? true : false,
        updateCheckoutFor: action.payload.formFor,
      };
    }
    // toggle medication modal to close
    case "CHECKOUTFORMCLOSE": {
      return {
        ...state,
        // Pes Record
        //
        addCheckoutModal: false,
        updateCheckoutFormView: false,
        updateCheckoutFor: null,
        checkoutformerror: null,
      };
    }
    // RECOMMENDEDTEST
    // toggle medication modal
    case "RECOMMENDEDTESTFORM": {
      console.log("===", action.payload);
      return {
        ...state,
        addRecommendedTestModal:
          action.payload.formtype === "add" ? true : false,
        updateRecommendedTestFormView:
          action.payload.formtype === "update" ? true : false,
        updateRecommendedTestFor: action.payload.formFor,
      };
    }
    // toggle medication modal to close
    case "RECOMMENDEDTESTFORMCLOSE": {
      return {
        ...state,

        addRecommendedTestModal: false,
        updateRecommendedTestFormView: false,
        updateRecommendedTestFor: null,
        recommendedTestformerror: null,
      };
    }

    //OBSERVATION START
    case "OBSERVATION_FORM": {
      console.log("===", action.payload);
      return {
        ...state,
        addObservationModal: action.payload.formtype === "add" ? true : false,
        updateObservationFormView:
          action.payload.formtype === "update" ? true : false,
        updateObservationFor: action.payload.formFor,
      };
    }
    // toggle medication modal to close
    case "OBSERVATION_FORMCLOSE": {
      return {
        ...state,

        addObservationModal: false,
        updateObservationFormView: false,
        updateObservationFor: null,
        observationformerror: null,
      };
    }
    //end- COMPAINTS
    //OBSERVATION START
    case "COMPLAIN_FORM": {
      console.log("===", action.payload);
      return {
        ...state,
        addComplainModal: action.payload.formtype === "add" ? true : false,
        updateComplainFormView:
          action.payload.formtype === "update" ? true : false,
        updateComplainFor: action.payload.formFor,
      };
    }
    // toggle medication modal to close
    case "COMPLAIN_FORMCLOSE": {
      return {
        ...state,

        addComplainModal: false,
        updateComplainFormView: false,
        updateComplainFor: null,
        complainformerror: null,
      };
    }
    //end- COMPAINTS
    case "VISITEMPTY": {
      return { ...state, visit: {}, viewVisit: false };
    }
    case "VISITOPEN": {
      return {
        ...state,

        visit_open: action.payload,
      };
    }
    default:
      return state;
  }
};
