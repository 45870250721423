import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import {
  getAppUiConfig,
  // getAllConfigData,
  getConfigData,
} from "services/actions";
import asyncComponent from "../util/asyncComponent";
// Setting Actions
import { setLoggedUser } from "./Account/actions";
import UserProfile from "./Account/UserProfile";
//  Containers
import Contact from "./Contact";
import Inbox from "./Inbox";
import MessagePage from "./MessagePage";
import Visit from "./Profile/Visit";
import Profile from "./Profile";
import Settings from "./Settings";
import TaskPage from "./TaskPage";
import Template from "./Template";
import VisitReport from "./Profile/Visit/VisitForm/VisitReport";

// const lookupArray = [
//   "tags",
//   "maritalStatus",
//   "userAccessGroup",
//   "userRole",
//   "clientRole",
//   "status",
//   "customFieldType",
//   "gender",
//   "emailType",
//   "addressType",
//   "phoneType",
//   "jobSector",
//   "religion",
//   "salutation",
//   "salutationEndsWith",
//   // "countries",
//   "taskStatus",
// ];
const lookupArray = [
  "addressType",
  "bedType",
  "bloodType",
  "clientRole",
  "consciousnessLevel",
  "customFieldType",
  "diagnosisType",
  "drugType",
  "emailType",
  "ewsFrequency",
  "familyRelations",
  "gender",
  "heightUnit",
  "intakeFluid",
  "intakeMethod",
  "intakeOutputType",
  "intakeOutputUnit",
  "interviewQuestion",
  "investigationGroup",
  "jobSector",
  "maritalStatus",
  "medicalHistory",
  "observationType",
  "outputFluid",
  "outputMethod",
  "phoneType",
  "referrer",
  "religion",
  "salutation",
  "salutationEndsWith",
  "status",
  "taskCategory",
  "taskStatus",
  "temperatureUnit",
  "userAccessGroup",
  "userRole",
  "weightUnit",
];
const Routes = ({ match }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    // only runs once
    dispatch(setLoggedUser());
    dispatch(getAppUiConfig());
    // dispatch(getAllConfigData());
    lookupArray.forEach((val) => {
      dispatch(getConfigData(val));
    });
  }, []);
  return (
    <div className="dashboard animated slideInUpTiny animation-duration-3">
      <div className="app-wrapper">
        <Switch>
          <Route path={`${match.url}/patients`} component={Contact} />
          <Route path={`${match.url}/viewPatient/:id`} component={Profile} />

          <Route path={`${match.url}/contacts`} component={Contact} />
          <Route path={`${match.url}/contact/:id`} component={Profile} />

          <Route path={`${match.url}/settings`} component={Settings} />
          <Route path={`${match.url}/inbox`} component={Inbox} />
          <Route path={`${match.url}/template`} component={Template} />
          <Route path={`${match.url}/message`} component={MessagePage} />
          <Route path={`${match.url}/visit`} component={Visit} />
          <Route path={`${match.url}/tasks`} component={TaskPage} />

          <Route path={`${match.url}/account`} component={UserProfile} />
          <Route
            component={asyncComponent(() => import("./extraPages/routes/404"))}
          />
        </Switch>
      </div>
    </div>
  );
};

export default withRouter(Routes);
