import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect, Route, Switch, withRouter } from "react-router-dom";

import Auxiliary from "util/Auxiliary";

// Actions
import { setProfile, getClientNotes } from "./actions";

import { getActiveUsers, getClientById } from "services/actions";
// components
import ProfileHeader from "./ProfileHeader";
import ContactDetail from "./ContactDetail";
import About from "./About";
// import FormModal from "appRoutes/Contact/FormModal";
import ClientNotes from "./ClientNotes";
import Intro from "./Intro";
import NavBar from "./NavBar";
import {
  getMedicalDetailByPId,
  getTestDetailByPId,
} from "redux/actions/MedicalActions";
import MedicalRecord from "./MedicalRecord";
import MedicalNotes from "./MedicalNotes";
import Medications from "./Medications";
import Ews from "./Ews";
import InvestigationHistory from "./MedicalRecord/MedHistory/Investigations/InvestigationHistory";
import Insights from "./Insights";
import InPatient from "./InPatient";
import InpatientDetail from "./InPatient/InpatientDetail";
import DischargeReport from "./DischargeReport";
import DailyLogs from "./DailyLogs";
import { FormModal } from "./MedicalRecord/MedHistory";
import Visit from "appRoutes/Profile/Visit";

const Profile = (props) => {
  const dispatch = useDispatch();
  const { client, clientLoading, clientError } = useSelector(
    ({ profileStore }) => profileStore
  );
  const { uiUsers, appUiConfig } = useSelector(
    ({ uiConfigureStore }) => uiConfigureStore
  );
  const profileData = appUiConfig?.profile || {
    enableAbout: false,
    enableMedicalRecord: false,
    enableInvestigations: false,
    enableClinicalVisit: false,
    enableEWS: false,
    enableDailyLog: false,
    enableInsights: false,
  };
  const {
    enableAbout,
    enableMedicalRecord,
    enableInvestigations,
    enableClinicalVisit,
    enableEWS,
    enableDailyLog,
    enableInsights,
  } = profileData;
  const { contact } = useSelector(({ contactStore }) => contactStore);
  // const medRecordStore = useSelector((state)=>({medRecordStore:state.medRecordStore}))
  useEffect(() => {
    dispatch(getClientById(props.match.params.id));
    dispatch(getClientNotes(props.match.params.id));
    uiUsers.length === 0 && dispatch(getActiveUsers());
  }, []);

  useEffect(() => {
    // const medicalrecord = medRecordStore?.medicalrecord;
    // if(medicalrecord?.patientId !==props.match.params.id){
    dispatch(getMedicalDetailByPId(props.match.params.id));
    dispatch(getTestDetailByPId(props.match.params.id));
    // }
  }, []);

  useEffect(() => {
    contact && contact.id && dispatch(setProfile(contact));
  }, [contact]);
  return (
    <Auxiliary>
      <FormModal />
      <ProfileHeader />
      <div className="jr-profile-banner-bottom">
        <NavBar />
      </div>
      <div className="jr-profile-content">
        <div className="row">
          <div className="col-xl-8 col-lg-8 col-md-7 col-12">
            {/* <About />
            <Intro />
            <ClientNotes /> */}
            {/* <Events /> */}
            {contact && contact.id && (
              <Switch>
                <Redirect
                  exact
                  from={`${props.match.path}/`}
                  to={`${props.match.path}/about`}
                />
                {enableAbout && (
                  <Route
                    path={`${props.match.path}/about`}
                    component={AboutNav}
                  />
                )}
                {enableMedicalRecord && (
                  <Route
                    path={`${props.match.path}/medicalRecord`}
                    component={MedicalRecord}
                  />
                )}
                <Route
                  path={`${props.match.path}/medicalNotes`}
                  component={MedicalNotes}
                />
                <Route
                  path={`${props.match.path}/Medications`}
                  component={Medications}
                />
                {enableInvestigations && (
                  <Route
                    path={`${props.match.path}/investigations`}
                    component={InvestigationHistory}
                  />
                )}
                {enableInsights && (
                  <Route
                    path={`${props.match.path}/insights`}
                    component={Insights}
                  />
                )}
                {enableClinicalVisit && (
                  <Route path={`${props.match.path}/visit`} component={Visit} />
                )}
                <Route
                  path={`${props.match.path}/inpatient`}
                  component={InPatient}
                />

                <Route
                  path={`${props.match.path}/inpatientDetail/:inpatientId`}
                  component={InpatientDetail}
                />
                <Route
                  path={`${props.match.path}/dischargeReport/:inpatientId`}
                  component={DischargeReport}
                />

                {enableDailyLog && (
                  <Route
                    path={`${props.match.path}/dailyLogs`}
                    component={DailyLogs}
                  />
                )}
                {enableEWS && (
                  <Route path={`${props.match.path}/ews`} component={Ews} />
                )}
              </Switch>
            )}
          </div>
          <div className="col-xl-4 col-lg-4 col-md-5 col-12">
            <ContactDetail />
            <div className="row"></div>
          </div>
        </div>
      </div>
    </Auxiliary>
  );
};

export default withRouter(Profile);
const AboutNav = () => {
  return (
    <>
      <About />
      <Intro />
      <ClientNotes />
    </>
  );
};
