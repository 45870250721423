// material ui
import { Button, DialogActions, DialogContent } from "@material-ui/core";
// custom Components
import DialogTitle from "components/Dialog/DialogTitle";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Spinner } from "reactstrap";
// Actions
import { getActiveContacts, getActiveStaffs } from "services/actions";
import { CrudNotification } from "util/helpers";
// Utilities and helpers
import IntlMessages from "util/IntlMessages";
import {
  getAllVisits,
  onVisitAdd,
  onVisitClose,
  onVisitForm,
  onVisitUpdate,
  setVisitEmpty,
  setVisitOpen,
} from "../actions";
import { formSchema, initVisitVal } from "../Compose/formIndex";
import VisitReport from "./VisitReport";
import VisitSubjectForm from "./VisitSubjectForm";

const FormTitle = (props) => {
  const { task, updateFormVisit } = props;
  if (updateFormVisit) {
    return <span>Checkin</span>;
  } else {
    return <span>Checkin</span>;
  }
};

const VisitForm = (props) => {
  const dispatch = useDispatch();
  const { accConfig, districts, appUiConfig, uiClients, uiStaffs } =
    useSelector(({ uiConfigureStore }) => uiConfigureStore);
  const { client } = useSelector(({ profileStore }) => profileStore);
  const { visit, updateVisit, formerror, is_saving } = useSelector(
    ({ visitStore }) => visitStore
  );

  const [initialVal, setInitialVal] = useState(
    updateVisit ? visit : initVisitVal
  );

  const {
    handleSubmit,
    register,
    reset,
    control,
    errors,
    setValue,
    getValues,
    watch,
  } = useForm({
    validationSchema: formSchema,
    defaultValues: initialVal,
    mode: "onBlur",
  });

  useEffect(() => {
    uiStaffs.length === 0 && dispatch(getActiveStaffs());
    uiClients.length === 0 && dispatch(getActiveContacts());
  }, []);

  // On update submit
  const onSubmit = (values) => {
    if (values.id) {
      // console.log("add", values);
      const visitId = visit.visitId;

      return dispatch(onVisitUpdate(values)).then(async (res) => {
        CrudNotification("success", "Visit updated Successfully !");
        await dispatch(getAllVisits(client?.clientId));
        // await dispatch(setVisitEmpty());
        await dispatch(onVisitForm(visitId, "view"));

        // await dispatch(setVisitOpen(false));
      });
    } else {
      // console.log("update", values);
      return dispatch(onVisitAdd(values)).then(async (res) => {
        CrudNotification("success", "Visit added Successfully !");

        await dispatch(getAllVisits(client?.clientId));
        // await dispatch(setVisitEmpty());

        // await dispatch(setVisitOpen(false));
      });
    }
  };

  // Process form values before submitting
  const transform = (onSubmitFunc) => (data) => {
    let values = { ...data };
    if (client?.clientId) {
      values.patientId = client?.clientId;
      values.patientName = client?.name;
    }
    if (data.department && data.department.key) {
      values.department = data.department.key;
    } else {
      values.department = "OTHER";
    }
    if (data.unit && data.unit.key) {
      values.unit = data.unit.key;
    } else {
      values.unit = "OTHER";
    }
    if (data.assignedId && data.assignedId.clientId) {
      values.assignedId = data.assignedId.clientId;
      values.assignedName = data.assignedId.name;
    } else {
      values.assignedName = values.assignedName ? values.assignedName : null;
      values.assignedId = values.assignedId ? values.assignedId : null;
    }

    if (data.startDateTime) {
      values.startDateTime = moment(data.startDateTime).utc().toISOString();
    }

    // if (values.accessGroups) {
    //   values.accessGroups.map((accessGroup, index) => {
    //     if (accessGroup.group === "ALL") {
    //       values.accessGroups.splice(index);
    //     } else {
    //       if (accessGroup.members && accessGroup.members.length !== 0) {
    //         accessGroup.members = accessGroup.members.map((a) =>
    //           a.id ? a.id : a
    //         );
    //         accessGroup.members = [...new Set(accessGroup.members)];
    //         return accessGroup;
    //       }
    //     }
    //   });
    // }

    onSubmitFunc(values);
  };
  // on contacts modal close
  const onClose = () => {
    dispatch(onVisitClose());
  };

  return (
    <Fragment>
      <DialogTitle id="contact-form" onClose={onClose}>
        <FormTitle updateVisit={updateVisit} visit={visit} />
      </DialogTitle>
      <DialogContent dividers={true}>
        {formerror && <Alert color="danger"> {formerror.message} </Alert>}
        {updateVisit && (
          <input
            ref={register}
            name="id"
            type="text"
            hidden
            defaultValue={visit.id}
          />
        )}
        {/* <input
          ref={register}
          name="accountId"
          type="text"
          hidden
          defaultValue={contact.clientId}
        /> */}

        <VisitSubjectForm
          {...{
            control,
            register,
            getValues,
            setValue,
            errors,
            updateVisit,
            visit,
            watch,
          }}
        />

        {/* <AccessGroupsInput
          label="Share with..."
          formVals={initialVal}
          groups={groups}
          setGroups={setGroups}
        /> */}
      </DialogContent>
      <DialogActions>
        <Button
          className="button buttonBlack"
          type="button"
          onClick={() => {
            reset(initialVal);
          }}
        ></Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSubmit(transform(onSubmit))}
          disabled={is_saving}
        >
          {is_saving && <Spinner size="sm" />}
          <IntlMessages id="form.save" />
        </Button>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          onClick={() => onClose()}
        >
          <IntlMessages id="form.cancel" />
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default VisitForm;
