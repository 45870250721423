import * as yup from "yup";

export const initInboxVal = {
  accountId: "",
  name: "",
  subject: "",
  body: "",
  personalization: {
    salutation: "",
    useFirstNameOnly: true,
    endsWith: "",
  },
  score: 0,
  status: "ACTIVE",
};

export const formSchema = yup.object().shape({
  name: yup.string().required("name is Required"),
  subject: yup.string().required("Subject is Required"),
  body: yup.string().required("Body is Required"),
  status: yup.string().required("Status is Required"),
  personalization: yup
    .object()
    .default(null)
    .nullable()
    .shape({
      salutation: yup.string().required("Salutation is required"),
    }),
});
