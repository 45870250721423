import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Controller } from "react-hook-form";

import moment from "moment";
import {
  Grid,
  TextField,
  FormControl,
  Container,
  CardHeader,
} from "@material-ui/core";

import { DateTimeInput } from "util/formInput";

import IntlMessages from "util/IntlMessages";

export default function OxygenSatForm(props) {
  const {
    control,
    setValue,
    getValues,
    errors,
    register,
    updateMedicalFor,
  } = props;

  const { accConfig } = useSelector(({ uiConfigureStore }) => uiConfigureStore);
  const values = getValues({ nest: true });
  return (
    <div>
      {updateMedicalFor !== "oxygenSat" && (
        <CardHeader title={<h2>Oxygen Saturation</h2>} />
      )}
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormControl fullWidth margin="normal">
              <DateTimeInput
                setAgeValue={setValue}
                value={values.oxygenSaturation && values.oxygenSaturation.date}
                label={<IntlMessages id="medicRecord.form.date" />}
                name="oxygenSaturation.date"
                format="YYYY-MM-DD"
                inputRef={register}
                fullWidth
                autoOk
              />
            </FormControl>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth margin="normal">
              <Controller
                label={<IntlMessages id="medicRecord.form.oxygenSaturation" />}
                as={TextField}
                name="oxygenSaturation.oxygenSaturation"
                control={control}
                fullWidth
                error={
                  errors.oxygenSaturation &&
                  errors.oxygenSaturation.oxygenSaturation
                }
                helperText={
                  errors.oxygenSaturation &&
                  errors.oxygenSaturation.oxygenSaturation &&
                  errors.oxygenSaturation.oxygenSaturation.message
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
