// get visitRecord list by Patient Id

import { api } from "util/api";

// MedRecordStore
export function onAddPesForm(formtype = "add", formFor = null) {
  return (dispatch) => {
    return dispatch({
      type: "PESCRIPTIONFORM",
      payload: { formtype: formtype, formFor: formFor },
    });
  };
}

// toggle patient visit details modal
// MedRecordStore
export function onPesFormClose() {
  return (dispatch) => {
    return dispatch({
      type: "PESFORMCLOSE",
    });
  };
}

// On visit record Save
export function onPesRecordSave(value) {
  return (dispatch) => {
    return dispatch({
      type: "PESRECORD_SAVE",
      payload: api.post("visit/clinical", value),
    });
  };
}
