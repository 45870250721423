import React, { useEffect, useState } from "react";
import { useFieldArray, Controller } from "react-hook-form";

import {
  Grid,
  TextField,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment,
  Divider,
  Container,
  Avatar,
  CardHeader,
  IconButton,
  Tooltip,
  Button,
} from "@material-ui/core";

import { Refresh, Add as AddIcon, Clear, Close } from "@material-ui/icons";

import IntlMessages from "util/IntlMessages";

export default function ObservationForm(props) {
  const { control, errors, accConfig, register, getValues } = props;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "clinicalRecord.clinicalExam.observations",
    keyName: "keyId", //, default to "id", you can change the key name
  });

  const values = getValues({ nest: true });
  const onAdd = () => {
    append({
      observationType: "",
      observation: "",
    });
  };

  const onRemove = (index) => {
    remove(index);
  };

  useEffect(() => {
    if (fields.length === 0) {
      onAdd();
    }
  }, []);
  const onReset = (index) => {
    remove(index);
    onAdd();
    // console.log('reset');
  };

  // console.log(errors);
  return (
    <div>
      {fields.map((item, index) => {
        return (
          <div key={item.keyId}>
            <Container style={{ paddingBottom: 25 }}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel htmlFor="unit">
                      <IntlMessages id="inpatient.form.clinicalRecord.observationType" />
                    </InputLabel>
                    <Controller
                      as={
                        <Select>
                          <MenuItem value="" disabled>
                            {" "}
                            Observation Type
                          </MenuItem>
                          {accConfig.observationType &&
                            accConfig.observationType.map((val) => (
                              <MenuItem value={val.key} key={val.key}>
                                {val.value}
                              </MenuItem>
                            ))}
                        </Select>
                      }
                      name={`clinicalRecord.clinicalExam.observations[${index}].observationType`}
                      label={
                        <IntlMessages id="inpatient.form.clinicalRecord.observationType" />
                      }
                      control={control}
                      fullWidth
                      defaultValue={item.observationType}
                      error={
                        errors.clinicalRecord &&
                        errors.clinicalRecord.clinicalExam &&
                        errors.clinicalRecord.clinicalExam.observations &&
                        errors.clinicalRecord.clinicalExam.observations[
                          index
                        ] &&
                        errors.clinicalRecord.clinicalExam.observations[index]
                          .observationType
                      }
                      helperText={
                        errors.clinicalRecord &&
                        errors.clinicalRecord.clinicalExam &&
                        errors.clinicalRecord.clinicalExam.observations &&
                        errors.clinicalRecord.clinicalExam.observations[
                          index
                        ] &&
                        errors.clinicalRecord.clinicalExam.observations[index]
                          .observationType &&
                        errors.clinicalRecord.clinicalExam.observations[index]
                          .observationType.message
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={11} sm={11}>
                  <FormControl fullWidth margin="normal">
                    <Controller
                      label={
                        <IntlMessages id="inpatient.form.clinicalRecord.observation" />
                      }
                      as={TextField}
                      name={`clinicalRecord.clinicalExam.observations[${index}].observation`}
                      control={control}
                      fullWidth
                      multiline
                      defaultValue={item.note}
                      error={
                        errors.clinicalRecord &&
                        errors.clinicalRecord.clinicalExam &&
                        errors.clinicalRecord.clinicalExam.observations &&
                        errors.clinicalRecord.clinicalExam.observations[
                          index
                        ] &&
                        errors.clinicalRecord.clinicalExam.observations[index]
                          .observation
                      }
                      helperText={
                        errors.clinicalRecord &&
                        errors.clinicalRecord.clinicalExam &&
                        errors.clinicalRecord.clinicalExam.observations &&
                        errors.clinicalRecord.clinicalExam.observations[
                          index
                        ] &&
                        errors.clinicalRecord.clinicalExam.observations[index]
                          .observation &&
                        errors.clinicalRecord.clinicalExam.observations[index]
                          .observation.message
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={1} sm={1}>
                  <Container>
                    <Button
                      color="secondary"
                      aria-label="Remove"
                      onClick={() => onRemove(index)}
                      size="small"
                    >
                      <Tooltip
                        title={"Remove Observation Notes  " + (index + 1)}
                        size="large"
                      >
                        <Clear />
                      </Tooltip>
                    </Button>
                  </Container>
                </Grid>
              </Grid>
            </Container>

            {index + 1 !== fields.length && <Divider />}
          </div>
        );
      })}

      <Tooltip title="Click to Add Observation Detail">
        <Button
          aria-label="Add"
          onClick={() => onAdd()}
          variant="contained"
          color="primary"
        >
          Add More <AddIcon />
        </Button>
      </Tooltip>
    </div>
  );
}
