import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

// Vendors Components
import {
  CardHeader,
  Tooltip,
  IconButton,
  CardContent,
  Grid,
} from "@material-ui/core";

import { AccountCircle, Edit as EditIcon } from "@material-ui/icons";

//  Themes Components

import Auxiliary from "util/Auxiliary";
import Widget from "components/Widget/index";
import AlertBox from "components/AlertBox/index";
// Custom Components and Helpers
// import { capitalize } from "util/helpers";

// Components
import NoDetail from "../NoDetail";
import ChiefComplaints from "./ChiefComplaints";

import Observations from "./Observations";
import Illnesses from "./Illnesses";
import Findings from "./Findings";
import ClinicalExam from "./ClinicalExam";

import { getValuefromLookup, displayDate } from "util/helpers";
//  Actions
import { onInPatientForm } from "redux/actions/MedicalActions";

const ClinicalRecords = (props) => {
  const dispatch = useDispatch();
  //   const { contact } = useSelector(({ contactStore }) => contactStore);

  const { accConfig } = useSelector(({ uiConfigureStore }) => uiConfigureStore);
  const { inPatient, inpatient_loading } = useSelector(
    ({ medRecordStore }) => medRecordStore
  );

  const { inverted = "timeline-inverted" } = props;

  const onAddInpatientRecord = () => {
    dispatch(onInPatientForm("update", "clinicalrecord"));
  };
  return (
    <div className={"timeline-item " + inverted}>
      {!inPatient.clinicalRecord && (
        <NoDetail
          title={"Clinical Records"}
          loading={inpatient_loading}
          onAddInpatientRecord={onAddInpatientRecord}
          // inverted={inverted}
        />
      )}
      {inPatient.clinicalRecord && (
        <div>
          {/* <div className="timeline-time">
            {inPatient.admission.dateDisplay}
            <br /> {inPatient.admission.timeDisplay}
          </div> */}
          <div className={`timeline-badge bg-green`}>
            <AccountCircle />
          </div>

          <div className={"timeline-panel mytimeline"}>
            <CardHeader
              // action={
              //   inPatient.id && (
              //     <Tooltip title="Update Clinical Detail Info ">
              //       <IconButton
              //         aria-label="Add"
              //         onClick={() => onAddInpatientRecord()}
              //         variant="contained"
              //         color="primary"
              //       >
              //         <EditIcon />
              //       </IconButton>
              //     </Tooltip>
              //   )
              // }
              title={"Clinical Records"}
              subheader={
                <small>
                  {displayDate(
                    inPatient.clinicalRecord.dateTimeDisplay,
                    "datetime"
                  )}
                </small>
              }
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} sm={12}>
                  <ClinicalExam />
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                  <Observations />
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                  <ChiefComplaints onUpdate={onAddInpatientRecord} />
                </Grid>

                <Grid item xs={12} md={12} sm={12}>
                  <Illnesses />
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                  <Findings />
                </Grid>
              </Grid>
            </CardContent>
          </div>
        </div>
      )}
    </div>
  );
};
export default ClinicalRecords;
