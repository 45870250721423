import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

// Vendors Components
import { CardHeader, Tooltip, IconButton } from '@material-ui/core';

import { Skeleton } from '@material-ui/lab';
import { Alert } from 'reactstrap';

import { Edit as EditIcon } from '@material-ui/icons';

//  Themes Components

import Auxiliary from 'util/Auxiliary';
import Widget from 'components/Widget/index';
import AlertBox from 'components/AlertBox/index';
// Custom Components and Helpers

//  Actions
import { onAddMedicationForm } from 'redux/actions/MedicalActions';
import { capitalize } from 'util/helpers';


const AllergyHistory = (props) => {
	const dispatch = useDispatch();
	const { contact } = useSelector(({ contactStore }) => contactStore);
	const { medicalrecord } = useSelector(({ medRecordStore }) => medRecordStore);

	// on Add History
	const onAddHistory = () => {
		dispatch(onAddMedicationForm('update', 'allergy'));
		// dispatch(onContactForm(contact, 'update'));
	};
	return (
		<Widget styleName="jr-card-full jr-card-tabs-right jr-card-profile">
			<CardHeader
				action={
					medicalrecord.id && (
						<Tooltip title="Update Allergies">
							<IconButton
								aria-label="Add"
								onClick={() => onAddHistory()}
								variant="contained"
								color="primary"
							>
								<EditIcon />
							</IconButton>
						</Tooltip>
					)
				}
				title={'Allergies'}
			/>
			<div className="jr-tabs-content jr-task-list">

				{!medicalrecord.allergyHistory && (
					<AlertBox infotype="danger" message="No History Found." onClick={onAddHistory} />
				)}

				<div className="row">
					{medicalrecord.allergyHistory &&
						medicalrecord.allergyHistory.map((allergy, index) => {
							return (
								// <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12" key={index}>
								<div className="col-12" key={index}>
									<Auxiliary>
										<div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
											<div className="mr-3">
												{allergy.current && (
													<i className={`zmdi zmdi-check-circle jr-fs-xlxl text-red`} />
												)}
												{!allergy.current && (
													<i className={`zmdi zmdi-circle-o jr-fs-xlxl text-green`} />
												)}
											</div>
											<div className="media-body">
												<h6 className="mb-1 text-grey">{capitalize(allergy.allergy)} </h6>
												{capitalize(allergy.severity)}
											</div>
										</div>
									</Auxiliary>
								</div>
							);
						})}
				</div>
			</div>
		</Widget>
	);
};
export default AllergyHistory;
