import { api } from "util/api";
export function getAllNotes(filterVal = null, searchVal = null) {
  var query = "";
  if (filterVal === "tags") {
    query = "?" + filterVal + "=" + searchVal;
  }
  return (dispatch) => {
    return dispatch({
      type: "GET_ALLNOTES",
      payload: api.get("note/all" + query),
    });
  };
}

export function onNoteClose() {
  return (dispatch) => {
    return dispatch({
      type: "INBOXCLOSE",
    });
  };
}

export function onNoteForm(
  value = null,
  formType = "add",
  aboutType = "other"
) {
  return (dispatch) => {
    return dispatch({
      type: "INBOXNOTEFORM",
      payload: {
        note: value,
        formParam: { formType: formType, aboutType: aboutType },
      },
    });
  };
}

export function onInboxNoteAdd(value) {
  return (dispatch) => {
    return dispatch({
      type: "INBOXNOTE_ADD",
      payload: api.post("note", value),
    });
  };
}

export function onInboxNoteUpdate(value) {
  return (dispatch) => {
    return dispatch({
      type: "INBOXNOTE_UPDATE",
      payload: api.post("note", value),
    });
  };
}
export function onInboxNoteDelete(value) {
  return (dispatch) => {
    return dispatch({
      type: "INBOXNOTE_DELETE",
      payload: api.post("note", value),
    });
  };
}

export function getNoteTags() {
  return (dispatch) => {
    return dispatch({
      type: "NOTETAGS",
      payload: api.get("note/tags/"),
    });
  };
}
