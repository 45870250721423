import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";

import Tooltip from "@material-ui/core/Tooltip";
import Avatar from "@material-ui/core/Avatar";

import { getValuefromLookup, getColor } from "util/helpers";

const AccessGroupComponent = (props) => {
  const { client, clientLoading, clientError } = useSelector(
    ({ profileStore }) => profileStore
  );
  const { uiUsers, accConfig } = useSelector(
    ({ uiConfigureStore }) => uiConfigureStore
  );
  const { accessGroups } = client;
  return (
    <>
      {accessGroups && accessGroups.length > 0 && (
        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
          <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
            <div className="mr-3">
              <i className={`zmdi zmdi-group-work jr-fs-xlxl text-orange`} />
            </div>
            <div className="media-body">
              <h6 className="mb-1 text-grey">Shared With...</h6>
              {accessGroups[0].group !== "ME_AND_THESE" &&
                getValuefromLookup(
                  accConfig.userAccessGroup,
                  accessGroups[0].group
                )}
              {accessGroups[0].group === "ME_AND_THESE" &&
                accessGroups[0].members &&
                accessGroups[0].members.length > 0 && (
                  <p className="mb-0">
                    {accessGroups[0].members.map((member, index) => (
                      <Member memberId={member} key={index} />
                    ))}
                  </p>
                )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default withRouter(AccessGroupComponent);

const Member = (props) => {
  const [user, setUser] = useState(null);

  const { uiUsers } = useSelector(({ uiConfigureStore }) => uiConfigureStore);
  const { memberId } = props;

  useEffect(() => {
    getUserAvatar();
  }, [uiUsers]);

  const getUserAvatar = () => {
    let member = uiUsers.find((item) => item.id === memberId);
    setUser(member);
  };
  return (
    <li className="mb-2 list-inline-item">
      {user && (
        <Tooltip title={user.name} placement="top-start">
          <Avatar
            className="size-30"
            alt={memberId}
            style={{
              backgroundColor: getColor(user.name),
            }}
          >
            {user.name.charAt(0).toUpperCase()}
          </Avatar>
        </Tooltip>
      )}
    </li>
  );
};
