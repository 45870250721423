import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from "react-hook-form";

import {
  Grid,
  TextField,
  InputAdornment,
  Container,
  InputLabel,
} from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";

import { TagInput, EditorInput } from "util/formInput";
import CKEditor from "react-ckeditor-component";
// Utilities and helpers
import IntlMessages from "util/IntlMessages";

export default function BodyForm(props) {
  const { control, errors, register, formRole, setValue, getValues } = props;

  const values = getValues({ nest: true });

  const [content, setContent] = useState(values.body);

  useEffect(() => {
    content && setValue("body", content, { shouldValidate: true });
  }, [content]);

  return (
    <div>
      <Container style={{ paddingBottom: 25 }}>
        <Grid container spacing={1} direction="row">
          <Grid item xs={12} style={{ marginTop: "20px" }}>
            <InputLabel
              htmlFor="group"
              error={errors.body}
              style={{ paddingBottom: "20px" }}
            >
              <IntlMessages id="template.form.body" />
            </InputLabel>
            <input
              ref={register}
              name="body"
              type="text"
              hidden
              defaultValue={values.body}
            />
            <EditorInput
              content={content}
              setContent={setContent}
              errorsmsg={errors.body}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
