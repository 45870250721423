import React, { useState, useEffect } from "react";

import CKEditor from "react-ckeditor-component";

const EditorInput = (props) => {
  const { errorsmsg, content, setContent, miniTool } = props;

  const onChange = (evt) => {
    var newContent = evt.editor.getData();
    setContent(newContent);
  };

  const onBlur = (evt) => {
    var newContent = evt.editor.getData();
    setContent(newContent);
  };

  const afterPaste = (evt) => {
    var newContent = evt.editor.getData();
    setContent(newContent);
  };

  return (
    <>
      <CKEditor
        margin="normal"
        content={content}
        events={{
          blur: onBlur,
          afterPaste: afterPaste,
          change: onChange,
        }}
        config={
          miniTool && {
            toolbarGroups: [
              {
                name: "basicstyles",
                groups: ["basicstyles"],
              },
            ],
          }
        }
      />
      {errorsmsg && <small className="invalid-form">{errorsmsg.message}</small>}
    </>
  );
};

export default EditorInput;
