import { Button } from "@material-ui/core";
import { CloudDownload } from "@material-ui/icons";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { api } from "util/api";
import Report from "./ReportNew";
export default function VisitReport() {
  const [isLoading, setIsLoading] = useState(true);
  const [reportData, setReportData] = useState(null);
  const { visit } = useSelector(({ visitStore }) => visitStore);
  const visitId = visit?.visitId;

  const fetchDAta = async (visitId) => {
    const getData = await api.get(`visit/clinical/report/visit-id/${visitId}`);
    if (getData?.status === 200) {
      setIsLoading(false);
      setReportData(getData?.data);
    }
  };
  useEffect(() => {
    if (visitId) fetchDAta(visitId);
    return () => {
      setReportData(null);
    };
  }, [visitId]);

  return (
    <>
      {!isLoading && reportData && (
        // <PDFViewer>
        //   <Report JSONData={reportData} />
        // </PDFViewer>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "1rem",
          }}
        >
          <PDFDownloadLink
            document={<Report JSONData={reportData} />}
            fileName="report.pdf"
            style={{
              textDecoration: "none",
            }}
          >
            <CloudDownload /> Download{" "}
          </PDFDownloadLink>
        </div>
      )}
    </>
  );
}
