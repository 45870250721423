import React from "react";

import { Avatar } from "@material-ui/core";
import { getColor, displayDate } from "util/helpers";

const NoteItem = ({ noteItem }) => {
  const { createdAt, subject, noteBy, note, tags } = noteItem;
  return (
    <div className={`timeline-item`}>
      <div className="timeline-badge timeline-img">
        {noteBy && (
          <Avatar
            className="size-50"
            alt={noteBy}
            style={{
              fontSize: 16,
              backgroundColor: getColor(noteBy),
            }}
          >
            {noteBy.charAt(0).toUpperCase()}
          </Avatar>
        )}
        {!noteBy && (
          <img
            src={require("assets/images/pentagon.png")}
            alt="Pentagon"
            title="Pentagon"
          />
        )}
      </div>

      <div className="timeline-panel ">
        <div className="timeline-panel-header">
          <div className="timeline-heading">
            <h5>{displayDate(createdAt, "datetime")}</h5>
            {/* <h3 className="timeline-title">{subject}</h3> */}
            {tags.map((tag, index) => {
              return (
                // note.labels.includes(tag) && (
                <div
                  key={index}
                  className={`badge text-white `}
                  style={{ backgroundColor: getColor(tag) }}
                >
                  {tag}
                </div>
                // )
              );
            })}
          </div>
        </div>
        <div className="timeline-body">
          <div
            className="message"
            dangerouslySetInnerHTML={{ __html: note }}
          ></div>
        </div>
      </div>
    </div>
  );
};
export default NoteItem;
