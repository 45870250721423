// material ui
import { Dialog, withMobileDialog } from "@material-ui/core";
// custom Components
import TransitionSlide from "components/Dialog/TransitionSlide";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { onVisitClose } from "./actions";
import VisitForm from "./VisitForm";

const ComposeVisit = (props) => {
  const dispatch = useDispatch();
  const { addFormVisit, updateVisit } = useSelector(
    ({ visitStore }) => visitStore
  );
  const onClose = () => {
    dispatch(onVisitClose());
  };
  return (
    <Dialog
      fullScreen={props.fullScreen}
      fullWidth={true}
      maxWidth="md"
      scroll="paper"
      TransitionComponent={TransitionSlide}
      open={addFormVisit || updateVisit}
      onClose={onClose}
    >
      {(addFormVisit || updateVisit) && <VisitForm />}
    </Dialog>
  );
};
ComposeVisit.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};
export default withMobileDialog()(ComposeVisit);
