import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

// material ui
import { withMobileDialog, Dialog } from "@material-ui/core";
import { Alert, Spinner } from "reactstrap";
// custom Components
import TransitionSlide from "components/Dialog/TransitionSlide";

import { getAllTemplates, onTemplateForm, onTemplateClose } from "../actions";
import TemplateForm from "./TemplateForm";

const ComposeTemplate = (props) => {
  const dispatch = useDispatch();
  const {
    templates,
    templatesLoading,
    template,
    addFormTemplate,
    updateFormTemplate,
    viewTemplate,
    aboutType,
  } = useSelector(({ templateStore }) => templateStore);

  // onCloseNoteView Modal
  const onClose = () => {
    dispatch(onTemplateClose());
  };

  return (
    <Dialog
      fullScreen={props.fullScreen}
      fullWidth={true}
      maxWidth="md"
      scroll="paper"
      TransitionComponent={TransitionSlide}
      open={addFormTemplate || updateFormTemplate}
      onClose={onClose}
    >
      {(addFormTemplate || updateFormTemplate) && <TemplateForm />}
    </Dialog>
  );
};

ComposeTemplate.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};
export default withMobileDialog()(ComposeTemplate);
