import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
} from "constants/ActionTypes";

import { api } from "util/api";

export function loginUser(values) {
  return (dispatch) => {
    return dispatch({
      type: "LOGIN",
      payload: api.post("user/session", values),
    });
  };
}

export function logFirstUserId(userId) {
  return (dispatch) => {
    return dispatch({
      type: "LOGFIRSTUSERID",
      payload: api.get("user/user-id/" + userId),
    });
  };
}

export function logUserId(userId) {
  return (dispatch) => {
    return dispatch({
      type: "LOGUSERID",
      payload: api.get("user/user-id/" + userId),
    });
  };
}
export function signupUserEmail(values) {
  sessionStorage.setItem("newuser", JSON.stringify(values));
  return (dispatch) => {
    return dispatch({
      type: "SIGNUP_EMAIL",
      payload: api.post("account/signup-email", values),
    });
  };
}
export function signupUser(values) {
  return (dispatch) => {
    return dispatch({
      type: "SIGNUP",
      payload: api.post("account/signup", values),
    });
  };
}

// Update Users Password
// Authstore
export function onSavePassword(value) {
  return (dispatch) => {
    return dispatch({
      type: "SAVEPASSWORD",
      payload: api.post("user", value),
    });
  };
}

// logout
export function logOut() {
  // console.log('logout')
  return (dispatch) => {
    return dispatch({
      type: "LOGOUT",
      payload: "logout",
    });
  };
}

export function setLoggedUser() {
  return (dispatch) => {
    return dispatch({
      type: "SETLOGGEDUSER",
      payload: "setUser",
    });
  };
}

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message,
  };
};

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};

export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};
