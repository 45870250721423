import React, { useEffect, useState } from "react";
import ContainerHeader from "components/ContainerHeader/index";

import Widget from "components/Widget/index";
import IntlMessages from "util/IntlMessages";
import StepperView from "./StepperView";
import MessageLogs from "./MessageLogs";

const MessagePage = (props) => {
  const [viewLogs, setViewLogs] = useState(false);
  const buttonVal = {
    title: !viewLogs ? "View Logs" : "Message",
    onClick: () => setViewLogs(!viewLogs),
  };
  return (
    <>
      {/* // <div className="animated slideInUpTiny animation-duration-3"> */}
      <ContainerHeader
        match={props.match}
        title={<IntlMessages id="pages.messagePage" />}
        buttonVal={buttonVal}
      />
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
          <Widget styleName="jr-card-full jr-card-profile">
            <div className="jr-tabs-content">
              {!viewLogs && <StepperView />}
              {viewLogs && <MessageLogs />}
            </div>
          </Widget>
        </div>
      </div>
    </>
  );
};

export default MessagePage;
