import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

// material ui
import { withMobileDialog, Dialog } from "@material-ui/core";

import TransitionSlide from "components/Dialog/TransitionSlide";

import UserForm from "./UserForm";

const UserModal = (props) => {
  const { isProfile, addView, updateView, user, onClose } = props;

  return (
    <Dialog
      fullScreen={props.fullScreen}
      fullWidth={true}
      maxWidth="md"
      scroll="paper"
      TransitionComponent={TransitionSlide}
      open={addView || updateView}
      onClose={onClose}
    >
      {(addView || updateView) && (
        <UserForm {...{ isProfile, addView, updateView, user, onClose }} />
      )}
    </Dialog>
  );
};

UserModal.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};
export default withMobileDialog()(UserModal);
