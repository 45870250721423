import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFieldArray, Controller } from "react-hook-form";

import {
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment,
  Divider,
  Container,
  Avatar,
  CardHeader,
  IconButton,
  Paper,
} from "@material-ui/core";

import {
  Email as EmailIcon,
  Add as AddIcon,
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
} from "@material-ui/icons";

import IntlMessages from "util/IntlMessages";

export default function EmailsFormArray(props) {
  const { accConfig } = useSelector(({ uiConfigureStore }) => uiConfigureStore);
  const { control, errors, register, updateFormModal } = props;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "emails",
    keyName: "keyId", //, default to "id", you can change the key name
  });

  const onAdd = () => {
    append({
      id: 0,
      emailType: "PERSONAL",
      email: "",
      primary: false,
    });
  };

  const onRemove = (index) => {
    remove(index);
  };

  return (
    <div style={{ paddingBottom: 25 }}>
      <CardHeader
        avatar={
          <Avatar aria-label="Emails">
            <EmailIcon />
          </Avatar>
        }
        action={
          updateFormModal && (
            <IconButton
              aria-label="Add"
              onClick={onAdd}
              variant="contained"
              color="primary"
            >
              <AddIcon />
            </IconButton>
          )
        }
        title={
          <h2>
            <strong>Emails</strong>
          </h2>
        }
      />

      {fields.map((item, index) => {
        return (
          <div key={item.keyId}>
            <Container style={{ paddingBottom: 25 }}>
              <input
                ref={register}
                name={`emails[${index}].primary`}
                type="text"
                hidden
                defaultValue={index === 0}
              />

              <Grid container spacing={2}>
                <Grid item xs={4} sm={4}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel htmlFor="emailType">
                      <IntlMessages id="contact.form.emailType" />
                    </InputLabel>
                    <Controller
                      as={
                        <Select>
                          <MenuItem value="" disabled>
                            {" "}
                            Select Phone Type
                          </MenuItem>
                          {accConfig.emailType &&
                            accConfig.emailType.map((val) => (
                              <MenuItem value={val.key} key={val.key}>
                                {val.value}
                              </MenuItem>
                            ))}
                        </Select>
                      }
                      name={`emails[${index}].emailType`}
                      label={<IntlMessages id="contact.form.emailType" />}
                      control={control}
                      fullWidth
                      defaultValue={item.emailType}
                      error={
                        errors.emails &&
                        errors.emails[index] &&
                        errors.emails[index].emailType
                      }
                      helperText={
                        errors.emails &&
                        errors.emails[index] &&
                        errors.emails[index].emailType &&
                        errors.emails[index].emailType.message
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid
                  item
                  xs={updateFormModal ? 7 : 8}
                  sm={updateFormModal ? 7 : 8}
                >
                  <FormControl fullWidth margin="normal">
                    <Controller
                      label={<IntlMessages id="contact.form.email" />}
                      as={TextField}
                      name={`emails[${index}].email`}
                      control={control}
                      fullWidth
                      defaultValue={item.email}
                      error={
                        errors.emails &&
                        errors.emails[index] &&
                        errors.emails[index].email
                      }
                      helperText={
                        errors.emails &&
                        errors.emails[index] &&
                        errors.emails[index].email &&
                        errors.emails[index].email.message
                      }
                    />
                  </FormControl>
                </Grid>
                {updateFormModal && (
                  <Grid item xs={1}>
                    <IconButton
                      aria-label="Delete"
                      onClick={() => onRemove(index)}
                      variant="contained"
                      color="secondary"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </Container>

            {index + 1 !== fields.length && <Divider variant="middle" />}
          </div>
        );
      })}
    </div>
  );
}
