import {
  CHANGE_DIRECTION,
  CHANGE_NAVIGATION_STYLE,
  DARK_THEME,
  DRAWER_TYPE,
  HORIZONTAL_MENU_POSITION,
  SWITCH_LANGUAGE,
  THEME_COLOR,
  TOGGLE_COLLAPSED_NAV,
  WINDOW_WIDTH,
} from "constants/ActionTypes";
import { api,AWSFileApi } from "util/api";

// import { api, AWSFileApi } from "helpers/api";

export function toggleCollapsedNav(isNavCollapsed) {
  return { type: TOGGLE_COLLAPSED_NAV, isNavCollapsed };
}

export function setDrawerType(drawerType) {
  return { type: DRAWER_TYPE, drawerType };
}

export function updateWindowWidth(width) {
  return { type: WINDOW_WIDTH, width };
}

export function setThemeColor(color) {
  return { type: THEME_COLOR, color };
}

export function setDarkTheme() {
  return { type: DARK_THEME };
}

export function changeDirection() {
  return { type: CHANGE_DIRECTION };
}

export function changeNavigationStyle(layoutType) {
  return {
    type: CHANGE_NAVIGATION_STYLE,
    payload: layoutType,
  };
}

export function setHorizontalMenuPosition(navigationPosition) {
  return {
    type: HORIZONTAL_MENU_POSITION,
    payload: navigationPosition,
  };
}

export function switchLanguage(locale) {
  return {
    type: SWITCH_LANGUAGE,
    payload: locale,
  };
}

// logout
export function logOut() {
  // console.log('logout')
  return (dispatch) => {
    return dispatch({
      type: "LOGOUT",
      payload: "logout",
    });
  };
}

export function setLoggedUser() {
  return (dispatch) => {
    return dispatch({
      type: "SETLOGGEDUSER",
      payload: "setUser",
    });
  };
}

// get default App Ui Configuration
export function getAppUiConfig() {
  return (dispatch) => {
    return dispatch({
      type: "GET_APPUI_CONFIG",
      payload: api.get("/account/config"),
    });
  };
}
// get default Account Units
export function getDefaultAccUnits() {
  return (dispatch) => {
    return dispatch({
      type: "GET_DEFAULT_UNITS",
      payload: api.get("/account/units"),
    });
  };
}

// {{server}}:{{port}}/metta-care/v1/account/icd/description?filter=liver
// get app config datas
export function getICDcodes(val, index = 0) {
  // let payloaddata = api.get("account/icd/description?filter=" + val);
  let payloaddata = api.get("ehr/lookups/icd?filter=" + val);
  const payload = Promise.all([index, payloaddata]).then(
    ([index, payloaddata]) => {
      return { index, payloaddata };
    }
  );
  return (dispatch) => {
    return dispatch({
      type: "GET_ICD",
      payload: payload,
    });
  };
}
// Clear Icds on close dialog
export function clearIcds() {
  return (dispatch) => {
    return dispatch({
      type: "CLEARICDS",
    });
  };
}
// Set Profile Page title
export function setProfileTitle(val = { id: 1, title: "Medical History" }) {
  return (dispatch) => {
    return dispatch({
      type: "SETPROFILETITLE",
      payload: val,
    });
  };
}

// get app config datas
export function getInvestigation() {
  return (dispatch) => {
    return dispatch({
      type: "GET_INVESTIGATIONS",
      payload: api.get("ehr/lookups/investigation-items"),
    });
  };
}

// get app config lookup datas
export function getAccLookupBy(value = "investigations") {
  return (dispatch) => {
    return dispatch({
      type: "GET_LOOKUP",
      payload: api.get("account/lookups/" + value),
    });
  };
}
// get app config datas
export function getGlobalCountries() {
  return (dispatch) => {
    return dispatch({
      type: "GET_COUNTRIES",
      payload: api.get("/global/countries"),
    });
  };
}
// get app config datas
export function getConfigData(val = "clienttype") {
  // console.log(val);
  return (dispatch) => {
    return dispatch({
      type: "ACC_LOOKUP",
      payload: api.get("account/lookups?filter=" + val),
    });
  };
}
// get app config datas
export function getAllConfigData() {
  return (dispatch) => {
    api.get("account/lookups?filter=tables").then((response) => {
      // console.log(response);
      let datas = response.data;
      datas.map((data) => {
        dispatch(getConfigData(data));
      });
    });
  };
}

export function getAllConfigData1() {
  return (dispatch) => {
    return dispatch({
      type: "ALLCONFIGDATA",
      payload: api.get("account/lookups?filter=tables"),
    }).then((response) => {
      // console.log(response.value.data);
      let datas = response.value.data;
      Object.entries(datas).map(([key, value]) => {
        value.map((val) => {
          dispatch(getConfigData(val));
        });
      });
    });
  };
}
// get app config datas
export function getDistricts() {
  return (dispatch) => {
    return dispatch({
      type: "GET_DISTRICT",
      payload: api.get("account/lookups/districts"),
    });
  };
}
// get app config datas
export function isApiLoading(val) {
  return (dispatch) => {
    return dispatch({
      type: "APILOADING",
      payload: val,
    });
  };
}

// Get Contacts for forms
// Setting Store
export function getContactsByVal(searchVal) {
  return (dispatch) => {
    return dispatch({
      type: "GET_CONTACTSBYVAL",
      payload: api.get("client?query=" + searchVal),
    });
  };
}

// Get All Users
// Setting Store
export function getAllUsers() {
  return (dispatch) => {
    return dispatch({
      type: "GET_ALLUSERS",
      payload: api.get("/user/all"),
    });
  };
}

// save Users Password
// Settingstore
export function saveUser(value) {
  return (dispatch) => {
    return dispatch({
      type: "SAVEUSER",
      payload: api.post("user", value),
    });
  };
}
// Update Users Password
// Settingstore
export function updateUser(value) {
  return (dispatch) => {
    return dispatch({
      type: "UPDATEUSER",
      payload: api.post("user", value),
    });
  };
}

// save Defaults Units
// Settingstore
export function onSaveDefaultUnits(value) {
  return (dispatch) => {
    return dispatch({
      type: "SAVE_DEFAULTUNITS",
      payload: api.post("/account/lookups", value),
    });
  };
}

// save file to aws
export function onSaveDocumentToAWS(file, path = "") {
  return (dispatch) => {
    return dispatch({
      type: "SAVE_FILES",
      payload: AWSFileApi.put(path, file),
    });
  };
}

// Trial modal warning
export function onTogRestrictWarning(tog = true, formfor = "", message = null) {
  return (dispatch) => {
    return dispatch({
      type: "TOGTRIAL_WARNING",
      payload: { tog: tog, formfor: formfor, message: message },
    });
  };
}

// Get All Users for dropdowns
export function getActiveUsers() {
  return (dispatch) => {
    return dispatch({
      type: "GET_ACTIVEUSERS",
      payload: api.get("user/all"),
    });
  };
}
