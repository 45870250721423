import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  IconButton,
  MenuItem,
  FormControl,
  FormHelperText,
  TextField,
  Chip,
  InputAdornment,
  Avatar,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { Dropdown, DropdownMenu, DropdownToggle, Popover } from "reactstrap";
import SearchBox from "components/SearchBox";

import {
  Search as SeacrhIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  AddCircle as AddCircleIcon,
} from "@material-ui/icons";

import { getColor } from "util/helpers";
import UserCell from "components/chatPanel/ContactList/UserCell";
import { folders } from "../data";
const searchTypes = [
  {
    label: "Keywords",
    value: "search",
  },
  {
    label: "Status",
    value: "status",
  },
];

const AppModuleHeader = (props) => {
  const { taskTags } = useSelector(({ inboxStore }) => inboxStore);
  const { profilePic } = useSelector(({ auth }) => auth);
  const [searchBox, setSearchBox] = useState(false);
  const [popoverOpen, setPopOverOpen] = useState(false);
  const {
    placeholder,
    onChange,
    // value,
    user,
    notification,
    apps,
    filterVal,
    setFilterVal,
  } = props;

  const [taskStats, setTaskStats] = useState([]);
  const [searchVal, setSearchVal] = useState("");

  useEffect(() => {
    onChange(taskStats.toString());
  }, [taskStats]);

  useEffect(() => {
    // console.log(searchVal);
    onChange(searchVal);
  }, [searchVal]);

  const onSearchBoxSelect = () => {
    setSearchBox(!searchBox);
  };

  const toggle = () => {
    setPopOverOpen(!popoverOpen);
  };

  const onTaskStatChange = (event, value) => {
    let statuses = value.map((val) => val.handle);
    setTaskStats(statuses);
    return value;
  };

  return (
    <div className="module-box-header-inner">
      <div
        className="search-bar right-side-icon bg-transparent d-none d-sm-block"
        style={{ width: "80%" }}
      >
        <Grid container spacing={2}>
          <Grid item xs={3} sm={3}>
            <TextField
              select
              fullWidth
              label=" "
              value={filterVal}
              onChange={(e) => setFilterVal(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SeacrhIcon />
                  </InputAdornment>
                ),
              }}
            >
              {searchTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6} sm={6}>
            {filterVal === "search" && (
              <TextField
                id="search"
                label="Search"
                value={searchVal}
                onChange={(e) => setSearchVal(e.target.value)}
                fullWidth
              />
            )}
            {filterVal === "status" && (
              <Autocomplete
                margin="normal"
                multiple
                options={folders}
                // value={taskStats}
                getOptionLabel={(option) => option.title}
                // freeSolo
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      size="small"
                      variant="outlined"
                      label={option.title}
                      style={{
                        backgroundColor: option.color,
                        color: "white",
                      }}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                onChange={onTaskStatChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Tags"
                    fullWidth
                    margin="normal"
                  />
                )}
              />
            )}
          </Grid>
        </Grid>
      </div>

      <Popover
        className="p-3"
        placement="bottom"
        isOpen={popoverOpen}
        target="Popover1"
        toggle={toggle}
      >
        <h3>{user.name}</h3>
        <h4>{user.email}</h4>
      </Popover>

      <div className="d-inline-block d-sm-none">
        <Dropdown
          className="quick-menu nav-searchbox"
          isOpen={searchBox}
          toggle={onSearchBoxSelect}
        >
          <DropdownToggle
            className="d-inline-block"
            tag="span"
            data-toggle="dropdown"
          >
            <IconButton className="icon-btn">
              <i className="zmdi zmdi-search zmdi-hc-fw text-grey" />
            </IconButton>
          </DropdownToggle>

          <DropdownMenu className="p-0">
            <SearchBox
              styleName="search-dropdown"
              placeholder=""
              onChange={onChange}
              value={searchVal}
            />
          </DropdownMenu>
        </Dropdown>
      </div>

      <div className="module-box-header-right">
        {/* {apps && (
          <IconButton className="size-40" aria-label="Menu">
            <i className="zmdi zmdi-apps" />
          </IconButton>
        )}
        {notification && (
          <IconButton className="size-40" aria-label="Menu">
            <i className="zmdi zmdi-notifications-none" />
          </IconButton>
        )} */}

        {profilePic ? (
          <Avatar
            className="user-avatar "
            alt={user.name}
            src={profilePic}
            id="Popover1"
            alt={user.name}
            onMouseEnter={toggle}
            onMouseLeave={toggle}
            onClick={toggle}
          />
        ) : (
          <Avatar
            className="user-avatar "
            style={{ fontSize: 16, backgroundColor: getColor(user.name) }}
            id="Popover1"
            alt={user.name}
            onMouseEnter={toggle}
            onMouseLeave={toggle}
            onClick={toggle}
          >
            {user.name.charAt(0).toUpperCase()}
          </Avatar>
        )}
      </div>
    </div>
  );
};

export default AppModuleHeader;

AppModuleHeader.defaultProps = {
  styleName: "",
  value: "",
  notification: true,
  apps: true,
};
