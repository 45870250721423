import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";

// material ui
import { Button, DialogActions } from "@material-ui/core";
import { Alert, Spinner } from "reactstrap";

import { onClientUpdate } from "services/actions";

import { TagInput, EditorInput } from "util/formInput";
// Utilities and helpers
import IntlMessages from "util/IntlMessages";
import { CrudNotification } from "util/helpers";

const formSchema = yup.object().shape({
  about: yup.string().min(2, "Must be longer than 4 characters").required(),
});
const IntroForm = (props) => {
  const dispatch = useDispatch();
  const { formerror, contact, is_saving } = useSelector(
    ({ contactStore }) => contactStore
  );
  const { client, clientLoading, clientError } = useSelector(
    ({ profileStore }) => profileStore
  );
  const {
    handleSubmit,
    register,
    reset,
    control,
    errors,
    setValue,
    getValues,
    watch,
  } = useForm({
    validationSchema: formSchema,
    defaultValues: client,
    mode: "onBlur",
  });
  const [content, setContent] = useState(client.about);

  useEffect(() => {
    content && setValue("about", content, { shouldValidate: true });
  }, [content]);

  // On update submit
  const onSubmit = (values) => {
    // console.log(values);
    return dispatch(onClientUpdate(values)).then((res) => {
      props.onClose();
      CrudNotification("success", "Contacts Intro Updated Successfully !");
    });
  };

  // Process form values before submitting
  const transform = (onSubmitFunc) => (data) => {
    let values = data;
    onSubmitFunc(values);
  };

  const val = getValues({ nest: true });

  return (
    <Fragment>
      {formerror && <Alert color="danger"> {formerror.message} </Alert>}

      <input
        ref={register}
        name="clientId"
        type="text"
        hidden
        defaultValue={client.clientId}
      />
      <input
        ref={register}
        name="id"
        type="text"
        defaultValue={client.id}
        hidden
      />
      <input
        ref={register}
        name="about"
        type="text"
        hidden
        defaultValue={client.about}
      />
      <EditorInput
        content={content}
        setContent={setContent}
        errorsmsg={errors.about}
      />
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSubmit(transform(onSubmit))}
          disabled={is_saving}
        >
          {is_saving && <Spinner size="sm" />}
          <IntlMessages id="form.save" />
        </Button>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          onClick={() => props.onClose()}
        >
          <IntlMessages id="form.cancel" />
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default IntroForm;
