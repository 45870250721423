import React from "react";

import { useDispatch, useSelector } from "react-redux";

// Vendors Components
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Tooltip,
  IconButton,
  Paper,
} from "@material-ui/core";

import {
  Edit as EditIcon,
  CheckCircle as CheckCircleIcon,
} from "@material-ui/icons";

// Themes Components
import Auxiliary from "util/Auxiliary";
import Widget from "components/Widget/index";
import AlertBox from "components/AlertBox/index";

// Custom Components and Helpers
import { capitalize, getValuefromLookup } from "util/helpers";

// Actions
import { onInPatientForm } from "redux/actions/MedicalActions";

const Findings = (props) => {
  const dispatch = useDispatch();
  const { inPatient, medicalrecord } = useSelector(
    ({ medRecordStore }) => medRecordStore
  );
  const { accConfig } = useSelector(({ uiConfigureStore }) => uiConfigureStore);

  // on Add History
  const onUpdate = () => {
    dispatch(onInPatientForm("update", "findings"));
  };
  return (
    <Paper className="jr-tabs-content jr-task-list">
      <List component="nav" aria-label="Findings">
        <ListItem button>
          <ListItemText primary={<strong>Findings</strong>} />
          <Tooltip title="Update About Info ">
            <IconButton
              aria-label="Add"
              onClick={() => onUpdate()}
              variant="contained"
              color="primary"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </ListItem>
        {!inPatient.clinicalRecord.findings && (
          <AlertBox
            infotype="danger"
            message="No Findings detail Found."
            onClick={onUpdate}
          />
        )}
        {inPatient.clinicalRecord.findings &&
          inPatient.clinicalRecord.findings.map((value, index) => {
            return (
              <ListItem alignItems="flex-start" key={index}>
                <ListItemAvatar>
                  <CheckCircleIcon color="secondary" fontSize="large" />
                </ListItemAvatar>
                <ListItemText primary={value} />
              </ListItem>
            );
          })}
      </List>
    </Paper>
  );
};

export default Findings;
