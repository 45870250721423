import React, { useEffect, useState } from "react";
import { useFieldArray, Controller } from "react-hook-form";

import {
  Grid,
  TextField,
  Slider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment,
  Divider,
  Container,
  Avatar,
  CardHeader,
  IconButton,
  Tooltip,
  Button,
} from "@material-ui/core";

import { DateTimeInput } from "util/formInput";
import { getDefaultUnit } from "util/helpers";
import { Refresh, Add as AddIcon, Clear, Close } from "@material-ui/icons";

import IntlMessages from "util/IntlMessages";

export default function InOutRecordForm(props) {
  const { control, errors, accConfig, register, getValues, watch } = props;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "intakeOutputRecords",
    keyName: "keyId", //, default to "id", you can change the key name
  });
  const wrecord = watch("intakeOutputRecords");
  const values = getValues({ nest: true });
  const onAdd = () => {
    append({
      time: null,
      intakeOutputType: "INTAKE",
      intakeOutputMethod: "",
      fluidType: "",
      amount: 20,
      unit: "ML",
      note: "",
    });
  };

  const onRemove = (index) => {
    remove(index);
  };

  useEffect(() => {
    if (fields.length === 0) {
      onAdd();
    }
  }, []);
  const onReset = (index) => {
    remove(index);
    onAdd();
    // console.log('reset');
  };
  return (
    <div>
      {fields.map((item, index) => {
        return (
          <div key={item.keyId}>
            <Container style={{ paddingBottom: 25 }}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <FormControl fullWidth margin="normal">
                    <DateTimeInput
                      value={item.time}
                      label="Time *"
                      name={`intakeOutputRecords[${index}].time`}
                      inputRef={register}
                      fullWidth
                      type="time"
                      ampm={false}
                      autoOk
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel htmlFor="intakeOutputType">
                      <IntlMessages id="dailylog.form.intakeOutputType" />
                    </InputLabel>
                    <Controller
                      as={
                        <Select>
                          <MenuItem value="" disabled>
                            {
                              <IntlMessages id="dailylog.form.intakeOutputType" />
                            }
                          </MenuItem>
                          {accConfig.intakeOutputType &&
                            accConfig.intakeOutputType.map((val) => (
                              <MenuItem value={val.key} key={val.key}>
                                {val.value}
                              </MenuItem>
                            ))}
                        </Select>
                      }
                      name={`intakeOutputRecords[${index}].intakeOutputType`}
                      label={
                        <IntlMessages id="dailylog.form.intakeOutputType" />
                      }
                      control={control}
                      fullWidth
                      defaultValue={item.intakeOutputType}
                      error={
                        errors.intakeOutputRecords &&
                        errors.intakeOutputRecords[index] &&
                        errors.intakeOutputRecords[index].intakeOutputType
                      }
                      helperText={
                        errors.intakeOutputRecords &&
                        errors.intakeOutputRecords[index] &&
                        errors.intakeOutputRecords[index].intakeOutputType &&
                        errors.intakeOutputRecords[index].intakeOutputType
                          .message
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel htmlFor="intakeOutputMethod">
                      <IntlMessages id="dailylog.form.intakeOutputMethod" />
                    </InputLabel>
                    <Controller
                      as={
                        <Select>
                          <MenuItem value="" disabled>
                            {
                              <IntlMessages id="dailylog.form.intakeOutputMethod" />
                            }
                          </MenuItem>
                          {wrecord &&
                            wrecord[index] &&
                            wrecord[index].intakeOutputType === "INTAKE" &&
                            accConfig.intakeMethod &&
                            accConfig.intakeMethod.map((val) => (
                              <MenuItem value={val.key} key={val.key}>
                                {val.value}
                              </MenuItem>
                            ))}

                          {wrecord &&
                            wrecord[index] &&
                            wrecord[index].intakeOutputType === "OUTPUT" &&
                            accConfig.outputMethod &&
                            accConfig.outputMethod.map((val) => (
                              <MenuItem value={val.key} key={val.key}>
                                {val.value}
                              </MenuItem>
                            ))}
                        </Select>
                      }
                      name={`intakeOutputRecords[${index}].intakeOutputMethod`}
                      label={
                        <IntlMessages id="dailylog.form.intakeOutputType" />
                      }
                      control={control}
                      fullWidth
                      defaultValue={item.intakeOutputMethod}
                      error={
                        errors.intakeOutputRecords &&
                        errors.intakeOutputRecords[index] &&
                        errors.intakeOutputRecords[index].intakeOutputMethod
                      }
                      helperText={
                        errors.intakeOutputRecords &&
                        errors.intakeOutputRecords[index] &&
                        errors.intakeOutputRecords[index].intakeOutputMethod &&
                        errors.intakeOutputRecords[index].intakeOutputMethod
                          .message
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel htmlFor="intakeOutputMethod">
                      <IntlMessages id="dailylog.form.fluidType" />
                    </InputLabel>
                    <Controller
                      as={
                        <Select>
                          <MenuItem value="" disabled>
                            Fluid Type
                          </MenuItem>
                          {wrecord &&
                            wrecord[index] &&
                            wrecord[index].intakeOutputType === "INTAKE" &&
                            accConfig.intakeFluid &&
                            accConfig.intakeFluid.map((val) => (
                              <MenuItem value={val.key} key={val.key}>
                                {val.value}
                              </MenuItem>
                            ))}
                          {wrecord &&
                            wrecord[index] &&
                            wrecord[index].intakeOutputType === "OUTPUT" &&
                            accConfig.outputFluid &&
                            accConfig.outputFluid.map((val) => (
                              <MenuItem value={val.key} key={val.key}>
                                {val.value}
                              </MenuItem>
                            ))}
                        </Select>
                      }
                      name={`intakeOutputRecords[${index}].fluidType`}
                      label={<IntlMessages id="dailylog.form.fluidType" />}
                      control={control}
                      fullWidth
                      defaultValue={item.fluidType}
                      error={
                        errors.intakeOutputRecords &&
                        errors.intakeOutputRecords[index] &&
                        errors.intakeOutputRecords[index].fluidType
                      }
                      helperText={
                        errors.intakeOutputRecords &&
                        errors.intakeOutputRecords[index] &&
                        errors.intakeOutputRecords[index].fluidType &&
                        errors.intakeOutputRecords[index].fluidType.message
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <Controller
                      label={<IntlMessages id="dailylog.form.amount" />}
                      label={
                        <span>
                          <IntlMessages id="dailylog.form.amount" /> (
                          {getDefaultUnit(accConfig.intakeOutputUnit)})*
                        </span>
                      }
                      as={TextField}
                      name={`intakeOutputRecords[${index}].amount`}
                      control={control}
                      fullWidth
                      error={
                        errors.intakeOutputRecords &&
                        errors.intakeOutputRecords[index] &&
                        errors.intakeOutputRecords[index].amount
                      }
                      helperText={
                        errors.intakeOutputRecords &&
                        errors.intakeOutputRecords[index] &&
                        errors.intakeOutputRecords[index].amount &&
                        errors.intakeOutputRecords[index].amount.message
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={11} sm={11}>
                  <FormControl fullWidth margin="normal">
                    <Controller
                      label={<IntlMessages id="dailylog.form.note" />}
                      as={TextField}
                      name={`intakeOutputRecords[${index}].note`}
                      control={control}
                      multiline
                      fullWidth
                      error={
                        errors.intakeOutputRecords &&
                        errors.intakeOutputRecords[index] &&
                        errors.intakeOutputRecords[index].note
                      }
                      helperText={
                        errors.intakeOutputRecords &&
                        errors.intakeOutputRecords[index] &&
                        errors.intakeOutputRecords[index].note &&
                        errors.intakeOutputRecords[index].note.message
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={1} sm={1}>
                  <Container>
                    <Button
                      color="secondary"
                      aria-label="Remove"
                      onClick={() => onRemove(index)}
                      size="small"
                    >
                      <Tooltip
                        title={"Remove Observation Notes  " + (index + 1)}
                        size="large"
                      >
                        <Clear />
                      </Tooltip>
                    </Button>
                  </Container>
                </Grid>
              </Grid>
            </Container>

            {index + 1 !== fields.length && <Divider />}
          </div>
        );
      })}

      <Tooltip title="Click to Add Observation Detail">
        <Button
          aria-label="Add"
          onClick={() => onAdd()}
          variant="contained"
          color="primary"
        >
          Add More <AddIcon />
        </Button>
      </Tooltip>
    </div>
  );
}
