import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";

import * as yup from "yup";
import {
  ButtonGroup,
  TextField,
  IconButton,
  Grid,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Tooltip,
  FormControl,
  FormLabel,
} from "@material-ui/core";

import { Alert, Spinner } from "reactstrap";
import { Close as CloseIcon } from "@material-ui/icons";

import IntlMessages from "util/IntlMessages";

const formSchema = yup.object().shape({
  note: yup
    .object()
    .default(null)
    .nullable()
    .shape({
      note: yup
        .string()
        .required("Note is required")
        .nullable(),
    }),
});
const defaultVal = {
  note: {
    note: "",
  },
  ewsOverride: null,
};
const OverrideFrequencyForm = (props) => {
  const { accConfig } = useSelector(({ uiConfigureStore }) => uiConfigureStore);
  const { is_saving, ewsFormError, patientEws } = useSelector(
    ({ medRecordStore }) => medRecordStore
  );

  const {
    handleSubmit,
    register,
    reset,
    control,
    errors,
    getValues,
    setValue,
  } = useForm({
    validationSchema: formSchema,
    defaultValues: defaultVal,
    mode: "onBlur",
  });

  const values = getValues({ nest: true });
  const [data, setData] = useState(null);
  const [alerttype, setAlerttype] = useState(patientEws.consciousnessLevel);
  const onAlertType = (val) => {
    setValue("ewsOverride", val);
    setAlerttype(val);
  };
  //   Set ewsOverride onload
  useEffect(() => {
    values.ewsOverride ? onAlertType(values.ewsOverride) : onAlertType("ALERT");
  }, []);

  const onSubmit = (values) => {
    // console.log(values);
    props.onSubmit(values);
  };
  console.log(errors);
  return (
    <div className="jr-card p-0">
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* <div className={"card shadow text-center "}> */}
        <input
          ref={register}
          name="ewsOverride"
          type="text"
          hidden
          defaultValue={alerttype}
        />
        <input
          ref={register}
          name="id"
          type="text"
          hidden
          defaultValue={patientEws.id}
        />
        <CardHeader
          action={
            <Tooltip title="Cancel Override EWS Frequency ">
              <IconButton
                aria-label="Add"
                onClick={() => props.onCancel()}
                variant="contained"
                color="primary"
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          }
          title="Override EWS Frequency"
        />

        <CardContent>
          {ewsFormError && (
            <Alert color="danger"> {ewsFormError.message} </Alert>
          )}

          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormControl fullWidth margin="normal">
                <Controller
                  label={<IntlMessages id="ewsFreq.form.note" />}
                  as={TextField}
                  name="note.note"
                  control={control}
                  fullWidth
                  multiline
                  rowsMax={4}
                  error={errors.note && errors.note.note}
                  helperText={
                    errors.note && errors.note.note && errors.note.note.message
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth margin="normal">
                <FormLabel htmlFor="ewsOverride">
                  <IntlMessages id="ewsFreq.form.ewsOverride" />
                </FormLabel>
                <ButtonGroup
                  color="primary"
                  aria-label="outlined primary button group"
                >
                  {accConfig.ewsFrequency &&
                    accConfig.ewsFrequency.map((val) => (
                      <Button
                        key={val.key}
                        onClick={() => onAlertType(val.key)}
                        variant={
                          alerttype === val.key ? "contained" : "outlined"
                        }
                        color="primary"
                        size="medium"
                      >
                        {val.value}
                      </Button>
                    ))}
                </ButtonGroup>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            disabled={is_saving}
          >
            {is_saving && <Spinner size="sm" />}
            <IntlMessages id="form.save" />
          </Button>
          <Button
            type="button"
            variant="contained"
            color="secondary"
            onClick={() => props.onCancel()}
          >
            <IntlMessages id="form.cancel" />
          </Button>
        </CardActions>
        {/* </div> */}
      </form>
    </div>
  );
};

export default OverrideFrequencyForm;
