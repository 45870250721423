import React, { useEffect, useState } from "react";
import { useFieldArray, Controller } from "react-hook-form";

import {
  Grid,
  TextField,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment,
  Divider,
  Container,
  Avatar,
  CardHeader,
  IconButton,
  Tooltip,
  Button,
} from "@material-ui/core";

import { Refresh, Add as AddIcon, Clear, Close } from "@material-ui/icons";

import IntlMessages from "util/IntlMessages";

export default function AllergyHistoryForm(props) {
  const { control, errors, accConfig, register } = props;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "allergyHistory",
    keyName: "keyId", //, default to "id", you can change the key name
  });

  const onAdd = () => {
    append({
      allergy: "",
      severity: "",
      current: true,
    });
  };

  const onRemove = (index) => {
    remove(index);
  };
  const onReset = (index) => {
    remove(index);
    onAdd();
    // console.log('reset');
  };
  // console.log(errors);
  return (
    <div>
      {fields.map((item, index) => {
        return (
          <div key={item.keyId}>
            <Container style={{ paddingBottom: 25 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <Controller
                      label={<IntlMessages id="medicRecord.form.allergy" />}
                      as={TextField}
                      name={`allergyHistory[${index}].allergy`}
                      control={control}
                      fullWidth
                      defaultValue={item.allergy}
                      error={
                        errors.allergyHistory &&
                        errors.allergyHistory[index] &&
                        errors.allergyHistory[index].allergy
                      }
                      helperText={
                        errors.allergyHistory &&
                        errors.allergyHistory[index] &&
                        errors.allergyHistory[index].allergy &&
                        errors.allergyHistory[index].allergy.message
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <Controller
                      label={<IntlMessages id="medicRecord.form.severity" />}
                      as={TextField}
                      name={`allergyHistory[${index}].severity`}
                      control={control}
                      fullWidth
                      defaultValue={item.severity}
                      error={
                        errors.allergyHistory &&
                        errors.allergyHistory[index] &&
                        errors.allergyHistory[index].severity
                      }
                      helperText={
                        errors.allergyHistory &&
                        errors.allergyHistory[index] &&
                        errors.allergyHistory[index].severity &&
                        errors.allergyHistory[index].severity.message
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={11} sm={11}>
                  <FormControl fullWidth margin="normal">
                    {/* <InputLabel htmlFor="current"> */}
                    <IntlMessages id="medicRecord.form.current" />
                    {/* </InputLabel> */}
                    <Controller
                      as={Checkbox}
                      name={`allergyHistory[${index}].current`}
                      defaultValue={item.current}
                      type="checkbox"
                      control={control}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={1} sm={1}>
                  <Button
                    color="secondary"
                    aria-label="Remove"
                    onClick={() => onRemove(index)}
                    size="small"
                  >
                    <Tooltip
                      title={"Remove Allergy  " + (index + 1)}
                      size="large"
                    >
                      <Clear />
                    </Tooltip>
                  </Button>
                </Grid>
              </Grid>
            </Container>

            {index + 1 !== fields.length && <Divider />}
          </div>
        );
      })}
      <Tooltip title="Click to Add Alergy detail">
        <Button
          aria-label="Add"
          onClick={() => onAdd()}
          variant="contained"
          color="primary"
        >
          Add More <AddIcon />
        </Button>
      </Tooltip>
    </div>
  );
}
