import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { withRouter } from "react-router";
// Vendors Components
import {
  CardHeader,
  Tooltip,
  IconButton,
  TextField,
  Container,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@material-ui/core";

import {
  CheckCircle as CheckCircleIcon,
  Edit as EditIcon,
  Add as AddIcon,
  Equalizer as EqualizerIcon,
  List as ListIcon,
} from "@material-ui/icons";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

//  Themes Components

import Auxiliary from "util/Auxiliary";
import Widget from "components/Widget/index";
import AlertBox from "components/AlertBox/index";
// Custom Components and Helpers
// import { capitalize } from "util/helpers";

// Components
import FormModal from "./FormModal";

import {
  getValuefromLookup,
  getUniqueArray,
  displayDate,
  getInvestigationGroup,
} from "util/helpers";
//  Actions
import {
  onTestFormModal,
  onSetTest,
  onToggleGraphView,
} from "redux/actions/MedicalActions";
import { getInvestigation, getAccLookupBy } from "redux/actions/Setting";

const InvestigationHistory = (props) => {
  const dispatch = useDispatch();
  const { contact } = useSelector(({ contactStore }) => contactStore);
  const { accConfig } = useSelector(({ uiConfigureStore }) => uiConfigureStore);
  const { medTests, testGraph, medTestError, savedTest } = useSelector(
    ({ medRecordStore }) => medRecordStore
  );

  const { testgrpName, filteredTests, allTests } = testGraph;

  useEffect(() => {
    if (accConfig?.investigations?.length === 0) {
      dispatch(getInvestigation());
    }
    // dispatch(getAccLookupBy('investigation-groups'));
  }, []);
  //
  useEffect(() => {
    let grpName = savedTest.testItem
      ? getInvestigationGroup(
          accConfig.investigations,
          savedTest.testItem,
          "groupkey"
        )
      : testgrpName;
    // let grpName = testgrpName;

    var datas = Object.keys(medTests).reduce(function (res, v) {
      // console.log(res, v)
      grpName = grpName === "none" ? v : grpName;
      return res.concat(medTests[v]);
    }, []);
    // let datas = getUniqueArray(data, "testItem");

    datas.map((val) => {
      val.label = getValuefromLookup(accConfig.investigations, val.testItem);
      val.graphlabel = val.label + "(" + val.unit + ")";
    });

    // console.log(datas);
    var values = {
      ...testGraph,
      testgrpName: grpName,
      testName: "",
      allTests: datas,
      filteredTests: medTests[grpName] ? medTests[grpName] : [],
    };
    dispatch(onSetTest(values));
  }, [medTests, accConfig.investigations]);

  const onAddInpatientRecord = (formtype = "update", formfor = "labworks") => {
    dispatch(onTestFormModal(formtype, formfor));
  };

  // on Type Change
  const handleChange = (event) => {
    let val = event.target.value;
    let values = {
      ...testGraph,
      testgrpName: val,
      filteredTests: medTests[val] ? medTests[val] : [],
    };

    dispatch(onSetTest(values));
  };
  // on Search Change
  const onTestItemChange = (val) => {
    let data = [];
    if (val) {
      data = allTests.filter((item) => item.testItem === val.testItem);
    }
    let values = {
      ...testGraph,
      testName: val,
      testgrpName: "none",
      filteredTests: data,
    };

    dispatch(onSetTest(values));
    return val;
  };

  const onGraphToggle = (val) => {
    // dispatch(onToggleGraphView(val));
    props.history.push({
      pathname: `/app/viewPatient/${props.match.params.id}/insights/`,
      state: { item: "investigation" },
    });
  };

  return (
    <>
      <Widget styleName="jr-card-full jr-card-tabs-right jr-card-profile">
        <CardHeader
          action={
            <>
              {filteredTests.length > 0 && (
                <Tooltip title={"View Graph"}>
                  <IconButton
                    aria-label="Add"
                    onClick={() => onGraphToggle()}
                    variant="contained"
                    color="primary"
                  >
                    <EqualizerIcon />
                  </IconButton>
                </Tooltip>
              )}

              <Tooltip title="Add Test ">
                <IconButton
                  aria-label="Add"
                  onClick={() => onAddInpatientRecord("add")}
                  variant="contained"
                  color="primary"
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </>
          }
          title={"Investigations"}
        />
        <div className="jr-tabs-content jr-task-list">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Test Group</InputLabel>
                <Select value={testgrpName} onChange={handleChange}>
                  <MenuItem value="none">Test Group</MenuItem>
                  {accConfig.investigationGroup &&
                    accConfig.investigationGroup.map((grp) => (
                      <MenuItem key={grp.key} value={grp.key}>
                        {grp.value}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal">
                <Autocomplete
                  options={getUniqueArray(allTests, "testItem")}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField {...params} label="Test Item" />
                  )}
                  onChange={(event, newValue) => {
                    onTestItemChange(newValue);
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>

          {filteredTests.length === 0 && (
            <AlertBox
              infotype="danger"
              message={
                <span>
                  No{" "}
                  {getValuefromLookup(
                    accConfig.investigationGroup,
                    testgrpName
                  )}{" "}
                  Test Records Found <br />
                  Please Select other Group or Add New Tests.
                </span>
              }
              onClick={onAddInpatientRecord}
            />
          )}
          <List>
            {filteredTests.length > 0 &&
              filteredTests.map((value, index) => {
                return (
                  <div key={index}>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <CheckCircleIcon color="secondary" fontSize="large" />
                      </ListItemAvatar>
                      <ListItemText
                        primary={displayDate(value.dateTimeDisplay, "datetime")}
                        secondary={
                          <>
                            <strong>
                              {getValuefromLookup(
                                accConfig.investigations,
                                value.testItem
                              )}
                              :
                            </strong>
                            {value.result} {value.unit}
                            <br />
                            <span>{value.note}</span>
                          </>
                        }
                      />
                    </ListItem>
                    {index + 1 !== medTests.length && (
                      <Divider variant="inset" component="li" />
                    )}
                  </div>
                );
              })}
          </List>
        </div>
        {/* </CardContent> */}
      </Widget>
      <FormModal />
    </>
  );
};
export default withRouter(InvestigationHistory);
