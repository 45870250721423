import { api } from "util/api";
export function onModalClose() {
  return (dispatch) => {
    return dispatch({
      type: "CONTACTMODAL",
    });
  };
}

export function onContactForm(
  value = null,
  formType = "add",
  role = value.role ? value.role : "PATIENT"
) {

  return (dispatch) => {
    return dispatch({
      type: "CONTACTFORM",
      payload: {
        contact: value,
        formParam: { formType: formType, role: role },
      },
    });
  };
}
export function getClientTags() {
  return (dispatch) => {
    return dispatch({
      type: "CLIENTTAGS",
      payload: api.get("client/tags/"),
    });
  };
}

export function getClientById(id) {
  return (dispatch) => {
    return dispatch({
      type: "CLIENTBYID",
      payload: api.get("client/client-id/" + id),
    });
  };
}
export function onClientList(filterVal = null, searchVal = null) {
  // console.log(filterVal)
  // let query = "";
  // if (searchVal.toLowerCase() !== "client") {
  let query = "?" + filterVal + "=" + searchVal;
  // }
  return (dispatch) => {
    return dispatch({
      type: "CLIENTSEARCH",
      payload: api.get("client/all" + query),
    });
  };
}
export function onClientSearch(value) {
  return (dispatch) => {
    return dispatch({
      type: "CLIENTSEARCH",
      payload: api.get("client?query=" + value),
    });
  };
}

export function onClientAdd(value) {
  return (dispatch) => {
    return dispatch({
      type: "CLIENTADD",
      payload: api.post("client", value),
    });
  };
}

export function onClientUpdate(value) {
  return (dispatch) => {
    return dispatch({
      type: "CLIENTUPDATE",
      payload: api.post("client", value),
    });
  };
}
// Delete single contact
export function onClientDelete(value) {
  return (dispatch) => {
    return dispatch({
      type: "CLIENT_DELETE",
      payload: api.post("client", value),
      // payload: value,
    });
  };
}
// Delete multiple contacts
export function onClientDeletebyIds(ids) {
  return (dispatch) => {
    return dispatch({
      type: "CLIENTS_DELETEBUIDS",
      // payload: api.post('client',ids)
      payload: ids,
    });
  };
}
//  On Add client to favorite
export function onClientAddFav(value, type = "add") {
  if (type === "add") {
    return (dispatch) => {
      return dispatch({
        type: "ADD_FAVCLIENT",
        payload: value,
        // payload: api.post('client',value)
      });
    };
  } else {
    return (dispatch) => {
      return dispatch({
        type: "REM_FAVCLIENT",
        payload: value,
        // payload: api.post('client',value)
      });
    };
  }
}

// get documents list by Patient Id
// ContactStore
export function getDocsbyPatientId(id) {
  return (dispatch) => {
    return dispatch({
      type: "GET_DOCSBYPATID",
      payload: api.get("patient/document/patient-id/" + id),
    });
  };
}
// OnAdd DocumentModal
// ContactStore
export function onViewAddDocForm(view = true) {
  // console.log('dsfsd')
  return (dispatch) => {
    return dispatch({
      type: "DOCFORM",
      payload: { view: view },
    });
  };
}

// Save Documents
// ContactStore
export function onSaveDocument(value) {
  return (dispatch) => {
    return dispatch({
      type: value.id ? "UPDATE_DOCUMENT" : "SAVE_DOCUMENT",
      payload: api.post("patient/document", value),
    });
  };
}
// get document detail by Id
// ContactStore
export function getDocsById(id) {
  return (dispatch) => {
    return dispatch({
      type: "GET_DOCSBYID",
      payload: api.get("patient/document/id/" + id),
    });
  };
}
// resetContacts
export function resetContactsStore() {
  return (dispatch) => {
    dispatch({
      type: "RESET_CONTACTSTORE",
    });
  };
}

// viewNurseStatModal: false,
//   updateNurseStatFor: null,
//     nursingStationDetail: { },
