import React from "react";
import { connect } from "react-redux";

import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { Tooltip, IconButton } from "@material-ui/core";
//  Themes Components
import Auxiliary from "util/Auxiliary";

import { Edit as EditIcon } from "@material-ui/icons";
// Custom Components and Helpers
// import { getValuefromLookup, displayDate } from "helpers";

// Actions
// import { onContactForm } from "actions/ContactActions";
// import { onAddMedicationForm } from "actions/MedicalActions";
import { onContactForm ,} from "services/actions";
import { onAddMedicationForm } from "redux/actions/MedicalActions";
import { displayDate, getValuefromLookup } from "util/helpers";

const PersonalDetailBox = () => {
  const dispatch = useDispatch();
  const { accConfig,  } = useSelector(
    ({ uiConfigureStore }) => uiConfigureStore
  );
  const {  accountDefaultUnits } = useSelector(
    ({ configStore }) => configStore
  );
  const { contact, contactLoading } = useSelector(
    ({ contactStore }) => contactStore
  );
  const { medicalrecord } = useSelector(({ medRecordStore }) => medRecordStore);
  const personalInfo = contact.personalInfo;

  const onEditContact = (contact) => {
    dispatch(onContactForm(this.props.contact, "update", 1));
  };

  const handleClick = (val, formType = "add") => {
    dispatch(onAddMedicationForm(formType, val));
  };

  return (
    <div className="row">
      <div
        className="col-xl-2 col-lg-3 col-md-3 col-sm-6 col-12"
        onClick={() => handleClick("bloodgroup", "update")}
      >
        <Auxiliary>
          <Tooltip title="Click to Update BloodGroup">
            <div className="media flex-nowrap mt-3 mt-lg-4 mb-2 onclick">
              <div className="mr-3">
                <i className={`zmdi zmdi-invert-colors jr-fs-xlxl text-red`} />
              </div>
              <div className="media-body">
                <h6 className="mb-1 text-grey">Blood Group</h6>
                {medicalrecord?.id && medicalrecord?.bloodType && (
                  <span>
                    {getValuefromLookup(
                      accConfig.bloodType,
                      medicalrecord.bloodType
                    )}
                  </span>
                )}
              </div>
            </div>
          </Tooltip>
        </Auxiliary>
      </div>
      <div
        className="col-xl-2 col-lg-3 col-md-3 col-sm-6 col-12"
        onClick={() => handleClick("heightWeight")}
      >
        <Auxiliary>
          <Tooltip title="Click to Update height/weight">
            <div className="media flex-nowrap mt-3 mt-lg-4 mb-2 onclick">
              <div className="mr-3">
                <i
                  className={`zmdi zmdi-format-valign-top jr-fs-xlxl text-orange`}
                />
              </div>
              <div className="media-body">
                <h6 className="mb-1 text-grey">Height </h6>
                {medicalrecord?.height && medicalrecord?.height.height && (
                  <span>
                    {medicalrecord.height.heightText}{" "}
                    {accountDefaultUnits.heightUnit}
                    <br />
                    <small>
                      {displayDate(
                        medicalrecord.height.dateTimeDisplay,
                        "datetimeR"
                      )}
                    </small>
                  </span>
                )}
              </div>
            </div>
          </Tooltip>
        </Auxiliary>
      </div>
      <div
        className="col-xl-2 col-lg-3 col-md-3 col-sm-6 col-12 "
        onClick={() => handleClick("heightWeight")}
      >
        <Auxiliary>
          <Tooltip title="Click to Update height/weight">
            <div className="media flex-nowrap mt-3 mt-lg-4 mb-2 onclick">
              <div className="mr-3">
                <i className={`zmdi zmdi-space-bar jr-fs-xlxl text-orange`} />
              </div>

              <div className="media-body">
                <h6 className="mb-1 text-grey">Weight </h6>
                {medicalrecord.weight && medicalrecord.weight.weight && (
                  <span>
                    {accountDefaultUnits.weightUnit === "kg"
                      ? medicalrecord.weight.weight
                      : medicalrecord.weight.weightInLbs}{" "}
                    {accountDefaultUnits.weightUnit}
                    <br />
                    <small>
                      {displayDate(
                        medicalrecord.weight.dateTimeDisplay,
                        "datetimeR"
                      )}
                    </small>
                  </span>
                )}
              </div>
            </div>
          </Tooltip>
        </Auxiliary>
      </div>
      <div className="col-xl-2 col-lg-3 col-md-3 col-sm-6 col-12">
        <Auxiliary>
          <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
            <div className="mr-3">
              <i className={`zmdi zmdi-trending-up jr-fs-xlxl text-orange`} />
            </div>
            <div className="media-body">
              <h6 className="mb-1 text-grey">BMI</h6>
              {medicalrecord.bodyMassIndex && (
                <p>
                  {medicalrecord.bodyMassIndex.bmi}
                  <br />
                  <small>{medicalrecord.bodyMassIndex.range}</small>
                  <small>
                    {displayDate(
                      medicalrecord.bodyMassIndex.dateTimeDisplay,
                      "datetimeR"
                    )}
                  </small>
                </p>
              )}
            </div>
          </div>
        </Auxiliary>
      </div>
      <div
        className="col-xl-2 col-lg-3 col-md-3 col-sm-6 col-12"
        onClick={() => handleClick("bloodpressure")}
      >
        <Auxiliary>
          <Tooltip title="Click to Update Blood Pressure">
            <div className="media flex-nowrap mt-3 mt-lg-4 mb-2 onclick">
              <div className="mr-3">
                <i className={`zmdi zmdi-invert-colors jr-fs-xlxl text-red`} />
              </div>
              <div className="media-body">
                <h6 className="mb-1 text-grey">Blood Pressure</h6>
                {medicalrecord.id && medicalrecord.bloodPressure && (
                  <span>
                    {medicalrecord.bloodPressure.systolicPressure +
                      "/" +
                      medicalrecord.bloodPressure.diastolicPressure}
                    <br />
                    <small>
                      {displayDate(
                        medicalrecord.bloodPressure.dateTimeDisplay,
                        "datetimeR"
                      )}
                    </small>
                  </span>
                )}
              </div>
            </div>
          </Tooltip>
        </Auxiliary>
      </div>
      <div
        className="col-xl-2 col-lg-3 col-md-3 col-sm-6 col-12"
        onClick={() => handleClick("oxygenSat")}
      >
        <Auxiliary>
          <Tooltip title="Click to Update Oxygen Saturation">
            <div className="media flex-nowrap mt-3 mt-lg-4 mb-2 onclick">
              <div className="mr-3">
                <i className={`zmdi zmdi-trending-up jr-fs-xlxl text-orange`} />
              </div>
              <div className="media-body">
                <h6 className="mb-1 text-grey">SaO2</h6>
                {medicalrecord.id && medicalrecord.oxygenSaturation && (
                  <span>
                    {medicalrecord.oxygenSaturation.oxygenSaturation}%
                    <br />
                    <small>
                      {displayDate(
                        medicalrecord.oxygenSaturation.dateTimeDisplay,
                        "datetimeR"
                      )}
                    </small>
                  </span>
                )}
              </div>
            </div>
          </Tooltip>
        </Auxiliary>
      </div>
    </div>
  );
};

export default withRouter(PersonalDetailBox);
