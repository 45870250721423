import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from "react-hook-form";

// import ReactSelect from 'react-select';
import {
  Chip,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment,
  Divider,
  Container,
} from "@material-ui/core";

// import MuiAutoComplete from './MuiAutoComplete';
// import 'react-datepicker/dist/react-datepicker.css';

import AccountCircle from "@material-ui/icons/AccountCircle";

import IntlMessages from "util/IntlMessages";
import BloodGroupForm from "./BloodGroupForm";
import HtWtForm from "./HtWtForm";
import DiagnosisForm from "./DiagnosisForm";
// import BloodSugarForm from "./BloodSugarForm";
import OxygenSatForm from "./OxygenSatForm";

export default function MedicRecordForm(props) {
  const {
    control,
    errors,
    register,
    setValue,
    getValues,
    updateMedicalFor,
    watch,
  } = props;
  const { contact } = useSelector(({ contactStore }) => contactStore);

  // const [ isSubmitting, setSubmitting ] = useState(0);

  return (
    <div>
      {/* Blood Group */}
      <BloodGroupForm
        {...{
          control,
          errors,
          register,
          getValues,
          setValue,
        }}
      />
      {/* Height Weight Form */}
      <HtWtForm
        {...{
          control,
          errors,
          register,
          getValues,
          setValue,
          watch,
        }}
      />
      {/* Diagnosis form */}
      {/* {updateMedicalFor !== "medRecord" && (
        <DiagnosisForm
          {...{
            control,
            errors,
            icds,
            register,
            watch,
            getValues,
            setValue,
          }}
        />
      )} */}

      {/* Blood Sugar form */}
      {/* <BloodSugarForm
        {...{
          control,
          errors,
          register,
          getValues,
          setValue,
        }}
      /> */}
      {/* OxygenSatForm form */}
      <OxygenSatForm
        {...{
          control,
          errors,
          register,
          getValues,
          setValue,
        }}
      />
    </div>
  );
}
