import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { useForm } from "react-hook-form";

// material ui
import {
  ButtonGroup,
  Grid,
  withMobileDialog,
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  FormControl,
} from "@material-ui/core";
import { Alert, Spinner } from "reactstrap";
import DialogTitle from "components/Dialog/DialogTitle";
import TransitionSlide from "components/Dialog/TransitionSlide";
import getFormTitle from "components/formTitle";
// form components
import MedicalNoteForm from "./MedicalNoteForm";
import InterviewForm from "./InterviewForm";
import InOutRecordForm from "./InOutRecordForm";
import MedicationScheduleForm from "./MedicationScheduleForm";
// Actions
import {
  onSaveDailyLog,
  onMedicFormClose,
  onDailylogForm,
} from "redux/actions/MedicalActions";

import IntlMessages from "util/IntlMessages";
import { CrudNotification, timeToArray, datetoArray } from "util/helpers";

// formschema and initvalue
import { initFormVal, formSchema } from "./formIndex";
const logTypes = [
  {
    key: "interview",
    label: "Interview",
  },
  {
    key: "intakeOutputRecords",
    label: "Intake Output Records",
  },
  {
    key: "medicationSchedules",
    label: "Medication Schedules",
  },
];
const DailyLogForm = (props) => {
  const dispatch = useDispatch();
  const {
    dailyLog,
    addDailyLogModal,
    updateDailyLogModal,
    updateDailyLogFor,
    is_saving,
    dailyLogFormError,
  } = useSelector(({ medRecordStore }) => medRecordStore);
  const { accConfig, accountDefaultUnits } = useSelector(
    ({ uiConfigureStore }) => uiConfigureStore
  );
  const { contact } = useSelector(({ contactStore }) => contactStore);
  const {
    handleSubmit,
    register,
    reset,
    control,
    errors,
    setValue,
    getValues,
    watch,
  } = useForm({
    validationSchema: formSchema,
    defaultValues: updateDailyLogModal ? dailyLog : initFormVal,
    mode: "onBlur",
  });
  const onSubmit = (values) => {
    // console.log(values);
    return dispatch(onSaveDailyLog(values)).then((res) => {
      CrudNotification("success", "Daily Log  Data added Successfully !");
    });
  };

  // Process form values before submitting
  const transform = (onSubmitFunc) => (data) => {
    // console.log(data);
    let values = data;
    values.patientId = contact.clientId;
    values.date = updateDailyLogModal ? dailyLog.date : props.selectedDate;
    if (updateDailyLogModal) {
      values.date = dailyLog.date;
    } else {
      // values.date = moment(props.selectedDate)
      //   .format("YYYY,MM,DD")
      //   .split(",")
      //   .filter((x) => x.trim().length && !isNaN(x))
      //   .map(Number);
      values.date = values.date ? datetoArray(values.date) : null;
    }
    if (values.interview && values.interview.time) {
      values.interview.time = values.interview.time
        ? timeToArray(values.interview.time)
        : null;
    }
    if (values.intakeOutputRecords) {
      if (values.intakeOutputRecords.length !== 0) {
        values.intakeOutputRecords.map((val) => {
          val.time = val.time ? timeToArray(val.time) : null;
        });
      }
    }
    if (values.medicationSchedules) {
      if (values.medicationSchedules.length !== 0) {
        values.medicationSchedules.map((val) => {
          val.time = val.time ? timeToArray(val.time) : null;
        });
      }
    }
    // console.log(values);

    onSubmitFunc(values);
  };
  // on contacts modal close
  const onClose = () => {
    dispatch(onMedicFormClose());
  };

  // const onChangeFormType = (formFor) => {
  //   dispatch(onDailylogForm(addDailyLogModal ? "add" : "update", formFor));
  // };
  const values = getValues({ nest: true });
  // console.log(errors);
  return (
    <Dialog
      fullScreen={props.fullScreen}
      fullWidth={true}
      maxWidth="md"
      scroll="paper"
      TransitionComponent={TransitionSlide}
      open={addDailyLogModal || updateDailyLogModal}
      onClose={onClose}
    >
      <DialogTitle id="contact-form" onClose={onClose}>
        {getFormTitle(addDailyLogModal, updateDailyLogFor, contact.name)}
      </DialogTitle>
      <DialogContent dividers={true}>
        {dailyLogFormError && (
          <Alert color="danger"> {dailyLogFormError.message} </Alert>
        )}

        <input
          ref={register}
          name="patientId"
          type="text"
          hidden
          defaultValue={contact.clientId}
        />
        {updateDailyLogModal && (
          <input
            ref={register}
            name="id"
            type="text"
            hidden
            defaultValue={dailyLog.id}
          />
        )}
        {updateDailyLogModal && (
          <input
            ref={register}
            name="accountId"
            type="text"
            hidden
            defaultValue={dailyLog.accountId}
          />
        )}
        {/* {addDailyLogModal && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth margin="normal">
                <ButtonGroup
                  color="primary"
                  aria-label="outlined primary button group"
                >
                  {logTypes.map((val) => (
                    <Button
                      key={val.key}
                      onClick={() => onChangeFormType(val.key)}
                      variant={
                        updateDailyLogFor === val.key ? "contained" : "outlined"
                      }
                      color="primary"
                      size="large"
                    >
                      {val.label}
                    </Button>
                  ))}
                </ButtonGroup>
              </FormControl>
            </Grid>
          </Grid>
        )} */}
        {/* {logType === "interview" && ( */}

        {(updateDailyLogFor === "interview" ||
          updateDailyLogFor === "dailylog") && (
          <InterviewForm
            {...{
              control,
              register,
              getValues,
              errors,
              accConfig,
            }}
          />
        )}
        {updateDailyLogFor === "intakeOutputRecords" && (
          <InOutRecordForm
            {...{
              control,
              register,
              getValues,
              errors,
              accConfig,
              watch,
            }}
          />
        )}
        {updateDailyLogFor === "medicationSchedules" && (
          <MedicationScheduleForm
            {...{
              control,
              register,
              getValues,
              errors,
              accConfig,
              watch,
            }}
          />
        )}

        {updateDailyLogFor === "dailynotes" && (
          <MedicalNoteForm
            {...{
              control,
              register,
              getValues,
              errors,
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSubmit(transform(onSubmit))}
          disabled={is_saving}
        >
          {is_saving && <Spinner size="sm" />}
          <IntlMessages id="form.save" />
        </Button>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          onClick={() => onClose()}
        >
          <IntlMessages id="form.cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DailyLogForm.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};
export default withMobileDialog()(DailyLogForm);
