import React, { useState } from "react";
import VisitListItem from "./VisitListItem";
import CustomScrollbars from "util/CustomScrollbars";
import VisitHeader from "../VisitForm/VisitHeader";
import VisitReport from "../VisitForm/VisitReport";

const VisitList = ({ visits, onVisitSelect, width }) => {
  // console.log(visits);
  const [allVisits, setAllVisits] = useState(visits);
  return (
    <div className="module-list mail-list">
      <div style={{ padding: "1rem" }}>
        <VisitHeader visits={visits} setAllVisits={setAllVisits} />
      </div>
      <div>
        <VisitReport />
      </div>
      <CustomScrollbars
        className="module-list-scroll scrollbar"
        style={{
          height: width >= 1200 ? "calc(100vh - 259px)" : "calc(100vh - 238px)",
        }}
      >
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div id="tracking">
              <div className="tracking-list">
                {allVisits &&
                  allVisits.map((visit, index) => (
                    <VisitListItem
                      key={visit.id}
                      visit={visit}
                      onVisitSelect={onVisitSelect}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>

        {/* 
        <div className="timeline-section">
         
        </div> */}
      </CustomScrollbars>
    </div>
  );
};

export default VisitList;
