import React, { Component } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";

import asyncComponent from "util/asyncComponent";
import Users from "./Users";

class Settings extends Component {
  render() {
    const { match } = this.props;
    return (
      <>
        {/* <UserHeader /> */}
        <Switch>
          <Redirect exact from={`${match.path}/`} to={`${match.path}/users`} />
          <Route path={`${match.url}/users`} component={Users} />
        </Switch>
      </>
    );
  }
}

export default withRouter(Settings);
