const allColors = [
  {
    color: "Aero",
    hex: "#7CB9E8",
  },
  {
    color: "Beaver",
    hex: "#9F8170",
  },
  {
    color: "Cadet",
    hex: "#536872",
  },
  {
    color: "Denim",
    hex: "#1560BD",
  },
  {
    color: "Eggplant",
    hex: "#614051",
  },
  {
    color: "Flame",
    hex: "#E25822",
  },
  {
    color: "Gold",
    hex: "#D4AF37",
  },
  {
    color: "Harlequin",
    hex: "#3FFF00",
  },
  {
    color: "Indigo",
    hex: "#4B0082",
  },
  {
    color: "Jade",
    hex: "#00A86B",
  },
  {
    color: "Kobicha",
    hex: "#6B4423",
  },
  {
    color: "Lion",
    hex: "#C19A6B",
  },
  {
    color: "Magenta",
    hex: "#FF00FF",
  },
  {
    color: "Nickel",
    hex: "#727472",
  },
  {
    color: "Ochre",
    hex: "#CC7722",
  },
  {
    color: "Pear",
    hex: "#D1E231",
  },
  {
    color: "Redwood",
    hex: "#A45A52",
  },
  {
    color: "Sage",
    hex: "#BCB88A",
  },
  {
    color: "Teal",
    hex: "#008080",
  },
  {
    color: "Ultramarine",
    hex: "#3F00FF",
  },
  {
    color: "Violet",
    hex: "#8F00FF",
  },
  {
    color: "Wine",
    hex: "#722F37",
  },
  {
    color: "Xanthous",
    hex: "#F1B42F",
  },
  {
    color: "Zomp",
    hex: "#39A78E",
  },
  {
    color: "1",
    hex: "#39A78E",
  },
  {
    color: "2",
    hex: "#CEFF00",
  },
  {
    color: "3",
    hex: "#A020F0",
  },
  {
    color: "4",
    hex: "#5B92E5",
  },
  {
    color: "5",
    hex: "#A67B5B",
  },
  {
    color: "6",
    hex: "#8A9A5B",
  },
  {
    color: "7",
    hex: "#EEE600",
  },
  {
    color: "8",
    hex: "#8B8589",
  },
  {
    color: "9",
    hex: "#F28500",
  },
  {
    color: "0",
    hex: "##A7FC00",
  },
];

export default allColors;
