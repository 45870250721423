import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import MomentUtils from "@date-io/moment";
// import "moment/locale/vi";

import moment from "moment";
import { setDateForPicker } from "../helpers/common";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DatePicker,
  TimePicker,
  KeyboardDateTimePicker,
  DateTimePicker,
} from "@material-ui/pickers";

const pickerTypes = {
  DATE: "date",
  DATETIME: "datetime",
  TIME: "time",
};

const DateTimePickerFieldComp = ({
  type = "date",
  id,
  name,
  label,
  format,
  inputRef,
  setAgeValue,
  value,
  disableFuture = true,
  ...props
}) => {
  // const [ selectedDate, setSelectedDate ] = useState(value ? new Date(value) : new Date(''));
  const [selectedDate, setSelectedDate] = useState(moment());
  const [dformat, setFormat] = useState(format ? format : null);
  // set time
  useEffect(() => {
    if (type === "time" && value) {
      var date = moment.utc(value, "HH:mm").local().format();
      setSelectedDate(date);
      setFormat("HH:mm");
    }
    if (type === "date" && value) {
      let date = setDateForPicker(value);
      setSelectedDate(date);
      setFormat("YYYY-MM-DD");
    }
    if (type === "datetime" && value) {
      let date = setDateForPicker(value);
      setSelectedDate(date);
      setFormat("YYYY-MM-DD HH:mm");
    }
    if (!value) {
      setSelectedDate(null);
      // console.log("sdfsdf");
    }
    if (!format) {
      type === "time"
        ? setFormat("HH:mm")
        : type === "date"
        ? setFormat("YYYY-MM-DD")
        : setFormat("YYYY-MM-DD HH:mm");
    }
  }, []);
  let ADatetimePicker = DatePicker;
  if (type === pickerTypes.DATE) {
    ADatetimePicker = DatePicker;
    // DatetimePicker = KeyboardDatePicker;
  } else if (type === pickerTypes.TIME) {
    ADatetimePicker = TimePicker;
  } else if (type === pickerTypes.DATETIME) {
    ADatetimePicker = DateTimePicker;
  }

  // Handle Date time for Personal Detail
  const handleDateChange = (date) => {
    if (name === "personalInfo.dateOfBirth") {
      let age = moment().diff(date, "years");
      setAgeValue("personalInfo.age", age);
    }
    setSelectedDate(date);
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <ADatetimePicker
        {...props}
        ampm={false}
        id={id}
        name={name}
        label={label}
        format={dformat}
        value={selectedDate}
        onChange={handleDateChange}
        inputRef={inputRef}
        disableFuture={disableFuture}
      />
    </MuiPickersUtilsProvider>
  );
};

DateTimePickerFieldComp.propTypes = {
  type: PropTypes.oneOf(["date", "datetime", "time"]),
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  format: PropTypes.string,
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  value: PropTypes.any,
};

// DateTimePickerFieldComp.defaultProps = {
// 	type: 'date'
// };

export default memo(DateTimePickerFieldComp);
