// material ui
import { Dialog, withMobileDialog } from "@material-ui/core";
import TransitionSlide from "components/Dialog/TransitionSlide";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { onComplainFormClose } from "../actions";
import FormComponent from "./FormComponent";

const ComplainModal = (props) => {
  const dispatch = useDispatch();
  const { addComplainModal, updateComplainFormView } = useSelector(
    ({ visitStore }) => visitStore
  );

  // on contacts modal close
  const onClose = () => {
    dispatch(onComplainFormClose());
    // dispatch(clearIcds());
  };
  return (
    <Dialog
      fullScreen={props.fullScreen}
      fullWidth={true}
      maxWidth="md"
      scroll="paper"
      TransitionComponent={TransitionSlide}
      open={addComplainModal || updateComplainFormView}
      onClose={onClose}
    >
      {(addComplainModal || updateComplainFormView) && <FormComponent />}
    </Dialog>
  );
};

ComplainModal.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};
export default withMobileDialog()(ComplainModal);
