import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Chip,
  Input,
  InputAdornment,
  IconButton,
  ButtonGroup,
  Button,
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  AddCircle as AddCircleIcon,
} from "@material-ui/icons";
import { getColor } from "util/helpers";

//  Tag Input
const ClientTags = (props) => {
  // Handle Date time for Personal Detail
  const { client } = useSelector(({ profileStore }) => profileStore);
  const { clientTags } = useSelector(({ contactStore }) => contactStore);

  const { tags, setTags } = props;
  const [tagDatas, setTagDatas] = useState(client.tags ? client.tags : []);
  const [showInput, setShowInput] = useState(false);

  useEffect(() => {
    setTagDatas(client.tags ? client.tags : []);
  }, [client]);

  const onValueChange = (event, value) => {
    setTagDatas(value);
    return value;
  };

  const handleShowButton = (show) => {
    if (!show) {
      tagDatas.length > 0 ? setTags(tagDatas) : setTagDatas(tags);
    }
    setShowInput(show);
  };
  const handleCancelButton = () => {
    setTagDatas(tags);
    setShowInput(false);
  };
  return (
    <>
      {tagDatas.map((data) => {
        return (
          <>
            {!showInput && (
              <Chip
                label={data}
                key={data}
                style={{
                  backgroundColor: getColor(data),
                  color: "white",
                  margin: "10px",
                }}
              />
            )}
          </>
        );
      })}
      {showInput && (
        <Autocomplete
          multiple
          id="tags-filled"
          options={clientTags}
          value={tagDatas}
          freeSolo
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option}
                style={{
                  backgroundColor: getColor(option),
                  color: "white",
                  margin: "10px",
                }}
                {...getTagProps({ index })}
              />
            ))
          }
          onChange={onValueChange}
          renderInput={(params) => (
            <TextField {...params} placeholder="Tags" fullWidth />
          )}
          // style={{
          //   width: "75%",
          // }}
        />
      )}
      <ButtonGroup
        variant="text"
        color="primary"
        aria-label="text primary button group"
      >
        <IconButton
          onClick={() => handleShowButton(!showInput)}
          variant="contained"
          color="primary"
        >
          {!showInput ? <AddCircleIcon /> : <CheckCircleIcon />}
        </IconButton>
        {showInput && (
          <IconButton
            onClick={() => handleCancelButton()}
            variant="contained"
            color="secondary"
          >
            <CancelIcon />
          </IconButton>
        )}
      </ButtonGroup>
    </>
  );
};
export default ClientTags;
