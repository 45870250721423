import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import { CrudNotification, displayDate, getColor } from "util/helpers";

import { onTemplateForm, onTemplateDelete } from "../../actions";
const TemplateListItem = ({ template, onTemplateSelect }) => {
  // console.log(template);
  const dispatch = useDispatch();
  const [content, setContent] = useState("");
  useEffect(() => {
    let str = template.body;
    str = str.replace(/<\/?[^>]+(>|$)/g, "");
    // replace(/(<([^>]+)>)/gi, "");
    if (str.length > 300) {
      str = str.slice(0, 300) + "<strong> Read more ....</strong>";
    }
    setContent(str);
  }, []);

  const [anchorEl, setAnchorEl] = useState(undefined);
  // on Update
  const onUpdateTemplate = (template) => {
    dispatch(onTemplateForm(template, "update"));
  };
  // on Update
  const onDeleteTemplate = (template) => {
    template.status = template.status === "ACTIVE" ? "DELETED" : "ACTIVE";

    return dispatch(onTemplateDelete(template)).then((res) => {
      CrudNotification(
        "success",
        `Template ${
          template.status === "ACTIVE" ? "Active" : "Deleted"
        } Successfully !`
      );
    });
  };
  return (
    <div className="module-list-item mail-cell">
      <div className="mail-user-info">
        {template.createdBy && (
          <div
            className="avatar rounded-circle size-40 text-white text-center"
            style={{
              fontSize: 16,
              backgroundColor: getColor(template.createdBy),
            }}
          >
            {template.createdBy.charAt(0).toUpperCase()}
          </div>
        )}
      </div>

      <div
        className="module-list-info"
        onClick={() => {
          onTemplateSelect(template);
        }}
      >
        <div className="module-list-content">
          <div className="mail-user-info">
            <span className="sender-name text-dark">{template.name}</span>

            <span className="toolbar-separator" />

            <span
              className="d-inline-block"
              // style={{ maxWidth: "calc(100% - 220px)" }}
            >
              {template.subject}
            </span>

            {template.hasAttachments && <i className="zmdi zmdi-attachment" />}
          </div>
          <small className="time">
            {displayDate(template.createdAt, "datetime")}
          </small>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
          {/* <div className="labels">{template.createdBy}</div> */}
        </div>
      </div>
      <IconButton
        type="button"
        className="icon-btn size-50 p-0"
        onClick={() => {
          onUpdateTemplate(template);
        }}
      >
        <i className="zmdi zmdi-edit" />
      </IconButton>
      <IconButton
        type="button"
        className="icon-btn size-50 p-0"
        color="secondary"
        onClick={() => {
          onDeleteTemplate(template);
        }}
      >
        <i className="zmdi zmdi-delete" />
      </IconButton>
    </div>
  );
};

export default TemplateListItem;
