import { Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CrudNotification, displayDate } from "util/helpers";
import { onVisitDelete } from "../../actions";

const VisitListItem = ({ visit, onVisitSelect }) => {
  // console.log(task);
  const dispatch = useDispatch();
  const { accConfig } = useSelector(({ uiConfigureStore }) => uiConfigureStore);

  const { loggedUser } = useSelector(({ auth }) => auth);

  const [visitContent, setVisitContent] = useState("");
  // on Update
  //   const onUpdateTask = (task) => {
  //     dispatch(onVisitForm(task, "update"));
  //   };
  useEffect(() => {
    let str = visit.description ? visit.description : "";
    str = str.replace(/<\/?[^>]+(>|$)/g, "");
    // replace(/(<([^>]+)>)/gi, "");
    if (str.length > 300) {
      str = str.slice(0, 300) + "<strong> more >></strong>";
    }
    setVisitContent(str);
  }, []);

  // on onDeleteTask
  const onDeleteTask = (task) => {
    visit.status = visit.status === "ACTIVE" ? "DELETED" : "ACTIVE";
    return dispatch(onVisitDelete(task)).then((res) => {
      CrudNotification(
        "success",
        `Task ${
          visit.status === "ACTIVE" ? "Active" : "Deleted"
        } Successfully !`
      );
    });
  };

  let startD = displayDate(visit.startDateTime, "datetimef").split(",");
  let startE = displayDate(visit.endDateTime, "datetimef").split(",");

  const setIcons = () => {
    let icon = "check";
    icon =
      visit.status === "COMPLETED"
        ? "check"
        : visit.status === "ACTIVE"
        ? "dot-circle"
        : visit.status === "CANCELED"
        ? "close"
        : visit.status === "DELETED"
        ? "delete"
        : "circle";
    return icon;
  };
  return (
    <div className="tracking-item">
      <div className={`tracking-icon status-${visit?.status?.toLowerCase()}`}>
        <i className={`zmdi zmdi-${setIcons()}`} />
      </div>
      <div className="tracking-date">
        <Tooltip title={"Start Date"} aria-label="Start Date">
          <span>
            {startD[0] + startD[1]}
            <br />
            {startD[2]}
          </span>
        </Tooltip>
      </div>
      <div
        className="tracking-content"
        onClick={() => onVisitSelect(visit?.visitId)}
      >
        <div>{visit?.title}</div>
        <div dangerouslySetInnerHTML={{ __html: visitContent }}></div>
        <span>
          {visit?.aboutName && `${visit?.aboutName} / with `}
          {visit?.assignedName && `${visit?.assignedName} `}

          {/* <Chip
            variant="outlined"
            color="primary"
            size="small"
            label={getValuefromLookup(accConfig.taskCategory, visit.category)}
          /> */}
        </span>
      </div>
      <div></div>
    </div>
  );
};

export default VisitListItem;
